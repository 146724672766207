import {
  ApplicationInsights,
  DistributedTracingModes
} from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_AZURE_TELEMETRY_ID,
    distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
    enableAutoRouteTracking: true
  }
});

export default appInsights;
