import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '@bit/be-novative.kit.button';
import Modal from './Modal';
import Panel from '../Panel';

function Alert({
  isOpen,
  confirmButtonText,
  cancelButtonText,
  onCancel,
  onConfirm,
  children
}) {
  const footerClasses = cn('modal__footer', {
    'modal__footer--confirm': onCancel
  });

  return (
    <Modal isOpen={isOpen} size="small">
      <Panel bordered>
        {children}
        <div className={footerClasses}>
          {onCancel && (
            <Button variant={'secondary'} onClick={onCancel}>
              {cancelButtonText}
            </Button>
          )}
          <Button variant={'success'} onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </div>
      </Panel>
    </Modal>
  );
}

Alert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired
};

Alert.defaultProps = {
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Confirm'
};

export default Alert;
