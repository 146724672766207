import map from 'lodash/map';
import { SOCKET_MSG as MSG, S_ID } from '../../constants';
import { sendSocketMessage } from '../../../common/actions/socket';
import { normaliseParticipant } from './users';
import { mapPhaseProps } from './_mappers';

/**
 * Handles when the user wants to advance to the next ideation phase
 * @param {(string|number)} nextPhaseId - The current phase ID from where the user wants to advance
 * @returns {import('redux').Action}
 */
export const reqPhaseChangeMsg = nextPhaseId =>
  sendSocketMessage(S_ID, MSG.PHASE_CHANGE_REQ, nextPhaseId);

/**
 * @param {[phaseType: string, users: User[], botLoveCount: number]} msg - The raw socket message
 * @returns {{phase: string, users: User[], hummingbirdLoveCount:number}}
 */
export const normalisePhaseChangeMsg = msg => {
  const [phase, users, hummingbirdLoveCount] = msg;
  return {
    phase,
    users: map(users, normaliseParticipant),
    hummingbirdLoveCount
  };
};

/**
 * @param {[number: phaseId, phases: IdeationPhaseBehaviour[]]} msg - The raw socket message
 * @returns {{ currentPhaseId: number, phases: IdeationPhaseBehaviour[]}}
 */
export const normalisePhaseChangedWithConfigMsg = msg => {
  const [currentPhaseId, phases] = msg;
  return {
    currentPhaseId,
    phases: phases.map(mapPhaseProps)
  };
};
