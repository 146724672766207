import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import './Backdrop.css';

export const backdropClassNames = {
  base: 'backdrop',
  light: 'backdrop--light',
  dark: 'backdrop--dark',
  brainstorming: 'backdrop--brainstorming-preview'
};

function Backdrop({
  children,
  className,
  onClick,
  type = 'dark',
  visible = false
}) {
  const classes = cn(
    backdropClassNames.base,
    backdropClassNames[type],
    className,
    {
      [`${backdropClassNames.base}--hidden`]: !visible
    }
  );

  return (
    <div onClick={onClick} className={classes}>
      {children}
    </div>
  );
}

Backdrop.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['dark', 'light', 'brainstorming']),
  visible: PropTypes.bool
};

export default Backdrop;
