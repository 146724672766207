import get from 'lodash/get';
import { MANDATORY_CONSENT_KEYS } from '../../constants';

/**
 * Checks if mandatory fields have not been accepted in the user's consent model
 * @param {object} consentModel - A map of concept booleans
 * @return {boolean} `true` if any of the required consent fields is missing, `false` if all mandatory fields are accepted.
 */

export default function isConsentRequired(consentModel) {
  return MANDATORY_CONSENT_KEYS.some(key => get(consentModel, key) == false); // eslint-disable-line eqeqeq
}
