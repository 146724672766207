import React, { useEffect } from 'react';
import { translate } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { OTHER_FILTER_ID, conceptListFilterKeys } from '../../constants';
import { conceptListPage } from '../../paths';
import { changeListStageFilterMetrics } from '../../actions';
import useSetFilter from '../../hooks/conceptList/useSetFilter';
import useActiveStageFilter from '../../hooks/conceptList/useActiveStageFilter';
import useIsListLoading from '../../hooks/conceptList/useIsListLoading';
import PrimaryNavigation from '../../../common/components/PrimaryNavigation';
import Tab from '../../../common/components/TabButton';
import {
  getStageTranslation,
  getStageFilterIds,
  getStageFilters
} from '../../../ideaConceptCanvas/utils/translateConceptStage';

const { STAGE } = conceptListFilterKeys;
const stageFilters = getStageFilters();
const stageFilterIds = getStageFilterIds();

function StageTabRow({ t, ...rest }) {
  const activeStageFilter = useActiveStageFilter();
  const loading = useIsListLoading();
  const dispatch = useDispatch();
  const setFilter = useSetFilter();
  useEffect(
    function updateFilterOnRouteChange() {
      setFilter(STAGE, stageFilterIds[activeStageFilter], {
        overwriteValue: true
      });
      dispatch(changeListStageFilterMetrics(activeStageFilter));
    },
    [activeStageFilter, dispatch, setFilter]
  );

  return (
    <PrimaryNavigation {...rest}>
      {stageFilters.map(stage => (
        <Tab
          key={stage}
          active={activeStageFilter === stage}
          disabled={loading}
          to={conceptListPage(stage)}>
          {getStageTranslation(stage, t)}
        </Tab>
      ))}
      <Tab
        active={activeStageFilter === OTHER_FILTER_ID}
        disabled={loading}
        to={conceptListPage(OTHER_FILTER_ID)}>
        {t(`ideaConcepts/listPage/othersFilter`)}
      </Tab>
    </PrimaryNavigation>
  );
}

export default translate()(StageTabRow);
