import { CanvasContentRef } from '../constants';

const splitRef = string => {
  try {
    return string.split(CanvasContentRef.Separator);
  } catch (error) {
    return [];
  }
};

const getConceptIdFromRef = ideaTextWithRef => {
  const [, refId] = splitRef(String(ideaTextWithRef));
  return refId || null;
};

const isConceptRef = string => {
  const [refType] = splitRef(string);
  return refType === CanvasContentRef.Concept;
};

const createConceptRef = conceptId =>
  CanvasContentRef.Concept + CanvasContentRef.Separator + conceptId;

export { createConceptRef, getConceptIdFromRef, isConceptRef };
