import trim from 'lodash/trim';
import i18n from 'i18next';
import {
  SUCCESS_LANGUAGE_CHANGE,
  FAILURE_LANGUAGE_CHANGE,
  SIDENAV_CLOSE,
  SIDENAV_OPEN,
  SHARE_BUTTON_INSIDE_THE_SHARE_MODAL_CLICK,
  COPY_TO_CLIPBOARD_BUTTON_CLICK
} from '../../constants';
import { persistPreferredLanguage } from '../../utils/storage';
import { updatePreferredLocale } from '../../profile/state/profile';

export * from './alert';
export * from './modal';
export * from './challenges';
export * from './love';
export * from './company';
export * from './navigation';
export * from './users';
export * from './profile';
export * from './notifications';
export * from './network';
export * from './suggestions';
export * from './onboarding';
export * from './ideaConcepts';

export function changeLanguage(lang) {
  return function(dispatch) {
    if (trim(lang).length) {
      i18n.changeLanguage(lang, err => {
        if (err) {
          return dispatch({
            type: FAILURE_LANGUAGE_CHANGE,
            payload: err
          });
        }
        persistPreferredLanguage(lang);
        dispatch(updatePreferredLocale(lang));
        dispatch({
          type: SUCCESS_LANGUAGE_CHANGE,
          payload: lang
        });
      });
    }
  };
}

export function openSidenav() {
  return {
    type: SIDENAV_OPEN
  };
}

export function closeSidenav() {
  return {
    type: SIDENAV_CLOSE
  };
}

export const shareButtonClick = network => dispatch => {
  dispatch({
    type: SHARE_BUTTON_INSIDE_THE_SHARE_MODAL_CLICK,
    meta: {
      metrics: {
        provider: network
      }
    }
  });
};

export const ERASE_STORES = 'ERASE_STORES';

export const eraseStores = { type: ERASE_STORES };

export const copyToClipboardClicked = () => dispatch => {
  dispatch({
    type: COPY_TO_CLIPBOARD_BUTTON_CLICK
  });
};
