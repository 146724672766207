import client from '../../api/client';
import { getApiResponseData } from '../../api/utils/apiResponse';

/**
 * Queries the API with the search string. The consumer should handle debouncing requests and waiting for a minimum query length.
 * @param {string} query - The search string
 * @return {Promise<User[]>} The platform configuration extracted from the response
 */

function queryUsers(query = '') {
  return client
    .get('/users/find', {
      params: {
        q: query
      }
    })
    .then(getApiResponseData);
}

export default {
  queryUsers
};
