import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { Field } from 'react-final-form';
import { InputFormControl } from '../../../common/components';
import { validateConceptTitle } from '../../utils/formValidation';
import { IDEA_CONCEPT_TITLE_FIELD_MAXLENGTH } from '../../config';

ConceptTitleInput.propTypes = {
  autoFocus: PropTypes.bool,
  name: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};
export function ConceptTitleInput({ autoFocus, name, t, ...restOfProps }) {
  return (
    <Field
      component={InputFormControl}
      name={name}
      label={t('ideaConcept/prop/title/label')}
      placeholder={t('ideaConcept/prop/title/placeholder')}
      maxLength={IDEA_CONCEPT_TITLE_FIELD_MAXLENGTH}
      validate={validateConceptTitle(t)}
      required
      autoFocus={autoFocus}
      {...restOfProps}
    />
  );
}

export default compose(
  memo,
  translate()
)(ConceptTitleInput);
