import flow from 'lodash/flow';
import get from 'lodash/get';
import getOr from 'lodash/fp/getOr';
import { createSelector } from 'reselect';
import { getAllIdsFrom } from '../../common/selectors/entities';
import { isLoading } from '../../common/selectors/meta';

export const getEvalCriterionAverage = criterion => get(criterion, 'average');

export const getEvalCriterionId = criterion => get(criterion, 'criterionId');

export const getEvalCriterionName = criterion => get(criterion, 'name');

export const rootSelector = state => get(state, 'ideaConceptEvaluation', {});

export const getOffset = state => get(state, 'offset', 0);

export const getConceptMap = flow(
  rootSelector,
  getOr({}, 'byId')
);

/**
 * Returns the ID of the next concept to be reviewed during an evaluation session.
 * Also checks if the user backtracked to a previous concept during their evaluation session
 * @param {import('redux').Store} state
 * @returns {string}
 */

export const getNextConceptIdForEval = createSelector(
  flow(
    rootSelector,
    getAllIdsFrom
  ),
  getOffset,
  (ids, offset) => ids[offset]
);

/**
 * Returns `true` if no concept data was received from the API after the latest evaluation was submitted
 * @param {import('redux').Store} state
 * @returns {boolean}
 */

export const isConceptEvaluationFinished = state =>
  flow(
    rootSelector,
    getOr(false, 'isEvaluationFinished')
  )(state);

export const isConceptEvaluationLoading = state =>
  flow(
    rootSelector,
    isLoading
  )(state);
