import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { branch, compose, renderNothing } from 'recompose';
import Intercom from 'react-intercom';
import { connect } from 'react-redux';
import { ownProfileSelector } from '../../../profile/state/profile';
import { isCookieConsentDenied } from '../../../utils/cookieConsent';
import { intercomDimensions } from '../../styled/variables.styled';

const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const { vPadding, hPadding } = intercomDimensions;

IntercomClient.defaultProps = {
  userProfile: {}
};
export function IntercomClient({ userProfile }) {
  const userData = {};

  if (!isEmpty(userProfile)) {
    const { firstName, lastName, userId } = userProfile;
    userData.user_id = userId;
    userData.name = `${firstName} ${lastName}`;
  }

  return (
    <Intercom
      appID={APP_ID}
      vertical_padding={vPadding}
      hPadding={hPadding}
      {...userData}
    />
  );
}

const mapStateToProps = state => ({
  userProfile: ownProfileSelector(state)
});

export default compose(
  connect(mapStateToProps),
  branch(isCookieConsentDenied, renderNothing)
)(IntercomClient);
