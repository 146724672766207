import forceArray from '../../utils/forceArray';

export const FILTER_ITEMS_BY = 'FILTER_ITEMS_BY';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const REMOVE_FILTER_TYPE = 'REMOVE_FILTER_TYPE';

export const filterItemsBy = reducerId => (
  key,
  value,
  overwriteExisting = false
) => ({
  type: FILTER_ITEMS_BY,
  payload: { key, value },
  meta: { reducerId, overwrite: overwriteExisting }
});

export const removeAllFiltersWithKey = reducerId => key => ({
  type: REMOVE_FILTER_TYPE,
  payload: key,
  meta: { reducerId }
});

export const removeFilter = reducerId => (key, value) => {
  if (!value) {
    return removeAllFiltersWithKey(reducerId)(key);
  }

  return {
    type: REMOVE_FILTER,
    payload: { key, value: forceArray(value) },
    meta: { reducerId }
  };
};
