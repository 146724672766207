import React from 'react';
import { getIdeaConceptId } from '../../../selectors/ideaConcepts';
import { AlignedCellContent } from '../ConceptTable.styled';
import getUserEvals from './getUserEvals';

export default function ExpandedCommentCell({ as: DisplayComponent, concept }) {
  return (
    <>
      {/* Whitespace for "Reviewed by" label in row header cell */}
      <small>&nbsp;</small>
      {getUserEvals(concept)
        .map(evaluation => evaluation.user.userId)
        .map(authorId => (
          <AlignedCellContent
            key={authorId}
            alignItems="flex-start"
            justifyContent="stretch">
            <DisplayComponent
              authorId={authorId}
              conceptId={getIdeaConceptId(concept)}
            />
          </AlignedCellContent>
        ))}
    </>
  );
}
