export default function calculateSurplusItems(items = [], maxItems) {
  const maxDisplay = Math.floor(maxItems) || 0;
  const shouldTruncate = maxDisplay > 0;

  if (!Array.isArray(items)) {
    throw new Error(`items should be an array, found ${typeof items} instead`);
  }

  const surplus = shouldTruncate ? Math.max(items.length - maxDisplay, 0) : 0;
  const visibleItems = shouldTruncate ? items.slice(0, maxDisplay) : items;

  return [visibleItems, surplus];
}
