import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { notificationsSelector } from '../../../common/reducers';
import { NotificationList } from '../../components';
import { markNotifications } from '../../actions';

class NotificationsPopover extends Component {
  handleNotificationClick = notification => {
    const { onClose, markNotifications } = this.props;

    if (!notification.read) {
      markNotifications([notification.notificationId]);
    }

    onClose();
  };

  render() {
    const { notifications } = this.props;
    return (
      <NotificationList
        condensed
        notifications={notifications}
        onNotificationClick={this.handleNotificationClick}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: notificationsSelector(state)
  };
}

export default compose(connect(mapStateToProps, { markNotifications }))(
  NotificationsPopover
);
