import React, { Children } from 'react';
import classNames from 'classnames';
import './MediaList.css';

export default function MediaList({ children, className, condensed }) {
  const classes = classNames(
    'MediaList',
    {
      'MediaList--condensed': condensed
    },
    className
  );

  return (
    <ul className={classes}>
      {Children.map(children, child => (
        <li className="MediaList__Item">{child}</li>
      ))}
    </ul>
  );
}
