import get from 'lodash/get';
import ideaConceptsReducerFactory from './ideaConcepts/ideaConceptsReducerFactory';

const ideaConcepts = ideaConceptsReducerFactory(state =>
  get(state, 'ideaConcepts', [])
);

export const {
  ideaConceptByIdSelector,
  isIdeaConceptLoadingSelector,
  isIdeaConceptErrorSelector,
  isIdeaConceptByIdLoadingSelector,
  isIdeaConceptByIdErrorSelector,
  isRevealPendingSelector,
  isEditPendingSelector
} = ideaConcepts;

export default ideaConcepts.reducer;
