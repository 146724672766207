import React from 'react';
import NonIdealState from './NonIdealState';
import defaultImageUrl from './Hummingbird.png';
import listImageUrl from './HummingbirdList.png';
import hiddenImageUrl from './HummingbirdHidden.gif';

NonIdealState.HUMMINGBIRD = <img src={defaultImageUrl} alt="" />;
NonIdealState.HIDDEN_HUMMINGBIRD = <img src={hiddenImageUrl} alt="" />;
NonIdealState.LIST_HUMMINGBIRD = <img src={listImageUrl} alt="" />;

export default NonIdealState;
