import React from 'react';
import truncate from 'lodash/truncate';
import { Link } from 'react-router-dom';
import Spinner from '@bit/be-novative.kit.spinner';
import Avatar from '../../../../common/components/Avatar';
import Ellipsis from '../../../../common/components/Ellipsis';
import Icon from '../../../../common/components/Icon';
import { getUserId } from '../../../../common/getters/users';
import { Size } from '../../../../constants';
import { conceptSubpage } from '../../../paths';
import { getTitle, getIdeaConceptId } from '../../../selectors/ideaConcepts';
import { AlignedCellContent } from '../ConceptTable.styled';
import getUserEvals from './getUserEvals';

const conceptTitle = {
  main: (t, concept, onExpand, expandState = 'closed') => (
    <AlignedCellContent justifyContent="space-between">
      <Link
        to={conceptSubpage(getIdeaConceptId(concept))}
        title={getTitle(concept)}>
        {truncate(getTitle(concept), { length: 60 })}
      </Link>
      {expandState === 'loading' ? (
        <Spinner type="bubble" size="small" />
      ) : (
        <Icon
          type={expandState === 'open' ? 'angle-up' : 'angle-down'}
          onClick={() => onExpand(getIdeaConceptId(concept))}
          size={Size.lg}
        />
      )}
    </AlignedCellContent>
  ),
  expanded: (t, concept, maxWidth) => (
    <AlignedCellContent
      flexDirection="column"
      alignItems="flex-start"
      maxWidth={maxWidth}>
      <small>
        {t('ideaConcept/evaluation/resultDisplay/evaluators/listHeader', {
          context: getUserEvals(concept).length === 0 ? 'empty' : undefined
        })}
      </small>
      {getUserEvals(concept)
        .map(evaluation => evaluation.user)
        .map(user => {
          const name = `${user.firstName} ${user.lastName}`;
          return (
            <AlignedCellContent
              key={getUserId(user)}
              justifyContent="flex-start">
              <Avatar
                image={user.image}
                name={name}
                size={Size.xs}
                tooltipPosition={'right'}
                style={{ marginLeft: 10, marginRight: 5 }}
              />
              <Ellipsis>{name}</Ellipsis>
            </AlignedCellContent>
          );
        })}
    </AlignedCellContent>
  )
};

export default conceptTitle;
