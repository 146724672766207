import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Size } from '../../../constants';
import Avatar from '../../../common/components/Avatar';
import Icon from '../../../common/components/Icon';
import Media from '../../../common/components/Media';
import MutedText from '../../../common/components/MutedText';
import { NotificationType, NotificationAction } from '../../constants';
import { getUserAvatar } from '../../../common/getters/users';
import localiseUserName from '../../../utils/localiseUserName';
import { notificationType } from '../../types';

export class NotificationRow extends Component {
  static propTypes = {
    condensed: PropTypes.bool,
    notification: notificationType,
    onClick: PropTypes.func,
    t: PropTypes.func
  };

  handleClick = () => {
    const { onClick, notification } = this.props;
    onClick(notification);
  };

  render() {
    const { notification, t, className, condensed } = this.props;
    const {
      notificationId,
      linkedUser,
      createdAt,
      groupIdeationDateTime,
      title,
      ideaConceptId
    } = notification;
    const type = notification['@type'];
    const link = NotificationAction[type] || (() => null);
    const { iconType, color } = NotificationType[type];
    const userName = localiseUserName(t, linkedUser);
    return (
      <Media
        key={notificationId}
        mediaObject={
          <Avatar
            image={getUserAvatar(linkedUser)}
            name={userName}
            size={condensed ? Size.sm : Size.md}
          />
        }
        onClick={this.handleClick}
        to={link(notification)}
        className={className}
        active={!notification.read}>
        {t(`notifications/${notification['@type']}`, {
          name: userName,
          title,
          groupIdeationDateTime,
          ideaConceptId
        })}
        <br />
        <MutedText>
          <Icon className="h-align-middle" type={iconType} color={color} />{' '}
          <time dateTime={createdAt}>
            {t('notifications/{{createdAt,fromNow}}', { createdAt })}
          </time>
        </MutedText>
      </Media>
    );
  }
}

export default translate()(NotificationRow);
