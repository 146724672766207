import get from 'lodash/get';
import {
  CLOSE_INTRO_MESSAGE,
  VIEW_CHALLENGE_INFO,
  PAGINATE_IDEA_CARDS,
  SKIP_SUGGESTIONS
} from '../../ideaConcept/constants';
import { ADD_SUGGESTION_TO_IDEA } from '../../constants';
import EventTracker from './_EventTracker';

const EVENT_GROUP = 'challengeSuggestions';

export default class ChallengeSuggestionsTracker extends EventTracker {
  constructor(metrics) {
    super(metrics);
    this.eventGroup = EVENT_GROUP;
  }

  track(action, userId) {
    const { type } = action;
    const metrics = get(
      action,
      'metrics',
      get(action, ['meta', 'metrics'], {})
    );
    const commonData = {
      challengeId: get(metrics, 'challengeId'),
      userId
    };
    const metricsPage = get(metrics, 'page');

    if (type === ADD_SUGGESTION_TO_IDEA.SUCCESS) {
      this.coreTrack(ADD_SUGGESTION_TO_IDEA.SUCCESS, {
        ...commonData,
        suggestionLength: get(metrics, 'suggestionLength')
      });
    } else if (type === CLOSE_INTRO_MESSAGE) {
      this.coreTrack(type, commonData);
    } else if (type === PAGINATE_IDEA_CARDS && metricsPage === 'SUGGESTION') {
      this.coreTrack(type, {
        ...commonData,
        currentPage: get(metrics, 'currentPage'),
        page: metricsPage,
        pageCount: get(metrics, 'pageCount')
      });
    } else if (type === SKIP_SUGGESTIONS) {
      this.coreTrack(type, commonData);
    } else if (type === VIEW_CHALLENGE_INFO) {
      this.coreTrack(type, commonData);
    }
  }
}
