import { denormalize } from 'normalizr';
import {
  EDIT_IDEA_CONCEPT,
  FETCH_IDEA_CONCEPT_BY_ID,
  REVEAL_IDEACONCEPT_IDENTITY
} from '../../constants';
import { VOYAGER_API } from '../../api/constants';
import { CREATE_IDEA_CONCEPT, entities } from '../../ideaConcept/constants';
import schemas from '../../ideaConcept/schemas';
import { getConceptById } from '../../ideaConcept/selectors/ideaConcepts';
import { getEntities } from '../selectors/entities';
import createConceptPayload from '../../ideaConcept/utils/payloadUtils';
import addBreadcrumb from '../../telemetry/addBreadcrumb';
import logRequestFailure from '../../api/utils/logRequestFailure';
import { convertApiErrorToStatusCode } from '../../api/utils/apiError';

export const createIdeaConcept = payload => async dispatch => {
  const data = createConceptPayload(payload);
  addBreadcrumb('Creating idea concept...', {
    category: 'idea-concept',
    data: payload
  });

  try {
    return await dispatch({
      [VOYAGER_API]: {
        url: `/ideaconcepts`,
        method: 'POST',
        data,
        metrics: {},
        type: CREATE_IDEA_CONCEPT,
        mostRecent: true,
        schema: schemas.IDEA_CONCEPT,
        entityType: entities.IDEA_CONCEPT
      }
    });
  } catch (error) {
    logRequestFailure(error, 'Could not create idea concept', 'idea-concept');
    throw convertApiErrorToStatusCode(error);
  }
};

export const editIdeaConcept = (ideaConceptId, payload) => async (
  dispatch,
  getState
) => {
  const existingData = getConceptById(ideaConceptId)(getState());
  const denormalizedConcept = denormalize(
    existingData,
    schemas.IDEA_CONCEPT,
    getEntities(getState())
  );

  const sanitizedData = createConceptPayload({
    ...denormalizedConcept,
    ...payload
  });

  addBreadcrumb('Updating idea concept...', {
    category: 'idea-concept',
    data: sanitizedData
  });

  try {
    const req = await dispatch({
      [VOYAGER_API]: {
        url: `/ideaconcepts/${ideaConceptId}`,
        method: 'PUT',
        data: sanitizedData,
        type: EDIT_IDEA_CONCEPT,
        ideaConceptId
      },
      metrics: { ideaConceptId }
    });
    dispatch(fetchIdeaConceptById(ideaConceptId));
    return req;
  } catch (error) {
    logRequestFailure(error, 'Could not update idea concept', 'idea-concept');
    throw convertApiErrorToStatusCode(error);
  }
};

export const fetchIdeaConceptById = ideaConceptId => async dispatch => {
  addBreadcrumb('Fetching idea concept...', {
    category: 'idea-concept',
    data: ideaConceptId
  });
  try {
    return await dispatch({
      [VOYAGER_API]: {
        url: `/ideaconcepts/ideaconcept/${ideaConceptId}`,
        method: 'GET',
        type: FETCH_IDEA_CONCEPT_BY_ID,
        schema: schemas.IDEA_CONCEPT,
        entityType: entities.IDEA_CONCEPT,
        id: ideaConceptId
      }
    });
  } catch (error) {
    logRequestFailure(error, 'Could not fetch idea concept', 'idea-concept');
    throw convertApiErrorToStatusCode(error);
  }
};

export const revealIdentity = ideaConceptId => dispatch =>
  dispatch({
    [VOYAGER_API]: {
      url: `/ideaconcepts/reveal`,
      method: 'POST',
      data: [ideaConceptId],
      type: REVEAL_IDEACONCEPT_IDENTITY
    },
    metrics: {}
  }).then(() => {
    dispatch(fetchIdeaConceptById(ideaConceptId));
  });
