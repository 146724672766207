import flow from 'lodash/flow';
import forEachFp from 'lodash/fp/forEach';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { parseQueryString } from '../../utils';
import forceArray from '../../utils/forceArray';
import { isCepheidVariant } from '../../utils/variants';
import {
  conceptListFilterEnums,
  conceptListFilterKeys,
  IdeaConceptImplementationStatus,
  IdeaConceptImplementationStatusCepheid
} from '../constants';

const ERR = 'Concept filter query validation failed.';
const { STAGE, CONCEPT_TYPE, CHALLENGE, CATEGORY } = conceptListFilterKeys;
const conceptStages = isCepheidVariant()
  ? IdeaConceptImplementationStatusCepheid
  : IdeaConceptImplementationStatus;

/**
 * Validates elements of a query map, and throws if unknown keys or invalid values are used.
 * Sorting and pagination (both page- and cursor-based) params are ignored
 * @param {Object<string, string>} query - Query string or a map of query params
 * @returns {void} Returns `undefined` if the query is valid, or throws if it's invalid.
 */
export default function validateConceptFilterQuery(query) {
  let q;
  if (!query) {
    return;
  } else if (isString(query)) {
    q = parseQueryString(query);
  } else if (isPlainObject(query)) {
    q = query;
  } else {
    throw new Error(`query must be a string or an object, got: ${query}`);
  }

  Object.keys(q).forEach(key => {
    if (!values(conceptListFilterKeys).includes(key)) {
      throw new Error(`${ERR} Filter key is invalid: ${key}`);
    }
  });

  const validatorMap = {
    [STAGE]: validateFilter(val => values(conceptStages).includes(val)),
    [CONCEPT_TYPE]: validateFilter(val =>
      values(conceptListFilterEnums).includes(val)
    ),
    [CHALLENGE]: validateFilter(isString),
    [CATEGORY]: validateFilter(isString)
  };

  flow(
    toPairs,
    forEachFp(([key, val]) => validatorMap[key](key, val))
  )(q);
}

function validateFilter(validatorFn) {
  return (key, val) => {
    if (!val) {
      return;
    }

    const valueArr = forceArray(val);
    if (!valueArr.every(filterVal => validatorFn(filterVal))) {
      throw new Error(
        `${ERR} Filter value is invalid. Key: ${key}, value: ${val}`
      );
    }
  };
}
