import axios from 'axios';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import snakeCase from 'lodash/snakeCase';
import { normalize } from 'normalizr';
import AuthSvc from '../authentication/services/auth.service';
import { getToken } from '../authentication/services/token.service';
import { changeInternetStatus } from '../common/actions';
import { internetStatusSelector } from '../common/reducers';
import { getCurrentLanguage } from '../common/selectors/language';
import handleError from './handleError';
import { InternetStatus, FETCH_SCHEMA_DATA } from '../constants';
import { HTTP_TIMEOUT_MS } from '../api/constants';

function parsePage(rawPage) {
  const page = parseInt(rawPage, 10);
  return isNaN(page) ? -1 : page;
}

export default options => ({ getState, dispatch }) => {
  const { id, ...rest } = options;
  const api = axios.create({
    timeout: HTTP_TIMEOUT_MS,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true,
    ...rest
  });
  api.interceptors.request.use(languageRequestInterceptor);
  api.interceptors.request.use(tokenRequestInterceptor);
  api.interceptors.request.use(sortingRequestInterceptor);
  api.interceptors.request.use(paginationRequestInterceptor);
  api.interceptors.response.use(tokenResponseInterceptor);
  api.interceptors.response.use(
    successResponseInterceptor,
    errorResponseInterceptor
  );

  window.addEventListener('online', updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);

  function languageRequestInterceptor(config) {
    const language = getCurrentLanguage(getState());
    config.headers = {
      ...config.headers,
      'Accept-Language': `${language}`
    };
    return config;
  }

  function tokenRequestInterceptor(config) {
    const token = getToken();

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `${token}`
      };
    }
    return config;
  }

  function sortingRequestInterceptor(config) {
    const { sortBy, sortDirection } = config;
    if (sortBy && sortDirection) {
      config.headers = {
        ...config.headers,
        'X-SORT-BY': snakeCase(sortBy).toUpperCase(),
        'X-ORDER-DIRECTION': sortDirection
      };
    }
    return config;
  }

  function paginationRequestInterceptor(config) {
    const { page } = config;
    if (isNumber(page)) {
      config.headers = {
        ...config.headers,
        'X-PAGE': page
      };
    }
    return config;
  }

  function tokenResponseInterceptor(response) {
    const { headers = {} } = response;
    const token = headers['authorization'];
    if (token) {
      AuthSvc.handleNewToken(token);
    }
    return response;
  }

  function successResponseInterceptor(response) {
    if (internetStatusSelector(getState()) !== InternetStatus.CONNECTED) {
      dispatch(changeInternetStatus(InternetStatus.CONNECTED));
    }
    return response;
  }

  function errorResponseInterceptor(error) {
    if (error.code === 'ECONNABORTED' || !error.response) {
      dispatch(changeInternetStatus(InternetStatus.SERVER_UNAVAILABLE));
    } else if (
      internetStatusSelector(getState()) !== InternetStatus.CONNECTED
    ) {
      dispatch(changeInternetStatus(InternetStatus.CONNECTED));
    }

    return Promise.reject(error);
  }

  function updateOnlineStatus() {
    dispatch(
      changeInternetStatus(
        window.navigator.onLine
          ? InternetStatus.CONNECTED
          : InternetStatus.DISCONNECTED
      )
    );
  }

  return next => action => {
    const options = action[id];

    // it's not a api call, skip
    if (!options) {
      return next(action);
    }

    const { type, ...config } = options;
    let pendingActionCreator;
    let successActionCreator;
    let errorActionCreator;

    if (Array.isArray(type)) {
      if (type.length !== 3) {
        throw new Error(
          'type should contain exactly 3 action type and/or action creator'
        );
      }
      pendingActionCreator = createAction(type[0]);
      successActionCreator = createAction(type[1]);
      errorActionCreator = createAction(type[2]);
    } else {
      if (!type.PENDING || !type.SUCCESS || !type.ERROR) {
        throw new Error(
          'type should contain 3 action type: PENDING, SUCCESS, ERROR'
        );
      }
      pendingActionCreator = createAction(type.PENDING);
      successActionCreator = createAction(type.SUCCESS);
      errorActionCreator = createAction(type.ERROR);
    }

    dispatch(pendingActionCreator(null, config));

    if (options.schema) {
      dispatch({
        meta: {
          entityType: config.entityType,
          id: config.id
        },
        type: FETCH_SCHEMA_DATA.PENDING
      });
    }

    return api(config)
      .then(handleSuccess)
      .catch(handleFailure);

    function handleSuccess(response) {
      const { headers = {} } = response;
      const paging = {};
      let data = response.data;

      if (headers['x-current-page']) {
        Object.assign(paging, {
          currentPage: parsePage(headers['x-current-page']),
          nextPage: parsePage(headers['x-next-page']),
          prevPage: parsePage(headers['x-prev-page']),
          lastPage: parsePage(headers['x-last-page'])
        });
      }

      const meta = { ...config, ...paging };

      if (has(data, 'results')) {
        const { results, ...payloadMetaKeys } = data;

        data = results;
        Object.assign(meta, payloadMetaKeys);
      }

      if (options.schema) {
        if (!config.entityType) {
          throw new Error(
            `When adding schema to an action creator, the entityType string should also be added. Check the ${type.SUCCESS} action creator.`
          );
        }

        dispatch({
          type: FETCH_SCHEMA_DATA.SUCCESS,
          payload: normalize(data, options.schema),
          meta
        });
      }

      next(successActionCreator(data, meta));

      return response;
    }

    function handleFailure(error) {
      const { response = {} } = error;

      handleError(dispatch, getState, {
        status: response.status,
        resource: config.url,
        error
      });

      if (options.schema) {
        dispatch({
          meta: {
            entityType: config.entityType,
            id: config.id
          },
          payload: error,
          type: FETCH_SCHEMA_DATA.ERROR
        });
      }

      next(errorActionCreator(error, config));

      return Promise.reject(error);
    }

    function createAction(type) {
      if (isFunction(type)) {
        return type;
      }

      return function(payload, meta) {
        const finalAction = Object.assign({}, action, { type, payload, meta });
        delete finalAction[id];
        return finalAction;
      };
    }
  };
};
