import filterFp from 'lodash/fp/filter';
import forEach from 'lodash/forEach';
import flow from 'lodash/flow';
import keyByFp from 'lodash/fp/keyBy';
import mapFp from 'lodash/fp/map';
import negate from 'lodash/negate';
import omit from 'lodash/omit';
import produce from 'immer';
import without from 'lodash/without';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import {
  CANVAS_IDEAS_MERGED,
  CANVAS_IDEA_ADDED,
  CANVAS_IDEA_DELETED,
  CANVAS_IDEA_EDITED,
  STORE_IDEAS
} from '../actions/ideas';
import {
  getIdeaId,
  getIdeaText,
  getParentGroupId
} from '../../common/selectors/ideas';
import { commonIdProps } from '../../constants';
import { IDEAS_GROUPED, IDEAS_UNGROUPED } from '../actions/groups';
import { getMergedIdeas } from '../../dndCanvas/selectors/canvasIdea';
import { FLUSH_DATA } from '../actions/presence';
import { isConceptRef } from '../utils/inlineRefs';
import { DRAGGABLE_TYPES } from '../constants';

const isTopLevelIdea = negate(getParentGroupId);

const allIds = handleActions(
  {
    [STORE_IDEAS]: (_, { payload }) =>
      flow(
        filterFp(isTopLevelIdea),
        mapFp(getIdeaId)
      )(payload),
    [CANVAS_IDEA_ADDED]: (state, { payload }) => {
      const mergeChildIds = flow(
        getMergedIdeas,
        mapFp(getIdeaId)
      )(payload);
      const newState = without(state, ...mergeChildIds);
      if (isTopLevelIdea(payload)) {
        newState.push(getIdeaId(payload));
      }
      return newState;
    },
    [CANVAS_IDEA_DELETED]: (state, { meta }) => without(state, meta.id),
    [CANVAS_IDEAS_MERGED]: (state, { payload }) =>
      without(state, ...payload.mergedIdeaIds),
    [IDEAS_GROUPED]: (state, { payload }) => without(state, ...payload.itemIds),
    [IDEAS_UNGROUPED]: (state, { payload }) => state.concat(payload.itemIds),
    [FLUSH_DATA]: () => []
  },
  []
);

const byId = handleActions(
  {
    [STORE_IDEAS]: (_, { payload }) => {
      const state = keyByFp(commonIdProps.IDEA_ID)(payload);
      forEach(payload, idea => {
        // Annotate with exact type based on content
        const type = isConceptRef(getIdeaText(idea))
          ? DRAGGABLE_TYPES.IMPORTED_CONCEPT
          : DRAGGABLE_TYPES.IDEA;
        state[getIdeaId(idea)].type = type;
        // Collect already existing ideas that are merged into this idea
        forEach(idea.mergedIdeaIds, mergedIdea => {
          state[getIdeaId(mergedIdea)] = mergedIdea;
        });
      });
      return state;
    },
    [CANVAS_IDEA_ADDED]: (state, { payload }) =>
      produce(state, state => {
        const id = getIdeaId(payload);
        const type = isConceptRef(getIdeaText(payload))
          ? DRAGGABLE_TYPES.IMPORTED_CONCEPT
          : DRAGGABLE_TYPES.IDEA;
        state[id] = payload;
        state[id].type = type;
      }),
    [CANVAS_IDEA_EDITED]: (state, { payload, meta }) =>
      produce(state, state => {
        state[meta.id].text = payload;
      }),
    [CANVAS_IDEA_DELETED]: (state, { meta }) => omit(state, meta.id),
    [CANVAS_IDEAS_MERGED]: (state, { payload, meta }) => {
      const { id: parentId } = meta;
      const { ideaText, mergedIdeaIds } = payload;
      const mergedIdeas = mergedIdeaIds.map(id => state[id]);
      return produce(state, state => {
        state[parentId].text = ideaText;
        state[parentId].mergedIdeas = mergedIdeas;
      });
    },
    [IDEAS_GROUPED]: (state, { payload, meta }) => {
      const { id: groupId } = meta;
      const { itemIds } = payload;
      return produce(state, state => {
        itemIds.forEach((id, ix) => {
          if (state[id]) {
            state[id].groupId = groupId;
            state[id].groupIndex = ix;
          }
        });
      });
    },
    [IDEAS_UNGROUPED]: (state, { payload }) => {
      return produce(state, state => {
        payload.itemIds.forEach(id => {
          if (state[id]) {
            state[id].groupId = null;
            state[id].groupIndex = null;
          }
        });
      });
    },
    [FLUSH_DATA]: () => ({})
  },
  {}
);

export default combineReducers({
  allIds,
  byId
});
