import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { refType } from '../../types';

DropdownContent.propTypes = {
  bubble: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  className: PropTypes.string,
  forwardedRef: refType
};
function DropdownContent({
  bubble,
  children,
  className,
  forwardedRef,
  ...rest
}) {
  const baseClass = 'Dropdown__content';
  const classes = classNames(baseClass, className, {
    [`${baseClass}--bubble-${bubble}`]: bubble
  });
  return (
    <div {...rest} ref={forwardedRef} className={classes}>
      {children}
    </div>
  );
}

export default DropdownContent;
