import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { withResponsive } from '../Responsive';
import { isCookieConsentVisible } from '../../../utils/cookieConsent';
import './Navbar.css';

function Navbar({ children, right, fixed, className, layout, verticalOffset }) {
  const isStickyBottom = fixed === 'bottom';
  const navbarClasses = classNames(className, 'Navbar', `Navbar--${layout}`, {
    'Navbar--bottom': isStickyBottom
  });

  const navbarCenterClasses = classNames('Navbar__Center');
  const style =
    isStickyBottom && isCookieConsentVisible()
      ? { bottom: verticalOffset }
      : {};

  return (
    <div className={navbarClasses} style={style}>
      <ul className={navbarCenterClasses}>{children}</ul>
      {right && <ul className="Navbar__Right">{right}</ul>}
    </div>
  );
}

Navbar.propTypes = {
  children: PropTypes.node.isRequired,
  right: PropTypes.node,
  fixed: PropTypes.oneOf(['bottom']),
  layout: PropTypes.oneOf(['default', 'feed', 'login']),
  className: PropTypes.string
};

Navbar.defaultProps = {
  layout: 'default'
};

export { Navbar };
export default withResponsive(Navbar);
