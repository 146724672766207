import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import DayPicker, { MomentLocaleUtils } from '../DayPicker';
import {
  parseUTCISOString as parseDate,
  formatLocalDateToUTCISOString as formatDate
} from '../../../utils/date';
import TimeInput from '../TimeInput';
import './DateTimeRangePicker.css';

class DateTimeRangePicker extends Component {
  state = {
    isSelectingLastDay: false
  };

  today = moment().toDate();

  handleDayClick = (day, modifiers = {}) => {
    const { isSelectingLastDay } = this.state;
    const { value, onChange } = this.props;
    const [from] = value || [];
    const fromLocalDate = parseDate(from);

    if (modifiers.disabled) {
      return;
    }

    // Enable day selection on mouse enter
    if (!isSelectingLastDay) {
      this.setState(() => ({
        isSelectingLastDay: true
      }));
      onChange([formatDate(day), formatDate(day)]);
    }

    if (isSelectingLastDay && fromLocalDate && day < fromLocalDate) {
      // Reset the from-day if the clicked day is before the current from-day
      onChange([formatDate(day), null]);
    }

    if (isSelectingLastDay) {
      // Unset the day selection on mouse enter
      this.setState(() => ({
        isSelectingLastDay: false
      }));
    }
  };

  handleDayMouseEnter = day => {
    const { isSelectingLastDay } = this.state;
    const { value, onChange } = this.props;
    const [from] = value || [];
    const fromLocalDate = parseDate(from);

    if (!isSelectingLastDay || (fromLocalDate && day < fromLocalDate)) {
      return;
    }

    onChange([from, formatDate(day)]);
  };

  handleFromTimeChange = from => {
    const { value, onChange } = this.props;
    const [, to] = value || [];
    onChange([from, to]);
  };

  handleToTimeChange = to => {
    const { value, onChange } = this.props;
    const [from] = value || [];
    onChange([from, to]);
  };

  render() {
    const { value, onFocus, onBlur, locale, labels, closeButton } = this.props;
    const [from, to] = value || [];
    const fromLocalDate = parseDate(from);
    const toLocalDate = parseDate(to);
    const selectedDays = [];
    const now = moment()
      .local()
      .toDate();
    const disabledDays = {
      before: now
    };

    if (fromLocalDate) {
      selectedDays.push(selectedDays);
    }
    if (fromLocalDate && toLocalDate) {
      selectedDays.push({
        from: fromLocalDate,
        to: toLocalDate
      });
    }

    return (
      <div
        className="DateTimeRangePicker"
        tabIndex={0}
        onFocus={onFocus}
        onBlur={onBlur}>
        <DayPicker
          numberOfMonths={2}
          fromMonth={fromLocalDate}
          initialMonth={fromLocalDate || this.today}
          modifiers={{
            start: fromLocalDate,
            end: toLocalDate
          }}
          selectedDays={selectedDays}
          disabledDays={disabledDays}
          locale={locale}
          labels={labels}
          localeUtils={MomentLocaleUtils}
          onDayClick={this.handleDayClick}
          onDayMouseEnter={this.handleDayMouseEnter}
        />
        <div className="DateTimeRangePicker__Times">
          <TimeInput value={from} onChange={this.handleFromTimeChange} />
          <TimeInput value={to} onChange={this.handleToTimeChange} />
        </div>
        <div className="h-text-center">{closeButton}</div>
      </div>
    );
  }
}

DateTimeRangePicker.propTypes = {
  closeButton: PropTypes.element,
  locale: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};

DateTimeRangePicker.defaultProps = {
  locale: 'en',
  onChange: () => {}
};

export default DateTimeRangePicker;
