import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import paths from '../../paths';
import { eraseStores } from '../../../common/actions';
import AuthSvc from '../../services/auth.service';
import { isTokenValid, getTokenData } from '../../services/token.service';

export const AuthContext = createContext({});

export default function AuthProvider(props) {
  const { login, magicLogin, logout, signup } = AuthSvc;
  const dispatch = useDispatch();
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState(isTokenValid());
  const tokenData = getTokenData();
  const [userId, setUserId] = useState(tokenData.userId);
  const [userEmail, setUserEmail] = useState(tokenData.email);
  const [companyId, setCompanyId] = useState(tokenData.companyId);

  useEffect(
    function updateUserData() {
      setUserId(tokenData.userId);
      setUserEmail(tokenData.email);
      setCompanyId(tokenData.companyId);
    },
    [loggedIn, tokenData]
  );

  const loginUser = useCallback(
    credentials =>
      login(credentials)
        .then(() => {
          setLoggedIn(true);
        })
        .catch(error => {
          setLoggedIn(false);
          throw error;
        }),
    [login]
  );

  const handleMagicLogin = useCallback(
    code =>
      magicLogin(code)
        .then(() => {
          setLoggedIn(true);
        })
        .catch(error => {
          setLoggedIn(false);
          throw error;
        }),
    [magicLogin]
  );

  const logoutUser = useCallback(
    (forced = false) => {
      if (!forced) {
        history.push(paths.login());
        dispatch(eraseStores);
      }
      logout(forced);
      setLoggedIn(false);
    },
    [dispatch, history, logout]
  );

  const signupUser = useCallback(
    (formData, captchaToken, invitationCode) =>
      signup(formData, captchaToken, invitationCode)
        .then(() => {
          setLoggedIn(true);
        })
        .catch(error => {
          setLoggedIn(false);
          throw error;
        }),
    [signup]
  );

  const handlePlatformSwitch = useCallback(companyId => {
    AuthSvc.switchPlatform(companyId).then(({ token, subdomain }) => {
      if (window) {
        const { protocol, host } = window.location;
        window.location.href = `${protocol}//${host.replace(
          /^[^.]*/,
          subdomain
        )}/switch/${token}`;
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      {...props}
      value={{
        loggedIn,
        companyId,
        userId,
        userEmail,
        loginUser,
        magicLogin: handleMagicLogin,
        logoutUser,
        signupUser,
        switchPlatform: handlePlatformSwitch
      }}
    />
  );
}
