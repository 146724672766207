import React, { useCallback, useEffect, useState } from 'react';
import LocalStorage from 'store2';
import moment from 'moment';
import { translate, Trans } from 'react-i18next';
import AnnouncementBanner from '../AnnouncementBanner/AnnouncementBanner';
import Button from '@bit/be-novative.kit.button';
import Icon from '../Icon';

const STORE_KEY = 'ie_notice';

function IENotice({ t }) {
  const [show, setShow] = useState(false);
  useEffect(function showIfDismissedLongEnough() {
    const lastDismissTime = LocalStorage.get(STORE_KEY);
    if (
      !lastDismissTime ||
      (lastDismissTime && moment().diff(lastDismissTime, 'weeks') > 2)
    ) {
      setShow(true);
    }
  }, []);

  const handleDismiss = useCallback(function handleDismiss() {
    setShow(false);
    LocalStorage.set(STORE_KEY, Date.now(), true);
  }, []);

  return show ? (
    <AnnouncementBanner
      type="warning"
      text={
        <Trans i18nKey="ieBanner/info">
          <strong>text</strong>
          <a
            href="https://browsehappy.com/"
            rel="noreferrer noopener"
            target="_blank">
            link
          </a>
        </Trans>
      }
      action={
        <Button variant="minimal" onClick={handleDismiss}>
          <Icon type="cross" />
          <span>{t('ieBanner/dismissCTA')}</span>
        </Button>
      }
    />
  ) : null;
}

export default translate()(IENotice);
