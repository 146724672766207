import get from 'lodash/get';
import { combineActions, handleActions } from 'redux-actions';
import { REVEAL_IDEACONCEPT_IDENTITY } from '../../../constants';
import { dropById } from './commonMethods';

const getIdFromPayload = payload => get(payload, 'meta.data[0]');

const revealReducer = handleActions(
  {
    [REVEAL_IDEACONCEPT_IDENTITY.PENDING](state, payload) {
      return [...state, getIdFromPayload(payload)];
    },
    [combineActions(
      REVEAL_IDEACONCEPT_IDENTITY.SUCCESS,
      REVEAL_IDEACONCEPT_IDENTITY.ERROR
    )](state, payload) {
      return dropById(getIdFromPayload(payload))(state);
    }
  },
  []
);

export default {
  reducer: revealReducer,
  isRevealPendingSelector: stateSelector => conceptId => state =>
    stateSelector(state).includes(conceptId)
};
