import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import invariant from 'invariant';
import isPlainObject from 'lodash/isPlainObject';
import isNumber from 'lodash/isNumber';
import without from 'lodash/without';
import { connect } from 'react-redux';
import cn from 'classnames';
import { ONBOARDING_LAYER } from '../../../constants/e2e';
import Backdrop from '../../components/Backdrop';
import Popover from '../../components/Popover';
import { updateOnboarding, skipOnboarding } from '../../actions';
import { getDisplayName } from '../../../utils';
import OnboardingTipPopover from './OnboardingTipPopover';
import {
  isOnboardingTipActiveSelectorFactory,
  onboardingTipsSelector
} from '../../reducers';

const popoverModifiers = {
  preventOverflow: {
    boundariesElement: 'viewport'
  }
};

const popoverStyle = {
  width: 300
};

const onboardingHighlightCssClass = 'onboarding-highlight';

const withOnboardingTip = options => WrappedComponent => {
  invariant(isPlainObject(options), 'options must be an object');
  invariant(isNumber(options.tip), 'options.tip must be a number');
  const {
    tip,
    skipLabel = t => t('onboarding/Skip intro'),
    nextLabel = t => t('onboarding/Next tip'),
    finalLabel = t => t('onboarding/OK'),
    singleton = true,
    ...popoverProps
  } = options;
  const isTipActiveSelector = isOnboardingTipActiveSelectorFactory(tip);

  class WithOnboardingTip extends Component {
    static displayName = `withOnboardingTip(${getDisplayName(
      WrappedComponent
    )})`;

    static propTypes = {
      isTipActive: PropTypes.bool,
      onNextTip: PropTypes.func.isRequired,
      onSkipTips: PropTypes.func.isRequired,
      tips: PropTypes.arrayOf(PropTypes.number)
    };

    static defaultProps = {
      isTipActive: false,
      tips: []
    };

    static instances = [];

    constructor(props) {
      super(props);
      WithOnboardingTip.instances = [...WithOnboardingTip.instances, this];
    }

    componentWillUnmount() {
      const shouldUpdate = WithOnboardingTip.instances[0] === this;
      WithOnboardingTip.instances = without(WithOnboardingTip.instances, this);
      if (WithOnboardingTip.instances[0] && shouldUpdate) {
        WithOnboardingTip.instances[0].forceUpdate();
      }
    }

    handleSkipTips = () => {
      const { onSkipTips, tips } = this.props;
      onSkipTips(tips);
    };

    render() {
      const { isTipActive, onNextTip, onSkipTips, tips, ...rest } = this.props;
      const className = get(this, 'props.className');
      const wrappedClassName = isTipActive
        ? cn(className, onboardingHighlightCssClass)
        : className;
      const popover = (
        <OnboardingTipPopover
          skipLabel={skipLabel}
          nextLabel={nextLabel}
          finalLabel={finalLabel}
          onNextTip={onNextTip}
          onSkipTips={this.handleSkipTips}
          tip={tip}
          tips={tips}
        />
      );

      // allow a single instance only
      if (singleton && WithOnboardingTip.instances[0] !== this) {
        return <WrappedComponent {...rest} className={wrappedClassName} />;
      }

      return (
        <span>
          <Popover.PopoverTrigger
            show={isTipActive}
            popover={popover}
            popoverStyle={popoverStyle}
            popoverClassName={rest.className}
            popoverModifiers={popoverModifiers}
            {...popoverProps}>
            <WrappedComponent {...rest} className={wrappedClassName} />
          </Popover.PopoverTrigger>
          <Backdrop visible={isTipActive} data-test={ONBOARDING_LAYER} />
        </span>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      isTipActive: isTipActiveSelector(state),
      tips: onboardingTipsSelector(state)
    };
  }

  const mapDispatchToProps = {
    onNextTip: () => updateOnboarding(tip),
    onSkipTips: () => skipOnboarding()
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithOnboardingTip);
};

export default withOnboardingTip;
