import get from 'lodash/get';
import AuthSvc from '../../authentication/services/auth.service';

export default function storeToken(response) {
  const token = get(response, ['headers', 'authorization']);
  if (token) {
    AuthSvc.handleNewToken(token);
  }

  return response;
}
