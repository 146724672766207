import cloneDeep from 'lodash/cloneDeep';
import overEvery from 'lodash/overEvery';
import flow from 'lodash/flow';
import get from 'lodash/fp/get';
import has from 'lodash/fp/has';
import isEqual from 'lodash/fp/isEqual';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';
import negate from 'lodash/negate';
import mapValues from 'lodash/fp/mapValues';
import omit from 'lodash/fp/omit';
import pick from 'lodash/fp/pick';
import startsWith from 'lodash/fp/startsWith';
import {
  BLACKLISTED_ACTIONS,
  BLACKLISTED_NS,
  SCHEMA_DEF,
  WHITELISTED_PROFILE_PROPS,
  NORMALISED_RESULT
} from './constants';
import { FETCH_SCHEMA_DATA } from '../../constants';

const getType = get('type');

// predicates

const hasSchema = has(SCHEMA_DEF);

const typeMatches = target =>
  flow(
    getType,
    isEqual(target)
  );

const isFetchAction = flow(
  getType,
  startsWith('FETCH')
);

const hasNormalisedPayload = overEvery([isFetchAction, hasSchema]);

const isSchemaStoreAction = flow(
  getType,
  isEqual(FETCH_SCHEMA_DATA.SUCCESS)
);

const isFetchActionWithSchema = overEvery(
  hasNormalisedPayload,
  negate(isSchemaStoreAction)
);

const isOrdinaryFetchAction = action =>
  isFetchAction(action) && !hasNormalisedPayload(action);

const isIgnored = action => {
  const type = getType(action);
  const isBlackListed = BLACKLISTED_ACTIONS.includes(type);
  const hasIgnoredNamespace = BLACKLISTED_NS.some(ns => startsWith(ns)(type));

  return (
    isBlackListed || hasIgnoredNamespace || isFetchActionWithSchema(action)
  );
};

// transformers

const ignorePayload = action => ({
  ...cloneDeep(action),
  payload: { payloadIgnored: true }
});

const ignoreSchemaDefinition = action => ({
  ...cloneDeep(action),
  meta: { ...omit(SCHEMA_DEF)(action.meta) }
});

const scrubModal = pick(['type', 'payload.modalType']);

const scrubProfile = pick(WHITELISTED_PROFILE_PROPS);

const omitEntities = omit(['byId']);

const scrubEntityObjects = action => ({
  ...cloneDeep(action),
  payload: get(NORMALISED_RESULT)(action)
});

const handleOrdinaryFetchPayload = action => {
  if (isObject(action.payload)) {
    return {
      ...cloneDeep(action),
      payload: keys(action.payload)
    };
  }

  return ignorePayload(action);
};

const handleNormalisedPayload = action =>
  flow(
    ignoreSchemaDefinition,
    scrubEntityObjects
  )(action);

const flattenEntityKeys = mapValues(keys);

export {
  flattenEntityKeys,
  handleNormalisedPayload,
  handleOrdinaryFetchPayload,
  ignorePayload,
  isIgnored,
  isOrdinaryFetchAction,
  isSchemaStoreAction,
  omitEntities,
  typeMatches,
  scrubModal,
  scrubProfile
};
