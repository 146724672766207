import { combineReducers } from 'redux';
import keyBy from 'lodash/fp/keyBy';
import map from 'lodash/map';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import { set } from 'dot-prop-immutable';
import { handleActions } from 'redux-actions';
import { commonIdProps } from '../../constants';
import { IMAGE_RECEIVED, IMAGE_DELETED, STORE_IMAGES } from '../actions/images';
import { getImageId } from '../selectors/images';
import { FLUSH_DATA } from '../actions/presence';

const allIds = handleActions(
  {
    [STORE_IMAGES]: (_, { payload }) => map(payload, getImageId),
    [IMAGE_RECEIVED]: (state, { payload }) =>
      uniq([...state, getImageId(payload)]),
    [IMAGE_DELETED]: (state, { payload }) => without(state, payload),
    [FLUSH_DATA]: () => []
  },
  []
);

const byId = handleActions(
  {
    [STORE_IMAGES]: (_, { payload }) => keyBy(commonIdProps.IMAGE_ID)(payload),
    [IMAGE_RECEIVED]: (state, { payload }) =>
      set(state, getImageId(payload), payload),
    [IMAGE_DELETED]: (state, { payload }) => omit(state, payload),
    [FLUSH_DATA]: () => ({})
  },
  {}
);

export default combineReducers({
  allIds,
  byId
});
