import isString from 'lodash/isString';
import range from 'lodash/range';
import sample from 'lodash/sample';
import invariant from 'invariant';

export function getInitials(name, limit = 3) {
  invariant(isString(name), 'name should be a string, got ' + typeof name);
  return name
    .split(' ')
    .map(str => str[0])
    .join('')
    .substr(0, limit);
}

export function convertToRandomBackgroundId(str, maxValue = 10) {
  if (!isString(str)) {
    return sample(range(1, 10));
  }

  const charCodeHash = str
    .split('')
    .map((_, ix) => str.charCodeAt(ix))
    .map(num => num << 5)
    .map(num => (num % maxValue) + 1)
    .reduce((diff, num) => Math.abs(diff - num), 0);
  return maxValue < charCodeHash ? charCodeHash % maxValue : charCodeHash;
}
