import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Severity } from '../constants';
export const AZURE_ROLE_NAME = 'app-web';

export const MonitoringSeverity = {
  [Severity.Verbose]: SeverityLevel.Verbose,
  [Severity.Info]: SeverityLevel.Information,
  [Severity.Warning]: SeverityLevel.Warning,
  [Severity.Error]: SeverityLevel.Error,
  [Severity.Critical]: SeverityLevel.Critical
};
