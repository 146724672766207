import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { translate } from 'react-i18next';
import Button from '@bit/be-novative.kit.button';
import Callout from '../../components/Callout';
import signupApi from '../../../authentication/api/signupApi';
import { getToken } from '../../../authentication/services/token.service';
import { sendAlert } from '../../actions/alert';

export function EmailConfirmationCallout({ t }) {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleRequestEmail = useCallback(() => {
    setSubmitting(true);
    signupApi
      .resendAccountVerificationEmail(getToken())
      .then(() => {
        setSuccess(true);
        dispatch(
          sendAlert({
            kind: 'success',
            message: t('authentication/accountConfirm/success')
          })
        );
      })
      .catch(() => {
        dispatch(
          sendAlert({
            kind: 'danger',
            message: t('authentication/accountConfirm/error')
          })
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [dispatch, t]);

  return (
    <Callout type="success" spacing={1}>
      {t('authentication/accountConfirm/description')}{' '}
      {!success && (
        <Button
          onClick={handleRequestEmail}
          loading={submitting}
          variant="link-inverse">
          {t('authentication/accountConfirm/resendCTA')}
        </Button>
      )}
    </Callout>
  );
}

export default translate()(EmailConfirmationCallout);
