import clamp from 'lodash/clamp';
import flow from 'lodash/flow';
import round from 'lodash/round';

/**
 * Normalise an item's position data from incoming socket message.
 * @param {Object} message - Incoming socket message
 * @param {ItemPositionInAPI} message.position - An item's canonical position on the canvas received from the API
 * @param {RestOfArgs} message.item - Rest of item props
 * @return {ItemWithNormalisedPosition} Item with normalised position data
 */

export const mapPositionFromMsg = ({ position, ...item }) => {
  return {
    ...item,
    ...normaliseApiPosition(position)
  };
};

/**
 * Rounds position data to whole numbers, and forces them between 0-100.
 * @param {{ x: number, y: number}} positionObj
 * @returns {{ posX: number, posY: number }}
 */
export const normaliseApiPosition = positionObj => {
  const roundAndClamp = flow(
    num => round(num, 0),
    num => clamp(num, 0, 100)
  );
  return {
    posX: roundAndClamp(positionObj.x),
    posY: roundAndClamp(positionObj.y)
  };
};

/**
 * Normalise user's temporary ID from incoming socket message.
 * @param {Object} message - Incoming socket message
 * @param {string} message.roomSpecificUserId - User's temporary ID during the ideation session. Obscures real user ID for the sake of anonimity.
 * @param {RestOfArgs} message.item - Rest of item props
 * @return {ItemWithNormalisedUserId} Item with normalised owner ID
 */

export const mapOwnerIdFromMsg = ({ roomSpecificUserId, ...item }) => ({
  ...item,
  owner: roomSpecificUserId
});

/**
 * Convert item position data for API
 * @param {Object} position - Item position data as represented by the UI
 * @param {number} position.posX - X coordinate of item
 * @param {number} position.posY - Y coordinate of item
 * @param {number} [position.posZ] - ***DEPRECATED*** `z-index` of item
 * @return {ItemPositionInAPI} Converted position data
 */
export const mapPositionToMsg = ({ posX, posY, posZ }) => ({
  x: posX,
  y: posY,
  z: posZ
});
