import { CommonException } from '../api/constants';

// TODO convert to hook after i18n upgrade
/**
 * Returns a translation for API-related error codes
 * @param {Function} t - The translator method
 * @param {CommonException} error - The internal error code
 * @param {string} [fallbackMessage] - If provided, the fallback message will be returned in case of a general/uncrecognised error
 * @return {string} The localised error message
 * @todo Omit translator method injection after i18n engine upgrade
 */
export default function translateApiErrorMessage(t, error, fallbackMessage) {
  let tKey = 'common/error/';

  if (error === CommonException.NotFound) {
    tKey += 'missing';
  } else if (error === CommonException.ServerError) {
    tKey += 'server';
  } else if (error === CommonException.NetworkError) {
    tKey += 'network';
  } else if (error === CommonException.Timeout) {
    tKey += 'timeout';
  } else if (fallbackMessage) {
    return fallbackMessage;
  } else {
    tKey += 'general';
  }

  return t(tKey);
}
