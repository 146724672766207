import { schema } from 'normalizr';
import { entities, idProps } from './constants';
import commonSchemas from '../schemas';

const attachmentSchema = new schema.Entity(
  entities.ATTACHMENT,
  {},
  {
    idAttribute: idProps.ATTACHMENT_ID
  }
);

const suggestionSchema = new schema.Entity(
  entities.SUGGESTION,
  {
    creator: commonSchemas.USER
  },
  {
    idAttribute: idProps.SUGGESTION_ID
  }
);

const ideaConceptSchema = new schema.Entity(
  entities.IDEA_CONCEPT,
  {
    attachments: [attachmentSchema],
    category: commonSchemas.CATEGORY,
    creator: commonSchemas.USER,
    evaluators: commonSchemas.USER_ARRAY,
    owner: commonSchemas.USER,
    suggestions: [suggestionSchema],
    team: commonSchemas.INVITED_USER_ARRAY
  },
  {
    idAttribute: idProps.IDEA_CONCEPT_ID
  }
);

export default {
  ATTACHMENT: attachmentSchema,
  ATTACHMENT_ARRAY: [attachmentSchema],
  IDEA_CONCEPT: ideaConceptSchema,
  IDEA_CONCEPT_ARRAY: [ideaConceptSchema],
  SUGGESTION: suggestionSchema,
  SUGGESTION_ARRAY: [suggestionSchema]
};
