import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import size from 'lodash/size';
import invariant from 'invariant';
import { handleActions, combineActions } from 'redux-actions';
import forceArray from '../../../utils/forceArray';

const paginationInitialState = {
  currentPage: 0,
  nextPage: -1,
  prevPage: -1,
  lastPage: -1
};

export const getInfiniteScrollData = action => {
  const { payload, meta } = action;
  const start = get(meta, 'start', 0);
  const limit = get(meta, 'limit');
  const collectionSize = size(payload);
  let nextStart = start;

  if (collectionSize > 0) {
    nextStart += collectionSize || limit;
  }

  return {
    start,
    limit,
    nextStart
  };
};

export const getPagerData = action => {
  const { meta } = action;

  return {
    currentPage: get(meta, 'currentPage', 0),
    nextPage: get(meta, 'nextPage', -1),
    prevPage: get(meta, 'prevPage', -1),
    lastPage: get(meta, 'lastPage', -1)
  };
};

const NO_ACTION = Symbol('empty');

/**
 * @typedef {Object} PaginationReducerActionArgs
 * @property {(Array|string)} [successActions] - Action(s) that contain updated pagination data
 * @property {(Array|string)} [flushActions] - Action(s) that trigger the reducer to reset to the first page
 */

const ACTION_DEFAULTS = {
  successActions: [NO_ACTION],
  flushActions: [NO_ACTION]
};

/**
 * @typedef {Object} PaginationReducerConfig
 * @property {number} [currentPage] - The initial page index for pagination reducers
 * @property {number} [start] - The initial start position for infinite-scroll reducers
 * @property {number} [limit] - The page size for infinite-scroll reducers
 * @property {boolean} [cursorBased] - Whether the generated reducer function is used for infinite scroll. Defaults to `true`.
 */

const CONFIG_DEFAULTS = {
  currentPage: 0,
  start: 0,
  limit: 0,
  cursorBased: true
};

/**
 * Generates a reducer function for storing pagination or infinite-scroll data
 * @param {PaginationReducerActionArgs} actionTypes - One or more Redux actions that trigger the generated reducer function to store or drop pagination data
 * @param {PaginationReducerConfig} [options] - Reducer configuration
 * @returns {Function} The generated reducer function
 */

const paginationReducerFactory = (
  actionTypes = ACTION_DEFAULTS,
  options = CONFIG_DEFAULTS
) => {
  const actions = { ...ACTION_DEFAULTS, ...actionTypes };
  const config = { ...CONFIG_DEFAULTS, ...options };
  const { currentPage, start, limit, cursorBased } = config;

  if (cursorBased) {
    invariant(isNumber(start), 'Default `start` value must be an integer');
    invariant(
      isNumber(limit) && limit > 0,
      'Default `limit` value must be a positive integer'
    );
  } else {
    invariant(
      isNumber(currentPage),
      'Default `currentPage` value must be an integer'
    );
  }

  const defaultState = cursorBased
    ? { start, nextStart: start, limit }
    : { ...paginationInitialState, currentPage };

  return handleActions(
    {
      [combineActions(...forceArray(actions.successActions))](
        _,
        currentAction
      ) {
        return cursorBased
          ? getInfiniteScrollData(currentAction)
          : getPagerData(currentAction);
      },
      [combineActions(...forceArray(actions.flushActions))](state) {
        return cursorBased
          ? { ...state, start: 0, nextStart: 0 }
          : { ...state, currentPage: 0, nextPage: 0, prevPage: -1 };
      }
    },
    defaultState
  );
};

export default paginationReducerFactory;
