import hasFp from 'lodash/fp/has';
import includes from 'lodash/includes';
import memo from 'lodash/memoize';
import isPlainObject from 'lodash/isPlainObject';
import { conceptEvalCriteriaDefaults, EMPTY_NUMBER } from '../constants';

/**
 * Converts the object map of criterion IDs and score values to an array of objects for the API
 * @param {Object<IdeaConceptEvaluationCriterion.criterionId, number>} mapOfValues
 * @returns {IdeaConceptEvaluationScore[]}
 */

export const mapEvaluationModelToPayload = mapOfValues => {
  if (!isPlainObject(mapOfValues)) {
    throw new Error(`Expected plain object, got ${typeof mapOfValues}`);
  }

  return Object.keys(mapOfValues).reduce(
    (evalArray, evalKey) => [
      ...evalArray,
      {
        criterionId: evalKey,
        value: mapOfValues[evalKey]
      }
    ],
    []
  );
};

const isDefaultInputLabel = memo(label =>
  includes(conceptEvalCriteriaDefaults, label)
);

/**
 * Checks if criterion labels are custom values. If so, they are not translated. If labels are default system values, they are translated.
 * @param {Function} t - The translator function
 * @returns {Function<string>} - Curried function that performs the optional translation
 */

export const maybeTranslateCriterionLabel = t => label =>
  isDefaultInputLabel(label)
    ? t(`ideaConcept/evaluation/form/fields/${label}/label`)
    : label;

/**
 * Generates config props for the concept evaluation sliders, based on the intended form behaviour.
 * @param {import('../config').IdeaConceptEvaluationFormConfig} formConfig
 * @returns {SliderConfig}
 */

export const mapFormConfigToSliderProps = formConfig => {
  const defaultMarkers = Array.from(
    Array(formConfig.CRITERION_MAX).keys()
  ).reduce(
    (markers, num) => {
      markers[num + 1] = { label: (num + 1).toString() };
      return markers;
    },
    {
      0: { label: EMPTY_NUMBER }
    }
  );

  if (formConfig.markers && !formConfig.markers.every(hasFp('label'))) {
    throw new Error('formConfig.markers array items must have a label prop');
  }

  return {
    min: formConfig.CRITERION_MIN,
    max: formConfig.CRITERION_MAX,
    step: formConfig.CRITERION_STEP,
    markers: formConfig.markers || defaultMarkers
  };
};
