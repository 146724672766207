import React from 'react';
import { connect } from 'react-redux';
import { getDisplayName } from '../../../utils';

const renderOnCondition = (condition, component = null) => WrappedComponent => {
  function RenderOnCondition({ isVisible, ...rest }) {
    return isVisible ? <WrappedComponent {...rest} /> : component;
  }

  function mapStateToProps(state) {
    return {
      isVisible: condition(state)
    };
  }

  RenderOnCondition.dispayname = `renderOnCondition(${getDisplayName(
    WrappedComponent
  )})`;

  return connect(mapStateToProps)(RenderOnCondition);
};

export default renderOnCondition;
