import PropTypes from 'prop-types';
import React, { Component, cloneElement } from 'react';
import classNames from 'classnames';
import Popover from '../Popover';
import Icon from '../Icon';
import Button from '@bit/be-novative.kit.button';
import {
  formatLocalDateToUTCISOString as formatDate,
  formatUTCISOStringToLocalDate as formatLocal,
  parseLocalDateString as parseLocal
} from '../../../utils/date';
import './DateTimeRangeInput.css';

class DateTimeRangeInput extends Component {
  static propTypes = {
    picker: PropTypes.node.isRequired,
    fromPlaceholder: PropTypes.string,
    toPlaceholder: PropTypes.string,
    input: PropTypes.shape({
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ])
    }),
    meta: PropTypes.shape({
      active: PropTypes.bool
    })
  };

  constructor(props) {
    super(props);
    const { value = [] } = props.input;
    const [from, to] = value;
    this.state = {
      fromInputValue: formatLocal(from),
      toInputValue: formatLocal(to),
      open: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value: nextValue } = nextProps.input;
    const { value: prevValue } = this.props.input;

    if (!Array.isArray(nextValue)) {
      this.setState(() => ({
        fromInputValue: '',
        toInputValue: ''
      }));
    } else if (nextValue !== prevValue) {
      const [from, to] = nextValue;
      this.setState(() => ({
        fromInputValue: formatLocal(from),
        toInputValue: formatLocal(to)
      }));
    }

    if (
      this.props.meta.active !== nextProps.meta.active &&
      nextProps.meta.active &&
      !this.state.open
    ) {
      this.setState(() => ({
        open: nextProps.meta.active
      }));
    }
  }

  setFromInputRef = el => {
    this.fromInput = el;
  };

  setToInputRef = el => {
    this.toInput = el;
  };

  handleFromInputChange = e => {
    const { onChange, value = [] } = this.props.input;
    const [, to] = value;
    const fromLocalDate = parseLocal(e.target.value);

    if (fromLocalDate) {
      onChange([formatDate(fromLocalDate), to]);
    } else {
      onChange(null);
    }

    this.setState({
      fromInputValue: e.target.value
    });
  };

  handleToInputChange = e => {
    const { onChange, value = [] } = this.props.input;
    const [from] = value;
    const toLocalDate = parseLocal(e.target.value);

    if (toLocalDate) {
      onChange([from, formatDate(toLocalDate)]);
    } else {
      onChange(null);
    }

    this.setState({
      toInputValue: e.target.value
    });
  };

  handleBlur = e => {
    const { onBlur, value } = this.props.input;
    onBlur(value);
  };

  handleClose = () => {
    this.setState(() => ({
      open: false
    }));
  };

  handleIconClick = () => {
    if (this.fromInput) {
      this.fromInput.focus();
    }
  };

  render() {
    const { input, meta, picker, fromPlaceholder, toPlaceholder } = this.props;
    const { value, onChange, onFocus } = input;
    const { active, touched, error, warning, submitFailed, submitting } = meta;
    const { fromInputValue, toInputValue, open } = this.state;

    const popover = cloneElement(picker, {
      ref: el => (this.picker = el),
      onBlur: this.handleBlur,
      value,
      onChange,
      onFocus,
      closeButton: picker.props.closeButton
        ? cloneElement(picker.props.closeButton, {
            onClick: this.handleClose
          })
        : null
    });

    const classes = classNames('DateTimeRangeInput', {
      'DateTimeRangeInput--error':
        (touched || submitFailed || submitting) && error,
      'DateTimeRangeInput--warning':
        (touched || submitFailed || submitting) && warning,
      'DateTimeRangeInput--active': active
    });

    return (
      <Popover.PopoverTrigger
        show={open}
        popoverStyle={{ position: 'fixed' }}
        popover={popover}
        onClose={this.handleClose}
        closeable={true}>
        <div className={classes}>
          <input
            type="text"
            ref={this.setFromInputRef}
            value={fromInputValue || ''}
            onChange={this.handleFromInputChange}
            onFocus={onFocus}
            onBlur={this.handleBlur}
            placeholder={fromPlaceholder}
            className="DateTimeRangeInput__Input"
          />
          <span className="DateTimeRangeInput__Separator"> &ndash; </span>
          <input
            type="text"
            ref={this.setToInputRef}
            value={toInputValue || ''}
            onChange={this.handleToInputChange}
            onFocus={onFocus}
            onBlur={this.handleBlur}
            placeholder={toPlaceholder}
            className="DateTimeRangeInput__Input"
          />
          <div className="DateTimeRangeInput__Icon">
            <Button variant="link-secondary" onClick={this.handleIconClick}>
              <Icon type="date" size="lg" />
            </Button>
          </div>
        </div>
      </Popover.PopoverTrigger>
    );
  }
}

export default DateTimeRangeInput;
