import flow from 'lodash/flow';
import { omitEntities } from '../../telemetry/errorLogging/payloadUtils';
import { rootSelector } from '../selectors';
import { setIfFp } from '../../utils/setIf';

const scrubStore = state =>
  flow(
    rootSelector,
    setIfFp('ideas', omitEntities),
    setIfFp('images', omitEntities),
    setIfFp('users', omitEntities),
    setIfFp('words', omitEntities)
  )(state);

export default scrubStore;
