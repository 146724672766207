import React, { useEffect, useState } from 'react';
import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import PropTypes from 'prop-types';
import Logo from '@bit/be-novative.kit.logo';
import { useSelector } from 'react-redux';
import { platformDataSelector } from '../../state/platform';
import { getPlatformName } from '../../getters/platform';
import { getUrl } from '../../selectors/images';

PlatformLogo.propTypes = {
  fluid: PropTypes.bool,
  compact: PropTypes.bool
};
function PlatformLogo(props) {
  const platformData = useSelector(platformDataSelector);
  const platformName = getPlatformName(platformData);
  const customLogoUrl = flow(
    getFp('image'),
    getUrl
  )(platformData);

  const [hasImg, setHasImg] = useState(false);
  const [hasTriedLoading, setHasTriedLoading] = useState(false);

  useEffect(
    function downloadPlatformLogo() {
      const logoImg = new Image();
      logoImg.onload = () => {
        setHasImg(true);
        setHasTriedLoading(true);
      };
      logoImg.onerror = () => setHasTriedLoading(true);
      logoImg.src = customLogoUrl;
    },
    [customLogoUrl]
  );

  const imgUrl = hasImg ? customLogoUrl : null;
  const showLogo = !customLogoUrl || hasTriedLoading;

  return (
    <Logo
      {...props}
      url={imgUrl}
      name={hasImg ? platformName : null}
      style={{ opacity: Number(showLogo) }}
    />
  );
}

export default PlatformLogo;
