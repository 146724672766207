import { useSelector } from 'react-redux';
import {
  isAdminSelector,
  ownProfileSelector
} from '../../../profile/state/profile';
import useIdeaConcept from '../../hooks/useIdeaConcept';
import { getTeam } from '../../selectors/ideaConcepts';

/**
 * Checks whether the user can access the feedback belonging to the idea concept.
 * @param {string|number} conceptId - Idea concept UUID
 * @returns {boolean} `true` if the user is either a concept team member or a platform admin
 */
export default function useCanShowFeedback(conceptId) {
  const { userId } = useSelector(ownProfileSelector);
  const isAdmin = useSelector(isAdminSelector);
  const [concept] = useIdeaConcept(conceptId);
  const isTeamMember = getTeam(concept).includes(userId);
  return isAdmin || isTeamMember;
}
