import { useCallback, useMemo, useState } from 'react';

export default function useImageFetch(resource) {
  if (typeof resource.fetch !== 'function') {
    throw new Error('resource.fetch must be a function');
  }
  const [images, setImages] = useState([]);
  const [pageIx, setPageIx] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const handleFetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setHasError(false);
      const result = await resource.fetch(pageIx);
      setImages(prevImages => prevImages.concat(result));
      setIsLoading(false);
      setPageIx(ix => ix + 1);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
    }
  }, [pageIx, resource]);

  return useMemo(
    () => ({
      images,
      pageIx,
      isLoading,
      hasError,
      fetch: handleFetch,
      reset: () => setImages([])
    }),
    [images, pageIx, isLoading, hasError, handleFetch]
  );
}
