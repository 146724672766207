import get from 'lodash/get';
import { getPlatformName } from '../utils/platform';

const DEFAULT_PROFILE_FORM_SETTINGS = {
  requirePrivacyConsent: false
};

const EXCEPTIONS = {
  'mvm-csoport': {
    requirePrivacyConsent: true
  },
  team: {
    requirePrivacyConsent: true
  }
};

const platform = getPlatformName();

export function getProfileFormSettings() {
  return get(EXCEPTIONS, platform, DEFAULT_PROFILE_FORM_SETTINGS);
}
