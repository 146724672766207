import React from 'react';
import isFinite from 'lodash/isFinite';
import cn from 'classnames';
import { Size } from '../../../constants';
import Counter from '../Counter';
import scss from './Badge.module.scss';

/**
 * Used for the `origin` prop that controls the badge position relative to the wrapped element
 * @enum {string}
 * @readonly
 */
const BadgeOrigin = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom'
};

/**
 * Allows adjusting badge position to fit the child's shape
 * @enum {string}
 * @readonly
 */
const BadgeChildShape = {
  circle: 'circle',
  rectangle: 'rectangle'
};

function Badge({
  ariaLabel,
  ariaRole,
  badgeClass,
  children,
  className,
  value,
  max = 99,
  dot,
  origin = { horizontal: BadgeOrigin.right, vertical: BadgeOrigin.top },
  shape = BadgeChildShape.circle,
  size = Size.md,
  variant,
  visible
}) {
  const commonClasses = cn(
    scss.Badge,
    scss[`shape-${shape}`],
    scss[origin.horizontal],
    scss[origin.vertical],
    badgeClass,
    {
      [scss.visible]: visible
    }
  );
  const badgeClasses = cn(
    commonClasses,
    scss.SimpleBadge,
    scss[`size-${size}`],
    {
      [scss.dot]: dot
    }
  );

  const isComponent = React.isValidElement(value);
  const showBadgeContent = visible && !dot;
  const isNumberBadge = isFinite(parseInt(value));

  return (
    <div className={cn(scss.Wrapper, className)}>
      {children}
      {isComponent ? (
        <div
          className={commonClasses}
          aria-label={ariaLabel}
          aria-hidden={!visible}
          role={ariaRole}>
          {visible ? value : null}
        </div>
      ) : (
        <span
          aria-label={ariaLabel}
          aria-hidden={!visible}
          role={ariaRole}
          className={badgeClasses}
          style={{ background: variant, color: variant ? 'white' : undefined }}>
          {showBadgeContent && isNumberBadge && (
            <Counter max={max}>{value}</Counter>
          )}
          {showBadgeContent && !isNumberBadge && value}
        </span>
      )}
    </div>
  );
}

export default Badge;
export { BadgeOrigin, BadgeChildShape };
