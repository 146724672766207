import {
  APP_INIT,
  SET_USERAGENT_LANGUAGE,
  SUCCESS_LANGUAGE_CHANGE
} from '../constants';
import {
  detectLanguage,
  determineUiLanguage,
  getLanguageShortCode
} from '../utils/locale';
import {
  getPreferredLanguage,
  persistPreferredLanguage
} from '../utils/storage';
import { ERASE_STORES } from '../common/actions';
import {
  getBrowserLanguage,
  getCurrentLanguage
} from '../common/selectors/language';

export default function localeMiddleware({ dispatch, getState }) {
  return next => action => {
    if (action.type === APP_INIT) {
      const preferredLanguage = getPreferredLanguage();
      const browserLanguage = getLanguageShortCode(detectLanguage());
      const uiLanguage = determineUiLanguage(
        preferredLanguage,
        browserLanguage
      );

      dispatch({
        type: SET_USERAGENT_LANGUAGE,
        payload: browserLanguage
      });

      dispatch({
        type: SUCCESS_LANGUAGE_CHANGE,
        payload: uiLanguage
      });

      persistPreferredLanguage(uiLanguage);
    }

    if (action.type === ERASE_STORES) {
      const browserLanguage = getBrowserLanguage(getState());
      const uiLanguage = getCurrentLanguage(getState());

      next(action);
      dispatch({
        type: SET_USERAGENT_LANGUAGE,
        payload: browserLanguage
      });
      dispatch({
        type: SUCCESS_LANGUAGE_CHANGE,
        payload: uiLanguage
      });
      return;
    }

    next(action);
  };
}
