import { SOCKET_ID } from '../api/constants';

// Config

export const MOVE_THROTTLE_MS = 500;
export const MAX_ITEMS_IN_ROOM = 20;

// Definitions

export const SMALL_HEIGHT_BREAKPOINT = 640;

export const ideationIdProps = {
  IMAGE_ID: 'imageId',
  WORD_ID: 'wordId'
};

// TODO migrate consumers to new enum
export const DRAGGABLE_TYPES = {
  IDEA: 'IDEA',
  IMAGE: 'IMAGE',
  WORD: 'WORD',
  // internal to UI
  EDITOR: 'EDITOR'
};

export const DRAGGABLE_ID_PROP = {
  WORD: ideationIdProps.WORD_ID
};

/**
 * IDs for the sequence of ideation phases.
 * Also contains IDs for the evaluation and finished phases.
 * @enum {string}
 * @readonly
 */
export const IDEATION_PHASES = {
  /** 1st phase, where ideas of other users are obscured */
  REDACTED: 'REDACTED',
  /** 2nd phase, where all ideas are visible */
  PEER: 'PEER',
  /** 3rd phase, where images can be added */
  IMAGES: 'IMAGES',
  /** 4th and final phase, where random word cards are displayed */
  WORDS: 'WORDS',
  EVALUATION: 'EVALUATION',
  FINISHED: 'FINISHED'
};

/**
 * IDs that describe the behaviour of a phase.
 * The UI also relies on these strings to determine if a phase name has been customised,
 * and so it shouldn't be localised.
 * @enum {Object} IdeationPhaseType
 * @readonly
 */
export const IdeationPhaseType = {
  /** In the case of a facilitated challenge, participants are waiting in this phase until the facilitator launches the event. */
  Lobby: 'LOBBY',
  /** Ideas of other users are obscured; the user only sees their own ideas. 1st phase of the classic sequence. */
  Redacted: 'REDACTED',
  /** All ideas are visible to all users. 2nd phase of the classic sequence. */
  Peer: 'PEER',
  /** Random images are added to the canvas by participants, and by the system. 3rd phase of the classic sequence. */
  Images: 'IMAGES',
  /** Random words are added to the canvas by the system. 4th phase of the classic sequence. */
  Words: 'WORDS',
  /** Ideation is still possible, but evaluation is also open. 5th phase of the classic sequence. */
  Evaluation: 'EVALUATION',
  /** Ideation and evaluation are finished. 6th and final phase of the classic sequence. */
  Finished: 'FINISHED'
};

/**
 * These config objects define the default flow of an ideation process.
 *
 * When creating a challenge, `Evaluation` and `Finished` must also be present,
 * even if they don't have corresponding form inputs,
 * otherwise the API would exhibit severe bugs.
 * @type {IdeationPhase[]}
 * @readonly
 */
export const DefaultIdeationPhaseConfig = [
  {
    name: IdeationPhaseType.Redacted,
    ideationPhaseType: IdeationPhaseType.Redacted,
    idleTimeoutInSeconds: 60,
    minLengthInSeconds: 120,
    maxLengthInSeconds: 300,
    optimalNumberOfIdeas: 4,
    optimalNumberOfImages: 0,
    optimalNumberOfWords: 0
  },
  {
    name: IdeationPhaseType.Peer,
    ideationPhaseType: IdeationPhaseType.Peer,
    idleTimeoutInSeconds: 60,
    minLengthInSeconds: 120,
    maxLengthInSeconds: 300,
    optimalNumberOfIdeas: 4,
    optimalNumberOfImages: 0,
    optimalNumberOfWords: 0
  },
  {
    name: IdeationPhaseType.Images,
    ideationPhaseType: IdeationPhaseType.Images,
    idleTimeoutInSeconds: 60,
    minLengthInSeconds: 120,
    maxLengthInSeconds: 300,
    optimalNumberOfIdeas: 8,
    optimalNumberOfImages: 3,
    optimalNumberOfWords: 0
  },
  {
    name: IdeationPhaseType.Words,
    ideationPhaseType: IdeationPhaseType.Words,
    idleTimeoutInSeconds: 60,
    minLengthInSeconds: 120,
    maxLengthInSeconds: 300,
    optimalNumberOfIdeas: 12,
    optimalNumberOfImages: 3,
    optimalNumberOfWords: 3
  },
  {
    name: IdeationPhaseType.Evaluation,
    ideationPhaseType: IdeationPhaseType.Evaluation,
    idleTimeoutInSeconds: null,
    minLengthInSeconds: null,
    maxLengthInSeconds: 900,
    optimalNumberOfIdeas: null,
    optimalNumberOfImages: null,
    optimalNumberOfWords: null
  },
  {
    name: IdeationPhaseType.Finished,
    ideationPhaseType: IdeationPhaseType.Finished,
    idleTimeoutInSeconds: null,
    minLengthInSeconds: null,
    maxLengthInSeconds: null,
    optimalNumberOfIdeas: null,
    optimalNumberOfImages: null,
    optimalNumberOfWords: null
  }
];

/**
 * IDs for the ideation state combining socket status and user's presence in room
 */
export const IDEATION_STATUS = {
  /** Socket is already connected, and user initiated request to join a session */
  JOINING: 'JOINING',
  /** Socket is connected, user is in room */
  ACTIVE: 'ACTIVE',
  /** User is exiting the session */
  LEAVING_IDEATION: 'LEAVING_IDEATION',
  /** User is exiting the session, and simultaneously abandoning their assigned room */
  LEAVING_ROOM: 'LEAVING_ROOM',
  /** User is not in room, either because they haven't connected yet or the socket connection is dropping/failing */
  INACTIVE: 'INACTIVE',
  /** User couldn't join the session */
  ERROR: 'ERROR'
};

// Modals

export const CONN_ERROR_MODAL = 'CONN_ERROR_MODAL';
export const IMAGE_SELECTOR_MODAL = 'IMAGE_SELECTOR_MODAL';

// Web socket messages

export const S_ID = SOCKET_ID.IDEATION;

export const SOCKET_MSG = {
  CHALLENGE_JOIN_REQ: 'JoinChallenge',
  CHALLENGE_JOIN_RES: 'ChallengeJoined',
  CHALLENGE_LEAVE_IDEATION: 'LeaveIdeation',
  CHALLENGE_LEAVE_ROOM: 'LeaveRoom',
  CHALLENGE_STATUS_CHANGED: 'ChallengeStatusChanged',
  IDEA_DELETE_REQ: 'DeleteIdea',
  IDEA_DELETED: 'IdeaDeleted',
  IDEA_EDIT_REQ: 'EditIdea2',
  IDEA_EDITED: 'IdeaEdited',
  IDEA_LIKE_REQ: 'LikeIdea',
  IDEA_UNLIKE_REQ: 'UnlikeIdea',
  IDEA_MOVE_REQ: 'MoveIdea',
  IDEA_MOVED: 'IdeaMoved',
  IDEA_RECEIVED: 'IdeaAdded',
  IDEA_SUBMIT: 'AddIdea',
  IMAGE_DELETE_REQ: 'DeleteImage',
  IMAGE_DELETED: 'ImageDeleted',
  IMAGE_MOVED: 'ImageMoved',
  IMAGE_MOVE_REQ: 'MoveImage',
  IMAGE_RECEIVED: 'ImageAdded',
  IMAGE_SUBMIT: 'AddImage',
  LIKE_COUNT_CHANGED: 'LikeCountChanged',
  PHASE_CAN_MODIFY: 'ManualPhaseForwardEnablement',
  PHASE_CHANGED_USER_DATA: 'PhaseChanged',
  PHASE_CHANGED_WITH_CONFIG: 'PhaseChanged2',
  PHASE_CHANGE_REQ: 'MoveToPhase',
  USER_DISCONNECTED: 'UserDisconnected', // also user left ideation
  USER_LEFT_IDEATION: 'UserDisconnected',
  USER_JOINED: 'UserJoinedRoom',
  USER_LEFT_ROOM: 'UserLeftRoom',
  WORD_MOVE_REQ: 'MoveWord',
  WORD_MOVED: 'WordMoved',
  WORDS_RECEIVED: 'WordsAdded'
};
