import React from 'react';
import constant from 'lodash/constant';
import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import negate from 'lodash/negate';
import { Redirect } from 'react-router-dom';
import {
  isAdminSelector,
  ownProfileSelector
} from '../../../profile/state/profile';
import platform from '../../../utils/platform';
import paths from '../../../paths';
import renderOnCondition from './renderOnCondition';
import { isTokenValid } from '../../../authentication/services/token.service';

// HOCs

const isAuthenticated = flow(
  ownProfileSelector,
  getFp('userId'),
  Boolean,
  hasProfile => hasProfile || isTokenValid()
);

export const renderWhenAuthenticated = renderOnCondition(isAuthenticated);

export const renderWhenUnauthenticated = renderOnCondition(
  negate(isAuthenticated)
);

export const renderWhenPublicPlatform = renderOnCondition(
  constant(platform.isPublic(window.location.hostname))
);

export const requireAdminRole = renderOnCondition(
  isAdminSelector,
  <Redirect to={paths.feed()} />
);

export const renderWhenAdmin = renderOnCondition(isAdminSelector);

export default {
  requireAdminRole,
  renderOnCondition,
  renderWhenAuthenticated,
  renderWhenUnauthenticated,
  renderWhenPublicPlatform,
  renderWhenAdmin
};
