import React, { Component } from 'react';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Callout } from '../../components';
import { internetStatusSelector } from '../../reducers';
import { InternetStatus } from '../../../constants';
import {
  RECONNECTED_MESSAGE_TIMEOUT_MS,
  TIME_TILL_NETWORK_ERRORMSG_MS
} from '../../../api/constants';

const Message = {
  NOTHING: 'NOTHING',
  INTERNET_DISCONNECTED: 'INTERNET_DISCONNECTED',
  INTERNET_RECONNECTED: 'INTERNET_RECONNECTED',
  SERVER_UNAVAILABLE: 'SERVER_UNAVAILABLE'
};

class NetworkErrorCallout extends Component {
  state = {
    message: Message.NOTHING
  };

  isInternetDisconnected(nextProps) {
    return (
      this.props.internetStatus === InternetStatus.CONNECTED &&
      nextProps.internetStatus === InternetStatus.DISCONNECTED
    );
  }

  isServerUnavailable(nextProps) {
    return (
      this.props.internetStatus === InternetStatus.CONNECTED &&
      nextProps.internetStatus === InternetStatus.SERVER_UNAVAILABLE
    );
  }

  isWebsocketDisconnected(nextProps) {
    return (
      this.props.internetStatus === InternetStatus.CONNECTED &&
      nextProps.internetStatus === InternetStatus.WEBSOCKET_DISCONNECTED
    );
  }

  isInternetReconnected(nextProps) {
    return (
      this.props.internetStatus !== InternetStatus.CONNECTED &&
      nextProps.internetStatus === InternetStatus.CONNECTED
    );
  }

  waitUntilTimeoutThenShowMessage(message, timeout) {
    setTimeout(() => {
      if (this.props.internetStatus !== InternetStatus.CONNECTED) {
        this.setState({ message });
      }
    }, timeout);
  }

  showReconnectedThenHideAfterAWhile() {
    const { message } = this.state;

    if (
      message === Message.INTERNET_DISCONNECTED ||
      message === Message.SERVER_UNAVAILABLE
    ) {
      this.setState({ message: Message.INTERNET_RECONNECTED });

      setTimeout(() => {
        if (this.state.message === Message.INTERNET_RECONNECTED) {
          this.setState({ message: Message.NOTHING });
        }
      }, RECONNECTED_MESSAGE_TIMEOUT_MS);
    }
  }

  componentWillReceiveProps(nextProps) {
    const internetDisconnected = this.isInternetDisconnected(nextProps);
    const websocketDisconnected = this.isWebsocketDisconnected(nextProps);
    const internetReconnected = this.isInternetReconnected(nextProps);
    const serverIsUnavailable = this.isServerUnavailable(nextProps);

    if (internetReconnected) {
      this.showReconnectedThenHideAfterAWhile();
    } else if (internetDisconnected) {
      this.waitUntilTimeoutThenShowMessage(
        Message.INTERNET_DISCONNECTED,
        TIME_TILL_NETWORK_ERRORMSG_MS
      );
    } else if (serverIsUnavailable) {
      this.waitUntilTimeoutThenShowMessage(
        Message.SERVER_UNAVAILABLE,
        TIME_TILL_NETWORK_ERRORMSG_MS
      );
    } else if (websocketDisconnected) {
      this.waitUntilTimeoutThenShowMessage(
        Message.INTERNET_DISCONNECTED,
        TIME_TILL_NETWORK_ERRORMSG_MS
      );
    }
  }

  render() {
    const { t } = this.props;

    switch (this.state.message) {
      case Message.INTERNET_DISCONNECTED:
        return (
          <Callout type="danger">{t('common/No Internet Connection')}</Callout>
        );
      case Message.SERVER_UNAVAILABLE:
        return (
          <Callout type="danger">{t('common/Server Unavailable')}</Callout>
        );
      case Message.INTERNET_RECONNECTED:
        return <Callout type="success">{t('common/Reconnected')}</Callout>;
      case Message.NOTHING:
      default:
        return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    internetStatus: internetStatusSelector(state)
  };
}

export default compose(
  translate(),
  connect(mapStateToProps)
)(NetworkErrorCallout);
