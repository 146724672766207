import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { denormalize } from 'normalizr';
import { getEntities, hasEntityFailed } from '../../common/selectors/entities';
import { getConceptById } from '../selectors/ideaConcepts';
import { fetchIdeaConceptById } from '../../common/actions/ideaConcepts';
import schemas from '../schemas';
import { entities } from '../constants';

/**
 * @typedef {Object} IdeaConceptHookApi
 * @property {() => boolean} isLoading - Returns whether the concept is being fetched
 * @property {() => boolean} getError - Returns whether the error if concept fetch has failed
 * @property {() => Promise} fetch - Callback for triggering concept re-fetch
 * @property {() => IdeaConcept} denorm - Expensive function for denormalising the idea concept
 */

/**
 * Provides a normalised idea concept,
 * which is fetched from the server if it's not available in the Redux state.
 *
 * Getters are provided for status indicators, as well as for the denormalised idea concept.
 * @param {string} conceptId
 * @returns {[IdeaConcept, api: IdeaConceptHookApi]} A tuple of the idea concept and its related API
 */
export default function useIdeaConcept(conceptId) {
  const normalisedConcept = useSelector(getConceptById(conceptId), isEqual);
  const [loading, setLoading] = useState(false);
  const error = useSelector(hasEntityFailed(entities.IDEA_CONCEPT)(conceptId));

  const dispatch = useDispatch();
  const fetchConcept = useCallback(
    async function fetchConcept() {
      if (!conceptId || loading) {
        return;
      }
      try {
        setLoading(true);
        await dispatch(fetchIdeaConceptById(conceptId));
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    [dispatch, conceptId, loading]
  );

  useEffect(
    function fetchIfEmpty() {
      if (!normalisedConcept && !loading && !error && conceptId) {
        fetchConcept();
      }
    },
    [conceptId, error, fetchConcept, loading, normalisedConcept]
  );

  const existingEntities = useSelector(getEntities);
  const denorm = useCallback(
    function denorm() {
      if (!normalisedConcept) {
        return {};
      }
      return denormalize(
        normalisedConcept,
        schemas.IDEA_CONCEPT,
        existingEntities
      );
    },
    [existingEntities, normalisedConcept]
  );

  const api = useMemo(
    () => ({
      isLoading: () => loading,
      getError: () => error,
      fetch: fetchConcept,
      denorm
    }),
    [loading, error, fetchConcept, denorm]
  );

  return [normalisedConcept, api];
}
