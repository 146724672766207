import entityIdReducerFactory from './entityId';
import errorReducerFactory from './error';
import loadingReducerFactory from './loading';
import paginationReducerFactory from './pagination';

export {
  entityIdReducerFactory,
  errorReducerFactory,
  loadingReducerFactory,
  paginationReducerFactory
};
