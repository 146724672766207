import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './Progress.css';

function Progress({ animate, thickness }) {
  const classes = classNames({
    Progress: true,
    'Progress--animate': animate,
    [`Progress--${thickness}`]: !!thickness
  });
  return <div className={classes} />;
}

Progress.propTypes = {
  animate: PropTypes.bool,
  thickness: PropTypes.oneOf(['thin', 'thick'])
};

Progress.defaultProps = {
  animate: false
};

export default Progress;
