import React, { lazy } from 'react';
import get from 'lodash/get';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  conceptListPage,
  CONCEPT_SUBPAGE,
  CONCEPT_LIST,
  CONCEPT_EVALUATION
} from '../../paths';
import { conceptListFilterKeys } from '../../constants';
import { CONCEPT_LIST_DEFAULT_STAGE_FILTER } from '../../config';
import useCanReviewConcepts from '../../hooks/useCanReviewConcepts';
import { getConceptListFilters } from '../../selectors/conceptList';
import LazyComponent from '../../../common/components/LazyComponent';
import IdeaConceptListPage from '../IdeaConceptListPage';
import findFilterPathParam from './util/findFilterPathParam';

const Subpage = lazy(() => import('../IdeaConceptPage'));
const EvalPage = lazy(() => import('../ConceptReviewPage'));

function ProtectedEvalPage({ allowed, redirectTo }) {
  return allowed ? (
    <LazyComponent>
      <EvalPage />
    </LazyComponent>
  ) : (
    <Redirect to={redirectTo} />
  );
}

function IdeaConceptRoot() {
  const filters = useSelector(getConceptListFilters);
  const lastFilterId = get(
    filters,
    conceptListFilterKeys.STAGE,
    CONCEPT_LIST_DEFAULT_STAGE_FILTER
  );
  const filterId = Array.isArray(lastFilterId) ? lastFilterId[0] : lastFilterId;
  const filterPathParam = findFilterPathParam(filterId);
  const redirectTarget = conceptListPage(filterPathParam);

  const allowConceptReview = useCanReviewConcepts();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/${CONCEPT_LIST}/:stage`}>
        <IdeaConceptListPage />
      </Route>

      <Route exact path={`${path}/${CONCEPT_SUBPAGE}/:conceptId`}>
        <LazyComponent>
          <Subpage />
        </LazyComponent>
      </Route>

      <Route path={`${path}/${CONCEPT_EVALUATION}`}>
        <ProtectedEvalPage allowed={allowConceptReview} />
      </Route>

      <Redirect to={redirectTarget} />
    </Switch>
  );
}

export default IdeaConceptRoot;
