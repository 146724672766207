import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { handleActions } from 'redux-actions';
import keyBy from 'lodash/fp/keyBy';
import map from 'lodash/fp/map';
import {
  NOTIFICATIONS_INIT,
  NOTIFICATION_RECEIVED,
  NOTIFICATION_READ
} from '../../constants';

const unreadCount = handleActions(
  {
    [NOTIFICATIONS_INIT](state, { payload }) {
      return payload.unreadCount || 0;
    },
    [NOTIFICATION_RECEIVED](state, { payload }) {
      return payload.notification.read === false ? state + 1 : state;
    },
    [NOTIFICATION_READ](state, { payload }) {
      return Math.max(state - 1, 0);
    }
  },
  0
);

const allIds = handleActions(
  {
    [NOTIFICATIONS_INIT](state, { payload }) {
      return map('notificationId')(payload.notifications);
    },
    [NOTIFICATION_RECEIVED](state, { payload }) {
      return [payload.notification.notificationId, ...state];
    }
  },
  []
);

const byId = handleActions(
  {
    [NOTIFICATIONS_INIT](state, { payload }) {
      return keyBy('notificationId')(payload.notifications);
    },
    [NOTIFICATION_RECEIVED](state, { payload }) {
      const { notification } = payload;
      return {
        ...state,
        [notification.notificationId]: notification
      };
    },
    [NOTIFICATION_READ](state, { payload }) {
      const notification = state[payload.notificationId];

      if (!notification) {
        return state;
      }

      return {
        ...state,
        [notification.notificationId]: {
          ...notification,
          read: true
        }
      };
    }
  },
  {}
);

export default combineReducers({
  unreadCount,
  allIds,
  byId
});

export const unreadNotificationCountSelector = state =>
  state.latestNotifications.unreadCount;

export const notificationsSelector = createSelector(
  state => state.latestNotifications.allIds,
  state => state.latestNotifications.byId,
  (allIds, byId) => map(id => byId[id], allIds)
);
