import flow from 'lodash/flow';
import { SOCKET_MSG as MSG, S_ID } from '../../constants';
import { sendSocketMessage } from '../../../common/actions/socket';
import {
  mapPositionToMsg,
  mapPositionFromMsg,
  mapImageDataFromMsg,
  mapOwnerIdFromMsg
} from './_mappers';

// Requests

export const deleteImageMsg = imageId =>
  sendSocketMessage(S_ID, MSG.IMAGE_DELETE_REQ, imageId);

export const moveImageMsg = (imageId, posX, posY, posZ) => {
  const payload = [imageId, posX, posY, posZ];
  return sendSocketMessage(S_ID, MSG.IMAGE_MOVE_REQ, payload);
};

export const submitImgMsg = (image, posX, posY, posZ) => {
  const payload = [image, mapPositionToMsg({ posX, posY, posZ })];
  return sendSocketMessage(S_ID, MSG.IMAGE_SUBMIT, payload);
};

// Responses

export const normaliseImageReceivedMsg = (msg = []) =>
  flow(
    mapPositionFromMsg,
    mapImageDataFromMsg,
    mapOwnerIdFromMsg
  )(msg);
