import React, { Component } from 'react';
import chunk from 'lodash/chunk';
import noop from 'lodash/noop';
import slice from 'lodash/slice';
import PropTypes from 'prop-types';
import { ViewPager, Frame } from 'react-view-pager';

export default class Container extends Component {
  static propTypes = {
    animated: PropTypes.bool,
    paginateBy: PropTypes.number.isRequired
  };
  static defaultProps = {
    animated: false,
    children: () => null,
    items: [],
    onNext: noop,
    onPrev: noop
  };

  state = {
    currentPage: 0
  };

  getPageCount() {
    const { items, paginateBy } = this.props;

    return paginateBy < 1 ? 1 : Math.ceil(items.length / paginateBy);
  }

  getAllPages() {
    const { items, paginateBy } = this.props;

    return chunk(items, paginateBy);
  }

  getCurrentPage() {
    return this.getItemsForPage(this.state.currentPage);
  }

  getItemsForPage(page) {
    const { items, paginateBy } = this.props;
    const sliceStart = page * paginateBy;
    const sliceEnd = sliceStart + paginateBy;

    return slice(items, sliceStart, sliceEnd);
  }

  getVisibleItems() {
    return this.props.animated ? this.getAllPages() : this.getCurrentPage();
  }

  getChildProps() {
    const oneBasedCurrentPage = this.state.currentPage + 1;

    return {
      currentPage: oneBasedCurrentPage,
      items: this.getVisibleItems(),
      pageCount: this.getPageCount(),
      navProps: {
        onNext: this.handleNext,
        onPrev: this.handlePrev
      },
      slideTrackProps: this.getSlideTrackProps()
    };
  }

  getSlideTrackProps() {
    if (this.props.animated) {
      return {
        currentView: this.state.currentPage,
        onViewChange: this.handleSwipeFinish,
        ref: ref => (this.slider = ref)
      };
    }
  }

  handleNext = event => {
    this.setState(
      state => ({ currentPage: state.currentPage + 1 }),
      () =>
        this.props.onNext(event, this.state.currentPage, this.getPageCount())
    );
  };

  handlePrev = event => {
    this.setState(
      state => ({ currentPage: state.currentPage - 1 }),
      () =>
        this.props.onPrev(event, this.state.currentPage, this.getPageCount())
    );
  };

  handleSwipeFinish = ([currentPage]) => {
    if (this.state.currentPage !== currentPage) {
      this.setState({ currentPage });
    }
  };

  render() {
    const shouldAnimate = this.props.animated && this.getPageCount() > 1;

    return shouldAnimate
      ? this.renderAnimated()
      : this.props.children({ ...this.getChildProps() });
  }

  renderAnimated() {
    return (
      <ViewPager>
        <Frame>{this.props.children({ ...this.getChildProps() })}</Frame>
      </ViewPager>
    );
  }
}
