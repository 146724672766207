import React from 'react';
import './Dot.css';

function Dot(props) {
  return (
    <span {...props} className="Dot">
      &bull;
    </span>
  );
}

export default Dot;
