import filter from 'lodash/filter';
import find from 'lodash/find';
import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import mapFp from 'lodash/fp/map';
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector
} from '@reduxjs/toolkit';
import { AsyncStatus } from '../../constants';
import { convertApiErrorToStatusCode } from '../../api/utils/apiError';
import handleApiCallAction from '../../utils/handleApiCallAction';

/**
 * @typedef {Object} ActionMap
 * @property {Function<Promise>} editComment - Calls the resource provided in `options.editFn` to edit a comment
 * @property {Function<Promise<Array>>} fetchComments - Calls the resource provided in `options.fetchFn` and stores the received comments in the state
 */

/**
 * @typedef {Object} SelectorMap
 * @property {Function} getGroupCommentByConceptId - Returns a group comment (i.e. without a `user` prop) by its parent concept ID
 * @property {Function} getUserCommentForConcept - Returns an individual comment (i.e. with a `user` prop) by its parent concept ID
 */

/**
 * Generates an RTK state slice for handling concept comments,
 * and allows customising some options to handle the minor differences between
 * endpoints and object shapes.
 * @param {Object} options
 * @param {Function<Promise>} options.editFn - Callback for editing a comment
 * @param {Function<Promise<Array>>} options.fetchFn - Callback for fetching comments for an idea concept
 * @param {Function} options.entityPropNormalizerFn - Mapper function for normalizing unique prop names of items
 * @param {string} options.entityLabel - Entity name to be used in Redux action strings
 * @param {string} options.sliceName - Name and path for the generated state slice
 * @returns {{ slice: import('@reduxjs/toolkit').Slice, actions: ActionMap, selectors: SelectorMap }}
 */
export default function commentSliceFactory({
  editFn,
  fetchFn,
  entityPropNormalizerFn,
  entityLabel,
  sliceName
}) {
  const createCommentUuid = (conceptId, authorId = 'group') =>
    `${conceptId}_${authorId}`;

  const fetchComments = createAsyncThunk(
    `fetch${entityLabel}s`,
    handleApiCallAction(fetchFn)
  );

  const editComment = createAsyncThunk(
    `edit${entityLabel}`,
    async (args, thunkApi) => {
      try {
        const { authorId, conceptId, isGroupComment, text } = args;
        await editFn(conceptId, text, isGroupComment);
        return { conceptId, authorId, text };
      } catch (error) {
        return thunkApi.rejectWithValue({
          code: convertApiErrorToStatusCode(),
          error
        });
      }
    }
  );

  const commentsAdapter = createEntityAdapter({
    selectId: comment => comment.commentId
  });

  const initialState = {
    entities: commentsAdapter.getInitialState(),
    status: AsyncStatus.Idle,
    error: null
  };

  const commentsSlice = createSlice({
    name: sliceName,
    initialState,
    extraReducers: builder =>
      builder
        .addCase(fetchComments.pending, state => {
          state.status = AsyncStatus.Loading;
          state.error = null;
        })
        .addCase(fetchComments.fulfilled, (state, action) => {
          const conceptId = action.meta.arg;
          state.entities = commentsAdapter.upsertMany(
            state.entities,
            flow(
              mapFp(comment => ({
                ...comment,
                ideaConceptId: conceptId,
                commentId: createCommentUuid(
                  conceptId,
                  get(comment, 'user.userId')
                )
              })),
              mapFp(entityPropNormalizerFn)
            )(action.payload)
          );
          state.status = AsyncStatus.Succeeded;
        })
        .addCase(fetchComments.rejected, (state, action) => {
          state.status = AsyncStatus.Failed;
          state.error = action.error;
        })
  });

  const getSlice = getFp(sliceName);
  const getCommentMap = flow(
    getSlice,
    getFp('entities.entities')
  );

  const getCommentsByConceptId = createSelector(
    getCommentMap,
    (state, conceptId) => conceptId,
    (commentMap, conceptId) =>
      filter(commentMap, comment => comment.ideaConceptId === conceptId)
  );

  const hasUserInfo = obj => obj.user !== null;

  const getUserCommentForConcept = createSelector(
    getCommentMap,
    (state, conceptId) => conceptId,
    (state, conceptId, userId) => userId,
    (commentMap, conceptId, userId) =>
      find(
        commentMap,
        comment =>
          comment.ideaConceptId === conceptId &&
          get(comment, 'user.userId') === userId
      )
  );

  const getGroupCommentByConceptId = createSelector(
    (state, conceptId) => getCommentsByConceptId(state, conceptId),
    comments => find(comments, comment => !hasUserInfo(comment))
  );

  return {
    slice: commentsSlice,
    actions: {
      fetchComments,
      editComment
    },
    selectors: {
      getCommentsByConceptId,
      getGroupCommentByConceptId,
      getUserCommentForConcept
    }
  };
}
