import React from 'react';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import size from 'lodash/size';
import some from 'lodash/some';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import cn from 'classnames';
import { EMPTY_NUMBER } from '../../constants';
import {
  CONCEPT_EVAL_CRITERIA_MAXLENGTH,
  CepheidConceptEvaluationFormConfig,
  IdeaConceptEvaluationFormConfig
} from '../../config';
import { maybeTranslateCriterionLabel } from '../../utils/conceptEvaluation';
import {
  getEvalCriterionId,
  getEvalCriterionName,
  getEvalCriterionAverage
} from '../../selectors/conceptEvaluation';
import NonIdealState from '../../../common/components/NonIdealState';
import {
  isAmdocsVariant,
  isCepheidVariant,
  isConnectHumanityVariant
} from '../../../utils/variants';
import scss from './ReviewScoreDetails.module.scss';

const shouldShowVariant =
  isCepheidVariant() || isAmdocsVariant() || isConnectHumanityVariant();

ReviewScoreDetails.propTypes = {
  canEdit: PropTypes.bool,
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      criterionId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      average: PropTypes.number
    })
  ),
  t: PropTypes.func.isRequired
};
ReviewScoreDetails.defaultProps = {
  scores: []
};
export function ReviewScoreDetails({ className, canEdit, scores, t }) {
  const labelTranslatorFn = maybeTranslateCriterionLabel(t);
  const hasLongLabels = some(
    scores,
    flow(
      getEvalCriterionName,
      size,
      length => length > CONCEPT_EVAL_CRITERIA_MAXLENGTH
    )
  );
  const formConfig = shouldShowVariant
    ? CepheidConceptEvaluationFormConfig
    : IdeaConceptEvaluationFormConfig;
  const cepheidMarkers = shouldShowVariant
    ? t('ideaConcept/evaluation/form/cepheidMarkers', { returnObjects: true })
    : [];

  if (isEmpty(scores)) {
    return (
      <NonIdealState
        title={t('ideaConcept/evaluation/empty/title')}
        description={
          canEdit ? null : t('ideaConcept/evaluation/empty/description')
        }
        visual={NonIdealState.HUMMINGBIRD}
        style={{ padding: 0 }}
      />
    );
  }

  return (
    <table
      className={cn(scss.Table, className, {
        [scss.longLabels]: hasLongLabels
      })}>
      {scores.map(criterion => {
        const label = getEvalCriterionName(criterion);
        const score = getEvalCriterionAverage(criterion);
        const scoreString = shouldShowVariant
          ? cepheidMarkers[round(score, 1) - 1]
          : `${round(score, 1)}/${formConfig.CRITERION_MAX}`;

        return (
          <tr key={getEvalCriterionId(criterion)}>
            <th className={scss.Header}>
              <span>{labelTranslatorFn(label)}</span>
            </th>
            <td>
              <div
                className={scss.BarGraph}
                style={{
                  width: `${(score / formConfig.CRITERION_MAX) * 100}%`
                }}
              />
            </td>
            <td className={scss.Score}>
              {score === 0 ? EMPTY_NUMBER : scoreString}
            </td>
          </tr>
        );
      })}
    </table>
  );
}

export default translate()(ReviewScoreDetails);
