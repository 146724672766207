import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './FadeTransition.css';

const ANIM_MS = 300;

FadeTransition.propTypes = {
  speed: PropTypes.number
};
FadeTransition.defaultProps = {
  speed: ANIM_MS
};
export default function FadeTransition({ speed, ...props }) {
  return (
    <CSSTransition
      {...props}
      classNames="fade-transition"
      timeout={{ enter: speed, exit: speed }}
    />
  );
}
