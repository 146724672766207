import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import calculateSurplusItems from '../../../utils/calculateSurplusItems';
import ConnectedAvatar from '../../../common/containers/ConnectedAvatar';

const ListItem = styled(ConnectedAvatar)`
  position: relative;
  margin-left: -10px;
  vertical-align: middle;
  &:first-child {
    margin-left: 0;
  }
  &:hover,
  &:focus {
    z-index: 20 !important;
  }
`;

const Surplus = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  vertical-align: middle;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
`;

ConnectedAvatarList.propTypes = {
  avatarClassName: PropTypes.string,
  avatarSize: PropTypes.string,
  tooltipPosition: PropTypes.string,
  canExpand: PropTypes.bool,
  maxDisplay: PropTypes.number,
  ownUserId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object)
};
export function ConnectedAvatarList({
  avatarClassName,
  avatarSize,
  canExpand,
  className,
  maxDisplay = 0,
  users = [],
  style,
  tooltipPosition
}) {
  const [expanded, setExpanded] = useState(false);
  const [truncatedItems, surplus] = calculateSurplusItems(users, maxDisplay);
  const visibleItems = expanded ? users : truncatedItems;

  return (
    <div className={className} style={style}>
      {visibleItems.map(({ isSelf, isVisible, key, userId }, ix) =>
        userId ? (
          <ListItem
            key={key}
            userId={userId}
            blurred={isSelf && !isVisible}
            size={avatarSize}
            tooltipPosition={tooltipPosition}
            wrapperClassName={avatarClassName}
            style={{ zIndex: visibleItems.length - ix }}
          />
        ) : null
      )}
      {surplus > 0 && !expanded && (
        <Surplus
          onClick={canExpand ? () => setExpanded(true) : null}
          clickable={canExpand}>
          &nbsp;
          {`+${surplus}`}
        </Surplus>
      )}
    </div>
  );
}

export default ConnectedAvatarList;
