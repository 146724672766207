import styled from 'styled-components';

export const FilterOption = styled.button`
  display: block;
  appearance: none;
  width: 100%;
  height: 1.75rem;
  padding: 0 0.9rem;
  border: none;
  background: none;
  font-size: 0.9rem;
  color: black;
  text-align: left;
  vertical-align: middle;
  transition: background 0.15s linear;

  &:hover {
    background: #eee;
  }
`;
