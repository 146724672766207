import get from 'lodash/get';
import {
  IDEA_LIST_FILTER_CHANGED,
  IDEA_LIST_PAGINATED,
  DELETE_IDEA,
  RESULTS_EVALUATE_BUTTON_CLICK,
  RESULTS_EVALUATE_CONCEPT_BUTTON_CLICK,
  RESULTS_BRAINSTORM_BUTTON_CLICK,
  MODAL_OPEN,
  REVEAL_IDENTITY
} from '../../constants';
import { PAGINATE_IDEA_CARDS } from '../../ideaConcept/constants';
import EventTracker from './_EventTracker';
import { EXPORT_IDEAS } from '../../challengeResults/stateSlices/challengeExport';

const EVENT_GROUP = 'challengeResults';

class ChallengeResultsTracker extends EventTracker {
  constructor(metrics) {
    super(metrics);
    this.eventGroup = EVENT_GROUP;
  }

  track(action, userId) {
    const { type, payload } = action;
    const metrics = get(
      action,
      'metrics',
      get(action, ['meta', 'metrics'], get(action, ['payload', 'metrics'], {}))
    );
    const commonData = {
      userId
    };
    const challengeId = get(metrics, 'challengeId');
    const metricsPage = get(metrics, 'page');

    if (type === IDEA_LIST_FILTER_CHANGED) {
      this.coreTrack(type, { ...commonData, ...action.meta.metrics });
    } else if (type === IDEA_LIST_PAGINATED) {
      this.coreTrack(type, { ...commonData, ...action.meta.metrics });
    } else if (type === MODAL_OPEN && payload.modalType === 'REVEAL') {
      this.coreTrack(type + '_' + payload.modalType, {
        challengeId: payload.modalProps.challengeId,
        ...commonData,
        clickedAt: payload.modalProps.clickedAt
      });
    } else if (type === DELETE_IDEA.SUCCESS) {
      this.coreTrack(type, {
        ideaId: payload,
        ...commonData,
        challengeId
      });
    } else if (type === RESULTS_EVALUATE_BUTTON_CLICK) {
      this.coreTrack(type, {
        ...commonData,
        ...action.meta.metrics
      });
    } else if (type === RESULTS_EVALUATE_CONCEPT_BUTTON_CLICK) {
      this.coreTrack(type, {
        ...commonData,
        ...action.meta.metrics
      });
    } else if (type === RESULTS_BRAINSTORM_BUTTON_CLICK) {
      this.coreTrack(type, {
        ...commonData,
        ...action.meta.metrics
      });
    } else if (type === MODAL_OPEN && payload.modalType === 'EDIT_CHALLENGE') {
      this.coreTrack(type + '_' + payload.modalType, {
        challengeId: payload.modalProps.challengeId,
        ...commonData,
        highlightedField: payload.modalProps.highlightedField
      });
    } else if (type === REVEAL_IDENTITY.SUCCESS) {
      this.coreTrack(type, {
        challengeId: action.meta.metrics.challengeId,
        ...commonData,
        numOfRevealedIdeas: action.meta.data.length
      });
    } else if (type === PAGINATE_IDEA_CARDS && metricsPage === 'RESULTS') {
      this.coreTrack(type, {
        ...commonData,
        currentPage: get(metrics, 'currentPage'),
        page: metricsPage,
        pageCount: get(metrics, 'pageCount')
      });
    } else if (type === EXPORT_IDEAS.PENDING) {
      this.coreTrack('EXPORT_IDEAS', {
        ...commonData,
        challengeId
      });
    }
  }
}

export default ChallengeResultsTracker;
