import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isSubmitting, isPristine } from 'redux-form';
import Button from '@bit/be-novative.kit.button';
import Spinner from '@bit/be-novative.kit.spinner';

SubmitButton.propTypes = {
  form: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
};

SubmitButton.defaultProps = {
  style: {
    minWidth: 150
  }
};

function SubmitButton({ dispatch, form, ...props }) {
  return <Button variant="success" type="submit" size="large" {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { form, children, asyncValidating } = ownProps;
  const submitting = isSubmitting(form)(state);
  const pristine = isPristine(form)(state);
  return {
    disabled: submitting || pristine || !!asyncValidating,
    children: submitting ? <Spinner type="bubble" size="medium" /> : children
  };
}

export default connect(mapStateToProps)(SubmitButton);
