import invariant from 'invariant';
import { handleActions, combineActions } from 'redux-actions';
import concat from 'lodash/fp/concat';
import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';
import without from 'lodash/without';
import forceArray from '../../../utils/forceArray';

const NO_ACTION = Symbol('empty');
const ACTION_DEFAULTS = {
  successActions: [NO_ACTION],
  dropActions: [NO_ACTION],
  flushActions: [NO_ACTION]
};
const CONFIG_DEFAULTS = {
  idProp: '',
  initialState: [],
  overwriteExisting: false
};
const entityIdReducerFactory = (
  actionTypes = ACTION_DEFAULTS,
  options = CONFIG_DEFAULTS
) => {
  const { successActions, dropActions, flushActions } = actionTypes;
  const { idProp, initialState, overwriteExisting } = {
    ...CONFIG_DEFAULTS,
    ...options
  };
  const mapEntityIds = map(getFp(idProp));

  invariant(
    isString(idProp) && !isEmpty(idProp),
    'Config object must contain idProp string'
  );
  invariant(isArray(initialState), 'initialState must be an array');

  const reducerMap = {
    [combineActions(...forceArray(successActions))]: (
      state,
      { meta, payload }
    ) => {
      const mergeWithState = get(meta, 'mostRecent')
        ? items => [...items, ...state]
        : concat(state);
      const ids = flow(
        forceArray,
        mapEntityIds
      )(payload);

      if (overwriteExisting) {
        return ids;
      }

      return flow(
        mergeWithState,
        uniq
      )(ids);
    }
  };

  if (!isEmpty(flushActions)) {
    reducerMap[combineActions(...forceArray(flushActions))] = () => [];
  }

  if (!isEmpty(dropActions)) {
    reducerMap[combineActions(...forceArray(dropActions))] = (
      state,
      { payload }
    ) => without(state, payload);
  }

  return handleActions(reducerMap, initialState);
};

export default entityIdReducerFactory;
