import React, { Component } from 'react';
import escapeRegExp from 'lodash/escapeRegExp';
import identity from 'lodash/identity';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import stubTrue from 'lodash/stubTrue';
import PropTypes from 'prop-types';
import formControl from '@bit/be-novative.kit.form-control';
import Autosuggest from './';

export class AutosuggestFinalFormAdapter extends Component {
  static propTypes = {
    getDisplayValue: PropTypes.func,
    getValue: PropTypes.func,
    input: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func,
    suggestions: PropTypes.array
  };

  static defaultProps = {
    getDisplayValue: identity,
    getValue: identity,
    input: {
      onChange: noop
    },
    onFilterChange: noop,
    suggestions: []
  };

  getFilteredChoices = target =>
    this.props.suggestions.filter(this.testEqualDisplayValues);

  renderSuggestion = s => <span>{this.props.getDisplayValue(s)}</span>;

  handleUserInput = event => {
    event.preventDefault();
    this.updateValue(event.target.value);
  };

  selectionCallback = (event, { suggestionValue }) => {
    event.preventDefault();
    this.updateValue(suggestionValue);
  };

  testEqualDisplayValues = choice => {
    const { input, getDisplayValue } = this.props;
    const filterRegex = new RegExp(escapeRegExp(input.value), 'ig');

    return filterRegex.test(getDisplayValue(choice));
  };

  updateValue = value => {
    const { onChange } = this.props.input;

    if (isFunction(onChange)) {
      onChange(value);
    }
  };

  render() {
    const { getValue, input, placeholder, ...props } = this.props;
    const inputProps = {
      ...input,
      onChange: this.handleUserInput,
      placeholder
    };

    return (
      <Autosuggest
        {...props}
        getSuggestionValue={getValue}
        inputProps={inputProps}
        onSuggestionSelected={this.selectionCallback}
        renderSuggestion={this.renderSuggestion}
        shouldRenderSuggestions={stubTrue}
        suggestions={this.getFilteredChoices()}
      />
    );
  }
}

export default formControl(AutosuggestFinalFormAdapter);
