import flow from 'lodash/flow';
import get from 'lodash/fp/get';
import isEqual from 'lodash/fp/isEqual';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { MODAL_CLOSE, MODAL_OPEN, ROUTE_CHANGE } from '../../constants';
import { MODAL_CLOSE_ALL } from '../actions';

const stack = handleActions(
  {
    [MODAL_CLOSE](state) {
      return state.length > 0 ? state.slice(1) : state;
    },
    [MODAL_OPEN](state, { payload }) {
      return [payload, ...state];
    },
    [MODAL_CLOSE_ALL]() {
      return [];
    },
    [ROUTE_CHANGE]() {
      return [];
    }
  },
  []
);

export default combineReducers({
  stack
});

export const activeModalSelector = get('modal.stack[0]');

export const isModalOpen = name => state =>
  flow(
    activeModalSelector,
    get('modalType'),
    isEqual(name)
  )(state);
