import random from 'lodash/fp/random';
import { VOYAGER_API } from '../../api/constants';
import { openModal } from '../../common/actions/modal';
import { getContent } from '../../common/selectors/images';
import { asyncActionType } from '../../utils';
import forceArray from '../../utils/forceArray';
import { getImageId } from '../selectors/images';
import { IMAGE_SELECTOR_MODAL, S_ID } from '../constants';
import {
  normaliseImageReceivedMsg,
  submitImgMsg
} from '../utils/socketMsg/images';
import { storeCanvasItemPosition } from './movement';

export const sendImage = image => dispatch => {
  const { posX, posY, ...restOfImg } = image;
  const newPosX = image.posX || random(0, 100);
  const newPosY = image.posY || random(0, 100);

  return dispatch(submitImgMsg(restOfImg, newPosX, newPosY));
};

const UPLOAD_CANVAS_IMAGE = asyncActionType('UPLOAD_CANVAS_IMAGE');
export const uploadCanvasImage = (conceptId, canvasType, image) => dispatch =>
  dispatch({
    [VOYAGER_API]: {
      url: `/ideaconcepts/${conceptId}/canvases/${canvasType}/images`,
      method: 'POST',
      data: getContent(image),
      type: UPLOAD_CANVAS_IMAGE
    }
  });

export const STORE_IMAGES = 'canvas/image/store';
/**
 * Stores one or more items as canvas images in Redux
 * @param {CanvasImage|CanvasImage[]} payload
 * @returns {import('redux').Action}
 */
export const storeImages = payload => ({
  type: STORE_IMAGES,
  payload: forceArray(payload),
  meta: { canvasId: S_ID }
});

export const IMAGE_RECEIVED = 'IMAGE_RECEIVED';
export const imageReceived = (response, messageType) => dispatch => {
  const image = normaliseImageReceivedMsg(response);
  dispatch({
    type: IMAGE_RECEIVED,
    payload: image,
    meta: { messageType, id: image.imageId, canvasId: S_ID }
  });
  dispatch(
    storeCanvasItemPosition(
      { id: getImageId(image), posX: image.posX, posY: image.posY },
      'external'
    )
  );
};

const DELETE_CANVAS_IMAGE = asyncActionType('DELETE_CANVAS_IMAGE');
export const deleteImage = (conceptId, canvasType, imageId) => dispatch =>
  dispatch({
    [VOYAGER_API]: {
      url: `/ideaconcepts/${conceptId}/canvases/${canvasType}/images/${imageId}`,
      method: 'DELETE',
      type: DELETE_CANVAS_IMAGE
    }
  });

export const IMAGE_DELETED = 'IMAGE_DELETED';
export const imageDeleted = (response, messageType) => ({
  type: IMAGE_DELETED,
  payload: response,
  meta: { messageType, canvasId: S_ID }
});

export const openImageSelectorModal = onSelect => dispatch => {
  dispatch(
    openModal({
      modalType: IMAGE_SELECTOR_MODAL,
      modalProps: {
        size: 'large',
        isCloseButtonShown: false,
        onImageSelect: onSelect,
        uploadImageType: 'blob'
      }
    })
  );
};
