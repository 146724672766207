import findIndexFp from 'lodash/fp/findIndex';
import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import head from 'lodash/head';
import isEqualFp from 'lodash/fp/isEqual';
import maxByFp from 'lodash/fp/maxBy';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import tail from 'lodash/tail';
import { createSelector } from 'reselect';
import { rootSelector } from '.';

const getMovementRoot = flow(
  rootSelector,
  getFp('movement')
);

const getPositionMap = state =>
  flow(
    getMovementRoot,
    getFp('position')
  )(state);

export const getCanvasItemPosition = createSelector(
  getPositionMap,
  (_, itemId) => itemId,
  (positionMap, itemId) => get(positionMap, itemId, [0, 0])
);

export const makeGetCanvasItemPosition = () => getCanvasItemPosition;

export const getUserMovedItemId = state =>
  flow(
    getMovementRoot,
    getFp('itemMovedByUser')
  )(state);

const getLastMovementMap = flow(
  getMovementRoot,
  getFp('lastMoved')
);

const sortByLastMoveAsc = tuples => sortBy(tuples, tail);
const findHighestByTupleTail = maxByFp(tail);

export const getLastUserMovedItemId = createSelector(
  getLastMovementMap,
  movementMap =>
    flow(
      toPairs,
      sortByLastMoveAsc,
      findHighestByTupleTail,
      head
    )(movementMap)
);

const getZIndexByItemId = createSelector(
  getLastMovementMap,
  (_, itemId) => itemId,
  (movementMap, itemId) => {
    const isEqualToItemId = flow(
      head,
      String,
      isEqualFp(String(itemId))
    );
    const getZIndexByMoveOrder = flow(
      toPairs,
      sortByLastMoveAsc,
      findIndexFp(isEqualToItemId)
    );
    return Math.max(1, getZIndexByMoveOrder(movementMap) + 1);
  }
);

export const makeGetZIndexByItemId = () => getZIndexByItemId;
