import React, { useCallback, useState } from 'react';
import { translate } from 'react-i18next';
import Button from '@bit/be-novative.kit.button';
import Popover from '../../../common/components/Popover';
import Icon from '../../../common/components/Icon';
import ChallengeFilter from '../ChallengeFilter';
import scss from './ChallengeSearchPopover.module.scss';

ChallengeSearchPopover.propTypes = {};
function ChallengeSearchPopover({ selections, onSelect, t }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <Popover.PopoverTrigger
      popover={<ChallengeFilter onSubmit={onSelect} selections={selections} />}
      popoverModifiers={{ flip: { enabled: false } }} // @see https://popper.js.org/popper-documentation.html#modifiers..flip
      showFooterStripe={false}
      show={isOpen}
      onClose={handleClose}
      popoverClassName={scss.ChallengeSearchPopover}>
      <Button variant="minimal" onClick={handleToggle}>
        <span>{t('ideaConcepts/listPage/filter/challenge/dropdown')}</span>
        <Icon type="angle-down" />
      </Button>
    </Popover.PopoverTrigger>
  );
}

export default translate()(ChallengeSearchPopover);
