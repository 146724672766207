import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getGroupCommentByConceptId,
  getUserCommentForConcept
} from '../../state/internalComments';
import {
  getGroupFeedbackByConceptId,
  getUserFeedbackForConcept
} from '../../state/reviewerFeedback';

const getSelectorFor = {
  internalComment: isGroupComment =>
    isGroupComment ? getGroupCommentByConceptId : getUserCommentForConcept,
  feedback: isGroupComment =>
    isGroupComment ? getGroupFeedbackByConceptId : getUserFeedbackForConcept
};

/**
 * Returns an internal comment or a feedback from Redux
 * @param {"internalComment" | "feedback"} commentType - Determines which endpoint will be called for fetching the comment
 * @param {string|number} conceptId - Parent concept ID
 * @param {string|number} authorId - If the author's user ID is present, their comment is returned. Otherwise the group comment is returned.
 * @returns {Function<Promise>}
 */
export default function useGetCommentLike(commentType, conceptId, authorId) {
  if (!['internalComment', 'feedback'].includes(commentType))
    throw Error(`Invalid commentType: ${commentType}`);

  const isGroupComment = !authorId;
  const commentSelector = useMemo(
    () => getSelectorFor[commentType](isGroupComment),
    [commentType, isGroupComment]
  );
  return useSelector(state => commentSelector(state, conceptId, authorId));
}
