import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import { StickyContainer } from 'react-sticky';
import classNames from 'classnames';
import times from 'lodash/times';
import Measure from 'react-measure';
import { SortDirection } from '../../../constants';
import defaultHeaderRowRenderer from './defaultHeaderRowRenderer';
import defaultRowRenderer from './defaultRowRenderer';
import './Table.css';

const BODY_CLASS = 'Table__Body';

class Table extends Component {
  static propTypes = {
    bodyColumnClassName: PropTypes.string,
    headerColumnClassName: PropTypes.string,
    headerRowRenderer: PropTypes.func,
    id: PropTypes.string,
    rowClassName: PropTypes.string,
    rowCount: PropTypes.number.isRequired,
    rowGetter: PropTypes.func,
    rowRenderer: PropTypes.func,
    rowStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    sort: PropTypes.func,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string
  };

  static defaultProps = {
    headerRowRenderer: defaultHeaderRowRenderer,
    rowGetter: item => item,
    rowRenderer: defaultRowRenderer
  };

  getFlexStyleForColumn(column, customStyle = {}) {
    const flexValue = `${column.props.flexGrow} ${column.props.flexShrink} ${column.props.width}px`;

    const style = {
      ...customStyle,
      flex: flexValue,
      msFlex: flexValue,
      WebkitFlex: flexValue
    };

    if (column.props.maxWidth) {
      style.maxWidth = column.props.maxWidth;
    }

    if (column.props.minWidth) {
      style.minWidth = column.props.minWidth;
    }

    return style;
  }

  getHeaderColumns(children) {
    return children.map((column, index) =>
      column ? this.createHeader({ column, index }) : null
    );
  }

  createHeader({ column, index }) {
    const { sort, sortBy, sortDirection } = this.props;
    const {
      dataKey,
      disableSort,
      headerClassName,
      headerRenderer,
      id,
      label,
      columnData,
      align
    } = column.props;
    const sortEnabled = !disableSort && sort;

    const classes = classNames(
      'Table__HeaderCol',
      {
        'Table__HeaderCol--sortable': sortEnabled,
        [`Table__HeaderCol--${align}`]: !!align
      },
      headerClassName
    );
    const style = this.getFlexStyleForColumn(column);

    const renderedHeader = headerRenderer({
      columnData,
      dataKey,
      disableSort,
      label,
      sortBy,
      sortDirection
    });

    const a11yProps = {
      role: 'columnheader'
    };

    if (sortEnabled) {
      // If this is a sortable header, clicking it should update the table data's sorting.
      const newSortDirection =
        sortBy !== dataKey || sortDirection === SortDirection.DESC
          ? SortDirection.ASC
          : SortDirection.DESC;

      const onClick = event => {
        sortEnabled &&
          sort({
            sortBy: dataKey,
            sortDirection: newSortDirection
          });
      };

      const onKeyDown = event => {
        if (event.key === 'Enter' || event.key === ' ') {
          onClick(event);
        }
      };

      a11yProps['aria-label'] = column.props['aria-label'] || label || dataKey;
      a11yProps.tabIndex = 0;
      a11yProps.onClick = onClick;
      a11yProps.onKeyDown = onKeyDown;
    }
    if (sortBy === dataKey) {
      a11yProps['aria-sort'] =
        sortDirection === SortDirection.ASC ? 'ascending' : 'descending';
    }

    if (id) {
      a11yProps.id = id;
    }

    return (
      <div
        {...a11yProps}
        key={`Header-Col${index}`}
        className={classes}
        style={style}>
        {renderedHeader}
      </div>
    );
  }

  createRow({ rowIndex: index, key, style, columns }) {
    const { rowClassName, rowGetter, rowRenderer, rowStyle } = this.props;

    const rowClass =
      typeof rowClassName === 'function'
        ? rowClassName({ index })
        : rowClassName;
    const rowStyleObject =
      typeof rowStyle === 'function' ? rowStyle({ index }) : rowStyle;
    const rowData = rowGetter({ index });

    const className = classNames('Table__Row', rowClass);
    const flattenedStyle = {
      ...style,
      ...rowStyleObject
    };

    return rowRenderer({
      className,
      columns,
      index,
      key,
      rowData,
      style: flattenedStyle
    });
  }

  createColumn({ column, columnIndex, rowData, rowIndex }) {
    if (!column) {
      return null;
    }

    const {
      cellDataGetter,
      cellRenderer,
      className,
      columnData,
      dataKey,
      id,
      align
    } = column.props;

    const cellData = cellDataGetter({ columnData, dataKey, rowData });
    const renderedCell = cellRenderer({
      cellData,
      columnData,
      columnIndex,
      dataKey,
      rowData,
      rowIndex
    });

    const style = this.columnStyles[columnIndex];

    const title = typeof renderedCell === 'string' ? renderedCell : null;

    const a11yProps = {
      role: columnIndex === 0 ? 'rowheader' : 'cell'
    };

    if (id) {
      a11yProps['aria-describedby'] = id;
    }

    return (
      <div
        {...a11yProps}
        key={`Row${rowIndex}-Col${columnIndex}`}
        className={classNames('Table__Col', className, {
          [`Table__Col--${align}`]: !!align
        })}
        style={style}
        title={title}>
        {renderedCell}
      </div>
    );
  }

  render() {
    const {
      bodyColumnClassName,
      children,
      id,
      style,
      headerColumnClassName,
      headerRowRenderer,
      rowCount,
      rowGetter
    } = this.props;
    const childrenArray = Children.toArray(children);
    const [fixedColumn, ...fluidColumns] = childrenArray;

    const headerColClasses = classNames(
      BODY_CLASS,
      `${BODY_CLASS}--fixed`,
      headerColumnClassName
    );
    const bodyColClasses = classNames(
      BODY_CLASS,
      `${BODY_CLASS}--scroll`,
      bodyColumnClassName
    );

    this.columnStyles = childrenArray.map(column => ({
      ...this.getFlexStyleForColumn(column, column.props.style)
    }));

    return (
      <StickyContainer>
        <div className="Table" role="table" id={id} style={style}>
          <Measure bounds>
            {({ measureRef, contentRect }) => (
              <div
                ref={measureRef}
                className={headerColClasses}
                role="rowgroup">
                {headerRowRenderer({
                  className: 'Table__HeaderRow',
                  columns: this.getHeaderColumns([fixedColumn]),
                  dimensions: contentRect.bounds,
                  width: '100%'
                })}
                {times(rowCount, index =>
                  this.createRow({
                    rowIndex: index,
                    key: `Row${index}`,
                    columns: [
                      this.createColumn({
                        column: fixedColumn,
                        columnIndex: 0,
                        rowData: rowGetter({ index }),
                        rowIndex: index
                      })
                    ]
                  })
                )}
              </div>
            )}
          </Measure>
          <Measure bounds>
            {({ measureRef, contentRect }) => (
              <div ref={measureRef} className={bodyColClasses} role="rowgroup">
                {headerRowRenderer({
                  className: 'Table__HeaderRow',
                  columns: this.getHeaderColumns(fluidColumns),
                  dimensions: contentRect.bounds,
                  width: fluidColumns.reduce(
                    (acc, column) => acc + column.props.width,
                    0
                  )
                })}
                {times(rowCount, index =>
                  this.createRow({
                    rowIndex: index,
                    key: `Row${index}`,
                    columns: fluidColumns.map((column, columnIndex) =>
                      this.createColumn({
                        column,
                        columnIndex: columnIndex + 1,
                        rowData: rowGetter({ index }),
                        rowIndex: index
                      })
                    )
                  })
                )}
              </div>
            )}
          </Measure>
        </div>
      </StickyContainer>
    );
  }
}

export default Table;
