const PREFIX = '[SOCKET]';
export const SOCKET_INIT_CONNECTION = `${PREFIX} INIT_CONNECTION`;
export const SOCKET_CLOSE_CONNECTION = `${PREFIX} CLOSE_CONNECTION`;
export const SOCKET_CONNECTING = `${PREFIX} CONNECTING`;
export const SOCKET_CONNECTED = `${PREFIX} CONNECTED`;
export const SOCKET_DISCONNECTED = `${PREFIX} DISCONNECTED`;
export const SOCKET_ERROR = `${PREFIX} ERROR`;
export const SOCKET_SEND_MESSAGE = `${PREFIX} SEND_MESSAGE`;
export const SOCKET_SUBSCRIBE = `${PREFIX} EVENT_SUBSCRIBE`;
export const SOCKET_UNSUBSCRIBE = `${PREFIX} EVENT_UNSUBSCRIBE`;

export const connectToSocket = socketId => ({
  type: SOCKET_INIT_CONNECTION,
  meta: { socketId }
});

export const disconnectFromSocket = socketId => ({
  type: SOCKET_CLOSE_CONNECTION,
  meta: { socketId }
});

export const socketConnecting = socketId => ({
  type: SOCKET_CONNECTING,
  meta: { socketId }
});

export const socketConnected = socketId => ({
  type: SOCKET_CONNECTED,
  meta: { socketId }
});

export const socketDisconnected = socketId => ({
  type: SOCKET_DISCONNECTED,
  meta: { socketId }
});

export const socketError = (socketId, error) => ({
  type: SOCKET_ERROR,
  payload: error,
  meta: { socketId }
});

export const subscribeToEvents = (socketId, events = []) => ({
  type: SOCKET_SUBSCRIBE,
  payload: events,
  meta: {
    socketId
  }
});

export const unsubscribeFromEvents = (socketId, events = []) => ({
  type: SOCKET_UNSUBSCRIBE,
  payload: events,
  meta: {
    socketId
  }
});

export const sendSocketMessage = (
  socketId,
  messageType,
  payload,
  throttleMs
) => ({
  type: SOCKET_SEND_MESSAGE,
  payload,
  meta: {
    messageType,
    socketId,
    throttle: throttleMs
  }
});
