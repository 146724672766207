import PropTypes from 'prop-types';
import React from 'react';
import Avatar from '../Avatar';
import { Size } from '../../../constants';
import { imageDetailsType } from '../../types';
import { getUrl } from '../../selectors/images';
import './ProfileCard.css';

function ProfileCard({ image, name, children }) {
  let backgroundImage =
    'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))';

  if (getUrl(image)) {
    backgroundImage += `, url(${getUrl(image)})`;
  }

  return (
    <div className="profile-card" style={{ backgroundImage }}>
      <div className="profile-card__title">
        <Avatar name={name} image={image} size={Size.sm} showTooltip={false} />
        <span className="profile-card__name">{name}</span>
      </div>
      {children && <div className="profile-card__footer">{children}</div>}
    </div>
  );
}

ProfileCard.propTypes = {
  image: imageDetailsType,
  name: PropTypes.string
};

export default ProfileCard;
