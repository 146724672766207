import { FORBIDDEN, UNAUTHORIZED, BAD_REQUEST } from 'http-status-codes';
import * as Sentry from '@sentry/browser';
import paths from '../paths';
import authPaths from '../authentication/paths';
import history from '../history';
import { clearAlerts } from '../common/actions';
import authService from '../authentication/services/auth.service';

export default function(dispatch, getState, { status, resource, error }) {
  dispatch(clearAlerts());

  switch (status) {
    case UNAUTHORIZED:
      // user isn't on login page entering invalid password
      if (resource !== authPaths.login()) {
        authService.logout(true);
      }
      break;

    case FORBIDDEN:
      history.push(paths.error({ reason: status, resource }));
      break;

    case BAD_REQUEST:
      Sentry.addBreadcrumb({
        category: 'api',
        message: resource,
        data: error,
        level: Sentry.Severity.Error
      });
      break;

    default:
  }
}
