import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Button from '@bit/be-novative.kit.button';
import Icon from '../../../common/components/Icon';
import { ImageTypes, Size } from '../../../constants';
import { prependBase64Content } from '../../../utils/payloadUtils/image';
import PickerWithTabNav from './PickerWithTabNav';
import scss from './ConceptImageInput.module.scss';

ConceptImageInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.shape({
      '@type': PropTypes.oneOf(values(ImageTypes)).isRequired,
      url: PropTypes.string,
      content: PropTypes.string
    })
  })
};
function ConceptImageInput({ input = {}, t }) {
  const { onChange, value } = input;
  const [shouldShowPicker, setShouldShowPicker] = useState(false);
  const shouldShowEmpty =
    !get(value, 'url') && !get(value, 'content') && !shouldShowPicker;

  const handleSelect = useCallback(
    image => {
      onChange(image);
      setShouldShowPicker(false);
    },
    [onChange]
  );

  if (shouldShowEmpty) {
    return (
      <div className={scss.Wrapper}>
        <Button variant="outline" onClick={() => setShouldShowPicker(true)}>
          <Icon type="image" size={Size.lg} />
          <span>{t('common/prop/image/addCTA')}</span>
        </Button>
      </div>
    );
  }

  if (shouldShowPicker) {
    return (
      <div className={scss.Wrapper}>
        <PickerWithTabNav onSelect={handleSelect} />
      </div>
    );
  }

  const url = value.url || prependBase64Content(value.content);

  return (
    <div className={scss.Wrapper}>
      <div className={scss.ContentWrapper}>
        <img src={url} alt="" className={scss.Img} />
        <div className={scss.Tools}>
          <Button variant="link" onClick={() => setShouldShowPicker(true)}>
            <Icon type="pencil" size={Size.md} />
            <span>{t('common/userAction/edit')}</span>
          </Button>
          <Button variant="link" onClick={() => handleSelect(null)}>
            <Icon type="bin" size={Size.md} />
            <span>{t('common/userAction/remove')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default translate()(ConceptImageInput);
