import flow from 'lodash/flow';
import isString from 'lodash/isString';
import map from 'lodash/fp/map';
import uniq from 'lodash/uniq';
import { maybeHtmlFromState } from '@bit/be-novative.kit.richtext-utils';
import { conceptEditorFormFields } from '../constants';
import { convertInviteeToIdString } from '../../utils/payloadUtils/invitee';
import { getCategoryId } from '../../common/getters/categories';

const {
  BENEFITS,
  CATEGORY,
  CATEGORY_ID,
  COSTS,
  FOLLOW_UP_CHALLENGE,
  IDEA_TEXT,
  IMAGE,
  INVITED_USERS,
  IS_PRIVATE,
  PLANNED_ACTIVITIES,
  CONCEPT_STATE,
  TEAM,
  TITLE
} = conceptEditorFormFields;

export default function createConceptPayload(payload = {}) {
  const benefitsHtml = maybeHtmlFromState(payload[BENEFITS]);
  const costsHtml = maybeHtmlFromState(payload[COSTS]);
  const ideaTextHtml = maybeHtmlFromState(payload[IDEA_TEXT]);
  const plannedActivitiesHtml = maybeHtmlFromState(payload[PLANNED_ACTIVITIES]);
  const state = payload[CONCEPT_STATE];

  const category = payload[CATEGORY] || payload[CATEGORY_ID];
  const categoryId = isString(category) ? category : getCategoryId(category);

  const mappedInvitedUsers = flow(
    map(convertInviteeToIdString),
    uniq
  )(payload[TEAM]);

  return {
    [TITLE]: payload[TITLE],
    [IDEA_TEXT]: ideaTextHtml,
    [BENEFITS]: benefitsHtml,
    [COSTS]: costsHtml,
    [PLANNED_ACTIVITIES]: plannedActivitiesHtml,
    [IMAGE]: payload[IMAGE],
    [CONCEPT_STATE]: state,
    [IS_PRIVATE]: Boolean(payload[IS_PRIVATE]),
    [INVITED_USERS]: mappedInvitedUsers, // GET endpoint sends `team`, PUT/POST expects `invitedUsers`
    [CATEGORY_ID]: categoryId,
    [FOLLOW_UP_CHALLENGE]: payload[FOLLOW_UP_CHALLENGE]
  };
}
