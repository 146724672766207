import FileSaver from 'file-saver';

export const DEFAULT_FILE_NAME = 'download';
export const DEFAULT_MIME = 'application/octet-stream';

export function saveFile(
  data,
  fileName = DEFAULT_FILE_NAME,
  mimeType = DEFAULT_MIME
) {
  try {
    const file = new Blob([data], {
      type: mimeType
    });
    return FileSaver.saveAs(file, fileName);
  } catch (err) {
    throw new Error(err);
  }
}

export function saveJson(data, fileName) {
  return saveFile(
    JSON.stringify(data),
    fileName,
    'application/json;charset=utf-8'
  );
}

export function saveExcel(data, fileName) {
  return saveFile(data, fileName, 'application/excel;charset=utf-8');
}
