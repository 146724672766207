import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import escapeRegExp from 'lodash/escapeRegExp';
import {
  registerField,
  formValueSelector,
  change,
  initialize,
  touch
} from 'redux-form';
import { Autosuggest, formControl } from '../../../common/components';

class AutosuggestFormControl extends Component {
  static defaultProps = {
    onFilterChange: () => {},
    getValue: s => s,
    getDisplayValue: s => s,
    choices: []
  };

  componentDidMount() {
    const { meta, input, registerField, change, getDisplayValue } = this.props;
    this.filterName = `${input.name}Filter`;
    registerField(meta.form, this.filterName, 'Field');
    if (input.value) {
      change(meta.form, this.filterName, getDisplayValue(input.value));
    }
  }

  handleFilterChange = (event, { newValue }) => {
    const { meta, input, change, onFilterChange, touch } = this.props;
    change(meta.form, this.filterName, newValue);
    touch(meta.form, input.name);
    onFilterChange(newValue);
  };

  handleFilterBlur = () => {
    const { meta, input, filterValue, change, getDisplayValue } = this.props;
    if (!input.value || filterValue !== getDisplayValue(input.value)) {
      change(meta.form, input.name, null);
      change(meta.form, this.filterName, '');
    }
  };

  handleSuggestionSelect = (event, { suggestion }) => {
    const { meta, input, change } = this.props;
    change(meta.form, input.name, suggestion);
  };

  renderSuggestion = s => {
    const { getDisplayValue } = this.props;
    return <span>{getDisplayValue(s)}</span>;
  };

  shouldRenderSuggestions = value => {
    return true;
  };

  focus() {
    if (this.el) {
      this.el.focus();
    }
  }

  setRef = el => {
    this.el = el;
  };

  render() {
    const {
      filterValue = '',
      placeholder,
      meta,
      input,
      suggestions,
      getValue,
      ...rest
    } = this.props;

    return (
      <Autosuggest
        {...rest}
        meta={meta}
        input={input}
        ref={this.setRef}
        inputProps={{
          value: filterValue,
          placeholder,
          onChange: this.handleFilterChange,
          onBlur: this.handleFilterBlur
        }}
        suggestions={suggestions}
        onSuggestionSelected={this.handleSuggestionSelect}
        getSuggestionValue={getValue}
        renderSuggestion={this.renderSuggestion}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { meta, input, choices, getDisplayValue = v => v } = props;
  const filterValue = formValueSelector(meta.form)(
    state,
    `${input.name}Filter`
  );

  const re = new RegExp(escapeRegExp(filterValue), 'ig');
  const suggestions = choices.filter(choice => {
    return re.test(getDisplayValue(choice));
  });

  return {
    filterValue,
    suggestions
  };
}

export default compose(
  formControl,
  connect(
    mapStateToProps,
    {
      registerField,
      change,
      initialize,
      touch
    },
    null,
    {
      forwardRef: true
    }
  )
)(AutosuggestFormControl);
