import React, { useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import {
  SUPPORTED_UI_LANGUAGES,
  SUPPORTED_UI_LANGUAGES_NATIVE
} from '../../constants';
import paths from '../../paths';
import { resetOnboarding, changeLanguage, closeSidenav } from '../actions';
import { onboardingTipsSelector } from '../reducers/onboarding';
import { getCurrentLanguage } from '../selectors/language';
import Menu, { MenuItem, SubmenuItem } from '../components/Menu';
import Drawer from '../components/Drawer';
import Progress from '../components/Progress';
import ProfileCard from '../components/ProfileCard';
import { renderWhenAuthenticated, renderWhenAdmin } from './authorization';
import localiseUserName from '../../utils/localiseUserName';
import { getWorkPosition } from '../../profile/getters';
import { ownProfileSelector } from '../../profile/state/profile';
import { getUserAvatar } from '../getters/users';
import { AuthContext } from '../../authentication/components/AuthProvider';

const AdminMenuItem = renderWhenAdmin(MenuItem);

function Sidenav({
  avatar,
  changeLanguage,
  closeSidenav,
  currentLanguage,
  opened,
  resetOnboarding,
  t,
  tips,
  title,
  userName
}) {
  const authCtx = useContext(AuthContext);

  const handleLanguageClick = useCallback(
    lang => {
      changeLanguage(lang);
      closeSidenav();
    },
    [changeLanguage, closeSidenav]
  );

  return (
    <Drawer opened={opened} onCloseClick={closeSidenav}>
      <Progress />
      <ProfileCard image={avatar} name={userName}>
        {title}
      </ProfileCard>
      <Progress />
      <Menu>
        <MenuItem to={paths.profile()} icon="account">
          {t('common/My Profile')}
        </MenuItem>
        {false && (
          <MenuItem to={paths.notifications()} icon="globe">
            {t('common/Notifications')}
          </MenuItem>
        )}
        {tips && (
          <MenuItem
            onClick={() => {
              resetOnboarding();
              closeSidenav();
            }}
            icon="info">
            {t('common/Tooltips')}
          </MenuItem>
        )}
        <MenuItem
          submenu={SUPPORTED_UI_LANGUAGES.map(lang => (
            <SubmenuItem
              key={lang}
              active={currentLanguage === lang}
              onClick={() => handleLanguageClick(lang)}>
              {SUPPORTED_UI_LANGUAGES_NATIVE[lang]}
            </SubmenuItem>
          ))}
          icon="language">
          {t('common/Language')}:{' '}
          {SUPPORTED_UI_LANGUAGES_NATIVE[currentLanguage]}
        </MenuItem>
        <AdminMenuItem to={paths.generalAdmin()} icon="cog">
          {t('common/Admin')}
        </AdminMenuItem>
        <MenuItem
          onClick={() => authCtx.logoutUser(true)}
          icon="cog"
          highlighted>
          {t('common/Logout')}
        </MenuItem>
      </Menu>
    </Drawer>
  );
}

function mapStateToProps(state, { t }) {
  const profile = ownProfileSelector(state);

  return {
    avatar: getUserAvatar(profile),
    currentLanguage: getCurrentLanguage(state),
    opened: state.sidenav.open,
    tips: onboardingTipsSelector(state),
    title: getWorkPosition(profile),
    userName: localiseUserName(t, profile)
  };
}

export default compose(
  renderWhenAuthenticated,
  translate(),
  connect(
    mapStateToProps,
    {
      resetOnboarding,
      changeLanguage,
      closeSidenav
    }
  )
)(Sidenav);
