import React from 'react';
import { TableBody } from './ConceptTable.styled';
import { useScrollParent } from './ScrollSpyProvider';

export default function TableBodyWithScroll({ children }) {
  const scrollRef = useScrollParent();
  return (
    <TableBody role="rowgroup" ref={scrollRef}>
      {children}
    </TableBody>
  );
}
