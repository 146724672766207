import React, { useContext } from 'react';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Size } from '../../../constants';
import { getCreator } from '../../../common/selectors/meta';
import { AuthContext } from '../../../authentication/components/AuthProvider';
import {
  getConceptById,
  getOwner,
  getRevealTime,
  getTeam,
  isQuickIdeaConcept
} from '../../selectors/ideaConcepts';
import ConnectedAvatarList from '../ConnectedAvatarList';
import mapVisibilityInfo from './mapVisibilityInfo';

ConceptTeamAvatars.propTypes = {
  avatarClassName: PropTypes.string,
  avatarSize: PropTypes.oneOf(values(Size)),
  ideaConceptId: PropTypes.string.isRequired,
  maxDisplay: PropTypes.number
};
export default function ConceptTeamAvatars({
  avatarClassName,
  avatarSize,
  className,
  ideaConceptId,
  maxDisplay
}) {
  const { userId: currentUserId } = useContext(AuthContext);
  const concept = useSelector(getConceptById(ideaConceptId));
  const creatorId = getCreator(concept);
  const ownerId = getOwner(concept);
  const revealedAt = getRevealTime(concept);
  const forceReveal = isQuickIdeaConcept(concept);
  const team = getTeam(concept);
  const visibilityMapper = mapVisibilityInfo({
    creatorId,
    currentUserId,
    forceReveal,
    ownerId,
    revealedAt
  });
  const mappedMembers = team.map(visibilityMapper);
  return (
    <ConnectedAvatarList
      avatarClassName={avatarClassName}
      avatarSize={avatarSize}
      className={className}
      maxDisplay={maxDisplay}
      users={mappedMembers}
    />
  );
}
