import { set } from 'dot-prop-immutable';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  ITEM_POS_CHANGED,
  USER_DRAGS_ITEM,
  USER_STOPS_DRAGGING
} from '../actions/movement';
import { getPosX, getPosY } from '../../dndCanvas/selectors/canvasItem';
import { FLUSH_DATA } from '../actions/presence';

const position = handleActions(
  {
    [ITEM_POS_CHANGED]: (state, { payload }) =>
      produce(state, state => {
        payload.forEach(item => {
          state[item.id] = [getPosX(item), getPosY(item)];
        });
      }),
    [FLUSH_DATA]: () => ({})
  },
  {}
);

const lastMoved = handleActions(
  {
    [USER_DRAGS_ITEM]: (state, { meta }) => {
      return set(state, meta.id, meta.movedAt);
    },
    [FLUSH_DATA]: () => ({})
  },
  {}
);

const itemMovedByUser = handleActions(
  {
    // TODO filter for own user ID when API change is implemented
    [USER_DRAGS_ITEM]: (_, { meta }) => meta.id,
    [USER_STOPS_DRAGGING]: () => null,
    [FLUSH_DATA]: () => null
  },
  null
);

export default combineReducers({
  position,
  lastMoved,
  itemMovedByUser
});
