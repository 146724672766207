import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import getFp from 'lodash/fp/get';
import flow from 'lodash/flow';
import truncate from 'lodash/truncate';
import upperFirst from 'lodash/upperFirst';

/**
 * @param {Category} category
 * @return {string} The category ID
 */

export const getCategoryId = category => get(category, 'categoryId', '');

/**
 * Returns the (optionally truncated) name corresponding to the supplied category ID
 * @param {Category} category
 * @param {number} [maxLength] - The length above which the category name will be truncated
 * @return {string} The category name
 */

export const getCategoryName = (category, maxLength) =>
  flow(
    getFp('name'),
    upperFirst,
    name =>
      isNumber(maxLength)
        ? truncate(name, { length: maxLength, omission: '…' })
        : name
  )(category);
