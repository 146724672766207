import client from './client';

/**
 * Sets the `Authorization` header for the API client singleton as a side effect
 * @param {string} token - The user's current token
 */

export default function setApiAuthHeader(token) {
  client.defaults.headers.common['Authorization'] = token;
}
