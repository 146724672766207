import React from 'react';
import './SecondaryNavigationGroup.css';

function SecondaryNavigationGroup({ children, title }) {
  return (
    <li className="SecondaryNavigationGroup">
      <div className="SecondaryNavigationGroup__Title">
        {title}
      </div>
      <ul className="SecondaryNavigationGroup__Items">
        {children}
      </ul>
    </li>
  );
}

export default SecondaryNavigationGroup;
