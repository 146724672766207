import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import omit from 'lodash/omit';
import { AuthException } from '../constants';
import addBreadcrumb, { Severity } from '../../telemetry/addBreadcrumb';
import { convertApiErrorToStatusCode } from '../../api/utils/apiError';
import {
  getApiResponse,
  getApiResponseCode,
  getApiResponseData
} from '../../api/utils/apiResponse';
import setApiAuthHeader from '../../api/setAuthHeader';
import authApi from '../api/authApi';
import signupApi from '../api/signupApi';
import { setToken, clearToken } from './token.service';

/**
 * Authenticates the user with either a password or through SSO
 * @param {Object} loginData
 * @param {string} loginData.email - The account email
 * @param {string} [loginData.password] - The account password
 * @param {string} [loginData.authenticationToken] - The token received from the SSO provider
 * @return {Promise} The pending request
 */

function login({ email, password, authenticationToken: token }) {
  if (token) {
    logEvent({
      message: 'Logging in with provider...',
      data: { provider: token['@type'] }
    });
  } else {
    logEvent({ message: 'Logging in with password...' });
  }
  return authApi
    .loginWithEmail(email, { password, token })
    .then(handleAuthSuccess)
    .catch(handleAuthFail);
}

/**
 * Logs in the user with the single-use magic code received via email
 * @param {string} code - The single-use access code
 * @return {Promise} The pending request
 */

function magicLogin(code) {
  logEvent({
    message: 'Logging in with magic link...'
  });
  return authApi
    .loginWithOneTimeCode(code)
    .then(handleAuthSuccess)
    .catch(handleAuthFail);
}

function loginWithService() {
  logEvent('Logging in with Auth Svc. Clearing token & redirecting...');
  window.location.href = `/auth?redirectTo=${window.location.pathname}`;
}

/**
 * Persists the token after successful authentication
 * @private
 * @param {Object} response - API response
 */

function handleAuthSuccess(response) {
  const token = get(response, ['headers', 'authorization']);
  logEvent({ message: 'Auth success, persisting token', data: token });
  handleNewToken(token);
}

/**
 * Converts the API error to an internal code and throws it
 * @param {Error} error - The failed request result
 * @return {(CommonException|AuthException)} The error reason represented with an internal symbol
 */

function handleAuthFail(error) {
  const {
    PlatformNotFound,
    InvalidLogin,
    AlreadyHasAccount,
    EmailNotAllowed
  } = AuthException;
  let errorReason;
  switch (getApiResponseCode(error)) {
    case 403:
      errorReason = EmailNotAllowed;
      break;
    case 404:
      errorReason = InvalidLogin;
      break;
    case 409:
      errorReason = AlreadyHasAccount;
      break;
    case 500:
      const statusText = flow(
        getApiResponse,
        getFp('statusText')
      )(error);
      if (statusText === PlatformNotFound) {
        errorReason = PlatformNotFound;
      } else {
        errorReason = convertApiErrorToStatusCode(error);
      }
      break;
    default:
      errorReason = convertApiErrorToStatusCode(error);
  }

  logEvent({ message: errorReason, level: Severity.Warning });
  throw errorReason;
}

/**
 * Persists the token, and sets it as an `Authorization` header for the API client singleton. Logs out user on error.
 * @param {string} token - A valid token
 * @public
 * @function
 */

function handleNewToken(token) {
  try {
    setApiAuthHeader(token);
    setToken(token);
  } catch (error) {
    logout(true);
  }
}
function switchPlatform(companyId) {
  return authApi.switchPlatform(companyId).then(getApiResponseData);
}

/**
 * Erases the user's token and optionally reloads the page so the user is redirected to the login page
 * @param {boolean} reloadPage - Whether to force reload the page after the token was erased
 */

function logout(reloadPage = false) {
  logEvent('User logout. Clearing token & redirecting to Auth Svc');
  clearToken();
  window.location.href = `/auth/Identity/Account/Logout?returnUrl=${window.location.pathname}`;
}

/**
 * Creates a user account
 * @param {UserSignupPayload} userData - The account details
 * @param {string} captchaToken - Token that must be sent to the API for verification
 * @param {string} [invitationCode] - The invitation code decoded from a magic link, in case the user was invited to the platform
 * @return {Promise} The pending request
 */

function signup(formData = {}, captchaToken, invitationCode) {
  logEvent({
    message: 'Creating new account...',
    data: { ...omit(formData, ['password']), captchaToken, invitationCode }
  });

  return signupApi
    .signupUser(formData, captchaToken, invitationCode)
    .then(handleAuthSuccess)
    .catch(handleAuthFail);
}

/**
 * Logs a breadcrumb to Sentry
 * @private
 * @param {Object} breadcrumb
 * @param {string} breadcrumb.message - The log message
 * @param {Sentry.Severity} [breadcrumb.level] - The log level
 * @param {Object} [breadcrumb.data] - Additional data
 */

function logEvent({ message, level = Severity.Verbose, data }) {
  addBreadcrumb(message, {
    category: 'authentication',
    level,
    data
  });
}

export default {
  login,
  loginWithService,
  magicLogin,
  handleAuthSuccess,
  handleAuthFail,
  handleNewToken,
  switchPlatform,
  logout,
  signup
};
