import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { translate } from 'react-i18next';
import useChallengeSearch from '../../../challenge/hooks/useChallengeSearch';
import Spinner from '@bit/be-novative.kit.spinner';
import SearchInput from '../../../common/components/SearchInput';
import ChallengeList from './ChallengeList';
import scss from './ChallengeFilter.module.scss';

const MIN_SEARCH_TERM_LENGTH = 3;

ChallengeSearch.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selections: PropTypes.arrayOf(PropTypes.string)
};
ChallengeSearch.defaultProps = {
  selections: []
};
function ChallengeSearch({ onSelect, selections, t, ...rest }) {
  const [isPristine, setIsPristine] = useState(true);
  const [listItems, setListItems] = useState([]);
  const [handleSearch, isSearching] = useChallengeSearch();

  const handleQueryInput = useCallback(
    async query => {
      setListItems([]);

      if (isEmpty(query) || query.length < MIN_SEARCH_TERM_LENGTH) {
        return;
      }
      setIsPristine(false);

      const results = await handleSearch(query);
      setListItems(existing => [...existing, ...results]);
    },
    [handleSearch]
  );

  return (
    <section className={scss.Content} {...rest}>
      <SearchInput onSearch={handleQueryInput} style={{ marginTop: '1em' }} />
      <main className={scss.List}>
        {isSearching && <Spinner type="bubble" centered />}

        {!size(listItems) && !isPristine && (
          <div style={{ textAlign: 'center' }}>
            {t('challenge/filterForm/search/empty')}
          </div>
        )}

        <ChallengeList
          items={listItems}
          onSelect={onSelect}
          selections={selections}
        />
      </main>
    </section>
  );
}

export default translate()(ChallengeSearch);
