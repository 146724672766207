import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

function defaultCellDataGetter({ dataKey, rowData }) {
  if (isFunction(rowData.get)) {
    return rowData.get(dataKey);
  } else if (isString(rowData)) {
    return rowData;
  } else {
    return rowData[dataKey];
  }
}

export default defaultCellDataGetter;
