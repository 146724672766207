import action from './action';
import averageScore from './averageScore';
import conceptTitle from './conceptTitle';
import criterionScore from './criterionScore';
import feedback from './feedback';
import internalComments from './internalComments';
import owner from './owner';
import startTime from './startTime';
import status from './status';
import team from './team';

export default {
  action,
  averageScore,
  conceptTitle,
  criterionScore,
  feedback,
  internalComments,
  owner,
  startTime,
  status,
  team
};
