import EventTracker from './_EventTracker';

const EVENT_GROUP = 'header';

class HeaderTracker extends EventTracker {
  constructor(metrics) {
    super(metrics);
    this.eventGroup = EVENT_GROUP;
  }

  track(action, userId) {}
}

export default HeaderTracker;
