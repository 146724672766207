import has from 'lodash/has';
import values from 'lodash/values';
import { CanvasBackgroundType } from './constants';

export const CONCEPT_CANVAS_ROOT = 'canvas';

export function conceptCanvasPage(ideaConceptId, canvasType) {
  if (!has(CanvasBackgroundType, canvasType)) {
    throw new Error(
      `canvasType must be one of ${values(
        CanvasBackgroundType
      )}, got ${canvasType}`
    );
  }
  return `/${CONCEPT_CANVAS_ROOT}/${ideaConceptId}?canvas=${canvasType}`;
}

export function conceptMapCanvasPage(ideaConceptId) {
  return `/${CONCEPT_CANVAS_ROOT}/${ideaConceptId}/conceptmap`;
}
