import flow from 'lodash/flow';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { entities } from '../../constants';
import { getEntity, getEntityMap } from './entities';
import { convertUserToInvitee } from '../../utils/payloadUtils/invitee';

// Collection selectors

export const getUserEntity = getEntity(entities.USER);

export const getUserById = getUserEntity;

export const makeGetUserEntity = () =>
  createSelector(
    getEntityMap(entities.USER),
    (_, userId) => userId,
    (users, id) => get(users, id)
  );

export const getUserByIdAsInvitee = userId => state =>
  flow(
    getUserEntity(userId),
    convertUserToInvitee
  )(state);
