import { createAction } from 'redux-actions';
import { ALERT_CLEAR, ALERT_DISMISS, ALERT_SEND } from '../../constants';

export const dismissAlert = createAction(ALERT_DISMISS, id => id);
export const clearAlerts = createAction(ALERT_CLEAR);

const DEFAULTS = {
  kind: '',
  message: '',
  dismissAfterMs: 6000,
  id: null
};

/**
 * Displays a global toast message. Only one message is displayed at a time.
 * @param {Object} alert - Alert config object
 * @param {"success" | "info" | "warning" | "danger"} [alert.kind] - Controls the color scheme of the toast message. Defaults to `info`.
 * @param {string} alert.message - The message to display in the toast.
 * @param {number} [alert.dismissAfterMs] - The timeout after which the message should disappear. Defaults to `6000`.
 * @param {any} [alert.id] - Optional ID for the message. Defaults to the current timestamp.
 * @returns
 */
export const sendAlert = (alert = DEFAULTS) => dispatch => {
  const config = {
    ...DEFAULTS,
    ...alert,
    id: alert.id || new Date().getTime()
  };

  dispatch({ type: ALERT_SEND, payload: config });

  if (config.dismissAfterMs) {
    setTimeout(() => {
      dispatch({
        type: ALERT_DISMISS,
        payload: config.id
      });
    }, config.dismissAfterMs);
  }

  return config.id;
};
