import isFunction from 'lodash/isFunction';
import { FORM_ERROR } from 'final-form';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createIdeaConcept } from '../../common/actions';

export default function useSubmitQuickConceptForm({ onSuccess }) {
  const dispatch = useDispatch();
  const submitInsight = useCallback(
    async values => {
      try {
        await dispatch(createIdeaConcept(values));
        if (isFunction(onSuccess)) {
          onSuccess();
        }
      } catch (error) {
        return { [FORM_ERROR]: error };
      }
    },
    [dispatch, onSuccess]
  );
  return submitInsight;
}
