import isNumber from 'lodash/isNumber';
import round from 'lodash/round';
import React from 'react';
import { translate } from 'react-i18next';
import CriterionScoreIndicator from '../../../components/CriterionScoreIndicator';
import { EMPTY_NUMBER } from '../../../constants';
import {
  getEvalCriterionId,
  getEvalCriterionName
} from '../../../selectors/conceptEvaluation';
import { getEvalScoreArray } from '../../../selectors/ideaConcepts';
import {
  AlignedCellContent,
  SingleLineHeight,
  TitleCellLabel
} from '../ConceptTable.styled';
import getUserEvals from './getUserEvals';
import ScrollAnchor from './ScrollAnchor';

const ScoreLabel = translate()(({ t, value }) => {
  const labels = t('ideaConcept/evaluation/form/cepheidMarkers', {
    returnObjects: true
  });
  const label = labels[value - 1] || EMPTY_NUMBER;
  return <span>{label}</span>;
});

const criterionScore = {
  header: (t, concept, criterion, scrollIx) => {
    return (
      <AlignedCellContent justifyContent="flex-start">
        {isNumber(scrollIx) && <ScrollAnchor index={scrollIx} />}
        <TitleCellLabel>{getEvalCriterionName(criterion)}</TitleCellLabel>
      </AlignedCellContent>
    );
  },
  main: (t, concept, criterion) => {
    const criterionId = getEvalCriterionId(criterion);
    const score = getEvalScoreArray(concept).find(
      score => score.criterionId === criterionId
    );
    return (
      <AlignedCellContent justifyContent="flex-start" flexBasis="40px">
        <SingleLineHeight>
          <CriterionScoreIndicator
            style={{ fontSize: 14 }}
            rules={[
              {
                matcher: score => round(score, 0) === 3,
                labelRenderer: () => <ScoreLabel value={3} />
              },
              {
                matcher: score => round(score, 0) === 2,
                labelRenderer: () => <ScoreLabel value={2} />
              },
              {
                matcher: score => round(score, 0) === 1,
                labelRenderer: () => <ScoreLabel value={1} />
              },
              {
                matcher: () => true,
                labelRenderer: () => <span>{EMPTY_NUMBER}</span>
              }
            ]}
            value={score ? round(score.average, 0) : null}
          />
        </SingleLineHeight>
      </AlignedCellContent>
    );
  },
  expanded: (t, concept, criterion) => {
    return (
      <AlignedCellContent flexDirection="column" alignItems="flex-start">
        {/* Whitespace for "Reviewed by" label in row header cell */}
        <small>&nbsp;</small>
        {getUserEvals(concept)
          .map(evaluation => evaluation.scores)
          .map(userEval => {
            const id = getEvalCriterionId(criterion);
            const score = userEval[id];
            return (
              <AlignedCellContent key={id} justifyContent="flex-start">
                <CriterionScoreIndicator
                  style={{ fontSize: 12 }}
                  rules={[
                    {
                      matcher: score => round(score, 0) === 3,
                      labelRenderer: () => (
                        <>
                          {round(score, 0)} <ScoreLabel value={3} />
                        </>
                      )
                    },
                    {
                      matcher: score => round(score, 0) === 2,
                      labelRenderer: () => (
                        <>
                          {round(score, 0)} <ScoreLabel value={2} />
                        </>
                      )
                    },
                    {
                      matcher: score => round(score, 0) === 1,
                      labelRenderer: () => (
                        <>
                          {round(score, 0)} <ScoreLabel value={1} />
                        </>
                      )
                    },
                    {
                      matcher: () => true,
                      labelRenderer: () => <span>{EMPTY_NUMBER}</span>
                    }
                  ]}
                  value={score ? round(score, 0) : null}
                />
              </AlignedCellContent>
            );
          })}
      </AlignedCellContent>
    );
  }
};

export default criterionScore;
