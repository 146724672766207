/* eslint jsx-a11y/anchor-is-valid: 0 */
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import trim from 'lodash/trim';
import React, { Component } from 'react';
import classNames from 'classnames';
import ReactTagsInput from 'react-tagsinput';
import set from 'lodash/set';
import FormFeedback from '@bit/be-novative.kit.form-feedback';
import Autosuggest from '../Autosuggest';
import keycode from 'keycode';
import './TagsInput.css';

class TagsInput extends Component {
  static propTypes = {
    addKeys: PropTypes.arrayOf(PropTypes.number),
    deleteDisabled: PropTypes.bool,
    getSuggestionValue: PropTypes.func,
    input: PropTypes.shape({
      autoFocus: PropTypes.bool,
      value: PropTypes.arrayOf(PropTypes.string),
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func
    }),
    meta: PropTypes.shape({
      active: PropTypes.bool
    }),
    onSuggestionsClearRequested: PropTypes.func,
    onSuggestionsFetchRequested: PropTypes.func,
    renderSuggestion: PropTypes.func,
    separators: PropTypes.arrayOf(PropTypes.string),
    suggestions: PropTypes.array,
    tagDisplayProp: PropTypes.string,
    validationRegex: PropTypes.instanceOf(RegExp),
    validationError: PropTypes.string,
    maxLength: PropTypes.number,
    theme: PropTypes.shape({
      container: PropTypes.string,
      focused: PropTypes.string,
      input: PropTypes.string,
      tag: PropTypes.string,
      remove: PropTypes.string
    })
  };

  static defaultProps = {
    addKeys: [keycode('tab'), keycode('enter'), keycode('space')],
    suggestions: [],
    getSuggestionValue: suggestion => suggestion,
    getTagClassName: noop,
    onSuggestionsClearRequested: () => {},
    onSuggestionsFetchRequested: () => {},
    renderSuggestion: suggestion => <span>{suggestion}</span>,
    renderTag: tag => tag,
    separators: [';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r'],
    placeholder: 'Add a tag',
    label: 'Remove',
    validationRegex: /.*/,
    validationError: '',
    theme: {
      container: 'TagsInput',
      focused: 'TagsInput--focused',
      marked: 'TagsInput__Input--marked',
      input: 'TagsInput__Input',
      tag: 'TagsInput__Tag',
      remove: 'TagsInput__Remove'
    }
  };

  pasteSplit = data =>
    data.split(new RegExp(this.props.separators.join('|'))).map(trim);

  shouldRenderSuggestions = value => trim(value).length > 0;

  render() {
    const {
      tagDisplayProp,
      validationRegex,
      addKeys,
      input,
      placeholder,
      theme,
      marked,
      maxLength,
      meta
    } = this.props;
    const { onChange, onFocus, name, value } = input;

    return (
      <ReactTagsInput
        onChange={onChange}
        value={Array.isArray(value) ? value : []}
        className={theme.container}
        focusedClassName={theme.focused}
        inputProps={{
          className: classNames(theme.input, {
            [theme.marked]: marked,
            'TagsInput__Tag--error': meta.submitFailed && meta.error
          }),
          id: name,
          onFocus,
          name,
          placeholder,
          maxLength
        }}
        tagProps={{
          className: theme.tag,
          classNameRemove: theme.remove
        }}
        addKeys={addKeys}
        onlyUnique
        addOnPaste
        validationRegex={validationRegex}
        tagDisplayProp={tagDisplayProp}
        pasteSplit={this.pasteSplit}
        renderInput={this.renderAutocomplete}
        renderTag={this.renderTag}
      />
    );
  }

  renderAutocomplete = ({ addTag, ...props }) => {
    const {
      getSuggestionValue,
      suggestions,
      renderSuggestion,
      input,
      meta,
      onSuggestionsClearRequested,
      onSuggestionsFetchRequested,
      validationRegex,
      validationError,
      marked,
      tagDisplayProp
    } = this.props;

    // TODO [CLOUD-3482] figure out why these events need to be handled in a separate render fn
    const handleOnChange = (e, { newValue, method }) => {
      if (method === 'enter') {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const handleBlur = e => {
      if (props.value) {
        const tag = tagDisplayProp
          ? set({}, tagDisplayProp, props.value)
          : props.value;
        addTag(tag);
      }
    };

    const handleSuggestionSelect = (e, { suggestion }) => {
      addTag(suggestion);
    };

    const invalid = props.value && !validationRegex.test(props.value);

    return (
      <div>
        <Autosuggest
          suggestions={suggestions}
          marked={marked}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            ...props,
            name: input.name,
            autoFocus: input.autoFocus,
            onChange: handleOnChange,
            onBlur: handleBlur,
            onFocus: input.onFocus
          }}
          meta={meta}
          onSuggestionSelected={handleSuggestionSelect}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        />
        {invalid && (
          <FormFeedback intent="danger">{validationError}</FormFeedback>
        )}
      </div>
    );
  };

  renderTag = props => {
    const { input, renderTag, getTagClassName, label } = this.props;
    const {
      tag,
      key,
      className,
      classNameRemove,
      disabled,
      onRemove,
      getTagDisplayValue,
      ...other
    } = props;
    const getDisplayVal = isFunction(this.props.getTagDisplayValue)
      ? this.props.getTagDisplayValue
      : getTagDisplayValue;
    const tagClasses = classNames(className, getTagClassName(tag));
    const showDeleteBtn = !disabled && !input.disableDelete;

    return (
      <span key={key} className={tagClasses} {...other}>
        <span className="TagValue">{renderTag(getDisplayVal(tag))}</span>
        {showDeleteBtn && (
          <a
            className={classNameRemove}
            onClick={e => onRemove(key)}
            aria-label={label}>
            <span aria-hidden="true">×</span>
          </a>
        )}
      </span>
    );
  };
}

export default TagsInput;
