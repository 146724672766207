import { CHALLENGE_CREATE } from '../../constants';
import EventTracker from './_EventTracker';

const EVENT_GROUP = 'challengeCreation';

class ChallengeCreationTracker extends EventTracker {
  constructor(metrics) {
    super(metrics);
    this.eventGroup = EVENT_GROUP;
  }

  track(action, userId) {
    if (action.type === CHALLENGE_CREATE.SUCCESS) {
      this.coreTrack(action.type, {
        userId: userId,
        visibility: action.meta.data.visibility,
        numberOfInvitedUsers: action.meta.data.invitedUsers.length,
        numberOfHashtags: action.meta.data.hashtags.length
      });
    }
  }
}

export default ChallengeCreationTracker;
