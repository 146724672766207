import React from 'react';
import { translate } from 'react-i18next';
import { SortDirection } from '../../../api/constants';
import { getIdeaConceptId } from '../../selectors/ideaConcepts';
import ColHeader from './ColHeader';
import MeasuredCell from './MeasuredCell';
import { Row } from './ConceptTable.styled';

function RowGroup({
  cols = [],
  rows = [],
  controlsRowHeight,
  getCellSize,
  getExpandState,
  onExpandRow,
  onCellSizeChange,
  onSortUpdate,
  sortDisabled,
  sortBy,
  sortDirection,
  t
}) {
  return (
    <>
      <Row role="row">
        {cols.map(col => {
          const isSortActive = sortBy === col.id;
          let ariaSort = 'none';
          if (isSortActive) {
            ariaSort =
              sortDirection === SortDirection.Asc ? 'ascending' : 'descending';
          }
          return (
            <MeasuredCell
              key={`header_${col.id}`}
              id={`header_${col.id}`}
              onSizeChange={onCellSizeChange}
              scope="col"
              role={col.ariaRole || 'column'}
              ariaSort={ariaSort}
              width={col.width}>
              <ColHeader
                colId={col.id}
                onSortUpdate={onSortUpdate}
                sortDirection={sortBy === col.id ? sortDirection : null}
                sortDisabled={
                  (!col.renderHeader && !col.title) ||
                  col.sortDisabled ||
                  sortDisabled
                }>
                {col.renderHeader && col.renderHeader(t)}
                {!col.renderHeader && col.title && String(col.title)}
              </ColHeader>
            </MeasuredCell>
          );
        })}
      </Row>
      {rows.map(concept => {
        const rowId = getIdeaConceptId(concept);
        const rowState = getExpandState(rowId);
        return (
          <Row role="row" key={rowId}>
            {cols.map(col => {
              const cellId = controlsRowHeight
                ? `header_${rowId}`
                : `${rowId}_${col.id}`;
              return (
                <MeasuredCell
                  key={cellId}
                  id={cellId}
                  onSizeChange={controlsRowHeight ? onCellSizeChange : null}
                  role={col.ariaRole || 'column'}
                  width={getCellSize(`header_${col.id}`).width}
                  height={
                    controlsRowHeight
                      ? null
                      : getCellSize(`header_${rowId}`).height
                  }
                  expanded={rowState === 'open'}>
                  {col.renderMain
                    ? col.renderMain(t, concept, onExpandRow, rowState)
                    : String(concept)}
                  {rowState === 'open' && (
                    <>
                      {col.renderExpanded
                        ? col.renderExpanded(t, concept)
                        : null}
                    </>
                  )}
                </MeasuredCell>
              );
            })}
          </Row>
        );
      })}
    </>
  );
}

export default translate()(RowGroup);
