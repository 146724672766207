import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import cn from 'classnames';
import Backdrop from '../Backdrop';
import Button from '@bit/be-novative.kit.button';
import Icon, { ICON_SIZES } from '../Icon';
import Heading from '../Heading';
import Popover from '../Popover';
import './HelpIcon.css';

const baseClass = 'HelpIcon';

export const Tip = ({ title, description }) => (
  <div>
    <Heading level={4} className={`${baseClass}__Tip__Title`}>
      {title}
    </Heading>
    <p className={`${baseClass}__Tip__Description`}>{description}</p>
  </div>
);

export const FooterButton = ({ onClick, t }) => (
  <div style={{ textAlign: 'right' }}>
    <Button
      onClick={onClick}
      variant="success"
      className={`${baseClass}__Tip__Button`}>
      {t('common/helpIconCloseActionLabel')}
    </Button>
  </div>
);

const TranslatedFooterButton = translate()(FooterButton);

HelpIcon.propTypes = {
  iconClass: PropTypes.string,
  iconSize: PropTypes.oneOf(ICON_SIZES)
};
HelpIcon.defaultProps = {
  iconSize: 'md'
};
export function HelpIcon({
  children,
  className,
  description,
  iconClass,
  iconSize,
  title
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <span className={className}>
      <Popover.PopoverTrigger
        popover={<Tip title={title || children} description={description} />}
        popoverClassName={`${baseClass}__Popover`}
        action={<TranslatedFooterButton onClick={handleClose} />}
        closeable={true}
        showFooterStripe={false}
        onClose={handleClose}
        show={isOpen}>
        <span onClick={handleOpen} className={`${baseClass}__Trigger`}>
          <Icon
            type="info2"
            size={iconSize}
            className={cn(`${baseClass}__Trigger__Icon`, iconClass)}
          />
          {children}
        </span>
      </Popover.PopoverTrigger>
      <Backdrop visible={isOpen} onClick={handleClose} />
    </span>
  );
}

export default HelpIcon;
