import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import isUndefined from 'lodash/isUndefined';
import kebabCase from 'lodash/kebabCase';
import GridPropTypes from './PropTypes';
import { withResponsive } from '../Responsive';
import './Col.css';

const widths = ['xs', 'sm', 'md', 'lg', 'xl'];

const responsiveSuffix = value => (value === 'xs' ? '' : `@${value}`);

const popProp = (acc, size, props, suffix) => {
  const propName = size + suffix;
  const value = props[propName];
  if (isUndefined(value)) {
    return;
  }

  let className = `Col--${suffix ? `${kebabCase(suffix)}-` : ''}${value}${suffix
    ? ''
    : '-12'}`;

  className += responsiveSuffix(size);

  delete props[propName];
  acc.push(className);
};

function Col(props) {
  const {
    className,
    tag: Tag,
    stretch,
    first,
    last,
    unordered,
    ...rest
  } = props;

  const colClasses = widths.reduce((acc, size, i) => {
    popProp(acc, size, rest, '');
    popProp(acc, size, rest, 'Offset');
    popProp(acc, size, rest, 'Gutter');
    popProp(acc, size, rest, 'GutterX');
    popProp(acc, size, rest, 'GutterY');
    return acc;
  }, []);

  const classes = classNames(className, 'Col', ...colClasses, {
    'Col--stretch': stretch,
    [`Col--first${responsiveSuffix(first)}`]: !!first,
    [`Col--last${responsiveSuffix(last)}`]: !!last,
    [`Col--unordered${responsiveSuffix(unordered)}`]: !!unordered
  });

  return <Tag {...rest} className={classes} />;
}

Col.propTypes = {
  tag: GridPropTypes.tag,
  xs: GridPropTypes.column,
  sm: GridPropTypes.column,
  md: GridPropTypes.column,
  lg: GridPropTypes.column,
  xl: GridPropTypes.column,
  xsOffset: PropTypes.number,
  smOffset: PropTypes.number,
  mdOffset: PropTypes.number,
  lgOffset: PropTypes.number,
  xlOffset: PropTypes.number,
  xsGutter: PropTypes.number,
  smGutter: PropTypes.number,
  mdGutter: PropTypes.number,
  lgGutter: PropTypes.number,
  xlGutter: PropTypes.number,
  xsGutterX: PropTypes.number,
  smGutterX: PropTypes.number,
  mdGutterX: PropTypes.number,
  lgGutterX: PropTypes.number,
  xlGutterX: PropTypes.number,
  xsGutterY: PropTypes.number,
  smGutterY: PropTypes.number,
  mdGutterY: PropTypes.number,
  lgGutterY: PropTypes.number,
  xlGutterY: PropTypes.number,
  className: PropTypes.string,
  stretch: PropTypes.bool,
  first: PropTypes.oneOf(widths),
  last: PropTypes.oneOf(widths),
  unordered: PropTypes.oneOf(widths)
};

Col.defaultProps = {
  tag: 'div',
  stretch: false
};

export default withResponsive(Col);
