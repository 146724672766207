import values from 'lodash/values';
import pick from 'lodash/pick';
import { produce } from 'immer';
import { maybeHtmlFromState } from '@bit/be-novative.kit.richtext-utils';
import { PROFILE_FORM_FIELDS, TERMS_AND_PRIVACY_ACCEPTED } from '../constants';
import isValidImageObject from '../../utils/isValidImageObject';

const { DESCRIPTION, IMAGE } = PROFILE_FORM_FIELDS;

export const pickValidProfileKeys = formData =>
  pick(formData, values(PROFILE_FORM_FIELDS));

/**
 * Sanitizes the form model for the API and converts rich text into HTML string
 * @param {OwnProfilePayload} formData - The form editor form model
 * @return {OwnProfilePayload} The sanitized payload
 */

export const createProfilePayload = formData => ({
  ...pickValidProfileKeys(formData),
  [DESCRIPTION]: maybeHtmlFromState(formData[DESCRIPTION]),
  [IMAGE]: isValidImageObject(formData[IMAGE]) ? formData[IMAGE] : null
});

/**
 * Arranges the user's existing and updated consent booleans into the map required by the API
 * @param {Object} existingConsent - The existing consent map in the user's profile
 * @param {Object} newSettings - The new consent settings
 * @return {Object} The composed and namespaced consent map
 */

export const createPrivacyPayload = (existingConsent, newSettings) => ({
  ...newSettings,
  [TERMS_AND_PRIVACY_ACCEPTED]:
    newSettings[TERMS_AND_PRIVACY_ACCEPTED] ||
    existingConsent[TERMS_AND_PRIVACY_ACCEPTED]
});

/**
 * Scrubs personal data from a profile payload intended for the API
 * @param {OwnProfilePayload} payload
 * @returns {OwnProfilePayload} The scrubbed payload
 */
export const scrubProfilePayload = produce(payload => {
  payload[PROFILE_FORM_FIELDS.FIRST_NAME] = '[scrubbed]';
  payload[PROFILE_FORM_FIELDS.LAST_NAME] = '[scrubbed]';
  payload[PROFILE_FORM_FIELDS.WORKPLACE] = '[scrubbed]';
  payload[PROFILE_FORM_FIELDS.POSITION] = '[scrubbed]';
});
