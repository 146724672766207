import React, { useCallback, useEffect, useState } from 'react';
import { translate, Trans } from 'react-i18next';
import { DIAGNOSTICS_PATH } from '../../../constants';
import Button from '@bit/be-novative.kit.button';
import CircleSpinner from '../CircleSpinner';
import FullScreen from '../FullScreen';
import scss from './BootstrapSpinner.module.scss';

function BootstrapSpinner({ t, timeoutMs = 5000 }) {
  const [showWarning, setShowWarning] = useState(false);
  useEffect(
    function launchTimer() {
      let timer;
      if (!showWarning && !timer && timeoutMs > 0) {
        timer = setTimeout(() => {
          setShowWarning(true);
        }, timeoutMs);
      }
      return () => {
        clearTimeout(timer);
      };
    },
    [showWarning, timeoutMs]
  );

  const handleReload = useCallback(() => {
    if (window) {
      window.location.reload();
    }
  }, []);

  const diagUrl =
    window &&
    `${window.location.protocol}//${window.location.hostname}/${DIAGNOSTICS_PATH}`;

  return (
    <FullScreen className={scss.Screen}>
      <section className={scss.Wrapper}>
        <div className={scss.CenterBox}>
          <CircleSpinner className={scss.Spinner} />
          {showWarning && (
            <main className={scss.Content}>
              <p>{t('bootstrap/warning')}</p>
              <p>
                <Trans i18nKey="bootstrap/prompt">
                  <a href={diagUrl} target="_blank" rel="noopener noreferrer">
                    diagPage
                  </a>
                  <Button variant="link" onClick={handleReload}>
                    reload
                  </Button>
                </Trans>
              </p>
            </main>
          )}
        </div>
      </section>
    </FullScreen>
  );
}

export default translate()(BootstrapSpinner);
