import identity from 'lodash/identity';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { selectors } from '../state/categories';

const makeGetCategoryById = () =>
  createSelector(
    selectors.selectById,
    identity
  );

/**
 * Returns the category corresponding to the supplied ID
 * @param {string} categoryId
 * @return {Category}
 */

export default function useCategory(categoryId) {
  const getCateg = useMemo(makeGetCategoryById, []);
  const category = useSelector(state => getCateg(state, categoryId));
  return category;
}
