import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';

function Alert(props) {
  const {
    actionLabel,
    id,
    kind,
    message,
    onActionClick,
    status,
    statusIcon
  } = props;

  const handleActionClick = e => {
    e.preventDefault();

    if (!onActionClick) {
      return;
    }

    onActionClick(id);
  };

  return (
    <div className={`alert alert--${kind}`}>
      <div>{message}</div>
      {actionLabel &&
        <span className="alert__action">
          <button onClick={handleActionClick}>{actionLabel}</button>
        </span>}
      {status &&
        <div>
          {statusIcon && <Icon type={statusIcon} />}
          {status}
        </div>}
    </div>
  );
}

Alert.defaultProps = {
  kind: 'info'
};

Alert.propTypes = {
  actionLabel: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  kind: PropTypes.oneOf(['success', 'info', 'warning', 'danger']).isRequired,
  message: PropTypes.string.isRequired,
  onActionClick: PropTypes.func
};

export default Alert;
