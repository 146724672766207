import React from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import isFunction from 'lodash/isFunction';
import Close from '../../../common/components/Close';
import Modal from '../../../common/components/Modal';
import { closeModal } from '../../../common/actions';
import { getDisplayName } from '../../../utils';

const withModal = (
  mapStateToProps,
  mapDispatchToProps,
  className
) => WrappedComponent => {
  function WithModal(props) {
    const {
      isCloseButtonShown,
      closeable,
      onClose,
      size = 'medium',
      t,
      ...rest
    } = props;
    const modalProps = {
      className,
      isOpen: true,
      size,
      ...(closeable ? { onClose } : {})
    };
    return (
      <Modal {...modalProps} {...rest}>
        <WrappedComponent {...props} />
        {closeable && isCloseButtonShown && (
          <Close onClick={onClose}>{t('common/Close')}</Close>
        )}
      </Modal>
    );
  }

  WithModal.defaultProps = {
    isCloseButtonShown: true,
    closeable: true
  };

  WithModal.displayName = `withModal(${getDisplayName(WrappedComponent)})`;

  return compose(
    translate(),
    connect(
      mapStateToProps,
      function(dispatch, ownProps) {
        let boundActionCreators = isFunction(mapDispatchToProps)
          ? mapDispatchToProps(dispatch, ownProps)
          : bindActionCreators(mapDispatchToProps || {}, dispatch);

        return {
          ...boundActionCreators,
          onClose: () => dispatch(closeModal())
        };
      }
    )
  )(WithModal);
};

export default withModal;
