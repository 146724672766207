import React from 'react';
import { translate } from 'react-i18next';
import Icon from '../Icon';
import './TutorialButton.css';

function TutorialButton({ t }) {
  return (
    <a
      className="TutorialButton"
      href="https://www.youtube.com/watch?v=iPYbTwz5XVc&list=PLdgYDH0sBrjLMn1HRpsx7gEF38bQ-ThYI"
      target="_blank"
      rel="noopener noreferrer">
      <div>
        <span className="TutorialButton__Icon">
          <Icon type="graduation_cap" />
        </span>

        <span className="Tutorial__Text">{t('common/Tutorials')}</span>
      </div>
    </a>
  );
}

export default translate()(TutorialButton);
