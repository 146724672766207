import client from '../../api/client';
import { getApiResponseData } from '../../api/utils/apiResponse';

/**
 * Returns the available categories of the platform
 * @return {Promise<Category[]>} The array of available categories extracted from the response
 */

function fetchCategories() {
  return client.get(`categories`).then(getApiResponseData);
}

export default {
  fetchCategories
};
