/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import zipObject from 'lodash/zipObject';
import * as Sentry from '@sentry/browser';
import useInterval from '../../../common/hooks/useInterval';
import { getPlatformName } from '../../../utils/platform';
import addBreadcrumb, { Severity } from '../../../telemetry/addBreadcrumb';
import {
  FEATURE_FLAG_FIRST_LOAD_ERROR_TIMEOUT_MS,
  FEATURE_FLAG_FIRST_LOAD_TIMEOUT_MS,
  FEATURE_FLAG_UPDATE_INTERVAL_MS
} from '../../config';
import { getAllFeatureFlagKeys, getFeatureFlagAsync } from '../../client';
import BootstrapSpinner from '../../../common/components/BootstrapSpinner';

const targetingData = { custom: { platformName: getPlatformName() } };

function FeatureFlagProvider({ children }) {
  const [isLoadingSlowly, setIsLoadingSlowly] = useState(false);
  const slowErrorReportTimerRef = useRef();

  const [hasReportedError, setHasReportedError] = useState(false);
  const slowLoadTimerRef = useRef();
  const reportLoadError = useCallback(
    function reportLoadError() {
      if (hasReportedError) {
        addBreadcrumb('Feature flags not loaded within time limit', {
          category: 'bootstrap',
          level: Severity.Critical
        });
        Sentry.captureException();
        setHasReportedError(true);
      }
    },
    [hasReportedError]
  );

  const [flagKeys, setFlagKeys] = useState([]);
  const [flags, setFlags] = useState({});
  const hasLoadedFlags = size(flags) > 0;
  const getFlagKeys = useCallback(() => getAllFeatureFlagKeys(setFlagKeys), []);

  useEffect(
    function beginInitialLoad() {
      const isLoadingForFirstTime = !hasLoadedFlags;

      if (isLoadingForFirstTime) {
        slowLoadTimerRef.current = setTimeout(() => {
          setIsLoadingSlowly(true);
        }, FEATURE_FLAG_FIRST_LOAD_TIMEOUT_MS);

        slowErrorReportTimerRef.current = setTimeout(
          reportLoadError,
          FEATURE_FLAG_FIRST_LOAD_ERROR_TIMEOUT_MS
        );

        getFlagKeys();
      } else {
        clearTimeout(slowLoadTimerRef.current);
        clearTimeout(slowErrorReportTimerRef.current);
      }
    },
    [hasLoadedFlags, flagKeys, getFlagKeys, reportLoadError]
  );

  useInterval(getFlagKeys, FEATURE_FLAG_UPDATE_INTERVAL_MS);

  useEffect(
    function syncFlags() {
      async function sync() {
        const latestValues = await Promise.all(
          flagKeys.map(key => getFeatureFlagAsync(key, null, targetingData), {})
        );
        const latestFlags = zipObject(flagKeys, latestValues);

        if (!isEqual(flags, latestFlags)) {
          setFlags(latestFlags);
        }
      }
      sync();
    },
    [flags, flagKeys]
  );

  const getter = useCallback(flagId => get(flags, flagId, null), [flags]);

  const showSpinner = !hasLoadedFlags && isLoadingSlowly;

  return (
    <FeatureFlagContext.Provider value={getter}>
      {hasLoadedFlags && children}
      {showSpinner && <BootstrapSpinner />}
    </FeatureFlagContext.Provider>
  );
}

export const FeatureFlagContext = React.createContext(() => null);

export default FeatureFlagProvider;
