import * as Sentry from '@sentry/browser';
import { ApiRegion } from '../constants';

const API_BASE_URL = {
  [ApiRegion.EU]: process.env.REACT_APP_API_EU_BASE_URL,
  [ApiRegion.US]: process.env.REACT_APP_API_US_BASE_URL
};

export default function getApiUrl(region) {
  const url = API_BASE_URL[region];

  Sentry.addBreadcrumb({
    data: {
      region,
      url
    },
    level: Sentry.Severity.Info
  });

  if (url) {
    return url;
  } else {
    throw new Error('API URL not found!');
  }
}
