import flow from 'lodash/flow';
import reduceFp from 'lodash/fp/reduce';
import round from 'lodash/round';
import size from 'lodash/size';
import React from 'react';
import { getUserId } from '../../../../common/getters/users';
import { CepheidConceptEvaluationFormConfig } from '../../../config';
import { EMPTY_NUMBER } from '../../../constants';
import {
  getCompositeEvalScore,
  getEvaluatorCount
} from '../../../selectors/ideaConcepts';
import { AlignedCellContent, SingleLineHeight } from '../ConceptTable.styled';
import getUserEvals from './getUserEvals';

const averageScore = {
  main: (t, concept) => {
    const score = getCompositeEvalScore(concept);
    const evalCount = getEvaluatorCount(concept);
    const label =
      round(score, 1) +
      ' / ' +
      CepheidConceptEvaluationFormConfig.CRITERION_MAX;
    return (
      <AlignedCellContent alignItems="flex-start" justifyContent="flex-start">
        <SingleLineHeight>
          {0 < evalCount ? (
            <div
              style={{
                lineHeight: 1,
                paddingTop: 3
              }}>
              {label}
              <br />
              <small>
                {evalCount}{' '}
                {t('ideaConcept/evaluation/collection', { count: evalCount })}
              </small>
            </div>
          ) : (
            <span>{EMPTY_NUMBER}</span>
          )}
        </SingleLineHeight>
      </AlignedCellContent>
    );
  },
  expanded: (t, concept) => {
    return (
      <AlignedCellContent
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        flexBasis="40px">
        {/* Whitespace for "Reviewed by" label in row header cell */}
        <small>&nbsp;</small>
        {getUserEvals(concept).map(evaluation => {
          const avgUserScore = flow(
            reduceFp((sum, score) => (sum += score), 0),
            sum => sum / size(evaluation.scores),
            avg => round(avg, 1)
          )(evaluation.scores);
          const label =
            round(avgUserScore, 1) +
            ' / ' +
            CepheidConceptEvaluationFormConfig.CRITERION_MAX;
          return (
            <div key={getUserId(evaluation.user)}>
              <SingleLineHeight style={{ fontSize: 12 }}>
                {0 < size(evaluation.scores) ? (
                  <span>{label}</span>
                ) : (
                  <span>{EMPTY_NUMBER}</span>
                )}
              </SingleLineHeight>
            </div>
          );
        })}
      </AlignedCellContent>
    );
  }
};

export default averageScore;
