import React, { Component } from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import withHandleClickOutside from 'react-onclickoutside';
import { Size } from '../../../constants';
import getDisplayName from '../../../utils/getDisplayName';
import Button from '@bit/be-novative.kit.button';
import EditButton from '../EditButton';
import Icon from '../Icon';
import './inlineEditable.css';

export default function inlineEditableFactory(WrappedComponent) {
  const TOOLBAR_LOCATIONS = { top: 'top', bottom: 'bottom' };
  class InlineEditable extends Component {
    static propTypes = {
      closeOnClickOutside: PropTypes.bool,
      compactToolbar: PropTypes.bool,
      input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired
      }),
      meta: PropTypes.object,
      onCancel: PropTypes.func,
      onStartEdit: PropTypes.func,
      onSave: PropTypes.func,
      readonly: PropTypes.bool,
      renderInline: PropTypes.func,
      reset: PropTypes.func,
      // Promise
      submit: PropTypes.func.isRequired,
      toolbarPosition: PropTypes.oneOf([
        TOOLBAR_LOCATIONS.top,
        TOOLBAR_LOCATIONS.bottom
      ])
    };

    static defaultProps = {
      closeOnClickOutside: true,
      compactToolbar: true,
      onCancel: () => {},
      onStartEdit: () => {},
      onSave: () => {},
      renderInline: value => value,
      reset: noop,
      toolbarPosition: TOOLBAR_LOCATIONS.bottom
    };

    state = {
      edit: false,
      submitFailed: false,
      submitting: false
    };

    setWrappedComponentRef = el => {
      this.wrappedEl = el;
    };

    stopEdit() {
      this.setState({
        edit: false
      });
    }

    startEdit() {
      this.setState(
        {
          edit: true,
          valueBeforeEdit: this.props.input.value
        },
        this.focusInput
      );
    }

    focusInput = () => {
      if (this.wrappedEl && this.wrappedEl.focus) {
        this.wrappedEl.focus();
      }
    };

    submitForm = () => {
      if (this.state.submitting) {
        return;
      }

      this.setState(
        {
          submitting: true,
          submitFailed: false
        },
        this.callApi
      );
    };

    callApi = async () => {
      const { input, submit } = this.props;
      const payload = { [input.name]: input.value };

      try {
        await submit(payload);
        this.handleSuccess(payload);
      } catch (e) {
        Sentry.captureException(e);
        this.setSubmitFail();
      }
    };

    restoreForm = () => {
      const { onCancel, reset } = this.props;

      reset();
      onCancel();
      this.stopEdit();
      this.setSubmitFail(false);
    };

    setSubmitFail = (submitFailed = true) => {
      this.setState({ submitFailed, submitting: false });
    };

    getMetaProps() {
      if (this.props.meta) {
        return { ...this.props.meta };
      }

      const { pristine, valid } = this.props;

      return {
        pristine,
        valid
      };
    }

    handleSuccess = payload => {
      const { onSave } = this.props;
      onSave(payload);
      this.stopEdit();
      this.setState({ submitting: false, submitFailed: false });
    };

    handleClickOutside = () => {
      const { closeOnClickOutside } = this.props;
      const { pristine, valid } = this.getMetaProps();

      if (!this.state.edit || !closeOnClickOutside) {
        return;
      }

      if (valid && !pristine) {
        this.submitForm();
      } else {
        this.restoreForm();
      }
    };

    handleStartEditClick = event => {
      event.preventDefault();
      this.props.onStartEdit();
      this.startEdit();
    };

    handleCommitChangeClick = event => {
      const { valid, pristine } = this.getMetaProps();

      if (!valid) {
        return;
      }

      if (!pristine) {
        this.submitForm();
      }
    };

    handleKeyPress = e => {
      const isEnter = e.keyCode === 13;
      const isEscape = e.keyCode === 27;

      if (isEnter) {
        e.preventDefault();
        this.submitForm();
      }

      if (isEscape) {
        this.restoreForm();
      }
    };

    render() {
      const { edit, submitFailed } = this.state;
      const {
        className,
        compactToolbar,
        input,
        meta,
        renderInline,
        readonly,
        required,
        t,
        toolbarPosition,
        ...rest
      } = this.props;
      const { value } = input;
      const classNames = cn('InlineEditable', className);
      const actionClasses = cn(
        'InlineEditable__Actions',
        `InlineEditable__Actions--${toolbarPosition}`
      );

      if (readonly === true) {
        return <div className={classNames}>{renderInline(value)}</div>;
      }

      return (
        <div className={classNames}>
          {!edit && (
            <span>
              {renderInline(value)}{' '}
              <EditButton onClick={this.handleStartEditClick} />
            </span>
          )}
          {edit && (
            <div>
              <WrappedComponent
                {...rest}
                input={{
                  ...input,
                  onKeyDown: this.handleKeyPress,
                  autoFocus: true
                }}
                required={required}
                meta={{
                  ...meta,
                  submitFailed,
                  error: meta.error || submitFailed
                }}
                ref={this.setWrappedComponentRef}
                style={{ display: 'block' }}
              />
              {(submitFailed || meta.error) && (
                <div
                  className="InlineEditable__Error"
                  style={{ marginTop: '-1em' }}>
                  {meta.error || t('common/saveFailed')}
                </div>
              )}
              <span className={actionClasses}>
                <Button
                  variant="success"
                  size={Size.xs}
                  onClick={this.handleCommitChangeClick}
                  aria-label={t('common/userAction/save')}>
                  <Icon type="check" />{' '}
                  {compactToolbar ? null : t('common/userAction/save')}
                </Button>{' '}
                <Button
                  size={Size.xs}
                  onClick={this.restoreForm}
                  aria-label={t('common/userAction/cancel')}>
                  <Icon type="cross" />{' '}
                  {compactToolbar ? null : t('common/userAction/cancel')}
                </Button>
              </span>
            </div>
          )}
        </div>
      );
    }
  }

  InlineEditable.displayName = `inlineEditable(${getDisplayName(
    WrappedComponent
  )})`;

  return compose(
    translate(),
    withHandleClickOutside
  )(InlineEditable);
}
