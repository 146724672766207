import React from 'react';
import { translate } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { CommonModalTypes } from '../../../constants';
import paths from '../../../paths';
import ProfileTab from '../ProfileTab';
import SettingsTab from '../SettingsTab';
import FullScreen from '../../../common/components/FullScreen';
import Grid from '../../../common/components/Grid';
import GlobalPageHeader from '../../../common/components/GlobalPageHeader';
import PrimaryNavigation from '../../../common/components/PrimaryNavigation';
import ModalRoot from '../../../common/containers/ModalRoot';
import ImageSearchModal from '../../../common/containers/ImageSearchModal';
import './ProfilePage.css';

const MODAL_COMPONENTS = {
  [CommonModalTypes.IMAGE_PICKER]: ImageSearchModal
};

const BASE_CLASS = 'ProfilePage';

function ProfilePage({ t }) {
  return (
    <FullScreen type="overview">
      <GlobalPageHeader />
      <Grid.Container>
        <PrimaryNavigation className={`${BASE_CLASS}__Nav`}>
          <PrimaryNavigation.Item to={paths.profile()} exact>
            {t('profile/Profile')}
          </PrimaryNavigation.Item>
          <PrimaryNavigation.Item
            push
            to={paths.profileSettings()}
            iconType="cog"
            className={`${BASE_CLASS}__Nav__SettingsBtn`}>
            {t('profile/Profile Settings')}
          </PrimaryNavigation.Item>
        </PrimaryNavigation>
        <Grid.Row>
          <Grid.Col xsGutter={2}>
            <Switch>
              <Route path="/profile/settings" component={SettingsTab} />
              <Route component={ProfileTab} />
            </Switch>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <ModalRoot modalComponents={MODAL_COMPONENTS} />
    </FullScreen>
  );
}

export default translate()(ProfilePage);
