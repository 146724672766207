import flow from 'lodash/flow';
import linkify from 'linkifyjs/html';
import { quotesFromSlantedToDouble } from '@bit/be-novative.kit.sanitize-for-json';

export const LINKIFY_CONFIG = {
  attributes: {
    rel: 'nofollow noopener'
  },
  className: 'inline-link',
  defaultProtocol: 'https',
  format: {
    url: val => (val.length > 50 ? val.slice(0, 50) + '…' : val)
  },
  ignoreTags: ['script', 'style'],
  target: '_blank',
  validate: true
};

// @see https://linkify.js.org/docs/
export default function linkifyHtml(htmlString, options = LINKIFY_CONFIG) {
  if (typeof htmlString !== 'string') {
    return;
  }

  return flow(
    str => linkify(str, options),
    quotesFromSlantedToDouble
  )(htmlString);
}

window.linkify = linkify;
