import React, { Component, cloneElement } from 'react';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';

export default class TruncatedText extends Component {
  static propTypes = {
    length: PropTypes.number,
    triggerComponent: PropTypes.element.isRequired
  };

  static defaultProps = {
    length: 300
  };

  state = {
    expanded: false
  };

  getButtonProps = () => ({
    expanded: this.state.expanded,
    onClick: this.toggleCollapse
  });

  toggleCollapse = () => {
    this.setState(({ expanded }) => ({ expanded: !expanded }));
  };

  render() {
    const { className, triggerComponent } = this.props;
    const isTruncateNecessary =
      this.renderExpanded().length !== this.renderTruncated().length;

    return (
      <div className={className}>
        {this.state.expanded ? this.renderExpanded() : this.renderTruncated()}
        {isTruncateNecessary && (
          <div>{cloneElement(triggerComponent, this.getButtonProps())}</div>
        )}
      </div>
    );
  }

  renderExpanded = () => this.props.children;

  renderTruncated = () => {
    const { children, length } = this.props;
    return truncate(children, { length });
  };
}
