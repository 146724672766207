import React from 'react';
import { Size } from '../../../../constants';
import ConceptTeamAvatars from '../../../components/ConceptTeamAvatars';
import { getIdeaConceptId } from '../../../selectors/ideaConcepts';
import { AlignedCellContent, SingleLineHeight } from '../ConceptTable.styled';

const team = {
  main: (t, concept) => (
    <AlignedCellContent alignItems="flex-start">
      <SingleLineHeight>
        <ConceptTeamAvatars
          ideaConceptId={getIdeaConceptId(concept)}
          maxDisplay={4}
          avatarSize={Size.xs}
        />
      </SingleLineHeight>
    </AlignedCellContent>
  )
};

export default team;
