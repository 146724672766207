import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { commonIdProps } from '../../constants';

// TODO change this to `creator`
export const getAuthor = idea => get(idea, 'user', {});

export const getParentConceptId = idea => get(idea, 'ideaConceptId');

export const getEvaluationCount = idea => get(idea, 'evaluationCount', 0);

export const getIdeaId = idea => get(idea, commonIdProps.IDEA_ID, '');

export const getIdeaText = idea => get(idea, 'text', '');

export const getLoveCount = idea => {
  const loveCount = get(idea, 'loveCount');
  const likeCount = get(idea, 'likeCount');
  if (isNumber(likeCount)) {
    return likeCount;
  } else if (isNumber(loveCount)) {
    return loveCount;
  } else {
    return 0;
  }
};

export const getParentGroupId = idea => get(idea, 'groupId');

export const getPermissions = idea => get(idea, 'permissions', {});

export const getScore = idea => get(idea, 'score', -1);

export const isLoved = idea => get(idea, 'loved');

export const isOwn = idea => get(idea, 'own');

export const isRevealable = idea => get(idea, 'shouldReveal');
