import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import flow from 'lodash/flow';
import get from 'lodash/fp/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import negate from 'lodash/negate';
import unset from 'lodash/unset';
import { handleActions } from 'redux-actions';
import forceArray from '../../../utils/forceArray';
import {
  FILTER_ITEMS_BY,
  REMOVE_FILTER,
  REMOVE_FILTER_TYPE
} from '../../actions/meta';

const addOrUpdateFilter = (filters = {}, action = {}) => {
  const { key, value } = action.payload;
  const shouldOverwrite = get(['meta', 'overwrite'])(action);

  if (shouldOverwrite) {
    return {
      ...filters,
      [key]: forceArray(value)
    };
  } else {
    const existingValues = filters[key] || [];

    return {
      ...filters,
      [key]: [...existingValues, value]
    };
  }
};

const removeFilter = (filters = {}, action = {}) => {
  const { key, value: arrayOfTargetValues } = action.payload;
  const existingVals = filters[key];
  const dropTargets = negate(val => includes(arrayOfTargetValues, val));
  const updatedFilter = filter(existingVals, dropTargets);

  return {
    ...filters,
    [key]: isEmpty(updatedFilter) ? undefined : updatedFilter
  };
};

const removeAllFiltersWithKey = (filters = {}, action = {}) => {
  const key = action.payload;
  const updatedFilters = cloneDeep(filters);

  unset(updatedFilters, key);

  return updatedFilters;
};

const filterReducerFactory = (reducerId, defaultFilters = {}) => {
  const reducerIdMatches = flow(
    get(['meta', 'reducerId']),
    isEqual(reducerId)
  );

  return handleActions(
    {
      [FILTER_ITEMS_BY](state, action) {
        if (reducerIdMatches(action)) {
          return addOrUpdateFilter(state, action);
        }
        return state;
      },
      [REMOVE_FILTER](state, action) {
        if (reducerIdMatches(action)) {
          return removeFilter(state, action);
        }
        return state;
      },
      [REMOVE_FILTER_TYPE](state, action) {
        if (reducerIdMatches(action)) {
          return removeAllFiltersWithKey(state, action);
        }
        return state;
      }
    },
    defaultFilters
  );
};

export default filterReducerFactory;
