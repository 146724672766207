import React, { lazy } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Spinner from '@bit/be-novative.kit.spinner';
import LazyComponent from '../../../common/components/LazyComponent';
import { conceptListPage } from '../../../ideaConcept/paths';
import getAllowedCanvasBackgroundType from '../../utils/getAllowedCanvasBackgroundType';

const ConceptCanvasPage = lazy(() => import('../ConceptCanvasPage'));
const ConceptMapCanvasPage = lazy(() => import('../ConceptMapCanvasPage'));

function ConceptCanvasRoot() {
  const { path } = useRouteMatch();
  const showCanvas = !!getAllowedCanvasBackgroundType();

  return (
    <Switch>
      {showCanvas ? (
        <>
          <Route exact path={`${path}/:ideaConceptId`}>
            <LazyComponent
              fallback={<Spinner type="benovative" size="large" centered />}>
              <ConceptCanvasPage />
            </LazyComponent>
          </Route>
          <Route path={`${path}/:ideaConceptId/conceptmap`}>
            <LazyComponent
              fallback={<Spinner type="benovative" size="large" centered />}>
              <ConceptMapCanvasPage />
            </LazyComponent>
          </Route>
        </>
      ) : (
        <Redirect to={conceptListPage()} />
      )}
    </Switch>
  );
}

export default ConceptCanvasRoot;
