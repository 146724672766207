import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import StageDropdown from '../StageDropdown';
import StageIndicator from '../StageIndicator';
import useConceptStatusUpdate from '../../hooks/useConceptStatusUpdate';

StageSelector.propTypes = {
  ideaConceptId: PropTypes.string.isRequired
};
export function StageSelector({ ideaConceptId, t }) {
  const [stage, canEdit, onSelect] = useConceptStatusUpdate(
    ideaConceptId,
    t('common/saveFailed')
  );

  return canEdit ? (
    <StageDropdown onSelect={onSelect} stage={stage} data-test="dropdown" />
  ) : (
    <StageIndicator stage={stage} data-test="indicator" />
  );
}

export default translate()(StageSelector);
