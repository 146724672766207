import React from 'react';
import styled from 'styled-components';
import size from 'lodash/size';
import values from 'lodash/values';
import Button from '@bit/be-novative.kit.button';
import Icon from '../../../common/components/Icon';
import { Size } from '../../../constants';
import { useActiveScrollAnchor, useScrollToAnchor } from './ScrollSpyProvider';
import { scrollIds } from './useTableConfig';

const Disk = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: ${({ active }) => (active ? '#464F59' : '#C3CFD9')};
  transition: background 0.25s linear;
`;

export default function TableNav() {
  const activeAnchorIx = useActiveScrollAnchor();
  const prevBtnTarget = Math.max(0, activeAnchorIx - 1);
  const nextBtnTarget = Math.min(size(scrollIds) - 1, activeAnchorIx + 1);
  const handleScroll = useScrollToAnchor();
  return (
    <nav>
      <Button
        onClick={0 < activeAnchorIx ? () => handleScroll(prevBtnTarget) : null}
        variant="minimal"
        size={Size.xs}
        style={{
          height: 30
        }}>
        <Icon type="angle-left" size={Size.lg} />
      </Button>
      {values(scrollIds).map(ix => (
        <Button
          key={ix}
          onClick={activeAnchorIx !== ix ? () => handleScroll(ix) : null}
          variant="minimal"
          size={Size.xs}
          style={{
            height: 30,
            lineHeight: 30,
            paddingLeft: 5,
            paddingRight: 5
          }}>
          <Disk active={activeAnchorIx === ix} />
          <Disk active={activeAnchorIx === ix} />
        </Button>
      ))}
      <Button
        onClick={
          activeAnchorIx < size(scrollIds)
            ? () => handleScroll(nextBtnTarget)
            : null
        }
        variant="minimal"
        size={Size.xs}
        style={{
          height: 30
        }}>
        <Icon type="angle-right" size={Size.lg} />
      </Button>
    </nav>
  );
}
