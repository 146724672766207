import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './Label.css';

const BASE_CLASS = 'Label';

Label.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  rounded: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'info', 'success'])
};
Label.defaultProps = {
  variant: 'default'
};
function Label({ bgColor, children, className, color, rounded, variant }) {
  const classes = classNames(
    BASE_CLASS,
    {
      [`${BASE_CLASS}--${variant}`]: !!variant,
      [`${BASE_CLASS}--rounded`]: !!rounded
    },
    className
  );
  return (
    <span
      className={classes}
      style={{
        backgroundColor: bgColor ? bgColor : null,
        color: color ? color : null
      }}>
      {children}
    </span>
  );
}

export default Label;
