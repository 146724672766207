import React, { PureComponent } from 'react';
import find from 'lodash/fp/find';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Size } from '../../../constants';
import localiseUserName from '../../../utils/localiseUserName';
import { isIE } from '../../../utils/userAgent';
import {
  getCountry,
  getDescription,
  getRegisterDate,
  getWebsite,
  getWorkPosition,
  getWorkplace,
  getLinkedinUrl
} from '../../getters';
import {
  ownProfileSelector,
  isAdminSelector
} from '../../../profile/state/profile';
import { getUserAvatar } from '../../../common/getters/users';
import { withNonIdealState } from '../../../common/containers';
import placeholderUrl from './placeholder.svg';
import Avatar from '../../../common/components/Avatar';
import Grid from '../../../common/components/Grid';
import Icon from '../../../common/components/Icon';
import Label from '../../../common/components/Label';
import LinkifiedSafeHtml from '../../../common/components/LinkifiedSafeHtml';
import NonIdealState from '../../../common/components/NonIdealState';
import './ProfileTab.css';

const BASE_CLASS = 'ProfileTab';

export class ProfileTab extends PureComponent {
  render() {
    const {
      aboutMe,
      avatar,
      country,
      isAdmin,
      linkedInUrl,
      position,
      registeredAt,
      t,
      userName,
      websiteUrl,
      workplace
    } = this.props;

    return (
      <Grid.Row className={BASE_CLASS}>
        <Grid.Col xsGutter={2} xs={6} md={3} lg={2}>
          <Avatar
            name={userName}
            image={avatar}
            size={isIE() ? Size.lg : Size.xl}
          />
        </Grid.Col>
        <Grid.Col
          xsGutterX={2}
          md={6}
          lg={7}
          className={`${BASE_CLASS}__BasicInfo`}>
          <h1 className={`${BASE_CLASS}__Name`}>
            <span>{userName} </span>
            {isAdmin && (
              <Label className={`${BASE_CLASS}__AdminLabel`} rounded>
                {t('common/Admin')}
              </Label>
            )}
          </h1>
          {position && <h3>{position}</h3>}
          {workplace && <h3>{workplace}</h3>}
          {country && (
            <p className={`${BASE_CLASS}__HeaderInfo`}>
              <Icon type="marker" className={`${BASE_CLASS}__LocationIcon`} />
              <span>{country}</span>
            </p>
          )}
          <hr />
          {websiteUrl && (
            <p>
              <a
                href={websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={`${BASE_CLASS}__HeaderInfo`}>
                <Icon type="link" />
                <span>{websiteUrl}</span>
              </a>
            </p>
          )}
          {linkedInUrl && (
            <p>
              <a
                href={linkedInUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={`${BASE_CLASS}__HeaderInfo`}>
                <Icon type="linkedin" />
                <span>{linkedInUrl}</span>
              </a>
            </p>
          )}
          <p className={`${BASE_CLASS}__HeaderInfo`}>
            <Icon type="clock" />
            <span>
              {t('profile/Joined {{registeredAt,fromNow}}', {
                registeredAt
              })}
            </span>
          </p>
        </Grid.Col>

        <Grid.Col xsGutterX={2}>
          <h2>{t('profile/editorForm/fields/aboutMe/label')}</h2>

          <LinkifiedSafeHtml html={aboutMe} />

          <h2>{t('profile/Activity Graph')}</h2>
          <NonIdealState
            visual={<img src={placeholderUrl} alt="" />}
            title={t('common/Comming soon!')}
            description={t('profile/Activity graph description')}
          />
        </Grid.Col>
      </Grid.Row>
    );
  }
}

function mapStateToProps(state, { t }) {
  const profile = ownProfileSelector(state);
  const countryCode = getCountry(profile);
  const country = find(
    { cca2: countryCode },
    t('countries', { returnObjects: true })
  );
  const userName = localiseUserName(t, profile);

  return {
    aboutMe: getDescription(profile),
    avatar: getUserAvatar(profile),
    country: get(country, 'name', ''),
    isAdmin: isAdminSelector(state),
    linkedInUrl: getLinkedinUrl(profile),
    loading: !userName,
    position: getWorkPosition(profile),
    registeredAt: getRegisterDate(profile),
    userName,
    websiteUrl: getWebsite(profile),
    workplace: getWorkplace(profile)
  };
}

export default compose(
  translate(),
  connect(mapStateToProps),
  withNonIdealState({
    isLoading: ({ userName }) => !userName
  })
)(ProfileTab);
