import PropTypes from 'prop-types';
import React from 'react';
import RadioGroup from '../RadioGroup';
import scss from './RadioButtonGroup.module.scss';

RadioButtonGroup.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      disabled: PropTypes.bool,
      label: PropTypes.string,
      icon: PropTypes.node
    })
  ),
  name: PropTypes.string.isRequired
};

function RadioButtonGroup({ choices, name, parse }) {
  return (
    <RadioGroup
      choices={choices}
      name={name}
      parse={parse}
      theme={{
        wrapper: scss.Wrapper,
        radio: scss.Item,
        radioChecked: scss.checked,
        radioDisabled: scss.disabled
      }}
    />
  );
}

export default RadioButtonGroup;
