import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { platformDataSelector } from '../../../common/state/platform';
import { getAllowedEmailDomains } from '../../../common/getters/platform';
import { conceptEditorFormFields } from '../../constants';
import {
  getConceptFormModelById,
  isQuickIdeaConcept
} from '../../selectors/ideaConcepts';
import useIdeaConcept from '../../hooks/useIdeaConcept';
import formConfig from '../../utils/formConfig';
import ConceptEditorForm from '../../containers/ConceptEditorForm';
import TeamInput from '../TeamInput';

const { TEAM } = conceptEditorFormFields;

ConceptTeamEditor.propTypes = {
  ideaConceptId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  values: PropTypes.object
};
export function ConceptTeamEditor({ ideaConceptId }) {
  const platformData = useSelector(platformDataSelector);
  const emailRegex = getAllowedEmailDomains(platformData);
  const [concept] = useIdeaConcept(ideaConceptId);
  const isInsight = isQuickIdeaConcept(concept);
  const config = formConfig.getFormConfig({ insightForm: isInsight });
  const formModel = useSelector(getConceptFormModelById(ideaConceptId));
  const values = {
    [TEAM]: get(formModel, TEAM, [])
  };

  return (
    <ConceptEditorForm ideaConceptId={ideaConceptId} values={values}>
      <TeamInput
        emailRegex={emailRegex}
        minLength={get(config, [TEAM, 'minLength'])}
        name={conceptEditorFormFields.TEAM}
        autoFocus
      />
    </ConceptEditorForm>
  );
}

export default ConceptTeamEditor;
