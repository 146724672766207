import alerts from './alerts';
import categories from '../state/categories';
import challengeSettings from '../../challenge/reducers';
import ideaConcepts from './ideaConcepts';
import language from './language';
import modal from './modal';
import navigation from './navigation';
import latestNotifications from './latestNotifications';
import profile from './profile';
import sidenav from './sidenav';
import users from '../state/users';
import socket from './socket';
import network from './network';
import entities from './entities';
import onboarding from './onboarding';
import platformSlice from '../state/platform';

const rootReducer = {
  entities,
  alerts,
  categories: categories.reducer,
  challengeSettings,
  ideaConcepts,
  language,
  modal,
  navigation,
  latestNotifications,
  platform: platformSlice.reducer,
  profile,
  sidenav,
  users: users.reducer,
  socket,
  network,
  onboarding
};

export * from './navigation';
export * from './modal';
export * from './language';
export * from './profile';
export * from './socket';
export * from './latestNotifications';
export * from './network';
export * from './entities';
export * from './onboarding';
export * from './ideaConcepts';

export default rootReducer;
