import PropTypes from 'prop-types';
import React, { Component, cloneElement } from 'react';
import classNames from 'classnames';
import { Arrow } from 'react-popper';
import onClickOutside from 'react-onclickoutside';
import Progress from '../Progress';
import Close from '../Close';
import './Popover.css';

class Popover extends Component {
  static propTypes = {
    action: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    popperProps: PropTypes.shape({
      style: PropTypes.object
    }),
    closeable: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    showFooterStripe: PropTypes.bool
  };

  static defaultProps = {
    closeable: false,
    showFooterStripe: true,
    style: {}
  };

  handleClickOutside = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const {
      className,
      action,
      bodyStyle,
      children,
      style,
      popperProps,
      closeable,
      onClose,
      showFooterStripe
    } = this.props;
    const classes = classNames('Popover', className);
    const bodyClasses = 'Popover__Body';
    const footerClasses = 'Popover__Footer';
    const arrowClasses = 'Popover__Arrow';

    return (
      <div
        {...popperProps}
        style={{ ...style, ...popperProps.style }}
        className={classes}>
        <div className={bodyClasses} style={bodyStyle}>
          {cloneElement(children, { onClose })}
        </div>
        <div className={footerClasses}>
          {action}
          {showFooterStripe && <Progress thickness="thick" />}
        </div>
        <Arrow className={arrowClasses} />
        {closeable && <Close onClick={onClose} />}
      </div>
    );
  }
}

export { Popover };
export default onClickOutside(Popover);
