import React, { Component } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { translate } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import Button from '@bit/be-novative.kit.button';
import { ImageTypes } from '../../../constants';
import 'react-image-crop/dist/ReactCrop.css';
import scss from './ImagePicker.module.scss';

const MB_MAX_SIZE = 10;
const ACCEPT_TYPES = ['jpeg', 'png'];
export const BLOB = 'blob';
export const BASE_64 = 'base64';

const getImgType = image => get(image, 'type', 'image/jpeg');

class UploadImageTab extends Component {
  static propTypes = {
    dataType: PropTypes.oneOf([BLOB, BASE_64]),
    onSelect: PropTypes.func,
    crop: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number
    })
  };

  static defaultProps = {
    dataType: BASE_64,
    crop: {
      x: 10,
      y: 10,
      width: 80,
      height: 80
    }
  };

  state = {
    image: null,
    error: false
  };

  componentWillUnmount() {
    const { image } = this.props;
    if (image) {
      window.URL.revokeObjectURL(image.preview);
    }
  }

  handleDropAccepted = accepted => {
    this.setState({
      image: accepted[0],
      error: false
    });
  };

  handleDropRejected = () => {
    this.setState({
      error: true
    });
  };

  handleSelect = () => {
    this.loadImage()
      .then(this.cropImage)
      .then(canvas => {
        if (this.props.dataType === BLOB) {
          canvas.toBlob(this.submitImage, this.state.imageFileType);
        } else {
          const imgData = canvas
            .toDataURL(getImgType(this.state.image))
            // API throws error for non-base64 characters
            .replace(/data:image\/\w+;base64,/i, '');
          this.submitImage(imgData);
        }
      });
  };

  submitImage = content => {
    this.props.onSelect({
      '@type': ImageTypes.UPLOADED,
      content,
      dataType: this.props.dataType,
      fileType: getImgType(this.state.image)
    });
  };

  loadImage = () => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = () => reject();
      image.src = this.state.image.preview;
    });
  };

  cropImage = image => {
    const crop = this.state.crop || this.props.crop;
    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;

    const cropX = (crop.x / 100) * imageWidth;
    const cropY = (crop.y / 100) * imageHeight;

    const cropWidth = (crop.width / 100) * imageWidth;
    const cropHeight = (crop.height / 100) * imageHeight;

    const canvas = document.createElement('canvas');
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    return canvas;
  };

  handleCropChange = crop => {
    this.setState({
      crop
    });
  };

  handleImageLoaded = crop => {
    this.setState({
      crop
    });
  };

  render() {
    const { t, crop } = this.props;
    const { image, error } = this.state;

    return (
      <section className={scss.Uploader}>
        <header>
          <h2>{t('common/imagePicker/uploaded/title')}</h2>
        </header>
        {!image && (
          <Dropzone
            onDropAccepted={this.handleDropAccepted}
            onDropRejected={this.handleDropRejected}
            accept={ACCEPT_TYPES.map(type => `image/${type}`).join(', ')}
            maxSize={MB_MAX_SIZE * 1024 * 1024}
            multiple={false}
            className={scss.DropZone}
            activeClassName={scss.active}
            rejectClassName={scss.rejected}>
            {!error && <p>{t('common/imagePicker/uploaded/help')}</p>}
            {error && (
              <p className={scss.Error}>
                <b>{t('common/imagePicker/error/imageUploadFailed')}</b>
                <br />
                {t('common/imagePicker/error/uploadedImageInvalid', {
                  types: ACCEPT_TYPES.join(', '),
                  size: `${MB_MAX_SIZE}Mb`
                })}
              </p>
            )}
          </Dropzone>
        )}
        {image && (
          <div>
            <ReactCrop
              src={image.preview}
              crop={crop}
              onChange={this.handleCropChange}
              onImageLoaded={this.handleImageLoaded}
            />
            <Button variant="success" onClick={this.handleSelect}>
              {t('common/userAction/select')}
            </Button>
          </div>
        )}
      </section>
    );
  }
}

export default translate()(UploadImageTab);
