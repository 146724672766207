import React from 'react';
import './OverlayAction.css';

function OverlayAction({ children, action, disabled }) {
  return (
    <div className="OverlayAction__Container">
      {children}
      {!disabled && <div className="OverlayAction">{action}</div>}
    </div>
  );
}

export default OverlayAction;
