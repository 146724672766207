import React, { Component } from 'react';
import get from 'lodash/get';
import noop from 'lodash/noop';
import isObject from 'lodash/fp/isObject';
import PropTypes from 'prop-types';
import { RichUtils, Editor, EditorState } from 'draft-js';
import classNames from 'classnames';
import { markOverflow } from '@bit/be-novative.kit.richtext-utils';
import BlockStyleControls from './BlockStyleControls';
import InlineStyleControls from './InlineStyleControls';
import './RichText.css';

const CUSTOM_STYLE_MAP = {
  OVERFLOW: {
    background: '#f7d7d7'
  }
};

class RichText extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    editorClassName: PropTypes.string,
    maxLength: PropTypes.number,
    maxTabDepth: PropTypes.number,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    showToolbar: PropTypes.bool,
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      onFocus: PropTypes.func,
      onBlur: PropTypes.func
    })
  };

  static defaultProps = {
    input: { onChange: noop },
    maxTabDepth: 4,
    minHeight: 125,
    showToolbar: true
  };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus();
    }
  }

  setEditorRef = el => {
    this.editor = el;
  };

  focus = () => {
    this.editor.focus();
  };

  handleKeyCommand = command => {
    const { value, onChange } = this.props.input;
    const newState = RichUtils.handleKeyCommand(value, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  handleTab = event => {
    const { maxTabDepth, input } = this.props;
    const { value, onChange } = input;
    onChange(RichUtils.onTab(event, value, maxTabDepth));
  };

  toggleBlockType = blockType => {
    const { value, onChange } = this.props.input;
    onChange(RichUtils.toggleBlockType(value, blockType));
  };

  toggleInlineStyle = inlineStyle => {
    const { value, onChange } = this.props.input;
    onChange(RichUtils.toggleInlineStyle(value, inlineStyle));
  };

  handleChange = editorState => {
    const { input, maxLength } = this.props;
    const newState = maxLength
      ? markOverflow(editorState, maxLength, 'OVERFLOW')
      : editorState;
    input.onChange(newState);
  };

  render() {
    const {
      placeholder,
      readOnly,
      input,
      meta,
      minHeight,
      className,
      editorClassName,
      children,
      showToolbar
    } = this.props;

    const { value, onFocus, onBlur } = input;

    let editorState = isObject(value) ? value : EditorState.createEmpty();

    const contentState = editorState.getCurrentContent();
    const renderPlaceholder = !contentState.hasText();
    const renderToolbar = showToolbar && !readOnly;

    const classes = classNames('RichText', className);
    const editorClasses = classNames('RichText__Editor', editorClassName, {
      'RichText__Editor--readonly': readOnly,
      'RichText__Editor--active': get(meta, 'active'),
      'RichText__Editor--error': get(meta, 'submitFailed') && get(meta, 'error')
    });

    return (
      <div className={classes}>
        {renderToolbar && (
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />
        )}
        {renderToolbar && (
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />
        )}
        <div
          className={editorClasses}
          onClick={this.focus}
          style={{ minHeight }}>
          <Editor
            editorState={editorState}
            onChange={this.handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            autoCapitalize={'none'}
            autoComplete={'off'}
            autoCorrect={'off'}
            spellCheck={false}
            placeholder={renderPlaceholder ? placeholder : null}
            handleKeyCommand={this.handleKeyCommand}
            onTab={this.handleTab}
            readOnly={readOnly}
            ref={this.setEditorRef}
            customStyleMap={CUSTOM_STYLE_MAP}
          />
        </div>
        {children}
      </div>
    );
  }
}

export default RichText;
