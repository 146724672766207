import React, { useCallback } from 'react';
import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import sizeFp from 'lodash/fp/size';
import { useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import { conceptListFilterKeys } from '../../constants';
import { getConceptListFilters } from '../../selectors/conceptList';
import useUnsetFilter from '../../hooks/conceptList/useUnsetFilter';
import Button from '@bit/be-novative.kit.button';
import Icon from '../../../common/components/Icon';

const { CHALLENGE } = conceptListFilterKeys;

function FilterStatus({ t }) {
  const chosenChallengeCount = useSelector(
    flow(
      getConceptListFilters,
      getFp(CHALLENGE),
      sizeFp
    )
  );

  const unsetFilter = useUnsetFilter();
  const handleClearChallengeFilter = useCallback(() => {
    unsetFilter(CHALLENGE);
  }, [unsetFilter]);

  return (
    <div>
      {chosenChallengeCount > 0 && (
        <Button variant="outline" onClick={handleClearChallengeFilter}>
          <Icon type="cross" />
          <span>
            {t('ideaConcepts/listPage/filter/challenge/indicator', {
              count: chosenChallengeCount
            })}
          </span>
        </Button>
      )}
    </div>
  );
}

export default translate()(FilterStatus);
