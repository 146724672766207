import { sendSocketMessage } from '../../../common/actions/socket';
import { S_ID, SOCKET_MSG as MSG } from '../../constants';

// Requests

export const addCanvasIdeaMsg = (ideaText, posX, posY, section = null) =>
  sendSocketMessage(S_ID, MSG.ADD_IDEA_REQ, [
    ideaText,
    section,
    { x: posX, y: posY }
  ]);

export const deleteCanvasIdeaMsg = ideaId =>
  sendSocketMessage(S_ID, MSG.DELETE_IDEA_REQ, ideaId);

export const linkChallengeIdeaMsg = (ideaId, posX, posY, section = null) =>
  sendSocketMessage(S_ID, MSG.LINK_CHALLENGE_IDEA, [
    ideaId,
    section,
    { x: posX, y: posY }
  ]);

// Responses

export const normaliseIdeaEditedMsg = (msg = []) => {
  const [ideaId, text] = msg;
  return { ideaId, text };
};
