import React from 'react';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Editor, EditorState, ContentState } from 'draft-js';
import { markOverflow } from '@bit/be-novative.kit.richtext-utils';
import './Textarea.css';

const CUSTOM_STYLE_MAP = {
  OVERFLOW: {
    background: '#f7d7d7'
  }
};

const createEditorState = props => {
  return markOverflow(
    EditorState.createWithContent(
      ContentState.createFromText(props.input.value)
    ),
    props.maxLength,
    'OVERFLOW'
  );
};

const toPlainText = editorState => {
  const contentState = editorState.getCurrentContent();
  return contentState.getPlainText();
};

class Textarea extends React.Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    minRows: PropTypes.number,
    maxLength: PropTypes.number,
    counter: PropTypes.node,
    onEnter: PropTypes.func,
    onEscape: PropTypes.func
  };

  static defaultProps = {
    meta: {},
    minRows: 3,
    maxLength: Infinity
  };

  state = {
    editorState: createEditorState(this.props)
  };

  componentDidMount() {
    const { inputRef, autoFocus } = this.props;
    const ref = inputRef || this.editor;
    if (autoFocus && isFunction(get(ref, 'focus'))) {
      ref.focus();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (toPlainText(this.state.editorState) !== nextProps.input.value) {
      this.setState(() => ({
        editorState: createEditorState(nextProps)
      }));
    }
  }

  setEditorRef = el => {
    this.editor = el;
  };

  focus = () => {
    const { editorState } = this.state;
    const { onFocus = () => {} } = this.props.input;
    const selection = editorState.getSelection();
    if (!selection.getHasFocus()) {
      onFocus();
      this.setState(({ editorState }) => ({
        editorState: EditorState.moveFocusToEnd(editorState)
      }));
    }
  };

  handleChange = editorState => {
    const plainText = toPlainText(editorState);
    const { value, onChange } = this.props.input;
    const isValueChanged = plainText !== value;
    const shouldMarkOverflow =
      isValueChanged && value.length > this.props.maxLength;

    this.setState(
      (prevState, { maxLength }) => ({
        editorState: shouldMarkOverflow
          ? markOverflow(editorState, maxLength, 'OVERFLOW')
          : editorState
      }),
      () => {
        if (isFunction(onChange) && isValueChanged) {
          onChange(plainText);
        }
      }
    );
  };

  handleBlur = () => {
    const { value, onBlur = () => {} } = this.props.input;
    onBlur(value);
  };

  handleReturn = event => {
    const { onEnter } = this.props;
    if (onEnter && !event.shiftKey) {
      onEnter(event);
      return 'handled';
    }
    return 'not-handled';
  };

  render() {
    const {
      meta,
      input,
      inputRef,
      readOnly,
      placeholder,
      className,
      minRows,
      onEscape
    } = this.props;
    const { editorState } = this.state;
    const { onFocus } = input;
    const editorStyle = {
      minHeight: `${minRows * 1.2 + 0.6}em`
    };
    const { active, dirty, error, submitFailed } = meta;
    const classes = classNames('Textarea', className, {
      'Textarea--active': active,
      'Textarea--error': (dirty || submitFailed) && error
    });
    const selection = editorState.getSelection();

    return (
      <div
        className={classes}
        style={editorStyle}
        onClick={selection.getHasFocus() ? null : this.focus}>
        <Editor
          stripPastedStyles={true}
          autoCapitalize={'none'}
          autoComplete={'off'}
          autoCorrect={'off'}
          spellCheck={false}
          editorState={editorState}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={onFocus}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={inputRef || this.setEditorRef}
          customStyleMap={CUSTOM_STYLE_MAP}
          onEscape={onEscape}
          handleReturn={this.handleReturn}
        />
      </div>
    );
  }
}

export default Textarea;
