import cloneDeep from 'lodash/cloneDeep';
import mapValues from 'lodash/mapValues';
import memoize from 'lodash/memoize';
import { parse, stringify } from 'qs';

/**
 * Converts a query string into a key-value dictionary.
 * @param {string} queryString
 * @returns {Object}
 */
export function queryStringToObj(queryString) {
  const pathParams = parse(queryString, { ignoreQueryPrefix: true }) || {};

  return mapValues(pathParams, param => {
    if (param === 'true') {
      return true;
    } else if (param === 'false') {
      return false;
    }
    return param;
  });
}

export function stringifyQueryParams(params, addQueryPrefix = true) {
  return stringify(params, { addQueryPrefix });
}

export function updateSomeQueryParams(query) {
  let allParams,
    queryType = typeof query;

  if (queryType === 'object') {
    allParams = cloneDeep(query);
  } else if (queryType === 'string') {
    allParams = parseQueryString(query);
  } else throw Error(`String or object expected, received ${queryType}`);

  return paramConfig => {
    const updatedParams = {
      ...allParams,
      ...paramConfig
    };

    return queryType === 'string'
      ? stringifyQueryParams(updatedParams, false)
      : updatedParams;
  };
}

const parseQueryString = memoize(queryStringToObj);

export default parseQueryString;
