import get from 'lodash/get';
import { handleActions, combineActions } from 'redux-actions';
import { VOYAGER_API } from '../../api/constants';
import asyncActionType from '../../utils/asyncActionType';
import { saveExcel } from '../../utils/saveFile';
import getFilenameFromAjaxHeader from '../../utils/getFilenameFromAjaxHeader';

export const EXPORT_IDEAS = asyncActionType('EXPORT_IDEAS');
export const exportChallengeIdeas = challengeId => async dispatch => {
  try {
    const promise = await dispatch({
      [VOYAGER_API]: {
        url: `challenges/${challengeId}/export`,
        method: 'GET',
        responseType: 'blob',
        type: EXPORT_IDEAS,
        metrics: {
          challengeId
        }
      }
    });
    const defaultFileName = 'ideas_export.xlsx';
    const fileName = getFilenameFromAjaxHeader(
      get(promise, 'headers[content-disposition]')
    );

    saveExcel(promise.data, fileName || defaultFileName);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// TODO [CLOUD-5589] unnecessary reducer, load/fail states are handled by consumer hook
const exportPending = handleActions(
  {
    [EXPORT_IDEAS.PENDING]: () => true,
    [combineActions(EXPORT_IDEAS.SUCCESS, EXPORT_IDEAS.ERROR)]: () => false
  },
  false
);

export default exportPending;
