import React from 'react';

function defaultRowRenderer({
  className,
  columns,
  index,
  key,
  rowData,
  style
}) {
  const a11yProps = {};

  return (
    <div
      {...a11yProps}
      className={className}
      key={key}
      role="row"
      aria-rowindex={index}
      style={style}>
      {columns}
    </div>
  );
}

export default defaultRowRenderer;
