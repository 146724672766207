import {
  INTERNET_STATUS_CHANGE,
  NOTIFICATIONS_INIT,
  ONBOARDING_ENTER,
  ONBOARDING_LEAVE,
  ROUTE_CHANGE
} from '../../constants';
import { ITEM_MOVED } from '../../ideation/actions/itemMovement';
import {
  SOCKET_SUBSCRIBE,
  SOCKET_UNSUBSCRIBE,
  SOCKET_ERROR
} from '../../common/actions/socket';
import { Severity } from '../constants';

export const BLACKLISTED_ACTIONS = [
  INTERNET_STATUS_CHANGE,
  SOCKET_SUBSCRIBE,
  SOCKET_UNSUBSCRIBE,
  SOCKET_ERROR,
  NOTIFICATIONS_INIT,
  ONBOARDING_ENTER,
  ONBOARDING_LEAVE,
  ITEM_MOVED,
  ROUTE_CHANGE
];
export const BLACKLISTED_ERRORS = [
  /loading.*chunk.*failed/i,
  /long-polling/i,
  /network\s?error/i,
  /non-error/i,
  /time\s?out/i,
  /widget\.intercom\.io/i
];
export const BLACKLISTED_URLS = [
  /api\.amplitude\.com/i,
  /google-analytics/i,
  /stats\.g\.doubleclick.net/i,
  /dc\.services\.visualstudio\.com\/v2\/track/i
];
export const BLACKLISTED_NS = ['@@redux-form', '@@router'];
export const IGNORED_STORES = [
  'admin',
  'company',
  'entities',
  'form',
  'imageSuggestions',
  'latestNotifications',
  'profile'
];
export const NORMALISED_RESULT = ['payload', 'result'];
export const SCHEMA_DEF = ['meta', 'schema'];
// userId + email are handled by the Auth service
export const WHITELISTED_PROFILE_PROPS = ['companyAdmin', 'confirmed'];

export const ErrorLoggerSeverity = {
  [Severity.Verbose]: Severity.Debug,
  [Severity.Info]: Severity.Info,
  [Severity.Warning]: Severity.Warning,
  [Severity.Error]: Severity.Error,
  [Severity.Critical]: Severity.Critical
};
