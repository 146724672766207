import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cn from 'classnames';
import Radio from './Radio';

function RadioGroup({
  initialValue,
  name,
  choices,
  theme = {},
  parse,
  required,
  validate
}) {
  return (
    <div role="radiogroup" className={theme.wrapper}>
      {choices.map(choice => {
        return (
          <div key={choice.value}>
            <Field
              name={name}
              type="radio"
              value={choice.value}
              parse={parse}
              required={required}
              validate={validate}
              initialValue={initialValue}>
              {({ input }) => {
                const classes = cn(theme.radio, {
                  [theme.radioChecked]: input.checked,
                  [theme.radioDisabled]: input.disabled
                });
                return (
                  <Radio
                    {...input}
                    label={choice.label}
                    disabled={choice.disabled}
                    className={classes}
                  />
                );
              }}
            </Field>
          </div>
        );
      })}
    </div>
  );
}

RadioGroup.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      disabled: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    })
  ).isRequired,
  initialValue: PropTypes.any,
  name: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    wrapper: PropTypes.string,
    radio: PropTypes.string,
    radioChecked: PropTypes.string,
    radioDisabled: PropTypes.string
  })
};

export default RadioGroup;
