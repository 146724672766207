import flow from 'lodash/flow';
import get from 'lodash/get';
import { parseEditorState } from '@bit/be-novative.kit.richtext-utils';
import { conceptEditorFormFields } from '../constants';
import {
  getActivities,
  getBenefits,
  getCategory,
  getCosts,
  getIdeaText,
  getTitle,
  isConceptPrivate
} from '../selectors/ideaConcepts';
import { ImageTypes } from '../../constants';

const {
  BENEFITS,
  CATEGORY,
  COSTS,
  IDEA_TEXT,
  IMAGE,
  IMAGE_URL,
  IS_PRIVATE,
  PLANNED_ACTIVITIES,
  TEAM,
  TITLE
} = conceptEditorFormFields;

/**
 * Creates an object representing an empty or pre-filled concept form model that can be used as initial form values.
 * @param {IdeaConcept} [concept] - Optional idea concept for pre-loading the form
 * @param {InvitedUser[]} [invitedUsers] - Optional array of invitee objects. Has to be in a separate argument to avoid denormalization in Redux selectors.
 * @returns {Object}
 */

export default function createConceptFormModel(concept, invitedUsers = []) {
  const imageUrl = get(concept, IMAGE_URL);
  const image = imageUrl
    ? {
        '@type': ImageTypes.UPLOADED, // fake type, because it's not sent by the API
        url: concept[IMAGE_URL]
      }
    : null;
  return {
    [TITLE]: getTitle(concept),
    [IDEA_TEXT]: flow(
      getIdeaText,
      parseEditorState
    )(concept),
    [CATEGORY]: getCategory(concept),
    [IMAGE]: image,
    [BENEFITS]: flow(
      getBenefits,
      parseEditorState
    )(concept),
    [PLANNED_ACTIVITIES]: flow(
      getActivities,
      parseEditorState
    )(concept),
    [COSTS]: flow(
      getCosts,
      parseEditorState
    )(concept),
    [TEAM]: invitedUsers,
    [IS_PRIVATE]: Number(isConceptPrivate(concept))
  };
}
