export const brandGradient = {
  start: '#3EDE91',
  end: '#377dd4'
};

/**
 * Hex color strings that follow colors described in `src/scss/_variables.scss
 * @enum {string} ColorVariantHex
 */

export const ColorVariantHex = {
  primary: '#2372cb',
  success: '#61b73e',
  info: '#c4e0ff',
  danger: '#f24343',
  warning: '#e8b810'
};

export const ColorVariant = {
  primary: 'hsl(212, 71%, 47%)',
  success: 'hsl(103, 49%, 48%)',
  info: 'hsl(212, 100%, 88%)',
  danger: 'hsl(0, 87%, 61%)',
  warning: 'hsl(47, 87%, 49%)'
};

export const darkGrayScale = [
  'hsl(0, 0%, 29%)',
  'hsl(0, 0%, 42%)',
  'hsl(0, 0%, 58%)',
  'hsl(0, 0%, 63%)',
  'hsl(0, 1%, 67%)'
];

export const lightGrayScale = [
  'hsl(0, 0%, 78%)',
  'hsl(0, 0%, 82%)',
  'hsl(0, 0%, 86%)',
  'hsl(0, 3%, 94%)',
  'hsl(0, 0%, 94%)',
  'hsl(180, 100%, 100%)'
];

export const lightTheme = {
  bodyBg: 'white',
  dndCanvas: {
    accentColor: 'hsl(209deg 100% 77%)',
    canvasBg: lightGrayScale[4]
  },
  text: darkGrayScale[0]
};
