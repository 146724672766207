import { Component } from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import withClickOutside from 'react-onclickoutside';
import './Dropdown.css';

export class Dropdown extends Component {
  static propTypes = {
    onHide: PropTypes.func,
    onSelect: PropTypes.func,
    onShow: PropTypes.func
  };
  static defaultProps = {
    onHide: noop,
    onSelect: noop,
    onShow: noop
  };

  state = {
    isActive: false
  };

  handleHide = () => {
    this.setState({
      isActive: false
    });
    this.props.onHide();
  };

  handleShow = () => {
    this.setState({
      isActive: true
    });
    this.props.onShow();
  };

  handleClickOutside = () => {
    this.handleHide();
  };

  handleToggle = () => {
    this.state.isActive ? this.handleHide() : this.handleShow();
  };

  handleSelect = (...args) => {
    this.props.onSelect(...args);
    this.handleHide();
  };

  getChildProps = () => ({
    handleShow: this.handleShow,
    handleHide: this.handleHide,
    handleToggle: this.handleToggle,
    handleSelect: this.handleSelect,
    isActive: this.state.isActive
  });

  render() {
    return this.props.children(this.getChildProps());
  }
}

export default withClickOutside(Dropdown);
