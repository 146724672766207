import memoize from 'lodash/memoize';

const mapVisibilityInfo = ({
  creatorId,
  currentUserId,
  forceReveal,
  ownerId,
  revealedAt
}) =>
  memoize(memberId => {
    const isCreator = creatorId === memberId;
    const isSelf = currentUserId === memberId;
    const isVisible =
      (isCreator && revealedAt) || !isCreator || isSelf || forceReveal;

    return {
      isCreator,
      isOwner: ownerId === memberId,
      isSelf,
      isVisible,
      key: memberId,
      userId: isVisible ? memberId : null
    };
  });

export default mapVisibilityInfo;
