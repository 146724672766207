import { handleActions } from 'redux-actions';
import { NAVIGATION_CONFIRM, NAVIGATION_CONFIRM_CLEAR } from '../../constants';

export default handleActions(
  {
    [NAVIGATION_CONFIRM]: () => true,
    [NAVIGATION_CONFIRM_CLEAR]: () => false
  },
  true
);

export const isNavigationConfirmed = state => state.navigation;
