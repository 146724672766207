import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import Button from '@bit/be-novative.kit.button';
import Dropdown, { DropdownContent } from '../Dropdown';
import Icon from '../Icon';
import FadeTransition from '../FadeTransition';
import Responsive from '../Responsive';
import { SUPPORTED_UI_LANGUAGES_NATIVE } from '../../../constants';

LanguageDropdown.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  availableLanguages: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};
export default function LanguageDropdown({
  currentLanguage,
  availableLanguages,
  onChange
}) {
  const currentLang = SUPPORTED_UI_LANGUAGES_NATIVE[currentLanguage];

  return (
    <Dropdown onSelect={onChange}>
      {({ handleSelect, handleToggle, isActive }) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <Responsive mediaHiddenDown="sm">
            <Button variant="link-secondary" onClick={handleToggle}>
              <span>{currentLang}</span>
              <Icon type="angle-down" />
            </Button>
          </Responsive>
          <Responsive mediaHiddenUp="sm">
            <Button
              size="small"
              variant="link-secondary"
              onClick={handleToggle}>
              <span>{currentLang}</span>
              <Icon type="angle-down" />
            </Button>
          </Responsive>

          <TransitionGroup>
            {isActive && (
              <FadeTransition>
                <DropdownContent>
                  {availableLanguages.map(lang => (
                    <Button
                      key={lang}
                      onClick={() => handleSelect(lang)}
                      variant="secondary"
                      fillParent>
                      {SUPPORTED_UI_LANGUAGES_NATIVE[lang]}
                    </Button>
                  ))}
                </DropdownContent>
              </FadeTransition>
            )}
          </TransitionGroup>
        </div>
      )}
    </Dropdown>
  );
}
