import some from 'lodash/some';
import { shouldUpdate } from 'recompose';
import getDisplayName from '../../../utils/getDisplayName';

const valueChange = (props, nextProps) => props.value !== nextProps.value;
const labelChange = (props, nextProps) => props.label !== nextProps.label;
const secondaryLabelChange = (props, nextProps) =>
  props.secondaryLabel !== nextProps.secondaryLabel;

const diffByValueOrLocaleChange = (...args) =>
  some([
    valueChange(...args),
    labelChange(...args),
    secondaryLabelChange(...args)
  ]);

export default function withManualDiff(diffingFn = diffByValueOrLocaleChange) {
  return WrappedComponent => {
    const newComponent = shouldUpdate(diffingFn)(WrappedComponent);

    newComponent.displayName = `WithManualDiff(${getDisplayName(
      WrappedComponent
    )})`;
    return newComponent;
  };
}
