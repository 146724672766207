import React, { Component } from 'react';
import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import PaymentStartLandingPage from '../../platformCreation/pages/PaymentStartLandingPage';
import asyncComponent from './asyncComponent';
import ErrorPage from './ErrorPage';
import Main from './Main';
import TokenUpdaterPage from './TokenUpdaterPage';
import {
  fetchOwnProfile,
  getProfileStore,
  ownProfileSelector
} from '../../profile/state/profile';
import { AsyncStatus } from '../../constants';
import BootstrapSpinner from '../components/BootstrapSpinner';
import BootstrapError from '../pages/BootstrapError';
import { useEffect } from 'react';
import FeatureFlag from '../../featureFlags/constants';
import { AuthContext } from '../../authentication/components/AuthProvider';
import { getFeatureFlagAsync } from '../../featureFlags/client';
import Alerts from '../components/Alerts';
import CookieConsent from '../components/CookieConsent';
import IntercomClient from '../components/IntercomClient';
import authService from '../../authentication/services/auth.service';

class App extends Component {
  static propTypes = {
    injectModuleReducers: PropTypes.func.isRequired
  };

  static contextType = AuthContext;

  state = { isProfileLoadingSlowly: false, shouldUseNewAuth: false };

  AuthenticationPage = asyncComponent(() =>
    import(
      /* webpackChunkName: "authentication" */ '../../authentication'
    ).then(m => m.default)
  );

  MagiclinkPage = asyncComponent(() =>
    import(/* webpackChunkName: "magiclink" */ '../../magiclink').then(
      magiclinkModule => magiclinkModule.MagiclinkPage
    )
  );

  RegistrationConfirmationPage = asyncComponent(() =>
    import(
      /* webpackChunkName: "registration-confirmation" */ '../../confirmation'
    ).then(confirmationModule => confirmationModule.ConfirmationPage)
  );

  InvitationPage = asyncComponent(() =>
    import(/* webpackChunkName: "invitation" */ '../../invitation').then(
      invitationModule => invitationModule.InvitationPage
    )
  );

  PlatformCreationPage = asyncComponent(() =>
    import(
      /* webpackChunkName: "team-creation" */ '../../platformCreation'
    ).then(m => m.default)
  );

  renderAnonymousRoutes = () => {
    return (
      <Switch>
        <Route path="/magiclink/:code" component={this.MagiclinkPage} />
        <Route path="/createteam" component={this.PlatformCreationPage} />
        <Route
          component={
            this.state.shouldUseNewAuth
              ? RedirectToAuth
              : this.AuthenticationPage
          }
        />
      </Switch>
    );
  };

  renderAuthenticatedRoutes = props => {
    const { injectModuleReducers } = this.props;
    return (
      <Main
        {...props}
        injectModuleReducers={injectModuleReducers}
        shouldFetchProfile={!this.state.shouldUseNewAuth}
      />
    );
  };

  async componentDidMount() {
    const { profileState, fetchOwnProfile } = this.props;
    const shouldUseNewAuth = await getFeatureFlagAsync(FeatureFlag.useNewAuth);

    this.setState({ shouldUseNewAuth });

    if (shouldUseNewAuth && profileState === AsyncStatus.Idle) {
      fetchOwnProfile();
      this.timer = setTimeout(
        () => this.setState({ isProfileLoadingSlowly: true }),
        500
      );
    }
  }

  componentWillReceiveProps({ profileState }) {
    if (
      profileState === AsyncStatus.Succeeded ||
      profileState === AsyncStatus.Failed
    ) {
      clearTimeout(this.timer);
    }
  }

  render() {
    const { alerts, profileState } = this.props;
    const { shouldUseNewAuth } = this.state;

    if (
      shouldUseNewAuth &&
      profileState === AsyncStatus.Loading &&
      this.state.isProfileLoadingSlowly
    ) {
      return <BootstrapSpinner />;
    } else if (shouldUseNewAuth && profileState === AsyncStatus.Failed) {
      return <BootstrapError />;
    }

    return (
      <div>
        <Alerts alerts={alerts} />
        <Switch>
          <Route path="/invite/:code" component={this.InvitationPage} />
          <Route path="/switch/:token" component={TokenUpdaterPage} />
          <Route path="/error" component={ErrorPage} />
          <Route
            path="/createteam-microsoft"
            component={PaymentStartLandingPage}
          />
          <Route
            path="/confirmation"
            component={this.RegistrationConfirmationPage}
          />
          {shouldUseNewAuth ? this.renderNewAuth() : this.renderLegacyAuth()}
        </Switch>
        <CookieConsent />
        <IntercomClient />
      </div>
    );
  }

  renderLegacyAuth = () => {
    return this.context.loggedIn ? (
      <Route render={this.renderAuthenticatedRoutes} />
    ) : (
      <Route render={this.renderAnonymousRoutes} />
    );
  };

  renderNewAuth = () => {
    const { profileState, hasProfile } = this.props;
    return profileState === AsyncStatus.Succeeded ? (
      <>
        {hasProfile ? (
          <Route render={this.renderAuthenticatedRoutes} />
        ) : (
          <Route render={this.renderAnonymousRoutes} />
        )}
      </>
    ) : null;
  };
}

function RedirectToAuth() {
  useEffect(() => {
    authService.loginWithService();
  }, []);
  return null;
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts,
    profileState: flow(
      getProfileStore,
      getFp('status')
    )(state),
    hasProfile: flow(
      ownProfileSelector,
      getFp('userId'),
      Boolean
    )(state)
  };
}

const mapDispatchToProps = { fetchOwnProfile };

export { App };
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
