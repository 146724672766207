/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import { translate } from 'react-i18next';
import Spinner from '@bit/be-novative.kit.spinner';
import Callout from '../../components/Callout';
import { getUrl } from '../../selectors/images';
import scss from './ImagePicker.module.scss';

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string
    })
  ),
  onImageSelect: PropTypes.func,
  onNextPage: PropTypes.func,
  t: PropTypes.func.isRequired
};
export function ImageGallery({
  hasError,
  images = [],
  isLoading,
  onImageSelect,
  onNextPage = () => {},
  onSelect,
  searchResource,
  t,
  ...rest
}) {
  const onScrollEnd = useCallback(
    isEndOfPage => {
      if (isEndOfPage) {
        onNextPage();
      }
    },
    [onNextPage]
  );
  return (
    <div {...rest}>
      <div className={scss.GalleryWrapper}>
        {/* Google search results have identical URLs that can't be used as keys */}
        {images.map((image, ix) => (
          <div className={scss.Image} key={ix}>
            <a className={scss.Link} onClick={() => onImageSelect(image)}>
              <img
                alt=""
                src={getUrl(image, 'url')}
                className={scss.Background}
              />
              <div className={scss.Select}>
                <span>{t('common/userAction/select')}</span>
              </div>
            </a>
          </div>
        ))}
      </div>
      {images.length > 0 && (
        <VisibilitySensor onChange={onScrollEnd}>
          <div style={{ width: 1, height: 1 }} />
        </VisibilitySensor>
      )}
      {isLoading && <Spinner />}
      {!isLoading && hasError && (
        <Callout type="danger">
          {t('common/imagePicker/error/imageLoadFailed')}
        </Callout>
      )}
    </div>
  );
}

export default translate()(ImageGallery);
