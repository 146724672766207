import flow from 'lodash/flow';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { getEntityMap } from '../../common/selectors/entities';
import { getFilterBy, getVisibleIds } from '../../common/selectors/meta';
import { entities } from '../constants';

export const getListPageStore = state => get(state, 'ideaConceptsList', {});

/**
 * Returns the active concept list filter configuration,
 * mapped to an object where the key represents the filter type,
 * and the value represents the filter criteria.
 * @param {import('redux').Store} state
 * @returns {Object}
 */
export const getConceptListFilters = state =>
  flow(
    getListPageStore,
    getFilterBy
  )(state);

/**
 * Returns an array of concept IDs that match
 * the current list filter configuration.
 * @param {import('redux').Store} state
 * @returns {string[]} An array of filtered concept IDs
 */
export const getVisibleIdeaConceptIds = flow(
  getListPageStore,
  getVisibleIds
);

export const getVisibleIdeaConcepts = createSelector(
  getVisibleIdeaConceptIds,
  getEntityMap(entities.IDEA_CONCEPT),
  (visibleIds, concepts) => visibleIds.map(id => concepts[id])
);
