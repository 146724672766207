import cond from 'lodash/cond';
import flow from 'lodash/flow';
import omit from 'lodash/fp/omit';
import mapValues from 'lodash/mapValues';
import negate from 'lodash/negate';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
import { MODAL_OPEN, CHALLENGE_PERMISSIONS } from '../constants';
import { getEntities } from '../common/selectors/entities';
import { rootSelector as conceptEvalRoot } from '../ideaConcept/selectors/conceptEvaluation';
import { rootSelector as evaluationRoot } from '../evaluation/selectors';
import { IGNORED_STORES } from '../telemetry/errorLogging/constants';
import {
  handleNormalisedPayload,
  handleOrdinaryFetchPayload,
  ignorePayload,
  isIgnored,
  isOrdinaryFetchAction,
  isSchemaStoreAction,
  typeMatches,
  scrubModal,
  scrubProfile,
  flattenEntityKeys,
  omitEntities
} from '../telemetry/errorLogging/payloadUtils';
import flattenObject from '../utils/flattenObject';
import scrubChallengeState from '../challenge/utils/logScrubber';
import scrubChallengeResultsState from '../challengeResults/utils/logScrubber';
import scrubIdeationStore from '../ideation/utils/logScrubber';
import { fetchOwnProfile } from '../profile/state/profile';

const actionTransformer = action => {
  return cond([
    [typeMatches(MODAL_OPEN), scrubModal],
    [typeMatches(fetchOwnProfile.fulfilled), scrubProfile],
    [typeMatches(CHALLENGE_PERMISSIONS.SUCCESS), ignorePayload],
    [isOrdinaryFetchAction, handleOrdinaryFetchPayload],
    [isSchemaStoreAction, handleNormalisedPayload]
  ])(action);
};

// only single depth is allowed; we need entity types+IDs saved
const stateTransformer = state =>
  mapValues(
    {
      ...omit(IGNORED_STORES)(state),
      challenge: scrubChallengeState(state),
      challengeResults: scrubChallengeResultsState(state),
      entities: flow(
        getEntities,
        omit(['allIds']),
        flattenEntityKeys
      )(state),
      evaluation: flow(
        evaluationRoot,
        omitEntities
      )(state),
      ideaConceptEvaluation: flow(
        conceptEvalRoot,
        omitEntities
      )(state),
      ideation: scrubIdeationStore(state)
    },
    flattenObject
  );

export default createSentryMiddleware(Sentry, {
  breadcrumbDataFromAction: actionTransformer,
  actionTransformer,
  filterBreadcrumbActions: negate(isIgnored),
  stateTransformer
});
