import { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import 'cookieconsent';
import { getCurrentLanguage } from '../../selectors/language';
import { CONSENTCOOKIE_ID } from '../../../constants';

class CookieConsent extends Component {
  state = {
    isInitialized: false
  };

  componentWillReceiveProps() {
    if (!this.props) return;

    if (!this.state.isInitialized) {
      this.initialize();
    }
  }

  initialize() {
    if (window.cookieconsent) {
      this.instance = window.cookieconsent.initialise(
        this.getCookieConsentConfig()
      );
      this.setState({ isInitialized: true });
    }
  }

  getCookieConsentConfig() {
    const { t } = this.props;

    return {
      type: 'opt-out',
      cookie: {
        name: CONSENTCOOKIE_ID
      },
      content: {
        href: `//${t('URIs/www.be-novative.com/privacy_policy.html')}`,
        message:
          `<h4 class="cookieconsent-title">${t('legalCookieBar/title')}</h4>` +
          t('legalCookieBar/description'),
        allow: t('legalCookieBar/accept'),
        deny: t('legalCookieBar/decline'),
        link: t('legalCookieBar/policyLink')
      },
      revokable: false
    };
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  language: getCurrentLanguage(state)
});

export default compose(
  translate(),
  connect(mapStateToProps)
)(CookieConsent);
