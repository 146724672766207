import isEqual from 'lodash/fp/isEqual';
import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import { CommonException } from '../constants';
import { getApiResponse, getApiResponseCode } from './apiResponse';

const isMissingError = errorCode => errorCode === 404;

const isNetworkError = error =>
  !isTimeoutError(error) && !getApiResponse(error) && error.isAxiosError;

const isServerError = errorCode => errorCode >= 500;

const isTimeoutError = error =>
  flow(
    getFp('code'),
    isEqual('ECONNABORTED')
  )(error);

const isBadRequest = errorCode => errorCode === 400;

const isUnauthenticated = errorCode => errorCode === 401;

function convertStatusToInternalCode(errorCode) {
  if (isMissingError(errorCode)) {
    return CommonException.NotFound;
  } else if (isServerError(errorCode)) {
    return CommonException.ServerError;
  } else if (isBadRequest(errorCode)) {
    return CommonException.BadRequest;
  } else if (isUnauthenticated(errorCode)) {
    return CommonException.Unauthenticated;
  } else {
    return CommonException.General;
  }
}

/**
 * Converts an API response error object to an error code internal to the UI
 * @summary The function distinguishes 404 and 500 errors, timeouts and network errors. Other errors are classified as _general_.
 * @param {Error} apiError - The error object returned from a failed API request
 * @return {CommonException} The error's internal representation
 */

function convertApiErrorToStatusCode(apiError) {
  if (isTimeoutError(apiError)) {
    return CommonException.Timeout;
  } else if (isNetworkError(apiError)) {
    return CommonException.NetworkError;
  } else {
    return flow(
      getApiResponseCode,
      convertStatusToInternalCode
    )(apiError);
  }
}

/**
 * Converts a websocket error to an error code internal to the UI
 * @summary The function distinguishes 404 and 500 errors, timeouts and network errors. Other errors are classified as _general_.
 * @param {Error} apiError - The error object returned from a failed API request
 * @return {CommonException} The error's internal representation
 */

function convertSocketErrorToStatusCode(socketError) {
  const socketErrorCode = get(socketError, 'statusCode');
  if (socketErrorCode === 0) {
    // CORS or network error
    return CommonException.NetworkError;
  } else {
    return convertStatusToInternalCode(socketErrorCode);
  }
}

export { convertApiErrorToStatusCode, convertSocketErrorToStatusCode };
