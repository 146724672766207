import isEmpty from 'lodash/isEmpty';
import { getTokenData } from '../authentication/services/token.service';
import initSentry from './errorLogging/init';
import initAppInsights from './monitoring/init';
import setUserContext from './setUserContext';

/**
 * Initialise Sentry and Azure App Insights telemetry. If the user is logged in, the user context is also set
 */

export default function initTelemetry() {
  initSentry();
  initAppInsights();

  const tokenData = getTokenData();
  const hasContext = !isEmpty(tokenData);

  if (hasContext) {
    setUserContext(tokenData.userId, tokenData.email);
  }
}
