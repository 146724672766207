import { openModal } from '../common/actions';
import history from '../history';
import { isTokenValid } from '../authentication/services/token.service';

/**
 * Filter out { meta: { authenticated: true }} actions when the user is not authenticated.
 *
 * authenticated meta property can be the following:
 * * *boolean*: if true the action will be suppressed
 * * *object*:
 *    * *redirect*: redirect the browser to the given url
 *    * *modalType* and *modalProps*: open *modalType* modal with *modalProps* props
 */
const createAuthGuard = config => ({
  getState,
  dispatch
}) => next => action => {
  const { meta } = action;
  if (meta && meta.authenticated && !isTokenValid()) {
    const { authenticated } = meta;

    // redirect
    if (authenticated.redirect) {
      history.push(authenticated.redirect);
    }

    // modal
    if (authenticated.modalType) {
      dispatch(
        openModal({
          modalType: authenticated.modalType,
          modalProps: authenticated.modalProps
        })
      );
    }

    // Just suppress the action.
    return Promise.reject('Not logged in.');
  }

  return next(action);
};

export default createAuthGuard;
