import React from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import styled from 'styled-components';
import { darkGrayScale } from '../../../common/styled/colors.styled';
import Icon from '../../../common/components/Icon';
import Panel from '../../../common/components/Panel';
import withModal from '../../../common/containers/withModal';
import Avatar from '../../../common/components/Avatar';
import { getUserAvatar } from '../../../common/getters/users';
import localiseUserName from '../../../utils/localiseUserName';
import LinkifiedSafeHtml from '../../../common/components/LinkifiedSafeHtml/LinkifiedSafeHtml';
import { Size } from '../../../constants';

const Attrib = styled.span`
  margin-right: 10px;
  line-height: 1.25em;
  color: ${darkGrayScale[1]};
  text-transform: uppercase;
`;

function CommentDisplayModal({ comment, authorId, t }) {
  return (
    <Panel
      header={t('ideaConcept/evaluation/comment/title', {
        context: 'feedback-readonly'
      })}
      rounded>
      <p>
        <Attrib>
          {t('ideaConcept/evaluation/resultDisplay/evaluators/listHeader')}:
        </Attrib>
        {authorId ? (
          <>
            <Avatar
              image={getUserAvatar(comment.user)}
              name={localiseUserName(t, comment.user)}
              size={Size.xs}
              style={{ marginRight: 5 }}
            />
            {localiseUserName(t, comment.user)}
          </>
        ) : (
          <>
            <Icon type="team" style={{ marginRight: 5 }} size={Size.md} />
            Review Committee
          </>
        )}
      </p>
      <p>
        <Attrib>{t('ideaConcept/evaluation/comment/lastMod')}:</Attrib>
        {new Date(comment.createdAt).toLocaleString()}
      </p>
      <div>
        <LinkifiedSafeHtml html={comment.text} />
      </div>
    </Panel>
  );
}

export default compose(
  withModal(),
  translate()
)(CommentDisplayModal);
