import PropTypes from 'prop-types';
import useFeatureFlag from '../../hooks/useFeatureFlag';

BranchByFeature.propTypes = {
  fallbackComponent: PropTypes.node,
  feature: PropTypes.string.isRequired
};
function BranchByFeature({ children, fallbackComponent, feature }) {
  const isFeatureEnabled = useFeatureFlag(feature);

  if (isFeatureEnabled) {
    return children;
  } else if (fallbackComponent) {
    return fallbackComponent;
  } else {
    return null;
  }
}

export default BranchByFeature;
