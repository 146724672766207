import React, { Component } from 'react';
import { Input } from '../';

const protocolRegExp = /^https?:\/\//;

class UrlInput extends Component {
  addProtocol(value) {
    return `http://${value}`;
  }

  handleChange = event => {
    const { onChange } = this.props.input;
    const value = event.target.value || '';
    if (value.length < 8 || protocolRegExp.test(value)) {
      onChange(value);
    } else {
      onChange(this.addProtocol(value));
    }
  };

  handleBlur = event => {
    const { onBlur } = this.props.input;
    const value = event.target.value || '';
    if (!value || protocolRegExp.test(value)) {
      onBlur(value);
    } else {
      onBlur(this.addProtocol(value));
    }
  };

  render() {
    const { input, ...rest } = this.props;
    return (
      <Input
        {...rest}
        input={{
          ...input,
          onChange: this.handleChange,
          onBlur: this.handleBlur
        }}
      />
    );
  }
}

export default UrlInput;
