import React from 'react';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';

Counter.propTypes = {
  max: PropTypes.number,
  title: PropTypes.string
};

function Counter({ children, max = 99, title, ...rest }) {
  const numValue = Number(children);
  let displayedValue = numValue;
  if (isNumber(numValue) && max < numValue) {
    displayedValue = `${max}+`;
  }

  return (
    <span {...rest} aria-label={title} title={numValue}>
      {displayedValue}
    </span>
  );
}

export default Counter;
