import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import './PaginationItem.css';

class PaginationItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    eventKey: PropTypes.any,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
    active: PropTypes.bool
  };

  static defaultProps = {
    active: false,
    disabled: false
  };

  handleClick = event => {
    const { disabled, onSelect, eventKey } = this.props;

    if (disabled) {
      return;
    }

    if (onSelect) {
      onSelect(eventKey, event);
    }
  };

  render() {
    const {
      active,
      disabled,
      className,
      style,
      onSelect,
      eventKey,
      ...props
    } = this.props;

    const classes = classNames(className, 'PaginationItem', {
      'PaginationItem--active': active,
      'PaginationItem--disabled': disabled
    });

    return (
      <li className={classes} style={style}>
        <button
          className={'PaginationItem__Button'}
          {...props}
          disabled={disabled}
          onClick={this.handleClick}
        />
      </li>
    );
  }
}

export default PaginationItem;
