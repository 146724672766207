import trim from 'lodash/trim';
import LocalStorage from 'store2';
import { PREFERRED_LANGUAGE_LOCALSTORAGE_KEY } from '../constants';

export function persistPreferredLanguage(lang) {
  if (trim(lang)) {
    LocalStorage.set(PREFERRED_LANGUAGE_LOCALSTORAGE_KEY, lang);
  }
}

export function getPreferredLanguage() {
  return LocalStorage.get(PREFERRED_LANGUAGE_LOCALSTORAGE_KEY);
}
