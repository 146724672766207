import React from 'react';
import { useScrollAnchor } from '../ScrollSpyProvider';

export default function ScrollAnchor({ index }) {
  const anchor = useScrollAnchor(index);
  return (
    <div
      ref={anchor}
      style={{ width: 1, height: 1, display: 'inline-block' }}
    />
  );
}
