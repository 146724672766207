import get from 'lodash/get';
import {
  UPDATE_IDEA_CONCEPT_FILTER,
  REMOVE_IDEA_CONCEPT_FILTERS,
  REVEAL_IDEACONCEPT_IDENTITY,
  EDIT_IDEA_CONCEPT
} from '../../constants';
import {
  CHANGE_CONCEPT_STAGE,
  CONCEPT_LIST_CHANGE_STAGE_FILTER,
  CONCEPT_LIST_CHANGE_SORT,
  CREATE_QUICK_CONCEPT_FORM_OPEN,
  CREATE_IDEA_CONCEPT
} from '../../ideaConcept/constants';
import { getPage } from '../pageProvider';
import EventTracker from './_EventTracker';

const EVENT_GROUP = 'ideaConcepts';

export default class IdeaConceptsTracker extends EventTracker {
  constructor(metrics) {
    super(metrics);
    this.eventGroup = EVENT_GROUP;
  }

  track(action, userId) {
    const { type } = action;
    const metrics = get(
      action,
      'metrics',
      get(action, ['meta', 'metrics'], {})
    );
    const commonData = {
      userId
    };
    if (type === CHANGE_CONCEPT_STAGE) {
      this.coreTrack(type, {
        ...commonData,
        ideaConceptId: get(metrics, 'ideaConceptId'),
        page: getPage()
      });
    } else if (type === EDIT_IDEA_CONCEPT.SUCCESS) {
      this.coreTrack(type, {
        ...commonData,
        ideaConceptId: get(metrics, 'ideaConceptId')
      });
    } else if (type === REMOVE_IDEA_CONCEPT_FILTERS) {
      this.coreTrack(type, commonData);
    } else if (type === REVEAL_IDEACONCEPT_IDENTITY.SUCCESS) {
      this.coreTrack(type, commonData);
    } else if (type === UPDATE_IDEA_CONCEPT_FILTER) {
      this.coreTrack(type, {
        ...commonData,
        filterId: get(metrics, 'id')
      });
    } else if (type === CONCEPT_LIST_CHANGE_STAGE_FILTER) {
      this.coreTrack('CONCEPT_LIST_CHANGE_STAGE_FILTER', {
        ...commonData,
        filterId: get(metrics, 'stage')
      });
    } else if (type === CONCEPT_LIST_CHANGE_SORT) {
      this.coreTrack('CONCEPT_LIST_CHANGE_SORT', {
        ...commonData,
        sortBy: get(metrics, 'sortBy'),
        sortDirection: get(metrics, 'sortDirection')
      });
    } else if (type === CREATE_QUICK_CONCEPT_FORM_OPEN) {
      this.coreTrack('CREATE_QUICK_CONCEPT_FORM_OPEN', {
        ...commonData
      });
    } else if (type === CREATE_IDEA_CONCEPT.SUCCESS) {
      this.coreTrack('CREATE_QUICK_CONCEPT', {
        ...commonData
      });
    }
  }
}
