import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Checkbox from '@bit/be-novative.kit.checkbox';
import {
  getChallengeById,
  getTitle
} from '../../../common/selectors/challenges';
import scss from './ChallengeFilter.module.scss';

ChallengeRow.propTypes = {
  checked: PropTypes.bool,
  challengeId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  title: PropTypes.string
};
function ChallengeRow({ challengeId, checked, onSelect }) {
  const challenge = useSelector(getChallengeById(challengeId));

  return (
    <div style={{ padding: '.5rem 0', borderBottom: '1px solid #ccc' }}>
      <Checkbox
        input={{ name: challengeId, onChange: onSelect, value: checked }}
        size="sm"
        className={scss.Row}>
        {getTitle(challenge)}
      </Checkbox>
    </div>
  );
}

export default ChallengeRow;
