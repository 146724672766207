import toUpper from 'lodash/toUpper';
import appInsights from '.';
import { MonitoringSeverity } from './constants';

/**
 * Logs a trace to Azure App Insights
 * @param {string} message - The trace message
 * @param {Object} [data] - Optional contextual data
 * @param {MonitoringSeverity} [severity] - Trace level. Defaults to Info.
 * @param {string} [category] - Message category, e.g. `'challenge'`, `'profile'`, etc.
 * @return {void}
 */

export default function trackTrace(
  message,
  data = {},
  severity = MonitoringSeverity.INFO,
  category = ''
) {
  const trace = ['[UI]'];
  if (category) {
    trace.push(`[${toUpper(category)}]`);
  }
  trace.push(message);
  appInsights.trackTrace({
    message: trace.join(' '),
    properties: { ...data, timestamp: Date.now() },
    severityLevel: MonitoringSeverity[severity]
  });
}
