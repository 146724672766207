import { handleActions, combineActions } from 'redux-actions';

const loadingReducerFactory = (action, initialState = false) =>
  handleActions(
    {
      [combineActions(action.ERROR, action.SUCCESS)]: () => false,
      [action.PENDING]: () => true
    },
    initialState
  );

export default loadingReducerFactory;
