import { EMAIL_VALIDATE } from '../../constants';
import { IDENTITY_API } from '../../api/constants';

export const validateEmail = email => {
  return {
    [IDENTITY_API]: {
      url: `registration/email-availability/${email}`,
      method: 'GET',
      type: EMAIL_VALIDATE
    }
  };
};
