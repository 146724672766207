import styled, { css } from 'styled-components';
import Avatar from '../../../common/components/Avatar';
import { lightGrayScale } from '../../../common/styled/colors.styled';

const flexBasisFromProp = (props = { width: 'auto' }) => props.width;
const heightFromProp = props =>
  props.height
    ? css`
        height: ${props.height};
      `
    : null;

const ROW_HEIGHT = 50;
const PADDING = 5;

const Table = styled.div`
  display: flex;
  ${({ nested }) =>
    nested &&
    css`
      margin: -5px;
    `}
`;

const StickyGroup = styled.div`
  flex: 0 0 ${flexBasisFromProp};
  ${heightFromProp};
`;

const TableBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow-x: auto;
  overflow-y: hidden;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 0 0 ${flexBasisFromProp};
`;

const Cell = styled.div`
  height: 100%;
  padding: ${PADDING}px;
  display: flex;
  flex: 0 0 ${flexBasisFromProp};
  ${heightFromProp};
  border-bottom: 1px solid #f2eced;
  ${({ expanded }) =>
    expanded
      ? css`
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        `
      : css`
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: ${ROW_HEIGHT}px;
        `}
`;

const TitleCellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const TitleCellLabel = styled.span`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CommentCell = styled.div`
  height: 2em;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background 0.25s linear;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        background: ${lightGrayScale[4]};
      }
    `}
`;

const AlignedCellContent = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  flex-basis: ${({ flexBasis }) => flexBasis || 'auto'};
  flex-grow: 1;
  width: 100%;
  height: 100%;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
      overflow: hidden;
    `}
`;

const MAGIC = 2;
const SingleLineHeight = styled.div`
  line-height: ${ROW_HEIGHT - PADDING * 2 - MAGIC}px;
`;

const ConceptReviewerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;
  margin-bottom: 10px;
  ${styled(Avatar)`
    flex-shrink: 0;
  `}
`;

const ReviewerName = styled.span`
  display: inline-block;
  flex-grow: 1;
`;

const FloatingDebug = styled.pre`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  font-size: 0.6em;
  pointer-events: none;
`;

export {
  AlignedCellContent,
  FloatingDebug,
  Table,
  StickyGroup,
  TableBody,
  TitleCellWrapper,
  TitleCellLabel,
  CommentCell,
  Row,
  Cell,
  ConceptReviewerRow,
  ReviewerName,
  SingleLineHeight
};
