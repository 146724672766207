import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import identity from 'lodash/identity';
import { Trans } from 'react-i18next';
import {
  ACCOUNT_MANAGEMENT_CONSENT,
  PERSONAL_DATA_MANAGEMENT_ACCEPTED,
  COOKIES_AND_LOGS_ACCEPTED,
  MVM_PRIVACY_URL
} from '../profile/constants';
import { getPlatformName } from './platform';

const PLATFORM_NAME = getPlatformName();

const getDefaultConsentModel = t => [
  {
    name: ACCOUNT_MANAGEMENT_CONSENT,
    label: t('legalConsent/accountMgmt'),
    warning: {
      variant: 'danger',
      title: t('profile/deleteAccount/confirmDialog/title'),
      text: t('profile/deleteAccount/confirmDialog/details'),
      okLabel: t('profile/deleteAccount/confirmDialog/okCTA'),
      cancelLabel: t('profile/deleteAccount/confirmDialog/cancelCTA')
    },
    choices: [
      {
        name: PERSONAL_DATA_MANAGEMENT_ACCEPTED,
        label: t('legalConsent/personalDataMgmt')
      },
      {
        name: COOKIES_AND_LOGS_ACCEPTED,
        label: t('legalConsent/cookiesAndLogs')
      }
    ]
  }
];

const getConsentModel = (t = identity) => {
  const CUSTOM_MODELS = {
    'mvm-csoport': [
      {
        ...getDefaultConsentModel(t)[0],
        choices: [
          {
            name: 'OVER_18',
            label: t('legalConsent/mvm-csoport/over18')
          },
          {
            name: 'MVM_PRIVACY',
            label: (
              <Trans i18nKey="legalConsent/mvm-csoport/acceptPrivacy">
                <a
                  href={MVM_PRIVACY_URL}
                  target="_blank"
                  rel="noopener noreferrer">
                  label
                </a>
              </Trans>
            )
          }
        ]
      }
    ]
  };
  return get(CUSTOM_MODELS, PLATFORM_NAME);
};

export const generateConsentModel = t =>
  getConsentModel(t) || getDefaultConsentModel(t);

export const hasPlatformCustomConsent = () => !isEmpty(getConsentModel());
