import client from '../../api/client';
import logRequestFailure from '../../api/utils/logRequestFailure';
import addBreadcrumb from '../../telemetry/addBreadcrumb';

/**
 * Creates a user account
 * @param {UserSignupPayload} userData - The account details
 * @param {string} captchaToken - Token that must be sent to the API for verification
 * @param {string} [invitationCode] - The invitation code decoded from a magic link, in case the user was invited to the platform
 * @return {Promise} The pending request
 */

function signupUser(userData, captchaToken, invitationCode) {
  const config = invitationCode
    ? {
        headers: {
          'X-INVITATION-CODE': invitationCode
        }
      }
    : undefined;

  return client.post(`/registration?captcha=${captchaToken}`, userData, config);
}

/**
 * Verifies the user account
 * @param {string} userId
 * @param {string} platformId
 * @param {string} verificationKey - The key received in the confirmation email
 * @return {Promise} The pending request
 */

function verifyAccount(userId, platformId, verificationKey) {
  addBreadcrumb('Verifying user account...', {
    data: { userId, platformId, key: verificationKey }
  });
  try {
    return client.post('/users/confirm', {
      userId,
      companyId: platformId,
      key: verificationKey
    });
  } catch (error) {
    logRequestFailure(error, 'Could not verify user account', 'authentication');
    throw error;
  }
}

/**
 * Triggers re-sending the account verification email for the user
 * @param {string} token - The user's valid token
 * @return {Promise} The pending request
 */

function resendAccountVerificationEmail(token) {
  return client.get('/registration/resend-email', {
    headers: {
      Authorization: token
    }
  });
}

export default {
  signupUser,
  verifyAccount,
  resendAccountVerificationEmail
};
