import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { INTERNET_STATUS_CHANGE, InternetStatus } from '../../constants';

const internetStatus = handleActions(
  {
    [INTERNET_STATUS_CHANGE](state, { payload }) {
      return payload;
    }
  },
  InternetStatus.CONNECTED
);

export default combineReducers({
  internetStatus
});

export const internetStatusSelector = state => state.network.internetStatus;
