import startsWith from 'lodash/startsWith';
import * as Sentry from '@sentry/browser';
import { stringifyQueryParams } from '../utils/parseQueryString';

export const login = redirectPath => {
  let loginPath = '/login';

  if (redirectPath) {
    loginPath += `?redirect=${redirectPath}`;
  }

  if (startsWith(loginPath, '//')) {
    Sentry.captureException(new Error('Invalid redirect target'));
  }

  return loginPath;
};

export const registration = query =>
  `/registration${stringifyQueryParams(query)}`;

export const socialSignup = () => '/social';

export default {
  registration,
  socialSignup,
  login
};
