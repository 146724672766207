import { FETCH_NOTIFICATIONS, NOTIFICATIONS_MARK } from '../../constants';
import { VOYAGER_API } from '../../api/constants';
import { NotificationGroups } from '../constants';

export const fetchNotifications = ({ groupId, page }) => {
  return {
    [VOYAGER_API]: {
      url: '/notifications',
      method: 'GET',
      type: FETCH_NOTIFICATIONS,
      page,
      params: {
        ...(groupId ? { types: NotificationGroups[groupId] } : {})
      }
    },
    meta: {
      authenticated: true
    }
  };
};

export const markNotifications = notificationIds => {
  return {
    [VOYAGER_API]: {
      url: '/notifications/read',
      method: 'PUT',
      data: notificationIds,
      type: NOTIFICATIONS_MARK
    },
    meta: {
      authenticated: true
    }
  };
};
