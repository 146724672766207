import React from 'react';
import Icon from '../Icon';
import StyleButton from './StyleButton';

const INLINE_STYLES = [
  { label: <Icon type="bold" size="lg" />, style: 'BOLD' },
  { label: <Icon type="italic" size="lg" />, style: 'ITALIC' },
  { label: <Icon type="underline" size="lg" />, style: 'UNDERLINE' }
];

const InlineStyleControls = ({ editorState, onToggle }) => {
  var currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className="RichText__Controls">
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

export default InlineStyleControls;
