import { LOVE_CHALLENGE, LOVE_IDEA, entities } from '../../constants';
import { VOYAGER_API } from '../../api/constants';
import { updateEntity } from './entities';
import { getTokenData } from '../../authentication/services/token.service';

export const loveChallenge = (
  challengeId,
  isLoved = true,
  metrics
) => dispatch => {
  return dispatch({
    [VOYAGER_API]: {
      url: `/challenges/${challengeId}/love`,
      method: 'PUT',
      type: LOVE_CHALLENGE,
      challengeId,
      data: {
        loved: isLoved
      },
      metrics: { ...metrics, loved: isLoved }
    }
  }).then(() => {
    dispatch(updateEntity(entities.CHALLENGE, challengeId)({ loved: isLoved }));
  });
};

export const loveIdea = (ideaId, isLoved = true, metrics) => dispatch => {
  const { userId } = getTokenData();
  return dispatch({
    [VOYAGER_API]: {
      url: `/ideas/${ideaId}/love`,
      method: 'PUT',
      type: LOVE_IDEA,
      ideaId,
      userId,
      data: {
        loved: isLoved
      },
      metrics: metrics ? { ...metrics, loved: isLoved } : undefined
    },
    meta: {
      authenticated: {
        redirect: '/login'
      }
    }
  });
};
