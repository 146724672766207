import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeListSortMetrics } from '../../../actions';
import { getSortBy, getSortDirection } from '../../../../common/selectors/meta';
import { getListPageStore } from '../../../selectors/conceptList';

export default function useSorting({ onChange }) {
  const listStore = useSelector(getListPageStore);
  const sortBy = getSortBy(listStore);
  const sortDirection = getSortDirection(listStore);

  const dispatch = useDispatch();
  const updateSortFn = useCallback(
    sortConfig => {
      onChange({ query: { ...sortConfig, start: 0 }, flushList: true });
      dispatch(changeListSortMetrics(sortConfig));
    },
    [dispatch, onChange]
  );

  return [sortBy, sortDirection, updateSortFn];
}
