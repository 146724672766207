import React, { useCallback } from 'react';
import get from 'lodash/get';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import { translate } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  required,
  composeValidators,
  password
} from '../../../utils/validation';
import parseQueryString from '../../../utils/parseQueryString';
import translateApiErrorMessage from '../../../utils/translateApiErrorMessage';
import { PasswordPolicy } from '../../../constants';
import { CommonException } from '../../../api/constants';
import profileApi from '../../api/profileApi';
import formControl from '@bit/be-novative.kit.form-control';
import Button from '@bit/be-novative.kit.button';
import Callout from '../../../common/components/Callout';
import Grid from '../../../common/components/Grid';
import Password from '@bit/be-novative.kit.password';
import PasswordResetHelp from '../../components/PasswordResetHelp';

export const RESET_TOKEN_QUERY = 'code';
const PasswordFormControl = formControl(Password);

function PasswordChangeForm({ t }) {
  const location = useLocation();
  const routeParams = parseQueryString(location.search);
  const resetToken = get(routeParams, RESET_TOKEN_QUERY);

  const handleSubmit = useCallback(
    async function handleSubmit({ currentPassword, newPassword }) {
      try {
        return await profileApi.changePassword(
          currentPassword || resetToken,
          newPassword
        );
      } catch (error) {
        let message = translateApiErrorMessage(t, error.code);
        if (error.code === CommonException.Unauthenticated) {
          message = t(
            `profile/changePasswordForm/error/${
              resetToken ? 'invalidToken' : 'invalidPassword'
            }`
          );
        }
        return { [FORM_ERROR]: message };
      }
    },
    [resetToken, t]
  );

  return (
    <Grid.Row gutter={0}>
      <Grid.Col xsGutterX={2} md={8} lg={6}>
        <h1>
          {resetToken
            ? t('profile/changePasswordForm/title/reset')
            : t('profile/changePasswordForm/title/update')}
        </h1>
        <Form onSubmit={handleSubmit}>
          {({ form, handleSubmit }) => {
            const {
              submitting,
              submitSucceeded,
              submitError,
              dirtySinceLastSubmit
            } = form.getState();

            return (
              <form onSubmit={handleSubmit}>
                {!resetToken && (
                  <Field
                    name="currentPassword"
                    label={t(
                      'profile/changePasswordForm/fields/currentPassword/label'
                    )}
                    component={PasswordFormControl}
                    showLabel={t('common/show')}
                    validate={required(t)}
                    required
                  />
                )}

                <Field
                  name="newPassword"
                  label={t(
                    'profile/changePasswordForm/fields/newPassword/label'
                  )}
                  component={PasswordFormControl}
                  showLabel={t('common/show')}
                  validate={composeValidators(
                    required(t),
                    password(t, PasswordPolicy)
                  )}
                  required
                  hint={t(
                    'profile/changePasswordForm/fields/newPassword/hint',
                    PasswordPolicy
                  )}
                />

                <footer style={{ textAlign: 'right' }}>
                  <Button
                    variant="success"
                    type="submit"
                    loading={submitting}
                    fillParent>
                    {t('profile/changePasswordForm/submitCTA')}
                  </Button>
                </footer>

                {submitSucceeded && (
                  <Callout type="success" tag="p">
                    {t('profile/changePasswordForm/submitSuccess')}
                  </Callout>
                )}

                {submitError && !dirtySinceLastSubmit && (
                  <Callout type="danger" tag="p">
                    {submitError}
                  </Callout>
                )}

                {(!resetToken || submitError) && (
                  <PasswordResetHelp style={{ marginTop: '3em' }} />
                )}
              </form>
            );
          }}
        </Form>
      </Grid.Col>
    </Grid.Row>
  );
}

export default translate()(PasswordChangeForm);
