// GDPR
// TODO [CLOUD-5368] duplicates `UserConsentKeys`. See `src/constants.js`

export const ACCOUNT_MANAGEMENT_CONSENT = 'accountManagementConsent';
export const COOKIES_AND_LOGS_ACCEPTED = 'cookiesAndLogsAccepted';
export const PERSONAL_DATA_MANAGEMENT_ACCEPTED =
  'personalDataManagementAccepted';

export const EMAIL_NOTIFICATIONS_CONSENT = 'emailNotificationsConsent';
export const CHALLENGE_INVITATIONS_ACCEPTED = 'challengeInvitationsAccepted';

export const MARKETING_EMAILS_CONSENT = 'marketingEmailsConsent';
export const MARKETING_EMAILS_ACCEPTED = 'marketingEmailsAccepted';
export const NEWSLETTER_ACCEPTED = 'newsletterAccepted';

export const TERMS_AND_PRIVACY_ACCEPTED = 'termsAndPrivacyAccepted';

export const MVM_PRIVACY_URL =
  'https://prod-benovative-client-assets.s3.eu-central-1.amazonaws.com/mvm-csoport/mvm-csoport_adatkezelesi_tajekoztato.pdf';

// configuration

export const POSITION_MAXLENGTH = 200;
export const WORKPLACE_MAXLENGTH = 200;
export const PROFILE_IMAGE_ASPECT_RATIO = 1;
export const PROFILE_IMAGE_CROP_HEIGHT = 200;

// definitions

/**
 * @enum {string} PROFILE_FORM_FIELDS
 * @readonly
 * @see OwnProfilePayload
 */
export const PROFILE_FORM_FIELDS = {
  DESCRIPTION: 'aboutMe',
  FIRST_NAME: 'firstName',
  IMAGE: 'image',
  LANGUAGE: 'language',
  LAST_NAME: 'lastName',
  POSITION: 'position',
  WORKPLACE: 'workplace',
  WEBSITE_URL: 'websiteUrl',
  LINKEDIN_URL: 'linkedInUrl',
  CHALLENGE_LANGS: 'preferredChallengeLanguages',
  CONSENT: 'userConsent'
};
