import React from 'react';
import './Ellipsis.css';

Ellipsis.defaultProps = {
  tag: 'span'
};

function Ellipsis({ children, tag: Tag }) {
  return (
    <Tag className="Ellipsis">
      {children}
    </Tag>
  );
}

export default Ellipsis;
