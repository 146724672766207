import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';
import {
  SET_USERAGENT_LANGUAGE,
  SUCCESS_LANGUAGE_CHANGE
} from '../../constants';

const current = handleAction(
  SUCCESS_LANGUAGE_CHANGE,
  (state, { payload }) => payload,
  ''
);

const userAgent = handleAction(
  SET_USERAGENT_LANGUAGE,
  (state, { payload }) => payload,
  ''
);

export default combineReducers({
  current,
  userAgent
});
