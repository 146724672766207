import React from 'react';
import './Separator.css';

function Separator({ children }) {
  return (
    <div className="Separator">
      <span className="Separator__Content">{children}</span>
    </div>
  );
}

export default Separator;
