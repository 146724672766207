import {
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import head from 'lodash/head';
import takeRight from 'lodash/takeRight';
import { normalize } from 'normalizr';
import { convertApiErrorToStatusCode } from '../../api/utils/apiError';
import { addEntity } from '../../common/actions/entities';
import { getVisibleIds } from '../../common/selectors/meta';
import { AsyncStatus } from '../../constants';
import handleApiCallAction from '../../utils/handleApiCallAction';
import conceptReviewApi from '../api/conceptReviewApi';
import { entities } from '../constants';
import schemas from '../schemas';
import { getIdeaConceptId } from '../selectors/ideaConcepts';

/**
 * Calls the API to fetch the first item of a session for review.
 * @param {Object} query - A valid concept filter query map
 * @returns {Promise}
 */
const fetchFirstConcept = createAsyncThunk(
  'fetchFirstConcept',
  async (arg, thunkApi) => {
    try {
      const result = await handleApiCallAction(conceptReviewApi.initSession)(
        arg,
        thunkApi
      );
      if (result) {
        thunkApi.dispatch(
          addEntity(
            entities.IDEA_CONCEPT,
            normalize(result, schemas.IDEA_CONCEPT)
          )
        );
        return getIdeaConceptId(result);
      } else {
        return result;
      }
    } catch (error) {
      const code = convertApiErrorToStatusCode(error);
      return thunkApi.rejectWithValue({ error, code });
    }
  }
);

const reviewConcept = createAsyncThunk(
  'reviewConcept',
  async (arg, thunkApi) => {
    try {
      const [
        lastConcept,
        currentConcept
      ] = await conceptReviewApi.reviewConcept(
        arg.conceptId,
        arg.query,
        arg.scores
      );
      if (lastConcept) {
        thunkApi.dispatch(
          addEntity(
            entities.IDEA_CONCEPT,
            normalize(lastConcept, schemas.IDEA_CONCEPT)
          )
        );
      }
      if (currentConcept) {
        thunkApi.dispatch(
          addEntity(
            entities.IDEA_CONCEPT,
            normalize(currentConcept, schemas.IDEA_CONCEPT)
          )
        );
      }
      return getIdeaConceptId(currentConcept);
    } catch (error) {
      const code = convertApiErrorToStatusCode(error);
      return thunkApi.rejectWithValue({ error, code });
    }
  }
);

const initialState = {
  visibleIds: [],
  status: AsyncStatus.Idle,
  error: null,
  hasFetchedAllAvailableItems: false,
  offset: 0
};

const conceptReviewSlice = createSlice({
  name: 'conceptReview',
  initialState,
  reducers: {
    flushSession() {
      return initialState;
    }
  },
  extraReducers: builder => {
    function handleLoad(state) {
      state.status = AsyncStatus.Loading;
      state.error = null;
    }
    function handleSuccess(state, action) {
      state.status = AsyncStatus.Succeeded;
      state.offset = 0;
      if (action.payload && !state.visibleIds.includes(action.payload)) {
        state.visibleIds.push(action.payload);
      } else if (!action.payload) {
        state.hasFetchedAllAvailableItems = true;
      }
    }
    function handleError(state, action) {
      state.status = AsyncStatus.Failed;
      state.error = action.payload;
    }
    builder
      .addCase(fetchFirstConcept.pending, handleLoad)
      .addCase(fetchFirstConcept.fulfilled, handleSuccess)
      .addCase(fetchFirstConcept.rejected, handleError)
      .addCase(reviewConcept.pending, handleLoad)
      .addCase(reviewConcept.fulfilled, handleSuccess)
      .addCase(reviewConcept.rejected, handleError);
  }
});

const getReviewStore = getFp('conceptReview');

const selectors = {
  getCurrentReviewedConcept: createSelector(
    flow(
      getReviewStore,
      getVisibleIds
    ),
    flow(
      getReviewStore,
      getFp('offset')
    ),
    (ids, offset) => {
      return head(takeRight(ids, Math.abs(offset) + 1)); // 0 offset returns empty array
    }
  ),
  isStackEmpty: flow(
    getReviewStore,
    getFp('hasFetchedAllAvailableItems')
  )
  // session status selector: ready when offset === 0 && hasfetchedallavailable === true
};

const { flushSession } = conceptReviewSlice.actions;
export {
  fetchFirstConcept,
  reviewConcept,
  flushSession,
  selectors,
  initialState
};
export default conceptReviewSlice;
