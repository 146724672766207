import produce from 'immer';

/**
 * @typedef {string} ImageUrl
 */

/**
 * @typedef {Object} ItemPositionInAPI
 * @description Canonical position of item on the canvas received from the API
 * @property {number} x - Canonical X coordinate of item on canvas
 * @property {number} y - Canonical Y coordinate of item on canvas
 * @property {number} [z] - **DEPRECATED** `z-index` of item
 */

/**
 * @typedef {Object} RestOfArgs
 */

/**
 * @typedef ItemWithNormalisedImageUrl
 * @property {Object} image - Data describing image content
 * @property {ImageUrl} image.url - The image asset URL
 */

/**
 * Normalise image data from incoming socket message. Used for ideation images.
 * @param {Object} message - Incoming socket message
 * @param {string} message.url - Image URL
 * @param {Object} message.size - Image width & height data
 * @param {RestOfArgs} message.image - Rest of image props
 * @return {ItemWithNormalisedImageUrl} Normalised image data
 */

export const mapImageDataFromMsg = ({ url, ...image }) => ({
  ...image,
  image: { url }
});

/**
 * Normalise image URL from incoming socket message. Used for participating user avatars.
 * @param {Object} message - Incoming socket message
 * @param {string} message.imageUrl - Image URL
 * @param {RestOfArgs} message.item - Rest of item props
 * @return {ItemWithNormalisedImageUrl} Item with normalised image data
 */

export const mapImageUrlFromMsg = ({ imageUrl, ...item }) => ({
  ...item,
  image: { url: imageUrl }
});

/**
 * @typedef {Object} ItemWithNormalisedUserId
 * @property {string} owner - The user's temporary ID during the ideation session
 */

/**
 * Normalise user's temporary ID from incoming socket message.
 * @param {Object} message - Incoming socket message
 * @param {string} message.roomSpecificUserId - User's temporary ID during the ideation session. Obscures real user ID for the sake of anonimity.
 * @param {RestOfArgs} message.item - Rest of item props
 * @return {ItemWithNormalisedUserId} Item with normalised owner ID
 */

export const mapOwnerIdFromMsg = ({ roomSpecificUserId, ...item }) => ({
  ...item,
  owner: roomSpecificUserId
});

/**
 * @param {Object} rawPhase
 * @returns {IdeationPhaseBehaviour}
 */
export const mapPhaseProps = produce(rawPhase => {
  rawPhase.endsAt = rawPhase.ends_at;
  delete rawPhase.ends_at;
});

/**
 * Normalise an item's position data from incoming socket message.
 * @param {Object} message - Incoming socket message
 * @param {ItemPositionInAPI} message.position - An item's canonical position on the canvas received from the API
 * @param {RestOfArgs} message.item - Rest of item props
 * @return {ItemWithNormalisedPosition} Item with normalised position data
 */
export const mapPositionFromMsg = ({ position, ...item }) => ({
  ...item,
  posX: position.x,
  posY: position.y,
  posZ: position.z
});

/**
 * Convert item position data for API
 * @param {Object} position - Item position data as represented by the UI
 * @param {number} position.posX - X coordinate of item
 * @param {number} position.posY - Y coordinate of item
 * @param {number} [position.posZ] - ***DEPRECATED*** `z-index` of item
 * @return {ItemPositionInAPI} Converted position data
 */
export const mapPositionToMsg = ({ posX, posY, posZ }) => ({
  x: posX,
  y: posY,
  z: posZ
});

/**
 * Normalise user's name from incoming socket message.
 * @param {Object} message - Incoming socket message
 * @param {Object} message.fullName - User's namespaced first- and lastnamae
 * @param {string} message.fullName.firstName
 * @param {string} message.fullName.lastName
 * @param {RestOfArgs} message.item - Rest of item props
 * @return {User} Normalised user object
 */

export const mapUserNameFromMsg = ({ fullName, ...user }) => ({
  ...user,
  ...fullName
});
