import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SanitizedHTML from 'react-sanitized-html';
import linkifyHtml from '../../../utils/linkify';
import scss from './LinkifiedSafeHtml.module.scss';

const ALLOWED_ATTR = { a: ['href', 'rel', 'class', 'target', 'id'] };

LinkifiedSafeHtml.propTypes = {
  html: PropTypes.string
};

function LinkifiedSafeHtml({ html, className, ...rest }) {
  if (typeof html !== 'string') {
    return null;
  }

  return (
    <SanitizedHTML
      html={linkifyHtml(html)}
      allowedAttributes={ALLOWED_ATTR}
      className={cn(scss.Wrapper, className)}
      {...rest}
    />
  );
}

export default memo(LinkifiedSafeHtml);
