import PropTypes from 'prop-types';
import React from 'react';
import { SortDirection } from '../../../constants';

function SortIndicator({ sortDirection, style }) {
  return (
    <svg width={18} height={18} viewBox="0 0 24 24" style={style}>
      {sortDirection === SortDirection.ASC && <path d="M7 12l5-5 5 5z" />}
      {sortDirection === SortDirection.DESC && <path d="M7 8l5 5 5-5z" />}
      {!sortDirection && (
        <g>
          <path d="M7 7l5-5 5 5z" />
          <path d="M7 10l5 5 5-5z" />
        </g>
      )}
    </svg>
  );
}

SortIndicator.propTypes = {
  sortDirection: PropTypes.oneOf([SortDirection.ASC, SortDirection.DESC])
};

export default SortIndicator;
