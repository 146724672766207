import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import isEqualFp from 'lodash/fp/isEqual';
import { AsyncStatus } from '../../constants';

export const hasFailed = isEqualFp(AsyncStatus.Failed);

export const isLoading = getFp('loading');

export const isLoadingNew = isEqualFp(AsyncStatus.Loading);

export const getStoreStatus = getFp('status');

/**
 * @param {Object} itemWithCreator - An entity with a creator
 * @return {User} The user who created the entity
 */

export const getCreator = itemWithCreator => get(itemWithCreator, 'creator');

export const getOwner = idea => get(idea, 'owner');

export const getCreateTime = item => get(item, 'createdAt');

export const getEditTime = item => get(item, 'modifiedAt');

export const getFilterBy = flow(getFp('filterBy'));

export const getLimit = getFp('limit');

export const getNextStart = getFp('nextStart');

export const getStart = getFp('start');

export const getTotal = getFp('total');

export const getVisibleIds = state => get(state, 'visibleIds', []);

export const getPagination = getFp('pagination');

export const getSort = getFp('sort');

export const getCurrentPage = flow(
  getPagination,
  getFp('currentPage')
);

export const getNextPage = flow(
  getPagination,
  getFp('nextPage')
);

export const getPrevPage = flow(
  getPagination,
  getFp('prevPage')
);

export const getLastPage = flow(
  getPagination,
  getFp('lastPage')
);

export const getCurrentCursor = flow(
  getPagination,
  getStart
);

export const getNextCursor = flow(
  getPagination,
  getNextStart
);

export const getPageSize = flow(
  getPagination,
  getLimit
);

export const getSortBy = flow(
  getSort,
  getFp('sortBy')
);

export const getSortDirection = flow(
  getSort,
  getFp('sortDirection')
);
