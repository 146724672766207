import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './SliderInput.css';

SliderInput.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  markers: PropTypes.object,
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
  ariaLabel: PropTypes.string.isRequired,
  vertical: PropTypes.bool
};
SliderInput.defaultProps = {
  step: 1,
  markers: {},
  onChange: () => {},
  tabIndex: 0
};
function SliderInput({
  ariaLabel,
  className,
  disabled,
  error,
  input,
  min,
  max,
  step,
  markers,
  onChange,
  tabIndex,
  value,
  vertical
}) {
  const classes = cn('SliderInput', className, { disabled, vertical, error });
  return (
    <Slider
      vertical={vertical}
      min={min}
      max={max}
      step={step}
      marks={markers}
      value={get(input, 'value', value)}
      onChange={onChange}
      tabIndex={tabIndex}
      ariaLabelForHandle={ariaLabel}
      className={classes}
    />
  );
}

export default SliderInput;
