import React from 'react';
import Panel from '../Panel';
import withModal from '../../containers/withModal';
import LinkifiedSafeHtml from '../LinkifiedSafeHtml';

function ChallengeInfoModal({ title, description }) {
  return (
    <Panel spacing="lg" header={title} bordered>
      <LinkifiedSafeHtml html={description} />
    </Panel>
  );
}

export default withModal()(ChallengeInfoModal);
