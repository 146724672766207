// Config

export const CHALLENGE_DESCRIPTION_MAXLENGTH = 2000;
export const CHALLENGE_IMAGE_ASPECT_RATIO = 1.45;
export const CHALLENGE_IMAGE_CROP_HEIGHT = 240;
export const CHALLENGE_TITLE_MAXLENGTH = 200;
export const EVALUATION_AXIS_LABEL_LENGTH = 40;
export const INVITE_EMAIL_MAXLENGTH = 1000;
export const CHALLENGE_SENSITIVE_MIN_PARTICIPANTS_FOR_LIST = 10;

// Definitions

/**
 * @see ChallengePayloadForSubmission
 */
export const CHALLENGE_FORM_FIELDS = {
  AXIS_X: 'evaluationXAxis',
  AXIS_Y: 'evaluationYAxis',
  CATEGORY: 'categoryId',
  CLOSE_TIME: 'closesAt',
  CONCEPT_EVAL_CRITERIA: 'ideaConceptEvaluationCriteria',
  CRITERION: {
    ID: 'criterionId',
    NAME: 'name'
  },
  CREATOR: 'challengeCreator',
  DEFAULT_CONCEPT_OWNER: 'defaultIdeaConceptUser',
  DESCRIPTION: 'description',
  DURATION_CUSTOM: 'duration',
  DURATION_PRESET: 'range',
  FACILITATED: 'isFacilitated',
  FEATURED: 'highlighted',
  GROUP_IDEATION_TIME: 'groupIdeationDateTime',
  GROUP_IDEATION_TIMEZONE: 'groupIdeationTimeZone',
  HASHTAGS: 'hashtags',
  IDEATION_PHASES: 'ideationPhases',
  IDEATION_ROOM_MAX_SIZE: 'maximumNumberOfParticipantsPerRoom',
  IMAGE: 'image',
  INVITE_EMAIL: 'personalizedEmailBody',
  INVITE_ALL_USERS: 'sendEmailForAllRegisteredUsers',
  INVITED_USERS: 'invitedUsers',
  LANGUAGE: 'language',
  SCENARIO: 'scenario',
  START_PHASE: 'startPhase',
  START_TIME: 'startsAt',
  TITLE: 'title',
  VISIBILITY: 'visibility'
};

/**
 * @enum {string}
 * ISO-8601 duration presets for defining challenge start and close times
 */
export const ChallengeDurations = {
  oneDay: 'P1D',
  twoDays: 'P2D',
  oneWeek: 'P1W',
  twoWeeks: 'P2W'
};

/**
 * @enum {string}
 * @readonly
 */
export const ChallengePhases = {
  /** The challenge starts with sharing ideas. Default setting. */
  IDEATION: 'IDEATION',
  /** Joining users are directed to evaluating ideas */
  EVALUATION: 'EVALUATION'
};

/**
 * Determines how various app behaviour is configured in order to fit the most common usage scenarios.
 * @enum {string}
 * @readonly
 */
export const ChallengeScenario = {
  /** A classic challenge with the original app behaviour. */
  Default: 'DEFAULT',
  /** A sensitive challenge, where ideators and suggestion authors are completely hidden, without an option to reveal them. */
  Sensitive: 'SENSITIVE'
};

/**
 * Determines who will be the initial owner of idea concepts that are created during the challenge. Defaults to the challenge owner.
 * @enum {string}
 * @readonly
 */
export const DefaultConceptOwner = {
  /** The user who created the challenge will be the owner of all resulting idea concepts */
  ChallengeOwner: 'CHALLENGE_OWNER',
  /** Each concept ownership will be attributed to the user who wrote the original idea that later became the idea concept */
  IdeaCreator: 'IDEA_CREATOR'
};

/**
 * @enum {string}
 * @readonly
 */
export const ChallengeStatus = {
  /** The challenge is open. Ideas can be shared, evaluated and commented. */
  OPEN: 'OPEN',
  /** The challenge is closed. Content can no longer be added, but results are visible. */
  CLOSED: 'CLOSED',
  /** The challenge has not started yet. Content cannot be added yet, but the challenge is already visible. */
  ANNOUNCED: 'ANNOUNCED',
  /** The challenge has been deleted, and is no longer visible to users */
  DELETED: 'DELETED'
};

/**
 * Describes which users or groups can see and join a challenge.
 * @enum {string}
 * @readonly
 */
export const ChallengeVisiblity = {
  /** Only the challenge creator, the invited users and admins can see and join the challenge */
  PRIVATE: 'PRIVATE',
  /** All platform users can see and join the challenge */
  PUBLIC: 'COMPANY_WIDE'
};

/**
 * @enum {string} Properties for sorting ideas in the challenge idea list
 * @readonly
 */

export const ChallengeIdeasSortBy = {
  /** Sort by evaluation score weighted with number of likes */
  CombinedScore: 'COMBINED_SCORE',
  /** Sort by evaluation score */
  Score: 'SCORE',
  /** Sort by number of likes */
  Popularity: 'POPULARITY',
  /** Sort by X-axis score */
  ValueX: 'X_AXIS_VALUE',
  /** Sort by Y-axis score */
  ValueY: 'Y_AXIS_VALUE',
  /** Sort by creation time */
  CreatedAt: 'CREATED_AT'
};

// modals

export const DELETE_CHALLENGE_MODAL = 'DELETE_CHALLENGE_MODAL';
export const EDIT_CHALLENGE = 'EDIT_CHALLENGE';
