import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Notice.css';

Notice.propTypes = {
  rounded: PropTypes.bool,
  variant: PropTypes.string
};
Notice.defaultProps = {
  rounded: true,
  variant: 'notice'
};
export default function Notice({
  children,
  className,
  rounded,
  style,
  variant
}) {
  const classes = classNames(`NoticePanel--${variant}`, className, {
    'NoticePanel--isRounded': rounded
  });

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
}
