import snakeCase from 'lodash/snakeCase';
import toUpper from 'lodash/toUpper';
import * as Sentry from '@sentry/browser';
import { Severity } from './constants';
import { ErrorLoggerSeverity } from './errorLogging/constants';
import trackTrace from './monitoring/trackTrace';
import { MonitoringSeverity } from './monitoring/constants';

const DEFAULTS = {
  level: Severity.Info,
  data: undefined,
  category: undefined
};

/**
 * @typedef {Object} BreadcrumbConfig
 * @property {Severity} [level] - Log level. Defaults to Info.
 * @property {import('./types/jsdoc').BreadcrumbCategory} [category] - Feature or domain, e.g. `"challenge", "bootstrap"`. In Sentry, this is also set as the custom `feature` tag for the error report.
 * @property {Object} [data] - Optional log data object
 */

/**
 * Submits a breadcrumb to telemetry providers
 * @param {string} message - Message to be logged in telemetry services
 * @param {BreadcrumbConfig} options - Optional configuration object
 * @return {void}
 */

export default function addBreadcrumb(message, options = DEFAULTS) {
  const { data, level, category } = {
    ...DEFAULTS,
    ...options
  };
  trackTrace(
    `[${toUpper(category)}] ${message}`,
    data,
    MonitoringSeverity[level]
  );
  if (message instanceof Error) {
    Sentry.captureException(message, {
      tags: { feature: snakeCase(category) }
    });
  } else {
    Sentry.addBreadcrumb({
      message,
      level: ErrorLoggerSeverity[level],
      data,
      category
    });
  }
}

export { Severity };
