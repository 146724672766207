import noop from 'lodash/noop';
import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import moment from 'moment';

const formatters = {
  fromNow: (value, lng) =>
    moment(value)
      .locale(lng)
      .fromNow(),
  toNow: (value, lng) =>
    moment(value)
      .locale(lng)
      .toNow(),
  humanize: (value, lng) =>
    moment
      .duration(value)
      .locale(lng)
      .humanize(),
  percentage: value => `${value}%`
};

export default function configureI18n(onReady = noop) {
  const isDevelop = process.env.NODE_ENV === 'development';

  i18n.use(XHR).init({
    initImmediate: false,
    fallbackLng: false,
    debug: false,
    // allow keys to be phrases having `:`, `.` https://github.com/i18next/react-i18next/issues/387
    nsSeparator: false,

    keySeparator: '/',
    returnEmptyString: false,
    parseMissingKeyHandler: isDevelop ? key => `@@${key}@@` : () => null,

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format(value, format, lng = 'en') {
        const formatter = formatters[format];
        if (formatter) {
          return formatter(value, lng);
        }
        return value;
      }
    },

    backend: {
      loadPath: '{{lng}}',
      parse: data => data,
      ajax: loadLocales
    }
  });

  // load active language
  onReady();

  return i18n;
}

function loadLocales(lang, options, callback, data) {
  try {
    switch (lang) {
      case 'en':
        import('./locales/en').then(module => {
          const translations = module.default;
          moment.locale('en');
          callback(translations, { status: '200' });
        });
        return;
      case 'hu':
        import('./locales/hu').then(module => {
          const translations = module.default;
          moment.locale('hu');
          callback(translations, { status: '200' });
        });
        return;
      case 'de':
        import('./locales/de').then(module => {
          const translations = module.default;
          moment.locale('de');
          callback(translations, { status: '200' });
        });
        return;
      default:
        callback(null, { status: '404' });
    }
  } catch (e) {
    callback(null, { status: '404' });
  }
}
