import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import Button from '@bit/be-novative.kit.button';
import Icon from '../Icon';
import Submenu from './Submenu';

class MenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(event) {
    event.preventDefault();
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    const {
      submenu = [],
      children,
      icon,
      href,
      to,
      onClick,
      highlighted = false
    } = this.props;

    if (!submenu.length) {
      return (
        <li
          className={classNames({
            menu__item: true,
            'menu__item--highlighted': highlighted
          })}>
          <Button
            href={href}
            target={href ? '_blank' : null}
            to={to}
            onClick={onClick}
            variant="link"
            className="menu__link">
            <Icon className="menu__icon" type={icon} />
            <span className="menu__text">{children}</span>
          </Button>
        </li>
      );
    }

    return (
      <li className="menu__item">
        <button className="menu__link" onClick={this.toggle}>
          <Icon className="menu__icon" type={icon} />
          <span className="menu__text">{children}</span>
          <Icon
            className="menu__icon"
            type={this.state.collapsed ? 'angle-down' : 'angle-up'}
          />
        </button>
        <Submenu collapsed={this.state.collapsed}>{submenu}</Submenu>
      </li>
    );
  }
}

MenuItem.propTypes = {
  submenu: PropTypes.node,
  icon: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
  highlighted: PropTypes.bool
};

export default MenuItem;
