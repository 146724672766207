import without from 'lodash/without';
import uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import {
  DELETE_CONCEPT_POST,
  FETCH_CONCEPT_POSTS,
  FLUSH_CONCEPT_POST_LIST,
  STORE_CONCEPT_POST_IDS
} from '../actions/conceptPosts';
import errorReducerFactory from '../../common/reducers/factories/error';
import loadingReducerFactory from '../../common/reducers/factories/loading';

const visibleIds = handleActions(
  {
    [STORE_CONCEPT_POST_IDS]: (state, action) => {
      return uniq([...state, ...action.payload]);
    },
    [DELETE_CONCEPT_POST.SUCCESS]: (state, action) => {
      return without(state, action.meta.postId);
    },
    [FLUSH_CONCEPT_POST_LIST]: () => []
  },
  []
);

const loading = loadingReducerFactory(FETCH_CONCEPT_POSTS);

const error = errorReducerFactory(FETCH_CONCEPT_POSTS);

export default combineReducers({
  visibleIds,
  loading,
  error
});
