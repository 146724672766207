import React from 'react';
import cn from 'classnames';
import './NonIdealState.css';

function NonIdealState({
  title,
  description,
  action,
  visual,
  theme,
  className,
  style
}) {
  return (
    <div className={cn(theme.container, className)} style={style}>
      <div className={theme.visual}>{visual}</div>
      <h3>{title}</h3>
      <p>{description}</p>
      <div>{action}</div>
    </div>
  );
}

NonIdealState.defaultProps = {
  theme: {
    container: 'NonIdealState',
    visual: 'NonIdealState__Visual'
  }
};

export default NonIdealState;
