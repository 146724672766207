import includes from 'lodash/includes';
import { SUPPORTED_UI_LANGUAGES } from '../constants';

export function detectLanguage() {
  try {
    return navigator.language;
  } catch (e) {
    console.error('Could not detect user agent language');
  }
}

export const getLanguageShortCode = langCode =>
  langCode && langCode.toString().slice(0, 2);

export function determineUiLanguage(
  preferred,
  browser,
  fallback = SUPPORTED_UI_LANGUAGES[0]
) {
  if (includes(SUPPORTED_UI_LANGUAGES, preferred)) {
    return preferred;
  } else if (includes(SUPPORTED_UI_LANGUAGES, browser)) {
    return browser;
  } else {
    return fallback;
  }
}
