import { SOCKET_MSG as MSG, S_ID } from '../../constants';
import { sendSocketMessage } from '../../../common/actions/socket';
import { mapPositionFromMsg } from './_mappers';

// Requests

export const moveWordMsg = (wordId, posX, posY, posZ) => {
  const payload = [wordId, posX, posY, posZ];
  return sendSocketMessage(S_ID, MSG.WORD_MOVE_REQ, payload);
};

// Responses

export const normaliseWordReceivedMsg = (msg = []) =>
  msg.map(({ wordId, word, position }) => ({
    wordId,
    text: word,
    ...mapPositionFromMsg({ position })
  }));
