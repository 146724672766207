import get from 'lodash/get';
import identity from 'lodash/identity';
import isArray from 'lodash/isArray';
import { convertApiErrorToStatusCode } from '../api/utils/apiError';
import { CommonException } from '../api/constants';
import addBreadcrumb, { Severity } from '../telemetry/addBreadcrumb';

/**
 * @typedef {Function} ApiCaller
 * @param {*} arg - The (optionally processed) argument or map of arguments that will be passed to the called API endpoint
 * @return {Promise} The promise of the pending API call
 */

/**
 * @typedef {Function} ApiArgumentPreprocessor
 * @param {*} arg - A single argument, or the map of multiple arguments
 * @param {Object} state - The current Redux state
 * @return {*} The processed argument to be passed to the API caller
 */

/**
 * @typedef {Function} CurriedAsyncThunkHandler
 * @param {*} arg - A single argument, or the map of multiple arguments
 * @param {Object} thunkApi - The thunk API supplied by Redux Toolkit
 * @return {Promise<ProcessedApiCallResult>} The promise of the API call result or the processed error
 * @see https://redux-toolkit.js.org/api/createAsyncThunk
 */

/**
 * @typedef {Object} ApiCallLogData
 * @property {BreadcrumbCategory} domain - The general feature triggering the API call
 * @property {string} [message] - The log message. Defaults to `'Calling API...'`
 */

/**
 * Perform the common logic of handling API calls in async thunks generated by Redux Toolkit
 * @summary Generates the `payloadCreator` for `createAsyncThunk` that handles pre-processing arguments; calling the API endpoint; returning the result, or handling the API error
 * @param {ApiCaller} apiCaller - The function that performs the API call. It must return a Promise.
 * @param {ApiArgumentPreprocessor} [preprocessor] - An optional function for transforming the arguments before calling the API. It is called with: the arguments, the current Redux state.
 * @param {ApiCallLogData} [logData] - Optional log data including the general feature domain, and a custom log message
 * @return {CurriedAsyncThunkHandler} The generated payload creator function
 */

export default function handleApiCallAction(
  apiCaller,
  preprocessor = identity,
  logData
) {
  return async function asyncThunkHandler(arg, thunkApi) {
    try {
      const processedArg = preprocessor(arg, thunkApi.getState());

      addBreadcrumb(get(logData, 'message', 'Calling API...'), {
        category: get(logData, 'domain', 'redux-action'),
        data: processedArg,
        level: Severity.Info
      });

      if (isArray(processedArg)) {
        return await apiCaller.apply(this, processedArg);
      } else {
        return await apiCaller(processedArg);
      }
    } catch (error) {
      const code = convertApiErrorToStatusCode(error);

      if (code === CommonException.General) {
        addBreadcrumb(error, {
          level: Severity.Error
        });
      }

      return thunkApi.rejectWithValue({ code, error });
    }
  };
}
