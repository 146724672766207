import React, { useCallback } from 'react';
import { translate } from 'react-i18next';
import { PrimaryNavigation } from '../../../common/components';
import TabButton from '../../../common/components/TabButton';
import useActiveImagePicker from '../../../common/containers/ImageSearchModal/useActiveImagePicker';
import { ImageTypes } from '../../../constants';

const types = [ImageTypes.UPLOADED, ImageTypes.PRESET, ImageTypes.GOOGLE];

function PickerWithTabNav({ onSelect, t }) {
  const tabs = useActiveImagePicker(ImageTypes.UPLOADED);
  const activeType = tabs.getType();
  const Picker = tabs.getComponent();
  const handleSelect = useCallback(
    image => {
      image['@type'] = activeType;
      onSelect(image, activeType);
    },
    [onSelect, activeType]
  );
  return (
    <>
      <PrimaryNavigation>
        {types.map(tabId => (
          <TabButton
            key={tabId}
            ariaId={tabId}
            active={activeType === tabId}
            onClick={() => tabs.setType(tabId)}>
            {t(`common/imagePicker/${tabId}/navButton`)}
          </TabButton>
        ))}
      </PrimaryNavigation>
      <Picker onSelect={handleSelect} />
    </>
  );
}

export default translate()(PickerWithTabNav);
