import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import map from 'lodash/map';
import size from 'lodash/size';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';
import { API_QUERY_STRING_MINLENGTH } from '../../api/constants';
import { AsyncStatus } from '../../constants';
import usersApi from '../api/usersApi';
import handleApiCallAction from '../../utils/handleApiCallAction';
import { getEntities_new } from '../selectors/entities';
import { getStoreStatus } from '../selectors/meta';
import { getUserId } from '../getters/users';

const searchUsers = createAsyncThunk(
  'searchUsers',
  handleApiCallAction(usersApi.queryUsers),
  {
    condition: query => API_QUERY_STRING_MINLENGTH <= size(query)
  }
);

const usersAdapter = createEntityAdapter({
  selectId: getUserId
});

const initialState = {
  entities: usersAdapter.getInitialState(),
  searchHits: [],
  status: AsyncStatus.Idle,
  error: null
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    flushUserHits(state) {
      state.searchHits = [];
    }
  },
  extraReducers: {
    [searchUsers.pending]: state => {
      state.status = AsyncStatus.Loading;
      state.searchHits = [];
      state.error = null;
    },
    [searchUsers.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.entities = usersAdapter.setAll(state.entities, payload);
        state.searchHits = map(payload);
        state.status = AsyncStatus.Succeeded;
      }
    },
    [searchUsers.rejected]: (state, action) => {
      state.status = AsyncStatus.Failed;
      state.searchHits = [];
      state.error = action.error;
    }
  }
});

const { flushUserHits } = usersSlice.actions;

const usersStoreSelector = getFp('users');

const selectors = {
  ...usersAdapter.getSelectors(
    flow(
      usersStoreSelector,
      getEntities_new
    )
  ),
  getSearchHits: flow(
    usersStoreSelector,
    getFp('searchHits')
  ),
  getStatus: flow(
    usersStoreSelector,
    getStoreStatus
  )
};

export { searchUsers, flushUserHits, selectors, initialState };

export default usersSlice;
