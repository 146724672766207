import React, { memo, useCallback } from 'react';
import isNumber from 'lodash/isNumber';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { translate } from 'react-i18next';
import { Size } from '../../../constants';
import { EMPTY_NUMBER } from '../../constants';
import { conceptSubpage } from '../../paths';
import { openTeamEditorModal } from '../../actions';
import { getCategoryName } from '../../../common/getters/categories';
import { getTitle as getChallengeTitle } from '../../../common/selectors/challenges';
import { getCreateTime } from '../../../common/selectors/meta';
import {
  getChallengeId,
  getIdeaText,
  getOwner,
  getCompositeEvalScore,
  getTitle as getConceptTitle,
  getCategory,
  getEvalScoreArray
} from '../../selectors/ideaConcepts';
import useCategory from '../../../common/hooks/useCategory';
import useChallenge from '../../../challenge/hooks/useChallenge';
import useEditConcept from '../../hooks/useEditConcept';
import useIdeaConcept from '../../hooks/useIdeaConcept';
import Button from '@bit/be-novative.kit.button';
import DateTime from '../../../common/components/DateTime';
import Heading from '../../../common/components/Heading';
import Icon from '../../../common/components/Icon';
import LinkifiedSafeHtml from '../../../common/components/LinkifiedSafeHtml';
import ConceptOwnerAvatar from '../../components/ConceptOwnerAvatar';
import ConceptTeamAvatars from '../../components/ConceptTeamAvatars';
import StageSelector from '../../components/StageSelector';
import ReviewScoreDetails from '../../components/ReviewScoreDetails';
import {
  AddMemberBtn,
  Attrib,
  ChallengeTitle,
  CloseWrapper,
  ConceptTitle,
  Description,
  OwnerAvatar,
  OwnerWrapper,
  Team,
  Toolbar,
  Wrapper
} from './ConceptPreview.styled';

const CATEG_MAX_LENGTH = 25;
const DESC_MAX_LENGTH = 500;

ConceptPreview.propTypes = {
  ideaConceptId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};
function ConceptPreview({ ideaConceptId, onClose, style, t }) {
  const [concept] = useIdeaConcept(ideaConceptId);
  const categoryId = getCategory(concept);
  const createdAt = getCreateTime(concept);
  const description = getIdeaText(concept);
  const ownerId = getOwner(concept);
  const reviewScore = getCompositeEvalScore(concept);
  const detailedScores = getEvalScoreArray(concept);
  const title = getConceptTitle(concept);
  const challengeId = getChallengeId(concept);
  const [challenge] = useChallenge(challengeId);
  const challengeTitle = getChallengeTitle(challenge);

  const dispatch = useDispatch();
  const editConcept = useEditConcept(ideaConceptId);
  const onTeamClick = useCallback(
    () =>
      dispatch(
        openTeamEditorModal(ideaConceptId, {
          onSubmit: formData => editConcept(formData)
        })
      ),
    [dispatch, editConcept, ideaConceptId]
  );

  const category = useCategory(categoryId);

  return (
    <Wrapper style={style}>
      <Toolbar>
        <Attrib>
          <dt>{t('common/prop/category/label')}:</dt>
          <dd title={getCategoryName(category)} data-test="categ">
            {getCategoryName(category, CATEG_MAX_LENGTH)}
          </dd>
        </Attrib>
        <Attrib>
          <dt>{t('ideaConcept/prop/decision')}:</dt>
          <dd>
            <StageSelector ideaConceptId={ideaConceptId} />
          </dd>
        </Attrib>
        <Attrib>
          <dt>{t('ideaConcept/prop/reviewScore', { context: 'short' })}:</dt>
          <dd data-test="score">
            {isNumber(reviewScore) ? reviewScore.toFixed(1) : EMPTY_NUMBER}
          </dd>
        </Attrib>
        <CloseWrapper>
          <Button
            variant="minimal"
            onClick={onClose}
            title={t('common/userAction/close')}
            aria-label={t('common/userAction/close')}
            data-test="closeBtn">
            <Icon type="cross" size="lg" />
          </Button>
        </CloseWrapper>
      </Toolbar>

      <ConceptTitle as={Heading} level={3}>
        {title}
      </ConceptTitle>
      <Attrib small>
        <dt>{t('ideaConcept/prop/createdAt')}:</dt>
        <dd>
          <DateTime>{createdAt}</DateTime>
        </dd>
      </Attrib>

      {challengeTitle && (
        <ChallengeTitle title={challengeTitle}>
          <dt>{t('ideaConcept/prop/parentChallenge')}:</dt>
          <dd data-test="chalTitle">{challengeTitle}</dd>
        </ChallengeTitle>
      )}

      <Team>
        <OwnerWrapper>
          <Heading level={5}>
            <strong>{t('ideaConcept/prop/team/label')}</strong>
          </Heading>
          <OwnerAvatar
            as={ConceptOwnerAvatar}
            size="xs"
            userId={ownerId}
            wrapperClassName="OwnerAvatar"
          />
          <Heading level={6} style={{ transform: 'translateY(.3em)' }}>
            {t('ideaConcept/prop/owner')}
          </Heading>
        </OwnerWrapper>
        <ConceptTeamAvatars
          ideaConceptId={ideaConceptId}
          avatarSize={Size.xs}
          avatarClassName="TeamMember"
        />
        <AddMemberBtn
          as={Button}
          variant="minimal"
          onClick={onTeamClick}
          title="Manage team">
          <Icon type="plus" />
        </AddMemberBtn>
      </Team>

      <Description>
        <LinkifiedSafeHtml
          html={truncate(description, { length: DESC_MAX_LENGTH })}
        />
      </Description>

      <nav style={{ textAlign: 'right' }}>
        <Button
          variant="success"
          to={conceptSubpage(ideaConceptId)}
          onClick={onClose}
          size="large"
          data-test="navBtn">
          {t('common/navigation/details')}
        </Button>
      </nav>

      <div style={{ padding: '1em 0' }} data-test="tab">
        <ReviewScoreDetails scores={detailedScores} />
      </div>
    </Wrapper>
  );
}

export default compose(
  translate(),
  memo
)(ConceptPreview);
