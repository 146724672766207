import React from 'react';
import { Sticky } from 'react-sticky';

function defaultHeaderRowRenderer({ className, columns, width, dimensions }) {
  return (
    <Sticky>
      {params => {
        const { style, isSticky, calculatedHeight } = params;

        if (isSticky) {
          return (
            <div
              style={{
                position: 'fixed',
                overflow: 'hidden',
                width: dimensions.width,
                left: dimensions.left,
                top: style.top,
                height: calculatedHeight,
                zIndex: 2
              }}>
              <div
                role="row"
                className={className}
                style={{
                  position: 'absolute',
                  left: style.left - dimensions.left,
                  top: 0,
                  width
                }}>
                {columns}
              </div>
            </div>
          );
        }

        return (
          <div
            role="row"
            className={className}
            style={{
              ...style,
              width
            }}>
            {columns}
          </div>
        );
      }}
    </Sticky>
  );
}

export default defaultHeaderRowRenderer;
