import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import isEqual from 'lodash/fp/isEqual';
import filteredReducerCall from '../../utils/filteredReducerCall';
import { FLUSH_CONCEPT_IDS } from '../constants';

const actionMatchesStage = (action, stageId) =>
  flow(
    getFp(['meta', 'activeTab']),
    isEqual(stageId)
  )(action);

const isFlushAction = flow(
  getFp('type'),
  isEqual(FLUSH_CONCEPT_IDS)
);

export default function reduceStageFiltersToReducers(stageIds = [], reducerFn) {
  return stageIds.reduce((reducerMap, stageId) => {
    reducerMap[stageId] = filteredReducerCall(
      action => actionMatchesStage(action, stageId) || isFlushAction(action),
      reducerFn
    );
    return reducerMap;
  }, {});
}
