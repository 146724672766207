/**
 * Wraps a Redux reducer function so it's only called when the action satisfies some condition.
 * @param {(action: import('redux').Action) => boolean} evalFn - A predicate function that evaluates the current action and returns whether the reducer should be called with it.
 * @param {Function<import('redux').Store>} reducerFn - A reducer function
 * @returns {Function} The reducer function wrapped with the filter logic
 */
export default function filteredReducerCall(evalFn, reducerFn) {
  const defaultState = reducerFn(undefined, {});
  return (state = defaultState, action) =>
    evalFn(action) ? reducerFn(state, action) : state;
}
