import React from 'react';
import flow from 'lodash/flow';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import ContentLanguageForm from '../ContentLanguageForm';
import InterfaceLanguageForm from '../InterfaceLanguageForm';
import { Grid } from '../../../common/components';
import { getCurrentLanguage } from '../../../common/selectors/language';
import { SUPPORTED_UI_LANGUAGES } from '../../../constants';
import { platformDataSelector } from '../../../common/state/platform';
import { getContentLanguages } from '../../../common/getters/platform';

function LanguageSettings({ t, contentLanguages, activeInterfaceLanguage }) {
  return (
    <Grid.Row gutter={0}>
      <Grid.Col xsGutterX={2}>
        <h1>{t('profile/Language Settings')}</h1>
        <ContentLanguageForm languages={contentLanguages} />
        <InterfaceLanguageForm
          languages={SUPPORTED_UI_LANGUAGES}
          currentLanguage={activeInterfaceLanguage}
        />
      </Grid.Col>
    </Grid.Row>
  );
}

function mapStateToProps(state) {
  return {
    contentLanguages: flow(
      platformDataSelector,
      getContentLanguages
    )(state),
    activeInterfaceLanguage: getCurrentLanguage(state)
  };
}

export default compose(
  translate(),
  connect(mapStateToProps)
)(LanguageSettings);
