/**
 * @enum {string} AuthException
 * @readonly
 */
export const AuthException = {
  AlreadyHasAccount: 'USER_IS_ALREADY_REGISTERED',
  PlatformNotFound: 'COMPANY_NOT_FOUND',
  EmailNotAllowed: 'EMAIL_ADDRESS_IS_NOT_ALLOWED',
  InvalidInvitation: 'INVALID_INVITATION_CODE',
  InvalidLogin: 'INVALID_LOGIN',
  SignupForInvited: 'REGISTRATION_IS_ONLY_ALLOWED_FOR_INVITED_PEOPLE'
};

export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

export const SOCIAL_EXCEPTIONS = {
  NOT_READY: 'NOT_READY',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
  UNKNOWN_ERROR,
  UNKNOWN_PROVIDER: 'UNKNOWN_PROVIDER'
};

/**
 * @enum {string} SSOProvider
 * @readonly
 */
export const SSO_Provider = {
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  LINKEDIN: 'LINKEDIN'
};

/**
 * @enum {string} SignupFormFields
 * @readonly
 * @see {UserSignupPayload}
 */
export const SignupFormFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  password: 'password',
  countryCode: 'countryCode',
  userConsent: 'userConsent'
};
