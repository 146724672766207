import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ImageGallery from './ImageGallery';
import useGoogleImageSearch from './useGoogleImageSearch';
import useImageFetch from './useImageFetch';
import scss from './ImagePicker.module.scss';

GoogleImageSearch.propTypes = {
  onSelect: PropTypes.func.isRequired
};
function GoogleImageSearch({ onSelect, t }) {
  const googleSearch = useGoogleImageSearch();
  const [searchTerm, setSearchTerm] = useState('');
  const resource = useMemo(
    () => ({
      fetch: pageIx => googleSearch(searchTerm, pageIx)
    }),
    [googleSearch, searchTerm]
  );
  const searchApi = useImageFetch(resource);
  const handleSubmit = useCallback(
    event => {
      if (event.keyCode === 13) {
        event.preventDefault(); // Prevent parent form submit when hitting enter in search field
        searchApi.reset();
        searchApi.fetch(searchApi.pageIx);
      }
    },
    [searchApi]
  );

  const handleNextPage = useCallback(() => {
    searchApi.fetch(searchApi.pageIx);
  }, [searchApi]);

  return (
    <section>
      <header>
        <h2 className={'h-text-center'}>
          {t('common/imagePicker/external/title')}
        </h2>
        <input
          type="text"
          className={scss.SearchField}
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
          onKeyDown={handleSubmit}
          placeholder={t('common/imagePicker/external/placeholder')}
        />
        <p>
          <em>
            <small>{t('common/imagePicker/external/help')}</small>
          </em>
        </p>
      </header>
      <ImageGallery
        images={searchApi.images}
        hasError={searchApi.hasError}
        isLoading={searchApi.isLoading}
        onImageSelect={onSelect}
        onNextPage={handleNextPage}
      />
    </section>
  );
}

export default translate()(GoogleImageSearch);
