import invariant from 'invariant';
import { objectToQueryString } from '../../../utils';

export function facebook(url, { title, description, picture, hashtag }) {
  invariant(url, 'facebook.url');

  return (
    'https://www.facebook.com/sharer/sharer.php' +
    objectToQueryString({
      u: url,
      title,
      description,
      picture,
      hashtag
    })
  );
}

export function twitter(url, { title, via, hashtags = [] }) {
  invariant(url, 'twitter.url');
  invariant(Array.isArray(hashtags), 'twitter.hashtags is not an array');

  return (
    'https://twitter.com/share' +
    objectToQueryString({
      url,
      text: title,
      via: via || 'benovative',
      hashtags: hashtags.join(',')
    })
  );
}

export function linkedin(url, { title, description }) {
  invariant(url, 'linkedin.url');

  return (
    'https://linkedin.com/shareArticle' +
    objectToQueryString({
      url,
      title,
      summary: description
    })
  );
}
