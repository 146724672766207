import noop from 'lodash/noop';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import throttle from 'redux-throttle';
import { routerMiddleware } from 'connected-react-router';
import { APP_INIT } from './constants';
import {
  SOCKET_ID,
  VOYAGER_API,
  IDENTITY_API,
  SEARCH_API,
  SOCKET_PATH
} from './api/constants';
import history from './history';
import createReducer from './reducers';
import {
  createAuthGuard,
  apiMiddleware,
  localeMiddleware,
  errorLoggerMiddleware
} from './middleware';
import { metricsMiddleware } from './metrics';
import socketMiddlewareFactory from './middleware/socketMiddlewareFactory';
import signalrSocket from './api/socketAdapters/signalrSocket';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: false,
        shouldCatchErrors: false
      })
    : compose;

const DEFAULTS = { defaultStore: {}, urlGetter: noop };
export default function configureStore(options = DEFAULTS) {
  const middlewares = [thunk];
  const { urlGetter, defaultStore } = { ...DEFAULTS, ...options };

  if (process.env.NODE_ENV !== 'test') {
    middlewares.push(createAuthGuard());
    middlewares.push(createDebounce());
    middlewares.push(throttle());
  }

  middlewares.push(
    apiMiddleware({
      id: VOYAGER_API,
      baseURL: urlGetter(VOYAGER_API)
    })
  );
  middlewares.push(
    apiMiddleware({
      id: IDENTITY_API,
      baseURL: urlGetter(IDENTITY_API)
    })
  );
  middlewares.push(
    apiMiddleware({
      id: SEARCH_API,
      baseURL: urlGetter(SEARCH_API)
    })
  );
  middlewares.push(
    socketMiddlewareFactory({
      socketId: SOCKET_ID.IDEATION,
      url: [urlGetter('API'), SOCKET_PATH.IDEATION].join('/'),
      socketHandler: signalrSocket
    })
  );
  middlewares.push(
    socketMiddlewareFactory({
      socketId: SOCKET_ID.CONCEPT_CANVAS,
      url: [urlGetter('API'), SOCKET_PATH.CONCEPT_CANVAS].join('/'),
      socketHandler: signalrSocket
    })
  );
  middlewares.push(routerMiddleware(history));
  middlewares.push(localeMiddleware);

  if (process.env.NODE_ENV !== 'test') {
    middlewares.push(errorLoggerMiddleware);
    middlewares.push(metricsMiddleware);
  }

  if (process.env.NODE_ENV === `development`) {
    const { createLogger } = require(`redux-logger`);
    const loggerMiddleware = createLogger({
      level: 'info',
      collapsed: true
    });
    middlewares.push(loggerMiddleware);
  }

  let store = createStore(
    createReducer(),
    defaultStore,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  store.asyncReducers = {};

  store.dispatch({ type: APP_INIT });

  return store;
}

export function injectAsyncReducer(store, name, asyncReducer) {
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  store.dispatch({
    type: 'INIT'
  });
}

export function observeStore(store, select, onChange) {
  let currentState;

  function handleChange() {
    let nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}
