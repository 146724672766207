/* eslint-disable no-unused-vars */
import Cookie from 'js-cookie';
import { CONSENTCOOKIE_ID, CONSENTCOOKIE_STATES } from '../constants';

const { ALLOW, DENY, DISMISS } = CONSENTCOOKIE_STATES;

export const getConsentCookie = () => Cookie.get(CONSENTCOOKIE_ID);
export const isCookieConsentAccepted = () => {
  return process.env.NODE_ENV !== 'test';
  // const status = getConsentCookie();
  // return status === ALLOW || status === DISMISS;
};
export const isCookieConsentDenied = () => getConsentCookie() === DENY;
export const isCookieConsentVisible = () =>
  !isCookieConsentAccepted() && !isCookieConsentDenied();
export const resetCookieConsent = () => {
  Cookie.remove(CONSENTCOOKIE_ID);
};
