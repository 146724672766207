import paths from '../paths';
import { conceptSubpage } from '../ideaConcept/paths';
import { ChallengeIdeasSortBy } from '../challenge/constants';

export const NotificationType = {
  CHALLENGE_STARTED: {
    name: 'CHALLENGE_STARTED',
    iconType: 'rocket',
    color: 'warning'
  },
  CHALLENGE_ENDED: {
    name: 'CHALLENGE_ENDED',
    iconType: 'clock',
    color: 'info'
  },
  CHALLENGE_INVITATION: {
    name: 'CHALLENGE_INVITATION',
    iconType: 'airplane',
    color: 'info'
  },
  GROUP_IDEATION_TIME_CHANGED: {
    name: 'GROUP_IDEATION_TIME_CHANGED',
    iconType: 'clock',
    color: 'info'
  },
  CHALLENGE_LOVED: {
    name: 'CHALLENGE_LOVED',
    iconType: 'heart',
    color: 'danger'
  },
  IDEA_LOVED: {
    name: 'IDEA_LOVED',
    iconType: 'heart',
    color: 'danger'
  },
  REVEAL_IDEA: {
    name: 'REVEAL_IDEA',
    iconType: 'anonymous',
    color: 'info'
  },
  NEW_IDEA: {
    name: 'NEW_IDEA',
    iconType: 'bubble',
    color: 'success'
  },
  IDEA_CONCEPT_INVITATION: {
    name: 'IDEA_CONCEPT_INVITATION',
    iconType: 'teams',
    color: 'success'
  }
};

export const NotificationGroups = {
  CHALLENGES: [
    NotificationType.CHALLENGE_STARTED.name,
    NotificationType.CHALLENGE_ENDED.name,
    NotificationType.CHALLENGE_INVITATION.name,
    NotificationType.GROUP_IDEATION_TIME_CHANGED.name,
    NotificationType.CHALLENGE_LOVED.name
  ],
  IDEAS: [
    NotificationType.IDEA_LOVED.name,
    NotificationType.REVEAL_IDEA.name,
    NotificationType.NEW_IDEA.name
  ],
  IDEA_CONCEPTS: [NotificationType.IDEA_CONCEPT_INVITATION.name]
};

export const NotificationAction = {
  [NotificationType.CHALLENGE_STARTED.name](notification) {
    return paths.results(notification.challengeId);
  },
  [NotificationType.CHALLENGE_ENDED.name](notification) {
    return paths.results(notification.challengeId);
  },
  [NotificationType.CHALLENGE_INVITATION.name](notification) {
    return paths.feedDetails(notification.challengeId);
  },
  [NotificationType.GROUP_IDEATION_TIME_CHANGED.name](notification) {
    return paths.feedDetails(notification.challengeId);
  },
  [NotificationType.CHALLENGE_LOVED.name](notification) {
    return paths.results(notification.challengeId);
  },
  [NotificationType.IDEA_LOVED.name](notification) {
    return paths.results(
      notification.challengeId,
      {
        owned: true,
        sortBy: ChallengeIdeasSortBy.Popularity
      },
      'IdeaList'
    );
  },
  [NotificationType.REVEAL_IDEA.name](notification) {
    return paths.reveal(notification.challengeId);
  },
  [NotificationType.NEW_IDEA.name](notification) {
    return paths.ideation(notification.challengeId);
  },
  [NotificationType.IDEA_CONCEPT_INVITATION.name](notification) {
    return conceptSubpage(notification.ideaConceptId);
  }
};
