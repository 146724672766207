import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';

export const isOwn = get('own');

export const getPosX = getOr(0, 'posX');

export const getPosY = getOr(0, 'posY');

export const getZIndex = getOr(1, 'posZ');
