import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import { Size } from '../../../constants';
import localiseUserName from '../../../utils/localiseUserName';
import { getUserEntity } from '../../selectors/users';
import { getUserAvatar } from '../../getters/users';
import Avatar from '../Avatar';
import Media from '../Media';

UserItem.propTypes = {
  userId: PropTypes.string.isRequired
};
export function UserItem({ t, userId }) {
  const user = useSelector(getUserEntity(userId));
  const avatar = getUserAvatar(user);
  const name = localiseUserName(t, user);

  return (
    <Media
      mediaObject={
        <Avatar
          name={name}
          image={avatar}
          size={Size.sm}
          tooltipPosition="right"
        />
      }
      bodyAlign="middle">
      {name}
    </Media>
  );
}

export default translate()(UserItem);
