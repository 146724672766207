import React, { Suspense } from 'react';
import Spinner from '@bit/be-novative.kit.spinner';

function LazyComponent({ children, fallback }) {
  return (
    <Suspense fallback={fallback || <Spinner type="bubble" centered />}>
      {children}
    </Suspense>
  );
}

export default LazyComponent;
