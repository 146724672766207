import Button from '@bit/be-novative.kit.button';
import formControl from '@bit/be-novative.kit.form-control';
import { FORM_ERROR } from 'final-form';
import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import {
  htmlFromState,
  parseEditorState
} from '@bit/be-novative.kit.richtext-utils';
import Callout from '../../../common/components/Callout';
import Panel from '../../../common/components/Panel';
import RichText from '../../../common/components/RichText';
import withModal from '../../../common/containers/withModal';
import translateApiErrorMessage from '../../../utils/translateApiErrorMessage';

const RichTextFormControl = formControl(RichText);

function ConceptReviewerCommentModal({
  description,
  initialValue = '',
  isGroupComment,
  onClose,
  onSubmit,
  t,
  title
}) {
  const handleSubmit = useCallback(
    async ({ text }) => {
      try {
        await onSubmit(htmlFromState(text), isGroupComment);
        onClose();
      } catch (error) {
        return { [FORM_ERROR]: translateApiErrorMessage(t, error.code) };
      }
    },
    [isGroupComment, onSubmit, onClose, t]
  );

  return (
    <Panel header={title} rounded>
      {description && <p>{description}</p>}
      <Form
        initialValues={{
          text: parseEditorState(initialValue),
          isGroupComment: 0
        }}
        onSubmit={handleSubmit}>
        {({ form, handleSubmit }) => {
          const { pristine, submitting, submitError } = form.getState();
          return (
            <form onSubmit={handleSubmit}>
              <Field name="text" component={RichTextFormControl} autoFocus />
              <footer
                style={{
                  marginBottom: '1rem',
                  textAlign: 'right'
                }}>
                <Button
                  type="submit"
                  variant="success"
                  loading={submitting}
                  disabled={pristine}
                  style={{ marginLeft: '1rem' }}>
                  {t('ideaConcepts/subpage/describeForm/submitCTA')}
                </Button>
              </footer>
              {submitError && (
                <Callout type="danger" tag="p">
                  {submitError}
                </Callout>
              )}
            </form>
          );
        }}
      </Form>
    </Panel>
  );
}

export default compose(
  withModal(),
  translate()
)(ConceptReviewerCommentModal);
