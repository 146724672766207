import React, { useCallback, useEffect, useState } from 'react';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Button from '@bit/be-novative.kit.button';
import Heading from '../../../common/components/Heading';
import PrimaryNavigation from '../../../common/components/PrimaryNavigation';
import Tab from '../../../common/components/TabButton';
import ActiveSelections from './ActiveSelections';
import ChallengeSearch from './ChallengeSearch';
import LatestChallengesTab from './LatestChallengesTab';
import scss from './ChallengeFilter.module.scss';

const ACTIVE = 'active';
const LATEST = 'latest';
const SEARCH = 'search';

ChallengeFilter.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selections: PropTypes.arrayOf(PropTypes.string)
};
ChallengeFilter.defaultProps = {
  selections: []
};
function ChallengeFilter({ onClose, onSubmit, selections, t }) {
  const [activeTab, setActiveTab] = useState();
  const [hasChangedTab, setHasChangedTab] = useState(false);
  const [selectedItems, setSelectedItems] = useState(selections);
  const selectedCounter = size(selectedItems) || 0;

  useEffect(
    function setInitialTab() {
      if (!hasChangedTab) {
        setActiveTab(selectedCounter > 0 ? ACTIVE : LATEST);
        setHasChangedTab(true);
      }
    },
    [hasChangedTab, selectedCounter]
  );

  const handleTabClick = useCallback(
    tabId => () => {
      setActiveTab(tabId);
      setHasChangedTab(true);
    },
    []
  );

  const handleSelection = useCallback(event => {
    event.persist();
    setSelectedItems(currentSelections => {
      if (event.target.checked) {
        return uniq([...currentSelections, event.target.name]);
      } else {
        return without(currentSelections, event.target.name);
      }
    });
  }, []);

  const handleSelectAll = useCallback(challengeIds => {
    setSelectedItems(challengeIds);
  }, []);

  const handleDeselectAll = useCallback(() => {
    setSelectedItems([]);
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(selectedItems);
    onClose();
  }, [onClose, onSubmit, selectedItems]);

  return (
    <aside
      className={scss.ChallengeFilter}
      aria-labelledby="challengeFilterTitle">
      <Heading level={3} style={{ marginTop: 0 }} id="challengeFilterTitle">
        {t('challenge/filterForm/title')}
      </Heading>
      <PrimaryNavigation role="tablist">
        <Tab
          active={activeTab === ACTIVE}
          onClick={handleTabClick(ACTIVE)}
          ariaId={ACTIVE}>
          {t('challenge/filterForm/active/title')}
        </Tab>
        <Tab
          active={activeTab === LATEST}
          onClick={handleTabClick(LATEST)}
          ariaId={LATEST}>
          {t('challenge/filterForm/latest/title')}
        </Tab>
        <Tab
          active={activeTab === SEARCH}
          onClick={handleTabClick(SEARCH)}
          ariaId={SEARCH}>
          {t('challenge/filterForm/search/title')}
        </Tab>
      </PrimaryNavigation>

      <main className={scss.Content} role="presentation">
        {activeTab === ACTIVE && (
          <ActiveSelections
            onSelect={handleSelection}
            selections={selectedItems}
            onSelectAll={handleSelectAll}
            onDeselectAll={handleDeselectAll}
            role="tabpanel"
            aria-labelledby={ACTIVE}
            data-test={ACTIVE}
          />
        )}
        {activeTab === LATEST && (
          <LatestChallengesTab
            onSelect={handleSelection}
            selections={selectedItems}
            role="tabpanel"
            aria-labelledby={LATEST}
            data-test={LATEST}
          />
        )}
        {activeTab === SEARCH && (
          <ChallengeSearch
            onSelect={handleSelection}
            selections={selectedItems}
            role="tabpanel"
            aria-labelledby={SEARCH}
            data-test={SEARCH}
          />
        )}
      </main>

      <footer className={scss.Footer}>
        <Button variant="outline" onClick={onClose}>
          {t('common/userAction/close')}
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {t('challenge/filterForm/submitCTA', { count: selectedCounter })}
        </Button>
      </footer>
    </aside>
  );
}

export default translate()(ChallengeFilter);
