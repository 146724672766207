import { createAction } from 'redux-actions';
import {
  NOTIFICATIONS_INIT,
  NOTIFICATION_RECEIVED,
  NOTIFICATION_READ
} from '../../constants';
import { SOCKET_ID } from '../../api/constants';
import { SOCKET_SUBSCRIBE, SOCKET_UNSUBSCRIBE } from './socket';

const initNotifications = createAction(NOTIFICATIONS_INIT);
const receivedNotification = createAction(NOTIFICATION_RECEIVED);
const markNotificationRead = createAction(NOTIFICATION_READ);

const serverEvents = {
  'com.benovative.voyager.api.websocket.NotificationList': initNotifications,
  'com.benovative.voyager.api.websocket.Notification': receivedNotification,
  'com.benovative.voyager.api.websocket.NotificationRead': markNotificationRead
};

export { initNotifications, receivedNotification, markNotificationRead };

export const subscribeNotifications = () => {
  return {
    type: SOCKET_SUBSCRIBE,
    payload: serverEvents,
    meta: { socketId: SOCKET_ID.NOTIFICATION }
  };
};

export const unsubscribeNotifications = () => {
  return {
    type: SOCKET_UNSUBSCRIBE,
    payload: serverEvents,
    meta: { socketId: SOCKET_ID.NOTIFICATION }
  };
};
