import React from 'react';
import { NavLink } from 'react-router-dom';
import './SecondaryNavigationItem.css';

function SecondaryNavigationItem({ children, ...rest }) {
  return (
    <li className="SecondaryNavigationItem">
      <NavLink
        {...rest}
        className="SecondaryNavigationItem__Link"
        activeClassName="SecondaryNavigationItem__Link--active">
        {children}
      </NavLink>
    </li>
  );
}

export default SecondaryNavigationItem;
