import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import GridPropTypes from './PropTypes';
import { withResponsive } from '../Responsive';
import './Row.css';

const Row = props => {
  const { className, tag: Tag, gutter, align, noWrap, ...rest } = props;

  const classes = classNames(className, 'Row', `Row--${gutter}`, {
    [`Row--${align}`]: align,
    'Row--no-wrap': !!noWrap
  });

  return <Tag {...rest} className={classes} />;
};

Row.propTypes = {
  tag: GridPropTypes.tag,
  className: PropTypes.string,
  gutter: PropTypes.number,
  noWrap: PropTypes.bool,
  align: PropTypes.oneOf(['center', 'stretch'])
};

Row.defaultProps = {
  tag: 'div',
  gutter: 2
};

export default withResponsive(Row);
