import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import './DumbTextarea.css';

class DumbTextarea extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    input: PropTypes.object,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    marked: PropTypes.bool,
    rows: PropTypes.number,
    resizable: PropTypes.bool
  };

  static defaultProps = {
    input: { value: '' },
    marked: false,
    type: 'text',
    readOnly: false,
    disabled: false,
    defaultValue: '',
    meta: {},
    rows: 3
  };

  focus() {
    if (this.el) {
      this.el.focus();
    }
  }

  render() {
    const {
      input,
      meta,
      placeholder,
      marked,
      readOnly,
      disabled,
      defaultValue,
      rounded,
      rows,
      resizable
    } = this.props;
    const { active, touched, error, warning, submitFailed, submitting } = meta;

    const classes = classNames('Textarea', {
      'Textarea--error': (touched || submitFailed || submitting) && error,
      'Textarea--warning': (touched || submitFailed || submitting) && warning,
      'Textarea--marked': marked,
      'Textarea--active': active,
      'Textarea--rounded': rounded,
      'Textarea--resizable': resizable
    });

    return (
      <textarea
        {...input}
        value={input.value || defaultValue}
        ref={el => (this.el = el)}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        rows={rows}
        className={classes}
      />
    );
  }
}

export default DumbTextarea;
