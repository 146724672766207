import { schema } from 'normalizr';
import { entities } from './constants';
import {
  getUserId,
  getUserHash,
  getUserDisplayName
} from './common/getters/users';

export const CATEGORY_ID = 'categoryId';
export const USER_ID = 'userId';

const userSchema = new schema.Entity(
  entities.USER,
  {},
  {
    idAttribute: USER_ID
  }
);

const invitedUserSchema = new schema.Entity(
  entities.USER,
  {},
  {
    idAttribute: user => {
      const userId = getUserId(user);
      const hash = getUserHash(user);
      const displayName = getUserDisplayName(user);

      return userId || hash || displayName;
    }
  }
);

const categorySchema = new schema.Entity(
  entities.CATEGORY,
  {},
  {
    idAttribute: CATEGORY_ID
  }
);

export default {
  CATEGORY: categorySchema,
  CATEGORY_ARRAY: [categorySchema],
  USER: userSchema,
  USER_ARRAY: [userSchema],
  INVITED_USER: invitedUserSchema,
  INVITED_USER_ARRAY: [invitedUserSchema]
};
