import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import maxByFp from 'lodash/fp/maxBy';
import { createSelector } from 'reselect';
import { getAllIdsFrom } from '../../common/selectors/entities';
import { getEditTime } from '../../common/selectors/meta';
import { rootSelector } from '.';

const ideasRoot = flow(
  rootSelector,
  getFp('ideas')
);

export const getCanvasIdeaIds = state =>
  flow(
    ideasRoot,
    getAllIdsFrom
  )(state);

export const getCanvasIdeas = state =>
  flow(
    ideasRoot,
    getFp('byId')
  )(state);

export const getCanvasIdeaById = createSelector(
  getCanvasIdeas,
  (_, ideaId) => ideaId,
  (ideaMap, id) => get(ideaMap, id)
);

export const makeGetCanvasIdeaById = () => getCanvasIdeaById;

export const findLastModifyTime = (items = []) =>
  flow(
    maxByFp(
      flow(
        getEditTime,
        timestamp => new Date(timestamp)
      )
    ),
    getEditTime
  )(items);

export const getOwnerId = idea => get(idea, 'owner');
