import isEmpty from 'lodash/isEmpty';

/**
 * Creates spreadable object of props for displaying a tooltip
 * @param {string} tip
 * @param {string} [position] - `up|down|left|right`
 * @param {string} [length] - `small|medium|large|xlarge|fit`
 * @return {Object.<string,string>} Spreadable props for the component that should anchor the tooltip
 */

export default function useTooltip(tip, position = 'up', length) {
  if (isEmpty(tip)) {
    return {};
  }

  return {
    'aria-label': tip,
    'data-balloon': tip,
    'data-balloon-pos': position,
    'data-balloon-length': length
  };
}
