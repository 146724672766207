import get from 'lodash/get';
import client from '../../api/client';
import { convertApiErrorToStatusCode } from '../../api/utils/apiError';
import { getApiResponseData } from '../../api/utils/apiResponse';
import logRequestFailure from '../../api/utils/logRequestFailure';
import addBreadcrumb from '../../telemetry/addBreadcrumb';
import getFilenameFromAjaxHeader from '../../utils/getFilenameFromAjaxHeader';
import { saveJson } from '../../utils/saveFile';
import { scrubProfilePayload } from '../utils/payloadUtils';

/**
 * Fetches user's own profile from the API
 * @return {OwnProfilePayload} The user's own profile
 */

async function fetchOwnProfile() {
  const response = await client.get(`/profile`);
  return getApiResponseData(response);
}

/**
 * Update user's own profile through the API
 * @param {OwnProfilePayload} profilePayload - The updated user profile
 * @return {Promise} The API request promise
 */

async function updateProfile(profilePayload) {
  addBreadcrumb('Updating profile data...', {
    category: 'profile',
    data: scrubProfilePayload(profilePayload)
  });
  try {
    return await client.put('/profile', profilePayload);
  } catch (error) {
    logRequestFailure(error);
    const code = convertApiErrorToStatusCode(error);
    return Promise.reject({ code, error });
  }
}

/**
 * Triggers permanently deleting the user's account
 * @return {Promise} The API request promise
 */

async function deleteProfile() {
  return await client.delete('/profile');
}

/**
 * Fetches the user's profile export and saves it as a JSON file
 * @return {Promise<ProcessedApiCallResult>} The request promise or the processed error
 */

async function exportProfile() {
  addBreadcrumb('Exporting profile data...', {
    category: 'profile'
  });
  try {
    const result = await client.get('/profile/export');
    const fileName = getFilenameFromAjaxHeader(
      get(result, 'headers[content-disposition]')
    );
    const defaultFileName = 'benovative_export.json';
    saveJson(getApiResponseData(result), fileName || defaultFileName);
  } catch (error) {
    logRequestFailure(error);
    const code = convertApiErrorToStatusCode(error);
    return Promise.reject({ code, error });
  }
}

async function changePassword(passOrToken, newPassword) {
  addBreadcrumb('Changing account password...', {
    category: 'profile'
  });
  try {
    return await client.put('/profile/password', {
      currentPassword: passOrToken,
      newPassword
    });
  } catch (error) {
    logRequestFailure(error);
    const code = convertApiErrorToStatusCode(error);
    return Promise.reject({ code, error });
  }
}

export default {
  fetchOwnProfile,
  updateProfile,
  deleteProfile,
  exportProfile,
  changePassword
};
