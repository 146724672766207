import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import getDisplayName from '../../../utils/getDisplayName';

FinalFormWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  onSubmit: PropTypes.func.isRequired
};
FinalFormWrapper.defaultProps = {
  inputName: 'value'
};
export default function FinalFormWrapper({
  component,
  initialValues,
  inputName,
  onCancel,
  onSave,
  onStartEdit,
  onSubmit,
  ...restOfProps
}) {
  FinalFormWrapper.displayName = `FinalFormWrapper(${getDisplayName(
    component
  )})`;

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ form, handleSubmit, pristine, valid }) => {
        const { reset, submit } = form;
        const fieldProps = {
          ...restOfProps,
          component,
          name: inputName,
          onCancel,
          onSave,
          onStartEdit,
          pristine,
          reset,
          submit,
          valid
        };
        return (
          <form onSubmit={handleSubmit}>
            <Field {...fieldProps} />
          </form>
        );
      }}
    </Form>
  );
}
