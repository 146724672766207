import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Panel, Callout, Button, Heading } from '../../../common/components';
import { resetPassword } from '../../actions';

const PENDING = 'PENDING';
const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';

PasswordResetHelp.propTypes = {
  t: PropTypes.func.isRequired
};
export function PasswordResetHelp({ t, ...rest }) {
  const [requestStatus, setRequestStatus] = useState();
  const dispatch = useDispatch();

  const handleRequestClick = async () => {
    setRequestStatus(PENDING);

    try {
      await dispatch(resetPassword());
      setRequestStatus(SUCCESS);
    } catch (error) {
      setRequestStatus(FAIL);
    }
  };

  return (
    <Panel
      {...rest}
      rounded
      shadowed
      header={t('profile/passwordReset/title')}
      className="h-text-center">
      <p>{t('profile/passwordReset/body')}</p>

      {requestStatus !== SUCCESS && (
        <Button
          variant="link"
          onClick={handleRequestClick}
          loading={requestStatus === PENDING}>
          {t('profile/passwordReset/requestCTA')}
        </Button>
      )}

      {requestStatus === FAIL && (
        <Callout type="danger" tag="p" style={{ marginTop: '1rem' }}>
          {t('profile/passwordReset/submitFail')}
        </Callout>
      )}

      {requestStatus === SUCCESS && (
        <Heading level={3}>{t('profile/passwordReset/submitSuccess')}</Heading>
      )}
    </Panel>
  );
}

export default translate()(PasswordResetHelp);
