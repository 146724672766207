import PropTypes from 'prop-types';
import React, { Component, Children } from 'react';
import { Manager, Target, Popper } from 'react-popper';
import { TransitionGroup } from 'react-transition-group';
import { Portal } from 'react-overlays';
import Popover from './Popover';
import FadeTransition from '../FadeTransition';

class PopoverTrigger extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    closeable: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    popoverPlacement: Popper.propTypes.placement,
    popoverModifiers: PropTypes.object,
    popover: PropTypes.node.isRequired,
    show: PropTypes.bool
  };

  static defaultProps = {
    animationSpeed: 500,
    popoverPlacement: 'bottom',
    show: false,
    onClose: () => {},
    style: { display: 'inline-block' }
  };

  render() {
    const {
      action,
      animationSpeed,
      children,
      closeable,
      onClose,
      popover,
      popoverStyle,
      popoverBodyStyle,
      popoverClassName,
      popoverPlacement,
      popoverModifiers,
      show,
      showFooterStripe,
      style
    } = this.props;
    const child = Children.only(children);

    return (
      <Manager style={style}>
        <Target>{child}</Target>
        <Portal>
          <TransitionGroup>
            {show && (
              <FadeTransition speed={animationSpeed}>
                <Popper
                  key="popper"
                  placement={popoverPlacement}
                  modifiers={popoverModifiers}>
                  {({ popperProps }) => {
                    return (
                      <Popover
                        action={action}
                        outsideClickIgnoreClass="Popover"
                        onClose={onClose}
                        popperProps={popperProps}
                        className={popoverClassName}
                        showFooterStripe={showFooterStripe}
                        style={popoverStyle}
                        bodyStyle={popoverBodyStyle}
                        closeable={closeable}>
                        {popover}
                      </Popover>
                    );
                  }}
                </Popper>
              </FadeTransition>
            )}
          </TransitionGroup>
        </Portal>
      </Manager>
    );
  }
}

export default PopoverTrigger;
