import React, { useCallback, useContext, useState } from 'react';
import flow from 'lodash/flow';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import omit from 'lodash/omit';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { translate, Trans } from 'react-i18next';
import { parseEditorState } from '@bit/be-novative.kit.richtext-utils';
import {
  POSITION_MAXLENGTH,
  WORKPLACE_MAXLENGTH,
  PROFILE_IMAGE_ASPECT_RATIO,
  PROFILE_IMAGE_CROP_HEIGHT,
  PROFILE_FORM_FIELDS
} from '../../constants';
import { ImageTypes } from '../../../constants';
import { required, url, maxlength } from '../../../utils/validation';
import formControl from '@bit/be-novative.kit.form-control';
import Button from '@bit/be-novative.kit.button';
import Callout from '../../../common/components/Callout';
import Checkbox from '@bit/be-novative.kit.checkbox';
import Grid from '../../../common/components/Grid';
import Input from '@bit/be-novative.kit.input';
import RichText from '../../../common/components/RichText';
import UrlInput from '../../../common/components/UrlInput';
import { AuthContext } from '../../../authentication/components/AuthProvider';
import ImageInput from '../../components/ImageInput';
import { openImagePickerModal } from '../../../common/actions';
import { getCurrentLanguage } from '../../../common/selectors/language';
import {
  updateOwnProfile,
  ownProfileSelector
} from '../../../profile/state/profile';
import {
  getUserAvatar,
  getFirstName,
  getLastName
} from '../../../common/getters/users';
import {
  getWorkPosition,
  getWorkplace,
  getDescription,
  getLinkedinUrl,
  getWebsite
} from '../../getters';
import { getProfileFormSettings } from '../../config';
import translateApiErrorMessage from '../../../utils/translateApiErrorMessage';
import useMountEffect from '../../../common/hooks/useMountEffect';

const InputFormControl = formControl(Input);
const RichTextFormControl = formControl(RichText);
const UrlInputFormControl = formControl(UrlInput);

const {
  DESCRIPTION,
  FIRST_NAME,
  IMAGE,
  LAST_NAME,
  POSITION,
  WORKPLACE,
  WEBSITE_URL,
  LINKEDIN_URL
} = PROFILE_FORM_FIELDS;

function ProfileEditForm({ t }) {
  // Handled in lifecycle to allow testing
  const [requireConsent, setRequireConsent] = useState(false);
  useMountEffect(function checkIfConsentRequired() {
    const formSettings = getProfileFormSettings();
    setRequireConsent(formSettings.requirePrivacyConsent);
  });

  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    async function handleSubmit(formData) {
      try {
        unwrapResult(
          await dispatch(updateOwnProfile(omit(formData, 'consent')))
        );
        if (window) {
          window.location.reload();
        }
      } catch (error) {
        return { [FORM_ERROR]: get(error, 'code') };
      }
    },
    [dispatch]
  );

  const handleAvatarChange = useCallback(
    function handleAvatarChange(onImageSelect) {
      dispatch(
        openImagePickerModal({
          defaultActiveType: ImageTypes.UPLOADED,
          availableTypes: [ImageTypes.UPLOADED],
          crop: {
            aspect: PROFILE_IMAGE_ASPECT_RATIO,
            width: PROFILE_IMAGE_CROP_HEIGHT
          },
          isCloseButtonShown: false,
          onImageSelect
        })
      );
    },
    [dispatch]
  );

  const language = useSelector(getCurrentLanguage);
  const isNameReversed = lowerCase(language) === 'hu';

  const { userEmail } = useContext(AuthContext);
  const profile = useSelector(ownProfileSelector);
  const initialForm = {
    [FIRST_NAME]: getFirstName(profile),
    [LAST_NAME]: getLastName(profile),
    [IMAGE]: getUserAvatar(profile),
    [DESCRIPTION]: flow(
      getDescription,
      parseEditorState
    )(profile),
    [POSITION]: getWorkPosition(profile),
    [WORKPLACE]: getWorkplace(profile),
    [WEBSITE_URL]: getWebsite(profile),
    [LINKEDIN_URL]: getLinkedinUrl(profile)
  };

  return (
    <Form
      initialValues={initialForm}
      keepDirtyOnReinitialize={true}
      onSubmit={handleSubmit}>
      {({ form, handleSubmit, values }) => {
        const {
          invalid,
          submitting,
          submitError,
          submitSucceeded
        } = form.getState();
        const firstName = get(values, FIRST_NAME);
        const lastName = get(values, LAST_NAME);
        const avatarUserName = isNameReversed
          ? `${lastName} ${firstName}`
          : `${firstName} ${lastName}`;
        const updateAvatar = () => {
          const imageUpdateCallback = image => {
            form.change(IMAGE, image);
            form.submit();
          };
          handleAvatarChange(imageUpdateCallback);
        };

        return (
          <form onSubmit={handleSubmit} data-test="form">
            <Grid.Row gutter={0}>
              <Grid.Col xsGutterX={3} md={4} lg={3}>
                <Field
                  name={IMAGE}
                  component={ImageInput}
                  rounded
                  editText={t('profile/editorForm/fields/image/label')}
                  onEdit={updateAvatar}
                  userName={avatarUserName}
                />
              </Grid.Col>
              <Grid.Col xsGutterX={2} md={8} lg={9}>
                <h1>{t('profile/editorForm/title')}</h1>
                {submitSucceeded && (
                  <Callout type="success" tag="p">
                    {t('profile/editorForm/submitSuccess')}
                  </Callout>
                )}
                {submitError && (
                  <Callout type="danger" tag="p">
                    {translateApiErrorMessage(t, submitError)}
                  </Callout>
                )}
                <Grid.Row>
                  {!isNameReversed && (
                    <Grid.Col xsGutterX={2} md={6}>
                      <Field
                        label={t('profile/editorForm/fields/firstName/label')}
                        name={FIRST_NAME}
                        component={InputFormControl}
                        validate={required(t)}
                        required
                      />
                    </Grid.Col>
                  )}
                  <Grid.Col xsGutterX={2} md={6}>
                    <Field
                      label={t('profile/editorForm/fields/lastName/label')}
                      name={LAST_NAME}
                      component={InputFormControl}
                      validate={required(t)}
                      required
                    />
                  </Grid.Col>
                  {isNameReversed && (
                    <Grid.Col xsGutterX={2} md={6}>
                      <Field
                        label={t('profile/editorForm/fields/firstName/label')}
                        name={FIRST_NAME}
                        component={InputFormControl}
                        validate={required(t)}
                        required
                      />
                    </Grid.Col>
                  )}
                  <Grid.Col xsGutterX={2}>
                    <InputFormControl
                      label={t('profile/editorForm/fields/email/label')}
                      secondaryLabel={t(
                        'profile/editorForm/fields/email/secondaryLabel'
                      )}
                      defaultValue={userEmail}
                      disabled
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xsGutterX={2}>
                    <Field
                      label={t('profile/editorForm/fields/position/label')}
                      name={POSITION}
                      component={InputFormControl}
                      maxLength={POSITION_MAXLENGTH}
                      validate={maxlength(t, POSITION_MAXLENGTH)}
                    />
                  </Grid.Col>

                  <Grid.Col xsGutterX={2}>
                    <Field
                      label={t('profile/editorForm/fields/workplace/label')}
                      name={WORKPLACE}
                      component={InputFormControl}
                      maxLength={WORKPLACE_MAXLENGTH}
                      validate={maxlength(t, WORKPLACE_MAXLENGTH)}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
              <Grid.Col xsGutterX={2}>
                <Field
                  label={t('profile/editorForm/fields/aboutMe/label')}
                  name={DESCRIPTION}
                  component={RichTextFormControl}
                />
              </Grid.Col>
              <Grid.Col xsGutterX={2} lg={6}>
                <Field
                  label={t('profile/editorForm/fields/websiteUrl/label')}
                  name={WEBSITE_URL}
                  component={UrlInputFormControl}
                  placeholder={'https://'}
                  validate={url(t)}
                  hint={t('profile/editorForm/fields/websiteUrl/hint')}
                />
              </Grid.Col>
              <Grid.Col xsGutterX={2} lg={6}>
                <Field
                  label={t('profile/editorForm/fields/linkedInUrl/label')}
                  name={LINKEDIN_URL}
                  component={UrlInputFormControl}
                  placeholder={'https://'}
                  validate={url(t)}
                  hint={t('profile/editorForm/fields/linkedInUrl/hint')}
                />
              </Grid.Col>
              <Grid.Col xsGutterX={2}>
                <p>
                  <small>
                    <em>
                      <Trans i18nKey="profile/editorForm/requiredTip">
                        <span style={{ color: 'red' }}>* </span>
                      </Trans>
                    </em>
                  </small>
                </p>
                {requireConsent && (
                  <div style={{ marginBottom: '1em' }}>
                    <Field
                      name="consent"
                      component={Checkbox}
                      validate={required(t)}
                      required>
                      {t('legalConsent/profileEdit')}
                    </Field>
                  </div>
                )}
                <Button
                  type="submit"
                  variant="success"
                  loading={submitting}
                  disabled={invalid}>
                  {t('common/userAction/save')}
                </Button>
              </Grid.Col>
            </Grid.Row>
          </form>
        );
      }}
    </Form>
  );
}

export default translate()(ProfileEditForm);
