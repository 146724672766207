import styled, { css } from 'styled-components';
import Icon from '../Icon';

const focused = css`
  outline: none;
  border-color: #0a86cd;
  box-shadow: inset 0 5px 7px #f0f0f0;
`;

export const StyledInput = styled.input`
  position: relative;
  z-index: 1;
  padding-left: calc(0.563rem + 30px);
  border: 1px solid #c7c7c7;
  border-radius: 2px;
  transition: border-color 150ms ease;

  &:hover:not(:disabled) {
    border-color: #8c8c8c;
  }

  &:focus {
    ${focused}
  }

  ${({ active }) => active && focused}
  ${({ error }) =>
    error &&
    css`
      border-color: #ed1717;
    `}
`;

export const InputIcon = styled(Icon)`
  position: absolute;
  top: 0.563rem;
  left: 0.75rem;
  z-index: 2;

  ${({ disabled }) =>
    disabled &&
    css`
      color: #c7c7c7;
    `}
`;
