import { SOCKET_ID } from '../api/constants';

// Config

export const MOVE_THROTTLE_MS = 500;
export const MAX_ITEMS_IN_ROOM = 15;

// Definitions

/**
 * @enum {string} CanvasBackgroundType
 * @readonly
 * Defines available canvas background types.
 * Currently one type is supported per platform.
 *
 * Related background components can be found in
 * src/ideaConceptCanvas/components/CanvasBackground/CanvasBackground.js
 */

export const CanvasBackgroundType = {
  AmdocsBusinessModel: 'AmdocsBusinessModel',
  BusinessModel: 'BusinessModel',
  BusinessModel2: 'BusinessModel2',
  CepheidBusinessModel: 'CepheidBusinessModel',
  ConceptMap: 'ConceptMap',
  EmpathyMap: 'EmpathyMap',
  GivaudanInt: 'GivaudanInt',
  InsightCanvas: 'InsightCanvas',
  MissionModel: 'MissionModel',
  ProblemDeconstruction: 'ProblemDeconstruction',
  ProblemOpportunityMap: 'ProblemOpportunityMap',
  RiskAnalysis: 'RiskAnalysis',
  SocialImpactMap: 'SocialImpactMap',
  SmuValueProposition: 'SmuValueProposition',
  SmuZones: 'SmuZones',
  StakeholderMap: 'StakeholderMap'
};

export const CanvasContentRef = {
  Concept: '##concept##',
  Separator: ':'
};

export const DRAGGABLE_TYPES = {
  GROUP: 'GROUP',
  IDEA: 'IDEA',
  IMAGE: 'IMAGE',
  // internal to UI
  EDITOR: 'EDITOR',
  IMPORTED_CONCEPT: 'IMPORTED_CONCEPT'
};

export const SESSION_STATUS = {
  /** Socket is connected, user has initiated request to join session */
  JOINING: 'JOINING',
  /** Socket is connected, user is in the canvas session */
  ACTIVE: 'ACTIVE',
  /** Socket is connected, but user is not yet in the session. Or the socket connection is dropping/failing. */
  INACTIVE: 'INACTIVE',
  /** User could not join the session */
  ERROR: 'ERROR'
};

// Modals

export const IMAGE_SELECTOR_MODAL = 'IMAGE_SELECTOR_MODAL';

// Web socket messages

export const S_ID = SOCKET_ID.CONCEPT_CANVAS;

export const SOCKET_MSG = {
  ADD_IDEA_REQ: 'AddIdea',
  ADD_IDEA_TO_GROUP_REQ: 'GroupIdeas',
  CANVAS_JOIN_REQ: 'Join',
  DELETE_IDEA_REQ: 'DeleteIdea',
  EDIT_IDEA_REQ: 'EditIdea2',
  GROUP_ADD_IDEA_REQ: 'GroupIdeasWithTitle',
  GROUP_IDEA_ADDED: 'IdeasGrouped',
  GROUP_MOVE_REQ: 'MoveGroup',
  GROUP_MOVED: 'GroupMoved',
  GROUP_RENAME_REQ: 'EditGroup',
  GROUP_RENAMED: 'GroupEdited',
  IDEA_ADDED: 'IdeaAdded',
  IDEA_DELETED: 'IdeaDeleted',
  IDEA_EDITED: 'IdeaEdited',
  IDEA_MERGE_REQ: 'MergeIdeas',
  IDEA_MERGED: 'IdeaEditedWithMergedItems',
  IDEA_MOVED: 'IdeaMoved',
  IMAGE_DELETE_REQ: 'DeleteImage',
  IMAGE_DELETED: 'ImageDeleted',
  IMAGE_MOVE_REQ: 'MoveImage',
  IMAGE_MOVED: 'ImageMoved',
  IMAGE_RECEIVED: 'ImageAdded',
  IMAGE_SUBMIT: 'AddImage',
  JOINED_CANVAS: 'CanvasJoined',
  LINK_CHALLENGE_IDEA: 'LinkIdea',
  MOVE_IDEA_REQ: 'MoveIdea'
};
