import { FETCH_CHALLENGE, DELETE_CHALLENGE } from '../../constants';
import { VOYAGER_API } from '../../api/constants';
import schemas, { entityKeys } from '../../challenge/schemas';
import { convertApiErrorToStatusCode } from '../../api/utils/apiError';

export const fetchChallenge = challengeId => dispatch =>
  dispatch({
    [VOYAGER_API]: {
      url: `/challenges/${challengeId}`,
      method: 'GET',
      type: FETCH_CHALLENGE,
      challengeId,
      schema: schemas.CHALLENGE,
      entityType: entityKeys.CHALLENGE,
      id: challengeId
    }
  }).catch(error => {
    throw convertApiErrorToStatusCode(error);
  });

export const deleteChallenge = challengeId => {
  return {
    [VOYAGER_API]: {
      url: `/challenges/${challengeId}`,
      method: 'DELETE',
      type: DELETE_CHALLENGE
    }
  };
};
