import React, { Component } from 'react';
import cn from 'classnames';
import './CircleSpinner.css';

let id = 0;

class CircleSpinner extends Component {
  instanceId = ++id;
  circles = [160, 150, 135];

  render() {
    const gradientId = `CircleSpinner__LinearGradient${this.instanceId}`;
    return (
      <div
        className={cn('CircleSpinner', this.props.className)}
        style={this.props.style}>
        <svg viewBox="-200 -200 400 400">
          <defs>
            <linearGradient id={gradientId} x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stopColor="#3EDE91" />
              <stop offset="100%" stopColor="#377dd4" />
            </linearGradient>
          </defs>

          <g className="CircleSpinner__Circles">
            {this.circles.map(r => (
              <circle key={r} r={r} stroke={`url(#${gradientId})`} />
            ))}
          </g>
          <path
            d="m -52.9,-47.7 26.3,7.8 19.8,-0.8 8.4,7.5 3.6,13.6 2.7,3.5 c 0,0 22.6,-15.7 26.7,-16.8 4.1,-1.1 13.7,-3.7 13.7,-3.7 l 1.8,0.2 1.2,3.2 -22.6,29.5 19.2,3.7 1,1.1 2.5,4.3 -2.5,3.3 -5.2,1.7 -2,0.9 -11,4.6 2.3,4.2 5.2,20.7 2.2,5.2 2.1,4.8 -1,0.6 L 36.6,48.1 35.5,44.3 20,33.2 14,30.7 0.1,21.5 -9.3,12 l -6.4,-12.6 -2.9,-10.6 -2.3,-4.3 -4.9,-16.1 -7,-6.7 -20.1,-8.9 0,-0.5 z"
            className="CircleSpinner__Hummingbird"
          />
        </svg>
      </div>
    );
  }
}

export default CircleSpinner;
