export const ADD_ENTITY = 'ADD_ENTITY';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';

/**
 * Adds, or completely replaces one or more entities based on the provided payload.
 *
 * **Take care to keep links to other normalized entities intact, or update those in separate actions!**
 * @param {string} entityType
 * @param {NormalizationResult} payload
 * @returns {import('redux').Action} Returns a curried function that accepts the new entity
 */
export const addEntity = (entityType, payload) => ({
  type: ADD_ENTITY,
  payload,
  meta: {
    entityType
  }
});

/**
 * Allows changing certain values of an entity by mapping the changed values to the existing object.
 *
 * **Take care not to change normalized links (e.g. category IDs), as that will break the reference between entities!**
 * @param {string} entityType
 * @param {string} id
 * @returns {(delta: Object) => import('redux').Action} Returns a curried function that accepts the changed entity values.
 */
export const updateEntity = (entityType, id) => (delta = {}) => ({
  type: UPDATE_ENTITY,
  payload: delta,
  meta: {
    entityType,
    id
  }
});
