import isEmpty from 'lodash/isEmpty';
import {
  getFirstName,
  getLastName,
  getUserDisplayName
} from '../common/getters/users';

/**
 * Extracts the user's first- and lastname and sets them in the correct order according to the locale. Uses display name as fallback.
 * @param {Function} t - The translator method
 * @param {User} user - The user whose name is to be localised
 * @param {string} [tKey] - A custom localisation key
 * @return {string} The localised user name or the display name as fallback
 */

export default function localiseUserName(
  t,
  user,
  tKey = 'common/localisedUserName'
) {
  const first = getFirstName(user);
  const last = getLastName(user);

  if (!isEmpty(first) && !isEmpty(last)) {
    return t(tKey, { first, last });
  }

  return getUserDisplayName(user);
}
