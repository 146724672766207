import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { SIDENAV_OPEN, SIDENAV_CLOSE, ROUTE_CHANGE } from '../../constants';

const open = handleActions(
  {
    [SIDENAV_OPEN]: () => true,
    [combineActions(SIDENAV_CLOSE, ROUTE_CHANGE)]: () => false
  },
  false
);

export default combineReducers({
  open
});
