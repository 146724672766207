import React from 'react';
import Avatar from '../../../common/components/Avatar';
import Button from '@bit/be-novative.kit.button';
import scss from './ImageInput.module.scss';
import { Size } from '../../../constants';

function ImageInput({ input, meta, editText, onEdit, userName, ...rest }) {
  return (
    <div className={scss.Wrapper}>
      <Avatar {...rest} image={input.value} name={userName} size={Size.xl} />
      <Button variant="cover" onClick={onEdit}>
        {editText}
      </Button>
    </div>
  );
}

export default ImageInput;
