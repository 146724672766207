import React, { useState, useCallback } from 'react';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import Button from '@bit/be-novative.kit.button';
import { isCepheidVariant } from '../../../utils/variants';
import {
  IdeaConceptImplementationStatus,
  IdeaConceptImplementationStatusCepheid
} from '../../constants';
import Popover from '../../../common/components/Popover';
import Icon from '../../../common/components/Icon';
import StageIndicator from '../StageIndicator';
import MenuItem from './MenuItem';

const ANIM_SPEED_MS = 300;
const stageEnum = values(
  isCepheidVariant()
    ? IdeaConceptImplementationStatusCepheid
    : IdeaConceptImplementationStatus
);

StageDropdown.propTypes = {
  ideaConceptId: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};
export function StageDropdown({ onSelect, stage }) {
  const [showPopover, setShowPopover] = useState(false);
  const toggleVisibility = useCallback(
    () => setShowPopover(isVisible => !isVisible),
    []
  );
  const handleClose = useCallback(() => setShowPopover(false), []);
  const handleSelect = useCallback(
    stage => () => {
      onSelect(stage);
      handleClose();
    },
    [onSelect, handleClose]
  );

  return (
    <Popover.PopoverTrigger
      // @see https://popper.js.org/popper-documentation.html#modifiers..preventOverflow.boundariesElement
      // StageDropdown is also used in concept list page, where the table has internal scroll
      popoverModifiers={{
        preventOverflow: { boundariesElement: 'viewport' }
      }}
      show={showPopover}
      showFooterStripe={false}
      popover={
        <nav data-test="menu">
          {stageEnum.map(stage => (
            <MenuItem key={stage} onClick={handleSelect} stage={stage} />
          ))}
        </nav>
      }
      onClose={handleClose}
      animationSpeed={ANIM_SPEED_MS}>
      <Button
        variant="minimal"
        onClick={toggleVisibility}
        fillParent
        data-test="toggleBtn">
        <>
          <StageIndicator stage={stage} data-test="btnIndicator" />
          <Icon type="angle-down" />
        </>
      </Button>
    </Popover.PopoverTrigger>
  );
}

export default StageDropdown;
