import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchChallenges } from '../actions';
import { getChallengeId } from '../../common/selectors/challenges';

const RESULT_LIMIT = 10;
const ORDER_BY = 'createdAt';

export default function useChallengeSearch() {
  const [isSearching, setIsSearching] = useState(false);
  const dispatch = useDispatch();

  const handleSearch = useCallback(
    query => {
      if (isEmpty(query)) {
        return;
      }

      setIsSearching(true);

      return dispatch(
        searchChallenges({
          query,
          limit: RESULT_LIMIT,
          orderBy: ORDER_BY,
          descending: true
        })
      )
        .then((challenges = []) => {
          return map(challenges, getChallengeId);
        })
        .finally(() => {
          setIsSearching(false);
        });
    },
    [dispatch]
  );

  return [handleSearch, isSearching];
}
