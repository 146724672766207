import * as Sentry from '@sentry/browser';
import { BLACKLISTED_ERRORS, BLACKLISTED_URLS } from './constants';
import { isInternal, isLocalhost } from '../../utils/platform';

export default function initSentry() {
  if (!isLocalhost()) {
    const sentryEnv =
      process.env.NODE_ENV === 'development' || isInternal()
        ? 'development'
        : 'production';

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: `hummingbird@${process.env.REACT_APP_VERSION}`,
      environment: sentryEnv,
      denyUrls: BLACKLISTED_URLS,
      ignoreErrors: BLACKLISTED_ERRORS
    });
  }
}
