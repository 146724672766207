import get from 'lodash/get';
import split from 'lodash/split';
import includes from 'lodash/includes';

export function getPlatformName() {
  return get(window, 'location.host', '').split('.')[0];
}

/**
 * Checks if the current platform is a public platform based on the subdomain
 * @param {string} [hostname] - The current hostname by default
 * @returns {boolean}
 */

export function isPublic(hostname = getPlatformName()) {
  return includes(
    split(process.env.REACT_APP_PUBLIC_PLATFORM_NAMES, ','),
    hostname
  );
}

export function isLocalhost(hostname = getPlatformName()) {
  const hostStr = hostname.toString();

  return hostStr.includes('localhost') || hostStr.includes('127.0.0.1');
}

export function isStaging(hostname) {
  return includes(
    split(process.env.REACT_APP_STAGING_PLATFORM_NAMES, ','),
    hostname
  );
}

/**
 * @param {string} [hostname] - Host name from a URL
 * @return {boolean} Returns `true` if the hostname points to `localhost` or an internal staging platform
 */

export function isInternal(hostname = getPlatformName()) {
  return isLocalhost(hostname) || isStaging(hostname);
}

export default {
  isPublic,
  isLocalhost,
  isInternal
};
