import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { prependBase64Content } from '../../../utils/payloadUtils/image';
import Icon from '../Icon';
import './ImagePreview.css';

ImagePreview.propTypes = {
  previewLabel: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  input: PropTypes.shape({
    value: PropTypes.object
  }).isRequired
};

function ImagePreview(props) {
  const { input, style, onClick, previewLabel } = props;
  const { value } = input;
  const classes = classNames('ImagePreview', {
    'ImagePreview--selected': !!value
  });

  return (
    <div className={classes} style={style}>
      {value && (
        <img
          className="ImagePreview__Image"
          src={value.url || prependBase64Content(value.content)}
          alt=""
        />
      )}
      <button type="button" className="ImagePreview__Button" onClick={onClick}>
        <Icon type="upload" size="lg" /> {previewLabel(value)}
      </button>
    </div>
  );
}

export default ImagePreview;
