import React, { Component } from 'react';
import PropTypes from 'prop-types';
import flow from 'lodash/flow';
import includes from 'lodash/fp/includes';
import Autosuggest from '../Autosuggest';
import strNormalise from '../../../utils/strNormalise';

class CountrySelect extends Component {
  static propTypes = {
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        cca2: PropTypes.string.isRequired,
        name: PropTypes.string
      })
    )
  };

  static defaultProps = {
    countries: []
  };

  state = {
    suggestions: [],
    value: this.getCountry(this.props.input.value)
  };

  getSuggestions(value = '') {
    const { countries, input } = this.props;

    if (this.getCountry(input.value) === value) {
      return countries;
    }

    const target = strNormalise(value);

    return countries.filter(country =>
      flow(
        strNormalise,
        includes(target)
      )(country.name)
    );
  }

  getCountry(countryCode) {
    const { countries } = this.props;
    if (Array.isArray(countries)) {
      const country = countries.find(country => country.cca2 === countryCode);
      return country ? country.name : '';
    }
    return '';
  }

  handleBlur = () => {
    this.setState({
      value: this.getCountry(this.props.input.value)
    });
  };

  handleChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.input.onChange(suggestion ? suggestion.cca2 : null);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: this.getCountry(nextProps.input.value)
    });
  }

  render() {
    const inputProps = {
      value: this.state.value,
      onChange: this.handleChange,
      onBlur: this.handleBlur
    };

    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={suggestion => suggestion.name}
        renderSuggestion={suggestion => <span>{suggestion.name}</span>}
        inputProps={inputProps}
        meta={this.props.meta}
        onSuggestionSelected={this.onSuggestionSelected}
      />
    );
  }
}

export default CountrySelect;
