import filterFp from 'lodash/fp/filter';
import findFp from 'lodash/fp/find';
import flow from 'lodash/flow';
import isEqualFp from 'lodash/fp/isEqual';
import getOrFp from 'lodash/fp/getOr';
import mapFp from 'lodash/fp/map';
import { sendSocketMessage } from '../../common/actions/socket';
import { SESSION_STATUS, SOCKET_MSG, S_ID } from '../constants';
import {
  mapOwnerIdFromMsg,
  mapPositionFromMsg
} from '../utils/socketMsg/_mappers';
import { getIdeaId, getParentGroupId } from '../../common/selectors/ideas';
import { getGroupId } from '../selectors/groups';
import { storeCanvasIdeas } from './ideas';
import { storeImages } from './images';
import { storeCanvasGroups } from './groups';
import { storeCanvasItemPosition } from './movement';
import { getImageId } from '../selectors/images';
import { updateSessionStatus } from '../stateSlices/status';
import addBreadcrumb from '../../telemetry/addBreadcrumb';

export const joinCanvas = (ideaConceptId, canvasType) => async dispatch => {
  dispatch(updateSessionStatus(SESSION_STATUS.JOINING));
  try {
    addBreadcrumb('Joining canvas...', {
      category: 'canvas',
      data: {
        ideaConceptId,
        canvasType
      }
    });
    await dispatch(
      sendSocketMessage(S_ID, SOCKET_MSG.CANVAS_JOIN_REQ, [
        ideaConceptId,
        canvasType
      ])
    );
    dispatch(updateSessionStatus(SESSION_STATUS.ACTIVE));
  } catch (error) {
    dispatch(updateSessionStatus(SESSION_STATUS.ERROR));
    addBreadcrumb(error, { category: 'canvas' });
    throw error;
  }
};

export const canvasJoined = response => dispatch => {
  const getArray = path => getOrFp([], path);
  const normaliseItems = flow(
    mapPositionFromMsg,
    mapOwnerIdFromMsg
  );
  // API submits empty groups which must be filtered
  const hasGroupContent = ideas => group =>
    flow(
      findFp(
        flow(
          getParentGroupId,
          isEqualFp(getGroupId(group))
        )
      ),
      Boolean
    )(ideas);
  const mapPositionData = idGetter => item => ({
    id: idGetter(item),
    posX: item.posX,
    posY: item.posY
  });

  const ideas = flow(
    getArray('ideas'),
    mapFp(normaliseItems)
  )(response);
  const images = flow(
    getArray('images'),
    mapFp(normaliseItems)
  )(response);
  const groups = flow(
    getArray('groups'),
    filterFp(hasGroupContent(ideas)),
    mapFp(mapPositionFromMsg)
  )(response);
  const positionData = [].concat(
    ideas.map(mapPositionData(getIdeaId)),
    images.map(mapPositionData(getImageId)),
    groups.map(mapPositionData(getGroupId))
  );

  dispatch(storeCanvasIdeas(ideas));
  dispatch(storeImages(images));
  dispatch(storeCanvasGroups(groups));
  dispatch(storeCanvasItemPosition(positionData));
  dispatch(updateSessionStatus(SESSION_STATUS.ACTIVE));
};

export const FLUSH_DATA = 'canvas/flush';
export const flushCanvasData = () => ({
  type: FLUSH_DATA,
  meta: { canvasId: S_ID }
});
