import get from 'lodash/get';
import ConsentCheck from './ConsentCheck';
import { getPage } from '../pageProvider';

const EVENT_CATEG_DEFAULT = 'Common Event';

// https://developers.google.com/analytics/devguides/collection/analyticsjs/pages
// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
class GoogleAnalytics extends ConsentCheck {
  constructor() {
    super();

    if (this.trackingAllowed) {
      require('./injectors/ga');
    }
  }

  _ga(...args) {
    if (this.trackingAllowed) {
      try {
        window.ga(...args);
      } catch (e) {
        console.error('Metrics failure', e);
      }
    }
  }

  pageView(eventName, params) {
    this.setUserId(params);

    this._ga('send', 'pageview', getPage());
  }

  track(eventName, params = {}) {
    const eventLabel = get(params, 'value');
    const eventCategory = get(params, 'eventCategory', EVENT_CATEG_DEFAULT);

    this.setUserId(params);
    this._ga('send', 'event', eventCategory, eventName, eventLabel);
  }

  setUserId(params) {
    if (params.userId) {
      this._ga('set', 'userId', params.userId);
    }
  }
}

export default GoogleAnalytics;
