import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { flushConceptIds, fetchAllIdeaConcepts } from '../../actions';
import { conceptListFilterKeys } from '../../constants';
import useActiveStageFilter from './useActiveStageFilter';

const { STAGE } = conceptListFilterKeys;

export default function useListFetcher() {
  const dispatch = useDispatch();
  const activeStagePathParam = useActiveStageFilter();
  const refreshList = useCallback(
    (args = { query: null, flushList: false }) => {
      if (args.flushList) {
        dispatch(flushConceptIds());
      }
      dispatch(
        fetchAllIdeaConcepts({
          ...args.query,
          [STAGE]: activeStagePathParam
        })
      );
    },
    [dispatch, activeStagePathParam]
  );

  return refreshList;
}
