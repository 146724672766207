import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal as ReactModal } from 'react-overlays';
import classNames from 'classnames';
import Backdrop, { backdropClassNames } from '../Backdrop';
import Close from '../Close';
import ModalTransition from './ModalTransition';
import './Modal.css';
class Modal extends Component {
  renderBackdrop = () => {
    const backdropClasses = classNames(
      backdropClassNames.base,
      backdropClassNames[this.props.overlay],
      'modal-backdrop'
    );

    return (
      <Backdrop
        type={this.props.backdropType}
        className={backdropClasses}
        visible={this.props.isOpen}
      />
    );
  };

  render() {
    const {
      children,
      className,
      isCloseButtonShown,
      isOpen,
      itsATrap,
      onClose,
      rounded,
      size
    } = this.props;

    const modalClasses = classNames('modal', `modal--${size}`, className, {
      'modal--rounded': rounded
    });

    const backdrop = itsATrap ? 'static' : true;

    // @see https://github.com/react-bootstrap/react-bootstrap/blob/master/src/Modal.js
    return (
      <ReactModal
        show={isOpen}
        onHide={onClose}
        className="modal-wrapper"
        backdrop={backdrop}
        renderBackdrop={this.renderBackdrop}
        onBackdropClick={onClose}
        keyboard={!itsATrap}
        transition={ModalTransition}
        backdropTransition={ModalTransition}>
        <div tabIndex="0" className={modalClasses}>
          {children}
          {isCloseButtonShown && <Close onClick={onClose} />}
        </div>
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isCloseButtonShown: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  itsATrap: PropTypes.bool, // @see https://gph.to/2GUc0wy
  onClose: PropTypes.func,
  overlay: PropTypes.oneOf(['brainstorming', 'dark', 'light']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full'])
};

Modal.defaultProps = {
  isCloseButtonShown: false,
  itsATrap: false,
  overlay: 'dark',
  rounded: false,
  size: 'medium'
};

export default Modal;
