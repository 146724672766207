import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';
import { AsyncStatus } from '../../constants';
import categoriesApi from '../api/categoriesApi';
import handleApiCallAction from '../../utils/handleApiCallAction';
import { getCategoryId } from '../getters/categories';
import { getEntities_new } from '../selectors/entities';
import { getStoreStatus } from '../selectors/meta';

const fetchCategories = createAsyncThunk(
  'fetchCategories',
  handleApiCallAction(categoriesApi.fetchCategories)
);

const categsAdapter = createEntityAdapter({
  selectId: getCategoryId
});

const initialState = {
  entities: categsAdapter.getInitialState(),
  status: AsyncStatus.Idle,
  error: null
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  extraReducers: {
    [fetchCategories.pending]: state => {
      state.status = AsyncStatus.Loading;
      state.error = null;
    },
    [fetchCategories.fulfilled]: (state, { payload }) => {
      state.entities = categsAdapter.setAll(state.entities, payload);
      state.status = AsyncStatus.Succeeded;
    },
    [fetchCategories.rejected]: (state, action) => {
      state.status = AsyncStatus.Failed;
      state.error = action.error;
    }
  }
});

const categoriesStoreSelector = getFp('categories');

const selectors = {
  ...categsAdapter.getSelectors(
    flow(
      categoriesStoreSelector,
      getEntities_new
    )
  ),
  getStatus: flow(
    categoriesStoreSelector,
    getStoreStatus
  )
};

export { fetchCategories, selectors, initialState };

export default categoriesSlice;
