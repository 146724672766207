import get from 'lodash/get';
import { getPlatformName } from '../../utils/platform';
import { ConceptFormConfig, InsightFormConfig } from '../config';

function getFormConfig(
  options = { insightForm: false, platformName: undefined }
) {
  const conf = options.insightForm ? InsightFormConfig : ConceptFormConfig;
  const platform = options.platformName || getPlatformName();
  return get(conf, platform, {});
}

function isFieldLabelCustomised(t, tKey) {
  const baseLabel = t(tKey);
  const label = t(tKey, { context: getPlatformName() });
  return baseLabel !== label;
}

function getLabelNamespace(options = { insightForm: false }) {
  return get(getFormConfig(options), 'labelContext', getPlatformName());
}

export default {
  getFormConfig,
  getLabelNamespace,
  isFieldLabelCustomised
};
