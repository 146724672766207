import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import React from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  isAdminSelector,
  ownProfileSelector
} from '../../../../profile/state/profile';
import { getIdeaConceptId } from '../../../selectors/ideaConcepts';
import { AlignedCellContent, TitleCellLabel } from '../ConceptTable.styled';
import useEditCommentLike from '../useEditCommentLike';
import useFetchCommentLike from '../useFetchCommentLike';
import useGetCommentLike from '../useGetCommentLike';
import useViewCommentLike from '../useViewCommentLike';
import CommentDisplay from './CommentDisplay';
import ExpandedCommentCell from './ExpandedCommentCell';
import ScrollAnchor from './ScrollAnchor';

function InternalCommentDisplay({
  conceptId,
  shouldFetchOnVisible = false,
  authorId,
  t
}) {
  const canShowComment = useSelector(isAdminSelector);
  const ownUserId = useSelector(ownProfileSelector).userId;
  const comment = useGetCommentLike('internalComment', conceptId, authorId);
  const fetch = useFetchCommentLike('internalComment', conceptId);
  const canEdit = !authorId || ownUserId === authorId;
  const [openEditor] = useEditCommentLike(
    'internalComment',
    conceptId,
    authorId,
    t
  );
  const openReadOnly = useViewCommentLike(conceptId, authorId);
  let onOpen = null;
  if (canEdit) {
    onOpen = openEditor;
  } else if (comment) {
    onOpen = openReadOnly;
  }
  return canShowComment ? (
    <CommentDisplay
      onVisible={shouldFetchOnVisible ? fetch : () => Promise.resolve()}
      onOpen={onOpen}
      text={get(comment, 'text')}
      canEdit={canEdit}
    />
  ) : null;
}

const TranslatedInternalCommentDisplay = translate()(InternalCommentDisplay);

const internalComments = {
  header: (t, scrollIx) => (
    <AlignedCellContent justifyContent="flex-start">
      {isNumber(scrollIx) && <ScrollAnchor index={scrollIx} />}
      <TitleCellLabel>
        {t('ideaConcepts/listPage/tableHeaders/internalComments')}
      </TitleCellLabel>
    </AlignedCellContent>
  ),
  main: (t, concept) => {
    return (
      <AlignedCellContent alignItems="flex-start" justifyContent="stretch">
        <TranslatedInternalCommentDisplay
          conceptId={getIdeaConceptId(concept)}
          shouldFetchOnVisible
        />
      </AlignedCellContent>
    );
  },
  expanded: (t, concept) => (
    <ExpandedCommentCell
      as={TranslatedInternalCommentDisplay}
      concept={concept}
    />
  )
};

export default internalComments;
