import React, { memo } from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { Field } from 'react-final-form';
import formControl from '@bit/be-novative.kit.form-control';
import RichText from '../../../common/components/RichText';
import {
  maxlength,
  required,
  composeValidators
} from '../../../utils/validation';
import { IDEA_CONCEPT_DETAILS_FIELD_MAXLENGTH } from '../../config';

const RichTextFormControl = formControl(RichText);
const maybeValidateRequired = (t, isRequired) =>
  isRequired ? required(t) : noop;

ConceptRichTextField.propTypes = {
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};
export function ConceptRichTextField({
  autoFocus,
  label,
  name,
  t,
  required,
  ...restOfProps
}) {
  return (
    <Field
      name={name}
      component={RichTextFormControl}
      maxLength={IDEA_CONCEPT_DETAILS_FIELD_MAXLENGTH}
      label={label}
      secondaryLabel={required ? null : t('common/(optional)')}
      validate={composeValidators(
        maybeValidateRequired(t, required),
        maxlength(t, IDEA_CONCEPT_DETAILS_FIELD_MAXLENGTH)
      )}
      required={required}
      autoFocus={autoFocus}
      {...restOfProps}
    />
  );
}

export default compose(
  memo,
  translate()
)(ConceptRichTextField);
