import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  color: #6b6b6b;
`;

export const Toolbar = styled.nav`
  display: flex;
  flex-wrap: wrap;
`;

export const Attrib = styled.span`
  display: flex;
  align-items: center;
  margin-right: 24px;
  margin-bottom: 1em;

  ${({ small }) => ({
    fontSize: small && '.8em'
  })}

  dt, dd {
    display: inline;
    margin-right: 5px;
    font-weight: normal;
  }

  button {
    padding: 0;
  }
`;

export const CloseWrapper = styled.span`
  position: absolute;
  z-index: 2;
  right: 6px;
  top: 24px;
`;

export const ConceptTitle = styled.header`
  margin: 1em auto 0.625em;
`;

export const ChallengeTitle = styled.aside`
  margin: 1em 0;
  padding: 12px;
  border: 1px solid #d9d9d9;
  font-size: 0.875em;
  background: #f3f3f3;
  color: #a0a0a0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  dt,
  dd {
    display: inline;
    margin-right: 3px;
  }
`;

export const Description = styled.main`
  margin: 1.5em 0 0;
  font-size: 0.875em;
  line-height: 1.5em;
`;

export const Team = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-right: 15px;
  }

  .TeamMember {
    border: 3px solid white;
  }
`;

export const OwnerWrapper = styled.div`
  display: inline-block;
  > .Heading {
    margin: 0;
    font-weight: 400;
    text-align: center;
  }
  h6 {
    transform: translateY(-1em);
  }
`;

export const OwnerAvatar = styled.span`
  > .OwnerAvatar {
    margin: 5px 0;
  }
`;

export const AddMemberBtn = styled.button`
  width: 32px;
  height: 32px;
  margin-top: -4px;
  padding: 0;
  border-radius: 100%;
  background: #d8d8d8;
`;
