import get from 'lodash/get';
import memoize from 'lodash/memoize';
import LocalStorage from 'store2';
import * as Sentry from '@sentry/browser';
import setUserContext from '../../telemetry/setUserContext';

export const TOKEN_KEY = 'jwt_token';
const LOG_CATEG = 'authentication';

export function getToken() {
  return LocalStorage.get(TOKEN_KEY);
}

export function setToken(token) {
  Sentry.addBreadcrumb({
    category: LOG_CATEG,
    message: 'Setting new token...',
    data: { token }
  });

  if (token && isTokenValid(token)) {
    Sentry.addBreadcrumb({
      category: LOG_CATEG,
      message: 'Token is valid'
    });
    LocalStorage.set(TOKEN_KEY, token, true);
    setTelemetryContextWithUser(getTokenData());
  } else {
    Sentry.addBreadcrumb({
      category: LOG_CATEG,
      level: Sentry.Severity.Warning,
      message: 'Token is invalid'
    });
    throw new Error('Invalid token');
  }
}

export function clearToken() {
  LocalStorage.remove(TOKEN_KEY);
  Sentry.addBreadcrumb({
    category: LOG_CATEG,
    message: 'User logout'
  });
  setTelemetryContextWithUser({ email: null, userId: null });
}

function setTelemetryContextWithUser({ email, userId }) {
  setUserContext(userId, email);
}

export function isTokenValid(token = getToken()) {
  if (!token) {
    return false;
  }

  try {
    return _temp_isTokenValid(decodeToken(token));
  } catch (error) {
    return false;
  }
}

// Temporary to handle difference between new and old API
// New API doesn't contain custom `expires` prop in payload,
// but the old API incorrectly sets `exp` to be the current time
function _temp_getExpiration(tokenData = {}) {
  if (_temp_isNewApi()) {
    return Number(get(tokenData, 'exp'));
  }
  return new Date(get(tokenData, ['payload', 'expires']));
}

function _temp_isTokenValid(tokenData) {
  const expiration = _temp_getExpiration(tokenData);
  let now = Date.now() / 1000;
  return expiration > now;
}

function _temp_isNewApi() {
  return (
    process.env.REACT_APP_VOYAGER_API_BASE_URL ===
    'https://api-eu.be-novative.dev/api'
  );
}

function decodeToken(token) {
  try {
    const [, rawPayload] = token.split('.');
    const { sub, ...otherData } = JSON.parse(atob(rawPayload));
    return {
      payload: JSON.parse(sub),
      ...otherData
    };
  } catch (e) {
    throw new Error('Cannot deserialize JWT token!');
  }
}

const decodeTokenMemo = memoize(decodeToken);

/**
 * @typedef {Object} Token
 * @property {string} email - User email
 * @property {string} userId - User ID
 * @property {string} copmanyId - Platform ID
 */

/**
 * Extracts user ID, email, and company ID from the token
 * @param {string} [token] - Encoded token to extract. By default, it fetches the token from local storage.
 * @return {Token} Token data or an empty object if token extraction fails
 */

export function getTokenData(token = getToken()) {
  try {
    const { payload } = decodeTokenMemo(token);

    return {
      email: get(payload, 'email'),
      userId: get(payload, 'userId'),
      companyId: get(payload, 'companyId')
    };
  } catch (error) {
    return {};
  }
}
