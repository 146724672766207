import forEach from 'lodash/forEach';
import isObject from 'lodash/isObject';

export default function flattenObject(obj) {
  const flattened = {};

  function flatten(obj, prevPath = '') {
    forEach(obj, (val, key) => {
      const newPath = `${prevPath}${key}`;
      if (isObject(val)) {
        flatten(val, `${newPath}.`);
      } else {
        flattened[newPath] = val;
      }
    });
  }

  flatten(obj);

  return flattened;
}
