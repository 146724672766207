import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchComments } from '../../state/internalComments';
import { fetchFeedback } from '../../state/reviewerFeedback';

const fetchAction = {
  internalComment: fetchComments,
  feedback: fetchFeedback
};

/**
 * Returns a fetch callback for the given comment type
 * @param {Function} t - Localisation function
 * @param {"internalComment" | "feedback"} commentType - Determines which endpoint will be called for fetching the comment
 * @param {string|number} conceptId - Parent concept ID
 * @returns {Function<Promise>}
 */
export default function useFetchCommentLike(commentType, conceptId) {
  if (!['internalComment', 'feedback'].includes(commentType))
    throw Error(`Invalid commentType: ${commentType}`);

  const dispatch = useDispatch();
  const fetch = useCallback(
    () => dispatch(fetchAction[commentType](conceptId)),
    [dispatch, conceptId, commentType]
  );
  return fetch;
}
