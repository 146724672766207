import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import './CheckboxButton.css';

class CheckboxButton extends Component {
  static propTypes = {
    input: PropTypes.shape({
      checked: PropTypes.bool,
      disabled: PropTypes.bool
    }),
    meta: PropTypes.object,
    children: PropTypes.node
  };

  handleChange = event => {
    const { onChange, value } = this.props.input;
    onChange(value, event.target.checked);
  };

  render() {
    const { input, children, theme } = this.props;
    const classes = classNames(theme.container, {
      [theme.checked]: input.checked,
      [theme.disabled]: input.disabled
    });

    return (
      <label className={classes}>
        <input
          {...input}
          className={theme.input}
          type="checkbox"
          onChange={this.handleChange}
        />
        {children}
      </label>
    );
  }
}

export default CheckboxButton;
