import React, { useCallback } from 'react';
import { translate } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FeatureFlag from '../../../featureFlags/constants';
import { getPlatformName } from '../../../utils/platform';
import { inlineHelpViewed } from '../../../common/actions/metrics';
import { openConceptEditor, openQuickConceptFormMetrics } from '../../actions';
import ModalRoot from '../../../common/containers/ModalRoot';
import SideOverlay from '../../../common/containers/SideOverlay';
import ConceptPreview from '../../containers/ConceptPreview';
import QuickConceptForm, {
  QuickConceptFormAmdocs
} from '../../containers/QuickConceptForm';
import Button from '@bit/be-novative.kit.button';
import BranchByFeature from '../../../common/components/BranchByFeature';
import FullScreen from '../../../common/components/FullScreen';
import Grid from '../../../common/components/Grid';
import Heading from '../../../common/components/Heading';
import HelpIcon from '../../../common/components/HelpIcon';
import GlobalPageHeader from '../../../common/components/GlobalPageHeader';
import Paragraph from '../../../common/components/Paragraph';
import ConceptList from '../../components/ConceptList';
import ConceptTeamEditor from '../../components/ConceptTeamEditor';
import {
  DESCRIBE_IDEA_CONCEPT_FORM,
  CONCEPT_PREVIEW,
  TEAM_EDITOR,
  IdeaConceptImplementationStatus,
  CONCEPT_COMMENT_EDITOR,
  CONCEPT_COMMENT_DISPLAY
} from '../../constants';
import { conceptListPage } from '../../paths';
import ConceptReviewerCommentModal from '../../containers/ConceptTable/ConceptReviewerCommentModal';
import CommentDisplayModal from '../../containers/ConceptTable/CommentDisplayModal';
import './IdeaConceptListPage.css';
import { isAmdocsVariant } from '../../../utils/variants';

const HELP_ID = 'ideaConcepts__concept_definition';

function IdeaConceptListPage({ t }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const onInfoClick = useCallback(() => dispatch(inlineHelpViewed(HELP_ID)), [
    dispatch
  ]);
  const handleConceptSubmitSuccess = useCallback(
    function handleConceptSubmitSuccess() {
      history.push(conceptListPage(IdeaConceptImplementationStatus.ACTIVE));
    },
    [history]
  );
  const openNewConceptModal = useCallback(() => {
    dispatch(
      openConceptEditor(null, {
        formTitle: t('ideaConcepts/create/form/title'),
        formSubmitLabel: t('ideaConcepts/create/form/submitCTA'),
        onSubmit: handleConceptSubmitSuccess
      })
    );
    dispatch(openQuickConceptFormMetrics());
  }, [dispatch, t, handleConceptSubmitSuccess]);

  return (
    <FullScreen>
      <GlobalPageHeader
        cta={
          <BranchByFeature feature={FeatureFlag.canUseInsights}>
            <Button
              variant="success"
              size="large"
              onClick={openNewConceptModal}>
              {t('ideaConcepts/listPage/createConceptCTA', {
                context: getPlatformName()
              })}
            </Button>
          </BranchByFeature>
        }
      />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col className="ConceptListPage__Intro">
            <Heading align="center">
              {t('ideaConcepts/listPage/title', { context: getPlatformName() })}
            </Heading>
            <Paragraph align="right">
              <HelpIcon
                description={t('ideaConcepts/tips/whatIsIC/description')}
                onClick={onInfoClick}>
                {t('ideaConcepts/tips/whatIsIC/title')}
              </HelpIcon>
            </Paragraph>
            <Paragraph align="center">
              {t('ideaConcepts/listPage/description')}
            </Paragraph>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <ConceptList />
      <ModalRoot
        modalComponents={{
          [CONCEPT_PREVIEW]: props => (
            <SideOverlay {...props}>
              <ConceptPreview />
            </SideOverlay>
          ),
          [CONCEPT_COMMENT_EDITOR]: ConceptReviewerCommentModal,
          [CONCEPT_COMMENT_DISPLAY]: CommentDisplayModal,
          [DESCRIBE_IDEA_CONCEPT_FORM]: isAmdocsVariant()
            ? QuickConceptFormAmdocs
            : QuickConceptForm,
          [TEAM_EDITOR]: ConceptTeamEditor
        }}
      />
    </FullScreen>
  );
}

export default translate()(IdeaConceptListPage);
