import flow from 'lodash/flow';
import get from 'lodash/get';
import isEqualFp from 'lodash/fp/isEqual';
import { createSelector } from 'reselect';
import {
  ChallengePhases,
  ChallengeScenario,
  ChallengeStatus,
  ChallengeVisiblity
} from '../../challenge/constants';
import { entityKeys } from '../../challenge/schemas';
import {
  getEntity,
  isEntityLoading,
  hasEntityFailed,
  getEntityMap
} from './entities';

export const getCategory = challenge => get(challenge, 'category');

export const getCategoryId = challenge => get(challenge, 'categoryId');

export const getChallengeId = challenge => get(challenge, 'challengeId', '');

export const getCloseTime = challenge => get(challenge, 'closesAt');

/**
 * Returns the criteria used for evaluating concepts generated from this challenge
 * @param {Challenge} challenge
 * @returns {IdeaConceptEvaluationCriterion[]}
 */

export const getConceptEvalCriteria = challenge =>
  get(challenge, 'ideaConceptEvaluationCriteria', []);

export const getCreator = challenge => get(challenge, 'challengeCreator', {});

export const getDescription = challenge => get(challenge, 'description');

export const getHashtags = challenge => get(challenge, 'hashtags', []);

export const getFacilitatedStatus = challenge =>
  get(challenge, 'isFacilitated');

export const getHighlightedStatus = challenge => get(challenge, 'highlighted');

export const getIdeaStats = challenge => get(challenge, 'ideaStats', []);

/**
 * Returns an array of ideation phase configuration objects.
 * @param {Challenge} challenge
 * @returns {IdeationPhase[]} An array of configuration objects, or an empty array.
 */

export const getIdeationPhases = challenge =>
  get(challenge, 'ideationPhases', []);

export const getIdeationTimeZone = challenge =>
  get(challenge, 'groupIdeationTimeZone');

export const getIdeationTime = challenge =>
  get(challenge, 'groupIdeationDateTime');

export const getImage = challenge => get(challenge, 'image', {});

export const getInvitedUsers = challenge => get(challenge, 'invitedUsers', []);

export const getLanguage = challenge => get(challenge, 'language');

export const getLoveCount = challenge => get(challenge, 'loveCount', -1);

export const getPermissions = challenge => get(challenge, 'permissions', {});

/**
 * Returns the challenge scenario setting that controls the app's behaviour regarding incognito, concept ownership etc.
 * @param {Challenge} challenge
 * @return {ChallengeScenario}
 */

export const getScenario = challenge =>
  get(challenge, 'scenario', ChallengeScenario.Default);

export const getStartPhase = challenge =>
  get(challenge, 'startPhase', ChallengePhases.IDEATION);

export const getStartTime = challenge => get(challenge, 'startsAt');

export const getStatus = challenge => get(challenge, 'status', '');

/**
 * @param {Challenge} challenge
 * @returns {string}
 */
export const getTitle = challenge => get(challenge, 'title', '');

/**
 * Returns the number of participants who shared at least 1 idea in ideation
 * @param {Challenge} challenge
 * @return {number} The number of participants or `-1` as fallback
 */

export const getIdeatorCount = challenge => get(challenge, 'ideatorCount', -1);

export const getTopIdeators = challenge => get(challenge, 'topIdeators', []);

export const getVisibility = challenge => get(challenge, 'visibility');

export const getXAxis = challenge => get(challenge, 'evaluationXAxis');

export const getYAxis = challenge => get(challenge, 'evaluationYAxis');

export const getRevealableStatus = challenge =>
  get(challenge, 'shouldRevealIdeas');

export const hasResults = challenge =>
  get(challenge, 'resultsAvailable', false);

export const isClosed = isEqualFp(ChallengeStatus.CLOSED);

export const isOpen = isEqualFp(ChallengeStatus.OPEN);

export const isAnnounced = isEqualFp(ChallengeStatus.ANNOUNCED);

export const isDeleted = isEqualFp(ChallengeStatus.DELETED);

export const isPrivate = isEqualFp(ChallengeVisiblity.PRIVATE);

export const isPublic = isEqualFp(ChallengeVisiblity.PUBLIC);

/**
 * @param {Challenge} challenge
 * @return {boolean} `true` if the challenge has a sensitive topic
 */

export const isSensitiveChallenge = challenge =>
  flow(
    getScenario,
    isEqualFp(ChallengeScenario.Sensitive)
  )(challenge);

export const isLovedByUser = challenge => get(challenge, 'loved');

/**
 * @param {string} challengeId
 * @returns {(state: import('redux').Store) => Challenge}
 */
export const getChallengeById = challengeId => state =>
  getEntity(entityKeys.CHALLENGE)(challengeId)(state);

export const makeGetChallengeById = () =>
  createSelector(
    getEntityMap(entityKeys.CHALLENGE),
    (_, challengeId) => challengeId,
    (entities, challengeId) => get(entities, challengeId)
  );

export const isChallengeLoading = isEntityLoading(entityKeys.CHALLENGE);

export const hasChallengeFailed = hasEntityFailed(entityKeys.CHALLENGE);

export const getChallengeTitle = challengeId => state =>
  flow(
    getChallengeById(challengeId),
    getTitle
  )(state);

export default {
  getChallengeById,
  getChallengeTitle,
  hasChallengeFailed
};
