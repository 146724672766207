import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Media.css';

Media.propTypes = {
  active: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right']),
  bodyAlign: PropTypes.oneOf(['middle', 'top', 'bottom']),
  children: PropTypes.node,
  hideOverflow: PropTypes.bool,
  mediaObject: PropTypes.node,
  onClick: PropTypes.func
};

Media.defaultProps = {
  align: 'left',
  bodyAlign: 'top',
  hideOverflow: true
};

function Media({
  active,
  align,
  bodyAlign,
  children,
  className,
  hideOverflow,
  mediaObject,
  onClick,
  to
}) {
  const classes = classNames('Media', className, {
    'Media--clickable': !!onClick,
    'Media--active': active,
    'Media--hideOverflow': hideOverflow,
    [`Media--align-${bodyAlign}`]: !!bodyAlign
  });
  const bodyClasses = classNames('Media__Body', {});
  const Tag = onClick || to ? Link : 'div';

  return (
    <Tag onClick={onClick} to={to} className={classes}>
      {align === 'left' && <div className="Media__Left">{mediaObject}</div>}
      <div className={bodyClasses}>{children}</div>
      {align === 'right' && <div className="Media__Right">{mediaObject}</div>}
    </Tag>
  );
}

export default Media;
