import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import AuthProvider from './authentication/components/AuthProvider';
import Alerts from './common/components/Alerts';
import CookieConsent from './common/components/CookieConsent';
import IntercomClient from './common/components/IntercomClient';
import { lightTheme } from './common/styled/colors.styled';
import history from './history';

export default function App({ i18n, store, router }) {
  return (
    <ThemeProvider theme={lightTheme}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <DndProvider backend={HTML5Backend}>
            <ConnectedRouter history={history}>
              <AuthProvider>
                {router}
                <Alerts />
                <CookieConsent />
                <IntercomClient />
              </AuthProvider>
            </ConnectedRouter>
          </DndProvider>
        </I18nextProvider>
      </Provider>
    </ThemeProvider>
  );
}
