import { CanvasIdProps } from '../../dndCanvas/constants';
import {
  MOVE_THROTTLE_MS,
  DRAGGABLE_ID_PROP,
  DRAGGABLE_TYPES,
  S_ID
} from '../constants';
import { normaliseMoveMsg } from '../utils/socketMsg/itemMovement';
import { moveIdeaMsg } from '../utils/socketMsg/ideas';
import { moveImageMsg } from '../utils/socketMsg/images';
import { moveWordMsg } from '../utils/socketMsg/words';
import {
  getMaxZIndexForType,
  getZIndexForItem,
  isItemMovedByUser
} from '../selectors';

export const ITEM_MOVED = 'ITEM_MOVED';
export const USER_MOVE_ITEM = 'USER_MOVE_ITEM';
export const USER_MOVE_ITEM_STOP = 'USER_MOVE_ITEM_STOP';

const { IDEA, IMAGE, WORD } = DRAGGABLE_TYPES;

export const updatePosition = (itemType, itemId, position) => {
  const { posX, posY, posZ } = position;
  return {
    type: ITEM_MOVED,
    payload: {
      posX,
      posY,
      posZ
    },
    meta: { itemType, itemId, canvasId: S_ID }
  };
};

const handleItemMoved = (response, itemType) => (dispatch, getState) => {
  const { itemId, posX, posY, posZ } = normaliseMoveMsg(response);

  if (!isItemMovedByUser(getState(), itemId)) {
    return dispatch(
      updatePosition(itemType, itemId, {
        posX,
        posY,
        posZ
      })
    );
  }
};

export const handleIdeaMoved = response => handleItemMoved(response, IDEA);

export const handleImageMoved = response => handleItemMoved(response, IMAGE);

export const handleWordMoved = response => handleItemMoved(response, WORD);

// TODO adjust type annotation

/**
 * @typedef ItemMovementData
 * @property {string} id - ID of the moved item
 * @property {string} type - internal draggable type of the moved item
 * @property {number} posX - X coordinate of the item's new position
 * @property {number} posY - Y coordinate of the item's new position
 * @property {boolean} [dropped] - Whether this is the last move message
 * @todo Annotate `type` with dndCanvas draggable type enum
 */

/**
 * Sends item movement message to the API
 * @param {ItemMovementData} movementData - Description of the current movement
 */

export const moveObject = ({ id, type, posX, posY, dropped }) => (
  dispatch,
  getState
) => {
  let idProp, moveMsg;

  switch (type) {
    case DRAGGABLE_TYPES.IDEA:
      idProp = CanvasIdProps.Idea;
      moveMsg = moveIdeaMsg;
      break;
    case DRAGGABLE_TYPES.IMAGE:
      idProp = CanvasIdProps.Image;
      moveMsg = moveImageMsg;
      break;
    case DRAGGABLE_TYPES.WORD:
      idProp = DRAGGABLE_ID_PROP.WORD;
      moveMsg = moveWordMsg;
      break;
    default:
  }

  if (!idProp) {
    return;
  }

  const ownZ = getZIndexForItem(type, id)(getState());

  if (dropped) {
    const highestZ = getMaxZIndexForType(type)(getState());
    const posZ = ownZ === highestZ ? ownZ : highestZ + 1;

    dispatch(
      updatePosition(type, id, {
        posX,
        posY,
        posZ
      })
    );
    setTimeout(
      () =>
        dispatch({
          type: USER_MOVE_ITEM_STOP,
          payload: null,
          meta: { canvasId: S_ID, itemId: id }
        }),
      MOVE_THROTTLE_MS
    );
    return;
  }

  if (!moveMsg) {
    return;
  }

  const moveAction = moveMsg(id, posX, posY, ownZ);

  dispatch({
    ...moveAction,
    meta: {
      ...moveAction.meta,
      throttle: MOVE_THROTTLE_MS,
      canvasId: S_ID
    }
  });

  // Prevent move animation of element being dragged
  setTimeout(
    () =>
      dispatch({
        type: USER_MOVE_ITEM,
        payload: null,
        meta: { canvasId: S_ID, itemId: id }
      }),
    MOVE_THROTTLE_MS
  );
};
