import { HubConnectionState } from '@microsoft/signalr';

/**
 * Represents an API region to which the UI connects during runtime.
 * This setting is queried via the `apiRegion` feature flag.
 * @enum {string} ApiRegion
 * @readonly
 * @see FeatureFlag
 */
export const ApiRegion = {
  EU: 'EU',
  US: 'US'
};

// API symbols
export const VOYAGER_API = Symbol('Voyager API');
export const IDENTITY_API = Symbol('Identity API');
export const SEARCH_API = Symbol('Search API');

/**
 * @enum {string} RequestPaginationHeaderNames
 * @summary Header names are capitalised because they are used for requests only
 */
export const RequestPaginationHeaderNames = {
  /** Indicates 0-based index of requested page */
  Page: 'X-PAGE'
};

/**
 * @enum {string} ResponsePaginationHeaderNames
 * @summary Header names are lower-case because they are used for responses only
 * @readonly
 */
export const ResponsePaginationHeaderNames = {
  /** Indicates 0-based current page index */
  Current: 'x-current-page',
  /** Indicates 0-based last page index */
  Last: 'x-last-page',
  /** Indicates 0-based next page index */
  Next: 'x-next-page',
  /** Indicates 0-based previous page index */
  Prev: 'x-prev-page'
};

/**
 * @enum {string} SortHeaderNames
 * @summary Header names can be mix-cased because they are used for both requests and responses
 * @readonly
 */
export const SortHeaderNames = {
  SortBy: 'x-sort-by',
  SortDirection: 'x-order-direction'
};

/**
 * @enum {string} SortDirection
 * @readonly
 */
export const SortDirection = {
  Asc: 'ASC',
  Desc: 'DESC'
};

/**
 * Initial state for pagination reducers
 * @type {PaginationData}
 * @readonly
 */
export const InitialPaginationConfig = {
  currentPage: 0,
  prevPage: -1,
  lastPage: -1,
  nextPage: -1
};

/**
 * Constants for communicating errors within the app. Not intended for API endpoints.
 * @enum {string} CommonException
 * @readonly
 */
export const CommonException = {
  /** The UI sent an invalid request to the API */
  BadRequest: 'BadRequest',
  /** Marks a general or unknown error */
  General: 'General',
  /** Marks a network error, such as offline status or cancelled/blocked requests */
  NetworkError: 'NetworkError',
  /** The entity/item is not found */
  NotFound: 'NotFound',
  /** Marks an error within the API  */
  ServerError: 'ServerError',
  /** The request timed out */
  Timeout: 'Timeout',
  /** The user is not logged in */
  Unauthenticated: 'Unauthenticated',
  /** The user doesn't have permission to perform the task */
  Unauthorized: 'Unauthorized'
};

// Web-socket

export const SIGNALR = 'signalr';

export const SOCKET_ID = {
  CONCEPT_CANVAS: 'CONCEPT_CANVAS',
  IDEATION: 'IDEATION',
  NOTIFICATION: null
};

export const SOCKET_PATH = {
  CONCEPT_CANVAS: 'signalr/canvas',
  IDEATION: 'signalr'
};

export const SOCKET_EVENTS = {
  CONNECTED: HubConnectionState.Connected,
  CONNECTING: HubConnectionState.Connecting,
  DISCONNECTED: HubConnectionState.Disconnected,
  RECONNECTING: HubConnectionState.Reconnecting,
  ERROR: 'ERROR'
};

// Config

export const TIME_TILL_NETWORK_ERRORMSG_MS = 25000;
export const RECONNECTED_MESSAGE_TIMEOUT_MS = 1500;

export const FILE_UPLOAD_TIMEOUT_MS = 60000;
export const HTTP_TIMEOUT_MS = 25000;
export const SOCKET_TIMEOUT_MS = 15000;

export const API_QUERY_DEBOUNCE = 150;
export const API_QUERY_STRING_MINLENGTH = 3;

/** Users shouldn't be logged out when receiving 401 from these paths */
export const UNAUTH_PATH_WHITELIST = ['login', 'password'];
