import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import findKey from 'lodash/findKey';
import { isCepheidVariant } from '../../../../utils/variants';
import {
  conceptListStageFilterIds,
  conceptListStageFilterIdsCepheid
} from '../../../constants';

const stageFilters = isCepheidVariant()
  ? conceptListStageFilterIdsCepheid
  : conceptListStageFilterIds;

export default function findFilterPathParam(filterId) {
  return findKey(stageFilters, conceptStatus => {
    if (isArray(conceptStatus)) {
      return includes(conceptStatus, filterId);
    } else {
      return conceptStatus === filterId;
    }
  });
}
