import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import isEqualFp from 'lodash/fp/isEqual';
import filteredReducerCall from '../../utils/filteredReducerCall';

/**
 * A filter utility that only calls the reducer if the action targets the correct socket ID.
 * @param {string} canvasId - Unique ID that will be compared to the action's `meta.canvasId` value. (This doesn't have to be a valid canvas type.)
 * @param {Function} reducer - Reducer function to be called if the `canvasId` matches
 * @returns {Function<import('redux').Store>}
 */

export default function filterActionByCanvasId(canvasId, reducer) {
  return filteredReducerCall(
    flow(
      getFp(['meta', 'canvasId']),
      isEqualFp(canvasId)
    ),
    reducer
  );
}
