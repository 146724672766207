import * as Sentry from '@sentry/browser';
import appInsights from './monitoring';

/**
 * Sets user details for telemetry tools
 * @param {string} userId - ID of logged in user
 * @param {string} email - Account email of logged in user
 */

export default function setUserContext(userId, email) {
  Sentry.setUser({ email, id: userId });
  appInsights.setAuthenticatedUserContext(userId, null, true);
}
