import produce from 'immer';
import conceptReviewApi from '../api/conceptReviewApi';
import commentSliceFactory from './commentSliceFactory';

const { slice, actions, selectors } = commentSliceFactory({
  sliceName: 'internalComments',
  entityLabel: 'InternalComment',
  editFn: conceptReviewApi.editInternalCommentForConcept,
  fetchFn: conceptReviewApi.fetchInternalCommentsForConcept,
  entityPropNormalizerFn: produce(comment => {
    const text = comment.internalComment;
    comment.text = text;
    delete comment.internalComment;
  })
});
const { fetchComments, editComment } = actions;

const { getGroupCommentByConceptId, getUserCommentForConcept } = selectors;

export {
  fetchComments,
  editComment,
  getGroupCommentByConceptId,
  getUserCommentForConcept
};
export default slice;
