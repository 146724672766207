import React from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { compose, lifecycle, mapProps, withProps } from 'recompose';
import classNames from 'classnames';
import svg4everybody from 'svg4everybody';
import spriteUrl from './symbol/sprite.symbol.svg';
import { isIE } from '../../../utils/userAgent';
import { withTooltip } from '../Tooltip';
import './Icon.css';

const colors = ['danger', 'info', 'success', 'warning', 'neutral', 'light'];

const types = [
  'account',
  'airplane',
  'angle-down',
  'angle-up',
  'angle-left',
  'angle-right',
  'anonymous',
  'arrow-up',
  'atom',
  'attachment',
  'bin',
  'bold',
  'box-3d',
  'branching',
  'bubble',
  'bubble-square',
  'bulb',
  'calendar',
  'camera',
  'caution',
  'chain',
  'chalice',
  'chat',
  'chat-2',
  'check',
  'check-circle',
  'circle',
  'clock',
  'cog',
  'coordinate-system',
  'costs',
  'cross',
  'date',
  'dnd',
  'download',
  'email',
  'exit',
  'expand',
  'experiment',
  'facebook',
  'feed',
  'file',
  'fire',
  'forward-framed',
  'forward',
  'galaxy',
  'glasses',
  'globe',
  'google',
  'graduation_cap',
  'group-ideas',
  'head',
  'heart-full',
  'heart-ideation',
  'heart',
  'help',
  'hidden',
  'highlight',
  'highlight-2',
  'home',
  'hummingbird',
  'idea',
  'ideas',
  'image',
  'info',
  'info2',
  'italic',
  'language',
  'leaderboard',
  'lightbulb',
  'link',
  'linkedin',
  'list-ol',
  'list-ul',
  'lock',
  'lock-circle',
  'marker',
  'medal',
  'menu',
  'merge-ideas',
  'meter',
  'monitor',
  'network',
  'notifications',
  'pen',
  'pencil-2',
  'pencil',
  'plus',
  'polygon-1',
  'polygon-2',
  'private',
  'projectboard',
  'reload',
  'return',
  'rewind',
  'rocket',
  'search',
  'settings',
  'settings-horizontal',
  'share',
  'stackoverflow',
  'stat',
  'subscribe',
  'success',
  'sunglasses',
  'team',
  'teams',
  'twitter',
  'underline',
  'unlink',
  'unlock',
  'upload',
  'zoom-in'
];

function Icon({
  type,
  className,
  children,
  onClick,
  size,
  color,
  wrapperClass,
  ...rest
}) {
  const wrapperClasses = classNames('IconWrapper', wrapperClass, {
    'Icon--clickable': onClick
  });
  const iconClass = classNames('Icon', `Icon--${type}`, className, {
    [`Icon--${size}`]: !!size,
    [`Icon--color-${color}`]: !!color
  });

  return (
    <span className={wrapperClasses} onClick={onClick} {...rest}>
      <svg className={iconClass} xmlns="http://www.w3.org/2000/svg">
        <use xlinkHref={`${spriteUrl}#icon--${type}`} />
      </svg>
      {children}
    </span>
  );
}

export const ICON_SIZES = ['xs', 'sm', 'md', 'lg', 'xl'];

Icon.propTypes = {
  color: PropTypes.oneOf(colors),
  type: PropTypes.oneOf(types).isRequired,
  size: PropTypes.oneOf(ICON_SIZES),
  className: PropTypes.string
};

const checkIfIE = withProps({
  shouldFallBackToIE: isIE()
});

export const withSVGFallbackForIE = {
  componentDidMount() {
    if (this.props.shouldFallBackToIE) {
      svg4everybody();
    }
  },
  componentDidUpdate(prevProps) {
    const { type, shouldFallBackToIE } = this.props;
    if (shouldFallBackToIE && type !== prevProps.type) {
      svg4everybody();
    }
  }
};

const removeIEProp = mapProps(props =>
  omit(props, ['shouldFallBackToIE', 'dispatch'])
);

export default compose(
  withTooltip,
  checkIfIE,
  lifecycle(withSVGFallbackForIE),
  removeIEProp
)(Icon);
export { Icon, types };
