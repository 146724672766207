import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './FullScreen.css';

function FullScreen({ type, children, padding, className, style }) {
  const classes = classNames(
    'FullScreen',
    `FullScreen--${type}`,
    `FullScreen--padding-${padding}`,
    className
  );

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
}

FullScreen.propTypes = {
  type: PropTypes.oneOf(['default', 'overview', 'form', 'result', 'light']),
  padding: PropTypes.oneOf(['none'])
};

FullScreen.defaultProps = {
  type: 'default'
};

export default FullScreen;
