import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { RightSlideTransition, Backdrop } from '../../components';
import { closeModal } from '../../actions/modal';
import { intercomDimensions } from '../../styled/variables.styled';

const ANIM_SPEED = 300;
const CHILD_STYLE = {
  position: 'relative',
  width: '100%',
  flexGrow: 1,
  overflowY: 'auto'
};
const DEFAULT_BG_COLOR = 'white';
const Z_INDEX = 10500;
const { vPadding, height } = intercomDimensions;

const Overlay = styled.aside`
  position: fixed;
  z-index: ${Z_INDEX};
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 565px;
  max-width: 90em;
  box-shadow: -5px 1px 17px 0 rgba(128, 128, 128, 0.5);
  padding: 24px 24px ${vPadding + height}px 24px;
  color: #6b6b6b;
  ${({ bgColor }) => ({
    background: bgColor
  })}
`;

SideOverlay.propTypes = {
  bgColor: PropTypes.string,
  isActive: PropTypes.bool
};
SideOverlay.defaultProps = {
  bgColor: DEFAULT_BG_COLOR
};
export function SideOverlay({ bgColor, children, closeModal, ...childProps }) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(true);
  }, []);

  const handleClose = () => {
    setActive(false);
    setTimeout(closeModal, ANIM_SPEED);
  };

  return (
    <>
      <Backdrop visible={active} onClick={handleClose} type="light" />
      <TransitionGroup>
        {active && (
          <RightSlideTransition speed={ANIM_SPEED}>
            <Overlay bgColor={bgColor}>
              {cloneElement(children, {
                ...childProps,
                onClose: handleClose,
                style: CHILD_STYLE
              })}
            </Overlay>
          </RightSlideTransition>
        )}
      </TransitionGroup>
    </>
  );
}

const mapDispatchToProps = {
  closeModal
};

export default connect(
  null,
  mapDispatchToProps
)(SideOverlay);
