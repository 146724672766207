import get from 'lodash/get';
import getOrFp from 'lodash/fp/getOr';

/**
 * @function getCountry
 * @param {OwnProfilePayload} ownProfile - The user's profile model
 * @return {string} The 2-letter country code
 */

export const getCountry = profile => get(profile, 'countryCode', '');

/**
 * @function getDescription
 * @param {OwnProfilePayload} ownProfile - The user's profile model
 * @return {string} The user's introduction as stringified HTML
 */

export const getDescription = profile => get(profile, 'aboutMe');

/**
 * @function getLinkedinUrl
 * @param {OwnProfilePayload} ownProfile - The user's profile model
 * @return {string}
 */

export const getLinkedinUrl = profile => get(profile, 'linkedInUrl', '');

/**
 * @function getWebsite
 * @param {OwnProfilePayload} ownProfile - The user's profile model
 * @return {string}
 */

export const getWebsite = profile => get(profile, 'websiteUrl', '');

/**
 * @function getWorkPosition
 * @param {OwnProfilePayload} ownProfile - The user's profile model
 * @return {string}
 */

export const getWorkPosition = profile => get(profile, 'position', '');

/**
 * @function getWorkplace
 * @param {OwnProfilePayload} ownProfile - The user's profile model
 * @return {string}
 */

export const getWorkplace = profile => get(profile, 'workplace', '');

/**
 * @function getRegisterDate
 * @param {OwnProfilePayload} ownProfile - The user's profile model
 * @return {TimestampUTC} A timestamp in the format of `YYYY-MM-DDTHH:MM:SSZ`, for example: `"2018-03-08T15:37:22.01Z"`
 */

export const getRegisterDate = profile => get(profile, 'registeredAt');

/**
 * @function getUserConsent
 * @param {OwnProfilePayload} ownProfile - The user's profile model
 * @return {UserConsentMap} The user's map of GDPR consent booleans
 */

export const getUserConsent = getOrFp({}, 'userConsent');

/**
 * Checks if the user is a platform admin
 * @param {OwnProfilePayload} ownProfile - The user's profile model
 * @return {Boolean} `true` if the user is a platform admin, `false` otherwise
 */

export const getAdminStatus = getOrFp(false, 'companyAdmin');
