const FILENAME_REGEX = /filename=["|'](.*)["|']/;

// TODO move to api folder
export default function getFilenameFromAjaxHeader(headerString) {
  if (typeof headerString !== 'string') {
    return '';
  }

  const match = headerString.match(FILENAME_REGEX);

  return match ? match[1] : '';
}
