import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';
import includes from 'lodash/fp/includes';
import isFunction from 'lodash/isFunction';
import union from 'lodash/fp/union';
import without from 'lodash/fp/without';
import cn from 'classnames';
import Checkbox from '@bit/be-novative.kit.checkbox';
import './CheckboxGroup.css';

class CheckboxGroup extends Component {
  static propTypes = {
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        disabled: PropTypes.bool,
        label: PropTypes.string
      })
    ).isRequired,
    input: PropTypes.object,
    layout: PropTypes.oneOf(['horizontal', 'vertical']),
    meta: PropTypes.object
  };

  static defaultProps = {
    input: {
      onChange: null,
      onBlur: null
    },
    layout: 'vertical'
  };

  handleChange = event => {
    const { onChange, value: inputValue } = this.props.input;
    const { value, checked } = event.target;

    if (!isFunction(onChange)) {
      console.warn('input.onChange is not a function, skipping');
      return;
    }

    const activeCheckboxes = checked
      ? union([value], inputValue || [])
      : without([value], inputValue);
    const currentChange = { [value]: checked };

    onChange(activeCheckboxes, currentChange);
  };

  handleBlur = () => {
    const { input } = this.props;

    if (isFunction(input.onBlur)) {
      input.onBlur(input.value);
    } else {
      console.warn('input.onBlur is not a function, skipping');
    }
  };

  render() {
    const { input, meta, choices, className, layout } = this.props;

    return (
      <ul className={cn(`CheckboxGroup--${layout}`, className)}>
        {choices.map(choice => (
          <li key={choice.value}>
            <Checkbox
              meta={meta}
              children={choice.label}
              input={{
                ...input,
                onChange: this.handleChange,
                onBlur: this.handleBlur,
                value: choice.value,
                checked: includes(choice.value)(get(input, 'value', [])),
                disabled: choice.disabled
              }}
            />
          </li>
        ))}
      </ul>
    );
  }
}

export default CheckboxGroup;
