import React from 'react';
import cn from 'classnames';
import { alignType } from '../../types';
import './Paragraph.css';

function Paragraph({ align, className, ...props }) {
  return (
    <p
      {...props}
      className={cn('Paragraph', className, {
        [`Paragraph--${align}`]: !!align
      })}
    />
  );
}

Paragraph.propTypes = {
  align: alignType
};

Paragraph.defaultProps = {
  align: null
};

export default Paragraph;
