import get from 'lodash/get';
import BitSet from 'bitset.js';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ownProfileSelector } from '../../profile/state/profile';
import { activeModalSelector } from './modal';
import { ONBOARDING_ENTER, ONBOARDING_LEAVE } from '../../constants';

const tips = handleActions(
  {
    [ONBOARDING_ENTER](state, { payload }) {
      return payload;
    },
    [ONBOARDING_LEAVE]() {
      return null;
    }
  },
  null
);

export default combineReducers({
  tips
});

export const getOnboardingBitSet = createSelector(
  ownProfileSelector,
  profile => {
    const onboardingHex = get(profile, 'onboarding');

    if (onboardingHex) {
      return BitSet.fromHexString(onboardingHex);
    }
  }
);

export const onboardingTipsSelector = state =>
  get(state, 'onboarding.tips', []);

export const onboardingTipSelector = tip =>
  createSelector(
    getOnboardingBitSet,
    flags => flags && flags.get(tip) === 0
  );

export const isOnboardingTipActiveSelectorFactory = tip =>
  createSelector(
    onboardingTipsSelector,
    getOnboardingBitSet,
    activeModalSelector,
    (tips, flags, activeModal) => {
      if (!tips || !flags || activeModal) {
        return null;
      }
      const active = tips.find(tip => flags.get(tip) === 0);
      return active === tip;
    }
  );
