import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../common/actions/modal';
import { CONCEPT_COMMENT_DISPLAY } from '../../constants';
import {
  getGroupFeedbackByConceptId,
  getUserFeedbackForConcept
} from '../../state/reviewerFeedback';

export default function useViewCommentLike(conceptId, authorId) {
  const commentSelector = useMemo(
    () => (authorId ? getUserFeedbackForConcept : getGroupFeedbackByConceptId),
    [authorId]
  );
  const comment = useSelector(state =>
    commentSelector(state, conceptId, authorId)
  );
  if (
    authorId === '93f47af1-d8ed-ea11-8b03-281878328045' &&
    conceptId === '1bd1f795-2471-eb11-b566-a085fc482136'
  ) {
    console.log('useViewCommentLike', { conceptId, authorId, comment });
  }
  const dispatch = useDispatch();
  const openEditor = useCallback(
    () =>
      dispatch(
        openModal({
          modalType: CONCEPT_COMMENT_DISPLAY,
          modalProps: {
            conceptId,
            authorId,
            comment,
            size: 'large'
          }
        })
      ),
    [authorId, comment, conceptId, dispatch]
  );
  return openEditor;
}
