import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import './Close.css';

class Close extends Component {
  static propTypes = {
    className: PropTypes.string,
    inverse: PropTypes.bool,
    onClick: PropTypes.func.isRequired
  };

  static defaultProps = {
    inverse: false
  };

  render() {
    const { children, className, iconClassName, inverse, onClick } = this.props;
    const classes = classNames(className, 'Close', {
      'Close--inverse': inverse
    });
    const iconClasses = classNames(iconClassName, 'Close__Icon');

    return (
      <button
        type="button"
        onClick={onClick}
        className={classes}
        aria-label="Close">
        {children}
        <span className={iconClasses} aria-hidden="true">
          &times;
        </span>
      </button>
    );
  }
}

export default Close;
