import size from 'lodash/size';
import {
  composeValidators,
  required,
  maxlength,
  validateInvitedUsers
} from '../../utils/validation';
import {
  IDEA_CONCEPT_DETAILS_FIELD_MAXLENGTH,
  IDEA_CONCEPT_TITLE_FIELD_MAXLENGTH
} from '../config';

export const matchesMinItems = (collection, minItems) =>
  size(collection) >= minItems;

export const validateConceptTitle = (
  t,
  charLimit = IDEA_CONCEPT_TITLE_FIELD_MAXLENGTH
) => composeValidators(required(t), maxlength(t, charLimit));

export const validateConceptDescription = (
  t,
  charLimit = IDEA_CONCEPT_DETAILS_FIELD_MAXLENGTH
) => composeValidators(required(t), maxlength(t, charLimit));

export const validateTeamMembers = (t, { minMembers, emailRegex }) =>
  composeValidators(validateInvitedUsers(t, emailRegex), members =>
    matchesMinItems(members, minMembers)
      ? undefined
      : t('ideaConcepts/validationErrors/teamMinimum', {
          count: minMembers
        })
  );
