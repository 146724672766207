import { normalize, schema } from 'normalizr';
import flow from 'lodash/flow';
import { createAction } from 'redux-actions';
import sanitizeForJson from '@bit/be-novative.kit.sanitize-for-json';
import { VOYAGER_API } from '../../api/constants';
import { asyncActionType } from '../../utils';
import { entities } from '../constants';
import {
  getApiResponseData,
  getEmbeddedItems,
  getHalSelfLink,
  getIdFromHref
} from '../../api/utils/apiResponse';
import { FETCH_SCHEMA_DATA } from '../../constants';
import schemas from '../../schemas';
import { updateEntity } from '../../common/actions/entities';

const EMBEDDED_KEY = '_embedded';

const conceptPostSchema = new schema.Entity(
  entities.CONCEPT_POST,
  {
    [EMBEDDED_KEY]: {
      user: schemas.USER
    }
  },
  {
    idAttribute: value =>
      flow(
        getHalSelfLink,
        getIdFromHref
      )(value)
  }
);

const headers = {
  Accept: 'application/hal+json'
};

export const FETCH_CONCEPT_POSTS = asyncActionType('FETCH_CONCEPT_POSTS');
export const STORE_CONCEPT_POST_IDS = 'STORE_CONCEPT_POST_IDS';
export const fetchPosts = ideaConceptId => async dispatch => {
  const response = await dispatch({
    [VOYAGER_API]: {
      url: `/ideaconcepts/${ideaConceptId}/activities`,
      method: 'GET',
      headers,
      type: FETCH_CONCEPT_POSTS
    }
  });
  const normalisedResult = flow(
    getApiResponseData,
    getEmbeddedItems,
    items => normalize(items, [conceptPostSchema])
  )(response);

  dispatch({
    type: FETCH_SCHEMA_DATA.SUCCESS,
    payload: normalisedResult,
    meta: {
      entityType: entities.CONCEPT_POST
    }
  });

  dispatch({
    type: STORE_CONCEPT_POST_IDS,
    payload: normalisedResult.result
  });
  return response;
};

export const SUBMIT_CONCEPT_POST = asyncActionType('SUBMIT_CONCEPT_POST');
export const submitPost = (ideaConceptId, text) => dispatch =>
  dispatch({
    [VOYAGER_API]: {
      url: `/ideaconcepts/${ideaConceptId}/activities`,
      method: 'POST',
      headers,
      type: SUBMIT_CONCEPT_POST,
      data: { description: sanitizeForJson(text) }
    }
  });

export const EDIT_CONCEPT_POST = asyncActionType('EDIT_CONCEPT_POST');
export const editPost = (ideaConceptId, postId, text) => dispatch =>
  dispatch({
    [VOYAGER_API]: {
      url: `/ideaconcepts/${ideaConceptId}/activities/${postId}`,
      method: 'PUT',
      type: EDIT_CONCEPT_POST,
      headers,
      data: { description: sanitizeForJson(text) }
    }
  }).then(() => {
    dispatch(
      updateEntity(entities.CONCEPT_POST, postId)({ description: text })
    );
  });

export const saveConceptPost = (updatedText, postId, ideaConceptId) =>
  postId
    ? editPost(ideaConceptId, postId, updatedText)
    : submitPost(ideaConceptId, updatedText);

export const DELETE_CONCEPT_POST = asyncActionType('DELETE_CONCEPT_POST');
export const deleteConceptPost = (ideaConceptId, postId) => dispatch =>
  dispatch({
    [VOYAGER_API]: {
      url: `/ideaconcepts/${ideaConceptId}/activities/${postId}`,
      method: 'DELETE',
      type: DELETE_CONCEPT_POST,
      postId
    }
  });

export const FLUSH_CONCEPT_POST_LIST = 'FLUSH_CONCEPT_POST_LIST';
export const flushConceptPosts = createAction(FLUSH_CONCEPT_POST_LIST);
