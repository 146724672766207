import React, { useCallback, useState } from 'react';
import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import produce from 'immer';
import formControl from '@bit/be-novative.kit.form-control';
import { parseEditorState } from '@bit/be-novative.kit.richtext-utils';
import formConfig from '../../utils/formConfig';
import { conceptEditorFormFields } from '../../constants';
import { closeModal } from '../../../common/actions/modal';
import { platformDataSelector } from '../../../common/state/platform';
import { getAllowedEmailDomains } from '../../../common/getters/platform';
import { getConceptFormModelById } from '../../selectors/ideaConcepts';
import withModal from '../../../common/containers/withModal';
import Button from '@bit/be-novative.kit.button';
import Callout from '../../../common/components/Callout';
import CategorySelector from '../../../common/components/CategorySelector';
import Panel from '../../../common/components/Panel';
import Paragraph from '../../../common/components/Paragraph';
import ConceptImageInput from '../../components/ConceptImageInput';
import ConceptRichTextField from '../../components/ConceptRichTextField';
import ConceptTitleInput from '../../components/ConceptTitleInput';
import ConceptDescriptionInput from '../../components/ConceptDescriptionInput';
import useSubmitQuickConceptForm from '../../hooks/useSubmitQuickConceptForm';
import { isCepheidVariant } from '../../../utils/variants';
import translateApiErrorMessage from '../../../utils/translateApiErrorMessage';
import VisibilityInputs from './VisibilityInputs';
import './QuickConceptForm.css';

const BASE_CLASS = 'QuickConceptForm';
const ControlledConceptImageInput = formControl(ConceptImageInput);

const {
  TITLE,
  IDEA_TEXT,
  CATEGORY_ID,
  TEAM,
  BENEFITS,
  PLANNED_ACTIVITIES,
  COSTS,
  IS_PRIVATE,
  IMAGE
} = conceptEditorFormFields;

QuickConceptFormAmdocs.propTypes = {
  expanded: PropTypes.bool,
  ideaConceptId: PropTypes.string
};
export function QuickConceptFormAmdocs({
  customFormPayload,
  expanded,
  focusedField = TITLE,
  formTitle,
  ideaConceptId,
  onSuccess = () => {},
  t
}) {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const dispatch = useDispatch();
  const closeEditor = useCallback(
    function closeEditor() {
      dispatch(closeModal());
    },
    [dispatch]
  );
  const handleSuccess = useCallback(
    function handleSuccess() {
      onSuccess();
      closeEditor();
    },
    [closeEditor, onSuccess]
  );
  const createConcept = useSubmitQuickConceptForm({ onSuccess: handleSuccess });

  const title = formTitle || t('ideaConcepts/subpage/describeForm/formTitle');
  const config = formConfig.getFormConfig({ insightForm: true });
  const labelContext = formConfig.getLabelNamespace({ insightForm: true });
  const alwaysShowVisibilityInputs = focusedField === TEAM;
  const emailRegex = useSelector(
    flow(
      platformDataSelector,
      getAllowedEmailDomains
    )
  );
  const defaultFormModel = useSelector(getConceptFormModelById(ideaConceptId));
  const formModel = customFormPayload
    ? normaliseFormModel(customFormPayload)
    : defaultFormModel;

  return (
    <Panel
      size="large"
      header={title}
      headerTag="h4"
      headerClass={`${BASE_CLASS}__Header`}
      bodyClass={`${BASE_CLASS}__Body`}
      rounded
      className={BASE_CLASS}>
      {/*
            keepDirtyOnReinitialize
            formModel.invitedUsers array contains invitee objects created on the fly from user objects
            array reference is not the same, and updating the field would reinit the form
          */}
      <Form
        onSubmit={createConcept}
        initialValues={formModel}
        keepDirtyOnReinitialize={true}
        data-test="form">
        {({ handleSubmit, submitting, submitError, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={`${BASE_CLASS}__Fields`}>
              <ConceptTitleInput
                name={TITLE}
                placeholder={t('ideaConcept/prop/title/placeholder')}
                autoFocus={focusedField === TITLE}
                layout="horizontal"
                data-test="title"
              />

              <ConceptDescriptionInput
                name={IDEA_TEXT}
                placeholder={t('ideaConcept/richTextPlaceholder')}
                autoFocus={focusedField === IDEA_TEXT}
                layout="horizontal"
                data-test="desc"
              />

              <CategorySelector
                name={CATEGORY_ID}
                showAllCategs={!isCepheidVariant()}
                label={t('common/prop/category/label')}
                layout="horizontal"
                data-test="categ"
              />

              {alwaysShowVisibilityInputs && (
                <VisibilityInputs
                  emailRegex={emailRegex}
                  labelContext={labelContext}
                  teamMinLength={
                    get(config, [TEAM, 'minLength']) ||
                    Number(Boolean(values[IS_PRIVATE]))
                  }
                  autoFocus={focusedField === TEAM}
                />
              )}

              <Panel
                header={
                  <Paragraph align="center">
                    {!isExpanded && !config.forceExpanded && (
                      <Button
                        variant="link"
                        onClick={() => setIsExpanded(true)}
                        data-test="expand">
                        {t('ideaConcepts/subpage/describeForm/expandCTA')}
                      </Button>
                    )}
                    <br />
                    <small>
                      {t('ideaConcepts/subpage/describeForm/expandHint')}
                    </small>
                  </Paragraph>
                }
                collapsible={!config.forceExpanded}
                expanded={isExpanded}
                shadowed={false}>
                {!alwaysShowVisibilityInputs && (
                  <VisibilityInputs
                    emailRegex={emailRegex}
                    labelContext={labelContext}
                    teamMinLength={
                      get(config, [TEAM, 'minLength']) ||
                      Number(Boolean(values[IS_PRIVATE]))
                    }
                  />
                )}

                <Field
                  name={IMAGE}
                  component={ControlledConceptImageInput}
                  label={t('common/prop/image/label')}
                  layout="horizontal"
                />

                {get(config, [PLANNED_ACTIVITIES, 'hidden']) ? null : (
                  <ConceptRichTextField
                    label={t('ideaConcept/prop/activities/label', {
                      context: labelContext
                    })}
                    name={PLANNED_ACTIVITIES}
                    required={get(config, [PLANNED_ACTIVITIES, 'required'])}
                    placeholder={t('ideaConcept/richTextPlaceholder')}
                    layout="horizontal"
                    data-test="activities"
                  />
                )}
              </Panel>
            </div>

            <hr />

            {submitError && (
              <Callout type="danger" tag="p" data-test="error">
                {translateApiErrorMessage(t, submitError)}
              </Callout>
            )}

            <footer className={`${BASE_CLASS}__Actions`}>
              <Button onClick={closeEditor} data-test="cancel">
                {t('common/userAction/cancel')}
              </Button>
              <Button
                type="submit"
                variant="success"
                loading={submitting}
                data-test="submit">
                {t('ideaConcepts/subpage/describeForm/submitCTA')}
              </Button>
            </footer>
          </form>
        )}
      </Form>
    </Panel>
  );
}

function normaliseFormModel(formModel) {
  return produce(model => {
    model[IS_PRIVATE] = Number(Boolean(model[IS_PRIVATE]));
    model[IDEA_TEXT] = parseEditorState(model[IDEA_TEXT]);
    model[BENEFITS] = parseEditorState(model[BENEFITS]);
    model[PLANNED_ACTIVITIES] = parseEditorState(model[PLANNED_ACTIVITIES]);
    model[COSTS] = parseEditorState(model[COSTS]);
  })(formModel);
}

export default compose(
  translate(),
  withModal()
)(QuickConceptFormAmdocs);
