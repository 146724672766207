import { normalize } from 'normalizr';
import { stringify } from 'qs';
import createChallengeRequest from '../utils/payloadUtils';
import {
  CHALLENGE_PERMISSIONS,
  CHALLENGE_CREATE,
  CHALLENGE_DELETE,
  CHALLENGE_REOPEN,
  FETCH_HASHTAGS,
  FETCH_SCHEMA_DATA
} from '../../constants';
import { VOYAGER_API, SEARCH_API } from '../../api/constants';
import schemas, { entityKeys } from '../schemas';
import { dropFeedCardById } from '../../feed/actions';
import { fetchChallenge } from '../../common/actions/challenges';
import asyncActionType from '../../utils/asyncActionType';
import { getApiResponseData } from '../../api/utils/apiResponse';
import { convertApiErrorToStatusCode } from '../../api/utils/apiError';
import logRequestFailure from '../../api/utils/logRequestFailure';
import addBreadcrumb from '../../telemetry/addBreadcrumb';

export const ADD_NEW_CHALLENGE_TO_FEED = 'ADD_NEW_CHALLENGE_TO_FEED';
export const SEARCH_CHALLENGES = asyncActionType('SEARCH_CHALLENGES');

export const publishChallenge = (challenge, captchaToken) => async dispatch => {
  const data = createChallengeRequest(challenge, { isImageUpdated: true });
  try {
    addBreadcrumb('Creating challenge...', {
      data: { ...data, captchaToken },
      category: 'challenge'
    });
    const result = await dispatch({
      [VOYAGER_API]: {
        url: `/challenges?captcha=${captchaToken}`,
        method: 'POST',
        data,
        type: CHALLENGE_CREATE
      }
    });
    const challengeId = getApiResponseData(result);
    dispatch({
      type: ADD_NEW_CHALLENGE_TO_FEED,
      payload: { challengeId },
      // push returned challengeId to front of feed array
      meta: { mostRecent: true }
    });
    return result;
  } catch (error) {
    logRequestFailure(error, 'Could not create challenge', 'challenge', data);
    throw convertApiErrorToStatusCode(error);
  }
};

export const deleteChallenge = challengeId => async dispatch => {
  try {
    await dispatch({
      [VOYAGER_API]: {
        url: `challenges/${challengeId}`,
        method: 'DELETE',
        type: CHALLENGE_DELETE
      }
    });
    dispatch(dropFeedCardById(challengeId));
  } catch (error) {
    logRequestFailure(error, 'Could not delete challenge', 'challenge');
    throw convertApiErrorToStatusCode(error);
  }
};

export const reopenChallenge = ({
  challengeId,
  startsAt,
  closesAt
}) => async dispatch => {
  try {
    await dispatch({
      [VOYAGER_API]: {
        url: `challenges/${challengeId}/reopen`,
        method: 'POST',
        data: { startsAt, closesAt },
        type: CHALLENGE_REOPEN
      }
    });
    dispatch(fetchChallenge(challengeId));
  } catch (error) {
    logRequestFailure(error, 'Could not reopen challenge', 'challenge', {
      challengeId,
      startsAt,
      closesAt
    });
    throw convertApiErrorToStatusCode(error);
  }
};
export const searchChallenges = ({
  query,
  start = 0,
  limit = 25,
  orderBy = '',
  descending = false
}) => async dispatch => {
  const queryString = stringify({
    query,
    skip: start,
    limit,
    orderBy,
    descending
  });

  try {
    const response = await dispatch({
      [SEARCH_API]: {
        url: `/challenges?${queryString}`,
        method: 'GET',
        type: SEARCH_CHALLENGES
      }
    });
    const challenges = getApiResponseData(response);

    dispatch({
      type: FETCH_SCHEMA_DATA.SUCCESS,
      payload: normalize(challenges, schemas.CHALLENGE_ARRAY),
      meta: {
        entityType: entityKeys.CHALLENGE
      }
    });

    return challenges;
  } catch (error) {
    logRequestFailure(error, 'Challenge search failed', 'challenge', {
      queryString
    });
    throw convertApiErrorToStatusCode(error);
  }
};

export const fetchPermissions = () => {
  return {
    type: CHALLENGE_PERMISSIONS,
    [VOYAGER_API]: {
      url: '/challenges/save-permissions',
      method: 'GET',
      type: CHALLENGE_PERMISSIONS
    }
  };
};

export const fetchHashtags = () => {
  return {
    type: FETCH_HASHTAGS,
    [VOYAGER_API]: {
      url: '/hashtags',
      method: 'GET',
      type: FETCH_HASHTAGS
    }
  };
};
