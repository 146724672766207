import React, { useCallback } from 'react';
import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import includesFp from 'lodash/fp/includes';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { conceptListFilterEnums, conceptListFilterKeys } from '../../constants';
import Checkbox from '@bit/be-novative.kit.checkbox';
import ChallengeSearchPopover from '../ChallengeSearchPopover';
import CategoryFilter from './CategoryFilter';
import useSetFilter from '../../hooks/conceptList/useSetFilter';
import useUnsetFilter from '../../hooks/conceptList/useUnsetFilter';
import useIsListLoading from '../../hooks/conceptList/useIsListLoading';
import { getConceptListFilters } from '../../selectors/conceptList';
import scss from './ConceptListToolbar.module.scss';

const {
  CATEGORY,
  CHALLENGE,
  CONCEPT_TYPE,
  INVOLVEMENT
} = conceptListFilterKeys;

const {
  OWN_CONCEPTS,
  MY_CONTRIBUTIONS,
  QUICK_CONCEPTS
} = conceptListFilterEnums;

function FilterControls({ t }) {
  const activeFilters = useSelector(getConceptListFilters);
  const loading = useIsListLoading();
  const setFilter = useSetFilter();
  const unsetFilter = useUnsetFilter();

  const handleCategChange = useCallback(
    categId => {
      setFilter(CATEGORY, categId, { overwriteValue: true });
    },
    [setFilter]
  );

  const handleChallengeChange = useCallback(
    (challengeIds = []) => {
      setFilter(CHALLENGE, challengeIds, { overwriteValue: true });
    },
    [setFilter]
  );

  const handleCheckboxToggle = useCallback(
    event => {
      const { name, value, checked } = event.target;
      if (checked) {
        setFilter(name, value);
      } else {
        unsetFilter(name, value);
      }
    },
    [setFilter, unsetFilter]
  );

  const isFilterActive = useCallback(
    (key, value) => {
      return flow(
        getFp(key),
        includesFp(value)
      )(activeFilters);
    },
    [activeFilters]
  );

  return (
    <div
      role="group"
      aria-label={t('ideaConcepts/listPage/filter/legend')}
      className={scss.ChboxWrapper}>
      <Checkbox
        input={{
          name: INVOLVEMENT,
          value: OWN_CONCEPTS,
          onChange: handleCheckboxToggle,
          checked: isFilterActive(INVOLVEMENT, OWN_CONCEPTS),
          disabled: loading
        }}>
        {t('ideaConcepts/listPage/filter/ownConcepts')}
      </Checkbox>
      <Checkbox
        input={{
          name: INVOLVEMENT,
          value: MY_CONTRIBUTIONS,
          onChange: handleCheckboxToggle,
          checked: isFilterActive(INVOLVEMENT, MY_CONTRIBUTIONS),
          disabled: loading
        }}>
        {t('ideaConcepts/listPage/filter/contribution')}
      </Checkbox>
      <Checkbox
        input={{
          name: CONCEPT_TYPE,
          value: QUICK_CONCEPTS,
          onChange: handleCheckboxToggle,
          checked: isFilterActive(CONCEPT_TYPE, QUICK_CONCEPTS),
          disabled: loading
        }}>
        {t('ideaConcepts/listPage/filter/quickConcepts')}
      </Checkbox>
      <ChallengeSearchPopover
        selections={activeFilters[CHALLENGE]}
        onSelect={handleChallengeChange}
      />
      <CategoryFilter
        canEdit={!loading}
        onSelect={handleCategChange}
        value={get(activeFilters, [CATEGORY, 0])}
      />
    </div>
  );
}

export default translate()(FilterControls);
