import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import scss from './PageHeader.module.scss';

PageHeader.propTypes = {
  lastChild: PropTypes.bool
};
function PageHeader({ lastChild, ...props }) {
  const classes = cn(scss.PageHeader, { [scss['last-child']]: lastChild });
  return <header className={classes} {...props} />;
}

export function Section(props) {
  return <section className={scss.Section} {...props} />;
}

export function Item(props) {
  return <div className={scss.Item} {...props} />;
}

export default PageHeader;
