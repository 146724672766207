import flow from 'lodash/flow';
import get from 'lodash/get';
import isEqualFp from 'lodash/fp/isEqual';
import { UNAUTH_PATH_WHITELIST } from '../constants';
import { getApiResponseCode } from '../utils/apiResponse';
import authService from '../../authentication/services/auth.service';

export default function handleUnauthenticated(error) {
  const isUnauthenticated = flow(
    getApiResponseCode,
    isEqualFp(401)
  )(error);
  const isWhitelisted = UNAUTH_PATH_WHITELIST.some(whitelistedPath =>
    get(error, 'config.url', []).includes(whitelistedPath)
  );

  if (isUnauthenticated && !isWhitelisted) {
    authService.logout(true);
  } else {
    throw error;
  }
}
