import snakeCase from 'lodash/snakeCase';
import * as Sentry from '@sentry/browser';
import { MonitoringSeverity } from '../../telemetry/monitoring/constants';
import trackTrace from '../../telemetry/monitoring/trackTrace';
import addBreadcrumb, { Severity } from '../../telemetry/addBreadcrumb';
import { CommonException } from '../constants';
import { convertApiErrorToStatusCode } from './apiError';

/**
 * Processes an API request error, and logs the error with the appropriate service.
 * HTTP 5xx errors and general/unknown errors are logged with Azure App Insights.
 * Bad requests and general/unknown errors are logged with Sentry as a UI error.
 * @param {Error} requestError - The response received from the API instance
 * @param {string} [message] - The log message
 * @param {BreadcrumbCategory} [category] - Feature or domain, e.g. `"challenge", "bootstrap"`. In Sentry, this is also set as the custom `domain` tag for the error report.
 * @param {Object} [data] - Optional contextual data.
 * @return {void}
 */
export default function logRequestFailure(
  requestError,
  message = '',
  category = '',
  data
) {
  const errorCode = convertApiErrorToStatusCode(requestError);
  if (
    [CommonException.ServerError, CommonException.General].includes(errorCode)
  ) {
    trackTrace(message, data, MonitoringSeverity.Error, category);
  }
  if (
    [CommonException.General, CommonException.BadRequest].includes(errorCode)
  ) {
    if (data) {
      Sentry.addBreadcrumb({ data, category });
    }
    addBreadcrumb(new Error(message), {
      level: Severity.Error,
      category: snakeCase(category)
    });
  }
}
