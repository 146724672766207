import React from 'react';
import classNames from 'classnames';
import './MutedText.css';

MutedText.defaultProps = {
  tag: 'div'
};

function MutedText({ children, className, tag: Tag, ...rest }) {
  const classes = classNames(className, 'MutedText');
  return (
    <Tag {...rest} className={classes}>
      {children}
    </Tag>
  );
}

export default MutedText;
