import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ImageTypes } from '../../../constants';
import Button from '@bit/be-novative.kit.button';
import Close from '../../components/Close';
import Grid from '../../components/Grid';
import Icon from '../../components/Icon';
import Panel from '../../components/Panel';
import Responsive from '../../components/Responsive';
import withModal from '../withModal';
import useActiveImagePicker from './useActiveImagePicker';

const { PRESET, GOOGLE, UPLOADED } = ImageTypes;
export const Icons = {
  [PRESET]: 'rewind',
  [GOOGLE]: 'search',
  [UPLOADED]: 'upload'
};

ImageSearchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onImageSelect: PropTypes.func.isRequired
};
function ImageSearchModal({ t, onClose, onImageSelect }) {
  const defaultType = PRESET;
  const tabs = useActiveImagePicker(defaultType);
  const Tab = tabs.getComponent();
  const activeType = tabs.getType();
  const shouldShowNavToDefaultTab = activeType !== defaultType;
  const handleSelect = useCallback(
    image => {
      image['@type'] = activeType;
      onImageSelect(image, activeType);
      onClose();
    },
    [onClose, onImageSelect, activeType]
  );
  return (
    <div>
      <Grid.Row gutter={0} align="stretch">
        <Grid.Col xs={'auto'} xsGutterX={2} xsGutterY={2} mdGutterX={0}>
          {shouldShowNavToDefaultTab && (
            <Button
              variant={'secondary'}
              onClick={() => tabs.setType(defaultType)}>
              <Icon type={Icons[defaultType]} />
              <Responsive mediaHiddenDown="lg">
                {t(`common/imagePicker/${defaultType}/navButton`)}
              </Responsive>
            </Button>
          )}
        </Grid.Col>
        <Grid.Col xs={'auto'} xsGutterX={2} xsGutterY={2} mdGutterX={0}>
          <Button
            variant={'success'}
            onClick={() => tabs.setType(GOOGLE)}
            style={{ marginLeft: 5 }}>
            <Icon type={Icons[GOOGLE]} />
            <span>{t(`common/imagePicker/${GOOGLE}/navButton`)}</span>
          </Button>
          <Button
            variant={'success'}
            onClick={() => tabs.setType(UPLOADED)}
            style={{ marginLeft: 5 }}>
            <Icon type={Icons[UPLOADED]} />
            <span>{t(`common/imagePicker/${UPLOADED}/navButton`)}</span>
          </Button>
        </Grid.Col>
      </Grid.Row>
      <div style={{ position: 'relative' }}>
        <Panel>
          <Close onClick={onClose}>{t('common/userAction/close')}</Close>
          <Tab onSelect={handleSelect} data-test="tab" />
        </Panel>
      </div>
    </div>
  );
}

export default withModal()(ImageSearchModal);
