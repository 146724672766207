import React, { useCallback } from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import translateApiErrorMessage from '../../../utils/translateApiErrorMessage';
import { closeModal } from '../../../common/actions/modal';
import Button from '@bit/be-novative.kit.button';
import Callout from '../../../common/components/Callout';
import Panel from '../../../common/components/Panel';
import withModal from '../../../common/containers/withModal';
import useEditConcept from '../../hooks/useEditConcept';

ConceptEditorForm.propTypes = {
  ideaConceptId: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func,
  title: PropTypes.string,
  values: PropTypes.object.isRequired
};
function ConceptEditorForm({
  children,
  ideaConceptId,
  onSubmitSuccess = noop,
  t,
  title,
  values
}) {
  const dispatch = useDispatch();
  const editConcept = useEditConcept(ideaConceptId);
  const onSubmit = useCallback(
    async function onSubmit(formVals) {
      try {
        const req = await editConcept(formVals);
        onSubmitSuccess();
        dispatch(closeModal());
        return req;
      } catch (error) {
        return { [FORM_ERROR]: translateApiErrorMessage(t, error) };
      }
    },
    [dispatch, editConcept, onSubmitSuccess, t]
  );

  return (
    <Form
      initialValues={values}
      onSubmit={onSubmit}
      keepDirtyOnReinitialize={true}>
      {({ form, handleSubmit }) => {
        const { submitting, submitError } = form.getState();
        return (
          <Panel header={title} rounded>
            <form onSubmit={handleSubmit} style={{ marginTop: '1rem' }}>
              {children}
              <footer
                style={{
                  marginBottom: '1rem',
                  textAlign: 'right'
                }}>
                <Button
                  type="submit"
                  variant="success"
                  loading={submitting}
                  style={{ marginLeft: '1rem' }}>
                  {t('ideaConcepts/subpage/describeForm/submitCTA')}
                </Button>
              </footer>
              {submitError && (
                <Callout type="danger" tag="p">
                  {submitError}
                </Callout>
              )}
            </form>
          </Panel>
        );
      }}
    </Form>
  );
}

export default compose(
  withModal(),
  translate()
)(ConceptEditorForm);
