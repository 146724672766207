import asyncActionType from '../utils/asyncActionType';

// action types
export const CREATE_IDEA_CONCEPT = asyncActionType('CREATE_IDEA_CONCEPT');
export const UPDATE_CONCEPT_OWNER = asyncActionType('UPDATE_CONCEPT_OWNER');
export const FETCH_IDEA_CONCEPTS = asyncActionType('FETCH_IDEA_CONCEPTS');
export const FETCH_CHALLENGE_IDEA_CONCEPTS = asyncActionType(
  'FETCH_CHALLENGE_IDEA_CONCEPTS'
);
export const FLUSH_CONCEPT_IDS = 'FLUSH_CONCEPT_IDS';
export const UPLOAD_CONCEPT_ATTACHMENT = asyncActionType(
  'UPLOAD_CONCEPT_ATTACHMENT'
);
export const DOWNLOAD_CONCEPT_ATTACHMENT = asyncActionType(
  'DOWNLOAD_CONCEPT_ATTACHMENT'
);

// dedicated metrics action types
export const CHANGE_CONCEPT_STAGE = 'CHANGE_CONCEPT_STAGE';
export const CREATE_QUICK_CONCEPT_FORM_OPEN = 'CREATE_QUICK_CONCEPT_FORM_OPEN';
export const CONCEPT_LIST_CHANGE_SORT = 'CONCEPT_LIST_CHANGE_SORT';
export const CONCEPT_LIST_CHANGE_STAGE_FILTER =
  'CONCEPT_LIST_CHANGE_STAGE_FILTER';
export const CONCEPT_STATUS_UPDATE_CANCEL = 'CONCEPT_STATUS_UPDATE_CANCEL';
export const CLOSE_INTRO_MESSAGE = 'CLOSE_INTRO_MESSAGE';
export const PAGINATE_IDEA_CARDS = 'PAGINATE_IDEA_CARDS';
export const SKIP_SUGGESTIONS = 'SKIP_SUGGESTIONS';
export const VIEW_CHALLENGE_INFO = 'VIEW_CHALLENGE_INFO';

// definitions
export const EMPTY_NUMBER = 'N/A';

export const conceptEditorFormFields = {
  BENEFITS: 'benefits',
  COSTS: 'costs',
  IDEA_TEXT: 'ideaText',
  PLANNED_ACTIVITIES: 'plannedActivities',
  TITLE: 'title',
  CONCEPT_STATE: 'ideaConceptState',
  FOLLOW_UP_CHALLENGE: 'followUpChallengeId',
  IS_PRIVATE: 'isPrivate',
  // sent by GET endpoint
  CATEGORY: 'category',
  IMAGE_URL: 'imageUrl',
  TEAM: 'team',
  // expected by POST/PUT endpoint
  CATEGORY_ID: 'categoryId',
  INVITED_USERS: 'invitedUsers',
  IMAGE: 'image'
};

export const conceptEvalCriteriaDefaults = [
  'Size of the impact',
  'Business viability',
  'Technological feasibility',
  'Team composition',
  'Disruptiveness of the idea'
];

/**
 * @enum {string} IdeaConceptImplementationStatus
 * @readonly
 */
export const IdeaConceptImplementationStatus = {
  ACTIVE: 'ACTIVE',
  VALIDATION: 'VALIDATION',
  IMPLEMENTING: 'IMPLEMENTING',
  DONE: 'DONE',
  LATER: 'SAVE_FOR_LATER',
  STOP: 'STOPPED'
};

/**
 * @enum {string} IdeaConceptImplementationStatus
 * @readonly
 */
export const IdeaConceptImplementationStatusCepheid = {
  ACTIVE: 'ACTIVE',
  VALIDATION: 'VALIDATION',
  IMPLEMENTING: 'IMPLEMENTING',
  FINAL_ASSESSMENT: 'FINAL_ASSESSMENT',
  COACHING: 'COACHING',
  DONE: 'DONE',
  LATER: 'SAVE_FOR_LATER',
  STOP: 'STOPPED'
};

export const stagesWithOwnFilters = [
  IdeaConceptImplementationStatus.ACTIVE,
  IdeaConceptImplementationStatus.VALIDATION,
  IdeaConceptImplementationStatus.IMPLEMENTING,
  IdeaConceptImplementationStatus.DONE
];
export const stagesWithOwnFiltersCepheid = [
  IdeaConceptImplementationStatusCepheid.ACTIVE,
  IdeaConceptImplementationStatusCepheid.VALIDATION,
  IdeaConceptImplementationStatusCepheid.IMPLEMENTING,
  IdeaConceptImplementationStatusCepheid.FINAL_ASSESSMENT,
  IdeaConceptImplementationStatusCepheid.COACHING,
  IdeaConceptImplementationStatusCepheid.DONE
];
export const OTHER_FILTER_ID = 'OTHER';

export const conceptListColumns = {
  TITLE: 'title',
  OWNER: 'owner',
  TEAM: 'team',
  CREATE_TIME: 'createTime',
  EVAL_SCORE: 'evalScore',
  STAGE: 'stage',
  INTERNAL_COMMENT: 'internalComment',
  REVIEWER_FEEDBACK: 'REVIEWER_FEEDBACK'
};

export const conceptListSortIds = {
  [conceptListColumns.TITLE]: 'TITLE',
  [conceptListColumns.OWNER]: 'OWNER',
  [conceptListColumns.TEAM]: 'TEAM_SIZE',
  [conceptListColumns.CREATE_TIME]: 'CREATED_AT',
  [conceptListColumns.EVAL_SCORE]: 'REVIEW_SCORE',
  [conceptListColumns.STAGE]: 'STATUS'
};

export const conceptListFilterKeys = {
  CATEGORY: 'category',
  CHALLENGE: 'challengeid',
  CONCEPT_TYPE: 'filter', // undefined (normal concept) | quick concepts
  INVOLVEMENT: 'filter', // created by me | with my contribution
  STAGE: 'state'
};

export const conceptListFilterEnums = {
  OWN_CONCEPTS: 'onlymyconcepts',
  MY_CONTRIBUTIONS: 'contributedbyme',
  QUICK_CONCEPTS: 'onlyquickideaconcepts'
};

export const conceptListStageFilterIds = {
  [IdeaConceptImplementationStatus.ACTIVE]:
    IdeaConceptImplementationStatus.ACTIVE,
  [IdeaConceptImplementationStatus.VALIDATION]:
    IdeaConceptImplementationStatus.VALIDATION,
  [IdeaConceptImplementationStatus.IMPLEMENTING]:
    IdeaConceptImplementationStatus.IMPLEMENTING,
  [IdeaConceptImplementationStatus.DONE]: IdeaConceptImplementationStatus.DONE,
  [OTHER_FILTER_ID]: [
    IdeaConceptImplementationStatus.LATER,
    IdeaConceptImplementationStatus.STOP
  ]
};
export const conceptListStageFilterIdsCepheid = {
  [IdeaConceptImplementationStatusCepheid.ACTIVE]:
    IdeaConceptImplementationStatusCepheid.ACTIVE,
  [IdeaConceptImplementationStatusCepheid.VALIDATION]:
    IdeaConceptImplementationStatusCepheid.VALIDATION,
  [IdeaConceptImplementationStatusCepheid.IMPLEMENTING]:
    IdeaConceptImplementationStatusCepheid.IMPLEMENTING,
  [IdeaConceptImplementationStatusCepheid.FINAL_ASSESSMENT]:
    IdeaConceptImplementationStatusCepheid.FINAL_ASSESSMENT,
  [IdeaConceptImplementationStatusCepheid.COACHING]:
    IdeaConceptImplementationStatusCepheid.COACHING,
  [IdeaConceptImplementationStatusCepheid.DONE]:
    IdeaConceptImplementationStatusCepheid.DONE,
  [OTHER_FILTER_ID]: [
    IdeaConceptImplementationStatusCepheid.LATER,
    IdeaConceptImplementationStatusCepheid.STOP
  ]
};

// modals
export const ACTIVITIES_EDITOR = 'ACTIVITIES_EDITOR';
export const BENEFITS_EDITOR = 'BENEFITS_EDITOR';
export const CONCEPT_FIELD_EDITOR = 'CONCEPT_FIELD_EDITOR';
export const CONCEPT_MAP_PREVIEW = 'CONCEPT_MAP_PREVIEW';
export const CONCEPT_OWNER_FORM = 'CONCEPT_OWNER_FORM';
export const CONCEPT_PREVIEW = 'CONCEPT_PREVIEW';
export const COSTS_EDITOR = 'COSTS_EDITOR';
export const DESCRIBE_IDEA_CONCEPT_FORM = 'DESCRIBE_IDEA_CONCEPT_FORM';
export const CONCEPT_COMMENT_EDITOR = 'CONCEPT_COMMENT_EDITOR';
export const CONCEPT_COMMENT_DISPLAY = 'CONCEPT_COMMENT_DISPLAY';
export const TEAM_EDITOR = 'TEAM_EDITOR';
export const VISIBILITY_EDITOR = 'VISIBILITY_EDITOR';

// schema IDs
export const entities = {
  ATTACHMENT: 'attachments',
  CONCEPT_POST: 'conceptPosts',
  IDEA_CONCEPT: 'ideaConcepts',
  SUGGESTION: 'suggestions'
};

// entity ID props
export const idProps = {
  ATTACHMENT_ID: 'attachmentId',
  IDEA_CONCEPT_ID: 'ideaConceptId',
  SUGGESTION_ID: 'suggestionId'
};
