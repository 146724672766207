import React, { useCallback } from 'react';
import { translate } from 'react-i18next';
import Intercom from 'react-intercom';
import Button from '@bit/be-novative.kit.button';
import FullScreen from '../../components/FullScreen';
import NonIdealState from '../../components/NonIdealState';

function BootstrapError({ t }) {
  const handleReload = useCallback(() => {
    window && window.location.reload();
  }, []);

  return (
    <FullScreen>
      <NonIdealState
        visual={NonIdealState.HUMMINGBIRD}
        title={t('common/bootstrapError/title')}
        description={t('common/bootstrapError/description')}
        action={
          <Button variant="primary" onClick={handleReload}>
            {t('common/bootstrapError/CTA')}
          </Button>
        }
      />
      <Intercom appID={process.env.REACT_APP_INTERCOM_APP_ID} />
    </FullScreen>
  );
}

export default translate()(BootstrapError);
