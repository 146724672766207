import { handleActions } from 'redux-actions';
import { UPDATE_ONBOARDING } from '../../constants';

export default handleActions(
  {
    [UPDATE_ONBOARDING.PENDING](state, { meta }) {
      return {
        ...state,
        onboarding: meta.data,
        loading: true
      };
    }
  },
  null
);
