import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import PaginationItem from './PaginationItem';
import './Pagination.css';

class Pagination extends React.Component {
  static propTypes = {
    activePage: PropTypes.number,
    items: PropTypes.number,
    maxButtons: PropTypes.number,
    boundaryLinks: PropTypes.bool,
    ellipsis: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    prev: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    next: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    onSelect: PropTypes.func
  };

  static defaultProps = {
    activePage: 1,
    items: 1,
    maxButtons: 5,
    prev: true,
    next: true,
    ellipsis: true,
    boundaryLinks: true
  };

  renderPageButtons(
    activePage,
    items,
    maxButtons,
    boundaryLinks,
    ellipsis,
    buttonProps
  ) {
    const pageButtons = [];

    let startPage;
    let endPage;

    if (maxButtons && maxButtons < items) {
      startPage = Math.max(
        Math.min(
          activePage - Math.floor(maxButtons / 2, 10),
          items - maxButtons + 1
        ),
        1
      );
      endPage = startPage + maxButtons - 1;
    } else {
      startPage = 1;
      endPage = items;
    }

    for (let page = startPage; page <= endPage; ++page) {
      pageButtons.push(
        <PaginationItem
          {...buttonProps}
          key={page}
          eventKey={page}
          active={page === activePage}>
          {page}
        </PaginationItem>
      );
    }

    if (ellipsis && boundaryLinks && startPage > 1) {
      if (startPage > 2) {
        pageButtons.unshift(
          <PaginationItem
            key="ellipsisFirst"
            disabled
            componentClass={buttonProps.componentClass}>
            <span aria-label="More">
              {ellipsis === true ? '\u2026' : ellipsis}
            </span>
          </PaginationItem>
        );
      }

      pageButtons.unshift(
        <PaginationItem {...buttonProps} key={1} eventKey={1} active={false}>
          1
        </PaginationItem>
      );
    }

    if (ellipsis && endPage < items) {
      if (!boundaryLinks || endPage < items - 1) {
        pageButtons.push(
          <PaginationItem
            key="ellipsis"
            disabled
            componentClass={buttonProps.componentClass}>
            <span aria-label="More">
              {ellipsis === true ? '\u2026' : ellipsis}
            </span>
          </PaginationItem>
        );
      }

      if (boundaryLinks) {
        pageButtons.push(
          <PaginationItem
            {...buttonProps}
            key={items}
            eventKey={items}
            active={false}>
            {items}
          </PaginationItem>
        );
      }
    }

    return pageButtons;
  }

  render() {
    const {
      activePage,
      items,
      maxButtons,
      boundaryLinks,
      ellipsis,
      prev,
      next,
      onSelect,
      className
    } = this.props;

    const classes = classNames(className, 'Pagination');

    const buttonProps = {
      onSelect
    };

    return (
      <ul className={classes}>
        {prev &&
          <PaginationItem
            {...buttonProps}
            eventKey={activePage - 1}
            disabled={activePage === 1}>
            <span aria-label="Previous">
              {prev === true ? '\u2039' : prev}
            </span>
          </PaginationItem>}

        {this.renderPageButtons(
          activePage,
          items,
          maxButtons,
          boundaryLinks,
          ellipsis,
          buttonProps
        )}

        {next &&
          <PaginationItem
            {...buttonProps}
            eventKey={activePage + 1}
            disabled={activePage >= items}>
            <span aria-label="Next">
              {next === true ? '\u203a' : next}
            </span>
          </PaginationItem>}
      </ul>
    );
  }
}
export default Pagination;
