import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDisplayName } from '../../../utils';
import { enterOnboarding, leaveOnboarding } from '../../actions';

const withOnboardingManager = tips => WrappedComponent => {
  class WithOnboardingManager extends Component {
    static displayName = `withOnboardingManager${getDisplayName(
      WrappedComponent
    )}`;

    componentDidMount() {
      this.props.enterOnboarding(tips);
    }

    componentWillUnmount() {
      this.props.leaveOnboarding();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(null, { enterOnboarding, leaveOnboarding })(
    WithOnboardingManager
  );
};

export default withOnboardingManager;
