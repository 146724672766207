import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Panel, Grid, Button } from '../../components';
import withModal from '../withModal';

class AuthorizationRequiredModal extends Component {
  handleLoginClick = () => {
    const { history, location } = this.props;
    history.push({
      pathname: '/login',
      search: `?redirect=${location.pathname}`
    });
  };

  handleRegistrationClick = () => {
    const { history, location } = this.props;
    history.push({
      pathname: '/registration',
      search: `?redirect=${location.pathname}`
    });
  };

  render() {
    const { t, children } = this.props;

    return (
      <Panel bordered>
        <p>{children(t)}</p>
        <Grid.Row tag="footer">
          <Grid.Col xs={6} xsGutterX={2}>
            <Button
              fillParent
              variant={'secondary'}
              onClick={this.handleLoginClick}>
              {t('common/Login')}
            </Button>
          </Grid.Col>
          <Grid.Col xs={6} xsGutterX={2}>
            <Button
              fillParent
              variant={'success'}
              onClick={this.handleRegistrationClick}>
              {t('common/Signup')}
            </Button>
          </Grid.Col>
        </Grid.Row>
      </Panel>
    );
  }
}

export default compose(withRouter, withModal())(AuthorizationRequiredModal);
