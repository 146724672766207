import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';
import isString from 'lodash/isString';
import Collapse from '../Collapse';
import classNames from 'classnames';
import './Panel.css';

function Panel({
  bodyClass,
  bordered,
  canExpand,
  children,
  className,
  collapsible,
  expanded,
  header,
  headerClass,
  headerRole,
  headerTag: HeaderTag,
  onExpand,
  onExpanded,
  panelRole,
  rounded,
  smoked,
  spacing,
  shadowed,
  ...rest
}) {
  const handleClick =
    collapsible && canExpand && onExpand && !expanded ? onExpand : null;
  const headerClasses = classNames('Panel__Header', headerClass, { expanded });
  const bodyClasses = classNames('Panel__Body', bodyClass);
  const classes = classNames('Panel', className, {
    'Panel--bordered': bordered,
    'Panel--rounded': rounded,
    'Panel--smoked': smoked,
    'Panel--shadowed': shadowed,
    'Panel--clickable': !!handleClick,
    [`Panel--spacing-${spacing}`]: !!spacing
  });

  return (
    <div className={classes} tabIndex={0} onClick={handleClick} {...rest}>
      {header && isString(header) && (
        <HeaderTag className={headerClasses}>{header}</HeaderTag>
      )}
      {header &&
        !isString(header) &&
        cloneElement(header, {
          role: headerRole,
          active: collapsible && expanded
        })}
      {collapsible && (
        <Collapse
          in={expanded}
          role={panelRole}
          unmountOnExit
          onEntered={onExpanded}>
          <div>
            <div className={bodyClasses}>{children}</div>
          </div>
        </Collapse>
      )}
      {!collapsible && (
        <div role={panelRole} className={bodyClasses}>
          {children}
        </div>
      )}
    </div>
  );
}

Panel.propTypes = {
  bordered: PropTypes.bool,
  collapsible: PropTypes.bool,
  expanded: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerRole: PropTypes.string,
  headerTag: PropTypes.string,
  panelRole: PropTypes.string,
  smoked: PropTypes.bool,
  shadowed: PropTypes.bool,
  spacing: PropTypes.oneOf(['md', 'lg', false])
};

Panel.defaultProps = {
  bordered: false,
  canExpand: true,
  collapsible: false,
  expanded: false,
  headerTag: 'h2',
  smoked: false,
  shadowed: true,
  spacing: 'md'
};

export default Panel;
