import PropTypes from 'prop-types';

const stringOrNumberProp = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number
]);

export const tag = PropTypes.oneOfType([PropTypes.func, PropTypes.string]);

export const column = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.oneOf(['auto'])
]);

export const spacing = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.shape({
    x: stringOrNumberProp,
    y: stringOrNumberProp
  })
]);

export default {
  tag,
  column,
  spacing
};
