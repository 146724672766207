import has from 'lodash/has';
import { set } from 'dot-prop-immutable';

function setIf(targetObject, path, value) {
  return has(targetObject, path)
    ? set(targetObject, path, value)
    : targetObject;
}

export function setIfFp(path, value) {
  return targetObject => setIf(targetObject, path, value);
}

export default setIf;
