import React from 'react';
import PropTypes from 'prop-types';
import Button from '@bit/be-novative.kit.button';
import Icon from '../../components/Icon';
import './PaginationHorizontal.css';

Navigation.propTypes = {
  disablePrev: PropTypes.bool,
  disableNext: PropTypes.bool,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};
export default function Navigation({
  className,
  currentPage,
  disableNext,
  disablePrev,
  onNext,
  onPrev,
  pageCount
}) {
  const disablePrevBtn = disablePrev || currentPage === 1;
  const disableNextBtn = disableNext || pageCount - currentPage === 0;

  return (
    <div className={className}>
      {disablePrevBtn ? (
        <div className="Pagination__Nav__Placeholder" />
      ) : (
        <Button
          onClick={onPrev}
          variant="ghost-white"
          className="Pagination__Nav__Btn--left">
          <Icon type="angle-left" size="xl" />
        </Button>
      )}
      {disableNextBtn ? (
        <div className="Pagination__Nav__Placeholder" />
      ) : (
        <Button
          onClick={onNext}
          variant="ghost-white"
          className="Pagination__Nav__Btn--right">
          <Icon type="angle-right" size="xl" />
        </Button>
      )}
    </div>
  );
}
