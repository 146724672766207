import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { withResponsive } from '../Responsive';
import Button from '@bit/be-novative.kit.button';
import Icon from '../Icon';
import './PrimaryNavigationItem.css';

PrimaryNavigationItem.defaultProps = {
  secondary: false,
  push: false
};

function PrimaryNavigationItem({
  children,
  className,
  push,
  center,
  secondary,
  iconType,
  onClick,
  to,
  ...rest
}) {
  const classes = classNames('PrimaryNavigationItem', className, {
    'PrimaryNavigationItem--push': push,
    'PrimaryNavigationItem--center': center
  });
  const linkClasses = classNames('PrimaryNavigationItem__Link', {
    'PrimaryNavigationItem__Link--secondary': secondary
  });
  const icon = iconType && (
    <Icon className="PrimaryNavigationItem__Icon" type={iconType} />
  );
  const showLink = !!to;
  const showButton = !to && !!onClick;
  const showStatic = !showLink && !showButton;

  return (
    <li className={classes}>
      {showLink && (
        <NavLink
          {...rest}
          to={to}
          onClick={onClick}
          className={linkClasses}
          activeClassName="PrimaryNavigationItem__Link--active">
          {icon} {children}
        </NavLink>
      )}
      {showButton && (
        <Button variant="link" onClick={onClick} className={linkClasses}>
          {icon} {children}
        </Button>
      )}
      {showStatic && (
        <span>
          {icon} {children}
        </span>
      )}
    </li>
  );
}

export default withResponsive(PrimaryNavigationItem);
