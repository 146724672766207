import get from 'lodash/get';
import includes from 'lodash/fp/includes';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import size from 'lodash/size';
import some from 'lodash/some';
import trim from 'lodash/trim';
import urlRegex from './regex/url';
import invariant from 'invariant';
import moment from 'moment';
import { isEditorState } from '@bit/be-novative.kit.richtext-utils';
import { userConsentKeys } from '../constants';
import {
  ChallengeVisiblity,
  CHALLENGE_FORM_FIELDS
} from '../challenge/constants';
import { CHALLENGE_TITLE_MAXLENGTH } from '../challenge/constants';
import { getEmailFromInviteeDisplayName } from './payloadUtils/invitee';
import { getUserId } from '../common/getters/users';
import { getCloseTime, getStartTime } from '../common/selectors/challenges';

export const EMAIL_VALIDATION_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const HASHTAG_REGEX = /^#?[A-Za-z0-9]*$/;
export const PHONE_NUMBER_REGEX = /^\+?\d{1,20}$/;

const REQUIRED = 'common/Required';
const INVALID = 'common/Invalid';
const INVALID_USER_INVITE = 'validationErrors/userAutosuggest/regexFail';

export function composeValidators(...validators) {
  return (...args) =>
    validators.reduce(
      (error, validator) => error || validator(...args),
      undefined
    );
}

export function renderError(t, defaultError) {
  if (isString(t)) {
    return t;
  } else if (isFunction(t)) {
    return t(defaultError);
  } else {
    throw Error('The t argument must be a string or a function');
  }
}

function getLength(value) {
  if (isString(value)) {
    return value.length;
  }

  if (isEditorState(value)) {
    const contentState = value.getCurrentContent();
    return contentState.getPlainText().length;
  }

  return 0;
}

export function required(t) {
  return value => {
    const isEmptyObject = isObject(value) && isEmpty(value);
    const isEmptyString = isString(value) && isEmpty(trim(value));
    const isEmptyRichText = isEditorState(value) && !getLength(value);

    if (
      isEmptyObject ||
      isEmptyString ||
      isEmptyRichText ||
      value === undefined ||
      value === false ||
      value === null
    ) {
      return renderError(t, REQUIRED);
    }
  };
}

export function email(t) {
  return value => {
    if (value && !EMAIL_VALIDATION_REGEX.test(value)) {
      return renderError(t, 'common/user/formFields/email/validation/invalid');
    }
  };
}

export function phoneNumber(t) {
  return value => {
    if (value && !PHONE_NUMBER_REGEX.test(value)) {
      return renderError(t, 'common/Invalid phone number');
    }
  };
}

export function validatePasswordWithPolicy(
  password,
  policy = {
    minLength: 6,
    minLowercase: 1,
    minUppercase: 1,
    minNumber: 1,
    minSpecChars: 1
  }
) {
  invariant(isString(password), 'Password must be a string');
  const longEnough = size(password) >= policy.minLength;
  const matchesLower =
    (password.match(/[a-z]/g) || []).length >= policy.minLowercase;
  const matchesUpper =
    (password.match(/[A-Z]/g) || []).length >= policy.minUppercase;
  const matchesNumeric =
    (password.match(/\d/g) || []).length >= policy.minNumber;
  const matchesSpecChars =
    (password.match(/[^a-zA-Z0-9]/g) || []).length >= policy.minSpecChars;
  return {
    matchesLower,
    matchesUpper,
    matchesNumeric,
    matchesSpecChars,
    longEnough
  };
}

export function password(t, policy) {
  return value => {
    if (!value) {
      return;
    }
    const passwordQuality = validatePasswordWithPolicy(value, policy);
    if (some(passwordQuality, criterionFulfilled => !criterionFulfilled)) {
      return renderError(
        t,
        'common/user/formFields/password/validation/complexity'
      );
    }
  };
}

export function maxlength(t, maxlength) {
  return value => {
    const length = getLength(value);
    const remainingChars = maxlength - length;

    if (remainingChars < 0) {
      return isString(t)
        ? t
        : t('common/You have exceeded the {{maxlength}} character limit.', {
            maxlength
          });
    }
  };
}

export function url(t) {
  return value => {
    if (value && !urlRegex.test(value)) {
      return renderError(t, 'common/Invalid URL');
    }
  };
}

// TODO necessary?
// see https://tools.ietf.org/html/rfc1034
export function domainName(t) {
  return value => {
    if (value && !/[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?/.test(value)) {
      return renderError(t, 'common/Invalid domain name');
    }
  };
}

export function positiveInt(t) {
  return value => {
    if (value && !/^\d+$/.test(value)) {
      return renderError(t, 'common/Invalid number');
    }
  };
}

// TODO write test against valid substrings despite invalid characters
// see https://tools.ietf.org/html/rfc1034
export function subdomainName(t) {
  return value => {
    if (
      value &&
      !/^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/.test(value)
    ) {
      return renderError(t, 'common/Invalid subdomain');
    }
  };
}

export function isConsentCheckboxRequired(sectionName) {
  return sectionName === userConsentKeys.ACCOUNT_MANAGEMENT_CONSENT;
}

// Challenges
// TODO move to challenge folder

function hasRangeOrCustomDate(t) {
  return (value, allValues) => {
    if (!allValues[CHALLENGE_FORM_FIELDS.DURATION_PRESET] && !value) {
      return t(REQUIRED);
    } else if (isArray(value) && size(value) < 2) {
      return t('validationErrors/dateRange/stub');
    }
  };
}

function hasDateRangeCorrectOrder(t) {
  return value => {
    if (!isArray(value)) {
      return;
    }
    const [from, to] = value;
    if (moment(to).isBefore(moment(from))) {
      return t('validationErrors/dateRange/order');
    }
  };
}

function doesRangeEndInPast(t) {
  return value => {
    if (!isArray(value)) {
      return;
    }
    const to = value[1];
    const now = moment();
    if (moment(to).isBefore(now)) {
      return t('validationErrors/dateRange/past');
    }
  };
}

export function validateCustomDuration(t) {
  return composeValidators(
    hasRangeOrCustomDate(t),
    doesRangeEndInPast(t),
    hasDateRangeCorrectOrder(t)
  );
}

export function validateCustomDurationSingleInput(t) {
  return (_, allValues) => {
    const range = [getStartTime(allValues), getCloseTime(allValues)];
    return validateCustomDuration(t)(range, allValues);
  };
}

export function languageChoice(t, languages) {
  return (_, allValues) => {
    if (allValues.language && !includes(allValues.language, languages)) {
      return t(INVALID);
    }
  };
}

export function requiredUser(t) {
  return value => {
    if (isEmpty(getUserId(value))) {
      return t(REQUIRED);
    }
  };
}

export function isUserInviteRequired(t) {
  return (value, allValues) => {
    if (allValues.visibility === ChallengeVisiblity.PRIVATE && isEmpty(value)) {
      return t(REQUIRED);
    }
  };
}

export function isInvitedUserValid(allowedEmailRegex) {
  return user => {
    const hash = get(user, 'hash');

    if (hash) {
      return hash;
    }

    const email = getEmailFromInviteeDisplayName(user);
    return allowedEmailRegex.test(email);
  };
}

export function validateInvitedUsers(t, validationRegex) {
  return value => {
    if (!value) {
      return;
    }

    const users = isArray(value) ? value : [value];

    if (!users.every(isInvitedUserValid(validationRegex))) {
      return renderError(t, INVALID_USER_INVITE);
    }
  };
}

export const validateInvitees = (t, validationRegex) =>
  composeValidators(
    isUserInviteRequired(t),
    validateInvitedUsers(t, validationRegex)
  );

export function validateGroupIdeationDateTime(t) {
  return (value, allValues) => {
    if (allValues.groupIdeationTimeZone && !value) {
      return t(REQUIRED);
    }
  };
}

export function validateGroupIdeationTimeZone(t) {
  return (value, allValues) => {
    if (allValues.groupIdeationDateTime && !value) {
      return t(REQUIRED);
    }
  };
}

export const validateTitle = t =>
  composeValidators(required(t), maxlength(t, CHALLENGE_TITLE_MAXLENGTH));

export const validateLoginEmail = t => composeValidators(required(t), email(t));
