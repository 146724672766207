import { MODAL_OPEN } from '../../constants';
import EventTracker from './_EventTracker';
import {
  SOCIAL_LOGIN_CLICKED,
  LOGIN_BUTTON_CLICK
} from '../../authentication/actions/metrics';

const EVENT_GROUP = 'login';

class LoginTracker extends EventTracker {
  constructor(metrics) {
    super(metrics);
    this.eventGroup = EVENT_GROUP;
  }

  track(action, userId) {
    if (
      action.type === MODAL_OPEN &&
      action.payload.modalType === 'MAGIC_LINK_REQUEST'
    ) {
      this.coreTrack('MAGIC_LINK_REQUEST_OPEN');
    } else if (action.type === LOGIN_BUTTON_CLICK) {
      this.coreTrack(action.type, {});
    } else if (action.type === SOCIAL_LOGIN_CLICKED) {
      if (window.location.pathname.indexOf('login') > -1) {
        this.coreTrack(action.type, {
          provider: action.meta.provider
        });
      }
    }
  }
}

export default LoginTracker;
