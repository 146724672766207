import flow from 'lodash/flow';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { CHALLENGE_PERMISSIONS, FETCH_HASHTAGS } from '../../constants';

const sendEmailToEveryone = handleActions(
  {
    [CHALLENGE_PERMISSIONS.SUCCESS]: (state, { payload }) =>
      payload.sendEmailToEveryone
  },
  false
);

const featuredChallengeCanBeSet = handleActions(
  {
    [CHALLENGE_PERMISSIONS.SUCCESS]: (state, { payload }) =>
      payload.featuredChallengeCanBeSet
  },
  false
);

const companyWideChallengeCanBePublished = handleActions(
  {
    [CHALLENGE_PERMISSIONS.SUCCESS]: (state, { payload }) =>
      payload.companyWideChallengeCanBePublished
  },
  true
);

const customCreatorCanBeSet = handleActions(
  {
    [CHALLENGE_PERMISSIONS.SUCCESS]: (state, { payload }) =>
      payload.customCreatorCanBeSet
  },
  false
);

const hashtags = handleActions(
  {
    [FETCH_HASHTAGS.SUCCESS]: (state, { payload }) => payload
  },
  []
);

export default combineReducers({
  sendEmailToEveryone,
  featuredChallengeCanBeSet,
  companyWideChallengeCanBePublished,
  customCreatorCanBeSet,
  hashtags
});

export const getChallengeRoot = get('challengeSettings');
export const hashtagsSelector = state =>
  flow(
    getChallengeRoot,
    getOr([], 'hashtags')
  )(state);
export const isAllSendAllowedSelector = state =>
  flow(
    getChallengeRoot,
    get('sendEmailToEveryone')
  )(state);
export const isFeaturedAllowedSelector = state =>
  flow(
    getChallengeRoot,
    get('featuredChallengeCanBeSet')
  )(state);
export const isPublicAllowedSelector = state =>
  flow(
    getChallengeRoot,
    get('companyWideChallengeCanBePublished')
  )(state);
export const isCreatorEditableSelector = state =>
  flow(
    getChallengeRoot,
    get('customCreatorCanBeSet')
  )(state);
