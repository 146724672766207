import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import size from 'lodash/size';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { TagsInputFormControl } from '../../../common/components';
import {
  searchUsers,
  flushUserHits,
  selectors as userSelectors
} from '../../../common/state/users';
import { USER_AUTOSELECT_TAG_DISPLAY_PROP } from '../../../constants';
import { API_QUERY_DEBOUNCE } from '../../../api/constants';
import localiseUserName from '../../../utils/localiseUserName';
import { isInvitedUserValid } from '../../../utils/validation';

const INVALID_TAG_CLASSNAME = 'TagsInput__Tag--invalid';

class UserTagsInputFormControl extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    minCount: PropTypes.number,
    validationRegex: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired
  };

  componentWillUnmount() {
    this.props.flushUserHits();
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.props.searchUsers(value);
  };

  getDisplayName = sug => {
    const localisedName = localiseUserName(this.props.t, sug);

    if (localisedName) {
      return localisedName;
    }

    return get(sug, USER_AUTOSELECT_TAG_DISPLAY_PROP, '').toString();
  };

  renderSuggestion = sug => <span>{this.getDisplayName(sug)}</span>;

  getTagClassName = tag => (this.isTagValid(tag) ? '' : INVALID_TAG_CLASSNAME);

  convertDisplayValueForRendering = tag => {
    if (isObject(tag)) {
      return {
        ...tag,
        [USER_AUTOSELECT_TAG_DISPLAY_PROP]: this.getDisplayName(
          tag
        ).toLowerCase()
      };
    }

    return {
      [USER_AUTOSELECT_TAG_DISPLAY_PROP]: tag
    };
  };

  isTagValid = isInvitedUserValid(this.props.validationRegex);

  handleChange = (tags = []) => {
    const { input } = this.props;
    const mappedTags = tags.map(this.convertDisplayValueForRendering);

    input.onChange(mappedTags);
  };

  render() {
    const {
      autoFocus,
      validationRegex,
      suggestions,
      input,
      meta,
      minCount,
      ...rest
    } = this.props;
    const disableDelete = isNumber(minCount)
      ? size(get(input, 'value', [])) <= minCount
      : false;

    return (
      <TagsInputFormControl
        {...rest}
        input={{
          ...input,
          autoFocus,
          disableDelete,
          onChange: this.handleChange
        }}
        meta={meta}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        getSuggestionValue={this.getDisplayName}
        renderSuggestion={this.renderSuggestion}
        getTagDisplayValue={this.getDisplayName}
        getTagClassName={this.getTagClassName}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    suggestions: userSelectors.getSearchHits(state)
  };
}

const mapDispatchToProps = dispatch => ({
  searchUsers: debounce(
    (...args) => dispatch(searchUsers(...args)),
    API_QUERY_DEBOUNCE
  ),
  flushUserHits: () => dispatch(flushUserHits())
});

export { UserTagsInputFormControl };

export default compose(
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserTagsInputFormControl);
