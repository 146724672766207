import React, { useContext, useEffect } from 'react';
import { translate } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import AuthPageHeader from '../../../authentication/components/AuthPageHeader';
import { AuthContext } from '../../../authentication/components/AuthProvider';
import Heading from '../../../common/components/Heading';
import Paragraph from '../../../common/components/Paragraph';
import useQueryParams from '../../../common/hooks/useQueryParams';
import { lightGrayScale } from '../../../common/styled/colors.styled';
import paths from '../../paths';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.main`
  width: 640px;
  max-width: 100vw;
  padding: 1rem;
  text-align: center;
`;

function PaymentStartLandingPage({ t }) {
  const { token } = useQueryParams();
  const { loggedIn, logoutUser } = useContext(AuthContext);

  useEffect(
    function maybeLogout() {
      if (loggedIn) {
        logoutUser(true);
      }
    },
    [loggedIn, logoutUser]
  );

  return token ? (
    <Redirect to={paths.platformCreationPage(token)} />
  ) : (
    <>
      <AuthPageHeader
        showAuthLinks={false}
        style={{
          borderBottom: `1px solid ${lightGrayScale[0]}`,
          marginBottom: '1em'
        }}
      />
      <Wrapper>
        <Content>
          <Heading level={1}>{t('platformCreation/azureError/title')}</Heading>
          <Paragraph>{t('platformCreation/azureError/description')}</Paragraph>
          <Paragraph>
            <a
              href="https://azuremarketplace.microsoft.com/"
              target="_blank"
              rel="noreferrer noopener">
              {t('platformCreation/azureError/redirectCTA')}
            </a>
          </Paragraph>
        </Content>
      </Wrapper>
    </>
  );
}

export default translate()(PaymentStartLandingPage);
