import React, { useCallback } from 'react';
import size from 'lodash/size';
import { translate } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import { useSelector } from 'react-redux';
import useMountEffect from '../../../common/hooks/useMountEffect';
import {
  isAmdocsVariant,
  isCepheidVariant,
  isConnectHumanityVariant
} from '../../../utils/variants';
import { getVisibleIdeaConcepts } from '../../selectors/conceptList';
import useListFetcher from '../../hooks/conceptList/useListFetcher';
import useIsListLoading from '../../hooks/conceptList/useIsListLoading';
import useSorting from './hooks/useSorting';
import Button from '@bit/be-novative.kit.button';
import Spinner from '@bit/be-novative.kit.spinner';
import Grid from '../../../common/components/Grid';
import ConceptTable from '../../containers/ConceptTable';
import CepheidConceptTable from '../../containers/ConceptTable/CepheidConceptTable';
import ConceptListToolbar from '../ConceptListToolbar';
import Placeholder from './Placeholder';

const Table =
  isCepheidVariant() || isAmdocsVariant() || isConnectHumanityVariant()
    ? CepheidConceptTable
    : ConceptTable;

function ConceptList({ t }) {
  const loading = useIsListLoading();
  const concepts = useSelector(getVisibleIdeaConcepts);
  const hasContent = size(concepts) > 0;
  const fetchList = useListFetcher();

  const handleNextPage = useCallback(
    isEndOfPage => {
      if (isEndOfPage && hasContent && !loading) {
        fetchList();
      }
    },
    [hasContent, fetchList, loading]
  );

  useMountEffect(function fetchFirstPage() {
    if (!hasContent) {
      fetchList();
    }
  });

  const [sortBy, sortDirection, handleSortChange] = useSorting({
    onChange: fetchList
  });

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <ConceptListToolbar hasContent={hasContent} data-test="toolbar" />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xsGutterY={1}>
          <Table
            concepts={concepts}
            sortDisabled={loading}
            onSortUpdate={handleSortChange}
            sortBy={sortBy}
            sortDirection={sortDirection}
          />
          <VisibilitySensor onChange={handleNextPage}>
            <div style={{ width: 1, height: 1 }} />
          </VisibilitySensor>
          {loading && (
            <div className="h-text-center" style={{ marginTop: 15 }}>
              <Spinner type="benovative" size="medium" />
            </div>
          )}
          {!hasContent && !loading && (
            <Grid.Container>
              <Grid.Row>
                <Grid.Col xsGutterY={1}>
                  <Placeholder data-test="placeholder" />
                </Grid.Col>
              </Grid.Row>
            </Grid.Container>
          )}
          {hasContent && !loading && (
            <div className="h-text-center" style={{ marginTop: 15 }}>
              <Button onClick={fetchList}>
                {t('ideaConcepts/listPage/loadNextPage')}
              </Button>
            </div>
          )}
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
}

export default translate()(ConceptList);
