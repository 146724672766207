import { useSelector } from 'react-redux';
import { isAdminSelector } from '../../../profile/state/profile';
import { ownProfileSelector } from '../../../profile/state/profile';

/**
 * Checks whether the user can edit the feedback belonging to the idea concept.
 * @param {string|number} [feedbackAuthorId] - ID of the feedback author. Should be empty if the group feedback is edited.
 * @returns {boolean} `true` if the user can edit the feedback
 */
export default function useCanEditFeedback(feedbackAuthorId) {
  const isAdmin = useSelector(isAdminSelector);
  const ownUserId = useSelector(ownProfileSelector).userId;
  const canEditGroupFeedback = isAdmin && !feedbackAuthorId;
  const canEditOwnFeedback = isAdmin && feedbackAuthorId === ownUserId;
  return canEditGroupFeedback || canEditOwnFeedback;
}
