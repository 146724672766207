import get from 'lodash/get';
import { ConceptCanvasConfig } from '../../ideaConcept/config';
import { getPlatformName } from '../../utils/platform';

/**
 * Returns the ID of the allowed concept canvas background type by checking the concept config file.
 * If the platform has no setting, a default canvas type is returned.
 * @return {(CanvasBackgroundType|CanvasBackgroundType[])} One or more canvas background types
 */
export default function useAllowedCanvasBackgroundType() {
  return get(
    ConceptCanvasConfig,
    getPlatformName(),
    ConceptCanvasConfig._default
  );
}
