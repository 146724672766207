import React from 'react';
import Icon from '../Icon';
import StyleButton from './StyleButton';

const BLOCK_TYPES = [
  { label: <Icon type="list-ul" size="lg" />, style: 'unordered-list-item' },
  { label: <Icon type="list-ol" size="lg" />, style: 'ordered-list-item' }
];

const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichText__Controls">
      {BLOCK_TYPES.map(type =>
        <StyleButton
          key={type.style}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

export default BlockStyleControls;
