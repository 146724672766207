import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import produce from 'immer';
import { StickyGroup, Table } from './ConceptTable.styled';
import useTableConfig from './useTableConfig';
import RowGroup from './RowGroup';
import { ScrollSpyProvider } from './ScrollSpyProvider';
import TableBodyWithScroll from './TableBodyWithScroll';

export default function ConceptTable({
  concepts,
  sortDisabled,
  onSortUpdate,
  sortBy,
  sortDirection
}) {
  const [leftStickyCols, bodyCols, rightStickyCols] = useTableConfig();
  const getWidthSum = cols =>
    cols.reduce((sum, col) => sum + parseInt(col.width), 0) + 'px';
  const totalLeftStickWidthPx = getWidthSum(leftStickyCols);
  const totalRightStickyWidthPx = getWidthSum(rightStickyCols);

  const [expandStates, setExpandStates] = useState({});
  const getExpandState = id => expandStates[id];
  const handleExpandClick = useCallback(
    function handleExpandClick(id) {
      const rowState = expandStates[id];
      if (rowState === 'open') {
        setExpandStates(state => ({ ...state, [id]: 'closed' }));
      } else if (rowState === 'closed' || !rowState) {
        setExpandStates(state => ({ ...state, [id]: 'open' }));
      }
    },
    [expandStates]
  );

  const [cellSizes, setCellSizes] = useState({});
  const getCellSize = id => {
    const size = get(cellSizes, id);
    return {
      width: size ? size.width + 'px' : null,
      height: size ? size.height + 'px' : null
    };
  };
  const handleCellSizeChange = useCallback((id, width, height) => {
    setCellSizes(
      produce(sizes => {
        sizes[id] = { width, height };
      })
    );
  }, []);

  return (
    <ScrollSpyProvider>
      <Table role="table">
        {leftStickyCols.length > 0 && (
          <StickyGroup role="rowgroup" width={totalLeftStickWidthPx}>
            <RowGroup
              cols={leftStickyCols}
              rows={concepts}
              getCellSize={getCellSize}
              getExpandState={getExpandState}
              onExpandRow={handleExpandClick}
              onCellSizeChange={handleCellSizeChange}
              onSortUpdate={onSortUpdate}
              sortDisabled={sortDisabled}
              sortBy={sortBy}
              sortDirection={sortDirection}
              controlsRowHeight
            />
          </StickyGroup>
        )}

        <TableBodyWithScroll>
          <RowGroup
            cols={bodyCols}
            rows={concepts}
            getCellSize={getCellSize}
            getExpandState={getExpandState}
            onExpandRow={handleExpandClick}
            onCellSizeChange={handleCellSizeChange}
            onSortUpdate={onSortUpdate}
            sortDisabled={sortDisabled}
            sortBy={sortBy}
            sortDirection={sortDirection}
            withScrollSpy
          />
        </TableBodyWithScroll>

        {rightStickyCols.length > 0 && (
          <StickyGroup role="rowgroup" width={totalRightStickyWidthPx}>
            <RowGroup
              cols={rightStickyCols}
              rows={concepts}
              getCellSize={getCellSize}
              getExpandState={getExpandState}
              onExpandRow={handleExpandClick}
              onCellSizeChange={handleCellSizeChange}
              onSortUpdate={onSortUpdate}
              sortDisabled
            />
          </StickyGroup>
        )}
      </Table>
    </ScrollSpyProvider>
  );
}
