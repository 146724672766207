import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import { createSelector } from 'reselect';
import { getAllIdsFrom } from '../../common/selectors/entities';

export const rootSelector = state => get(state, 'evaluation');

const offsetSelector = state =>
  flow(
    rootSelector,
    getFp('offset')
  )(state);

const allIdsSelector = state =>
  flow(
    rootSelector,
    getAllIdsFrom
  )(state);

const byIdSelector = state =>
  flow(
    rootSelector,
    getFp('byId')
  )(state);

export const activeIdeaSelector = createSelector(
  byIdSelector,
  allIdsSelector,
  offsetSelector,
  (byId, allIds, offset) => byId[allIds[offset]]
);

export const isFetchingNextIdeaSelector = state =>
  flow(
    rootSelector,
    getFp('fetching')
  )(state);

export const targetCountSelector = state =>
  flow(
    rootSelector,
    getFp('targetCount')
  )(state);

export const evaluatedCountSelector = state =>
  flow(
    rootSelector,
    getFp('evaluatedCount')
  )(state);

export const skippedCountSelector = state =>
  flow(
    rootSelector,
    getFp('skippedCount')
  )(state);

export const overallProgressSelector = state =>
  flow(
    rootSelector,
    getFp('overallProgress')
  )(state);

export const isAllEvaluatedSelector = state =>
  flow(
    rootSelector,
    getFp('isAllEvaluated')
  )(state);

export const isSkipAvailableSelector = state =>
  flow(
    rootSelector,
    getFp('isSkipAvailable')
  )(state);

export const remainedCountSelector = createSelector(
  targetCountSelector,
  evaluatedCountSelector,
  (targetCount, evaluatedCount) => Math.max(targetCount - evaluatedCount, 0)
);

export const hasNextSelector = createSelector(
  offsetSelector,
  offset => offset > 0
);

export const hasPrevSelector = createSelector(
  offsetSelector,
  allIdsSelector,
  (offset, allIds) => offset < allIds.length - 1
);

export const hasUserPerformedSuggestion = state =>
  get(state, 'performedSuggestion');

export const isSuggestionCompletionLoaded = state =>
  flow(
    hasUserPerformedSuggestion,
    state => state !== null
  )(state);
