import * as ConfigCat from 'configcat-js';
import { getPlatformName } from '../utils/platform';

const POLLING_INTERVAL = 60;

const targetingData = { custom: { platformName: getPlatformName() } };

const logger = ConfigCat.createConsoleLogger(1);

const configCatClient = ConfigCat.createClientWithLazyLoad(
  process.env.REACT_APP_CONFIGCAT_SDK_KEY,
  {
    cacheTimeToLiveSeconds: POLLING_INTERVAL,
    logger
  }
);

export async function getFeatureFlagAsync(flagKey, fallbackValue) {
  return configCatClient.getValueAsync(flagKey, fallbackValue, targetingData);
}

export function getAllFeatureFlagKeys(callback) {
  return configCatClient.getAllKeys(callback);
}
