import client from '../../api';
import { getApiResponseData } from '../../api/utils/apiResponse';
import logRequestFailure from '../../api/utils/logRequestFailure';
import addBreadcrumb from '../../telemetry/addBreadcrumb';

async function fetchConceptById(conceptId) {
  addBreadcrumb('Fetching idea concept...', {
    category: 'idea-concept',
    data: { conceptId }
  });
  try {
    const result = await client.get(`/ideaconcepts/ideaconcept/${conceptId}`);
    return getApiResponseData(result);
  } catch (error) {
    logRequestFailure(error, 'Could not fetch idea concept', 'idea-concept');
    throw error;
  }
}

async function updateConcept(conceptId, payload) {
  addBreadcrumb('Updating idea concept...', {
    category: 'idea-concept',
    data: { conceptId, payload }
  });
  try {
    return await client.put(`/ideaconcepts/${conceptId}`, payload);
  } catch (error) {
    logRequestFailure(error, 'Could not update idea concept', 'idea-concept');
    throw error;
  }
}

export default {
  fetchConceptById,
  updateConcept
};
