import { mapPositionFromMsg } from './_mappers';

export const normaliseMoveMsg = msg => {
  const [itemId, position, actorUserId] = msg;
  return {
    ...mapPositionFromMsg({ position }),
    itemId,
    userId: actorUserId
  };
};
