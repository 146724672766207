import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import getOrFp from 'lodash/fp/getOr';
import mapFp from 'lodash/fp/map';
import {
  createAsyncThunk,
  createSlice,
  createSelector
} from '@reduxjs/toolkit';
import { AsyncStatus } from '../../constants';
import conceptReviewApi from '../../ideaConcept/api/conceptReviewApi';
import { convertApiErrorToStatusCode } from '../../api/utils/apiError';
import platformApi from '../api/platformApi';

const fetchPlatformData = createAsyncThunk('fetchPlatformData', () =>
  Promise.all([
    platformApi.fetchOwnPlatform(),
    conceptReviewApi.fetchDefaultReviewCriteria()
  ])
);

const updateConceptReviewCriteria = createAsyncThunk(
  'updateConceptReviewCriteria',
  async (arg, thunkApi) => {
    try {
      return await conceptReviewApi.updateDefaultReviewCriteria(arg);
    } catch (error) {
      return thunkApi.rejectWithValue({
        error,
        code: convertApiErrorToStatusCode(error)
      });
    }
  }
);

const initialState = {
  data: {},
  status: AsyncStatus.Idle,
  error: null
};

const platformSlice = createSlice({
  name: 'platform',
  initialState,
  extraReducers: {
    [fetchPlatformData.pending]: state => {
      state.status = AsyncStatus.Loading;
      state.error = null;
    },
    [fetchPlatformData.fulfilled]: (state, { payload = [] }) => {
      const [platformData = {}, defaultConceptReviewCriteria] = payload;
      const { emailRegex, ...platformSettings } = platformData;
      state.data = {
        ...platformSettings,
        allowedEmailDomains: new RegExp(emailRegex),
        defaultConceptReviewCriteria
      };
      state.status = AsyncStatus.Succeeded;
    },
    [updateConceptReviewCriteria.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.data.defaultConceptReviewCriteria = payload;
      }
    },
    [fetchPlatformData.rejected]: (state, action) => {
      state.status = AsyncStatus.Failed;
      state.error = action.error;
    }
  }
});

const platformStoreSelector = getFp('platform');

const platformDataSelector = flow(
  platformStoreSelector,
  getFp('data')
);

const defaultReviewCriteriaSelector = createSelector(
  platformDataSelector,
  getOrFp([], 'defaultConceptReviewCriteria')
);

const reviewCriteriaLabelsSelector = createSelector(
  defaultReviewCriteriaSelector,
  mapFp(getFp('name'))
);

export {
  fetchPlatformData,
  updateConceptReviewCriteria,
  platformStoreSelector,
  platformDataSelector,
  defaultReviewCriteriaSelector,
  reviewCriteriaLabelsSelector
};

export default platformSlice;
