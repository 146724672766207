import React, { useCallback, useContext, useState } from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { translate, Trans } from 'react-i18next';
import { AuthContext } from '../../../authentication/components/AuthProvider';
import { openModal } from '../../../common/actions/modal';
import ModalRoot from '../../../common/containers/ModalRoot';
import { deleteProfile } from '../../../profile/state/profile';
import Button from '@bit/be-novative.kit.button';
import Callout from '../../../common/components/Callout';
import ConfirmDialog from '../../../common/components/ConfirmDialog';
import Grid from '../../../common/components/Grid';
import { CUSTOMER_SUPPORT_EMAIL } from '../../../constants';
import translateApiErrorMessage from '../../../utils/translateApiErrorMessage';

const CONFIRM_MODAL = 'CONFIRM_MODAL';

function DeleteProfile({ t }) {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const Auth = useContext(AuthContext);

  const handleDelete = useCallback(
    async function handleSubmit() {
      setSubmitting(true);
      setError(null);

      try {
        unwrapResult(await dispatch(deleteProfile()));
        Auth.logoutUser();
      } catch (error) {
        setError(get(error, 'code'));
      }

      setSubmitting(false);
    },
    [Auth, dispatch]
  );

  const showConfirmDialog = useCallback(
    function showConfirmDialog() {
      dispatch(
        openModal({
          modalType: CONFIRM_MODAL,
          modalProps: {
            isCloseButtonShown: false,
            rounded: true,
            variant: 'danger',
            title: t('profile/deleteAccount/confirmDialog/title'),
            okLabel: t('profile/deleteAccount/confirmDialog/okCTA'),
            cancelLabel: t('profile/deleteAccount/confirmDialog/cancelCTA'),
            okCallback: handleDelete
          }
        })
      );
    },
    [dispatch, handleDelete, t]
  );

  return (
    <Grid.Row gutter={0}>
      <Grid.Col xsGutterX={2} md={8} lg={6}>
        <h1>{t('profile/deleteAccount/title')}</h1>
        <p>{t('profile/deleteAccount/subtitle')}</p>
        <p>
          <Trans
            i18nKey="profile/deleteAccount/emailPrompt"
            values={{ email: CUSTOMER_SUPPORT_EMAIL }}>
            <a
              href={`mailto:${CUSTOMER_SUPPORT_EMAIL}?subject=Account deletion request`}>
              {CUSTOMER_SUPPORT_EMAIL}
            </a>
          </Trans>
        </p>
        <h4>{t('profile/deleteAccount/infoSection/title')}</h4>
        <p>{t('profile/deleteAccount/infoSection/info1')}</p>
        <p>{t('profile/deleteAccount/infoSection/info2')}</p>
        <h4>{t('profile/deleteAccount/actionTitle')}</h4>
        <p>
          <Button
            onClick={showConfirmDialog}
            variant={'danger'}
            loading={submitting}
            fillParent>
            {t('profile/deleteAccount/deleteCTA')}
          </Button>
        </p>
        {error && (
          <Callout type="danger" tag="p" data-test="fail">
            {translateApiErrorMessage(t, error)}
          </Callout>
        )}
      </Grid.Col>
      <ModalRoot modalComponents={{ [CONFIRM_MODAL]: ConfirmDialog }} />
    </Grid.Row>
  );
}

export default translate()(DeleteProfile);
