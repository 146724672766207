import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './Embed.css';

function Embed(props) {
  const { children, ratio } = props;
  return (
    <div className={cn('Embed', `Embed--ratio-${ratio}`)}>
      {cloneElement(children, { className: 'Embed__Item' })}
    </div>
  );
}

Embed.propTypes = {
  ratio: PropTypes.oneOf(['16by9'])
};

Embed.defaultProps = {
  ratio: '16by9'
};

export default Embed;
