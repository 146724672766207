import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { closeModal } from '../../../common/actions';
import withModal from '../../containers/withModal';
import { Button, Panel } from '../';
import './ConfirmDialog.css';

export class ConfirmDialog extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    okLabel: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string.isRequired,
    okCallback: PropTypes.func,
    cancelCallback: PropTypes.func,
    autoClose: PropTypes.bool,
    isLoading: PropTypes.bool,
    variant: PropTypes.string
  };

  static defaultProps = {
    okCallback: () => {},
    cancelCallback: () => {},
    variant: 'primary',
    autoClose: true
  };

  onConfirm = () => {
    const { autoClose, closeModal, okCallback } = this.props;

    okCallback();

    if (autoClose) {
      closeModal();
    }
  };

  onCancel = () => {
    const { closeModal, cancelCallback } = this.props;

    cancelCallback();
    closeModal();
  };

  render() {
    const className = 'ConfirmDialog';
    const {
      title,
      text,
      children,
      okLabel,
      cancelLabel,
      variant,
      isLoading
    } = this.props;

    return (
      <Panel className={className}>
        <h3 className={`${className}__Title`}>{title}</h3>
        <div className={`${className}__Body`}>
          <p>{text}</p>
          {children}
        </div>
        <footer className={`${className}__Footer`}>
          <Button
            onClick={this.onCancel}
            disabled={isLoading}
            variant={'ghost-gray'}
            className={`${className}__Button ${className}__Button--cancel`}>
            {cancelLabel}
          </Button>
          <Button
            onClick={this.onConfirm}
            disabled={isLoading}
            variant={variant}
            className={`${className}__Button ${className}__Button--ok`}>
            {okLabel}
          </Button>
        </footer>
      </Panel>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  closeModal
};

export default compose(withModal(mapStateToProps, mapDispatchToProps))(
  ConfirmDialog
);
