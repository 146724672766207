import get from 'lodash/get';
import memoize from 'lodash/memoize';

export const getUserId = memoize(user => get(user, 'userId'));

export const getFirstName = user => get(user, 'firstName', '');

export const getLastName = user => get(user, 'lastName', '');

/**
 * Returns the user's avatar image data
 * @param {User} user
 * @return {ImageObject} The image may contain a public URL or a Base64-encoded image
 */

export const getUserAvatar = user => get(user, 'image', {});

export const getUserEmail = memoize(user => get(user, 'email'), getUserId);

export const isOnline = user => get(user, 'online');

// Invitee-specific selectors

export const getUserHash = user => get(user, 'hash', '');

export const getUserDisplayName = user => get(user, 'displayName', '');
