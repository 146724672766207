import React, { useCallback } from 'react';
import get from 'lodash/get';
import noop from 'lodash/noop';
import classNames from 'classnames';
import ReactAutosuggest from 'react-autosuggest';
import './Autosuggest.css';

/**
 * Simple theme wrapper around 3rdparty Autosuggest component.
 */
function Autosuggest({
  inputProps = {},
  marked,
  meta = {},
  focusFirstSuggestion = true,
  onSuggestionsFetchRequested = noop,
  onSuggestionsClearRequested = noop,
  onSuggestionSelected = noop,
  ...rest
}) {
  const inputClasses = classNames('Autosuggest__Input', {
    'Autosuggest__Input--marked': marked,
    'Autosuggest__Input--error': get(meta, 'submitFailed') && get(meta, 'error')
  });

  const handleSelected = useCallback(
    function handleSelected(event, selected) {
      event.preventDefault();
      onSuggestionSelected(event, selected);
    },
    [onSuggestionSelected]
  );

  return (
    <ReactAutosuggest
      {...rest}
      inputProps={inputProps}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionSelected={handleSelected}
      theme={{
        container: 'Autosuggest__Container',
        containerOpen: 'Autosuggest__Container--open',
        input: inputClasses,
        suggestionsContainer: 'Autosuggest__SuggestionsContainer',
        suggestionsList: 'Autosuggest__SuggestionsList',
        suggestion: 'Autosuggest__Suggestion',
        suggestionHighlighted: 'Autosuggest__Suggestion--focused',
        sectionContainer: 'Autosuggest__SectionContainer',
        sectionTitle: 'Autosuggest__SectionTitle'
      }}
    />
  );
}
export default Autosuggest;
