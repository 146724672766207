import get from 'lodash/get';

/**
 * @param {ImageObject} image
 * @return {ImageTypes}
 */

export const getType = image => get(image, '@type');

/**
 * Returns the encoded image content.
 * Encoded content is only used for images loaded in browser memory,
 * because the API always returns with URLs, even for uploaded images.
 * @param {ImageObject} image
 * @return {string} The encoded image content or an empty string
 */

export const getContent = image => get(image, 'content') || '';

/**
 * Returns encoded image content as a valid data URI that includes encoding type and image file type as well.
 * @param {ImageObject} image
 * @return {string} The image content as data URI
 */
export const getContentDataUrl = image => {
  const dataType = get(image, 'dataType');
  const fileType = get(image, 'fileType');
  const content = getContent(image);
  return content ? `data:${fileType};${dataType},${content}` : '';
};

/**
 * @param {ImageObject} image
 * @return {string}
 */

export const getUrl = image => get(image, 'url', '');
