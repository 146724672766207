import flow from 'lodash/flow';
import head from 'lodash/head';
import get from 'lodash/fp/get';
import isEqual from 'lodash/isEqual';
import { ALERT_SEND, ALERT_DISMISS, ALERT_CLEAR } from '../../constants';

export default function(state = [], action) {
  if (!action || !action.type) return state;

  switch (action.type) {
    case ALERT_SEND:
      return [
        action.payload,
        ...state.filter(({ id }) => id !== action.payload.id)
      ];
    case ALERT_DISMISS:
      return state.filter(alert => alert.id !== action.payload);
    case ALERT_CLEAR:
      return [];
    default:
      return state;
  }
}

const getId = get('id');

export const getCurrentAlert = state => head(state);

export const getCurrentAlertId = state =>
  flow(
    getCurrentAlert,
    getId
  )(state);

export const isAlertActive = alertId => state =>
  flow(
    getCurrentAlert,
    getId,
    isEqual(alertId)
  )(state);
