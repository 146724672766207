import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';
import { combineActions, handleActions } from 'redux-actions';
import forceArray from '../../../utils/forceArray';
import { UPDATE_ENTITY, ADD_ENTITY } from '../../actions/entities';

const addEntities = (state, { payload, meta }) => {
  const { entityType } = meta;
  const { entities, result } = payload;
  const newIds = forceArray(result);
  const mainEntityMappedIds = { [entityType]: newIds };
  const allIds = merge({}, state.allIds, mainEntityMappedIds);
  const newEntities = merge({}, state, entities);
  each(newIds, id =>
    set(newEntities, [entityType, id], entities[entityType][id])
  );

  return {
    ...newEntities,
    allIds
  };
};

const updateEntity = (state, { payload, meta }) => {
  const { entityType, id } = meta;
  const newState = cloneDeep(state);
  const existingEntity = get(state, [entityType, id], {});
  const updatedEntity = merge({}, existingEntity, payload);

  set(newState, [entityType, id], updatedEntity);
  return newState;
};

const entityReducerFactory = asyncAction => {
  return handleActions(
    {
      [combineActions(asyncAction.SUCCESS, ADD_ENTITY)]: addEntities,
      [UPDATE_ENTITY]: updateEntity
    },
    { allIds: {} }
  );
};

export default entityReducerFactory;
