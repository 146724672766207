import { PASSWORD_RESET } from '../../constants';
import { IDENTITY_API } from '../../api/constants';

/**
 * @deprecated Will be handled by auth app
 */
export const resetPassword = () => {
  return {
    [IDENTITY_API]: {
      url: '/profile/password/request',
      method: 'GET',
      type: PASSWORD_RESET
    }
  };
};
