import flow from 'lodash/flow';
import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { FETCH_IDEA_CONCEPT_BY_ID } from '../../../constants';
import { FETCH_CHALLENGE_IDEA_CONCEPTS } from '../../../ideaConcept/constants';
import { isLoadingSelector, isErrorSelector } from './commonMethods';
import pendingConceptRevealsFactory from './conceptRevealReducerFactory';
import pendingEditFactory from './pendingEditReducerFactory';

const loadingReducer = handleActions(
  {
    [FETCH_CHALLENGE_IDEA_CONCEPTS.PENDING]() {
      return true;
    },
    [combineActions(
      FETCH_CHALLENGE_IDEA_CONCEPTS.SUCCESS,
      FETCH_CHALLENGE_IDEA_CONCEPTS.ERROR
    )]() {
      return false;
    }
  },
  false
);

const errorReducer = handleActions(
  {
    [combineActions(
      FETCH_CHALLENGE_IDEA_CONCEPTS.PENDING,
      FETCH_CHALLENGE_IDEA_CONCEPTS.SUCCESS
    )]() {
      return false;
    },
    [FETCH_CHALLENGE_IDEA_CONCEPTS.ERROR]() {
      return true;
    }
  },
  false
);

const loadingByIdReducer = handleActions(
  {
    [FETCH_IDEA_CONCEPT_BY_ID.PENDING]() {
      return true;
    },
    [combineActions(
      FETCH_IDEA_CONCEPT_BY_ID.SUCCESS,
      FETCH_IDEA_CONCEPT_BY_ID.ERROR
    )]() {
      return false;
    }
  },
  false
);

const errorByIdReducer = handleActions(
  {
    [combineActions(
      FETCH_IDEA_CONCEPT_BY_ID.PENDING,
      FETCH_IDEA_CONCEPT_BY_ID.SUCCESS
    )]() {
      return false;
    },
    [FETCH_IDEA_CONCEPT_BY_ID.ERROR]() {
      return true;
    }
  },
  false
);

export default function ideaConceptsReducerFactory(rootSelector) {
  const getStateRoot = state => rootSelector(state);

  const bulkConceptSelectors = {
    isIdeaConceptLoadingSelector: createSelector(
      getStateRoot,
      isLoadingSelector
    ),
    isIdeaConceptErrorSelector: createSelector(getStateRoot, isErrorSelector)
  };

  const conceptByIdSelectors = {
    isIdeaConceptByIdLoadingSelector: createSelector(
      getStateRoot,
      state => state.byIdLoading
    ),
    isIdeaConceptByIdErrorSelector: createSelector(
      getStateRoot,
      state => state.byIdError
    )
  };

  const { isRevealPendingSelector } = pendingConceptRevealsFactory;
  const { isEditPendingSelector } = pendingEditFactory;

  return {
    reducer: combineReducers({
      loading: loadingReducer,
      error: errorReducer,
      byIdLoading: loadingByIdReducer,
      byIdError: errorByIdReducer,
      pendingReveals: pendingConceptRevealsFactory.reducer,
      pendingEdits: pendingEditFactory.reducer
    }),
    ...bulkConceptSelectors,
    ...conceptByIdSelectors,
    isRevealPendingSelector: isRevealPendingSelector(
      flow(getStateRoot, state => state.pendingReveals)
    ),
    isEditPendingSelector: isEditPendingSelector(
      flow(getStateRoot, state => state.pendingEdits)
    )
  };
}
