import forEach from 'lodash/forEach';
import isFunction from 'lodash/isFunction';
import isObjectLike from 'lodash/isObjectLike';
import isString from 'lodash/isString';
import { SOCKET_EVENTS } from '../constants';
import addBreadcrumb from '../../telemetry/addBreadcrumb';
import { Severity } from '@sentry/browser';

const { CONNECTED, RECONNECTING, DISCONNECTED, ERROR } = SOCKET_EVENTS;

export default class adapterClass {
  constructor(socketId, url) {
    this.socket = null;
    this.url = url;
    this.token = null;
    this.handlers = {};
    this.socketId = socketId;
    return this;
  }

  _log = ({ message, level = Severity.Verbose, data }) => {
    const prefixedMsg = message ? `[${this.socketId}] ${message}` : undefined;
    addBreadcrumb(prefixedMsg, {
      category: 'web-socket',
      level,
      data
    });
  };

  _validateEventId = eventId => {
    if (!isString(eventId)) {
      throw new Error(`eventId must be a string, got ${typeof eventId}`);
    }
  };

  _validateEventCallback = callback => {
    if (!isFunction(callback)) {
      throw new Error(`callback must be a function, got ${typeof callback}`);
    }
  };

  _addHandler = (callback, eventId) => {
    try {
      this._validateEventId(eventId);
      this._validateEventCallback(callback);
      this.handlers[eventId] = callback;
    } catch (error) {
      this._log({
        message: error,
        level: Severity.Error,
        data: { eventId, callback }
      });
      throw new Error(error);
    }
  };

  _removeHandler = eventId => {
    try {
      this._validateEventId(eventId);
      this.handlers[eventId] = undefined;
    } catch (error) {
      this._log({
        message: error,
        level: Severity.Error,
        data: { eventId }
      });
      throw new Error(error);
    }
  };

  _callHandler = (id, ...args) => {
    if (isFunction(this.handlers[id])) {
      this.handlers[id](...args);
    }
  };

  on(eventOrMap, callback) {
    if (isObjectLike(eventOrMap)) {
      forEach(eventOrMap, this._addHandler);
    } else {
      this._addHandler(callback, eventOrMap);
    }

    return this;
  }

  off(eventOrMap) {
    if (isString(eventOrMap)) {
      this._removeHandler(eventOrMap);
    } else {
      forEach(eventOrMap, this._removeHandler);
    }
  }

  withToken(newToken) {
    this.token = isFunction(newToken) ? newToken() : newToken;
    return this;
  }

  connect() {
    if (this.socket) {
      this._log({
        level: Severity.Warning,
        message: `Connection already live`
      });
      throw new Error('Already connected');
    }

    if (!this.token) {
      this._log({
        level: Severity.Warning,
        message: `Trying to connect without a token`
      });
    }

    // Implement custom socket logic
  }

  disconnect(response) {
    // Implement custom socket logic
    this._callHandler(DISCONNECTED, response);
  }

  send(payload) {
    this._log({ message: 'Sending message...', data: { payload } });

    if (!this.socket) {
      throw new Error('Trying to send message without being connected');
    }

    // Implement custom socket logic
  }

  onConnected = response => {
    this._log({ message: `Connection successful`, data: response });
    this._callHandler(CONNECTED, response);
  };

  onReconnect = response => {
    this._log({ message: 'Reconnecting', data: response });
    this._callHandler(RECONNECTING, response);
  };

  onDisconnect = () => {
    this._log({ message: 'Connection closed' });
    this._callHandler(DISCONNECTED);
  };

  onReceive = () => {
    // Implement custom socket logic
  };

  onError = response => {
    this._log({ level: Severity.Error, message: response });
    this._callHandler(ERROR, response);
  };
}
