import EventTracker from './_EventTracker';
import {
  USER_SIGNUP,
  CONTINUE_SIGNUP_BUTTON_CLICK,
  SOCIAL_SIGN_UP_BUTTON_CLICK
} from '../../authentication/actions/metrics';

const EVENT_GROUP = 'registration';

class RegistrationTracker extends EventTracker {
  constructor(metrics) {
    super(metrics);
    this.eventGroup = EVENT_GROUP;
  }

  track(action, userId) {
    if (action.type === USER_SIGNUP) {
      this.coreTrack(action.type, action.meta.metrics);
    } else if (action.type === SOCIAL_SIGN_UP_BUTTON_CLICK) {
      this.coreTrack(action.type, action.meta.metrics);
    } else if (action.type === CONTINUE_SIGNUP_BUTTON_CLICK) {
      this.coreTrack(action.type, {});
    }
  }
}

export default RegistrationTracker;
