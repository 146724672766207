export default class EventTracker {
  constructor(metrics) {
    this.metrics = metrics;
  }

  coreTrack(event, params = {}) {
    this.metrics.api.track(`${this.eventGroup}__${event}`, {
      ...params,
      eventCategory: this.eventGroup
    });
  }
}
