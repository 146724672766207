import AcceptCheckboxField from './AcceptCheckboxField';
import Alerts from './Alerts';
import Autosuggest from './Autosuggest';
import Avatar from './Avatar';
import Backdrop from './Backdrop';
import BranchByFeature from './BranchByFeature';
import BulletedList from './BulletedList';
import Button from '@bit/be-novative.kit.button';
import Callout from './Callout';
import CategorySelector from './CategorySelector';
import ChallengeInfoModal from './ChallengeInfoModal';
import Checkbox from '@bit/be-novative.kit.checkbox';
import CheckboxButtonGroup from './CheckboxButtonGroup';
import CheckboxGroup from './CheckboxGroup';
import CircleSpinner from './CircleSpinner';
import Close from './Close';
import Collapse from './Collapse';
import ConfirmDialog from './ConfirmDialog';
import CookieConsent from './CookieConsent';
import CountrySelect from './CountrySelect';
import DateTime from './DateTime';
import DateTimeInput from './DateTimeInput';
import DateTimePicker from './DateTimePicker';
import DateTimeRangeInput from './DateTimeRangeInput';
import DateTimeRangePicker from './DateTimeRangePicker';
import Dot from './Dot';
import DumbTextarea from './DumbTextarea';
import EditableText from './EditableText';
import EditButton from './EditButton';
import Ellipsis from './Ellipsis';
import Embed from './Embed';
import FadeSlideUpTransition from './FadeSlideUpTransition';
import FadeTransition from './FadeTransition';
import formControl from '@bit/be-novative.kit.form-control';
import FullScreen from './FullScreen';
import Grid from './Grid';
import GridList from './GridList';
import Heading from './Heading';
import Heart from './Heart';
import HelpIcon from './HelpIcon';
import Icon from './Icon';
import ImagePreview from './ImagePreview';
import Input from '@bit/be-novative.kit.input';
import IntercomClient from './IntercomClient';
import Label from './Label';
import LanguageDropdown from './LanguageDropdown';
import LegalUpdateModal from './LegalUpdateModal';
import LinkifiedSafeHtml from './LinkifiedSafeHtml';
import Media, { MediaList } from './Media';
import Modal from './Modal';
import MutedText from './MutedText';
import Navbar from './Navbar';
import NonIdealState from './NonIdealState';
import Notice from './Notice';
import OverflowCounter from '@bit/be-novative.kit.overflow-counter';
import OverlayAction from './OverlayAction';
import Pagination from './Pagination';
import Panel from './Panel';
import Paragraph from './Paragraph';
import Password from '@bit/be-novative.kit.password';
import Popover from './Popover';
import PrimaryNavigation from './PrimaryNavigation';
import Progress from './Progress';
import RadioButtonGroup from './RadioButtonGroup';
import RadioGroup from './RadioGroup';
import Responsive, { withResponsive } from './Responsive';
import RichText from './RichText';
import RightSlideTransition from './RightSlideTransition';
import Select from '@bit/be-novative.kit.select';
import Separator from './Separator';
import ShareButton from './ShareButton';
import SliderInput from './SliderInput';
import Spinner from '@bit/be-novative.kit.spinner';
import Table from './Table';
import TagsInput from './TagsInput';
import Text from './Text';
import TruncatedText from './TruncatedText';
import Textarea from './Textarea';
import Tooltip, { withTooltip } from './Tooltip';
import TwoColumnLayout from './TwoColumnLayout';
import UrlInput from './UrlInput';
import UserItem from './UserItem';

export const AutosuggestFormControl = formControl(Autosuggest);
export const CheckboxFormControl = formControl(Checkbox);
export const CheckboxGroupFormControl = formControl(CheckboxGroup);
export const CountrySelectFormControl = formControl(CountrySelect);
export const DateTimeInputFormControl = formControl(DateTimeInput);
export const DateTimeRangeInputFormControl = formControl(DateTimeRangeInput);
export const DumbTextareaFormControl = formControl(DumbTextarea);
export const InlineEditableAutosuggest = EditableText.inlineEditable(
  Autosuggest
);
export const InlineEditableDateTimeInput = EditableText.inlineEditable(
  DateTimeInput
);
export const InputFormControl = formControl(Input);
export const ImagePreviewFormControl = formControl(ImagePreview);
export const PasswordFormControl = formControl(Password);
export const RadioButtonGroupFormControl = formControl(RadioButtonGroup);
export const RadioGroupFormControl = formControl(RadioGroup);
export const RichTextFormControl = formControl(RichText);
export const SelectFormControl = formControl(Select);
export const TagsInputFormControl = formControl(TagsInput);
export const TextareaFormControl = formControl(Textarea);
export const SliderInputFormControl = formControl(SliderInput);
export const UrlInputFormControl = formControl(UrlInput);

export {
  AcceptCheckboxField,
  Alerts,
  Autosuggest,
  Avatar,
  Backdrop,
  BranchByFeature,
  BulletedList,
  Button,
  Callout,
  CategorySelector,
  ChallengeInfoModal,
  Checkbox,
  CheckboxButtonGroup,
  CheckboxGroup,
  CircleSpinner,
  Close,
  Collapse,
  ConfirmDialog,
  CookieConsent,
  DateTime,
  DateTimeInput,
  DateTimePicker,
  DateTimeRangePicker,
  Dot,
  DumbTextarea,
  EditableText,
  EditButton,
  Ellipsis,
  Embed,
  FadeSlideUpTransition,
  FadeTransition,
  formControl,
  FullScreen,
  Grid,
  GridList,
  Heading,
  Heart,
  HelpIcon,
  Icon,
  Input,
  IntercomClient,
  Label,
  LanguageDropdown,
  LegalUpdateModal,
  LinkifiedSafeHtml,
  Media,
  MediaList,
  Modal,
  MutedText,
  Navbar,
  NonIdealState,
  Notice,
  OverflowCounter,
  OverlayAction,
  Pagination,
  Panel,
  Paragraph,
  Popover,
  PrimaryNavigation,
  Progress,
  RadioButtonGroup,
  Responsive,
  RichText,
  RightSlideTransition,
  Separator,
  ShareButton,
  SliderInput,
  Spinner,
  Table,
  TagsInput,
  Text,
  TruncatedText,
  Textarea,
  Tooltip,
  UserItem,
  TwoColumnLayout,
  withResponsive,
  withTooltip
};
