import { combineReducers } from 'redux';
import {
  FETCH_IDEA_CONCEPTS,
  idProps,
  CREATE_IDEA_CONCEPT,
  FLUSH_CONCEPT_IDS,
  stagesWithOwnFilters,
  OTHER_FILTER_ID,
  conceptListFilterKeys,
  stagesWithOwnFiltersCepheid
} from '../constants';
import {
  CONCEPT_LIST_DEFAULT_STAGE_FILTER,
  CONCEPT_LIST_DEFAULT_SORT,
  CONCEPT_LIST_PAGE_SIZE
} from '../config';
import {
  entityIdReducerFactory,
  errorReducerFactory,
  loadingReducerFactory,
  paginationReducerFactory
} from '../../common/reducers/factories';
import filterReducerFactory from '../../common/reducers/factories/filter';
import sortingReducerFactory from '../../common/reducers/factories/sorting';
import reduceFiltersToReducers from '../utils/listReducer';
import { isCepheidVariant } from '../../utils/variants';

export const REDUCER_ID = 'conceptList';
const stageFilters = isCepheidVariant()
  ? stagesWithOwnFiltersCepheid
  : stagesWithOwnFilters;

const paginationReducer = paginationReducerFactory(
  {
    successActions: FETCH_IDEA_CONCEPTS.SUCCESS,
    flushActions: FLUSH_CONCEPT_IDS
  },
  {
    cursorBased: true,
    limit: CONCEPT_LIST_PAGE_SIZE
  }
);

export default combineReducers({
  loading: loadingReducerFactory(FETCH_IDEA_CONCEPTS),
  error: errorReducerFactory(FETCH_IDEA_CONCEPTS),
  filterBy: filterReducerFactory(REDUCER_ID, {
    [conceptListFilterKeys.STAGE]: CONCEPT_LIST_DEFAULT_STAGE_FILTER
  }),
  pagination: combineReducers({
    ...reduceFiltersToReducers(
      [...stageFilters, OTHER_FILTER_ID],
      paginationReducer
    )
  }),
  sort: sortingReducerFactory(FETCH_IDEA_CONCEPTS, CONCEPT_LIST_DEFAULT_SORT),
  visibleIds: entityIdReducerFactory(
    {
      successActions: [
        FETCH_IDEA_CONCEPTS.SUCCESS,
        CREATE_IDEA_CONCEPT.SUCCESS
      ],
      flushActions: FLUSH_CONCEPT_IDS
    },
    {
      idProp: idProps.IDEA_CONCEPT_ID
    }
  )
});
