import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { Component, cloneElement } from 'react';
import classNames from 'classnames';
import Button from '@bit/be-novative.kit.button';
import Popover from '../Popover';
import Icon from '../Icon';
import {
  formatLocalDateToUTCISOString as formatDate,
  formatUTCISOStringToLocalDate as formatLocal,
  parseLocalDateString as parseLocal
} from '../../../utils/date';
import './DateTimeInput.css';

class DateTimeInput extends Component {
  static propTypes = {
    picker: PropTypes.node.isRequired,
    input: PropTypes.shape({
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      value: PropTypes.string
    }),
    meta: PropTypes.shape({
      active: PropTypes.bool
    })
  };

  static defaultProps = {
    showIcon: true
  };

  constructor(props) {
    super(props);
    this.state = {
      inputValue: formatLocal(props.input.value) || '',
      open: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value: nextValue } = nextProps.input;
    const { value: prevValue } = this.props.input;

    if (nextValue && nextValue !== prevValue) {
      this.setState(() => ({
        inputValue: formatLocal(nextValue) || ''
      }));
    }

    if (
      this.props.meta.active !== nextProps.meta.active &&
      nextProps.meta.active
    ) {
      this.setState(() => ({
        open: nextProps.meta.active
      }));
    }
  }

  setInputRef = el => {
    this.input = el;
  };

  handleInputChange = e => {
    const { onChange } = this.props.input;
    const { value } = e.target;
    const localDate = parseLocal(value);

    if (localDate) {
      onChange(formatDate(localDate));
    } else {
      onChange(null);
    }

    this.setState({
      inputValue: value
    });
  };

  handleBlur = e => {
    const { input } = this.props;
    const { onBlur, value } = input;
    onBlur(value);
  };

  handleClose = () => {
    this.setState(() => ({
      open: false
    }));
  };

  handleIconClick = () => {
    this.focus();
  };

  focus() {
    if (isFunction(this.input.focus)) {
      this.input.focus();
    }
  }

  render() {
    const { input, meta, picker, showIcon } = this.props;
    const { onFocus, value, onChange, name, required } = input;
    const { active, touched, error, warning, submitFailed, submitting } = meta;
    const { inputValue, open } = this.state;

    const popover = cloneElement(picker, {
      value: value,
      ref: el => (this.picker = el),
      onBlur: this.handleBlur,
      onChange,
      onFocus,
      closeButton: picker.props.closeButton
        ? cloneElement(picker.props.closeButton, {
            onClick: this.handleClose
          })
        : null
    });

    const classes = classNames('DateTimeInput', {
      'DateTimeInput--error': (touched || submitFailed || submitting) && error,
      'DateTimeInput--warning':
        (touched || submitFailed || submitting) && warning,
      'DateTimeInput--active': active
    });

    return (
      <Popover.PopoverTrigger
        show={open}
        popover={popover}
        onClose={this.handleClose}
        style={{ display: 'block' }}>
        <div className={classes}>
          <input
            type="text"
            ref={this.setInputRef}
            className="DateTimeInput__Input"
            value={inputValue}
            onChange={this.handleInputChange}
            onFocus={onFocus}
            onBlur={this.handleBlur}
            id={name}
            required={required}
          />
          {showIcon && (
            <div className="DateTimeInput__Icon">
              <Button variant="link-secondary" onClick={this.handleIconClick}>
                <Icon type="date" size="lg" />
              </Button>
            </div>
          )}
        </div>
      </Popover.PopoverTrigger>
    );
  }
}

export default DateTimeInput;
