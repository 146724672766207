import get from 'lodash/get';
import React from 'react';
import { translate } from 'react-i18next';
import { getIdeaConceptId } from '../../../selectors/ideaConcepts';
import { AlignedCellContent } from '../ConceptTable.styled';
import useCanEditFeedback from '../useCanEditFeedback';
import useCanShowFeedback from '../useCanShowFeedback';
import useEditCommentLike from '../useEditCommentLike';
import useFetchCommentLike from '../useFetchCommentLike';
import useGetCommentLike from '../useGetCommentLike';
import useViewCommentLike from '../useViewCommentLike';
import CommentDisplay from './CommentDisplay';
import ExpandedCommentCell from './ExpandedCommentCell';

function FeedbackDisplay({
  conceptId,
  shouldFetchOnVisible = false,
  authorId,
  t
}) {
  const canShowFeedback = useCanShowFeedback(conceptId);
  const canEditFeedback = useCanEditFeedback(authorId);
  const feedback = useGetCommentLike('feedback', conceptId, authorId);
  const fetch = useFetchCommentLike('feedback', conceptId);
  const [openEditor] = useEditCommentLike('feedback', conceptId, authorId, t);
  const openReadOnly = useViewCommentLike(conceptId, authorId);
  let onOpen = null;
  if (canEditFeedback) {
    onOpen = openEditor;
  } else if (feedback) {
    onOpen = openReadOnly;
  }

  return canShowFeedback ? (
    <CommentDisplay
      onVisible={shouldFetchOnVisible ? fetch : () => Promise.resolve()}
      onOpen={onOpen}
      text={get(feedback, 'text')}
      canEdit={canEditFeedback}
    />
  ) : null;
}

const TranslatedFeedbackDisplay = translate()(FeedbackDisplay);

const feedback = {
  header: t => t('ideaConcepts/listPage/tableHeaders/feedback'),
  main: (t, concept) => {
    return (
      <AlignedCellContent alignItems="flex-start" justifyContent="stretch">
        <TranslatedFeedbackDisplay
          conceptId={getIdeaConceptId(concept)}
          shouldFetchOnVisible
        />
      </AlignedCellContent>
    );
  },
  expanded: (t, concept) => (
    <ExpandedCommentCell as={TranslatedFeedbackDisplay} concept={concept} />
  )
};

export default feedback;
