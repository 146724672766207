import React from 'react';
import { compose } from 'redux';
import formControl from '@bit/be-novative.kit.form-control';
import { translate } from 'react-i18next';
import RadioGroup from '../../../common/components/RadioGroup';
import Icon from '../../../common/components/Icon';
import { required as checkRequired } from '../../../utils/validation';
const RadioGroupFormControl = formControl(RadioGroup);

function PrivateConceptInput({ name, required, t, ...restOfProps }) {
  const choices = [
    {
      value: 0,
      label: t('ideaConcept/prop/visibility/public/hint'),
      icon: <Icon type="unlock" />
    },
    {
      value: 1,
      label: t('ideaConcept/prop/visibility/private/hint'),
      icon: <Icon type="lock" />
    }
  ];
  return (
    <RadioGroupFormControl
      name={name}
      type="radio"
      label={t('ideaConcept/prop/visibility/label')}
      choices={choices}
      layout="horizontal"
      parse={Number}
      required={required}
      validate={required ? checkRequired(t) : null}
      {...restOfProps}
    />
  );
}

export default compose(
  React.memo,
  translate()
)(PrivateConceptInput);
