import React from 'react';
import SortIndicator from './SortIndicator';

function defaultHeaderRenderer({
  dataKey,
  label,
  sortBy,
  sortDirection,
  disableSort
}) {
  const children = [
    <span className="Table__TruncatedText" key="label" title={label}>
      {label}
    </span>
  ];

  if (!disableSort) {
    children.push(
      <SortIndicator
        key="SortIndicator"
        sortDirection={sortBy === dataKey ? sortDirection : null}
      />
    );
  }

  return children;
}

export default defaultHeaderRenderer;
