import React from 'react';
import { connect } from 'react-redux';
import { activeModalSelector } from '../../reducers';

function ModalRoot({ modalComponents = {}, modal = {} }) {
  const ActiveModal = modalComponents[modal.modalType];

  if (!ActiveModal) {
    return null;
  }

  return <ActiveModal {...modal.modalProps} />;
}

function mapStateToProps(state) {
  return {
    modal: activeModalSelector(state)
  };
}

export default connect(mapStateToProps)(ModalRoot);
