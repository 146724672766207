import React, { useEffect, useState } from 'react';
import flow from 'lodash/flow';
import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Spinner from '@bit/be-novative.kit.spinner';
import { getApiResponseData } from '../../../api/utils/apiResponse';
import { fetchFeed } from '../../../feed/actions';
import { getChallengeId } from '../../../common/selectors/challenges';
import ChallengeList from './ChallengeList';
import scss from './ChallengeFilter.module.scss';

LatestChallengesTab.propTypes = {
  selections: PropTypes.array
};
LatestChallengesTab.defaultProps = {
  selections: []
};
function LatestChallengesTab({ onSelect, selections, ...rest }) {
  const [loading, setLoading] = useState(false);
  const [latestChallenges, setLatestChallenges] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function fetchLatestChallenges() {
      setLoading(true);
      try {
        const response = await dispatch(fetchFeed(0));
        const challengeIds = flow(
          getApiResponseData,
          map(getChallengeId)
        )(response);
        setLatestChallenges(challengeIds || []);
      } catch (error) {}
      setLoading(false);
    })();
  }, [dispatch]);

  return (
    <main className={scss.List} {...rest}>
      {loading && <Spinner type="bubble" centered />}

      <ChallengeList
        items={latestChallenges}
        onSelect={onSelect}
        selections={selections}
      />
    </main>
  );
}

export default LatestChallengesTab;
