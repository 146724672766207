import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import { translate } from 'react-i18next';
import Button from '@bit/be-novative.kit.button';
import Callout from '../../../common/components/Callout';
import Grid from '../../../common/components/Grid';
import profileApi from '../../api/profileApi';
import translateApiErrorMessage from '../../../utils/translateApiErrorMessage';

function ExportProfile({ t }) {
  const [submitting, setSubmitting] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback(async function handleSubmit() {
    setSubmitting(true);
    setError(null);
    setSucceeded(false);

    try {
      await profileApi.exportProfile();
      setSucceeded(true);
    } catch (error) {
      setError(get(error, 'code'));
    }

    setSubmitting(false);
  }, []);

  return (
    <Grid.Row gutter={0}>
      <Grid.Col xsGutterX={2} md={8} lg={6}>
        <h1>{t('profile/exportOwnProfile/title')}</h1>
        <p>{t('profile/exportOwnProfile/description')}</p>
        <h4>{t('profile/exportOwnProfile/prompt')}</h4>
        <p>
          <Button
            onClick={handleSubmit}
            variant={'warning'}
            loading={submitting}
            fillParent>
            {t('profile/exportOwnProfile/exportCTA')}
          </Button>
        </p>
        {succeeded && (
          <Callout type="success" tag="p" data-test="success">
            {t('profile/exportOwnProfile/success')}
          </Callout>
        )}
        {error && (
          <Callout type="danger" tag="p" data-test="fail">
            {translateApiErrorMessage(t, error)}
          </Callout>
        )}
      </Grid.Col>
    </Grid.Row>
  );
}

export default translate()(ExportProfile);
