import React from 'react';
import isNumber from 'lodash/isNumber';
import styled from 'styled-components';
import { EMPTY_NUMBER } from '../../constants';

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Rectangle = styled.div`
  display: ${({ background }) => (background ? 'inline-block' : 'none')};
  width: 1.4em;
  height: 1.4em;
  margin-right: 9px;
  border-radius: 4px;
  vertical-align: bottom;
  transition: background 0.25s ease-out;
  background: ${({ background }) => background};
`;

export default function CriterionScoreIndicator({
  rules = [
    {
      matcher: score => true,
      labelRenderer: score => score
    }
  ],
  style = {},
  value = EMPTY_NUMBER
}) {
  const matchingRule = rules.find(rule => rule.matcher(value));
  if (!matchingRule) {
    return <span>{value}</span>;
  }
  const colors = ['#FF1D1D', '#FFB21D', '#58A50B'];
  return (
    <Wrapper style={style}>
      {isNumber(value) && <Rectangle background={colors[value - 1]} />}
      <span>{matchingRule.labelRenderer(value)}</span>
    </Wrapper>
  );
}
