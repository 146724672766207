import { denormalize } from 'normalizr';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIdeaConceptById } from '../../common/actions';
import { getEntities } from '../../common/selectors/entities';
import conceptApi from '../api/conceptApi';
import schemas from '../schemas';
import { getConceptById } from '../selectors/ideaConcepts';
import createConceptPayload from '../utils/payloadUtils';

export default function useEditConcept(conceptId) {
  const allEntities = useSelector(getEntities);
  const oldEntity = useSelector(getConceptById(conceptId));
  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    async newData => {
      const existingData = denormalize(
        oldEntity,
        schemas.IDEA_CONCEPT,
        allEntities
      );
      const payload = createConceptPayload({
        ...existingData,
        ...newData
      });
      try {
        await conceptApi.updateConcept(conceptId, payload);
        dispatch(fetchIdeaConceptById(conceptId));
      } catch (error) {
        throw error;
      }
    },
    [allEntities, conceptId, dispatch, oldEntity]
  );
  return handleSubmit;
}
