import produce from 'immer';
import conceptReviewApi from '../api/conceptReviewApi';
import commentSliceFactory from './commentSliceFactory';

const { slice, actions, selectors } = commentSliceFactory({
  sliceName: 'reviewerFeedback',
  entityLabel: 'feedback',
  editFn: conceptReviewApi.editFeedbackForConcept,
  fetchFn: conceptReviewApi.fetchFeedbackForConcept,
  entityPropNormalizerFn: produce(comment => {
    const text = comment.feedback;
    comment.text = text;
    delete comment.feedback;
  })
});

const { fetchComments: fetchFeedback, editComment: editFeedback } = actions;
const {
  getCommentsByConceptId: getFeedbacksByConceptId,
  getGroupCommentByConceptId: getGroupFeedbackByConceptId,
  getUserCommentForConcept: getUserFeedbackForConcept
} = selectors;

export {
  editFeedback,
  fetchFeedback,
  getFeedbacksByConceptId,
  getGroupFeedbackByConceptId,
  getUserFeedbackForConcept
};
export default slice;
