import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';
import noop from 'lodash/noop';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

const ScrollSpyContext = React.createContext({
  activeId: null,
  dispatchAnchor: noop,
  parentRef: null
});

function ScrollSpyProvider({ children, tolerance = 15 }) {
  const parentRef = useRef();
  const anchorRefs = useRef([]);
  const dispatchAnchor = useCallback((type, ix, payload) => {
    const refs = anchorRefs.current;
    switch (type) {
      case 'add':
        if (refs[ix]) {
          throw new Error(
            'Tried adding scroll anchor ID that already exists:',
            ix
          );
        }
        refs[ix] = payload;
        break;
      case 'delete':
        refs[ix] = undefined;
        break;
      case 'scroll':
        if (refs[ix]) {
          parentRef.current.scrollTo({
            left: refs[ix].current.offsetLeft,
            behavior: 'smooth'
          });
        }
        break;
      default:
    }
  }, []);

  const [activeAnchorId, setActiveAnchorId] = useState(null);

  const findActiveAnchor = useCallback(() => {
    window.requestAnimationFrame(() => {
      const parent = parentRef.current;
      const anchors = anchorRefs.current;
      if (!parent || !anchors.length) {
        return;
      }
      const minVisible = parent.scrollLeft + tolerance;
      let visibleAnchor = null;
      anchors.forEach((anchor, ix) => {
        if (!anchor) return;
        const el = anchor.current;
        if (el.offsetLeft <= minVisible) {
          visibleAnchor = ix;
        }
      });
      if (visibleAnchor !== null) {
        setActiveAnchorId(visibleAnchor);
      }
    });
  }, [tolerance]);
  const findActiveAnchorDebounced = useCallback(
    debounce(findActiveAnchor, 100),
    [findActiveAnchor]
  );

  useEffect(() => {
    window.addEventListener('resize', findActiveAnchorDebounced);
    const scrollParent = parentRef.current;
    if (scrollParent) {
      findActiveAnchor();
      scrollParent.addEventListener('scroll', findActiveAnchor);
    }
    return () => {
      window.removeEventListener('resize', findActiveAnchorDebounced);
      if (scrollParent) {
        scrollParent.removeEventListener('scroll', findActiveAnchor);
      }
    };
  }, [findActiveAnchorDebounced, findActiveAnchor, parentRef]);

  return (
    <ScrollSpyContext.Provider
      value={{
        activeId: activeAnchorId,
        dispatchAnchor,
        parentRef
      }}>
      {children}
    </ScrollSpyContext.Provider>
  );
}

function useScrollParent() {
  const ctx = useContext(ScrollSpyContext);
  if (!ctx) {
    throw new Error('useScrollAnchorRef must be used within ScrollSpyProvider');
  }
  return ctx.parentRef;
}

function useScrollAnchor(index = 0) {
  const ctx = useContext(ScrollSpyContext);
  if (!ctx) {
    throw new Error('useScrollAnchorRef must be used within ScrollSpyProvider');
  }
  if (!isNumber(index)) {
    throw new Error('index must be a number');
  }
  const anchorRef = useRef();
  const { dispatchAnchor } = ctx;

  useEffect(() => {
    dispatchAnchor('add', index, anchorRef);
    return () => {
      dispatchAnchor('delete', index);
    };
  }, [dispatchAnchor, index]);

  return anchorRef;
}

function useActiveScrollAnchor() {
  const ctx = useContext(ScrollSpyContext);
  if (!ctx) {
    throw new Error('useScrollAnchorRef must be used within ScrollSpyProvider');
  }
  return ctx.activeId;
}

function useScrollToAnchor() {
  const ctx = useContext(ScrollSpyContext);
  if (!ctx) {
    throw new Error('useScrollAnchorRef must be used within ScrollSpyProvider');
  }
  const { dispatchAnchor } = ctx;
  const scrollTo = useCallback(
    ix => {
      dispatchAnchor('scroll', ix);
    },
    [dispatchAnchor]
  );
  return scrollTo;
}

export {
  ScrollSpyProvider,
  useScrollAnchor,
  useScrollParent,
  useActiveScrollAnchor,
  useScrollToAnchor
};
