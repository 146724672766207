import React from 'react';
import { translate } from 'react-i18next';
import Button from '@bit/be-novative.kit.button';
import Dot from '../../components/Dot';
import Grid from '../../components/Grid';
import './OnboardingTipPopover.css';

function OnboardingTipPopover({
  onNextTip,
  onSkipTips,
  skipLabel,
  nextLabel,
  finalLabel,
  t,
  tip,
  tips
}) {
  const title = t(`onboarding/tip-${tip}-title`).trim();
  const description = t(`onboarding/tip-${tip}-description`);
  const activeIndex = tips.indexOf(tip);
  const isLastTip = activeIndex + 1 === tips.length;

  return (
    <section className="OnboardingTipPopover">
      {title && (
        <header className="OnboardingTipPopover__Title">{title}</header>
      )}
      <p className="OnboardingTipPopover__Body">{description}</p>
      <footer>
        <Grid.Row align="stretch" gutter={2}>
          <Grid.Col xsGutter={2} xs="auto">
            <Button variant="link" onClick={onSkipTips}>
              {skipLabel(t)}
            </Button>
          </Grid.Col>
          <Grid.Col xs="auto">
            {tips.map((tip, index) => (
              <Dot disabled={index > activeIndex} key={tip} />
            ))}
          </Grid.Col>
          <Grid.Col xsGutter={2} xs="auto">
            <Grid.Col xs="auto">
              <Button variant="success" onClick={onNextTip}>
                {isLastTip ? finalLabel(t) : nextLabel(t)}
              </Button>
            </Grid.Col>
          </Grid.Col>
        </Grid.Row>
      </footer>
    </section>
  );
}

export { OnboardingTipPopover };
export default translate()(OnboardingTipPopover);
