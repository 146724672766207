import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import invariant from 'invariant';
import { getType as getMimeType } from 'mime';
import { stringify } from 'qs';
import { FILE_UPLOAD_TIMEOUT_MS, VOYAGER_API } from '../../api/constants';
import {
  UPDATE_CONCEPT_OWNER,
  FETCH_IDEA_CONCEPTS,
  FETCH_CHALLENGE_IDEA_CONCEPTS,
  entities,
  UPLOAD_CONCEPT_ATTACHMENT,
  DOWNLOAD_CONCEPT_ATTACHMENT,
  conceptListSortIds,
  conceptListFilterKeys
} from '../constants';
import schemas from '../schemas';
import { getFileName } from '../../utils/files';
import { saveFile } from '../../utils/saveFile';
import {
  isLoading,
  getSortBy,
  getSortDirection,
  getFilterBy,
  getPagination,
  getNextStart,
  getLimit
} from '../../common/selectors/meta';
import { getListPageStore } from '../selectors/conceptList';
import { getAttachmentById, getAttachmentName } from '../selectors/attachments';
import validateConceptFilterQuery from '../utils/validateConceptFilterQuery';

const { STAGE } = conceptListFilterKeys;

export const fetchAllIdeaConcepts = fetchParams => (dispatch, getState) => {
  const listPageStore = getListPageStore(getState());

  if (isLoading(listPageStore)) {
    return;
  }

  const filters = getFilterBy(listPageStore);
  validateConceptFilterQuery(filters);

  const sortBy = get(fetchParams, 'sortBy', getSortBy(listPageStore));
  const sortDirection = get(
    fetchParams,
    'sortDirection',
    getSortDirection(listPageStore)
  );

  const activeTab = get(fetchParams, STAGE);
  const paginationStore = getPagination(listPageStore)[activeTab];
  const limit = get(fetchParams, 'limit', getLimit(paginationStore));
  const start = get(fetchParams, 'start', getNextStart(paginationStore));

  invariant(
    isNumber(limit) && isNumber(start),
    'start and limit must be integers'
  );

  const queryString = stringify(
    {
      ...filters,
      sortby: conceptListSortIds[sortBy],
      sortdir: sortDirection,
      limit,
      start
    },
    // endpoint doesn't handle bracket notation (key[]=val), only repeated variables
    { arrayFormat: 'repeat' }
  );

  return dispatch({
    [VOYAGER_API]: {
      url: `/idea-concepts?${queryString}`,
      method: 'GET',
      type: FETCH_IDEA_CONCEPTS,
      schema: schemas.IDEA_CONCEPT_ARRAY,
      entityType: entities.IDEA_CONCEPT,
      start,
      limit,
      sortBy,
      sortDirection,
      activeTab
    }
  });
};

export const fetchChallengeIdeaConcepts = challengeId => dispatch =>
  dispatch({
    [VOYAGER_API]: {
      url: `/ideaconcepts/${challengeId}`,
      method: 'GET',
      type: FETCH_CHALLENGE_IDEA_CONCEPTS,
      schema: schemas.IDEA_CONCEPT_ARRAY,
      entityType: entities.IDEA_CONCEPT
    }
  });

export const editConceptOwner = (ideaConceptId, newOwnerId) => ({
  [VOYAGER_API]: {
    url: `/ideaconcepts/${ideaConceptId}/owner`,
    method: 'PUT',
    data: { newOwner: newOwnerId },
    type: UPDATE_CONCEPT_OWNER
  }
});

export const uploadConceptAttachment = (
  ideaConceptId,
  attachments = []
) => dispatch => {
  const form = new FormData();

  forEach(attachments, file => {
    form.append('file', file, getFileName(file));
  });

  return dispatch({
    [VOYAGER_API]: {
      url: `/idea-concepts/${ideaConceptId}/attachments`,
      method: 'POST',
      data: form,
      timeout: FILE_UPLOAD_TIMEOUT_MS,
      type: UPLOAD_CONCEPT_ATTACHMENT
    }
  });
};

export const downloadAttachment = ({ attachmentId, ideaConceptId }) => async (
  dispatch,
  getState
) => {
  try {
    const attachmentData = getAttachmentById(attachmentId)(getState());
    const fileName = getAttachmentName(attachmentData);
    const fileExtension = fileName.split('.').pop();
    const mime = getMimeType(fileExtension);
    const promise = await dispatch({
      [VOYAGER_API]: {
        url: `/ideaconcepts/${ideaConceptId}/attachments/${attachmentId}`,
        method: 'GET',
        responseType: 'blob',
        type: DOWNLOAD_CONCEPT_ATTACHMENT
      }
    });

    saveFile(promise.data, fileName, mime);
  } catch (error) {
    throw error;
  }
};
