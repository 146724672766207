import flow from 'lodash/flow';
import { omitEntities } from '../../telemetry/errorLogging/payloadUtils';
import { getChallengeRoot } from '../reducers';

const scrubStore = state =>
  flow(
    getChallengeRoot,
    omitEntities
  )(state);

export default scrubStore;
