import flow from 'lodash/flow';
import deburr from 'lodash/deburr';
import trim from 'lodash/trim';
import isString from 'lodash/isString';

/**
 * Converts slanted/curly quotes to regular double quotes.
 * @param {string} str - String containing slanted/curly quotes
 * @returns Sanitized string
 */
export function convertDoubleQuotes(str) {
  return String(str).replace(/[\u201C\u201D]/g, '"');
}

/**
 * Normalises user inputs typically for form values.
 * * It removes accented characters
 * * Converts the string to lower case
 * * Trims leading and trailing whitespace characters
 * @param {string} str String to be normalised
 * @returns {string}
 */
export default function strNormalise(str) {
  if (!isString(str)) {
    return '';
  }

  return flow(
    deburr,
    trim,
    s => s.toLowerCase() // _.lowerCase would insert spaces in case of camelCase
  )(str);
}
