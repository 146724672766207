import camelCase from 'lodash/camelCase';
import {
  conceptListStageFilterIds,
  conceptListStageFilterIdsCepheid,
  stagesWithOwnFilters,
  stagesWithOwnFiltersCepheid
} from '../../ideaConcept/constants';
import { isCepheidVariant } from '../../utils/variants';
import { getPlatformName } from '../../utils/platform';

function getStageTranslation(stage, t) {
  return t(`ideaConcept/implementationStage/indicator/${camelCase(stage)}`, {
    context: isCepheidVariant() ? 'cepheid' : getPlatformName()
  });
}

function getStageFilters() {
  return isCepheidVariant()
    ? stagesWithOwnFiltersCepheid
    : stagesWithOwnFilters;
}

function getStageFilterIds() {
  return isCepheidVariant()
    ? conceptListStageFilterIdsCepheid
    : conceptListStageFilterIds;
}

export { getStageFilters, getStageFilterIds, getStageTranslation };
