import React, { useCallback } from 'react';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import scss from './Tab.module.scss';

TabButton.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};
TabButton.defaultProps = {
  onClick: () => {}
};
function TabButton({
  active,
  ariaId,
  className,
  children,
  disabled,
  onClick,
  to
}) {
  const classes = cn(
    scss.Btn,
    {
      [scss.active]: active
    },
    className
  );
  const handleClick = useCallback(
    function maybePreventClick(event) {
      if (disabled) {
        event.preventDefault();
      } else if (isFunction(onClick)) {
        event.persist();
        onClick(event);
      }
    },
    [disabled, onClick]
  );

  const props = {
    children,
    onClick: handleClick,
    disabled,
    className: classes
  };
  const btnAria = ariaId
    ? {
        role: 'tab',
        'aria-selected': active,
        'aria-controls': ariaId,
        id: ariaId
      }
    : {};

  return to ? (
    <Link to={to} {...props} />
  ) : (
    <button type="button" {...props} {...btnAria} />
  );
}

export default TabButton;
