import { combineReducers } from 'redux';
import { FETCH_SCHEMA_DATA } from '../../constants';
import { loadingByIdFactory, errorByIdFactory } from './factories/status';
import entityFactory from './factories/entity';

const store = entityFactory(FETCH_SCHEMA_DATA);
const loading = loadingByIdFactory(FETCH_SCHEMA_DATA);
const error = errorByIdFactory(FETCH_SCHEMA_DATA);

export default combineReducers({ store, loading, error });
