import React from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import isFunction from 'lodash/isFunction';
import { translate } from 'react-i18next';
import {
  branch,
  compose,
  renderComponent,
  renderNothing,
  withProps
} from 'recompose';
import { CircleSpinner, NonIdealState } from '../../components';
import * as userAgent from '../../../utils/userAgent';

const isError = props => props.isError(props);
const isLoading = props => props.isLoading(props);
const isEmpty = props => props.isEmpty(props);

const getAnimationKey = props => {
  if (isLoading(props)) {
    return 'loading';
  } else if (isEmpty(props)) {
    return 'empty';
  } else if (isError(props)) {
    return 'error';
  }
  return 'loaded';
};

const getTranslation = (textVariable, props) =>
  isFunction(textVariable) ? textVariable(props) : textVariable;

const animateComponent = Component => props => (
  <div style={{ position: 'relative' }}>
    <ReactCSSTransitionReplace
      transitionName="fade"
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}>
      <Component key={getAnimationKey(props)} {...props} />
    </ReactCSSTransitionReplace>
  </div>
);

const LoadingComponent = ({ loadingComponent, ...props }) => {
  const LoadingComponent = loadingComponent;
  LoadingComponent.displayName = 'LoadingComponent';
  return <LoadingComponent {...props} />;
};

const EmptyComponent = ({ emptyComponent, emptyText, ...props }) => {
  const EmptyComponent = emptyComponent;
  EmptyComponent.displayName = 'EmptyComponent';
  return (
    <EmptyComponent
      {...props}
      title={getTranslation(emptyText, props)}
      visual={NonIdealState.HUMMINGBIRD}
    />
  );
};

const ErrorComponent = ({ errorComponent, errorText, ...props }) => {
  const ErrorComponent = errorComponent;
  ErrorComponent.displayName = 'ErrorComponent';
  return (
    <ErrorComponent
      {...props}
      title={getTranslation(errorText, props)}
      visual={NonIdealState.HUMMINGBIRD}
    />
  );
};

const shouldAnimate = ({ animate }) => animate && !userAgent.isIE();
const shouldRenderError = props => !isLoading(props) && isError(props);
const shouldRenderEmptyState = props =>
  isEmpty(props) && !props.shouldHideIfEmpty(props);
const shouldHideComponent = props =>
  isEmpty(props) && props.shouldHideIfEmpty(props);

const states = [
  { when: isLoading, then: renderComponent(LoadingComponent) },
  { when: shouldRenderError, then: renderComponent(ErrorComponent) },
  { when: shouldRenderEmptyState, then: renderComponent(EmptyComponent) },
  { when: shouldHideComponent, then: renderNothing }
];

const composeStates = states =>
  compose(...states.map(({ when, then }) => branch(when, then)));

const defaultOptions = {
  animate: true,
  emptyComponent: NonIdealState,
  emptyText: ({ t }) => t('common/Empty'),
  errorComponent: NonIdealState,
  errorText: ({ t }) => t('common/Sorry, something went wrong.'),
  isLoading: ({ loading }) => loading,
  isEmpty: ({ empty }) => empty,
  isError: ({ error }) => error,
  loadingComponent: CircleSpinner,
  shouldHideIfEmpty: ({ hideIfEmpty }) => !!hideIfEmpty
};

const withNonIdealState = (options = {}) => WrappedComponent =>
  compose(
    withProps(props => ({ ...defaultOptions, ...options, ...props })),
    translate(),
    branch(shouldAnimate, animateComponent),
    composeStates(states)
  )(WrappedComponent);

export default withNonIdealState;

export {
  LoadingComponent,
  EmptyComponent,
  ErrorComponent,
  getAnimationKey,
  animateComponent,
  isError,
  isLoading,
  isEmpty,
  shouldAnimate,
  shouldRenderError,
  shouldRenderEmptyState,
  shouldHideComponent,
  states,
  composeStates,
  defaultOptions
};
