import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Callout.css';

Callout.propTypes = {
  type: PropTypes.oneOf([
    'warning',
    'success',
    'info',
    'danger',
    'alert',
    'payment-info'
  ]),
  spacing: PropTypes.oneOf([1]),
  tag: PropTypes.node
};

Callout.defaultProps = {
  tag: 'div'
};

function Callout({
  children,
  className,
  type,
  spacing,
  tag: Tag,
  ...restOfProps
}) {
  const classes = classNames('Callout', className, {
    [`Callout--${type}`]: !!type,
    [`Callout--spacing-${spacing}`]: !!spacing
  });
  return (
    <Tag className={classes} {...restOfProps}>
      {children}
    </Tag>
  );
}

export default Callout;
