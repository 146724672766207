import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import {
  parseUTCISOString as parseDate,
  formatLocalDateToUTCISOString as formatDate
} from '../../../utils/date';
import './TimeInput.css';

const loop = (min, max) => value => {
  if (min > value) {
    return max;
  }
  if (max < value) {
    return min;
  }
  return value;
};

const loopHour = loop(0, 23);
const loopMinute = loop(0, 59);

const zeroPad = num => (num < 10 ? '0' + num : num);

class TimeInput extends Component {
  handleHourChange = event => {
    const { onChange, value } = this.props;
    const localDate = parseDate(value);
    const hour = loopHour(event.target.value);
    if (localDate) {
      onChange(formatDate(localDate.setHours(hour)));
    }
  };

  handleMinuteChange = event => {
    const { onChange, value } = this.props;
    const localDate = parseDate(value);
    const minute = loopMinute(event.target.value);
    if (localDate) {
      onChange(formatDate(localDate.setMinutes(minute)));
    }
  };

  isMeridiemUsed() {
    const { locale } = this.props;
    const localeData = moment.localeData(locale);
    return /a/gi.test(localeData.longDateFormat('LT'));
  }

  render() {
    const { value, onFocus, onBlur } = this.props;
    const localDate = parseDate(value);

    return (
      <div className="TimeInput">
        <input
          type="number"
          className="TimeInput__Input"
          value={localDate ? zeroPad(localDate.getHours()) : ''}
          placeholder={'00'}
          onChange={this.handleHourChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <span> : </span>
        <input
          type="number"
          className="TimeInput__Input"
          value={localDate ? zeroPad(localDate.getMinutes()) : ''}
          placeholder={'00'}
          onChange={this.handleMinuteChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    );
  }
}

TimeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  locale: PropTypes.string
};

TimeInput.defaultProps = {
  locale: 'en'
};

export default TimeInput;
