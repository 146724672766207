import { useHistory, useParams } from 'react-router-dom';
import { setToken } from '../../../authentication/services/token.service';
import useMountEffect from '../../hooks/useMountEffect';
import paths from '../../../paths';

export default function TokenUpdaterPage() {
  const history = useHistory();
  const { token } = useParams();

  useMountEffect(function storeNewToken() {
    setToken(token);
    history.push(paths.feed());
  });

  return null;
}
