import isFinite from 'lodash/isFinite';
import {
  DRAGGABLE_TYPES,
  MOVE_THROTTLE_MS,
  SOCKET_MSG,
  S_ID
} from '../constants';
import { getUserMovedItemId } from '../selectors/movement';
import { normaliseApiPosition } from '../utils/socketMsg/_mappers';
import { sendSocketMessage } from '../../common/actions/socket';
import forceArray from '../../utils/forceArray';
import addBreadcrumb from '../../telemetry/addBreadcrumb';

/**
 * @typedef {Object} CanvasPositionWithId
 * @property {string|number} id
 * @property {number} posX - X coordinate in %
 * @property {number} posY - Y coordinate in %
 */

/**
 * Stores a canvas item's canonical position in Redux
 * @param {CanvasPositionWithId|CanvasPositionWithId[]} payload - One or more position objects
 * @param {'internal' | 'external'} [origin] - Meta data on whether the position change comes from the UI or the API
 * @returns {import('redux').Action} Redux action
 */
export const storeCanvasItemPosition = (payload, origin = 'internal') => {
  const items = forceArray(payload);
  const isInvalidItem = item =>
    !item.id || !isFinite(item.posX) || !isFinite(item.posY);
  if (items.some(isInvalidItem)) {
    const invalidItem = items.find(isInvalidItem);
    addBreadcrumb(
      new Error('Invalid canvas position data: ' + JSON.stringify(invalidItem)),
      { category: 'canvas' }
    );
    throw new Error(
      'Invalid canvas position data: ' + JSON.stringify(invalidItem)
    );
  }
  return {
    type: ITEM_POS_CHANGED,
    payload: items,
    meta: { movedAt: Date.now(), canvasId: S_ID, origin }
  };
};
export const ITEM_POS_CHANGED = 'canvas/itemPos/store';

/**
 * Handles item movement messages. Message will be ignored
 * if the user is currently dragging the item.
 * An item is considered to be dragged if its ID was stored
 * by calling `registerCanvasItemMovement()` on drag start.
 * @param {[id: string|number, position: CoordinateObject]} socketMsg Incoming message from the API
 * @returns {import('redux').ActionCreator}
 */
export const canvasItemMoved = socketMsg => (dispatch, getState) => {
  if (!Array.isArray(socketMsg)) {
    throw new Error(
      'Expected array of arguments, but got ' + typeof socketMsgArgs
    );
  }
  const [id, position] = socketMsg;
  const itemPos = { ...normaliseApiPosition(position), id };
  const isItemDraggedByUser = getUserMovedItemId(getState()) === id;
  if (!isItemDraggedByUser) {
    dispatch(storeCanvasItemPosition(itemPos));
  }
};

/**
 * Sends a socket message to the API to move change the position
 * of a canvas item.
 * @param {string|number} id
 * @param {DRAGGABLE_TYPES} draggableType
 * @param {number} posX
 * @param {number} posY
 * @returns {import('redux').Action}
 */
export const sendMoveItemMessage = (id, draggableType, posX, posY) => {
  const typeMessageMap = {
    [DRAGGABLE_TYPES.GROUP]: SOCKET_MSG.GROUP_MOVE_REQ,
    [DRAGGABLE_TYPES.IDEA]: SOCKET_MSG.MOVE_IDEA_REQ,
    [DRAGGABLE_TYPES.IMAGE]: SOCKET_MSG.IMAGE_MOVE_REQ,
    [DRAGGABLE_TYPES.IMPORTED_CONCEPT]: SOCKET_MSG.MOVE_IDEA_REQ
  };
  const msgId = typeMessageMap[draggableType];
  if (!msgId) {
    throw new Error('Unknown draggable type: ' + draggableType);
  }
  const x = Number(posX);
  const y = Number(posY);
  if (!isFinite(x) || !isFinite(y)) {
    throw new Error(
      `Could not convert coordinates to number. x: ${posX}, y: ${posY}`
    );
  }
  // TODO delete branching after [CLOUD-5841]
  const shouldIncludeSectionArg =
    draggableType === DRAGGABLE_TYPES.IDEA ||
    draggableType === DRAGGABLE_TYPES.IMPORTED_CONCEPT;
  const maybeRequiredSectionArg = null;
  return shouldIncludeSectionArg
    ? sendSocketMessage(S_ID, msgId, [id, { x, y }], MOVE_THROTTLE_MS)
    : sendSocketMessage(
        S_ID,
        msgId,
        [id, { x, y }, maybeRequiredSectionArg],
        MOVE_THROTTLE_MS
      );
};

export const USER_DRAGS_ITEM = 'canvas/itemPos/dragStart';
/**
 * Disables an item's animations, so the incoming position change
 * socket messages don't interfere with the drag preview
 * while the user is dragging the item.
 *
 * Incoming `IdeaMoved` messages for the item ID will also be ignored,
 * to avoid canvas item re-renders that prevent idea grouping.
 * @param {string|number} itemId
 * @returns {import('redux').ActionCreator}
 */
export const registerCanvasItemMovement = itemId => (dispatch, getState) => {
  if (getUserMovedItemId(getState()) !== itemId) {
    dispatch({
      type: USER_DRAGS_ITEM,
      meta: {
        id: itemId,
        movedAt: Date.now(),
        throttle: MOVE_THROTTLE_MS,
        canvasId: S_ID
      }
    });
  }
};

export const USER_STOPS_DRAGGING = 'canvas/itemPos/dragStop';
/**
 * Re-enables an item's animations for incoming position change
 * socket messages. This action should be dispatched after
 * the user finished dragging the item.
 * @param {string|number} itemId
 * @returns {import('redux').ActionCreator}
 */
export const deregisterCanvasItemMovement = itemId => dispatch => {
  setTimeout(
    dispatch({
      type: USER_STOPS_DRAGGING,
      meta: { id: itemId, canvasId: S_ID, throttle: MOVE_THROTTLE_MS }
    }),
    MOVE_THROTTLE_MS
  );
};
