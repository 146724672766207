import React, { useEffect, useState } from 'react';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Size } from '../../../constants';
import usePrevious from '../../hooks/usePrevious';
import Badge, { BadgeChildShape } from '../Badge';
import Icon from '../Icon';
import scss from './Heart.module.scss';

Heart.propTypes = {
  badge: PropTypes.number,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

function Heart({
  badge = 0,
  checked,
  onChange,
  className,
  disabled,
  size,
  ...rest
}) {
  // Animation disabled on first render to prevent animating initial `checked` state
  const [animate, setAnimate] = useState(false);
  const prevChecked = usePrevious(checked);
  useEffect(
    function enableAnimationAfterFirstRender() {
      if (prevChecked !== undefined && prevChecked !== checked) {
        setAnimate(true);
      }
    },
    [checked, prevChecked]
  );
  const classes = classNames(scss.Heart, className, {
    [scss.checked]: checked,
    [scss.animate]: animate,
    [scss.disabled]: disabled
  });

  return (
    <label className={classes}>
      <input
        className={scss.Checkbox}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        type="checkbox"
      />
      <Badge
        value={badge}
        visible={isNumber(badge) && badge > 0}
        shape={BadgeChildShape.rectangle}
        size={Size.relative(size, -2)}
        badgeClass={scss.Badge}>
        <Icon
          {...rest}
          size={size}
          wrapperClass={scss.IconWrapper}
          className={scss.Icon}
          type={checked ? 'heart-full' : 'heart'}
        />
      </Badge>
    </label>
  );
}

export default Heart;
