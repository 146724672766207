import find from 'lodash/find';
import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import identity from 'lodash/identity';
import isEqual from 'lodash/fp/isEqual';
import isFinite from 'lodash/isFinite';
import isNumber from 'lodash/isNumber';
import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';
import { createSelector } from 'reselect';
import {
  entities,
  IdeaConceptImplementationStatus,
  conceptEditorFormFields
} from '../constants';
import getItemByIdFrom from '../../utils/getItemByIdFrom';
import {
  getEntity,
  getEntityIds,
  getEntityMap
} from '../../common/selectors/entities';
import { getCreator } from '../../common/selectors/meta';
import { isEditable } from '../../common/selectors/permissions';
import {
  getUserByIdAsInvitee,
  getUserEntity
} from '../../common/selectors/users';
import createConceptFormModel from '../utils/createConceptFormModel';

const {
  BENEFITS,
  CATEGORY,
  COSTS,
  IDEA_TEXT,
  IMAGE_URL,
  PLANNED_ACTIVITIES,
  CONCEPT_STATE,
  TEAM,
  TITLE
} = conceptEditorFormFields;

export const getActivities = concept => get(concept, PLANNED_ACTIVITIES, '');

export const getAttachments = concept => get(concept, 'attachments', []);

export const getBenefits = concept => get(concept, BENEFITS, '');

export const getCategory = concept => get(concept, CATEGORY);

/**
 * Returns the parent challenge ID if the concept was generated from a challenge idea
 * @param {IdeaConcept} concept
 * @returns {string}
 */
export const getChallengeId = concept => get(concept, 'challengeId');

/**
 * Returns the follow-up (child) challenge ID that was bound to the idea concept.
 * @param {IdeaConcept} concept
 * @returns {string} The UUID of the child challenge
 */
export const getFollowUpChallengeId = concept =>
  get(concept, 'followUpChallengeId');

export const getCosts = concept => get(concept, COSTS, '');

export const getEvaluationData = concept => get(concept, 'evaluationScores');

export const getCompositeEvalScore = concept =>
  flow(
    getEvaluationData,
    getFp('evaluationScore'),
    score => (isNumber(score) ? score : undefined)
  )(concept);

export const getEvalCriteria = concept =>
  get(concept, 'evaluationCriteria', []);

export const getEvalScoreArray = concept =>
  flow(
    getEvaluationData,
    evalData => get(evalData, 'criteriaScores', [])
  )(concept);

/**
 * Returns the count of all users who have evaluated the idea concept.
 * @param {IdeaConcept} concept
 * @returns {number} The count of evaluators if the result is a valid number, `0` otherwise.
 */
export const getEvaluatorCount = concept => {
  const count = Number(get(concept, 'evaluatorCount'));
  return isFinite(count) ? Math.max(0, count) : 0;
};

/**
 * Returns the list of unique users who have evaluated the idea concept
 * @param {IdeaConcept} concept
 * @returns {User[]}
 */
export const getEvaluatorList = concept => get(concept, 'evaluators', []);

export const getIdeaConceptId = concept => get(concept, 'ideaConceptId');

export const getIdeaId = concept => get(concept, 'ideaId');

export const getIdeaText = concept => get(concept, IDEA_TEXT, '');

export const getImageUrl = concept => get(concept, IMAGE_URL);

export const getOwner = concept => get(concept, 'owner');

export const getPermissions = concept => get(concept, 'permissions', {});

export const canEditConcept = concept =>
  flow(
    getPermissions,
    isEditable
  )(concept);

export const getRevealTime = concept => get(concept, 'revealedAt');

export const getStatus = concept =>
  get(concept, CONCEPT_STATE, IdeaConceptImplementationStatus.ACTIVE);

export const getSuggestionCounter = concept =>
  get(concept, 'numberOfSuggestions');

export const getSuggestions = concept => get(concept, 'suggestions', []);

export const getSuggestionsLength = createSelector(
  getSuggestionCounter,
  getSuggestions,
  (suggestionCounter, suggestionsArray) =>
    isNumber(suggestionCounter) ? suggestionCounter : suggestionsArray.length
);

export const getTeam = concept => get(concept, TEAM, []);

export const getDenormalizedTeam = concept => state =>
  flow(
    getTeam,
    map(userId => getUserEntity(userId)(state))
  )(concept);

export const getTitle = concept => get(concept, TITLE, '');

export const getTrendingScore = concept => get(concept, 'trendingScore');

export const isConceptPrivate = concept => !!get(concept, 'isPrivate', false);

export const getSuggestionAuthorsByConceptId = conceptId =>
  createSelector(
    getConceptById(conceptId),
    getEntityMap(entities.SUGGESTION),
    (concept, suggestionMap) =>
      flow(
        getSuggestions,
        map(
          flow(
            getItemByIdFrom(suggestionMap),
            getCreator
          )
        ),
        uniq
      )(concept)
  );

export const hasConceptSuggestionFromUser = (conceptId, userId) =>
  flow(
    getSuggestionAuthorsByConceptId(conceptId),
    suggestionAuthors => find(suggestionAuthors, isEqual(userId))
  );

/**
 * Checks if an idea concept was created directly instead of being generated from an idea.
 * @param {IdeaConcept} concept
 * @return {boolean} `true` if the concept was created directly, `false` if it was generated from an idea
 */

export const isQuickIdeaConcept = concept => Boolean(!getIdeaId(concept));

export const getAllIdeaConceptIds = getEntityIds(entities.IDEA_CONCEPT);

/**
 * Returns an idea concept from the Redux store
 * @param {string} conceptId
 * @returns {(state: import('redux').Store) => IdeaConcept}
 */
export const getConceptById = conceptId => state =>
  getEntity(entities.IDEA_CONCEPT)(conceptId)(state);

export const getConceptFormModelById = conceptId =>
  createSelector(
    getConceptById(conceptId),
    identity,
    (concept, state) =>
      createConceptFormModel(
        concept,
        flow(
          getTeam,
          map(userId => getUserByIdAsInvitee(userId)(state))
        )(concept)
      )
  );

export default {
  getActivities,
  getBenefits,
  getCategory,
  getChallengeId,
  getCosts,
  getIdeaConceptId,
  getIdeaId,
  getIdeaText,
  getOwner,
  getPermissions,
  getSuggestionAuthorsByConceptId,
  hasConceptSuggestionFromUser,
  canEditConcept,
  getRevealTime,
  getEvaluationData,
  getCompositeEvalScore,
  getSuggestionCounter,
  getSuggestions,
  getSuggestionsLength,
  getStatus,
  getTitle,
  getAllIdeaConceptIds,
  getConceptById,
  isQuickIdeaConcept
};
