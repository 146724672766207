// common ui
export const ALERT_TOAST = 'alertToast';
export const ONBOARDING_LAYER = 'onboardingLayer';

// login page
export const LOGIN_SUBMIT_BTN = 'submit';

// feed page
export const FEEDCARD_PRIMARY_CTA = 'feedPrimaryCTA';
export const FEEDCARD_SECONDARY_CTA = 'feedSecondaryCTA';
