import { AZURE_ROLE_NAME } from './constants';
import appInsights from '.';

export default function init() {
  appInsights.loadAppInsights();
  appInsights.addTelemetryInitializer(envelope => {
    envelope.tags['ai.cloud.role'] = AZURE_ROLE_NAME;
  });
  // Manually call trackPageView to establish the current user/session/pageview
  appInsights.trackPageView();
}
