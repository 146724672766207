import {
  SUBDOMAIN_VALIDATE,
  FETCH_UPDATE_PAYMENT_METHOD_URL,
  ACKNOWLEDGE_UPDATE_PAYMENT
} from '../../constants';
import { VOYAGER_API, IDENTITY_API } from '../../api/constants';

/**
 * @deprecated
 */
export const getUpdatePaymentMethodURL = () => ({
  [VOYAGER_API]: {
    url: '/company/payment/update',
    method: 'GET',
    type: FETCH_UPDATE_PAYMENT_METHOD_URL
  }
});

/**
 * @deprecated
 */
export const acknowledgeUpdatePayment = hostedPageId => ({
  [VOYAGER_API]: {
    url: '/company/payment/update/acknowledge',
    method: 'POST',
    type: ACKNOWLEDGE_UPDATE_PAYMENT,
    data: { hostedPageId }
  }
});

/**
 * @deprecated
 * Still working, 409 if already exists, 200 if ok
 * todo move to subfolder api
 */
export const validateSubdomain = subdomain => {
  return {
    [IDENTITY_API]: {
      url: `/subdomains/${subdomain}/available`,
      method: 'GET',
      type: SUBDOMAIN_VALIDATE
    }
  };
};
