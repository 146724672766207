import client from '../../api/client';

/**
 * Logs in the user with either a password or a 3rd-party token
 * @param {string} email - The account email
 * @param {Object} secret
 * @param {string} [secret.password] - The account password if the user signed up with a password
 * @param {import("../types/jsdoc").SSOToken} [secret.token] - The SSO token if the user signed up through a provider
 * @return {Promise} The pending request
 */

function loginWithEmail(email, { password, token }) {
  return client.post('/login', {
    email,
    authenticationToken: token || {
      '@type': 'password',
      password
    }
  });
}

/**
 * Logs in the user with the single-use magic code received via email
 * @param {string} code - The single-use access code
 * @return {Promise} The pending request
 */

function loginWithOneTimeCode(code) {
  return client.get(`/magiclink/${code}`);
}

/**
 * Triggers sending a one-time login email to the user
 * @param {string} email - The account email
 * @return {Promise} The pending request
 */

function requestOneTimeCode(email) {
  return client.get(`/magiclink/${email}/request`);
}

/**
 * Re-authenticates the user on another platform
 * @param {string} platformId - The target platform's ID
 * @return {Promise} The pending request
 */

function switchPlatform(platformId) {
  return client.post('/switch-company', { companyId: platformId });
}

export default {
  loginWithEmail,
  loginWithOneTimeCode,
  requestOneTimeCode,
  switchPlatform
};
