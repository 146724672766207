import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import paths from '../../paths';
import { openModal } from '../actions/modal';
import { maybeOpenWelcomeModal } from '../actions/profile';
import { getRedirectTarget } from '../selectors/router';
import { asyncComponent, requireAdminRole } from '.';
import FeatureFlagProvider from '../../featureFlags/components/FeatureFlagProvider';
import { CONCEPT_ROOT } from '../../ideaConcept/paths';
import IdeaConceptRoot from '../../ideaConcept/pages/IdeaConceptRoot';
import ConceptCanvasRoot from '../../ideaConceptCanvas/pages/ConceptCanvasRoot';
import { CONCEPT_CANVAS_ROOT } from '../../ideaConceptCanvas/paths';
import { getUserConsent } from '../../profile/getters';
import {
  fetchOwnProfile,
  ownProfileSelector
} from '../../profile/state/profile';
import isConsentRequired from '../../profile/utils/isConsentRequired';
import { fetchPlatformData } from '../state/platform';
import { fetchCategories } from '../state/categories';
import userAgent from '../../utils/userAgent';
import IENotice from '../components/IENotice';
import LegalUpdateModal, {
  LEGAL_UPDATE_MODAL
} from '../components/LegalUpdateModal';
import ModalRoot from './ModalRoot';
import Sidenav from './Sidenav';
import WelcomeModal from './WelcomeModal';

export class Main extends Component {
  static propTypes = {
    injectModuleReducers: PropTypes.func.isRequired,
    redirectTarget: PropTypes.string
  };

  async componentDidMount() {
    const {
      fetchPlatform,
      fetchProfile,
      fetchCategories,
      maybeOpenWelcomeModal,
      shouldFetchProfile
    } = this.props;
    maybeOpenWelcomeModal();
    fetchPlatform();
    fetchCategories();
    if (shouldFetchProfile) {
      fetchProfile();
    }
  }

  componentWillReceiveProps({ consentRequired, openModal }) {
    if (consentRequired) {
      openModal(LEGAL_UPDATE_MODAL);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  toggleSpinner = () => {
    this.setState({ isProfileLoadingSlowly: true });
  };

  checkUserConsent = consentData => {
    const consent = get(consentData, 'data.userConsent', {});
    if (isConsentRequired(consent)) {
      this.props.openModal(LEGAL_UPDATE_MODAL);
    }
  };

  FeedPage = asyncComponent(() =>
    import(/* webpackChunkName: "feed" */ '../../feed')
      .then(this.props.injectModuleReducers)
      .then(m => m.default)
  );

  AdminPage = requireAdminRole(
    asyncComponent(() =>
      import(/* webpackChunkName: "admin" */ '../../admin')
        .then(this.props.injectModuleReducers)
        .then(m => m.default)
    )
  );

  ProfilePage = asyncComponent(() =>
    import(/* webpackChunkName: "profile" */ '../../profile')
      .then(this.props.injectModuleReducers)
      .then(m => m.default)
  );

  IdeationPage = asyncComponent(() =>
    import(/* webpackChunkName: "ideation" */ '../../ideation')
      .then(this.props.injectModuleReducers)
      .then(m => m.default)
  );

  EvaluationPage = asyncComponent(() =>
    import(/* webpackChunkName: "evaluation" */ '../../evaluation')
      .then(this.props.injectModuleReducers)
      .then(m => m.default)
  );

  ChallengeResultsPage = asyncComponent(() => {
    return import(
      /* webpackChunkName: "challenge-results" */ '../../challengeResults'
    )
      .then(this.props.injectModuleReducers)
      .then(m => m.default);
  });

  ChallengeCreationPage = asyncComponent(() => {
    return Promise.all([
      import(
        /* webpackChunkName: "challenge-creation" */ '../../challengeCreation'
      ),
      import(/* webpackChunkName: "challenge" */ '../../challenge')
    ]).then(([challengeCreationModule]) => challengeCreationModule.default);
  });

  DuePaymentsPage = asyncComponent(() =>
    import(/* webpackChunkName: "due-payments" */ '../../duePayments')
      .then(this.props.injectModuleReducers)
      .then(m => m.default)
  );

  SuggestionsPage = asyncComponent(() =>
    import(/* webpackChunkName: "suggestions" */ '../../suggestions')
      .then(this.props.injectModuleReducers)
      .then(m => m.default)
  );

  render() {
    const { userId } = this.props;

    return (
      <FeatureFlagProvider>
        <Switch>
          <Route path="/feed/category/:categoryId" component={this.FeedPage} />
          <Route exact path="/feed/:challengeId?" component={this.FeedPage} />
          <Route path="/profile" component={this.ProfilePage} />
          <Route path="/admin" component={this.AdminPage} />
          <Route path="/system-admin" component={this.SystemAdminPage} />
          <Route
            path="/ideation/:challengeId"
            render={props => <this.IdeationPage {...props} userId={userId} />}
          />
          <Route path="/due-payments" component={this.DuePaymentsPage} />
          <Route
            path="/suggestions/:challengeId"
            component={this.SuggestionsPage}
          />
          <Route
            path="/challenge/create"
            component={this.ChallengeCreationPage}
          />
          <Route
            path="/evaluation/:challengeId"
            component={this.EvaluationPage}
          />
          <Route
            path="/results/:challengeId/:modal?"
            render={props => (
              <this.ChallengeResultsPage {...props} userId={userId} />
            )}
          />
          <Route path={`/${CONCEPT_ROOT}`} component={IdeaConceptRoot} />
          <Route
            path={`/${CONCEPT_CANVAS_ROOT}`}
            component={ConceptCanvasRoot}
          />
          {false && (
            <Route
              path="/notifications/:type?"
              component={this.NotificationsPage}
            />
          )}
          <Redirect to={this.props.redirectTarget} />
        </Switch>
        <Sidenav />
        <ModalRoot
          modalComponents={{
            WELCOME: WelcomeModal,
            [LEGAL_UPDATE_MODAL]: LegalUpdateModal
          }}
        />
        {userAgent.isIE() ? <IENotice /> : null}
      </FeatureFlagProvider>
    );
  }
}

const mapStateToProps = state => {
  const profile = ownProfileSelector(state);
  const consentModel = getUserConsent(profile);
  return {
    consentRequired: isConsentRequired(consentModel),
    redirectTarget: getRedirectTarget(state) || paths.feed(),
    userId: get(profile, 'userId')
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCategories: () => dispatch(fetchCategories()),
  fetchPlatform: () => dispatch(fetchPlatformData()),
  fetchProfile: () => dispatch(fetchOwnProfile()),
  maybeOpenWelcomeModal: () => dispatch(maybeOpenWelcomeModal()),
  openModal: () =>
    dispatch(
      openModal({
        modalType: LEGAL_UPDATE_MODAL,
        modalProps: {
          isCloseButtonShown: false,
          itsATrap: true,
          rounded: true
        }
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
