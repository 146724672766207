import React from 'react';
import withTooltip from './withTooltip';
import './Tooltip.css';

function Tooltip({ children, className, tag: Tag, ...rest }) {
  return <Tag {...rest} className={className || 'Tooltip'}>{children}</Tag>;
}

Tooltip.defaultProps = {
  tag: 'span'
};

export default withTooltip(Tooltip);
