import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import DayPicker, { MomentLocaleUtils } from '../DayPicker';
import {
  parseUTCISOString as parseDate,
  formatLocalDateToUTCISOString as formatDate
} from '../../../utils/date';
import TimeInput from '../TimeInput';
import './DateTimePicker.css';

class DateTimePicker extends Component {
  handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      return;
    }

    if (!selected) {
      this.props.onChange(formatDate(day));
    }
  };

  componentDidMount() {
    if (this.picker && this.props.value) {
      this.picker.showMonth(parseDate(this.props.value));
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextLocalDate = parseDate(nextProps.value);
    if (this.picker && nextLocalDate && nextProps.value !== this.props.value) {
      this.picker.showMonth(nextLocalDate);
    }
  }

  setRef = el => {
    this.picker = el;
  };

  render() {
    const {
      value,
      onChange,
      onFocus,
      onBlur,
      locale,
      labels,
      closeButton
    } = this.props;
    const localDate = parseDate(value);
    const now = moment()
      .local()
      .toDate();
    const disabledDays = {
      before: now
    };

    return (
      <div
        className="DateTimePicker"
        tabIndex={0}
        onFocus={onFocus}
        onBlur={onBlur}>
        <DayPicker
          ref={this.setRef}
          selectedDays={localDate ? localDate : null}
          disabledDays={disabledDays}
          locale={locale}
          labels={labels}
          localeUtils={MomentLocaleUtils}
          onDayClick={this.handleDayClick}
        />
        <TimeInput value={value} onChange={onChange} />
        <div className="h-text-center">{closeButton}</div>
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  closeButton: PropTypes.element,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  locale: PropTypes.string,
  labels: PropTypes.shape({
    nextMonth: PropTypes.string,
    previousMonth: PropTypes.string
  })
};

DateTimePicker.defaultProps = {
  onChange: () => {},
  locale: 'en',
  labels: {
    nextMonth: 'Next Month',
    previousMonth: 'Previouse Month'
  }
};

export default DateTimePicker;
