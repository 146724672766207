import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { withResponsive } from '../Responsive';
import './NavbarItem.css';

function NavbarItem({ children, push = false, ...rest }) {
  const className = classNames(rest.className, 'NavbarItem', {
    'NavbarItem--push': push
  });

  return (
    <li className={className}>
      {children}
    </li>
  );
}

NavbarItem.propTypes = {
  children: PropTypes.node,
  push: PropTypes.bool,
  visible: PropTypes.oneOf(['small', 'medium']),
  hidden: PropTypes.oneOf(['small', 'medium'])
};

export { NavbarItem };
export default withResponsive(NavbarItem);
