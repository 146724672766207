import React, { useCallback } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import { translate } from 'react-i18next';
import {
  updateOwnProfile,
  ownProfileSelector
} from '../../../profile/state/profile';
import translateApiErrorMessage from '../../../utils/translateApiErrorMessage';
import formControl from '@bit/be-novative.kit.form-control';
import Button from '@bit/be-novative.kit.button';
import Callout from '../../../common/components/Callout';
import CheckboxGroup from '../../../common/components/CheckboxGroup';

const CheckboxGroupFormControl = formControl(CheckboxGroup);

ContentLanguageForm.propTypes = {
  languages: PropTypes.array
};
function ContentLanguageForm({ languages = [], t }) {
  const dispatch = useDispatch();
  const handleFormSubmit = useCallback(
    async function handleSubmit(values) {
      try {
        unwrapResult(await dispatch(updateOwnProfile(values)));
      } catch (error) {
        return {
          [FORM_ERROR]: get(error, 'code')
        };
      }
    },
    [dispatch]
  );

  const profile = useSelector(ownProfileSelector);

  const choices = languages.map(language => ({
    value: language,
    label: t(`language/${language}`)
  }));

  return (
    <Form onSubmit={handleFormSubmit} initialValues={profile}>
      {({ handleSubmit, form }) => {
        const {
          touched,
          submitting,
          submitSucceeded,
          submitError
        } = form.getState();

        return (
          <form onSubmit={handleSubmit}>
            {submitSucceeded && (
              <Callout type="success" tag="p">
                {t('profile/Content language saved successfully!')}
              </Callout>
            )}
            {submitError && (
              <Callout type="danger" tag="p">
                {translateApiErrorMessage(t, submitError)}
              </Callout>
            )}
            <Field
              label={t('profile/Content Language')}
              hint={t(
                'profile/You can set here the challenge languages, which you are interested in.'
              )}
              name="preferredChallengeLanguages"
              component={CheckboxGroupFormControl}
              choices={choices}
            />
            <Button
              variant="success"
              type="submit"
              loading={submitting}
              disabled={isEmpty(touched)}>
              {t('common/userAction/save')}
            </Button>
          </form>
        );
      }}
    </Form>
  );
}

export default compose(translate())(ContentLanguageForm);
