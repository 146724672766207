import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';
import classNames from 'classnames';
import scss from './Radio.module.scss';

function Radio({ icon, label, className, checked, disabled, name, ...rest }) {
  const classes = classNames(scss.Radio, className, {
    [scss.checked]: checked,
    [scss.disabled]: disabled
  });
  return (
    <label className={classes}>
      <input
        type="radio"
        name={name}
        checked={checked}
        aria-checked={checked}
        disabled={disabled}
        tabIndex={checked ? 0 : -1}
        {...rest}
      />
      {icon &&
        cloneElement(icon, {
          className: classNames(icon.props.className, scss.Icon)
        })}{' '}
      <span>{label}</span>
    </label>
  );
}

Radio.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default Radio;
