import { useContext } from 'react';
import { FeatureFlagContext } from '../../featureFlags/components/FeatureFlagProvider';

/**
 * Returns whether the specified feature flag is enabled.
 * @param {import('../../featureFlags/constants').FeatureFlag} flagId
 * @returns {Boolean}
 */
export default function useFeatureFlag(flagId) {
  const isFeatureEnabled = useContext(FeatureFlagContext);
  return isFeatureEnabled(flagId);
}
