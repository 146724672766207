import React, { Component } from 'react';
import PropTypes from 'prop-types';
import without from 'lodash/fp/without';
import union from 'lodash/fp/union';
import includes from 'lodash/fp/includes';
import CheckboxButton from './CheckboxButton';

const THEME_DEFAULT = {
  container: 'CheckboxButtonGroup',
  checkbox: {
    container: 'CheckboxButton',
    checked: 'CheckboxButton--checked',
    disabled: 'CheckboxButton--disabled',
    input: 'CheckboxButton__Input'
  }
};

class CheckboxButtonGroup extends Component {
  static propTypes = {
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        disabled: PropTypes.bool,
        label: PropTypes.string,
        icon: PropTypes.node
      })
    ),
    hasIcon: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.object,
    name: PropTypes.string,
    separator: PropTypes.string
  };

  static defaultProps = {
    theme: THEME_DEFAULT
  };

  handleChange = (value, checked) => {
    const { input } = this.props;
    const prevValue = input.value || [];
    const nextValue = checked
      ? union([value], prevValue)
      : without([value], prevValue);

    input.onChange(nextValue);
  };

  handleBlur = () => {
    const { input } = this.props;
    input.onBlur(input.value);
  };

  render() {
    const { theme, choices, input, meta } = this.props;
    const choiceItems = choices.reduce(
      (acc, choice, index) => [
        ...acc,
        <CheckboxButton
          theme={theme.checkbox}
          key={`checkbox-button-${choice.value}`}
          input={{
            ...input,
            onChange: this.handleChange,
            onBlur: this.handleBlur,
            value: choice.value,
            checked: includes(choice.value, input.value),
            disabled: choice.disabled
          }}
          meta={meta}>
          {choice.label}
        </CheckboxButton>
      ],
      []
    );

    return (
      <div className={theme.container}>
        {choiceItems}
      </div>
    );
  }
}

export default CheckboxButtonGroup;
