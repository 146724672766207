import { useLocation } from 'react-router-dom';
import { parseQueryString } from '../../utils';

/**
 * Extracts the URL query params through `react-router-dom`, and converts the string to an object
 * @returns {Object.<string, any>}
 */
export default function useQueryParams() {
  return parseQueryString(useLocation().search);
}
