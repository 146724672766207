import React from 'react';
import { translate } from 'react-i18next';
import Button from '@bit/be-novative.kit.button';
import Icon from '../Icon';
import './EditButton.css';

export function EditButton({ t, className, onClick, ...props }) {
  return (
    <Button
      variant="link"
      {...props}
      onClick={onClick}
      className={`EditButton ${className}`}>
      <Icon type="pencil" className="EditButton__Icon" />
      &nbsp;
      {t('common/userAction/edit')}
    </Button>
  );
}

export default translate()(EditButton);
