import { ADD_SUGGESTION_TO_IDEA } from '../../constants';
import { VOYAGER_API } from '../../api/constants';
import {
  CLOSE_INTRO_MESSAGE,
  VIEW_CHALLENGE_INFO,
  PAGINATE_IDEA_CARDS,
  SKIP_SUGGESTIONS
} from '../../ideaConcept/constants';
import { convertApiErrorToStatusCode } from '../../api/utils/apiError';

export const addSuggestionToIdea = (suggestion, ideaId) => async dispatch => {
  try {
    await dispatch({
      [VOYAGER_API]: {
        url: `/ideas/${ideaId}/suggestion`,
        method: 'POST',
        type: ADD_SUGGESTION_TO_IDEA,
        data: { text: suggestion }
      }
    });
  } catch (error) {
    const code = convertApiErrorToStatusCode(error);
    throw new Error({ error, code });
  }
};

export const addSuggestionToConcept = (text, conceptId) => async dispatch => {
  try {
    await dispatch({
      [VOYAGER_API]: {
        url: `/idea-concepts/${conceptId}/suggestions`,
        method: 'POST',
        type: ADD_SUGGESTION_TO_IDEA,
        data: { text }
      }
    });
  } catch (error) {
    const code = convertApiErrorToStatusCode(error);
    throw new Error({ error, code });
  }
};

export const closeIntroMessage = challengeId => ({
  type: CLOSE_INTRO_MESSAGE,
  metrics: { challengeId }
});

export const openChallengeInfoModal = challengeId => ({
  type: VIEW_CHALLENGE_INFO,
  metrics: { challengeId }
});

export const paginateIdeas = (
  challengeId,
  currentPage,
  pageCount,
  feature
) => ({
  type: PAGINATE_IDEA_CARDS,
  metrics: {
    challengeId,
    currentPage,
    page: feature,
    pageCount
  }
});

export const skipSuggestions = challengeId => ({
  type: SKIP_SUGGESTIONS,
  metrics: { challengeId }
});
