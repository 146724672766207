import flow from 'lodash/flow';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { stringify } from 'qs';
import client from '../../api';
import { CommonException } from '../../api/constants';
import { getApiResponseData } from '../../api/utils/apiResponse';
import logRequestFailure from '../../api/utils/logRequestFailure';
import addBreadcrumb, { Severity } from '../../telemetry/addBreadcrumb';
import { mapEvaluationModelToPayload } from '../utils/conceptEvaluation';
import validateConceptFilterQuery from '../utils/validateConceptFilterQuery';

function getCurrentConcept(payload) {
  return get(payload, 'ideaConcept');
}

/**
 * Initialises a concept review session by fetching the first item from the API.
 * @param {Object<string, string>} query - Query string for filtering concepts to be reviewed
 * @returns {Promise}
 */
async function initSession(query) {
  addBreadcrumb('Fetching initial data for concept review session...', {
    level: Severity.Info,
    category: 'concept-review',
    data: query
  });

  try {
    validateConceptFilterQuery(query);
  } catch (error) {
    addBreadcrumb(error, {
      category: 'concept-review',
      data: query
    });
    throw new Error(CommonException.BadRequest);
  }

  try {
    const res = await client.get(
      `/evaluations/ideaconcept?${stringify(query, { arrayFormat: 'repeat' })}`
    );
    return flow(
      getApiResponseData,
      getCurrentConcept
    )(res);
  } catch (error) {
    logRequestFailure(
      error,
      'Could not fetch first concept for review',
      'concept-review'
    );
    throw error;
  }
}

/**
 * Submits review scores for an idea concept, then returns the next concept for review.
 * @param {string} ideaConceptId ID of the reviewed concept
 * @param {Object<string, string>} query The query used for fetching the next concept for review
 * @param {IdeaConceptEvaluationScoreMap} review The submitted scores
 * @returns {Promise<[lastConcept: IdeaConcept, currentConcept: IdeaConcept]>} A tuple of the evaluated concept, and the next concept to be evaluated
 */
async function reviewConcept(ideaConceptId, query, review) {
  addBreadcrumb('Submitting concept review...', {
    level: Severity.Info,
    category: 'concept-review',
    data: { ideaConceptId, query, review }
  });

  try {
    validateConceptFilterQuery(query);
  } catch (error) {
    addBreadcrumb(error, {
      category: 'concept-review',
      data: query
    });
    throw new Error(CommonException.BadRequest);
  }

  try {
    const queryString = !isEmpty(query)
      ? `?${stringify(query, { arrayFormat: 'repeat' })}`
      : '';
    const res = await client.post(
      `/evaluations/ideaconcept/${ideaConceptId}${queryString}`,
      {
        evaluationCriteria: mapEvaluationModelToPayload(review)
      }
    );
    const data = getApiResponseData(res);
    const lastConcept = get(data, 'evaluatedIdeaConcept');
    const currentConcept = getCurrentConcept(data);
    return [lastConcept, currentConcept];
  } catch (error) {
    logRequestFailure(
      error,
      'Could not submit concept review',
      'concept-review'
    );
    throw error;
  }
}

/**
 * Fetches the default concept review criteria for the current platform.
 * @returns {Promise<IdeaConceptEvaluationCriterion[]>} An array of criteria
 */
async function fetchDefaultReviewCriteria() {
  addBreadcrumb('Fetching default concept review criteria...', {
    category: 'concept-review'
  });
  try {
    const res = await client.get('/idea-concept-evaluation-criteria');
    return getApiResponseData(res);
  } catch (error) {
    logRequestFailure(
      error,
      'Could not fetch default concept review criteria',
      'concept-review'
    );
    throw error;
  }
}

async function updateDefaultReviewCriteria(newConfig) {
  addBreadcrumb('Updating default concept review criteria...', {
    category: 'concept-review'
  });
  try {
    const res = await client.put(
      '/idea-concept-evaluation-criteria',
      newConfig
    );
    return flow(getApiResponseData)(res);
  } catch (error) {
    logRequestFailure(
      error,
      'Could not update default concept review criteria',
      'concept-review'
    );
    throw error;
  }
}

async function fetchInternalCommentsForConcept(ideaConceptId) {
  addBreadcrumb('Fetching internal comments for concept...', {
    category: 'concept-review',
    data: { ideaConceptId }
  });
  try {
    const res = await client.get(
      `/idea-concepts/${ideaConceptId}/internal-comments`
    );
    return getApiResponseData(res);
  } catch (error) {
    logRequestFailure(
      error,
      'Could not fetch concept internal comments',
      'concept-review'
    );
    throw error;
  }
}

async function editInternalCommentForConcept(
  ideaConceptId,
  commentStr,
  isGroupInternalComment = false
) {
  addBreadcrumb('Updating internal comment for concept...', {
    category: 'concept-review',
    data: {
      ideaConceptId,
      commentStr
    }
  });
  if (!ideaConceptId) {
    throw new Error('conceptId is empty');
  }
  try {
    return client.put(`/idea-concepts/${ideaConceptId}/internal-comments`, {
      internalComment: commentStr,
      isGroupInternalComment
    });
  } catch (error) {
    logRequestFailure(
      error,
      'Could not update concept internal comment',
      'concept-review'
    );
    throw error;
  }
}

async function fetchFeedbackForConcept(ideaConceptId) {
  addBreadcrumb('Fetching feedback for concept...', {
    category: 'concept-review',
    data: { ideaConceptId }
  });
  try {
    const res = await client.get(`/idea-concepts/${ideaConceptId}/feedbacks`);
    return getApiResponseData(res);
  } catch (error) {
    logRequestFailure(
      error,
      'Could not fetch concept feedback',
      'concept-review'
    );
    throw error;
  }
}

async function editFeedbackForConcept(
  ideaConceptId,
  commentStr,
  isGroupFeedback = false
) {
  addBreadcrumb('Updating feedback for concept...', {
    category: 'concept-review',
    data: {
      ideaConceptId,
      commentStr,
      isGroupFeedback
    }
  });
  if (!ideaConceptId) {
    throw new Error('conceptId is empty');
  }
  try {
    return client.post(`/idea-concepts/${ideaConceptId}/feedbacks`, {
      feedback: commentStr,
      isGroupFeedback
    });
  } catch (error) {
    logRequestFailure(
      error,
      'Could not update concept feedback',
      'concept-review'
    );
    throw error;
  }
}

export default {
  initSession,
  reviewConcept,
  fetchDefaultReviewCriteria,
  updateDefaultReviewCriteria,
  fetchInternalCommentsForConcept,
  editInternalCommentForConcept,
  fetchFeedbackForConcept,
  editFeedbackForConcept
};
