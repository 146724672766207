import React, { memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import useOnClickOutside from 'use-onclickoutside';
import {
  getCategoryId,
  getCategoryName
} from '../../../common/getters/categories';
import Button from '@bit/be-novative.kit.button';
import { DropdownContent } from '../../../common/components/Dropdown';
import Ellipsis from '../../../common/components/Ellipsis';
import FadeTransition from '../../../common/components/FadeTransition';
import Icon from '../../../common/components/Icon';
import { FilterOption } from './FilterOption';
import useFetchCategories from '../../../common/hooks/useFetchCategories';
import useCategory from '../../../common/hooks/useCategory';

const CATEG_NAME_MAX_LENGTH = 50;

CategoryFilter.propTypes = {
  canEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  t: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};
CategoryFilter.defaultProps = {
  onSelect: () => {}
};
function CategoryFilter({ canEdit, isLoading, onSelect, t, value }) {
  const ref = useRef(null);
  const choices = useFetchCategories({ showAllCategs: true });

  const activeCategory = useCategory(value);
  const [open, setOpen] = useState(false);

  useOnClickOutside(ref, () => setOpen(false));

  const handleDropdown = useCallback(
    isOpen => () => {
      setOpen(!isOpen);
    },
    []
  );

  const handleSelect = useCallback(
    value => {
      onSelect(value);
      setOpen(false);
    },
    [onSelect]
  );

  return (
    <nav style={{ position: 'relative', display: 'inline-block' }} ref={ref}>
      <Button
        onClick={handleDropdown(open)}
        disabled={isLoading}
        variant="minimal"
        title={getCategoryName(activeCategory)}>
        <span>
          {value
            ? getCategoryName(activeCategory, CATEG_NAME_MAX_LENGTH)
            : t('common/prop/category/label')}
        </span>
        <Icon type="angle-down" />
      </Button>

      <TransitionGroup>
        {open && canEdit && (
          <FadeTransition>
            <DropdownContent>
              {renderOption(handleSelect)({
                categoryId: null,
                name: t('common/userAction/showAll')
              })}
              {choices.map(renderOption(handleSelect))}
            </DropdownContent>
          </FadeTransition>
        )}
      </TransitionGroup>
    </nav>
  );
}

export function renderOption(onSelect) {
  return category => {
    const categId = getCategoryId(category);
    const name = getCategoryName(category);

    return (
      <FilterOption
        key={categId}
        onClick={() => onSelect(categId)}
        title={name}>
        <Ellipsis>{truncate(name, CATEG_NAME_MAX_LENGTH)}</Ellipsis>
      </FilterOption>
    );
  };
}

export default compose(
  translate(),
  memo
)(CategoryFilter);
