import React, { useCallback } from 'react';
import styled from 'styled-components';
import { SortDirection } from '../../../api/constants';
import SortIndicator from '../../../common/components/Table/SortIndicator';

const ColHeaderBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  appearance: none;
  background: none;
  border: 0;
  text-align: left;
  font-size: 0.85em;
  &[disabled] {
    color: initial;
  }
  &:not([disabled]) {
    cursor: pointer;
  }
`;

export default function ColHeader({
  children,
  colId,
  onSortUpdate,
  sortDisabled,
  sortDirection
}) {
  const handleClick = useCallback(() => {
    const sortDir =
      sortDirection === SortDirection.Desc
        ? SortDirection.Asc
        : SortDirection.Desc;
    onSortUpdate({
      sortBy: colId,
      sortDirection: sortDir
    });
  }, [colId, onSortUpdate, sortDirection]);
  return (
    <ColHeaderBtn onClick={handleClick} disabled={sortDisabled}>
      {children}
      {!sortDisabled && (
        <SortIndicator
          key={colId}
          sortDirection={sortDirection}
          style={{ flexShrink: 0 }}
        />
      )}
    </ColHeaderBtn>
  );
}
