import get from 'lodash/get';
import { handleActions } from 'redux-actions';

const DEFAULT = {
  sortBy: '',
  sortDirection: ''
};

const sortingReducerFactory = (actionType, defaultState = DEFAULT) => {
  return handleActions(
    {
      [actionType.SUCCESS](state, currentAction) {
        return {
          sortBy: get(currentAction, ['meta', 'sortBy'], get(state, 'sortBy')),
          sortDirection: get(
            currentAction,
            ['meta', 'sortDirection'],
            get(state, 'sortDirection')
          )
        };
      }
    },
    defaultState
  );
};

export default sortingReducerFactory;
