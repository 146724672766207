import React, { Children } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import './SecondaryNavigation.css';

function SecondaryNavigation({ children, location }) {
  const activeGroup = Children.toArray(children).find(group =>
    Children.toArray(group.props.children).some(link => {
      return matchPath(location.pathname, { path: link.props.to, exact: true });
    })
  );

  return (
    <div className="SecondaryNavigation">
      {activeGroup && (
        <p className="SecondaryNavigation__ActiveGroup">
          {activeGroup.props.title}
        </p>
      )}
      <ul className="SecondaryNavigation__Items">{children}</ul>
    </div>
  );
}

export default withRouter(SecondaryNavigation);
