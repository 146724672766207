import startsWith from 'lodash/startsWith';
import { stringify } from 'qs';
import * as Sentry from '@sentry/browser';
import { ChallengeIdeasSortBy } from './challenge/constants';

function appendHash(path, hash) {
  if (!hash) {
    return path;
  }
  return `${path}#${hash}`;
}

const paths = {
  root() {
    return '/';
  },
  profile() {
    return '/profile';
  },
  profileSettings() {
    return `${paths.profile()}/settings`;
  },
  profilePasswordSettings() {
    return `${paths.profileSettings()}/password`;
  },
  profileLanguageSettings() {
    return `${paths.profileSettings()}/language`;
  },
  profilePrivacySettings() {
    return `${paths.profileSettings()}/privacy`;
  },
  exportProfile() {
    return `${paths.profileSettings()}/export`;
  },
  deleteProfile() {
    return `${paths.profileSettings()}/delete`;
  },
  duePayments() {
    return '/due-payments';
  },
  feed(categoryId) {
    let path = '/feed';

    if (categoryId) {
      path += `/category/${categoryId}`;
    }

    return path;
  },
  feedDetails(challengeId, autostart = false) {
    let path = paths.feed();

    if (challengeId) {
      path += `/${challengeId}`;
    }

    if (autostart) {
      path += '?autostart=true';
    }

    if (startsWith(path, '//')) {
      Sentry.captureException(new Error('Invalid redirect target'));
    }

    return path;
  },
  ideation(challengeId) {
    return `/ideation/${challengeId}`;
  },
  results(challengeId, query = {}, hash) {
    let path = `/results/${challengeId}${stringify(
      { sortBy: ChallengeIdeasSortBy.CombinedScore, ...query },
      { addQueryPrefix: true }
    )}`;
    path = appendHash(path, hash);
    return path;
  },
  reveal(challengeId) {
    return `${paths.results(challengeId)}/reveal`;
  },
  createChallenge() {
    return '/challenge/create';
  },
  evaluation(challengeId) {
    return `/evaluation/${challengeId}`;
  },
  error(query) {
    return `/error${stringify(query, { addQueryPrefix: true })}`;
  },
  notifications(group) {
    let path = '/notifications';
    if (group) {
      path += `/${group}`;
    }
    return path;
  },
  generalAdmin() {
    return '/admin';
  },
  /**
   * @deprecated
   */
  companies(companyId) {
    let path = '/system-admin';

    if (companyId) {
      path += `/${companyId}`;
    }

    return path;
  },
  suggestions(challengeId) {
    return `/suggestions/${challengeId}`;
  },
  users(userId) {
    let path = '/admin/users';

    if (userId) {
      path += `/${userId}`;
    }

    return path;
  },
  usersRights() {
    return '/admin/user-rights';
  }
};

export default paths;
