import React from 'react';
import withResponsive from './withResponsive';

function Responsive({ children, tag: Tag, ...rest }) {
  return <Tag {...rest}>{children}</Tag>;
}

Responsive.defaultProps = {
  tag: 'span'
};

export default withResponsive(Responsive);
