import PropTypes from 'prop-types';

export const alignType = PropTypes.oneOf([
  'start',
  'left',
  'center',
  'middle',
  'end',
  'right'
]);

export const categoryType = PropTypes.shape({
  categoryId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const historyType = PropTypes.shape({
  push: PropTypes.func.isRequired
});

export const imageDetailsType = PropTypes.shape({
  '@type': PropTypes.oneOf(['preset', 'external', 'uploaded']),
  url: PropTypes.string,
  content: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
});

export const userCoreInfoType = PropTypes.shape({
  userId: PropTypes.string.isRequired,
  fullName: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  }).isRequired,
  name: PropTypes.string.isRequired,
  image: imageDetailsType
});

export const refType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.any })
]);
