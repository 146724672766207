import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../Grid';

TwoColumnLayout.propTypes = {
  main: PropTypes.element.isRequired,
  side: PropTypes.element,
  mainBreakpoints: PropTypes.object,
  sideBreakpoints: PropTypes.object
};
TwoColumnLayout.defaultProps = {
  side: null,
  mainBreakpoints: {
    xsGutter: 2,
    md: 10,
    lg: 8,
    xl: 7,
    mdOffset: 1,
    lgOffset: 0,
    xlOffset: 1
  },
  sideBreakpoints: {
    xsGutter: 2,
    lg: 4,
    xl: 3,
    mediaHiddenDown: 'lg'
  }
};
function TwoColumnLayout({ main, side, mainBreakpoints, sideBreakpoints }) {
  return (
    <Grid.Row>
      <Grid.Col {...mainBreakpoints}>{main}</Grid.Col>
      <Grid.Col {...sideBreakpoints}>{side}</Grid.Col>
    </Grid.Row>
  );
}

export default TwoColumnLayout;
