import flow from 'lodash/flow';
import filterFp from 'lodash/fp/filter';
import getFp from 'lodash/fp/get';
import getOrFp from 'lodash/fp/getOr';
import isEqualFp from 'lodash/fp/isEqual';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import { getParentGroupId } from '../../common/selectors/ideas';
import { getCanvasIdeas } from './ideas';
import { rootSelector } from './index';

const getGroupId = getFp('ideaGroupId');

const getGroups = createSelector(
  state => state,
  flow(
    rootSelector,
    getFp('groups')
  )
);

const getGroupIds = createSelector(
  getGroups,
  getOrFp([], 'allIds')
);

const getGroupMap = createSelector(
  getGroups,
  getOrFp({}, 'byId')
);

const getGroupById = createSelector(
  getGroupMap,
  (_, groupId) => groupId,
  (groups, groupId) => groups[groupId]
);

const makeGetGroupById = () => getGroupById;

const getIdeasByGroupId = createSelector(
  getCanvasIdeas,
  (_, id) => id,
  (ideas, groupId) =>
    flow(
      filterFp(
        flow(
          getParentGroupId,
          isEqualFp(groupId)
        )
      ),
      ideas => orderBy(ideas, ['groupIndex', 'desc'])
    )(ideas)
);

const makeGetIdeasByGroupId = () => getIdeasByGroupId;

export {
  getGroupById,
  getGroupId,
  getGroupIds,
  getIdeasByGroupId,
  makeGetGroupById,
  makeGetIdeasByGroupId
};
