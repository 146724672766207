import React, { useEffect, useState } from 'react';
import trim from 'lodash/trim';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import useDebounce from '../../hooks/useDebounce';
import { StyledInput, InputIcon } from './SearchInput.styled';

SearchInput.propTypes = {
  autoFocus: PropTypes.bool,
  debounce: PropTypes.number,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};
SearchInput.defaultProps = {
  debounce: 800,
  onSearch: () => {}
};
export function SearchInput({
  autoFocus,
  className,
  debounce,
  disabled,
  error,
  initialValue,
  onSearch,
  placeholder,
  style,
  t
}) {
  const [value, setValue] = useState(initialValue || '');
  const debouncedQuery = useDebounce(value, debounce);

  const checkForEnter = event => {
    if (event.key === 'Enter') {
      onSearch(value);
    }
  };

  useEffect(() => {
    if (debouncedQuery) {
      onSearch(trim(debouncedQuery));
    }
  }, [debouncedQuery, onSearch]);

  return (
    <div style={{ ...style, position: 'relative' }}>
      <StyledInput
        type="text"
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyDown={checkForEnter}
        disabled={disabled}
        error={error}
        placeholder={placeholder || t('common/userAction/search')}
        autoFocus={autoFocus}
        className={className}
        data-test="search"
      />
      <InputIcon type="search" size="lg" disabled={disabled} />
    </div>
  );
}

export default translate()(SearchInput);
