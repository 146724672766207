import flow from 'lodash/flow';
import filter from 'lodash/filter';
import has from 'lodash/has';
import get from 'lodash/get';
import mapFp from 'lodash/fp/map';
import startsWith from 'lodash/startsWith';
import zipWith from 'lodash/zipWith';
import { CHALLENGE_FORM_FIELDS } from '../constants';

const { CONCEPT_EVAL_CRITERIA, CRITERION } = CHALLENGE_FORM_FIELDS;

const getCriterionId = criterion => get(criterion, CRITERION.ID);
const getCriterionName = criterion => get(criterion, CRITERION.NAME);

export const areConceptEvalCriteriaSet = formModel =>
  has(formModel, `${CONCEPT_EVAL_CRITERIA}_0`);

const mapCriteriaToArray = payload =>
  flow(
    model =>
      filter(model, (_, key) => startsWith(key, `${CONCEPT_EVAL_CRITERIA}_`)),
    mapFp(name => ({ name }))
  )(payload);

const mapCriteriaToExistingIds = (oldItemsWithId = [], updatedItems = []) =>
  zipWith(oldItemsWithId, updatedItems, (oldItem, newItem) => ({
    [CRITERION.ID]: getCriterionId(oldItem),
    [CRITERION.NAME]: getCriterionName(newItem) || getCriterionName(oldItem)
  }));

export const createCriteriaConfig = payload => {
  const newItems = mapCriteriaToArray(payload);
  if (has(payload, CONCEPT_EVAL_CRITERIA)) {
    return mapCriteriaToExistingIds(payload[CONCEPT_EVAL_CRITERIA], newItems);
  } else {
    return newItems;
  }
};

export const mapEvalCriteriaToChallengeInputs = (criteria = []) =>
  criteria.reduce((inputMap, criterion, ix) => {
    inputMap[`${CONCEPT_EVAL_CRITERIA}_${ix}`] = get(
      criterion,
      CRITERION.NAME,
      ''
    );
    return inputMap;
  }, {});
