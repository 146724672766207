import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import NotificationRow from './NotificationRow';
import { notificationType } from '../../types';
import { MediaList } from '../../../common/components';
import { withNonIdealState } from '../../../common/containers';

NotificationList.propTypes = {
  condensed: PropTypes.bool,
  loading: PropTypes.bool,
  notifications: PropTypes.arrayOf(notificationType),
  onNotificationClick: PropTypes.func.isRequired
};

export function NotificationList({
  notifications,
  onNotificationClick,
  condensed
}) {
  return (
    <MediaList condensed={condensed}>
      {notifications.map(notification => (
        <NotificationRow
          key={notification.notificationId}
          onClick={onNotificationClick}
          notification={notification}
          condensed={condensed}
        />
      ))}
    </MediaList>
  );
}

export default compose(
  withNonIdealState({
    isEmpty: ({ notifications }) => !notifications.length
  })
)(NotificationList);
