import { FeedModalTypes, MODAL_OPEN, LOVE_CHALLENGE } from '../../constants.js';
import EventTracker from './_EventTracker';

const EVENT_GROUP = 'feed';

class FeedTracker extends EventTracker {
  constructor(metrics) {
    super(metrics);
    this.eventGroup = EVENT_GROUP;
  }

  track(action, userId) {
    if (
      action.type === MODAL_OPEN &&
      action.payload.modalType === FeedModalTypes.SHOW_CARD
    ) {
      this.coreTrack(action.type + '_' + action.payload.modalType, {
        userId: userId
      });
    } else if (action.type === LOVE_CHALLENGE) {
      this.coreTrack(action.type, { userId: userId });
    }
  }
}

export default FeedTracker;
