import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PROFILE_FORM_FIELDS } from '../constants';
import { AsyncStatus } from '../../constants';
import { isTokenValid } from '../../authentication/services/token.service';
import profileApi from '../api/profileApi';
import {
  createPrivacyPayload,
  createProfilePayload
} from '../utils/payloadUtils';
import handleApiCallAction from '../../utils/handleApiCallAction';
import { getAdminStatus, getUserConsent } from '../getters';

const fetchOwnProfile = createAsyncThunk(
  'fetchProfile',
  async () => await profileApi.fetchOwnProfile()
);

const updateOwnProfile = createAsyncThunk(
  'updateProfile',
  handleApiCallAction(profileApi.updateProfile, (formData, state) =>
    createProfilePayload({
      ...ownProfileSelector(state),
      ...formData
    })
  ),
  { condition: () => isTokenValid() }
);

const updatePreferredLocale = createAsyncThunk(
  'updatePreferredLanguage',
  handleApiCallAction(profileApi.updateProfile, (language, state) =>
    createProfilePayload({
      ...ownProfileSelector(state),
      [PROFILE_FORM_FIELDS.LANGUAGE]: language
    })
  ),
  {
    condition: () => isTokenValid()
  }
);

const updatePrivacy = createAsyncThunk(
  'updatePrivacySettings',
  handleApiCallAction(profileApi.updateProfile, (consentSettings, state) =>
    createProfilePayload({
      ...ownProfileSelector(state),
      [PROFILE_FORM_FIELDS.CONSENT]: createPrivacyPayload(
        flow(
          ownProfileSelector,
          getUserConsent
        )(state),
        consentSettings
      )
    })
  )
);

const deleteProfile = createAsyncThunk(
  'deleteAccount',
  handleApiCallAction(profileApi.deleteProfile)
);

const initialState = {
  data: {},
  status: AsyncStatus.Idle,
  error: null
};

const profileSlice = createSlice({
  name: 'ownProfile',
  initialState,
  extraReducers: {
    [fetchOwnProfile.pending]: state => {
      state.status = AsyncStatus.Loading;
      state.error = null;
    },
    [fetchOwnProfile.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = AsyncStatus.Succeeded;
    },
    [fetchOwnProfile.rejected]: (state, action) => {
      state.status = AsyncStatus.Failed;
      state.error = action.error;
    }
  }
});

const getProfileStore = getFp('ownProfile');

function ownProfileSelector(state) {
  return flow(
    getProfileStore,
    getFp('data')
  )(state);
}

const isAdminSelector = flow(
  ownProfileSelector,
  getAdminStatus
);

export {
  getProfileStore,
  ownProfileSelector,
  isAdminSelector,
  fetchOwnProfile,
  updateOwnProfile,
  updatePrivacy,
  updatePreferredLocale,
  deleteProfile
};

export default profileSlice;
