import React from 'react';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Size } from '../../../constants';
import localiseUserName from '../../../utils/localiseUserName';
import { getUserAvatar } from '../../getters/users';
import { getUserEntity } from '../../selectors/users';
import Avatar from '../../components/Avatar';

ConnectedAvatar.propTypes = {
  blurred: PropTypes.bool,
  size: PropTypes.oneOf(values(Size)),
  transparent: PropTypes.bool,
  userId: PropTypes.string
};
function ConnectedAvatar({ blurred, size, t, transparent, userId, ...rest }) {
  const user = useSelector(getUserEntity(userId));
  const avatar = getUserAvatar(user);
  const name = localiseUserName(t, user);

  return (
    <Avatar
      image={avatar}
      blurred={blurred}
      transparent={transparent}
      name={name}
      size={size}
      {...rest}
    />
  );
}

export default translate()(ConnectedAvatar);
