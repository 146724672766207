import React from 'react';
import Button from '@bit/be-novative.kit.button';
import StageIndicator from '../StageIndicator';

export default function StageIndicatorMenuItem({ className, onClick, stage }) {
  return (
    <Button
      onClick={onClick(stage)}
      variant="secondary"
      fillParent
      textAlign="left"
      className={className}>
      <StageIndicator stage={stage} />
    </Button>
  );
}
