import { useSelector, useDispatch } from 'react-redux';
import {
  canEditConcept,
  getStatus,
  getConceptById
} from '../selectors/ideaConcepts';
import { useState, useEffect, useCallback } from 'react';
import { sendAlert } from '../../common/actions/alert';
import { conceptEditorFormFields } from '../constants';
import { changeConceptStageMetrics } from '../actions/metrics';
import useEditConcept from './useEditConcept';

export default function useConceptStatusUpdate(ideaConceptId, errorMessage) {
  const concept = useSelector(getConceptById(ideaConceptId));
  const canEdit = canEditConcept(concept);
  const stage = getStatus(concept);

  const [displayedStage, setDisplayedStage] = useState();
  useEffect(
    function changeStageOnConceptUpdate() {
      setDisplayedStage(stage);
    },
    [stage]
  );

  const dispatch = useDispatch();
  const editConcept = useEditConcept(ideaConceptId);
  const onSelect = useCallback(
    newStage => {
      setDisplayedStage(newStage);
      editConcept({
        [conceptEditorFormFields.CONCEPT_STATE]: newStage
      })
        .then(() => {
          changeConceptStageMetrics(ideaConceptId, newStage);
        })
        .catch(() => {
          setDisplayedStage(stage);
          dispatch(
            sendAlert({
              kind: 'danger',
              message: errorMessage
            })
          );
        });
    },
    [editConcept, ideaConceptId, stage, dispatch, errorMessage]
  );

  return [displayedStage, canEdit, onSelect];
}
