/**
 * Contains the keys of feature flags available for the UI.
 * Intended to be used as arguments in the `useFeatureFlag` hook,
 * which should be called within the `FeatureFlagProvider` context provider.
 *
 * **Important: Enum values must match downloaded feature flag IDs!**
 * @see https://app.configcat.com/
 * @typedef {Object} FeatureFlag
 * @readonly
 */
const FeatureFlag = {
  /**
   * Controls which API should be called by the UI during runtime. The result will be `"EU" | "US"`.
   * @see ApiRegion
   */
  apiRegion: 'apiRegion',
  /** By default only admins are allowed to review concepts. Enabling this feature flag opens evaluation to all platform users. The result will be a **boolean**. */
  canAllUsersReviewConcepts: 'canAllUsersReviewConcepts',
  /** Controls whether users can create challenges on a platform. The result will be a **boolean**. */
  canCreateChallenge: 'canCreateChallenge',
  /**
   * Controls whether users can submit quick concepts (aka insights) on the concept list page,
   * and whether the insight card is visible on the feed page. The result will be a **boolean**. */
  canUseInsights: 'canUseInsights',
  /**
   * Toggles displaying the category selector at the top of the feed page.
   */
  showFeedCategories: 'showFeedCategories',
  /** Redirects to the new auth service when turned on. Uses the legacy solution when turned off. */
  useNewAuth: 'useNewAuth',
  /** Enables rendering idea groups, collision toolbars, merged ideas, and sending group/merge messages. */
  useCanvasGroupMerge: 'useCanvasGroupMerge'
};

export default FeatureFlag;
