import { getPlatformName } from './platform';

export function isCepheidVariant() {
  return ['cepheiddemo', 'cepheid'].includes(getPlatformName());
}

export function isAmdocsVariant() {
  return getPlatformName() === 'amdocsavishkar';
}

export function isConnectHumanityVariant() {
  return ['experiencing', 'financing', 'future'].includes(getPlatformName());
}
