import React, { memo, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from 'react-final-form';
import { translate } from 'react-i18next';
import { required } from '../../../utils/validation';
import { sendAlert } from '../../actions/alert';
import { isLoadingNew, hasFailed } from '../../selectors/meta';
import { selectors as categSelectors } from '../../state/categories';
import useFetchCategories from '../../hooks/useFetchCategories';
import formControl from '@bit/be-novative.kit.form-control';
import Select from '@bit/be-novative.kit.select';

const SelectFormControl = formControl(Select);

CategorySelector.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  showAllCategs: PropTypes.bool,
  t: PropTypes.func.isRequired
};
export function CategorySelector({
  defaultValue,
  name,
  showAllCategs,
  t,
  ...restOfProps
}) {
  const dispatch = useDispatch();
  const categories = useFetchCategories({ showAllCategs });
  const categStatus = useSelector(categSelectors.getStatus);
  const isLoading = isLoadingNew(categStatus);
  const hasError = hasFailed(categStatus);
  const isDisabled = isLoading || hasError || isEmpty(categories);

  useEffect(
    function showError() {
      if (hasError) {
        dispatch(
          sendAlert({
            kind: 'danger',
            message: t('common/prop/category/loadError')
          })
        );
      }
    },
    [dispatch, hasError, t]
  );

  return (
    <Field
      component={SelectFormControl}
      name={name}
      defaultValue={defaultValue}
      disabled={isDisabled}
      label={t('common/prop/category/label')}
      isLoading={isLoading}
      validate={required(t)}
      required
      {...restOfProps}>
      <option value={''}>
        {t(
          isLoading
            ? 'common/status/loading'
            : 'common/prop/category/placeholder'
        )}
      </option>
      {categories.map(({ categoryId, name }) => (
        <option key={categoryId} value={categoryId}>
          {upperFirst(name)}
        </option>
      ))}
    </Field>
  );
}

export default compose(
  memo,
  translate()
)(CategorySelector);
