import flow from 'lodash/flow';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import { setIfFp } from '../../utils/setIf';
import { omitEntities } from '../../telemetry/errorLogging/payloadUtils';
import { rootSelector } from '../selectors';

const scrubStore = state =>
  flow(
    rootSelector,
    setIfFp('ideas', omitEntities),
    setIfFp('ideaStats', map(get('id')))
  )(state);

export default scrubStore;
