import React, { useLayoutEffect } from 'react';
import { Cell } from './ConceptTable.styled';
import { useRef } from 'react';

export default function MeasuredCell({
  children,
  id,
  onSizeChange,
  scope,
  role,
  ariaSort,
  width,
  height,
  expanded
}) {
  const ref = useRef();
  useLayoutEffect(
    function reportSize() {
      if (onSizeChange && ref.current) {
        const rect = ref.current.getBoundingClientRect();
        onSizeChange(id, rect.width, rect.height);
      }
    },
    [id, onSizeChange, expanded]
  );

  return (
    <Cell
      ref={onSizeChange ? ref : null}
      scope={scope}
      role={role}
      ariaSort={ariaSort}
      width={width}
      height={height}
      expanded={expanded}
      data-test={id}>
      {children}
    </Cell>
  );
}
