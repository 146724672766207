import ideaConceptsList from './conceptList';
import conceptReview from './conceptReview';
import ideaConceptPosts from './conceptPosts';
import commentsSlice from '../state/internalComments';
import feedbackSlice from '../state/reviewerFeedback';

export default {
  conceptReview: conceptReview.reducer,
  ideaConceptsList,
  ideaConceptPosts,
  internalComments: commentsSlice.reducer,
  reviewerFeedback: feedbackSlice.reducer
};
