import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './PrimaryNavigation.css';

PrimaryNavigation.propTypes = {
  justify: PropTypes.oneOf(['around']),
  scrollable: PropTypes.bool,
  wrap: PropTypes.bool
};

function PrimaryNavigation({ children, className, justify, scrollable, wrap }) {
  const classes = classNames(
    'PrimaryNavigation',
    {
      [`PrimaryNavigation--${justify}`]: !!justify,
      'PrimaryNavigation--wrap': !!wrap,
      'PrimaryNavigation--scrollable': !!scrollable
    },
    className
  );

  return <ul className={classes}>{children}</ul>;
}

export default PrimaryNavigation;
