import {
  CHANGE_CONCEPT_STAGE,
  CONCEPT_STATUS_UPDATE_CANCEL,
  CONCEPT_LIST_CHANGE_STAGE_FILTER,
  CONCEPT_LIST_CHANGE_SORT,
  CREATE_QUICK_CONCEPT_FORM_OPEN
} from '../constants';

export const cancelConceptStatusFormMetrics = ideaConceptId => ({
  type: CONCEPT_STATUS_UPDATE_CANCEL,
  metrics: { ideaConceptId }
});

export const openQuickConceptFormMetrics = () => ({
  type: CREATE_QUICK_CONCEPT_FORM_OPEN,
  metrics: {}
});

export const changeConceptStageMetrics = (ideaConceptId, stage) => ({
  type: CHANGE_CONCEPT_STAGE,
  metrics: {
    ideaConceptId,
    stage
  }
});

export const changeListSortMetrics = ({ sortBy, sortDirection }) => ({
  type: CONCEPT_LIST_CHANGE_SORT,
  metrics: {
    sortBy,
    sortDirection
  }
});

export const changeListStageFilterMetrics = stage => ({
  type: CONCEPT_LIST_CHANGE_STAGE_FILTER,
  metrics: {
    stage
  }
});
