import { useMemo, useState } from 'react';
import { ImageTypes } from '../../../constants';
import GoogleImagesTab from './GoogleImagesTab';
import PresetTab from './PresetTab';
import UploadImageTab from './UploadImageTab';

const tabs = [
  [ImageTypes.UPLOADED, UploadImageTab],
  [ImageTypes.GOOGLE, GoogleImagesTab],
  [ImageTypes.PRESET, PresetTab]
];

export default function useActiveImagePicker(
  defaultType = ImageTypes.UPLOADED
) {
  const [activePickerType, setActivePickerType] = useState(defaultType);
  const api = useMemo(
    () => ({
      getComponent: () => tabs.find(([tabId]) => tabId === activePickerType)[1],
      getType: () => activePickerType,
      setType: setActivePickerType
    }),
    [activePickerType]
  );
  return api;
}
