import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import useCanReviewConcepts from '../../hooks/useCanReviewConcepts';
import { conceptEvalPage } from '../../paths';
import Button from '@bit/be-novative.kit.button';
import StageTabRow from './StageTabRow';
import FilterControls from './FilterControls';
import FilterStatus from './FilterStatus';
import scss from './ConceptListToolbar.module.scss';

ConceptListToolbar.propTypes = {
  hasContent: PropTypes.bool
};
function ConceptListToolbar({ hasContent, t }) {
  const showReviewButton = useCanReviewConcepts();
  const disableReview = !hasContent;

  return (
    <>
      <div className={scss.StageRow}>
        <StageTabRow className={scss.StageFilters} data-test="tabs" />
        {showReviewButton && (
          <Button
            variant="primary"
            to={disableReview ? null : conceptEvalPage('conceptList')}
            style={{
              opacity: disableReview ? 0.8 : 1,
              cursor: disableReview ? 'not-allowed' : 'pointer'
            }}
            data-test="reviewBtn">
            {t('ideaConcepts/listPage/reviewConceptsCTA')}
          </Button>
        )}
      </div>
      <section style={{ marginTop: '1em' }}>
        <FilterControls data-test="controls" />
      </section>
      <section style={{ marginTop: '1em' }}>
        <FilterStatus data-test="status" />
      </section>
    </>
  );
}

export default translate()(ConceptListToolbar);
