import React, { memo } from 'react';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'react-graceful-image';
import { Size } from '../../../constants';
import { getContentDataUrl, getUrl } from '../../selectors/images';
import useTooltip from '../../hooks/useTooltip';
import { imageDetailsType } from '../../types';
import Icon from '../Icon';
import { convertToRandomBackgroundId, getInitials } from './_utils';
import scss from './Avatar.module.scss';

Avatar.propTypes = {
  blurred: PropTypes.bool,
  image: imageDetailsType,
  name: PropTypes.string,
  size: PropTypes.oneOf(values(Size)),
  transparent: PropTypes.bool
};
function Avatar({
  blurred,
  bgClassName,
  className,
  image,
  name,
  showTooltip = true,
  size = Size.md,
  transparent,
  tooltipPosition,
  ...rest
}) {
  const imgUrl = getUrl(image) || getContentDataUrl(image);
  const initials = name && getInitials(name);
  const showPlaceholder = !imgUrl && !initials;
  const tooltipProps = useTooltip(name, tooltipPosition);
  const tooltip = showTooltip ? tooltipProps : {};

  const wrapperClasses = classNames(
    scss.Wrapper,
    className,
    scss[`size-${size}`],
    {
      [scss.withInitials]: !imgUrl && initials
    }
  );
  const bgClasses = classNames(
    scss.Bg,
    bgClassName,
    scss[`bg-${convertToRandomBackgroundId(initials)}`],
    {
      [scss.blurred]: blurred,
      [scss.transparent]: transparent
    }
  );

  return (
    <figure {...tooltip} {...rest} className={wrapperClasses}>
      <div className={bgClasses}>
        {imgUrl && (
          <Image
            src={imgUrl}
            alt={name}
            noLazyLoad
            className={scss.Image}
            placeholderColor="transparent"
          />
        )}
        {initials && (
          <span className={scss.Initials} aria-label={name}>
            {initials}
          </span>
        )}
        {showPlaceholder && (
          <Icon type="anonymous" wrapperClass={scss.Placeholder} />
        )}
      </div>
    </figure>
  );
}

export default memo(Avatar);
