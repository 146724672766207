import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories, selectors } from '../state/categories';
import useMountEffect from './useMountEffect';

export default function useFetchCategories(options = { showAllCategs: false }) {
  const { showAllCategs } = options;
  const dispatch = useDispatch();
  const categories = useSelector(selectors.selectAll);

  const handleFetch = useCallback(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useMountEffect(function maybeFetchCategs() {
    if (isEmpty(categories) && showAllCategs) {
      handleFetch();
    }
  });

  const cepheidCategs = [
    {
      categoryId: '35a4bc57-db81-ec11-94f6-e42aac654314',
      name: 'Business Model Innovation',
      rank: 4
    },
    {
      categoryId: '36a4bc57-db81-ec11-94f6-e42aac654314',
      name: 'Product Innovation',
      rank: 5
    },
    {
      categoryId: '37a4bc57-db81-ec11-94f6-e42aac654314',
      name: 'Manufacturing/Operations Innovation',
      rank: 6
    },
    {
      categoryId: '38a4bc57-db81-ec11-94f6-e42aac654314',
      name: 'Other',
      rank: 7
    }
  ];

  return showAllCategs ? categories : cepheidCategs;
}
