import React, { useCallback } from 'react';
import keys from 'lodash/keys';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@bit/be-novative.kit.button';
import Logo from '@bit/be-novative.kit.logo/dist/Logo';
import LanguageDropdown from '../../../common/components/LanguageDropdown';
import PageHeader, {
  Section,
  Item
} from '../../../common/components/PageHeader/PageHeader';
import { changeLanguage } from '../../../common/actions';
import { getCurrentLanguage } from '../../../common/selectors/language';
import useWindowSize from '../../../common/hooks/useWindowSize';
import { SUPPORTED_UI_LANGUAGES_NATIVE } from '../../../constants';
import paths from '../../../paths';
import authPaths from '../../paths';
import { getPlatformName } from '../../../utils/platform';
import customLogo from './logo.jpg';

const hasCustomLogo = ['experiencing', 'financing', 'future'].includes(
  getPlatformName()
);

const AVAILABLE_LANGS = keys(SUPPORTED_UI_LANGUAGES_NATIVE);

AuthPageHeader.propTypes = {
  showAuthLinks: PropTypes.bool
};
export function AuthPageHeader({ t, showAuthLinks = true, ...props }) {
  const { width } = useWindowSize();

  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const handleLanguageChange = useCallback(
    lang => {
      dispatch(changeLanguage(lang));
    },
    [dispatch]
  );

  return (
    <PageHeader {...props}>
      <Section>
        <Item>
          <Link to={paths.root()} style={{ display: 'inline-block' }}>
            {hasCustomLogo ? (
              <img
                src={customLogo}
                style={{
                  width: 150,
                  height: 'auto'
                }}
                alt="Go to homepage"
              />
            ) : (
              <Logo compact={width < 480} />
            )}
          </Link>
        </Item>
      </Section>

      <Section>
        {showAuthLinks && (
          <Section>
            <Item>
              <Button to={authPaths.login()} variant="link-secondary">
                {t('common/Login')}
              </Button>
            </Item>
            <Item>
              <Button to={authPaths.registration()} variant="primary" uppercase>
                {t('common/Signup')}
              </Button>
            </Item>
          </Section>
        )}

        <Section>
          <Item>
            <LanguageDropdown
              availableLanguages={AVAILABLE_LANGS}
              currentLanguage={currentLanguage}
              onChange={handleLanguageChange}
            />
          </Item>
        </Section>
      </Section>
    </PageHeader>
  );
}

export default translate()(AuthPageHeader);
