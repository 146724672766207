import { createAction } from 'redux-actions';
import {
  NAVIGATION_CONFIRM,
  NAVIGATION_CONFIRM_CLEAR,
  ROUTE_CHANGE
} from '../../constants';

export const confirmNavigation = createAction(NAVIGATION_CONFIRM);
export const clearNavigationConfirm = createAction(NAVIGATION_CONFIRM_CLEAR);
export const routeChange = createAction(ROUTE_CHANGE);
