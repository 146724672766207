import flow from 'lodash/flow';
import get from 'lodash/fp/get';
import isEqual from 'lodash/fp/isEqual';
import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import { SOCKET_EVENTS } from '../../api/constants';
import {
  SOCKET_CONNECTING,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECTED,
  SOCKET_ERROR
} from '../actions/socket';

const status = handleActions(
  {
    [SOCKET_CONNECTING](state, { meta }) {
      return {
        ...state,
        [meta.socketId]: SOCKET_EVENTS.CONNECTING
      };
    },
    [SOCKET_CONNECTED](state, { meta }) {
      return {
        ...state,
        [meta.socketId]: SOCKET_EVENTS.CONNECTED
      };
    },
    [SOCKET_DISCONNECTED](state, { meta }) {
      return {
        ...state,
        [meta.socketId]: SOCKET_EVENTS.DISCONNECTED
      };
    }
  },
  {}
);

const error = handleActions(
  {
    [SOCKET_ERROR](state, { payload, meta }) {
      return {
        ...state,
        [meta.socketId]: payload
      };
    },
    [combineActions(SOCKET_CONNECTED, SOCKET_CONNECTING, SOCKET_DISCONNECTED)](
      state,
      { meta }
    ) {
      return {
        ...state,
        [meta.socketId]: null
      };
    }
  },
  {}
);

export default combineReducers({
  status,
  error
});

const rootSelector = get('socket');
export const socketStatusSelector = socketId => state =>
  flow(
    rootSelector,
    get(['status', socketId])
  )(state);
export const isSocketConnected = socketId => state =>
  flow(
    socketStatusSelector(socketId),
    isEqual(SOCKET_EVENTS.CONNECTED)
  )(state);
export const socketErrorSelector = socketId => state =>
  flow(
    rootSelector,
    get(['error', socketId])
  )(state);
