import get from 'lodash/get';

// TODO move to getters folder

/**
 * Returns the maximum amount of time (in seconds) until ideation participants remain
 * in the current ideation phase. A phase change occurs after this time is expired,
 * regardless of the participant activity.
 * @param {IdeationPhase} phaseConfig
 * @returns {number} The maximum length of this
 */
export const getMaxTime = phaseConfig => get(phaseConfig, 'maxLengthInSeconds');

/**
 * Returns the phase name that should be displayed for participants during ideation.
 * It is either a string constant for default values, or a custom value entered by the challenge creator
 * @param {IdeationPhase} phaseConfig
 * @returns {(string|DefaultIdeationPhaseName)}
 */
export const getPhaseName = phaseConfig => get(phaseConfig, 'name');

/**
 * Returns the phase type that dictates phase behaviour during ideation
 * @param {IdeationPhase} phaseConfig
 * @returns {IdeationPhaseType}
 */
export const getPhaseType = phaseConfig =>
  get(phaseConfig, 'ideationPhaseType');

/**
 * @param {IdeationPhase} phaseConfig
 * @returns {string}
 */
export const getPhaseId = phaseConfig => get(phaseConfig, 'ideationPhaseId');
