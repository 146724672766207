import { createSelector } from 'reselect';
import concat from 'lodash/concat';
import find from 'lodash/fp/find';
import filter from 'lodash/filter';
import filterFp from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/map';
import mapFp from 'lodash/fp/map';
import max from 'lodash/max';
import flow from 'lodash/flow';
import isEqual from 'lodash/fp/isEqual';
import orderBy from 'lodash/fp/orderBy';
import includes from 'lodash/includes';
import includesFp from 'lodash/fp/includes';
import {
  MAX_ITEMS_IN_ROOM,
  ideationIdProps,
  DRAGGABLE_TYPES,
  IdeationPhaseType
} from '../constants';
import produce from 'immer';
import { ownProfileSelector } from '../../profile/state/profile';
import { getUserId } from '../../common/getters/users';
import { getAllIdsFrom } from '../../common/selectors/entities';
import { getIdeaId } from '../../common/selectors/ideas';
import { getCreateTime } from '../../common/selectors/meta';
import { getZIndex } from '../../dndCanvas/selectors/canvasItem';
import getNumOrZero from '../../utils/getNumOrZero';
import { selectors as phaseSelectors } from '../state/phases';

export const rootSelector = get('ideation');

export const hummingbirdLoveCountSelector = state =>
  flow(
    rootSelector,
    getOr(0, 'hummingbirdLoveCount')
  )(state);
export const lovedIdeasSelector = state =>
  flow(
    rootSelector,
    getOr([], 'lovedIdeas')
  )(state);

// settings
export const roomSpecificUserIdSelector = state =>
  flow(
    rootSelector,
    get('roomSpecificUserId')
  )(state);
export const roomIdSelector = state =>
  flow(
    rootSelector,
    get('roomId')
  )(state);

const getById = get('byId');
const getIdeas = flow(
  rootSelector,
  get('ideas')
);
const getImages = flow(
  rootSelector,
  get('images')
);
const getWords = flow(
  rootSelector,
  get('words')
);

export const getItemsMovedByUser = get('ideation.itemsMovedByUser');

export const isItemMovedByUser = (state, itemId) =>
  flow(
    getItemsMovedByUser,
    includesFp(itemId)
  )(state);

export const visibleEntitiesSelector = createSelector(
  flow(
    getIdeas,
    getAllIdsFrom
  ),
  flow(
    getIdeas,
    getById
  ),
  flow(
    getImages,
    getAllIdsFrom
  ),
  flow(
    getImages,
    getById
  ),
  (ideaIds, ideasById, imageIds, imagesById) => {
    return [
      ...map(ideaIds, id => ideasById[id]),
      ...map(imageIds, id => imagesById[id])
    ]
      .sort((a, b) => (getCreateTime(a) < getCreateTime(b) ? 1 : -1))
      .splice(0, MAX_ITEMS_IN_ROOM);
  }
);

const visibleIdeasSelector = createSelector(
  visibleEntitiesSelector,
  filterFp(getIdeaId)
);

export const getImageId = get(ideationIdProps.IMAGE_ID);

const visibleImagesSelector = createSelector(
  phaseSelectors.getCurrentPhase,
  visibleEntitiesSelector,
  (phaseConfig, visibleItems) => {
    const preselectedImages = phaseConfig.images || [];
    return concat(preselectedImages, filter(visibleItems, getImageId));
  }
);

export const wordsSelector = createSelector(
  phaseSelectors.getCurrentPhase,
  flow(
    getWords,
    getById
  ),
  flow(
    getWords,
    getAllIdsFrom
  ),
  getItemsMovedByUser,
  (phaseConfig, byId, allIds, itemsMovedByUser) => {
    const preselectedWords = phaseConfig.words || [];
    const allWords = concat(preselectedWords, map(allIds, id => byId[id]));
    return map(
      allWords,
      produce(word => {
        word.movedByUser = includes(itemsMovedByUser, word.wordId);
      })
    );
  }
);

export const ideasSelector = createSelector(
  visibleIdeasSelector,
  flow(
    rootSelector,
    getOr([], ['unseenIdeas'])
  ),
  lovedIdeasSelector,
  roomSpecificUserIdSelector,
  phaseSelectors.getCurrentPhaseType,
  getItemsMovedByUser,
  (visibleIdeas, unseenIdeas, lovedIdeas, userId, phase, itemsMovedByUser) =>
    map(visibleIdeas, idea => {
      return {
        ...idea,
        loved: includes(lovedIdeas, idea.ideaId),
        unseen: includes(unseenIdeas, idea.ideaId),
        own: idea.owner === userId,
        redacted: idea.owner !== userId && phase === IdeationPhaseType.Redacted,
        movedByUser: includes(itemsMovedByUser, idea.ideaId)
      };
    })
);

export const imagesSelector = createSelector(
  visibleImagesSelector,
  roomSpecificUserIdSelector,
  getItemsMovedByUser,
  (visibleImages, userId, itemsMovedByUser) =>
    map(visibleImages, image => {
      return {
        ...image,
        own: image.owner === userId,
        movedByUser: includes(itemsMovedByUser, image.imageId)
      };
    })
);

export const getAllCanvasItems = createSelector(
  ideasSelector,
  imagesSelector,
  wordsSelector,
  (ideas, images, words) =>
    [...ideas, ...images, ...words]
      .sort((a, b) => (getCreateTime(a) < getCreateTime(b) ? 1 : -1))
      .splice(0, MAX_ITEMS_IN_ROOM)
);

const ideationRootSelector = get('ideation');

export const usersSelector = flow(
  ideationRootSelector,
  get('users')
);

export const usersOrderByLoveSelector = createSelector(
  flow(
    usersSelector,
    getOr({}, 'byId')
  ),
  flow(
    usersSelector,
    getOr([], 'allIds')
  ),
  (byId, allIds) =>
    flow(
      mapFp(id => byId[id]),
      orderBy(['loveCount'], ['desc'])
    )(allIds)
);

/**
 * Used for handling edge cases, e.g. multiple browser tabs, or the user being kicked from the server side.
 * Depending on the challenge scenario config, ideation users may contain their account IDs, or temporarily assigned user IDs.
 * Therefore, both types of IDs must be checked for the current user.
 * @summary Checks if a user is still in the room by comparing IDs of participants against user's own IDs
 * @param {Object} state - Redux state
 * @return {boolean} `true` if the user's temporary or account ID is still included in the participant list
 */

export const isCurrentUserInRoom = createSelector(
  roomSpecificUserIdSelector,
  flow(
    ownProfileSelector,
    getUserId
  ),
  flow(
    usersSelector,
    getOr([], 'allIds')
  ),
  (ownTempId, ownAccountId, participantIds) =>
    includes(participantIds, ownTempId) ||
    includes(participantIds, ownAccountId)
);

const TYPE_SELECTORS = {
  [DRAGGABLE_TYPES.IDEA]: ideasSelector,
  [DRAGGABLE_TYPES.IMAGE]: visibleImagesSelector,
  [DRAGGABLE_TYPES.WORD]: wordsSelector
};

export const getZIndexForItem = (type, id) => state =>
  flow(
    TYPE_SELECTORS[type],
    find(
      flow(
        get(DRAGGABLE_TYPES[type]),
        isEqual(id)
      )
    ),
    getZIndex,
    getNumOrZero
  )(state);

export const getMaxZIndexForType = type => state =>
  flow(
    TYPE_SELECTORS[type],
    mapFp(getZIndex),
    max,
    getNumOrZero
  )(state);

export const isOnline = user => get('online')(user);

export const isUserOnline = createSelector(
  state => state.ideation.users.byId,
  (_, userId) => userId,
  (users, userId) =>
    flow(
      get(userId),
      isOnline
    )(users)
);
