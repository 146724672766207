import React from 'react';
import PropTypes from 'prop-types';
import ConnectedAvatar from '../../../common/containers/ConnectedAvatar';
import scss from './ConceptOwnerAvatar.module.scss';

ConceptOwnerAvatar.propTypes = {
  userId: PropTypes.string.isRequired
};

function ConceptOwnerAvatar({ userId, ...rest }) {
  return <ConnectedAvatar {...rest} userId={userId} className={scss.Avatar} />;
}

export default ConceptOwnerAvatar;
