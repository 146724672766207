import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import HttpStatusCodes from 'http-status-codes';
import paths from '../../../paths';
import parseQueryString from '../../../utils/parseQueryString';
import Button from '@bit/be-novative.kit.button';
import FullScreen from '../../components/FullScreen';
import NonIdealState from '../../components/NonIdealState';
import GlobalPageHeader from '../../components/GlobalPageHeader';
import Sidenav from '../Sidenav';

function ErrorPage({ location, t }) {
  let { reason, resource } = parseQueryString(location.search);
  reason = parseInt(reason, 10);

  let props = {
    title: t('error/Ooops!'),
    description: t(
      'error/An unexpected error seems to have occured. Why not try refreshing your page? Or you can contact us if the problem persists.'
    )
  };

  if (reason === HttpStatusCodes.INTERNAL_SERVER_ERROR) {
    props = {
      title: t('error/Internal Server Error'),
      description: t(
        'error/The server encountered an error and could not complete your request.'
      )
    };
  } else if (reason === HttpStatusCodes.FORBIDDEN) {
    props = {
      title: t('error/Access Denied'),
      description: t(
        'error/You do not have permission to access {{resource}}',
        { resource }
      ),
      action: (
        <Button variant="primary" to={paths.feed()}>
          {t('common/userAction/nav/backToFeed', { context: 'long' })}
        </Button>
      )
    };
  }

  return (
    <FullScreen type="overview">
      <GlobalPageHeader />
      <Sidenav />
      <NonIdealState visual={NonIdealState.HUMMINGBIRD} {...props} />
    </FullScreen>
  );
}

ErrorPage.propTypes = {
  params: PropTypes.shape({
    reason: PropTypes.number,
    resource: PropTypes.string
  })
};

export default translate()(ErrorPage);
