import React from 'react';
import { Router } from 'react-router-dom';
import { routeChange } from './common/actions';
import { injectAsyncReducer } from './store';
import history from './history';
import App from './common/containers/App';

export default function configureRoutes(store) {
  function injectModuleReducers(mod) {
    const reducers = mod.reducers || {};
    Object.keys(reducers).map(key =>
      injectAsyncReducer(store, key, reducers[key])
    );
    return mod;
  }

  store.dispatch(routeChange(window.location));

  let prevLocation = {};
  history.listen(location => {
    if (location.pathname !== prevLocation.pathname) {
      store.dispatch(routeChange(location));
      prevLocation = location;
    }
  });

  return (
    <Router history={history}>
      <App injectModuleReducers={injectModuleReducers} />
    </Router>
  );
}
