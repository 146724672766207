import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import { sendSocketMessage } from '../../common/actions/socket';
import { getIdeaId } from '../../common/selectors/ideas';
import addBreadcrumb from '../../telemetry/addBreadcrumb';
import forceArray from '../../utils/forceArray';
import { SOCKET_MSG, S_ID } from '../constants';
import { getGroupById, getIdeasByGroupId } from '../selectors/groups';
import { getCanvasItemPosition } from '../selectors/movement';
import { normaliseApiPosition } from '../utils/socketMsg/_mappers';
import { storeCanvasItemPosition } from './movement';

export const STORE_GROUPS = 'canvas/group/store';
/**
 * Stores one or more items as canvas groups in Redux
 * @param {CanvasIdeaGroup|CanvasIdeaGroup[]} payload
 * @returns {import('redux').Action}
 */
export const storeCanvasGroups = payload => ({
  type: STORE_GROUPS,
  payload: forceArray(payload),
  meta: { canvasId: S_ID }
});

/**
 * Sends a socket message to update the content of a group.
 * Optimistically updates internal state before the API response arrives.
 * Should be used for adding or removing items, and for deleting groups.
 * @param {string[]} ideaIds - Array of idea IDs that are still in the group
 * @param {number} posX - Group X coordinate in %
 * @param {number} posY - Group X coordinate in %
 * @param {string|number|null} [groupId] - Group UUID
 * @param {string} [title] - Group title
 * @param {string|number|null} [oldGroupId] - Old group UUID. Used to check if the old group should be hidden after removing the last item.
 * @param {any} [section] Ignored, reserved
 * @returns {import('redux-thunk').ThunkAction}
 */
export const sendUpdateGroup = (
  ideaIds = [],
  posX,
  posY,
  groupId = null,
  groupTitle = null,
  oldGroupId = null,
  section = null
) => (dispatch, getState) => {
  const group = getGroupById(getState(), groupId);
  const title = groupTitle || group.title || null;
  dispatch(
    sendSocketMessage(S_ID, SOCKET_MSG.GROUP_ADD_IDEA_REQ, [
      groupId,
      uniq(ideaIds),
      { x: posX, y: posY },
      section,
      title
    ])
  );
  // Optimistically add new items to avoid drag-drop glitch
  if (groupId) {
    dispatch(updateGroup(groupId, ideaIds, title));
  }
  // Handle when last item is moved from one group to another
  if (oldGroupId) {
    const oldGroupItemIds = getIdeasByGroupId(getState(), oldGroupId);
    if (oldGroupItemIds.length === 0) {
      dispatch(hideGroup(oldGroupId));
    }
  }
};

/**
 * Sends a socket message to remove ideas, and immediately updates the UI
 * in a separate action.
 * @param {string[]} itemIds
 * @param {string|number} groupId - Group UUID
 * @returns {import('redux-thunk').ThunkAction}
 */
export const sendRemoveIdeasFromGroup = (itemIds = [], groupId) => (
  dispatch,
  getState
) => {
  const [posX, posY] = getCanvasItemPosition(getState(), groupId);
  const groupItems = getIdeasByGroupId(getState(), groupId).map(getIdeaId);
  const remainingItems = without(groupItems, ...itemIds);
  dispatch(sendUpdateGroup(remainingItems, posX, posY, groupId));
};

export const IDEAS_GROUPED = 'canvas/group/changeItems';
export const IDEAS_UNGROUPED = 'canvas/group/removeIdeas';
export const HIDE_GROUP = 'canvas/group/hide';

export const hideGroup = groupId => ({
  type: HIDE_GROUP,
  meta: { id: groupId, canvasId: S_ID }
});

const addItemsToGroup = (groupId, groupTitle, itemIds) => (
  dispatch,
  getState
) => {
  const group = getGroupById(getState(), groupId);
  dispatch(
    storeCanvasGroups({
      ideaGroupId: groupId,
      title: groupTitle || group.title
    })
  );
  dispatch({
    type: IDEAS_GROUPED,
    payload: { itemIds },
    meta: {
      id: groupId,
      canvasId: S_ID
    }
  });
};

const removeItemsFromGroup = (groupId, ungroupedItemIds) => (
  dispatch,
  getState
) => {
  dispatch({
    type: IDEAS_UNGROUPED,
    payload: { itemIds: ungroupedItemIds },
    meta: { canvasId: S_ID }
  });
  const groupItemIds = getIdeasByGroupId(getState(), groupId).map(getIdeaId);
  const isGroupBecomingEmpty = isEqual(ungroupedItemIds, groupItemIds);
  if (isGroupBecomingEmpty) {
    dispatch(hideGroup(groupId));
  }
};

/**
 * Handles incoming messages for idea grouping.
 * Based on the group ID and the included idea IDs,
 * this method must infer and handle the following cases:
 * - grouping ideas (based on idea IDs)
 * - ungrouping ideas
 * - ungrouping concepts
 * - deleting empty groups.
 * Ungrouping mixed item types is not supported.
 * @param {string|number} groupId - Group UUID
 * @param {(string|number)[]} itemIds - Child item IDs
 * @param {string} [groupTitle] - Updated group title
 * @returns {import('redux-thunk').ThunkAction}
 */
export const updateGroup = (groupId, itemIds, groupTitle) => (
  dispatch,
  getState
) => {
  const groupItemIds = getIdeasByGroupId(getState(), groupId).map(getIdeaId);
  const ungroupedItemIds = groupItemIds.filter(id => !itemIds.includes(id));
  if (groupId && size(ungroupedItemIds) > 0) {
    dispatch(removeItemsFromGroup(groupId, ungroupedItemIds));
  } else if (groupId && size(itemIds) > 0) {
    dispatch(addItemsToGroup(groupId, groupTitle, itemIds));
  } else if (size(itemIds) === 0) {
    dispatch(hideGroup(groupId));
  }
};

export const RENAME_GROUP = 'canvas/group/rename';
export const renameGroup = (groupId, title) => ({
  type: RENAME_GROUP,
  payload: { id: groupId, title },
  meta: { canvasId: S_ID }
});

export const sendRenameGroup = (groupId, title) => async dispatch => {
  try {
    await dispatch(
      sendSocketMessage(S_ID, SOCKET_MSG.GROUP_RENAME_REQ, [groupId, title])
    );
    dispatch(renameGroup(groupId, title));
  } catch (error) {
    addBreadcrumb(error, { category: 'canvas' });
    throw error;
  }
};

export const handleGroupUpdated = response => dispatch => {
  let groupId, itemIds, position, groupTitle;
  try {
    // eslint-disable-next-line no-unused-vars
    let section;
    [groupId, itemIds, position, section, groupTitle] = response;
  } catch (error) {
    throw new Error(
      'Could not parse socket response ' + JSON.stringify(response)
    );
  }
  const { posX, posY } = normaliseApiPosition(position);
  dispatch(updateGroup(groupId, itemIds, groupTitle));
  dispatch(storeCanvasItemPosition({ id: groupId, posX, posY }));
};

export const handleGroupRenamed = response => {
  let groupId, title;
  try {
    [groupId, title] = response;
  } catch (error) {
    throw new Error(
      'Could not parse socket response ' + JSON.stringify(response)
    );
  }
  return renameGroup(groupId, title);
};
