import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import './PrimaryNavigationButton.css';

PrimaryNavigationButton.defaultProps = {
  secondary: false,
  push: false
};

function PrimaryNavigationButton({
  children,
  className,
  push,
  secondary,
  iconType,
  onClick,
  to,
  ...rest
}) {
  return (
    <li className={cn('PrimaryNavigationButton', className)}>
      <NavLink
        {...rest}
        to={to}
        onClick={onClick}
        className="PrimaryNavigationButton__Link"
        activeClassName="PrimaryNavigationButton__Link--active">
        {children}
      </NavLink>
    </li>
  );
}

export default PrimaryNavigationButton;
