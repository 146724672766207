import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import flow from 'lodash/flow';
import getFp from 'lodash/fp/get';
import isEqualFp from 'lodash/fp/isEqual';
import {
  createAsyncThunk,
  createSlice,
  createSelector
} from '@reduxjs/toolkit';
import { JOIN_CHALLENGE } from '../../constants';
import addBreadcrumb from '../../telemetry/addBreadcrumb';
import {
  normalisePhaseChangedWithConfigMsg,
  reqPhaseChangeMsg
} from '../utils/socketMsg/phases';

const initialState = {
  canUserChangePhase: false,
  currentPhaseId: null,
  configs: []
};

const phasesSlice = createSlice({
  name: 'phases',
  initialState,
  reducers: {
    phaseChangedWithConfig: (state, action) => {
      const { currentPhaseId, phases } = normalisePhaseChangedWithConfigMsg(
        action.payload
      );
      if (!Array.isArray(phases)) {
        throw new Error('phases must be an array, got ' + typeof phases);
      }
      state.currentPhaseId = currentPhaseId;
      state.configs = phases;
    },
    toggleManualChange: (state, action) => {
      state.canUserChangePhase = Boolean(action.payload);
    }
  },
  extraReducers: builder => {
    builder.addCase(JOIN_CHALLENGE.SUCCESS, (state, action) => {
      const { ideationPhaseId, ideationPhases } = action.payload;
      if (!Array.isArray(ideationPhases)) {
        throw new Error('phases must be an array, got ' + typeof phases);
      }
      state.currentPhaseId = ideationPhaseId;
      state.configs = ideationPhases;
    });
    builder.addCase('RESET_IDEATION', () => initialState);
  }
});

const rootSelector = getFp(['ideation', 'phases']);
const idMatches = (phase, targetId) =>
  flow(
    getFp('id'),
    isEqualFp(targetId)
  )(phase);
const getCurrentPhaseId = createSelector(
  rootSelector,
  state => state.currentPhaseId
);
const getAllPhaseConfigs = createSelector(
  rootSelector,
  state => state.configs
);
const getPhaseById = createSelector(
  getAllPhaseConfigs,
  (_, targetId) => targetId,
  (phases, targetId) => find(phases, phase => idMatches(phase, targetId))
);
const getCurrentPhase = createSelector(
  getAllPhaseConfigs,
  getCurrentPhaseId,
  (phases, currentId) => find(phases, phase => idMatches(phase, currentId))
);
const getCurrentPhaseType = createSelector(
  getCurrentPhase,
  getFp('type')
);
const getCanUserChangePhase = createSelector(
  rootSelector,
  state => state.canUserChangePhase
);
const getNextPhaseId = createSelector(
  getAllPhaseConfigs,
  getCurrentPhaseId,
  (phases, currentId) => {
    const currentIx = findIndex(
      phases,
      flow(
        getFp('id'),
        isEqualFp(currentId)
      )
    );
    if (currentIx === phases.length) {
      addBreadcrumb(
        new Error('Range error: Trying to go past final ideation phase', {
          category: 'ideation'
        })
      );
    }
    const nextIx = Math.min(currentIx + 1, phases.length - 1);
    return phases[nextIx].id;
  }
);

const selectors = {
  getAllPhaseConfigs,
  getPhaseById,
  getNextPhaseId,
  getCurrentPhase,
  getCurrentPhaseId,
  getCurrentPhaseType,
  getCanUserChangePhase
};

const advancePhase = createAsyncThunk('phases/goToNext', (_, thunkApi) => {
  const nextPhaseId = getNextPhaseId(thunkApi.getState());
  // Socket messages don't have replies (apart from successfully delivering the message),
  // so no need for handling an async response.
  thunkApi.dispatch(reqPhaseChangeMsg(nextPhaseId));
});
// TODO is there an idiomatic way for augmenting the state slice actions?
phasesSlice.actions.advancePhase = advancePhase;

export { initialState, selectors };
export default phasesSlice;
