import React from 'react';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import ChallengeRow from './ChallengeRow';

ChallengeList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  selections: PropTypes.arrayOf(PropTypes.string)
};
ChallengeList.defaultProps = {
  items: [],
  selections: []
};
function ChallengeList({ items, onSelect, selections }) {
  return items.map(challengeId => (
    <ChallengeRow
      key={challengeId}
      challengeId={challengeId}
      onSelect={onSelect}
      checked={includes(selections, challengeId)}
    />
  ));
}

export default ChallengeList;
