import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import groupBy from 'lodash/groupBy';
import { useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ALERT_TOAST } from '../../../constants/e2e';
import Alert from './Alert';
import './Alerts.css';

function Alerts({
  transitionEnterTimeout = 600,
  transitionLeaveTimeout = 600
}) {
  const alerts = useSelector(state =>
    state.alerts.map(alert => ({
      ...alert,
      position: alert.position || 'top'
    }))
  );
  const groupedAlerts = groupBy(alerts, 'position');

  return (
    <TransitionGroup>
      {Object.keys(groupedAlerts).map(position => (
        <CSSTransition
          key={position}
          classNames={'alert-transition-'}
          timeout={{
            enter: transitionEnterTimeout,
            exit: transitionLeaveTimeout
          }}>
          <div
            className={classNames(
              'AlertContainer',
              `AlertContainer--${position}`
            )}
            data-test={ALERT_TOAST}>
            <CSSTransition
              classNames={'alert-transition-'}
              timeout={{
                enter: transitionEnterTimeout,
                exit: transitionLeaveTimeout
              }}>
              <div>
                {groupedAlerts[position].map(alert => (
                  <Alert {...alert} key={alert.id} />
                ))}
              </div>
            </CSSTransition>
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
}

Alerts.propTypes = {
  transitionEnterTimeout: PropTypes.number,
  transitionLeaveTimeout: PropTypes.number
};

export default Alerts;
