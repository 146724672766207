import { createMetrics } from 'react-metrics';
import { ROUTE_CHANGE } from '../constants';
import metricsConfig from './metricsConfig';
import ChallengeCreationTracker from './trackers/ChallengeCreationTracker';
import FeedTracker from './trackers/FeedTracker';
import HeaderTracker from './trackers/HeaderTracker';
import LoginTracker from './trackers/LoginTracker';
import RegistrationTracker from './trackers/RegistrationTracker';
import CommonTracker from './trackers/CommonTracker';
import ChallengeResultsTracker from './trackers/ChallengeResultsTracker';
import ChallengeSuggestionsTracker from './trackers/ChallengeSuggestionsTracker';
import IdeaConceptsTracker from './trackers/IdeaConceptsTracker';
import { getTokenData } from '../authentication/services/token.service';

const metrics = createMetrics(metricsConfig);

let challengeCreationTracker = new ChallengeCreationTracker(metrics);
let feedTracker = new FeedTracker(metrics);
let headerTracker = new HeaderTracker(metrics);
const challengeSuggestionsTracker = new ChallengeSuggestionsTracker(metrics);
const ideaConceptsTracker = new IdeaConceptsTracker(metrics);
let loginTracker = new LoginTracker(metrics);
let registrationTracker = new RegistrationTracker(metrics);
let commonTracker = new CommonTracker(metrics);
let challengeResultsTracker = new ChallengeResultsTracker(metrics);

export default function metricsMiddleware({ getState }) {
  return next => action => {
    const returnValue = next(action);
    const { email, userId } = getTokenData();
    const currentDomain = window.location.hostname;
    const benovativeEmployee = email && email.endsWith('@be-novative.com');

    // only track be-novative employees if they're working on cloud.be-novative.com
    const doNotTrack =
      !currentDomain.startsWith('cloud.') && benovativeEmployee;
    if (doNotTrack) {
      return returnValue;
    }

    if (action.type === ROUTE_CHANGE) {
      const location = action.payload;
      try {
        metrics.setRouteState(location);
        metrics.api.pageView({ userId: userId });
      } catch (error) {
        console.error('Metrics error', error);
      }
    } else {
      headerTracker.track(action, userId);
      challengeCreationTracker.track(action, userId);
      feedTracker.track(action, userId);
      challengeSuggestionsTracker.track(action, userId);
      ideaConceptsTracker.track(action, userId);
      loginTracker.track(action, userId);
      registrationTracker.track(action, userId);
      commonTracker.track(action, userId);
      challengeResultsTracker.track(action, userId);
    }

    return returnValue;
  };
}
