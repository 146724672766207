import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import './polyfills/Selection.prototype.addRange';
import './polyfills/String.prototype.startsWith';
import './polyfills/String.prototype.padStart';
import './polyfills/String.prototype.padEnd';
import 'dom4';

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { enableMapSet, enableES5 } from 'immer';
import bootstrapApi from './api/bootstrap';
import getApiUrl from './api/utils/getApiUrl';
import configureStore from './store';
import configureI18n from './i18n';
import configureRoutes from './routes';
import { getCurrentLanguage } from './common/selectors/language';
import { changeLanguage } from './common/actions';
import { getPreferredLanguage } from './utils/storage';
import BootstrapError from './common/pages/BootstrapError';
import getApiSymbolPath from './api/utils/getApiSymbolPath';
import initTelemetry from './telemetry/init';
import { isIE } from './utils/userAgent';
import App from './App';

/**
|--------------------------------------------------
| Init Sentry and Azure App Insights
|--------------------------------------------------
*/

initTelemetry();

/**
|--------------------------------------------------
| Opt-in to Immer ES6 features + IE11 support
|--------------------------------------------------
*/

if (isIE()) {
  enableES5();
}
enableMapSet();

/**
|--------------------------------------------------
| Get API config and render app
|--------------------------------------------------
*/

bootstrapApi()
  .then(function renderAfterBootstrap({ region }) {
    const apiBaseURL = getApiUrl(region);
    const urlGetter = apiSymbol => getApiSymbolPath(apiBaseURL, apiSymbol);

    const store = configureStore({ urlGetter });

    const currentLanguage = getCurrentLanguage(store.getState());
    const onLocaleReady = () => store.dispatch(changeLanguage(currentLanguage));
    const i18n = configureI18n(onLocaleReady);

    const router = configureRoutes(store);

    ReactDOM.render(
      <App i18n={i18n} router={router} store={store} />,
      document.getElementById('root')
    );
  })
  .catch(function catchAfterBootstrap(error) {
    const currentLanguage = getPreferredLanguage() || 'en';
    const i18n = configureI18n();

    i18n.changeLanguage(currentLanguage);

    ReactDOM.render(
      <I18nextProvider i18n={i18n}>
        <BootstrapError />
      </I18nextProvider>,
      document.getElementById('root')
    );
  });
