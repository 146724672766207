// login

export const LOGIN_BUTTON_CLICK = 'LOGIN_BUTTON_CLICK';
export const loginButtonClick = {
  type: LOGIN_BUTTON_CLICK
};

// signup

export const USER_SIGNUP = 'USER_SIGNUP';
export const signUpButtonClick = withInvitation => ({
  type: USER_SIGNUP,
  meta: {
    metrics: {
      withInvitation
    }
  }
});

export const CONTINUE_SIGNUP_BUTTON_CLICK = 'CONTINUE_SIGNUP_BUTTON_CLICK';
export const continueSignUp = () => dispatch => {
  dispatch({
    type: CONTINUE_SIGNUP_BUTTON_CLICK
  });
};

// social

export const SOCIAL_LOGIN_CLICKED = 'SOCIAL_LOGIN_CLICKED';
export const socialLoginClicked = provider => ({
  type: SOCIAL_LOGIN_CLICKED,
  meta: { provider }
});

export const SOCIAL_SIGN_UP_BUTTON_CLICK = 'SOCIAL_SIGN_UP_BUTTON_CLICK';
export const socialSignUpButtonClick = provider => ({
  type: SOCIAL_SIGN_UP_BUTTON_CLICK,
  meta: {
    metrics: {
      provider
    }
  }
});
