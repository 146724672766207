import includes from 'lodash/includes';
import { combineActions, handleActions } from 'redux-actions';
import { EDIT_IDEA_CONCEPT } from '../../../constants';
import { dropById } from './commonMethods';

const pendingEditReducer = handleActions(
  {
    [EDIT_IDEA_CONCEPT.PENDING](
      state,
      {
        meta: { ideaConceptId }
      }
    ) {
      return [...state, ideaConceptId];
    },
    [combineActions(EDIT_IDEA_CONCEPT.SUCCESS, EDIT_IDEA_CONCEPT.ERROR)](
      state,
      {
        meta: { ideaConceptId }
      }
    ) {
      return dropById(ideaConceptId)(state);
    }
  },
  []
);

export default {
  reducer: pendingEditReducer,
  isEditPendingSelector: stateSelector => conceptId => state =>
    includes(stateSelector(state), conceptId)
};
