import LocalStorage from 'store2';
import {
  FETCH_FEEDCARDS,
  HIDE_FEED_PRIVACY_BANNER,
  HIDE_FEED_PRIVACY_BANNER_LOCALSTORAGE_KEY
} from '../../constants';
import { VOYAGER_API } from '../../api/constants';
import schemas, { entityKeys } from '../../challenge/schemas';
import { asyncActionType } from '../../utils';

export const FETCH_CARD_BY_ID = asyncActionType('FETCH_CARD_BY_ID');
export const DROP_CARD_BY_ID = 'DROP_CARD_BY_ID';

export const hidePrivacyBanner = () => {
  LocalStorage.set(HIDE_FEED_PRIVACY_BANNER_LOCALSTORAGE_KEY, true);

  return {
    type: HIDE_FEED_PRIVACY_BANNER
  };
};

export function fetchFeed(page = 0) {
  return {
    [VOYAGER_API]: {
      url: '/feedcards',
      page,
      type: FETCH_FEEDCARDS,
      schema: schemas.CHALLENGE_ARRAY,
      entityType: entityKeys.CHALLENGE
    }
  };
}

export const fetchCardById = challengeId => ({
  [VOYAGER_API]: {
    url: `/feedcards/challenges/${challengeId}`,
    type: FETCH_CARD_BY_ID,
    challengeId,
    schema: schemas.CHALLENGE,
    entityType: entityKeys.CHALLENGE,
    id: challengeId
  }
});

export const dropFeedCardById = challengeId => ({
  type: DROP_CARD_BY_ID,
  payload: challengeId,
  meta: { challengeId }
});
