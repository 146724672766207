import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

function Submenu({ children, collapsed = false }) {
  return (
    <ul
      className={classNames({
        submenu: true,
        'submenu--collapsed': collapsed
      })}>
      {children}
    </ul>
  );
}

Submenu.propTypes = {
  children: PropTypes.node,
  collapsed: PropTypes.bool
};

export default Submenu;
