import flow from 'lodash/flow';
import includes from 'lodash/includes';
import values from 'lodash/values';
import { getUrl, getContent, getType } from '../common/selectors/images';
import { ImageTypes } from '../constants';

const isValidImageObject = image => {
  const hasContent = getUrl(image) || getContent(image);
  const hasValidType = flow(
    getType,
    type => includes(values(ImageTypes), type)
  )(image);

  return hasValidType && hasContent;
};

export default isValidImageObject;
