import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Button from '@bit/be-novative.kit.button';
import ChallengeList from './ChallengeList';
import NonIdealState from '../../../common/components/NonIdealState';
import scss from './ChallengeFilter.module.scss';

ActiveSelections.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
  selections: PropTypes.arrayOf(PropTypes.string)
};
ActiveSelections.defaultProps = {
  selections: []
};
function ActiveSelections({
  onSelect,
  onSelectAll,
  onDeselectAll,
  selections,
  t,
  ...rest
}) {
  const [items] = useState(selections);
  const handleSelectAll = useCallback(() => {
    onSelectAll(items);
  }, [items, onSelectAll]);

  return (
    <main className={scss.List} {...rest}>
      {items.length ? (
        <>
          <Button variant="link" onClick={handleSelectAll}>
            {t('common/userAction/selectAll')}
          </Button>{' '}
          <Button variant="link" onClick={onDeselectAll}>
            {t('common/userAction/deselectAll')}
          </Button>
          <ChallengeList
            items={items}
            onSelect={onSelect}
            selections={selections}
          />
        </>
      ) : (
        <NonIdealState
          visual={NonIdealState.LIST_HUMMINGBIRD}
          title={t('challenge/filterForm/active/placeholder/title')}
          description={t('challenge/filterForm/active/placeholder/description')}
          style={{ padding: '1rem' }}
        />
      )}
    </main>
  );
}

export default translate()(ActiveSelections);
