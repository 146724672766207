import React from 'react';
import PropTypes from 'prop-types';
import { alignType } from '../../types';
import cn from 'classnames';
import './Heading.css';

function Heading(props) {
  const { level, theme, className, align, ...rest } = props;
  const Tag = `h${level}`;
  return (
    <Tag
      {...rest}
      className={cn('Heading', `Heading--${level}`, className, {
        [`Heading--${align}`]: !!align,
        [`Heading--${theme}`]: !!theme
      })}
    />
  );
}

Heading.propTypes = {
  align: alignType,
  level: PropTypes.number.isRequired,
  theme: PropTypes.oneOf(['brand', 'info'])
};

Heading.defaultProps = {
  align: null,
  level: 1,
  theme: null
};

export default Heading;
