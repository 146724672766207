import { openModal } from '../../common/actions';
import { BASIC_CONCEPT_EDITOR } from '../../challengeResults/constants';
import {
  ACTIVITIES_EDITOR,
  BENEFITS_EDITOR,
  CONCEPT_FIELD_EDITOR,
  CONCEPT_MAP_PREVIEW,
  CONCEPT_PREVIEW,
  CONCEPT_OWNER_FORM,
  COSTS_EDITOR,
  DESCRIBE_IDEA_CONCEPT_FORM,
  TEAM_EDITOR
} from '../constants';
import { CanvasBackgroundType } from '../../ideaConceptCanvas/constants';

export const openConceptEditor = (
  ideaConceptId,
  props = {
    customFormPayload: null,
    expanded: false,
    focusedField: null,
    formTitle: null,
    formSubmitLabel: null,
    onSuccess: undefined
  }
) => dispatch =>
  dispatch(
    openModal({
      modalType: DESCRIBE_IDEA_CONCEPT_FORM,
      modalProps: {
        customFormPayload: props.customFormPayload,
        expanded: props.expanded,
        focusedField: props.focusedField,
        formTitle: props.formTitle,
        formSubmitLabel: props.formSubmitLabel,
        ideaConceptId,
        itsATrap: true,
        onSuccess: props.onSuccess,
        size: 'large'
      }
    })
  );

export const openConceptPreview = ideaConceptId => dispatch =>
  dispatch(
    openModal({
      modalType: CONCEPT_PREVIEW,
      modalProps: {
        ideaConceptId
      }
    })
  );

export const openConceptOwnerForm = (
  ideaConceptId,
  props = {
    onSubmit: undefined
  }
) => dispatch =>
  dispatch(
    openModal({
      modalType: CONCEPT_OWNER_FORM,
      modalProps: {
        ideaConceptId,
        itsATrap: true,
        onSubmit: props.onSubmit
      }
    })
  );

export const openBasicConceptEditorModal = (
  ideaConceptId,
  props = {
    onSubmit: undefined
  }
) => dispatch =>
  dispatch(
    openModal({
      modalType: BASIC_CONCEPT_EDITOR,
      modalProps: {
        ideaConceptId,
        itsATrap: true,
        onSubmitSuccess: props.onSubmit,
        size: 'large'
      }
    })
  );

export const openConceptFieldEditorModal = (
  ideaConceptId,
  fieldName,
  props = {
    onSubmit: undefined,
    isRequired: false,
    title: ''
  }
) => dispatch =>
  dispatch(
    openModal({
      modalType: CONCEPT_FIELD_EDITOR,
      modalProps: {
        ideaConceptId,
        fieldName,
        itsATrap: true,
        onSubmitSuccess: props.onSubmit,
        isRequired: props.isRequired,
        size: 'large',
        title: props.title
      }
    })
  );

export const openActivitiesEditorModal = (
  ideaConceptId,
  props = {
    onSubmit: undefined,
    isRequired: false
  }
) =>
  openModal({
    modalType: ACTIVITIES_EDITOR,
    modalProps: {
      ideaConceptId,
      itsATrap: true,
      onSubmitSuccess: props.onSubmit,
      isRequired: props.isRequired,
      size: 'large'
    }
  });

export const openBenefitsEditorModal = (
  ideaConceptId,
  props = {
    onSubmit: undefined,
    isRequired: false
  }
) =>
  openModal({
    modalType: BENEFITS_EDITOR,
    modalProps: {
      ideaConceptId,
      isRequired: props.isRequired,
      itsATrap: true,
      onSubmitSuccess: props.onSubmit,
      size: 'large'
    }
  });

export const openCostsEditorModal = (
  ideaConceptId,
  props = {
    onSubmit: undefined,
    isRequired: false
  }
) =>
  openModal({
    modalType: COSTS_EDITOR,
    modalProps: {
      ideaConceptId,
      isRequired: props.isRequired,
      itsATrap: true,
      onSubmitSuccess: props.onSubmit,
      size: 'large'
    }
  });

export const openTeamEditorModal = (
  ideaConceptId,
  props = {
    onSubmit: undefined
  }
) =>
  openModal({
    modalType: TEAM_EDITOR,
    modalProps: {
      ideaConceptId,
      itsATrap: true,
      onSubmitSuccess: props.onSubmit,
      size: 'large'
    }
  });

export const openConceptMapModal = (ideaConceptId, ideas, title) =>
  openModal({
    modalType: CONCEPT_MAP_PREVIEW,
    modalProps: {
      backdropType: 'light',
      canvasType: CanvasBackgroundType.ConceptMap,
      ideaConceptId,
      ideas,
      title,
      size: 'full'
    }
  });
