import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Text.css';

function Text({ variant, className, ...props }) {
  return (
    <span
      {...props}
      className={classNames('Text', className, `Text--${variant}`)}
    />
  );
}

Text.propTypes = {
  variant: PropTypes.oneOf(['success']).isRequired
};

export default Text;
