import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import Button from '@bit/be-novative.kit.button';

class SubmenuItem extends Component {
  static propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.string
  };

  static defaultProps = {
    onClick: () => {}
  };

  render() {
    const { to, children, active, onClick } = this.props;
    return (
      <li className="submenu__item">
        <Button
          to={to}
          onClick={onClick}
          variant="link"
          className={classNames({
            submenu__link: true,
            'submenu__link--active': active
          })}
          data-test="btn">
          {children}
        </Button>
      </li>
    );
  }
}

export default SubmenuItem;
