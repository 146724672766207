import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ImageGallery from './ImageGallery';
import client from '../../../api/client';
import useImageSearch from './useImageFetch';

PresetImagePicker.propTypes = {
  onSelect: PropTypes.func.isRequired
};
function PresetImagePicker({ onSelect, t }) {
  const resource = useMemo(
    () => ({
      fetch: () => client.get('/gallery/preset').then(res => res.data)
    }),
    []
  );
  const presetApi = useImageSearch(resource);
  const handleNextPage = useCallback(() => {
    presetApi.fetch(presetApi.pageIx);
  }, [presetApi]);

  const [didInitialFetch, setDidInitialFetch] = useState();
  useEffect(() => {
    if (presetApi.pageIx === 0 && !didInitialFetch) {
      presetApi.fetch(0);
      setDidInitialFetch(true);
    }
  }, [didInitialFetch, presetApi]);

  return (
    <section>
      <header>
        <h2 className={'h-text-center'}>
          {t('common/imagePicker/preset/title')}
        </h2>
      </header>
      <ImageGallery
        images={presetApi.images}
        hasError={presetApi.hasError}
        isLoading={presetApi.isLoading}
        onImageSelect={onSelect}
        onNextPage={handleNextPage}
      />
    </section>
  );
}

export default translate()(PresetImagePicker);
