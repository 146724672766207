import get from 'lodash/get';

// eslint-disable-rule no-useless-escape
const ieRegex = [
  /Trident\/7\.0.*rv:([0-9.]+).*\).*Gecko$/,
  /MSIE\s([0-9.]+);.*Trident\/[4-7].0/,
  /MSIE\s(7\.0)/
];

export const isIE = () => {
  const browser = get(window, 'navigator.userAgent', '');
  return ieRegex.some(regex => regex.test(browser));
};

export default {
  isIE
};
