import * as Sentry from '@sentry/browser';
import { ApiRegion } from './constants';
import FeatureFlag from '../featureFlags/constants';
import { getFeatureFlagAsync } from '../featureFlags/client';
import addBreadcrumb, { Severity } from '../telemetry/addBreadcrumb';
import getApiUrl from './utils/getApiUrl';
import client from './client';

/**
 * @typedef {Object} APIRegionConfig
 * @property {ApiRegion} region - The configured API region
 */

/**
 * @typedef {(APIRegionConfig|Error)} ApiBootstrapResult
 */

/**
 * Required to launch the app. Fetches feature flags for the API base URL, then configures the API client singleton (side effect).
 * @summary It asynchronously fetches the API base URL (fallback to EU region, see ADR #9), then initialises the API client with. It also returns the region info for further configuration.
 * @return {Promise<ApiBootstrapResult>} API region config or the thrown error
 * @public
 */

async function bootstrap() {
  // @see ADR #9 in the docs for reason of fallback choice
  try {
    const region = await getFeatureFlagAsync(
      FeatureFlag.apiRegion,
      ApiRegion.EU
    );

    if (region) {
      addBreadcrumb('API region set', {
        level: Severity.Verbose,
        category: 'bootstrap',
        data: { region }
      });
      client.defaults.baseURL = getApiUrl(region);
      return { region };
    } else {
      throw new Error('API region config is empty!');
    }
  } catch (error) {
    addBreadcrumb(error, {
      level: Severity.Critical,
      category: 'bootstrap'
    });
    Sentry.captureException();
    throw error;
  }
}

export default bootstrap;
