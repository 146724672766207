import { combineReducers } from 'redux';
import filterActionByCanvasId from '../../dndCanvas/utils/filterActionByCanvasId';
import status from '../stateSlices/status';
import { S_ID } from '../constants';
import groups from './groups';
import ideas from './ideas';
import images from './images';
import movement from './movement';

export default filterActionByCanvasId(
  S_ID,
  combineReducers({
    groups,
    ideas,
    images,
    movement,
    status
  })
);
