import React from 'react';
import ConnectedAvatar from '../../../../common/containers/ConnectedAvatar';
import { getOwner } from '../../../../common/selectors/meta';
import { Size } from '../../../../constants';
import { AlignedCellContent, TitleCellLabel } from '../ConceptTable.styled';

const owner = {
  header: t => {
    return (
      <AlignedCellContent justifyContent="flex-start">
        <TitleCellLabel>
          {t('ideaConcepts/listPage/tableHeaders/owner')}
        </TitleCellLabel>
      </AlignedCellContent>
    );
  },
  main: (t, concept) => (
    <AlignedCellContent alignItems="flex-start">
      <ConnectedAvatar
        userId={getOwner(concept)}
        size={Size.sm}
        tooltipPosition="right"
      />
    </AlignedCellContent>
  )
};

export default owner;
