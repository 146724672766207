import { useSelector } from 'react-redux';
import useFeatureFlag from '../../common/hooks/useFeatureFlag';
import { isAdminSelector } from '../../profile/state/profile';
import FeatureFlag from '../../featureFlags/constants';

export default function useCanReviewConcepts() {
  const isAdmin = useSelector(isAdminSelector);
  const canAllUsersReview = useFeatureFlag(
    FeatureFlag.canAllUsersReviewConcepts
  );
  return isAdmin || canAllUsersReview;
}
