import React, { useCallback } from 'react';
import { translate } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeLanguage } from '../../../common/actions';
import formControl from '@bit/be-novative.kit.form-control';
import Grid from '../../../common/components/Grid';
import Select from '@bit/be-novative.kit.select';

const SelectFormControl = formControl(Select);

function InterfaceLanguageForm({ currentLanguage, languages, t }) {
  const dispatch = useDispatch();
  const handleChange = useCallback(
    function handleChange(event) {
      dispatch(changeLanguage(event.target.value));
    },
    [dispatch]
  );

  return (
    <>
      <Grid.Row>
        <Grid.Col xsGutterX={2} md={6}>
          <SelectFormControl
            name="language"
            label={t('profile/Language of the Interface')}
            hint={t('profile/You can set here the language of the interface.')}
            input={{
              defaultValue: currentLanguage,
              onChange: handleChange
            }}>
            {languages.map(lang => (
              <option key={lang} value={lang}>
                {t(`language/${lang.toUpperCase()}`)}
              </option>
            ))}
          </SelectFormControl>
        </Grid.Col>
      </Grid.Row>
    </>
  );
}

export default translate()(InterfaceLanguageForm);
