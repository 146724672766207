import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { getDisplayName } from '../../../utils';
import './withResponsive.css';

const breakpoints = ['sm', 'md', 'lg', 'xl'];

/**
 * Simple utility HOC to hide or show the wrapped component based on mobile first media queries.
 * mediaHiddenDown="md" - hidden bellow medium screen size
 * mediaHiddenUp="md" - visible bellow medium screen size
 * 
 * Does not create wrapper DOM element, so the wrapped component must be capable of receiving
 * className prop.
 */
export default function(WrappedComponent) {
  function Responsive({
    mediaHiddenDown,
    mediaHiddenUp,
    className: originalClassName,
    ...rest
  }) {
    const className = classNames(originalClassName, {
      [`hidden-down-${mediaHiddenDown}`]: !!mediaHiddenDown,
      [`hidden-up-${mediaHiddenUp}`]: !!mediaHiddenUp
    });

    return <WrappedComponent {...rest} className={className} />;
  }

  Responsive.displayName = `Responsive(${getDisplayName(WrappedComponent)})`;

  Responsive.propTypes = {
    mediaHiddenDown: PropTypes.oneOf(breakpoints),
    mediaHiddenUp: PropTypes.oneOf(breakpoints)
  };

  return Responsive;
}
