import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '@bit/be-novative.kit.button';
import Icon from '../Icon';
import { windowOpen } from '../../../utils';
import * as links from './shareLinks';

const supportedNetworks = Object.keys(links);

class ShareButton extends Component {
  handleClick = e => {
    const {
      disabled,
      windowWidth,
      windowHeight,
      network,
      onClick
    } = this.props;

    if (!disabled) {
      e.preventDefault();

      const windowOptions = {
        height: windowHeight,
        width: windowWidth
      };

      onClick(network);
      windowOpen(this.link(), windowOptions);
    }
  };

  link() {
    const { url, opts = {}, network } = this.props;
    return links[network](url, opts);
  }

  render() {
    const { children, network, disabled, opts, url, ...rest } = this.props;

    return (
      <Button
        {...rest}
        onClick={this.handleClick}
        variant={network}
        disabled={disabled}>
        <Icon type={network} />
        <span>{children}</span>
      </Button>
    );
  }
}

ShareButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  network: PropTypes.oneOf(supportedNetworks),
  opts: PropTypes.object,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

ShareButton.networks = supportedNetworks;

export default ShareButton;
