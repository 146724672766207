import React, { Component } from 'react';
import flow from 'lodash/flow';
import isNumber from 'lodash/isNumber';
import memoize from 'lodash/memoize';
import round from 'lodash/round';
import truncate from 'lodash/fp/truncate';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Size, SortDirection } from '../../../constants';
import {
  conceptListColumns,
  EMPTY_NUMBER,
  IdeaConceptImplementationStatus as ConceptPhase
} from '../../constants';
import { IdeaConceptEvaluationFormConfig as ConceptEvalConfig } from '../../config';
import { conceptEvalPage, conceptSubpage } from '../../paths';
import stripHTML from '@bit/be-novative.utils.strip-html';
import { fetchChallenge } from '../../../common/actions/challenges';
import { openConceptPreview } from '../../actions';
import { getCreateTime } from '../../../common/selectors/meta';
import {
  getTitle,
  getIdeaConceptId,
  getOwner,
  getCompositeEvalScore,
  getIdeaText,
  getChallengeId,
  getEvaluatorCount,
  isConceptPrivate
} from '../../selectors/ideaConcepts';
import ConnectedAvatar from '../../../common/containers/ConnectedAvatar';
import Button from '@bit/be-novative.kit.button';
import DateTime from '../../../common/components/DateTime';
import Table from '../../../common/components/Table';
import Column from '../../../common/components/Table/Column';
import ConceptTeamAvatars from '../../components/ConceptTeamAvatars';
import StageSelector from '../../components/StageSelector';
import './ConceptTable.css';
import { Icon } from '../../../common/components/Icon/Icon';

const BASE_CLASS = 'ConceptTable';
const EVAL_MAX_SCORE = ConceptEvalConfig.CRITERION_MAX;
const TITLE_MAX_LENGTH = 40;
const TITLE_TOOLTIP_MAX_LENGTH = 200;
const TEAM_MAX_COUNT = 4;

export class ConceptTable extends Component {
  static propTypes = {
    activePhase: PropTypes.oneOf(values(ConceptPhase)).isRequired,
    canReviewConcepts: PropTypes.bool,
    concepts: PropTypes.arrayOf(PropTypes.object),
    sortDisabled: PropTypes.bool,
    onSortUpdate: PropTypes.func.isRequired,
    openConceptPreview: PropTypes.func.isRequired,
    sortBy: PropTypes.oneOf(values(conceptListColumns)),
    sortDirection: PropTypes.oneOf(values(SortDirection))
  };

  static defaultProps = {
    concepts: []
  };

  getConceptRowData = ({ index }) => this.props.concepts[index];

  handlePreviewClick = ideaConceptId => () =>
    this.props.openConceptPreview(ideaConceptId);

  render() {
    const {
      concepts,
      sortDisabled,
      onSortUpdate,
      sortDirection,
      sortBy,
      t
    } = this.props;

    return (
      <Table
        rowCount={concepts.length}
        rowGetter={this.getConceptRowData}
        sort={onSortUpdate}
        sortBy={sortBy}
        sortDirection={sortDirection}
        bodyColumnClassName={`${BASE_CLASS}__BodyCols`}
        headerColumnClassName={`${BASE_CLASS}__HeaderCols`}>
        <Column
          label={t('ideaConcepts/listPage/tableHeaders/title')}
          dataKey={conceptListColumns.TITLE}
          width={300}
          flexGrow={0}
          flexShrink={0}
          disableSort={sortDisabled}
          cellRenderer={this.renderTitle}
          headerClassName={`${BASE_CLASS}__Header`}
        />

        <Column
          label={t('ideaConcepts/listPage/tableHeaders/owner')}
          dataKey={conceptListColumns.OWNER}
          width={60}
          flexShrink={0}
          align="center"
          disableSort={sortDisabled}
          cellRenderer={this.renderOwner}
          headerClassName={`${BASE_CLASS}__Header`}
        />

        <Column
          label={t('ideaConcepts/listPage/tableHeaders/team')}
          dataKey={conceptListColumns.TEAM}
          width={140}
          flexGrow={1}
          flexShrink={0}
          align="center"
          disableSort={sortDisabled}
          cellRenderer={this.renderTeam}
          headerClassName={`${BASE_CLASS}__Header`}
        />

        <Column
          label={t('ideaConcepts/listPage/tableHeaders/createTime')}
          dataKey={conceptListColumns.CREATE_TIME}
          width={150}
          flexShrink={0}
          align="center"
          disableSort={sortDisabled}
          cellRenderer={this.renderStartDate}
          headerClassName={`${BASE_CLASS}__Header`}
        />

        <Column
          label={t('ideaConcepts/listPage/tableHeaders/evalScore')}
          dataKey={conceptListColumns.EVAL_SCORE}
          width={90}
          flexShrink={0}
          align="center"
          disableSort={sortDisabled}
          cellRenderer={this.renderScore}
          headerClassName={`${BASE_CLASS}__Header`}
        />

        <Column
          label={t('ideaConcepts/listPage/tableHeaders/stage')}
          dataKey={conceptListColumns.STAGE}
          width={200}
          flexShrink={0}
          align="center"
          disableSort={sortDisabled}
          cellRenderer={this.renderStatus}
          headerClassName={`${BASE_CLASS}__Header`}
          className={`${BASE_CLASS}__StageCol`}
        />

        <Column
          dataKey="action"
          width={130}
          flexShrink={0}
          align="right"
          disableSort
          cellRenderer={this.renderAction}
          headerClassName={`${BASE_CLASS}__Header`}
        />
      </Table>
    );
  }

  renderTitleLabel = memoize(
    flow(
      stripHTML,
      truncate({ length: TITLE_TOOLTIP_MAX_LENGTH })
    )
  );

  renderTitle = ({ rowData }) => {
    const link = conceptSubpage(getIdeaConceptId(rowData));
    const title = getTitle(rowData);
    const tooltip = this.renderTitleLabel(getIdeaText(rowData));
    const isPrivate = isConceptPrivate(rowData);

    return (
      <>
        {isPrivate && (
          <Icon
            type={'lock'}
            title={this.props.t(`ideaConcept/prop/visibility/private/hint`)}
            style={{ marginRight: 5 }}
          />
        )}
        <Button variant="link" to={link} title={tooltip}>
          <strong>{truncate({ length: TITLE_MAX_LENGTH })(title)}</strong>
        </Button>
      </>
    );
  };

  renderOwner = ({ rowData }) => (
    <ConnectedAvatar
      userId={getOwner(rowData)}
      size={Size.sm}
      tooltipPosition="right"
    />
  );

  renderTeam = ({ rowData }) => (
    <ConceptTeamAvatars
      ideaConceptId={getIdeaConceptId(rowData)}
      maxDisplay={TEAM_MAX_COUNT}
      avatarSize={Size.xs}
    />
  );

  renderStartDate = ({ rowData }) => (
    <DateTime>{getCreateTime(rowData)}</DateTime>
  );

  renderScore = ({ rowData }) => {
    const { t } = this.props;
    const score = getCompositeEvalScore(rowData);
    const evalCount = getEvaluatorCount(rowData);
    return isNumber(score) && 0 < score ? (
      <>
        <span>
          {round(score, 1)}/{EVAL_MAX_SCORE}
        </span>
        {0 < evalCount && (
          <>
            <br />
            <small>
              {evalCount}{' '}
              {t('ideaConcept/evaluation/collection', { count: evalCount })}
            </small>
          </>
        )}
      </>
    ) : (
      EMPTY_NUMBER
    );
  };

  renderStatus = ({ rowData }) => (
    <StageSelector ideaConceptId={getIdeaConceptId(rowData)} />
  );

  preloadChallenge = challengeId => {
    try {
      this.props.preloadChallenge(challengeId);
    } catch (error) {
      // No need to handle
      console.error(error);
    }
  };

  renderAction = ({ rowData }) => {
    const { activePhase, canReviewConcepts, t } = this.props;
    const parentChallengeId = getChallengeId(rowData);
    const shouldRenderReview =
      canReviewConcepts &&
      [
        ConceptPhase.IMPLEMENTING,
        ConceptPhase.VALIDATION,
        ConceptPhase.DONE
      ].includes(activePhase);
    const props = shouldRenderReview
      ? {
          children: t('ideaConcept/evaluation/form/submitCTA', {
            context: 'short'
          }),
          to: conceptEvalPage('conceptId', getIdeaConceptId(rowData)),
          onMouseEnter: () => this.preloadChallenge(parentChallengeId)
        }
      : {
          children: t('common/navigation/details', { context: 'short' }),
          onClick: this.handlePreviewClick(getIdeaConceptId(rowData))
        };
    return <Button variant="primary-gray" {...props} />;
  };
}

const mapDispatchToProps = dispatch => ({
  openConceptPreview: ideaConceptId =>
    dispatch(openConceptPreview(ideaConceptId)),
  preloadChallenge: challengeId => dispatch(fetchChallenge(challengeId))
});

export default compose(
  translate(),
  connect(
    null,
    mapDispatchToProps
  )
)(ConceptTable);
