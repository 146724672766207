import { useCallback } from 'react';
import invariant from 'invariant';
import { useDispatch } from 'react-redux';
import { REDUCER_ID } from '../../reducers/conceptList';
import { removeFilter } from '../../../common/actions/meta';
import useListFetcher from './useListFetcher';

const unsetFilter = removeFilter(REDUCER_ID);

export default function useUnsetFilter() {
  const dispatch = useDispatch();
  const refreshList = useListFetcher();

  const filterUnsetter = useCallback(
    (filterKey, filterValue, options = { autoRefetch: true }) => {
      invariant(filterKey, '`filterKey` not found');

      dispatch(unsetFilter(filterKey, filterValue));

      if (options.autoRefetch) {
        refreshList({ flushList: true });
      }
    },
    [dispatch, refreshList]
  );

  return filterUnsetter;
}
