import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './RightSlideTransition.css';

const ANIM_MS = 300;

RightSlideTransition.propTypes = {
  speed: PropTypes.number
};
RightSlideTransition.defaultProps = {
  speed: ANIM_MS
};
export default function RightSlideTransition({ speed, ...props }) {
  return (
    <CSSTransition
      {...props}
      classNames="slide-transition"
      timeout={{ enter: speed, exit: speed }}
      style={{ transition: `transform ${speed}ms ease-out` }}
    />
  );
}
