import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import GridPropTypes from './PropTypes';
import './Container.css';

const widths = ['sm', 'md', 'lg', 'xl'];

const Container = props => {
  const { className, fluid, children, tag: Tag, ...rest } = props;

  const classes = classNames(
    className,
    fluid ? 'Container--fluid' : 'Container',
    ...widths.filter(width => rest[width]).map(width => `Container@${width}`)
  );

  return <Tag className={classes}>{children}</Tag>;
};

Container.propTypes = {
  tag: GridPropTypes.tag,
  fluid: PropTypes.bool,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool
};

Container.defaultProps = {
  tag: 'div',
  fluid: false
};

export default Container;
