import React from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon, { ICON_TYPES } from '../Icon';
import scss from './BulletedList.module.scss';

BulletedList.propTypes = {
  bullet: PropTypes.oneOf(ICON_TYPES),
  iconClass: PropTypes.string,
  items: PropTypes.array
};
export default function BulletedList({
  bullet = 'hummingbird',
  className,
  iconClass,
  items = []
}) {
  return (
    <ul className={className}>
      {map(items, (item, index) => (
        <li key={index} className={scss.Item}>
          <Icon type={bullet} className={cn(scss.Icon, iconClass)} /> {item}
        </li>
      ))}
    </ul>
  );
}
