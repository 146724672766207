import { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

export default function useDebounce(value, interval) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (!isEqual(value, currentValue)) {
        setCurrentValue(value);
      }
    }, interval);

    return function clearHandler() {
      clearTimeout(debounce);
    };
  }, [value, currentValue, interval]);

  return currentValue;
}
