import React from 'react';
import { getCreateTime } from '../../../../common/selectors/meta';
import { AlignedCellContent, SingleLineHeight } from '../ConceptTable.styled';

const startTime = {
  main: (t, concept) => (
    <AlignedCellContent alignItems="flex-start" justifyContent="flex-start">
      <SingleLineHeight>
        {new Date(getCreateTime(concept)).toLocaleDateString()}
      </SingleLineHeight>
    </AlignedCellContent>
  )
};

export default startTime;
