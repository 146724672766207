/**
 * CSS units for position and size calculations, and for defining CSS rules as JS template literals
 * @enum {string}
 * @readonly
 */
export const SIZE_UNITS = {
  /** `"px"` */
  PX: 'px',
  /** `"%"` */
  PERCENT: '%'
};

const NO_NETWORK = 'NO_NETWORK';
const SOCKET_FAILED = 'SOCKET_FAILED';
const SOCKET_RETRY = 'SOCKET_RETRY';

export const CanvasIdProps = {
  Idea: 'ideaId',
  Image: 'imageId'
};

/**
 * Network- and socket-related error codes
 * @enum {string}
 * @readonly
 */
export const NETWORK_ERROR = {
  /** Browser is not connected */
  NO_NETWORK,
  /** Socket connection wasn't established or has failed */
  SOCKET_FAILED,
  /** Socket is trying to reconnect */
  SOCKET_RETRY
};

export const NETWORK_ERROR_CALLOUT_TYPES = {
  [NO_NETWORK]: { type: 'danger', label: 'common/error/network' },
  [SOCKET_FAILED]: {
    type: 'danger',
    label: 'dndCanvas/socketError/status'
  },
  [SOCKET_RETRY]: {
    type: 'warning',
    label: 'dndCanvas/socketRetry/status'
  }
};
