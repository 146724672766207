import clamp from 'lodash/clamp';
import findKey from 'lodash/findKey';
import isEqualFp from 'lodash/fp/isEqual';
import { asyncActionType } from './utils';

export const ADD_SUGGESTION_TO_IDEA = asyncActionType('ADD_SUGGESTION_TO_IDEA');
export const APP_INIT = 'APP_INIT';
export const ALERT_CLEAR = 'ALERT_CLEAR';
export const ALERT_DISMISS = 'ALERT_DISMISS';
export const ALERT_SEND = 'ALERT_SEND';
export const CHALLENGE_CREATE = asyncActionType('CHALLENGE_CREATE');
export const CHALLENGE_DELETE = asyncActionType('CHALLENGE_DELETE');
export const CHALLENGE_PERMISSIONS = asyncActionType('CHALLENGE_PERMISSIONS');
export const CHALLENGE_REOPEN = asyncActionType('CHALLENGE_REOPEN');
export const CLIPBOARD_COPIED = 'CLIPBOARD_COPIED';
export const COMPLETE_INVITATION = asyncActionType('COMPLETE_INVITATION');
export const COPY_TO_CLIPBOARD_BUTTON_CLICK = 'COPY_TO_CLIPBOARD_BUTTON_CLICK';
export const DELETE_CHALLENGE = asyncActionType('DELETE_CHALLENGE');
export const DELETE_IDEA = asyncActionType('DELETE_IDEA');
export const EDIT_IDEA_CONCEPT = asyncActionType('EDIT_IDEA_CONCEPT');
export const EMAIL_VALIDATE = asyncActionType('EMAIL_VALIDATE');
export const EXPORT_IDEAS = asyncActionType('EXPORT_IDEAS');
export const FAILURE_LANGUAGE_CHANGE = 'FAILURE_LANGUAGE_CHANGE';
export const FETCH_CHALLENGE = asyncActionType('FETCH_CHALLENGE');
export const FETCH_IDEA_CONCEPT_BY_ID = asyncActionType(
  'FETCH_IDEA_CONCEPT_BY_ID'
);
export const UPDATE_IDEA_CONCEPT_FILTER = 'UPDATE_IDEA_CONCEPT_FILTER';
export const FETCH_UPDATE_PAYMENT_METHOD_URL = asyncActionType(
  'FETCH_UPDATE_PAYMENT_METHOD_URL'
);
export const ACKNOWLEDGE_UPDATE_PAYMENT = asyncActionType(
  'ACKNOWLEDGE_UPDATE_PAYMENT'
);
export const FETCH_FEEDCARDS = asyncActionType('FETCH_FEEDCARDS');
export const FETCH_HASHTAGS = asyncActionType('FETCH_HASHTAGS');
export const FETCH_NOTIFICATIONS = asyncActionType('FETCH_NOTIFICATIONS');
export const FETCH_SCHEMA_DATA = asyncActionType('FETCH_SCHEMA_DATA');
export const HIDE_FEED_PRIVACY_BANNER = 'HIDE_FEED_PRIVACY_BANNER';
export const IDEA_LIST_FILTER_CHANGED = 'IDEA_LIST_FILTER_CHANGED';
export const IDEA_LIST_PAGINATED = 'IDEA_LIST_PAGINATED';
export const JOIN_CHALLENGE = asyncActionType('JOIN_CHALLENGE');
export const LOVE_CHALLENGE = asyncActionType('LOVE_CHALLENGE');
export const LOVE_IDEA = asyncActionType('LOVE_IDEA');
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_OPEN = 'MODAL_OPEN';
export const NAVIGATION_CONFIRM = 'NAVIGATION_CONFIRM';
export const NAVIGATION_CONFIRM_CLEAR = 'NAVIGATION_CONFIRM_CLEAR';
export const NOTIFICATION_READ = 'NOTIFICATION_READ';
export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED';
export const NOTIFICATIONS_INIT = 'NOTIFICATIONS_INIT';
export const NOTIFICATIONS_MARK = asyncActionType('NOTIFICATIONS_MARK');
export const ONBOARDING_ENTER = 'ONBOARDING_ENTER';
export const ONBOARDING_LEAVE = 'ONBOARDING_LEAVE';
export const PASSWORD_RESET = asyncActionType('PASSWORD_RESET');
export const PHASE_CHANGED = 'PHASE_CHANGED';
export const PHASE_FORWARD_ENABLED = 'PHASE_FORWARD_ENABLED';
export const REMOVE_IDEA_CONCEPT_FILTERS = 'REMOVE_IDEA_CONCEPT_FILTERS';
export const RESULTS_BRAINSTORM_BUTTON_CLICK =
  'RESULTS_BRAINSTORM_BUTTON_CLICK';
export const RESULTS_EVALUATE_BUTTON_CLICK = 'RESULTS_EVALUATE_BUTTON_CLICK';
export const RESULTS_EVALUATE_CONCEPT_BUTTON_CLICK =
  'RESULTS_EVALUATE_CONCEPT_BUTTON_CLICK';
export const REVEAL_IDEACONCEPT_IDENTITY = asyncActionType(
  'REVEAL_IDEACONCEPT_IDENTITY'
);
export const REVEAL_IDENTITY = asyncActionType('REVEAL_IDENTITY');
export const ROUTE_CHANGE = 'ROUTE_CHANGE';
export const SUBDOMAIN_VALIDATE = asyncActionType('SUBDOMAIN_VALIDATE');
export const SET_USERAGENT_LANGUAGE = 'SET_USERAGENT_LANGUAGE';
export const SHARE_BUTTON_INSIDE_THE_SHARE_MODAL_CLICK =
  'SHARE_BUTTON_INSIDE_THE_SHARE_MODAL_CLICKED';
export const SIDENAV_CLOSE = 'SIDENAV_CLOSE';
export const SIDENAV_OPEN = 'SIDENAV_OPEN';
export const INTERNET_STATUS_CHANGE = 'INTERNET_STATUS_CHANGE';
export const SUCCESS_LANGUAGE_CHANGE = 'SUCCESS_LANGUAGE_CHANGE';
export const UPDATE_ONBOARDING = asyncActionType('UPDATE_ONBOARDING');

// dedicated metrics action types
export const INLINE_HELP_VIEWED = 'INLINE_HELP_VIEWED';

/**
 * @deprecated
 * @see src/api/constants
 */
export const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const InternetStatus = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  WEBSOCKET_DISCONNECTED: 'WEBSOCKET_DISCONNECTED',
  SERVER_UNAVAILABLE: 'SERVER_UNAVAILABLE'
};

export const CommonModalTypes = {
  CONFIRM_NAVIGATE: 'CONFIRM_NAVIGATE',
  IMAGE_PICKER: 'IMAGE_PICKER',
  SHARE_URL_MODAL: 'SHARE_URL_MODAL'
};

/**
 * Constants describing the type of the selected image
 * @enum {string}
 * @readonly
 */
export const ImageTypes = {
  /** Image selected from a Google search */
  GOOGLE: 'external',
  /** Image chosen from the preset gallery */
  PRESET: 'preset',
  /** Image uploaded by the user */
  UPLOADED: 'uploaded'
};

/**
 * Meta status strings for async requests
 * @enum {Object} AsyncStatus
 */
export const AsyncStatus = {
  /** The request has never been called */
  Idle: 'Idle',
  /** The request is loading */
  Loading: 'Loading',
  /** The request was successful */
  Succeeded: 'Succeeded',
  /** The request returned with an error */
  Failed: 'Failed'
};

// Config

export const USER_AUTOSELECT_TAG_DISPLAY_PROP = 'displayName';

export const IdeaConceptsFilterBy = {
  MY_IDEA_CONCEPTS: 'myics',
  WROTE_SUGGESTION: 'wrotesuggestion'
};

export const ChallengeIdeasFilterBy = {
  LOVED: 'loved',
  OWNED: 'owned'
};

export const FeedModalTypes = {
  SHARE_CARD: 'SHARE_CARD',
  SHOW_CARD: 'SHOW_CARD'
};

export const PasswordPolicy = {
  minLength: 8,
  minNumber: 1,
  minLowercase: 1,
  minUppercase: 1,
  minSpecChars: 0
};

export const OnboardingTips = {
  WELCOME: 0,
  CHALLENGE_CARD: 1,
  CHALLENGE_CREATE: 2,
  START_IDEATION: 3,
  ATTENDANTS: 4,
  HUMMINGBOT: 5,
  CHALLENGE_INFO: 6,
  IDEATION_IDEA: 7,
  LOVE: 8,
  ADD_PICTURE: 9,
  RANDOM_WORD: 10,
  GO_TO_EVALUATION: 11,
  EVALUATION_IDEA: 12,
  EVALUATION_PROGRESS: 13,
  OVERALL_EVALUATION: 14,
  TOP_IDEATORS: 15,
  IDEAS_SORT_CRITERIA: 16,
  UNCOVER: 17,
  BACK_TO_IDEATION: 18
};

export const userConsentKeys = {
  ACCOUNT_MANAGEMENT_CONSENT: 'accountManagementConsent',
  COOKIES_AND_LOGS_ACCEPTED: 'cookiesAndLogsAccepted',
  PERSONAL_DATA_MANAGEMENT_ACCEPTED: 'personalDataManagementAccepted',
  EMAIL_NOTIFICATIONS_CONSENT: 'emailNotificationsConsent',
  CHALLENGE_INVITATIONS_ACCEPTED: 'challengeInvitationsAccepted',
  MARKETING_EMAILS_CONSENT: 'marketingEmailsConsent',
  MARKETING_EMAILS_ACCEPTED: 'marketingEmailsAccepted',
  NEWSLETTER_ACCEPTED: 'newsletterAccepted',
  TERMS_AND_PRIVACY_ACCEPTED: 'termsAndPrivacyAccepted'
};

export const userRoles = {
  USER: 'USER',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN'
};

export const MANDATORY_CONSENT_KEYS = [
  [
    userConsentKeys.ACCOUNT_MANAGEMENT_CONSENT,
    userConsentKeys.PERSONAL_DATA_MANAGEMENT_ACCEPTED
  ],
  [
    userConsentKeys.ACCOUNT_MANAGEMENT_CONSENT,
    userConsentKeys.COOKIES_AND_LOGS_ACCEPTED
  ],
  [userConsentKeys.TERMS_AND_PRIVACY_ACCEPTED]
];

export const HIDE_FEED_PRIVACY_BANNER_LOCALSTORAGE_KEY = 'hidePrivacyBanner';

export const CONSENTCOOKIE_ID = 'cookieconsent_status';
export const CONSENTCOOKIE_STATES = {
  ALLOW: 'allow',
  DENY: 'deny',
  DISMISS: 'dismiss'
};

export const CUSTOMER_SUPPORT_EMAIL = 'support@be-novative.com';

export const DIAGNOSTICS_PATH = 'diagnostics';

export const SUGGESTION_PAGE_GET_IDEAS_LIMIT = 6;
export const RESULTS_PAGE_TRENDING_CONCEPT_GET_LIMIT = 3;
export const RESULTS_PAGE_OTHER_CONCEPT_GET_LIMIT = 6;

export const SUPPORTED_UI_LANGUAGES = ['en', 'de', 'hu'];
export const SUPPORTED_UI_LANGUAGES_NATIVE = {
  en: 'English',
  de: 'Deutsch',
  hu: 'Magyar'
};
export const PREFERRED_LANGUAGE_LOCALSTORAGE_KEY = 'language';

export const entities = {
  CATEGORY: 'categories',
  CHALLENGE: 'challenges',
  USER: 'users'
};

export const commonIdProps = {
  CATEGORY_ID: 'categoryId',
  IDEA_ID: 'ideaId',
  IMAGE_ID: 'imageId',
  USER_ID: 'userId'
};

export const BREAKPOINTS = {
  XS: 480,
  SM: 768,
  MD: 992,
  LG: 1200
};

/**
 * Semantic size identifiers for use throughout the app
 * @enum {string} Size
 */

export const Size = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  /** @property {getRelativeSize} relative */
  relative: getRelativeSize // JSDoc doesn't work without explicit prop definition
};

Object.defineProperty(Size, 'relative', {
  enumerable: false,
  writable: false,
  configurable: false,
  value: getRelativeSize
});

/**
 * Returns the {@see Size} enum adjacent to the provided size. E.g. `relative(Size.md, -1)` returns `Size.sm`.
 * @param {Size} currentSize - The original size that's the basis
 * @param {number} [step] - The positive/negative number of steps to take from `currentSize`
 * @return {Size} The relative size value
 */

function getRelativeSize(currentSize, step = 0) {
  const keys = Object.keys(Size);
  const sizeKey = findKey(Size, isEqualFp(currentSize));
  const sizeIx = keys.findIndex(isEqualFp(sizeKey));
  const targetKey = keys[clamp(sizeIx + step, 0, keys.length - 1)];
  return Size[targetKey];
}
