import { schema } from 'normalizr';
import { CHALLENGE_FORM_FIELDS as FIELDS } from './constants';
import commonSchemas from '../schemas';

export const CHALLENGE_ID = 'challengeId';

export const entityKeys = {
  CHALLENGE: 'challenges'
};

const challengeSchema = new schema.Entity(
  entityKeys.CHALLENGE,
  {
    category: commonSchemas.CATEGORY,
    [FIELDS.CREATOR]: commonSchemas.USER,
    [FIELDS.INVITED_USERS]: commonSchemas.INVITED_USER_ARRAY
  },
  { idAttribute: CHALLENGE_ID }
);

export default {
  CHALLENGE: challengeSchema,
  CHALLENGE_ARRAY: [challengeSchema]
};
