import { createAction } from 'redux-actions';
import {
  MODAL_OPEN,
  MODAL_CLOSE,
  CommonModalTypes,
  ImageTypes
} from '../../constants';
import { getFullPath } from '../../utils/location';

export const MODAL_CLOSE_ALL = 'MODAL_CLOSE_ALL';

/**
 * @function
 * @param {Object} modalSettings
 * @param {string} modalSettings.modalType - Unique ID for the modal dialog
 * @param {Object} modalSettings.modalProps - Props forwarded to the modal child component
 */
export const openModal = createAction(MODAL_OPEN);
export const closeModal = createAction(MODAL_CLOSE);
export const closeAllModals = createAction(MODAL_CLOSE_ALL);

export const openShareUrlModal = (
  url = getFullPath(),
  props = {
    title: null,
    subtitle: null
  }
) => dispatch => {
  const { title, subtitle } = props;

  dispatch(
    openModal({
      modalType: CommonModalTypes.SHARE_URL_MODAL,
      modalProps: {
        url,
        title,
        subtitle
      }
    })
  );
};

export const openImagePickerModal = (options = {}) => {
  const defaultOptions = {
    availableTypes: [ImageTypes.PRESET, ImageTypes.GOOGLE, ImageTypes.UPLOADED],
    crop: {},
    defaultActiveType: ImageTypes.PRESET,
    isCloseButtonShown: true,
    onImageSelect: () => {},
    size: 'large'
  };

  return openModal({
    modalType: CommonModalTypes.IMAGE_PICKER,
    modalProps: { ...defaultOptions, ...options }
  });
};
