import React, { useState, useCallback } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import cn from 'classnames';
import EmailConfirmationCallout from '../../containers/EmailConfirmationCallout';
import Progress from '../Progress';
import NotificationsPopover from '../../../notifications/containers/NotificationsPopover';
import NetworkErrorCallout from '../../containers/NetworkErrorCallout';
import Avatar from '../Avatar';
import Badge from '../Badge';
import Button from '@bit/be-novative.kit.button';
import Icon from '../Icon';
import PageHeader, { Section, Item } from '../PageHeader';
import PlatformLogo from '../PlatformLogo';
import Popover from '../Popover';
import Responsive from '../Responsive';
import TutorialButton from '../TutorialButton';
import { openSidenav } from '../../actions';
import { unreadNotificationCountSelector } from '../../reducers/latestNotifications';
import { ownProfileSelector } from '../../../profile/state/profile';
import { getUserAvatar } from '../../getters/users';
import useTooltip from '../../hooks/useTooltip';
import useWindowSize from '../../hooks/useWindowSize';
import paths from '../../../paths';
import { conceptListPage } from '../../../ideaConcept/paths';
import { BREAKPOINTS, Size } from '../../../constants';
import { ColorVariantHex } from '../../styled/colors.styled';
import localiseUserName from '../../../utils/localiseUserName';
import { getPlatformName } from '../../../utils/platform';
import scss from './GlobalPageHeader.module.scss';

GlobalPageHeader.propTypes = {
  cta: PropTypes.node,
  lastChild: PropTypes.bool
};
GlobalPageHeader.defaultProps = {
  lastChild: true
};
function GlobalPageHeader({ cta, lastChild, t }) {
  const dispatch = useDispatch();
  const profile = useSelector(ownProfileSelector);
  const avatar = getUserAvatar(profile);
  const userName = localiseUserName(t, profile);
  const avatarTooltipProps = useTooltip(t('common/Profile'), 'down');
  const isConfirmed = get(profile, 'confirmed');

  const [showNotifications, setShowNotifications] = useState(false);
  const openNotifMenu = useCallback(() => {
    setShowNotifications(true);
  }, []);
  const closeNotifMenu = useCallback(() => {
    setShowNotifications(false);
  }, []);
  const unreadCount = useSelector(unreadNotificationCountSelector);

  const openDrawer = useCallback(() => {
    dispatch(openSidenav());
  }, [dispatch]);

  const { width } = useWindowSize();
  const showCompactLogo = width < BREAKPOINTS.SM;
  const logoClasses = cn({
    [scss.alignWithMenu]: !showCompactLogo
  });

  return (
    <header>
      {profile && isConfirmed && <Progress thickness="thick" />}
      {profile && !isConfirmed && <EmailConfirmationCallout />}
      <PageHeader lastChild={lastChild}>
        <Section>
          <Section>
            <Item>
              <Link to={paths.root()}>
                <PlatformLogo
                  compact={showCompactLogo}
                  className={logoClasses}
                />
              </Link>
            </Item>
          </Section>
          <Section>
            <Item>
              <NavLink to={paths.feed()}>
                {t('common/navMenu/challenges')}
              </NavLink>
            </Item>
            <Item>
              <NavLink to={conceptListPage()}>
                {t('common/navMenu/ideaConcepts', {
                  context: getPlatformName()
                })}
              </NavLink>
            </Item>
          </Section>
        </Section>

        <Section>
          <Responsive mediaHiddenDown="md">
            <Item>{cta}</Item>
          </Responsive>

          <Responsive mediaHiddenDown="md">
            <Item>
              <TutorialButton />
            </Item>
          </Responsive>

          <Responsive mediaHiddenDown="lg">
            <Item>
              <Link to={paths.profile()}>
                <Avatar
                  name={userName}
                  image={avatar}
                  size={Size.sm}
                  {...avatarTooltipProps}
                />
              </Link>
            </Item>
          </Responsive>

          {false && (
            <Item>
              <Popover.PopoverTrigger
                popover={<NotificationsPopover />}
                popoverStyle={{ width: 300 }}
                action={
                  <Button variant="link" to={paths.notifications()}>
                    {t('common/See All')}
                  </Button>
                }
                show={showNotifications}
                onClose={closeNotifMenu}>
                <Badge value={unreadCount} variant={ColorVariantHex.danger}>
                  <Icon
                    type="globe"
                    size={Size.lg}
                    tooltip={!showNotifications && t('common/Notifications')}
                    tooltipPosition="down"
                    onClick={openNotifMenu}
                  />
                </Badge>
              </Popover.PopoverTrigger>
            </Item>
          )}

          <Item>
            <Icon
              type="menu"
              size={Size.lg}
              onClick={openDrawer}
              tooltip={t('common/Menu')}
              tooltipPosition="down"
            />
          </Item>
        </Section>
      </PageHeader>

      <Responsive mediaHiddenUp="md">
        <div className={scss.MobileCta}>{cta}</div>
      </Responsive>
      <NetworkErrorCallout />
    </header>
  );
}

export default translate()(GlobalPageHeader);
