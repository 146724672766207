import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { translate } from 'react-i18next';
import PrivateConceptInput from '../../components/PrivateConceptInput';
import TeamInput from '../../components/TeamInput';
import { conceptEditorFormFields } from '../../constants';

const { TEAM, IS_PRIVATE } = conceptEditorFormFields;

function VisibilityInputs({
  autoFocus,
  emailRegex,
  teamMinLength,
  labelContext,
  t
}) {
  const formApi = useForm();
  const [hasAnimated, setHasAnimated] = useState(false);
  useEffect(
    function animateVisibilityChange() {
      let timer;
      if (autoFocus && !hasAnimated) {
        timer = setTimeout(() => {
          formApi.change(IS_PRIVATE, 1);
          setHasAnimated(true);
        }, 1000);
      }
      return () => clearTimeout(timer);
    },
    [autoFocus, formApi, hasAnimated]
  );

  return (
    <>
      <PrivateConceptInput name={IS_PRIVATE} required />

      <TeamInput
        customLabel={t('ideaConcept/prop/team/label', {
          context: labelContext
        })}
        emailRegex={emailRegex}
        name={TEAM}
        minLength={teamMinLength}
        autoFocus={autoFocus}
        layout="horizontal"
        data-test="team"
      />
    </>
  );
}

export default translate()(VisibilityInputs);
