import PropTypes from 'prop-types';
import React from 'react';

export default function withTooltip(WrappedComponent) {
  function Tooltip(props) {
    let tooltipProps = {};
    const {
      tooltip,
      tooltipPosition,
      tooltipLength,
      tooltipVisible,
      ...rest
    } = props;

    if (tooltip && tooltipVisible !== false) {
      tooltipProps = {
        'aria-label': tooltip,
        'data-balloon': tooltip,
        'data-balloon-pos': tooltipPosition,
        'data-balloon-length': tooltipLength
      };

      if (tooltipVisible) {
        tooltipProps['data-balloon-visible'] = true;
      }
    }

    return <WrappedComponent {...tooltipProps} {...rest} />;
  }

  Tooltip.propTypes = {
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    tooltipPosition: PropTypes.oneOf(['up', 'down', 'left', 'right']),
    tooltipLength: PropTypes.oneOf([
      'small',
      'medium',
      'large',
      'xlarge',
      'fit'
    ]),
    tooltipVisible: PropTypes.bool
  };
  Tooltip.defaultProps = {
    tooltipPosition: 'up'
  };

  return Tooltip;
}
