import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import history from './history';
import commonReducers from './common/reducers';
import { reducers as ideaConceptReducers } from './ideaConcept';
import { reducers as conceptCanvasReducers } from './ideaConceptCanvas';
import { ERASE_STORES } from './common/actions';
import { reducer as profileReducer } from './profile';

export default function createReducer(asyncReducers) {
  const combinedReducers = combineReducers({
    ...commonReducers,
    ...asyncReducers,
    ...ideaConceptReducers,
    ...conceptCanvasReducers,
    form: formReducer,
    router: connectRouter(history),
    ownProfile: profileReducer
  });

  return (state, action) =>
    combinedReducers(action.type === ERASE_STORES ? undefined : state, action);
}
