import { Component, cloneElement } from 'react';
import classNames from 'classnames';

class StyleButton extends Component {
  onToggle = e => {
    e.preventDefault();
    this.props.onToggle(this.props.style);
  };

  render() {
    const { label } = this.props;
    const classes = classNames('RichText__StyleButton', {
      'RichText__StyleButton--active': this.props.active
    });

    return cloneElement(label, {
      className: classes,
      onMouseDown: this.onToggle
    });
  }
}

export default StyleButton;
