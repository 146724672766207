import axios from 'axios';
import { getToken } from '../authentication/services/token.service';
import { HTTP_TIMEOUT_MS } from './constants';
import handleUnauthenticated from './interceptors/handleUnauthenticated';
import storeToken from './interceptors/storeToken';

const commonHeaders = {
  'Content-Type': 'application/json'
};
if (getToken()) {
  commonHeaders.Authorization = getToken();
}

const client = axios.create({
  timeout: HTTP_TIMEOUT_MS,
  headers: {
    common: commonHeaders,
    // Axios defaults overwrite common headers specified above
    patch: {
      'Content-Type': 'application/json'
    },
    post: {
      'Content-Type': 'application/json'
    },
    put: {
      'Content-Type': 'application/json'
    }
  },
  withCredentials: true
});

// Handle authentication
client.interceptors.response.use(storeToken, handleUnauthenticated);

export default client;
