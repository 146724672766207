export const CONCEPT_ROOT = 'ideaconcepts';
export const CONCEPT_LIST = 'list';
export const CONCEPT_SUBPAGE = 'concept';
export const CONCEPT_EVALUATION = 'review';

export function conceptListPage(conceptStage = '') {
  return `/${CONCEPT_ROOT}/${CONCEPT_LIST}/${conceptStage}`;
}

export function conceptSubpage(conceptId, bookmark) {
  let path = `/${CONCEPT_ROOT}/${CONCEPT_SUBPAGE}/${conceptId}`;
  if (bookmark) {
    path += `#${bookmark}`;
  }
  return path;
}

/**
 * Returns the path for the concept evaluation page.
 * @param {IdeaConceptEvaluationFilterKey} filterKey - Specifies what type of filter will be used for fetching concepts for review.
 * @param {string} filterValue - Specifies which concepts will be fetched for review.
 * @param {("subpage" | undefined)} [redirectTo] - Sets where to redirect after a successful review was submitted.
 * @returns {string} The page path with correct query params
 */
export function conceptEvalPage(filterKey, filterValue, redirectTo) {
  if (!filterKey || (filterKey !== 'conceptList' && !filterValue)) {
    throw new Error(
      `filterKey and filterValue must be specified. filterKey = ${filterKey}, filterValue = ${filterValue}`
    );
  }
  switch (filterKey) {
    case 'conceptId':
      const target = redirectTo ? `target=${redirectTo}` : '';
      return `/${CONCEPT_ROOT}/${CONCEPT_EVALUATION}?conceptId=${filterValue}&${target}`;
    case 'challengeId':
      return `/${CONCEPT_ROOT}/${CONCEPT_EVALUATION}?challengeId=${filterValue}`;
    case 'conceptList':
      return `/${CONCEPT_ROOT}/${CONCEPT_EVALUATION}?conceptList=true`;
    default:
      throw new Error(`filterKey is invalid: ${filterKey}`);
  }
}

export function conceptEvalById(ideaConceptId) {
  return `${conceptSubpage(ideaConceptId)}/review`;
}
