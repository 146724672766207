import moment from 'moment';

export function parseUTCISOString(isoDate) {
  const date = moment(isoDate);
  return isoDate && date.isValid() ? date.local().toDate() : null;
}

export function parseLocalDateString(localDateString) {
  const date = moment(localDateString, 'L LT', true);
  return date.isValid() ? date.toDate() : null;
}

export function formatLocalDateToUTCISOString(localDate) {
  const date = moment(localDate);
  return localDate && date.isValid() ? date.utc().format() : null;
}

export function formatUTCISOStringToLocalDate(isoDate) {
  const date = moment(isoDate);
  return isoDate && date.isValid() ? date.local().format('L LT') : null;
}
