import Amplitude from './vendors/Amplitude';
import GoogleAnalytics from './vendors/GoogleAnalytics';
import { getPage } from './pageProvider';

const config = {
  vendors: [
    { name: 'Amplitude', api: new Amplitude() },
    { name: 'GoogleAnalytics', api: new GoogleAnalytics() }
  ],
  pageDefaults: routeState => {
    return {
      timestamp: new Date(),
      build: process.env.REACT_APP_VERSION,
      page: getPage(routeState.pathname)
    };
  },
  pageViewEvent: 'pageView',
  debug: process.env.NODE_ENV === 'development'
};

export default config;
