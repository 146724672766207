import { unwrapResult } from '@reduxjs/toolkit';
import get from 'lodash/get';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../common/actions/modal';
import Icon from '../../../common/components/Icon';
import { CONCEPT_COMMENT_EDITOR } from '../../constants';
import { editComment, fetchComments } from '../../state/internalComments';
import { editFeedback, fetchFeedback } from '../../state/reviewerFeedback';
import useGetCommentLike from './useGetCommentLike';

const fetchAction = {
  internalComment: fetchComments,
  feedback: fetchFeedback
};

const editAction = {
  internalComment: editComment,
  feedback: editFeedback
};

const editorProps = {
  internalComment: t => ({
    title: t('ideaConcept/evaluation/comment/title', { context: 'internal' }),
    description: (
      <>
        <Icon type="lock" style={{ marginRight: 5 }} />
        {t('ideaConcept/evaluation/comment/visibility')}
      </>
    )
  }),
  feedback: (t, isGroupComment) => ({
    title: t('ideaConcept/evaluation/comment/title', { context: 'feedback' }),
    description: isGroupComment ? (
      <>
        <Icon type="email" style={{ marginRight: 5 }} />
        {t('ideaConcept/evaluation/comment/groupFeedbackEmail')}
      </>
    ) : null
  })
};

/**
 * Returns an edit callback for the given comment type
 * @param {"internalComment" | "feedback"} commentType - Determines which endpoint will be called for editing the comment
 * @param {string|number} conceptId - Parent concept ID
 * @param {string|number} [authorId] - Used for opening the modal dialog. If the author's user ID is present, the comment will be saved on their behalf. Otherwise it will be saved on behalf of the jury group.
 * @param {Function} [t] - Localisation function used for rendering the modal dialog
 * @returns {[openEditor: Function, submitComment: Function<Promise>]}
 */
export default function useEditCommentLike(
  commentType,
  conceptId,
  authorId,
  t
) {
  if (!['internalComment', 'feedback'].includes(commentType))
    throw Error(`Invalid commentType: ${commentType}`);

  const isGroupComment = !authorId;
  const comment = useGetCommentLike(commentType, conceptId, authorId);
  const text = get(comment, 'text', '');

  const dispatch = useDispatch();
  const submitComment = useCallback(
    async (text, isGroupComment) => {
      try {
        const res = await dispatch(
          editAction[commentType]({
            authorId,
            conceptId,
            isGroupComment,
            text
          })
        );
        unwrapResult(res);
        dispatch(fetchAction[commentType](conceptId));
        return res;
      } catch (error) {
        throw error;
      }
    },
    [authorId, conceptId, dispatch, commentType]
  );
  // TODO extract to useOpenCommentLikeEditor
  const openEditor = useCallback(
    () =>
      dispatch(
        openModal({
          modalType: CONCEPT_COMMENT_EDITOR,
          modalProps: {
            initialValue: text,
            onSubmit: submitComment,
            size: 'large',
            isGroupComment,
            ...editorProps[commentType](t, isGroupComment)
          }
        })
      ),
    [commentType, dispatch, isGroupComment, submitComment, t, text]
  );

  return [openEditor, submitComment];
}
