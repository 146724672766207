import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { denormalize } from 'normalizr';
import { getEntities, hasEntityFailed } from '../../common/selectors/entities';
import {
  getChallengeById,
  isChallengeLoading
} from '../../common/selectors/challenges';
import { fetchChallenge } from '../../common/actions/challenges';
import schemas from '../schemas';
import { entities } from '../../constants';

/**
 * @typedef {Object} ChallengeHookApi
 * @property {() => boolean} isLoading - Returns whether the challenge is being fetched
 * @property {() => Error} getError - Returns whether the error if challenge fetch has failed
 * @property {() => Promise} fetch - Callback for triggering challenge re-fetch
 * @property {() => Challenge} denorm - Expensive function for denormalising the challenge
 */

/**
 * Provides a normalised challenge,
 * which is fetched from the server if it's not available in the Redux state.
 *
 * Getters are provided for status indicators, as well as for the denormalised challenge.
 * @param {string} challengeId
 * @returns {[Challenge, api: ChallengeHookApi]} A tuple of the challenge and its related API
 */
export default function useChallenge(challengeId) {
  const normalisedChallenge = useSelector(
    getChallengeById(challengeId),
    isEqual
  );
  const loading = useSelector(isChallengeLoading(challengeId));
  const error = useSelector(hasEntityFailed(entities.CHALLENGE)(challengeId));

  const dispatch = useDispatch();
  const fetch = useCallback(
    async function fetch() {
      if (!challengeId || loading || error) {
        return;
      }
      try {
        await dispatch(fetchChallenge(challengeId));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, challengeId, error, loading]
  );

  useEffect(
    function fetchIfEmpty() {
      if (!normalisedChallenge && !error) {
        fetch();
      }
    },
    [challengeId, error, fetch, loading, normalisedChallenge]
  );

  const existingEntities = useSelector(getEntities);
  const denorm = useCallback(
    function denorm() {
      if (!normalisedChallenge) {
        return {};
      }
      return denormalize(
        normalisedChallenge,
        schemas.CHALLENGE,
        existingEntities
      );
    },
    [existingEntities, normalisedChallenge]
  );

  const api = useMemo(
    () => ({
      isLoading: () => loading,
      getError: () => error,
      fetch,
      denorm
    }),
    [loading, error, fetch, denorm]
  );

  return [normalisedChallenge, api];
}
