import getFp from 'lodash/fp/get';
import flow from 'lodash/flow';

export const rootSelector = state => state.language;

export const getBrowserLanguage = flow(rootSelector, getFp('userAgent'));

export const getCurrentLanguage = flow(rootSelector, getFp('current'));

export default {
  getBrowserLanguage,
  getCurrentLanguage
};
