import get from 'lodash/get';

/**
 * Returns the platform's ID
 * @param {PlatformSettingsMap} platformSettings - The platform settings object
 * @return {string} The platform's ID
 */

export const getPlatformId = platformSettings =>
  get(platformSettings, 'companyId');

/**
 * Returns the platform's pretty name
 * @param {PlatformSettingsMap} platformSettings - The platform settings object
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {string}
 */

export const getPlatformName = platformSettings =>
  get(platformSettings, 'companyName');

/**
 * Returns the languages in which content, e.g. challenges can be created
 * @param {PlatformSettingsMap} platformSettings - The platform settings object
 * @return {Array<string>} The array of 2-letter capitalised codes for the available languages
 */

export const getContentLanguages = platformSettings =>
  get(platformSettings, 'availableChallengeLanguages', []);

/**
 * Returns the list of hashtags that can be appended to content
 * @param {PlatformSettingsMap} platformSettings - The platform settings object
 * @return {Array<string>} The array of available hashtags
 */

export const getAvailableHashtags = platformSettings =>
  get(platformSettings, 'availableHashtags', []);

/**
 * Returns the regular expression of email domains allowed to access the platform
 * @param {PlatformSettingsMap} platformSettings - The platform settings object
 * @return {RegExp} The allowed email domains
 */

export const getAllowedEmailDomains = platformSettings =>
  get(platformSettings, 'allowedEmailDomains', /.*/);

/**
 * Returns the platform logo image
 * @param {PlatformSettingsMap} platformSettings - The platform settings object
 * @return {ImageObject} The platform logo image
 */

export const getPlatformLogo = platformSettings =>
  get(platformSettings, 'image');
