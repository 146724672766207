import client from '../../api/client';
import { getApiResponseData } from '../../api/utils/apiResponse';

/**
 * Returns the current platform settings
 * @return {Promise<PlatformSettingsMap>} The platform configuration extracted from the response
 */

function fetchOwnPlatform() {
  return client.get('/company').then(getApiResponseData);
}

export default {
  fetchOwnPlatform
};
