import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';

export const getEntities = state => get(state, ['entities', 'store'], {});

export const getEntities_new = state => get(state, 'entities', {});

export const getEntity = entityType => entityId => state =>
  flow(
    getEntities,
    entities =>
      get(entities, [entityType, entityId]) ||
      get(entities, [entityType, String(entityId).toUpperCase()]) ||
      get(entities, [entityType, String(entityId).toLowerCase()])
  )(state);

export const isEntityLoading = entityType => entityId => state =>
  flow(getFp(['entities', 'loading', `${entityType}_${entityId}`]))(state);

export const hasEntityFailed = entityType => entityId => state =>
  flow(getFp(['entities', 'error', `${entityType}_${entityId}`]))(state);

export const getEntityMap = entityType => state =>
  flow(
    getEntities,
    entities => get(entities, entityType, {})
  )(state);

export const getAllIdsFrom = getOr([], 'allIds');

export const getAllIds = flow(
  getEntities,
  getAllIdsFrom
);

export const getEntityIds = entityType => state =>
  flow(
    getAllIds,
    idMap => get(idMap, entityType, [])
  )(state);
