import PropTypes from 'prop-types';
import React from 'react';
import './Menu.css';

function Menu({ children }) {
  return <ul className="menu">{children}</ul>;
}

Menu.propTypes = {
  children: PropTypes.node
};

export default Menu;
