import React from 'react';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import cn from 'classnames';
import { getStageTranslation } from '../../../ideaConceptCanvas/utils/translateConceptStage';
import { IdeaConceptImplementationStatusCepheid } from '../../constants';
import './StageIndicator.css';

const stageEnum = values(IdeaConceptImplementationStatusCepheid);
const BASE_CLASS = 'StageIndicator';

StageIndicator.propTypes = {
  stage: PropTypes.oneOf(stageEnum)
};
function StageIndicator({ stage, t }) {
  return (
    <span
      className={cn(BASE_CLASS, {
        [`${BASE_CLASS}--${stage}`]: stage
      })}>
      {getStageTranslation(stage, t)}
    </span>
  );
}

export default translate()(StageIndicator);
