import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '../../components';

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string
};
export function SearchForm({ onSubmit, name, placeholder, label }) {
  const [query, setQuery] = useState('');
  const handleUpdate = e => {
    setQuery(e.target.value);
  };
  const submitHandler = e => {
    e.preventDefault();
    onSubmit(query);
  };

  return (
    <form onSubmit={submitHandler} style={{ margin: 0 }}>
      <Grid.Row align="center">
        <Grid.Col xs="auto" xsGutter={2}>
          {label}
        </Grid.Col>
        <Grid.Col xs="auto" xsGutter={2}>
          <input
            name={name}
            value={query}
            onChange={handleUpdate}
            placeholder={placeholder}
            className="Input"
          />
        </Grid.Col>
      </Grid.Row>
    </form>
  );
}

export default SearchForm;
