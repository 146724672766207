import { handleActions, combineActions } from 'redux-actions';
import get from 'lodash/get';

const errorReducerFactory = action =>
  handleActions(
    {
      [combineActions(action.PENDING, action.SUCCESS)]: () => false,
      [action.ERROR]: (_, { payload, meta }) =>
        get(meta, 'status') || payload || true
    },
    false
  );

export default errorReducerFactory;
