import moment from 'moment';

export default function useFormattedDateTime(timestamp, showDate, showTime) {
  if (!timestamp) {
    return null;
  }

  let display;

  if (showDate) {
    if (showTime) {
      display = 'LLL';
    } else {
      display = 'LL';
    }
  } else {
    if (showTime) {
      display = 'LT';
    } else {
      display = 'X';
    }
  }

  return moment(timestamp).format(display);
}
