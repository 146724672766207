import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { IDEA_CONCEPT_DETAILS_FIELD_MAXLENGTH } from '../../config';
import { validateConceptDescription } from '../../utils/formValidation';
import ConceptRichTextField from '../ConceptRichTextField';

ConceptDescriptionInput.propTypes = {
  autoFocus: PropTypes.bool,
  name: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};
export function ConceptDescriptionInput({
  autoFocus,
  name,
  t,
  ...restOfProps
}) {
  return (
    <ConceptRichTextField
      label={t('ideaConcept/prop/description/label')}
      placeholder={t('ideaConcept/richTextPlaceholder')}
      secondaryLabel={null}
      name={name}
      required
      validate={validateConceptDescription(t)}
      maxLength={IDEA_CONCEPT_DETAILS_FIELD_MAXLENGTH}
      autoFocus={autoFocus}
      {...restOfProps}
    />
  );
}

export default compose(
  memo,
  translate()
)(ConceptDescriptionInput);
