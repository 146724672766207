import get from 'lodash/get';
import {
  INLINE_HELP_VIEWED,
  LOVE_CHALLENGE,
  LOVE_IDEA,
  SHARE_BUTTON_INSIDE_THE_SHARE_MODAL_CLICK,
  MODAL_OPEN,
  COPY_TO_CLIPBOARD_BUTTON_CLICK,
  FeedModalTypes
} from '../../constants.js';
import { getPage } from '../pageProvider';
import EventTracker from './_EventTracker';

const EVENT_GROUP = 'common';

class CommonTracker extends EventTracker {
  constructor(metrics) {
    super(metrics);
    this.eventGroup = EVENT_GROUP;
  }

  track(action, userId) {
    const { type } = action;
    const metrics = get(
      action,
      'metrics',
      get(action, ['meta', 'metrics'], {})
    );
    const commonData = {
      page: getPage(),
      userId
    };

    if (
      action.type === LOVE_IDEA.SUCCESS ||
      action.type === LOVE_CHALLENGE.SUCCESS
    ) {
      this.coreTrack(action.type, { ...commonData, ...metrics });
    } else if (
      action.type === MODAL_OPEN &&
      (action.payload.modalType === FeedModalTypes.SHARE_CARD ||
        action.payload.modalType === 'SHARE')
    ) {
      this.coreTrack(action.type + '_' + action.payload.modalType, commonData);
    } else if (action.type === COPY_TO_CLIPBOARD_BUTTON_CLICK) {
      this.coreTrack(action.type, commonData);
    } else if (action.type === SHARE_BUTTON_INSIDE_THE_SHARE_MODAL_CLICK) {
      this.coreTrack(action.type, {
        ...commonData,
        provider: action.meta.metrics.provider
      });
    } else if (type === INLINE_HELP_VIEWED) {
      this.coreTrack(type, {
        ...commonData,
        helpId: get(metrics, 'helpId')
      });
    } else if (type === MODAL_OPEN) {
      this.coreTrack(type, {
        ...commonData,
        modalType: get(action, ['payload', 'modalType'])
      });
    }
  }
}

export default CommonTracker;
