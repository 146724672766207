import flow from 'lodash/flow';
import mapFp from 'lodash/fp/map';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import pickFp from 'lodash/fp/pick';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import upperCase from 'lodash/upperCase';
import moment from 'moment';
import {
  maybeHtmlFromState,
  htmlFromState
} from '@bit/be-novative.kit.richtext-utils';
import sanitizeForJSON from '@bit/be-novative.kit.sanitize-for-json';
import { convertInviteeToIdString } from '../../utils/payloadUtils/invitee';
import {
  areConceptEvalCriteriaSet,
  createCriteriaConfig
} from './conceptEvalUtils';
import {
  ChallengeScenario,
  ChallengeVisiblity,
  CHALLENGE_FORM_FIELDS as FIELDS
} from '../constants';
import { getCategoryId } from '../../common/getters/categories';
import { getUserId } from '../../common/getters/users';
import { getPhaseId } from '../../ideation/selectors/ideationPhase';
import { convertDoubleQuotes } from '../../utils/strNormalise';

const {
  AXIS_X,
  AXIS_Y,
  CATEGORY,
  CLOSE_TIME,
  CONCEPT_EVAL_CRITERIA,
  CREATOR,
  DEFAULT_CONCEPT_OWNER,
  DESCRIPTION,
  DURATION_CUSTOM,
  DURATION_PRESET,
  FACILITATED,
  FEATURED,
  GROUP_IDEATION_TIME,
  GROUP_IDEATION_TIMEZONE,
  HASHTAGS,
  IDEATION_PHASES,
  IDEATION_ROOM_MAX_SIZE,
  IMAGE,
  INVITE_EMAIL,
  INVITE_ALL_USERS,
  INVITED_USERS,
  LANGUAGE,
  SCENARIO,
  START_TIME,
  START_PHASE,
  TITLE,
  VISIBILITY
} = FIELDS;

/**
 * Field values that don't require any conversion or sanitisation
 */
const VALID_CHALLENGE_KEYS = [
  AXIS_X,
  AXIS_Y,
  CATEGORY,
  CLOSE_TIME,
  CREATOR,
  DEFAULT_CONCEPT_OWNER,
  DESCRIPTION,
  FACILITATED,
  FEATURED,
  GROUP_IDEATION_TIME,
  GROUP_IDEATION_TIMEZONE,
  HASHTAGS,
  IDEATION_PHASES,
  IDEATION_ROOM_MAX_SIZE,
  IMAGE,
  INVITE_ALL_USERS,
  INVITE_EMAIL,
  INVITED_USERS,
  LANGUAGE,
  SCENARIO,
  START_TIME,
  START_PHASE,
  TITLE,
  VISIBILITY
];

export const getChallengeDates = (presetRange, customDuration = []) => {
  if (presetRange) {
    return {
      [START_TIME]: moment()
        .utc()
        .format(),
      [CLOSE_TIME]: moment()
        .add(moment.duration(presetRange))
        .utc()
        .format()
    };
  }

  if (!isEmpty(customDuration)) {
    const [startsAt, closesAt] = customDuration;

    return { [START_TIME]: startsAt, [CLOSE_TIME]: closesAt };
  }

  return {};
};

export const pickChallengeKeys = pickFp(VALID_CHALLENGE_KEYS);

/**
 * Normalises the challenge payload for submission to the API
 * @param {Challenge} changedValues - The new challenge values. When editing a challenge, only the changed fields should be present in this argument.
 * @param {Object} options
 * @param {Challenge} options.existingData - Existing challenge data should be supplied when editing a challenge. This is necessary because the merge strategy must be adjusted on a per-field basis.
 * @param {boolean} options.isImageUpdated - Must be set to `true` explicitly, as the API expects `null` when the challenge image isn't updated
 * @return {ChallengePayloadForSubmission} The normalised challenge payload ready for submission
 */

const createChallengeRequest = (
  changedValues,
  options = { existingData: {}, isImageUpdated: false }
) => {
  const mergedValues = merge(options.existingData, changedValues);
  const payload = mapValues(mergedValues, function replaceArraysWithNewest(
    mergedVal,
    key
  ) {
    return Array.isArray(changedValues[key]) ? changedValues[key] : mergedVal;
  });
  const datesFromDuration = getChallengeDates(
    payload[DURATION_PRESET],
    payload[DURATION_CUSTOM]
  );
  const startTime = payload[START_TIME] || datesFromDuration[START_TIME];
  const closeTime = payload[CLOSE_TIME] || datesFromDuration[CLOSE_TIME];
  const phases = isEmpty(options.existingData)
    ? payload[IDEATION_PHASES]
    : uniqBy(payload[IDEATION_PHASES], getPhaseId);

  return {
    ...pickChallengeKeys(payload),
    [CATEGORY]: payload[CATEGORY] || getCategoryId(payload.category), // `categoryId` if categ was edited; denormalized `category` if it wasn't touched
    [CREATOR]: getUserId(payload[CREATOR]) || undefined,
    [CLOSE_TIME]: closeTime,
    [DESCRIPTION]: flow(
      maybeHtmlFromState,
      convertDoubleQuotes
    )(payload[DESCRIPTION]),
    [AXIS_X]: payload[AXIS_X] || null,
    [AXIS_Y]: payload[AXIS_Y] || null,
    [CONCEPT_EVAL_CRITERIA]: areConceptEvalCriteriaSet(payload)
      ? createCriteriaConfig(payload)
      : undefined,
    [IMAGE]: options.isImageUpdated ? payload[IMAGE] : null, // null should be sent when image is not updated
    [INVITED_USERS]: flow(
      mapFp(convertInviteeToIdString),
      uniq
    )(payload[INVITED_USERS]),
    [LANGUAGE]: upperCase(payload[LANGUAGE]),
    [IDEATION_PHASES]: phases,
    [INVITE_EMAIL]: htmlFromState(payload[INVITE_EMAIL]),
    [INVITE_ALL_USERS]:
      payload[INVITE_ALL_USERS] &&
      payload[VISIBILITY] === ChallengeVisiblity.PUBLIC,
    [SCENARIO]: payload[SCENARIO] || ChallengeScenario.Default,
    [START_TIME]: startTime,
    [TITLE]: sanitizeForJSON(payload[TITLE])
  };
};

export default createChallengeRequest;
