import get from 'lodash/get';

const getKey = (key, id) => `${key}_${id}`;
const getMeta = (meta = {}) => [get(meta, 'entityType'), get(meta, 'id')];

export const loadingByIdFactory = asyncAction => (
  state = {},
  { type, meta }
) => {
  const [key, id] = getMeta(meta);

  if (!key || !id) {
    return state;
  }

  switch (type) {
    case asyncAction.PENDING:
      return {
        ...state,
        [getKey(key, id)]: true
      };
    case asyncAction.ERROR:
    case asyncAction.SUCCESS:
      return {
        ...state,
        [getKey(key, id)]: false
      };
    default:
      return state;
  }
};

export const errorByIdFactory = asyncAction => (
  state = {},
  { type, payload, meta }
) => {
  const [key, id] = getMeta(meta);

  if (!key || !id) {
    return state;
  }

  switch (type) {
    case asyncAction.ERROR:
      return {
        ...state,
        [getKey(key, id)]: payload
      };
    case asyncAction.PENDING:
    case asyncAction.SUCCESS:
      return {
        ...state,
        [getKey(key, id)]: false
      };
    default:
      return state;
  }
};
