import isUndefined from 'lodash/isUndefined';
import { isCookieConsentAccepted } from '../../utils/cookieConsent';

export default class ConsentCheck {
  constructor() {
    if (isUndefined(this.trackingAllowed)) {
      this.trackingAllowed = isCookieConsentAccepted();
    }
  }
}
