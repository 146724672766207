import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isObjectLike from 'lodash/isObjectLike';
import size from 'lodash/size';
import * as Signalr from '@microsoft/signalr';
import { SOCKET_EVENTS } from '../constants';
import SocketAdapter from './_adapterClass';
import { Severity } from '../../telemetry/addBreadcrumb';

const { CONNECTED, DISCONNECTED, ERROR } = SOCKET_EVENTS;

export default class SignalrConnection extends SocketAdapter {
  // @see https://docs.microsoft.com/en-us/javascript/api/%40microsoft/signalr/index?view=signalr-js-latest
  constructor(socketId = '[signalr]', url) {
    super(socketId);

    const socket = new Signalr.HubConnectionBuilder()
      .withAutomaticReconnect()
      .configureLogging(Signalr.LogLevel.Information)
      .withUrl(url, {
        accessTokenFactory: this._tokenFactory
      })
      .build();

    socket.onreconnecting(this.onReconnect);
    socket.onreconnected(this.onReconnected);
    socket.onclose(this.onDisconnect);

    this.socket = socket;

    return this;
  }

  _tokenFactory = () => {
    if (!this.token) {
      return;
    }

    return this.token.split(' ')[1];
  };

  _addHandlerWithMsgType = (callback, eventType) => {
    this.socket.on(eventType, (...response) => {
      this._log({ message: eventType, payload: response });
      if (size(response) > 1) {
        return callback(response, eventType);
      } else {
        // Unwrap single argument from array
        return callback(response[0], eventType);
      }
    });
  };

  on(eventOrMap, callback) {
    super.on(eventOrMap, callback);

    if (!this.socket) {
      return this;
    }

    if (isObjectLike(eventOrMap)) {
      forEach(eventOrMap, this._addHandlerWithMsgType);
    } else {
      this._addHandlerWithMsgType(callback, eventOrMap);
    }

    return this;
  }

  async connect() {
    if (this.socket.state === CONNECTED) {
      this._log({
        level: Severity.Warning,
        message: `Connection already live`
      });
      return;
    }

    if (!this.token) {
      this._log({
        level: Severity.Warning,
        message: `Trying to connect without a token`
      });
    }

    this._log({
      message: `Has token, connecting to socket...`,
      data: { token: this.token, url: this.url }
    });

    try {
      const socket = this.socket;

      forEach(this.handlers, (callback, event) => {
        socket.on(event, callback);
      });

      await socket.start();
      this.onConnected();
      return socket;
    } catch (error) {
      this._callHandler(ERROR, error);
      throw error;
    }
  }

  disconnect() {
    if (this.socket.state === CONNECTED) {
      this._log({ message: 'Disconnecting Signalr' });
    } else {
      this._log({
        level: Severity.Warning,
        message: `Disconnecting while connection state is ${this.socket.state}`
      });
    }
    return this.socket.stop().catch(error => {
      this._log({
        level: Severity.Error,
        message: 'Error while trying to disconnect',
        data: { error, currentState: this.socket.state }
      });
    });
  }

  async send(messageType, args) {
    if (this.socket.state !== CONNECTED) {
      return Promise.reject(
        `Trying to send message without being connected, current state is ${this.socket.state}`
      );
    }

    this._log({
      message: 'Sending message...',
      data: { connection: this.socket.state, messageType, payload: args }
    });

    try {
      if (isArray(args) && size(args) > 1) {
        return await this.socket.invoke(messageType, ...args);
      } else if (!isEmpty(args) || isNumber(args) || isBoolean(args)) {
        return await this.socket.invoke(messageType, args);
      } else {
        // invoke() converts undefined to null, which might cause error if hub method doesn't expect arguments
        return await this.socket.invoke(messageType);
      }
    } catch (error) {
      this._log({
        level: Severity.Error,
        message: 'Could not send SignalR message',
        data: {
          connection: this.socket.state,
          messageType,
          message: args,
          error
        }
      });
      throw error;
    }
  }

  onReconnected = connectionId => {
    this._log({
      message: 'SignalR reconnected',
      data: {
        connectionId
      }
    });
    this.onConnected();
  };

  onDisconnect = error => {
    if (error) {
      this._log({
        level: Severity.Error,
        message: 'SignalR closed with error',
        data: {
          connectionId: this.socket.connectionId,
          error
        }
      });
      this.onError(error);
    } else {
      this._log({ message: 'Connection closed' });
    }
    this._callHandler(DISCONNECTED);
  };
}
