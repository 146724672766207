import * as Sentry from '@sentry/browser';
import ConsentCheck from './ConsentCheck';
import { getPage } from '../pageProvider';

class Amplitude extends ConsentCheck {
  constructor() {
    super();

    if (this.trackingAllowed) {
      require('./injectors/amplitude');
    }
  }

  _getInstance() {
    if (this.trackingAllowed) {
      try {
        return window.amplitude.getInstance();
      } catch (e) {
        Sentry.addBreadcrumb({
          category: 'metrics',
          message: '[amplitude] Could not get instance',
          level: Sentry.Severity.Error
        });
        Sentry.captureException(e);
      }
    }
  }

  pageView(eventName, params) {
    this.logEvent('pageView__' + getPage(), params);
  }

  track(eventName, params) {
    this.logEvent(eventName, params);
  }

  logEvent(event, params) {
    const company = window.location.hostname;
    const instance = this._getInstance();

    if (instance) {
      if (params.userId) {
        instance.setUserId(params.userId);
      }

      instance.setGroup('company', company);

      instance.logEvent(event, {
        ...params,
        company
      });
    }
  }
}

export default Amplitude;
