import React from 'react';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { translate } from 'react-i18next';
import paths from '../../../paths';
import { Grid } from '../../../common/components';
import { withNonIdealState } from '../../../common/containers';
import { SecondaryNavigation } from '../../components';
import ProfileEditForm from '../ProfileEditForm';
import PasswordChangeForm from '../PasswordChangeForm';
import LanguageSettings from '../LanguageSettings';
import PrivacySettings from '../PrivacySettings';
import ExportProfile from '../ExportProfile';
import DeleteProfile from '../DeleteProfile';
import { ownProfileSelector } from '../../state/profile';
import { getUserId } from '../../../common/getters/users';

SettingsTab.propTypes = {
  t: PropTypes.func.isRequired
};
export function SettingsTab(props) {
  const { t } = props;

  return (
    <Grid.Row>
      <Grid.Col xsGutterX={2} md={3}>
        <SecondaryNavigation>
          <SecondaryNavigation.Group
            title={t('profile/Your Personal Information')}>
            <SecondaryNavigation.Item
              exact
              to={paths.profileSettings()}
              children={t('profile/editorForm/title')}
            />
          </SecondaryNavigation.Group>
          <SecondaryNavigation.Group title={t('profile/Website Settings')}>
            <SecondaryNavigation.Item
              exact
              to={paths.profilePasswordSettings()}
              children={t('profile/Password')}
            />
            <SecondaryNavigation.Item
              exact
              to={paths.profileLanguageSettings()}
              children={t('profile/Language Settings')}
            />
            <SecondaryNavigation.Item
              exact
              to={paths.profilePrivacySettings()}
              children={t('profile/privacyForm/title')}
            />
            <SecondaryNavigation.Item
              exact
              to={paths.exportProfile()}
              children={t('profile/exportOwnProfile/title')}
            />
            <SecondaryNavigation.Item
              exact
              to={paths.deleteProfile()}
              children={t('profile/deleteAccount/title')}
            />
          </SecondaryNavigation.Group>
        </SecondaryNavigation>
      </Grid.Col>
      <Grid.Col md={9}>
        <Switch>
          <Route
            path="/profile/settings/password"
            component={PasswordChangeForm}
          />
          <Route
            path="/profile/settings/language"
            component={LanguageSettings}
          />
          <Route path="/profile/settings/privacy" component={PrivacySettings} />
          <Route path="/profile/settings/export" component={ExportProfile} />
          <Route path="/profile/settings/delete" component={DeleteProfile} />
          <Route component={ProfileEditForm} />
        </Switch>
      </Grid.Col>
    </Grid.Row>
  );
}

const mapStateToProps = state => ({
  profileId: flow(
    ownProfileSelector,
    getUserId
  )(state)
});

export default compose(
  translate(),
  connect(mapStateToProps),
  withNonIdealState({
    isLoading: ({ profileId }) => !profileId
  })
)(SettingsTab);
