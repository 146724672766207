import * as Sentry from '@sentry/browser';
import platform from '../../utils/platform';
import { UPDATE_ONBOARDING, OnboardingTips } from '../../constants';
import { IDENTITY_API } from '../../api/constants';
import {
  getOnboardingBitSet,
  onboardingTipsSelector,
  onboardingTipSelector
} from '../reducers';
import { openModal } from './modal';

// TODO convert into onboarding state slice
export const maybeOpenWelcomeModal = () => (dispatch, getState) => {
  const isWelcomeActive = onboardingTipSelector(OnboardingTips.WELCOME)(
    getState()
  );

  if (isWelcomeActive && !platform.isPublic(window.location.hostname)) {
    dispatch(
      openModal({
        modalType: 'WELCOME',
        modalProps: {
          size: 'large'
        }
      })
    );
  }
};

export const updateOnboarding = (flags, value = true) => (
  dispatch,
  getState
) => {
  const onboarding = getOnboardingBitSet(getState());

  if (!onboarding) {
    Sentry.captureException(
      new Error('Cannot update onboarding because onboarding data is empty')
    );
    return;
  }

  if (Array.isArray(flags)) {
    flags.forEach(flag => onboarding.set(flag, value === true ? 1 : 0));
  } else {
    onboarding.set(flags, value === true ? 1 : 0);
  }

  let hex = onboarding.toString(16);

  if (hex.length % 2) {
    hex = `0${hex}`;
  }

  return dispatch({
    [IDENTITY_API]: {
      url: '/profile/onboarding',
      method: 'PUT',
      data: hex,
      type: UPDATE_ONBOARDING
    }
  });
};

export const skipOnboarding = () => (dispatch, getState) => {
  const tips = onboardingTipsSelector(getState());
  if (tips) {
    return dispatch(updateOnboarding(tips, true));
  }
};

export const resetOnboarding = () => (dispatch, getState) => {
  const tips = onboardingTipsSelector(getState());
  if (tips) {
    return dispatch(updateOnboarding(tips, false));
  }
};
