// modals
export const BASIC_CONCEPT_EDITOR = 'BASIC_CONCEPT_EDITOR';
export const BULK_REVEAL_IDEAS = 'BULK_REVEAL_IDEAS';
export const CONFIRM_DELETE_IDEA = 'CONFIRM_DELETE_IDEA';
export const IDEATORS = 'IDEATORS';
export const INVITED_USERS = 'INVITED_USERS';
export const REOPEN_CHALLENGE = 'REOPEN_CHALLENGE';

// other constants
export const CHALLENGE_DETAILS_FORM = 'CHALLENGE_DETAILS_FORM';
export const FILTER = 'FILTER';
export const PAGINATION = 'PAGINATION';
