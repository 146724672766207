import Button from '@bit/be-novative.kit.button';
import React from 'react';
import { AlignedCellContent, SingleLineHeight } from '../ConceptTable.styled';
import TableNav from '../TableNav';

const action = {
  header: t => (
    <AlignedCellContent>
      <SingleLineHeight>
        <TableNav />
      </SingleLineHeight>
    </AlignedCellContent>
  ),
  main: (t, concept, btnProps) => (
    <AlignedCellContent alignItems="flex-start" justifyContent="center">
      <SingleLineHeight>
        <Button variant="primary-gray" {...btnProps} />
      </SingleLineHeight>
    </AlignedCellContent>
  )
};

export default action;
