import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import maxByFp from 'lodash/fp/maxBy';
import { createSelector } from 'reselect';
import { getAllIdsFrom } from '../../common/selectors/entities';
import { getEditTime } from '../../common/selectors/meta';
import { rootSelector } from '.';
import { CanvasIdProps } from '../../dndCanvas/constants';

const imagesRoot = flow(
  rootSelector,
  getFp('images')
);

export const getCanvasImageIds = state =>
  flow(
    imagesRoot,
    getAllIdsFrom
  )(state);

export const getCanvasImages = state =>
  flow(
    imagesRoot,
    getFp('byId')
  )(state);

export const getCanvasimageById = createSelector(
  getCanvasImages,
  (_, imageId) => imageId,
  (imageMap, id) => get(imageMap, id)
);

export const makeGetCanvasImageById = () => getCanvasimageById;

export const findLastModifyTime = (items = []) =>
  flow(
    maxByFp(
      flow(
        getEditTime,
        timestamp => new Date(timestamp)
      )
    ),
    getEditTime
  )(items);

export const getImageId = image => get(image, CanvasIdProps.Image);

export const getOwnerId = image => get(image, 'owner');

export const getImageData = getFp('image');
