import flow from 'lodash/flow';
import get from 'lodash/fp/get';
import { handleActions } from 'redux-actions';
import { SESSION_STATUS, S_ID } from '../constants';
import { rootSelector } from '../selectors';

export const UPDATE_SESSION_STATUS = 'UPDATE_SESSION_STATUS';
export const updateSessionStatus = sessionStatus => ({
  type: UPDATE_SESSION_STATUS,
  payload: sessionStatus,
  meta: { canvasId: S_ID }
});

const status = handleActions(
  {
    [UPDATE_SESSION_STATUS]: (_, { payload }) => payload
  },
  SESSION_STATUS.INACTIVE
);

export const getSessionStatus = state =>
  flow(
    rootSelector,
    get('status')
  )(state);

export default status;
