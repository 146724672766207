import produce from 'immer';
import flow from 'lodash/flow';
import mapFp from 'lodash/fp/map';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import {
  IDEAS_GROUPED,
  HIDE_GROUP,
  STORE_GROUPS,
  RENAME_GROUP
} from '../actions/groups';
import { FLUSH_DATA } from '../actions/presence';
import { getGroupId } from '../selectors/groups';

const allIds = handleActions(
  {
    [STORE_GROUPS]: (state, { payload }) =>
      flow(
        mapFp(getGroupId),
        newGroups => newGroups.concat(state),
        uniq
      )(payload),
    [IDEAS_GROUPED]: (state, { meta }) => uniq([...state, meta.id]),
    [HIDE_GROUP]: (state, { meta }) => without(state, meta.id),
    [FLUSH_DATA]: () => []
  },
  []
);

const byId = handleActions(
  {
    [STORE_GROUPS]: (state, { payload }) => {
      return produce(state, state => {
        payload.forEach(group => {
          state[getGroupId(group)] = group;
        });
      });
    },
    [IDEAS_GROUPED]: (state, { meta }) =>
      produce(state, state => {
        const { id: ideaGroupId } = meta;
        if (state[ideaGroupId]) {
          state[ideaGroupId].ideaGroupId = ideaGroupId;
        } else {
          state[ideaGroupId] = { ideaGroupId };
        }
      }),
    [HIDE_GROUP]: (state, { meta }) =>
      produce(state, state => {
        delete state[meta.id];
      }),
    [RENAME_GROUP]: (state, { payload }) =>
      produce(state, state => {
        state[payload.id].title = payload.title;
      }),
    [FLUSH_DATA]: () => ({})
  },
  {}
);

export default combineReducers({
  allIds,
  byId
});
