import PropTypes from 'prop-types';
import React from 'react';
import { Motion, spring } from 'react-motion';
import Close from '../Close';
import Backdrop from '../Backdrop';
import './Drawer.css';

function Drawer({ opened = false, children, onCloseClick }) {
  const x = opened ? 0 : 285;

  return (
    <div>
      <Backdrop visible={opened} onClick={onCloseClick} />
      <Motion style={{ xPosition: spring(x) }}>
        {({ xPosition }) => {
          const translate = `translateX(${xPosition}px)`;
          return (
            <div className="side-nav">
              <div
                className="side-bar drawer"
                style={{
                  WebkitTransform: translate,
                  transform: translate
                }}>
                <Close inverse onClick={onCloseClick} />
                {children}
              </div>
            </div>
          );
        }}
      </Motion>
    </div>
  );
}

Drawer.propTypes = {
  children: PropTypes.node,
  onCloseClick: PropTypes.func,
  opened: PropTypes.bool
};

export default Drawer;
