import flow from 'lodash/flow';
import sanitizeForJSON from '@bit/be-novative.kit.sanitize-for-json';
import { commonIdProps } from '../../../constants';
import { SOCKET_MSG as MSG, S_ID } from '../../constants';
import { sendSocketMessage } from '../../../common/actions/socket';
import {
  mapPositionToMsg,
  mapPositionFromMsg,
  mapOwnerIdFromMsg
} from './_mappers';

// Requests

export const editIdeaMsg = (text, ideaId, mergedIdeaIds = []) => {
  const sanitizedText = sanitizeForJSON(text);
  const payload = [ideaId, sanitizedText, mergedIdeaIds];
  return sendSocketMessage(S_ID, MSG.IDEA_EDIT_REQ, payload);
};

export const submitIdeaMsg = (text, posX, posY, posZ) => {
  const sanitizedText = sanitizeForJSON(text);
  const payload = [sanitizedText, mapPositionToMsg({ posX, posY, posZ })];
  return sendSocketMessage(S_ID, MSG.IDEA_SUBMIT, payload);
};

export const deleteIdeaMsg = ideaId =>
  sendSocketMessage(S_ID, MSG.IDEA_DELETE_REQ, ideaId);

export const moveIdeaMsg = (ideaId, posX, posY, posZ) => {
  const payload = [ideaId, mapPositionToMsg({ posX, posY, posZ })];
  return sendSocketMessage(S_ID, MSG.IDEA_MOVE_REQ, payload);
};

// Responses

export const normaliseIdeaReceivedMsg = (msg = {}) =>
  flow(
    mapPositionFromMsg,
    mapOwnerIdFromMsg
  )(msg);

export const normaliseIdeaEditedMsg = msg => {
  const [ideaId, text] = msg;
  return { [commonIdProps.IDEA_ID]: ideaId, text };
};
