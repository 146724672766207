import React from 'react';
import PropTypes from 'prop-types';
import useFormattedDateTime from '../../hooks/useFormattedDateTime';

DateTime.propTypes = {
  children: PropTypes.string,
  date: PropTypes.bool,
  time: PropTypes.bool
};
function DateTime({ children, date = true, time }) {
  const formattedTime = useFormattedDateTime(children, date, time);

  if (!children) {
    return null;
  }

  return <time dateTime={children}>{formattedTime}</time>;
}

export default React.memo(DateTime);
