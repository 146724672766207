import { createSelector } from 'reselect';
import chunk from 'lodash/fp/chunk';
import drop from 'lodash/fp/drop';
import filter from 'lodash/fp/filter';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import get from 'lodash/get';
import head from 'lodash/fp/head';
import identity from 'lodash/identity';
import isEqual from 'lodash/fp/isEqual';
import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';
import {
  IdeaConceptsFilterBy,
  RESULTS_PAGE_TRENDING_CONCEPT_GET_LIMIT
} from '../../constants';
import getItemByIdFrom from '../../utils/getItemByIdFrom';
import { entities } from '../../ideaConcept/constants';
import { getEntityMap } from '../../common/selectors/entities';
import { getCreator } from '../../common/selectors/meta';
import {
  getIdeaConceptId,
  hasConceptSuggestionFromUser
} from '../../ideaConcept/selectors/ideaConcepts';

export const rootSelector = state => get(state, 'challengeResults');

export const getConceptScores = state =>
  state.challengeResults.conceptTrendingScores;

const getOrderedChunkedTuples = flow(
  getConceptScores,
  chunk(RESULTS_PAGE_TRENDING_CONCEPT_GET_LIMIT)
);

const getTrendingTupleChunk = head;

const getRestOfTupleChunks = flow(
  drop(1),
  flatten
);

const getIdsFromTuples = map(head);

export const getTrendingConcepts = flow(
  getOrderedChunkedTuples,
  getTrendingTupleChunk,
  getIdsFromTuples
);

export const getNormalConcepts = flow(
  getOrderedChunkedTuples,
  getRestOfTupleChunks,
  getIdsFromTuples
);

export const getActiveTrendingConceptFilters = state =>
  state.challengeResults.trendingConceptFilters;

export const getConceptIdsBelongingToUser = createSelector(
  (_, userId) => userId,
  getTrendingConcepts,
  getEntityMap(entities.IDEA_CONCEPT),
  (currentUserId, trendingConceptIds, ideaConceptMap) =>
    flow(
      map(getItemByIdFrom(ideaConceptMap)),
      filter(
        flow(
          getCreator,
          isEqual(currentUserId)
        )
      ),
      map(getIdeaConceptId)
    )(trendingConceptIds)
);

export const getConceptIdsWithSuggestionFromUser = createSelector(
  getTrendingConcepts,
  identity,
  (_, userId) => userId,
  (trendingConceptIds, state, userId) =>
    filter(
      conceptId => hasConceptSuggestionFromUser(conceptId, userId)(state),
      trendingConceptIds
    )
);

const ideaConceptFilterMap = {
  [IdeaConceptsFilterBy.MY_IDEA_CONCEPTS]: getConceptIdsBelongingToUser,
  [IdeaConceptsFilterBy.WROTE_SUGGESTION]: getConceptIdsWithSuggestionFromUser
};

const callWithState = (state, userId) => fn => fn(state, userId);

export const getFilteredTrendingConcepts = createSelector(
  getActiveTrendingConceptFilters,
  getTrendingConcepts,
  identity,
  (_, userId) => userId,
  (activeFilterIds, trendingConceptIds, state, userId) => {
    if (activeFilterIds.length) {
      return flow(
        map(
          flow(
            getItemByIdFrom(ideaConceptFilterMap),
            callWithState(state, userId)
          )
        ),
        flatten,
        uniq
      )(activeFilterIds);
    }

    return trendingConceptIds;
  }
);
