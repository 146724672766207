import { IDENTITY_API, SEARCH_API, VOYAGER_API } from '../constants';

const API_PATH = {
  [VOYAGER_API]: 'api',
  [IDENTITY_API]: 'api',
  API: 'api',
  [SEARCH_API]: 'api'
};

export default function getApiSymbolPath(baseUrl, apiSymbol) {
  return `${baseUrl}/${API_PATH[apiSymbol]}`;
}
