import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import last from 'lodash/last';
import split from 'lodash/fp/split';
import toNumber from 'lodash/toNumber';

/**
 * Returns the complete result of an API call, including the HTTP code and any payload
 * @param {Object}
 * @return {import('axios').AxiosResponse}
 */

export const getApiResponse = getFp('response');

/**
 * Returns the HTTP error code from an API response
 * @param {Object} error - The raw API request, as received from the API handler
 * @returns {Number} The HTTP error code
 */

export const getApiResponseCode = error =>
  flow(
    getApiResponse,
    getFp('status'),
    toNumber
  )(error);

/**
 * Returns the payload from the API response
 * @param {Object} apiResponse - The unprocessed API response
 * @return {Object} The received payload
 */

export const getApiResponseData = apiResponse => get(apiResponse, 'data');

/**
 * Returns all raw headers from the API response
 * @param {Object} apiResponse - The unprocessed API response
 * @return {Object} The headers extracted from the API response
 */

export const getApiResponseHeaders = apiResponse => get(apiResponse, 'headers');

export const getEmbedded = data => get(data, '_embedded');

export const getEmbeddedItems = data =>
  flow(
    getEmbedded,
    getFp('items')
  )(data);

export const getHalRefs = data => get(data, '_links', {});

export const getHalSelfLink = data =>
  flow(
    getHalRefs,
    getFp('_self')
  )(data);

export const getIdFromHref = data =>
  flow(
    getFp('href'),
    flow(
      split('/'),
      last
    )
  )(data);
