import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { Field } from 'react-final-form';
import { getPlatformName } from '../../../utils/platform';
import { validateInvitedUsers } from '../../../utils/validation';
import { validateTeamMembers } from '../../utils/formValidation';
import UserTagsInputFormControl from '../../../challenge/containers/UserTagsInputFormControl';

TeamInput.propTypes = {
  autoFocus: PropTypes.bool,
  disableDelete: PropTypes.bool,
  emailRegex: PropTypes.instanceOf(RegExp),
  customLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  minLength: PropTypes.number,
  t: PropTypes.func.isRequired
};
export function TeamInput({
  autoFocus,
  emailRegex = /.*/,
  customLabel,
  minLength = 0,
  name,
  t,
  disableDelete,
  ...restOfProps
}) {
  const minLengthNum = Number(minLength);
  const required = minLengthNum > 0;
  const hintLabel = required
    ? t('ideaConcept/prop/team/hint', {
        count: minLengthNum
      })
    : t('ideaConcept/prop/team/hintOptional');
  const secondaryLabel = required ? null : t('common/description/optional');
  const validation = required
    ? validateTeamMembers(t, {
        emailRegex,
        minMembers: minLengthNum
      })
    : validateInvitedUsers(t, emailRegex);

  return (
    <Field
      component={UserTagsInputFormControl}
      name={name}
      label={
        customLabel ||
        t('ideaConcept/prop/team/label', {
          context: getPlatformName()
        })
      }
      hint={hintLabel}
      required={required}
      validationRegex={emailRegex}
      validate={validation}
      secondaryLabel={secondaryLabel}
      validationError={t('common/prop/email/error')}
      placeholder={t('common/prop/email/placeholder')}
      autoFocus={autoFocus}
      disableDelete={disableDelete}
      {...restOfProps}
    />
  );
}

export default compose(
  memo,
  translate()
)(TeamInput);
