import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import pick from 'lodash/pick';
import forceArray from '../../utils/forceArray';
import parseQueryString from '../../utils/parseQueryString';

export const rootSelector = state => get(state, 'router');

export const getLocation = state =>
  flow(
    rootSelector,
    getFp('location')
  )(state);

export const getRoute = state =>
  flow(
    getLocation,
    location => get(location, 'pathname', '')
  )(state);

const getQueryString = state =>
  flow(
    getLocation,
    location => get(location, 'search', '')
  )(state);

const getQueryParams = state =>
  flow(
    getQueryString,
    parseQueryString
  )(state);

export const pickQueryParams = paramNames => state =>
  flow(
    getQueryParams,
    queryParams => {
      const params = forceArray(paramNames);
      return pick(queryParams, params);
    }
  )(state);

export const getRedirectTarget = state =>
  flow(
    getQueryParams,
    getFp('redirect')
  )(state);

export default {
  getRoute,
  getQueryString,
  getQueryParams,
  pickQueryParams
};
