import axios from 'axios';
import get from 'lodash/get';
import { useCallback } from 'react';

const baseQueryParams = {
  cx: process.env.REACT_APP_GOOGLE_SEARCH_ENGINE_ID,
  searchType: 'image',
  key: process.env.REACT_APP_GOOGLE_CUSTOM_SEARCH_API_KEY,
  safe: 'high'
};
const PAGE_COUNT = 10;

export default function useGoogleImageSearch() {
  return useCallback((searchTerm, start = 0) => {
    return searchTerm
      ? axios
          .get('https://www.googleapis.com/customsearch/v1', {
            params: {
              ...baseQueryParams,
              q: searchTerm,
              start: start * PAGE_COUNT
            }
          })
          .then(response =>
            get(response, 'data.items', []).map(item => ({
              url: item.image.thumbnailLink
            }))
          )
          .catch(error => {
            throw new Error(error.response.data);
          })
      : Promise.resolve();
  }, []);
}
