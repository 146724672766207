import React from 'react';
import styled from 'styled-components';
import Grid from '../Grid';
import Icon from '../Icon';

const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 150px;
  left: 0;
  width: 100%;
`;

const Banner = styled.aside`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0.5rem;
  border-radius: 5px;
  background: ${({ theme }) => theme.bodyBg};
  box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.1);
`;

const icons = {
  info: 'info2',
  warning: 'caution'
};

function AnnouncementBanner({ action = null, text, type }) {
  return (
    <Wrapper>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Banner>
              <Icon type={icons[type] || icons.info} />
              <div style={{ margin: '0 1rem' }}>{text}</div>
              <div style={{ flexShrink: 0 }}>{action}</div>
            </Banner>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Wrapper>
  );
}

export default AnnouncementBanner;
