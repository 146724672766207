import React from 'react';
import lowerCase from 'lodash/lowerCase';
import { translate } from 'react-i18next';
import useActiveStageFilter from '../../hooks/conceptList/useActiveStageFilter';
import NonIdealState from '../../../common/components/NonIdealState';

function Placeholder({ i18n, t }) {
  const activeStageFilter = useActiveStageFilter();
  const context = lowerCase(activeStageFilter);
  const title = t('ideaConcepts/listPage/empty/title', { context });
  const desc = 'ideaConcepts/listPage/empty/description';
  const description = i18n.exists(desc, { context }) && t(desc, { context });

  return (
    <NonIdealState
      visual={NonIdealState.HUMMINGBIRD}
      title={title}
      description={description}
    />
  );
}

export default translate()(Placeholder);
