import { useCallback, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import invariant from 'invariant';
import { useDispatch, useSelector } from 'react-redux';
import forceArray from '../../../utils/forceArray';
import { filterItemsBy, removeFilter } from '../../../common/actions/meta';
import { REDUCER_ID } from '../../reducers/conceptList';
import { getConceptListFilters } from '../../selectors/conceptList';
import useListFetcher from './useListFetcher';

const setFilter = filterItemsBy(REDUCER_ID);
const unsetFilter = removeFilter(REDUCER_ID);

const DEFAULTS = { overwriteValue: false, autoRefetch: true };

export default function useSetFilter() {
  const dispatch = useDispatch();
  const refreshList = useListFetcher();
  const filters = useSelector(getConceptListFilters);
  const lastFilters = useRef(filters);

  useEffect(
    function storeLastFilters() {
      lastFilters.current = filters;
    },
    [filters]
  );

  const filterSetter = useCallback(
    (filterKey, filterValue, options = DEFAULTS) => {
      invariant(filterKey, '`filterKey` not found');

      const config = { ...DEFAULTS, ...options };

      if (isEmpty(filterValue)) {
        dispatch(unsetFilter(filterKey));
      } else {
        dispatch(setFilter(filterKey, filterValue, config.overwriteValue));
      }

      const lastFilterValue = lastFilters.current[filterKey];
      const isFilterValueSame = isEqual(
        forceArray(lastFilterValue),
        forceArray(filterValue)
      );
      if (config.autoRefetch && !isFilterValueSame) {
        refreshList({ flushList: true });
      }
    },
    [dispatch, refreshList, lastFilters]
  );

  return filterSetter;
}
