import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { OnboardingTips } from '../../../constants';
import { updateOnboarding } from '../../actions';
import { ownProfileSelector } from '../../../profile/state/profile';
import withModal from '../withModal';
import Button from '@bit/be-novative.kit.button';
import Embed from '../../components/Embed';
import Grid from '../../components/Grid';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import Panel from '../../components/Panel';

class WelcomeModal extends Component {
  componentWillUnmount() {
    this.props.onViewed();
  }

  render() {
    const { t, profile, onClose } = this.props;
    return (
      <Panel bordered>
        <Grid.Row>
          <Grid.Col xsGutterX={3} md={5}>
            <Heading>{t('common/Welcome {{firstName}}', profile)}</Heading>
            <Paragraph>
              <Trans i18nKey="common/introduction">
                <p>We are very happy to have you on board!</p>
                <p>
                  With Be-novative you can create challenges, ideate on various
                  topics, vote on the best suggestions, and source creative
                  ideas in minutes. Efficiently tackle complex tasks, just like
                  thousands of others globally.
                </p>
                <p>
                  To help you learn the ropes, we created a short guided tour
                  for you.
                </p>
              </Trans>
            </Paragraph>
            <Button variant="success" size="large" fillParent onClick={onClose}>
              {t('common/Let’s get started')}
            </Button>
          </Grid.Col>
          <Grid.Col xsGutter={3} md={7}>
            <Paragraph>
              {t('common/Psst, before you start, watch our short video.')}
            </Paragraph>
            <Embed>
              <iframe
                title={t(
                  'common/Psst, before you start, watch our short video.'
                )}
                src="https://www.youtube.com/embed/iPYbTwz5XVc"
                allowFullScreen
              />
            </Embed>
          </Grid.Col>
        </Grid.Row>
      </Panel>
    );
  }
}

const mapStateToProps = state => ({
  profile: ownProfileSelector(state)
});

const mapDispatchToProps = {
  onViewed: () => updateOnboarding(OnboardingTips.WELCOME)
};

export default withModal(mapStateToProps, mapDispatchToProps)(WelcomeModal);
