/**
 * Defines severity levels so the errorlogging utilities can set the proper log level for each telemetry service
 * @enum {string}
 * @readonly
 */

export const Severity = {
  Verbose: 'VERBOSE',
  Info: 'INFO',
  Warning: 'WARNING',
  Error: 'ERROR',
  Critical: 'CRITICAL'
};
