import React from 'react';
import StageSelector from '../../../components/StageSelector';
import { getIdeaConceptId } from '../../../selectors/ideaConcepts';
import { AlignedCellContent, SingleLineHeight } from '../ConceptTable.styled';

const status = {
  main: (t, concept) => (
    <AlignedCellContent alignItems="flex-start" justifyContent="flex-start">
      <SingleLineHeight>
        <StageSelector ideaConceptId={getIdeaConceptId(concept)} />
      </SingleLineHeight>
    </AlignedCellContent>
  )
};

export default status;
