import React, { Component } from 'react';
import each from 'lodash/each';
import find from 'lodash/fp/find';
import flow from 'lodash/flow';
import get from 'lodash/get';
import getFp from 'lodash/fp/get';
import memoize from 'lodash/memoize';
import some from 'lodash/some';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { translate } from 'react-i18next';
import {
  Button,
  Callout,
  ConfirmDialog,
  Grid,
  CheckboxFormControl,
  Paragraph
} from '../../../common/components';
import { openModal, closeModal } from '../../../common/actions';
import {
  updatePrivacy,
  deleteProfile,
  ownProfileSelector
} from '../../../profile/state/profile';
import { getUserConsent } from '../../getters';
import { ModalRoot } from '../../../common/containers';
import { userConsentKeys as consentKeys } from '../../../constants';
import { ACCOUNT_MANAGEMENT_CONSENT } from '../../constants';
import {
  findByPropMatch,
  dropByPropMatch
} from '../../../utils/arrayCallbacks';
import { resetCookieConsent } from '../../../utils/cookieConsent';
import { generateConsentModel } from '../../../utils/legal';
import translateApiErrorMessage from '../../../utils/translateApiErrorMessage';

const CONFIRM_MODAL = 'CONFIRM_MODAL';

const privacyOptions = memoize(t => generateConsentModel(t));

// TODO refactor task created in CLOUD-2772
export class PrivacySettings extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    updatePrivacy: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    privacyOptions: PropTypes.array.isRequired
  };

  state = {
    checkboxes: {
      ...this.props.initialValues
    },
    submitStarted: false,
    submitSucceeded: false,
    submitError: false,
    warningsOnSubmit: []
  };

  componentDidMount() {
    each(
      this.state.checkboxes[consentKeys.ACCOUNT_MANAGEMENT_CONSENT],
      (isConsentGiven, choiceName) => {
        if (!isConsentGiven) {
          this.queueSubmitWarning(
            consentKeys.ACCOUNT_MANAGEMENT_CONSENT,
            choiceName
          );
        }
      }
    );
  }

  getModalSettings = sectionName => {
    const warning = flow(
      find(findByPropMatch('name', sectionName)),
      getFp('warning')
    )(privacyOptions(this.props.t));

    if (warning) {
      return { ...warning, size: 'large' };
    }
  };

  handleCheckboxChange = (sectionName, choiceName, event) => {
    const { checked } = event.target;
    const { openModal } = this.props;
    const modalSettings = this.getModalSettings(sectionName);
    const shouldShowWarning = !checked && modalSettings;
    const continueCheckboxUpdate = () =>
      this.updateCheckboxState(sectionName, choiceName, checked);
    const okCallback = () => {
      continueCheckboxUpdate();
      this.queueSubmitWarning(sectionName, choiceName);
    };

    this.setState({ submitStarted: false });

    if (shouldShowWarning) {
      openModal({ ...modalSettings, okCallback });
    } else {
      continueCheckboxUpdate();
      this.unqueueSubmitWarning(choiceName);
    }
  };

  updateCheckboxState = (sectionName, choiceName, value) => {
    this.setState(prevState => ({
      checkboxes: {
        ...prevState.checkboxes,
        [sectionName]: {
          ...prevState.checkboxes[sectionName],
          [choiceName]: !!value
        }
      }
    }));
  };

  queueSubmitWarning = (sectionName, choiceName) => {
    const { closeModal } = this.props;
    const shouldBeFirstWarning =
      sectionName === consentKeys.ACCOUNT_MANAGEMENT_CONSENT;
    const restoreCheckboxToTrue = () =>
      this.updateCheckboxState(sectionName, choiceName, true);
    const okCallback = async () => {
      await closeModal();
      this.showNextSubmitWarning();
    };
    const cancelCallback = async () => {
      await closeModal();
      restoreCheckboxToTrue();
    };
    const modalSettings = {
      sectionName,
      choiceName,
      settings: {
        ...this.getModalSettings(sectionName),
        okCallback,
        cancelCallback
      }
    };
    const isWarningUnique = !this.state.warningsOnSubmit.find(
      findByPropMatch('sectionName', sectionName)
    );

    if (isWarningUnique) {
      this.setState(prevState => {
        const warningsOnSubmit = shouldBeFirstWarning
          ? [modalSettings, ...prevState.warningsOnSubmit]
          : [...prevState.warningsOnSubmit, modalSettings];

        return {
          ...prevState,
          warningsOnSubmit
        };
      });
    }
  };

  unqueueSubmitWarning = choiceName => {
    this.setState(prevState => ({
      ...prevState,
      warningsOnSubmit: [
        ...prevState.warningsOnSubmit.filter(
          dropByPropMatch('choiceName', choiceName)
        )
      ]
    }));
  };

  showNextSubmitWarning = () => {
    const { openModal } = this.props;
    const [modalSettings, ...restOfWarnings] = this.state.warningsOnSubmit;

    if (modalSettings) {
      openModal(modalSettings.settings);
      this.setState(prevState => ({
        ...prevState,
        warningsOnSubmit: [...restOfWarnings]
      }));
    } else {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    if (this.state.warningsOnSubmit.length > 0) {
      return this.showNextSubmitWarning();
    }

    this.setState({
      submitStarted: true,
      submitSucceeded: false,
      submitError: false
    });

    const consentSettings = this.state.checkboxes;
    const isProfileDeleteRequired = some(
      consentSettings[ACCOUNT_MANAGEMENT_CONSENT],
      consentGiven => !consentGiven
    );

    if (isProfileDeleteRequired) {
      this.props
        .deleteProfile()
        .then(unwrapResult)
        .catch(this.handleSubmitFail);
    } else {
      this.props
        .updatePrivacy(this.state.checkboxes)
        .then(unwrapResult)
        .then(this.handleSubmitSuccess)
        .catch(this.handleSubmitFail);
    }
  };

  handleSubmitSuccess = () => {
    this.setState({ submitStarted: false, submitSucceeded: true });
  };

  handleSubmitFail = submitError => {
    this.setState({ submitStarted: false, submitError });
  };

  handleCookieReset = () => {
    resetCookieConsent();
    window.location.reload();
  };

  render() {
    const { t } = this.props;
    const {
      checkboxes,
      submitStarted,
      submitError,
      submitSucceeded
    } = this.state;

    return (
      <>
        <Grid.Row gutter={0}>
          <Grid.Col xsGutterX={2} md={8} lg={6}>
            <h1>{t('profile/privacyForm/title')}</h1>
            <p>{t('profile/privacyForm/description')}</p>
            <form>
              {privacyOptions(t).map(section => (
                <div key={section.name}>
                  <h4>{section.label}</h4>

                  {section.choices.map(choice => (
                    <CheckboxFormControl
                      key={choice.name}
                      name={choice.name}
                      input={{
                        checked: !!get(
                          checkboxes,
                          [section.name, choice.name],
                          true
                        ),
                        onChange: this.handleCheckboxChange.bind(
                          this,
                          section.name,
                          choice.name
                        )
                      }}>
                      {choice.label}
                    </CheckboxFormControl>
                  ))}
                </div>
              ))}
              <Button
                onClick={this.handleSubmit}
                variant={'success'}
                loading={submitStarted}>
                {t('common/userAction/save')}
              </Button>
            </form>
            <Grid.Row gutter={0}>
              <Grid.Col stretch>
                {submitSucceeded && (
                  <Callout type="success" tag="p">
                    {t('profile/privacyForm/submitSuccess')}
                  </Callout>
                )}
                {submitError && (
                  <Callout type="danger" tag="p">
                    {translateApiErrorMessage(t, submitError)}
                  </Callout>
                )}
              </Grid.Col>
            </Grid.Row>
            <ModalRoot modalComponents={{ [CONFIRM_MODAL]: ConfirmDialog }} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xsGutterX={2} md={8} lg={6}>
            <Paragraph align="center">
              <Button variant="link" onClick={this.handleCookieReset}>
                {t('profile/privacyForm/resetCookieCTA')}
              </Button>
            </Paragraph>
          </Grid.Col>
        </Grid.Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: flow(
    ownProfileSelector,
    getUserConsent
  )(state)
});

const mapDispatchToProps = dispatch => ({
  openModal: (modalSettings = {}) =>
    dispatch(
      openModal({
        modalType: CONFIRM_MODAL,
        modalProps: {
          isCloseButtonShown: false,
          rounded: true,
          ...modalSettings
        }
      })
    ),
  closeModal: () => dispatch(closeModal()),
  updatePrivacy: userConsent => dispatch(updatePrivacy(userConsent)),
  deleteProfile: () => dispatch(deleteProfile())
});

export default compose(
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PrivacySettings);
