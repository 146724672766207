import React, { Component } from 'react';
import flow from 'lodash/flow';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { closeModal } from '../../actions';
import {
  ownProfileSelector,
  updatePrivacy
} from '../../../profile/state/profile';
import { getUserConsent } from '../../../profile/getters';
import withModal from '../../containers/withModal';
import { Button, Callout, Checkbox, Notice, Panel } from '../';
import { userConsentKeys } from '../../../constants';
import './LegalUpdateModal.css';

export const LEGAL_UPDATE_MODAL = 'legalUpdateModal';
const {
  ACCOUNT_MANAGEMENT_CONSENT,
  COOKIES_AND_LOGS_ACCEPTED,
  PERSONAL_DATA_MANAGEMENT_ACCEPTED,
  TERMS_AND_PRIVACY_ACCEPTED
} = userConsentKeys;

export class LegalUpdateModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    updatePrivacy: PropTypes.func.isRequired,
    userConsent: PropTypes.object.isRequired
  };

  state = {
    canSubmit: false,
    checkboxes: {},
    isSubmitting: false,
    isSubmitFailed: false,
    shouldShowTermsPrivacyUpdate: false,
    shouldShowDataMgmtUpdate: false,
    shouldShowCookieUpdate: false
  };

  componentDidMount() {
    const {
      termsAccepted,
      privacyAccepted,
      dataMgmtAccepted,
      cookiesAccepted
    } = this.getInitialConsentFields();
    const shouldShowTermsPrivacyUpdate = !termsAccepted || !privacyAccepted;
    const shouldShowDataMgmtUpdate = !dataMgmtAccepted;
    const shouldShowCookieUpdate = !cookiesAccepted;

    this.setState({
      checkboxes: {
        termsAccepted,
        privacyAccepted,
        dataMgmtAccepted,
        cookiesAccepted
      },
      shouldShowTermsPrivacyUpdate,
      shouldShowDataMgmtUpdate,
      shouldShowCookieUpdate
    });
  }

  getInitialConsentFields = () => {
    const { userConsent } = this.props;
    const termsAccepted = get(userConsent, TERMS_AND_PRIVACY_ACCEPTED);
    const dataMgmtAccepted = get(userConsent, [
      ACCOUNT_MANAGEMENT_CONSENT,
      PERSONAL_DATA_MANAGEMENT_ACCEPTED
    ]);
    const cookiesAccepted = get(userConsent, [
      ACCOUNT_MANAGEMENT_CONSENT,
      COOKIES_AND_LOGS_ACCEPTED
    ]);
    return {
      dataMgmtAccepted,
      cookiesAccepted,
      termsAccepted,
      privacyAccepted: termsAccepted
    };
  };

  getConsentPayload = () => {
    const {
      termsAccepted,
      privacyAccepted,
      dataMgmtAccepted,
      cookiesAccepted
    } = this.state.checkboxes;
    const { userConsent } = this.props;

    return {
      ...userConsent,
      [TERMS_AND_PRIVACY_ACCEPTED]: termsAccepted && privacyAccepted,
      [ACCOUNT_MANAGEMENT_CONSENT]: {
        [PERSONAL_DATA_MANAGEMENT_ACCEPTED]: dataMgmtAccepted,
        [COOKIES_AND_LOGS_ACCEPTED]: cookiesAccepted
      }
    };
  };

  handleCheckboxUpdate = event => {
    const { checked, name } = event.target;

    this.setState(prevState => ({
      checkboxes: {
        ...prevState.checkboxes,
        [name]: checked
      }
    }));
  };

  handleSubmit = () => {
    this.setState({ isSubmitting: true, isSubmitFailed: false });

    this.props
      .updatePrivacy(this.getConsentPayload())
      .then(unwrapResult)
      .then(this.handleSubmitSuccess)
      .catch(this.handleSubmitFail);
  };

  handleSubmitSuccess = () => {
    this.props.closeModal();
  };

  handleSubmitFail = () => {
    this.setState({ isSubmitting: false, isSubmitFailed: true });
  };

  renderTermsUpdate = () => {
    const { t } = this.props;
    const { checkboxes } = this.state;
    return (
      <div>
        <Notice>
          <p>
            {t(
              'legalUpdate/Our terms and conditions have changed since your last login. We want you to know exactly how our services works.'
            )}
          </p>
          <a
            href={
              '//' + t('URIs/www.be-novative.com/terms_and_conditions.html')
            }
            target="_blank"
            rel="noopener noreferrer">
            {t(
              'legalUpdate/Please read and accept our updated terms and conditions.'
            )}
          </a>
        </Notice>
        <Checkbox
          input={{
            checked: checkboxes.termsAccepted,
            name: 'termsAccepted',
            onChange: this.handleCheckboxUpdate
          }}>
          {t('legalUpdate/I have read and agree to the terms and conditions')}
        </Checkbox>
      </div>
    );
  };

  renderPrivacyUpdate = () => {
    const { t } = this.props;
    const { checkboxes } = this.state;
    return (
      <div>
        <Notice>
          <p>
            {t(
              'legalUpdate/We want to ensure that your personal information is safe and secure.'
            )}
          </p>
          <a
            href={'//' + t('URIs/www.be-novative.com/privacy_policy.html')}
            target="_blank"
            rel="noopener noreferrer">
            {t(
              'legalUpdate/Please read and accept our updated privacy policy.'
            )}
          </a>
        </Notice>
        <Checkbox
          input={{
            checked: checkboxes.privacyAccepted,
            name: 'privacyAccepted',
            onChange: this.handleCheckboxUpdate
          }}>
          {t('legalUpdate/I have read and agree to the privacy policy')}
        </Checkbox>
      </div>
    );
  };

  renderDataMgmtUpdate = () => {
    const { t } = this.props;
    const { checkboxes } = this.state;
    return (
      <Checkbox
        input={{
          checked: checkboxes.dataMgmtAccepted,
          name: 'dataMgmtAccepted',
          onChange: this.handleCheckboxUpdate
        }}>
        {t(
          'profile/I give consent to use my personal data to manage my account, which includes registration, password retrieval, data deletion, and receiving emails related to these items.'
        )}
      </Checkbox>
    );
  };

  renderCookieUpdate = () => {
    const { t } = this.props;
    const { checkboxes } = this.state;
    return (
      <Checkbox
        input={{
          checked: checkboxes.cookiesAccepted,
          name: 'cookiesAccepted',
          onChange: this.handleCheckboxUpdate
        }}>
        {t(
          'profile/I give consent to use cookies and system logs to manage my account.'
        )}
      </Checkbox>
    );
  };

  render() {
    const { t } = this.props;
    const {
      checkboxes,
      isSubmitting,
      isSubmitFailed,
      shouldShowTermsPrivacyUpdate,
      shouldShowDataMgmtUpdate,
      shouldShowCookieUpdate
    } = this.state;
    const shouldShowAccMgmtUpdate =
      shouldShowDataMgmtUpdate || shouldShowCookieUpdate;
    const isSubmitDisabled =
      reduce(
        checkboxes,
        (shouldDisable, val) => shouldDisable || !val,
        false
      ) || isSubmitting;

    return (
      <Panel className="LegalUpdate">
        <h3>{t('legalUpdate/Some changes for you to review!')}</h3>

        {shouldShowTermsPrivacyUpdate && this.renderTermsUpdate()}
        {shouldShowTermsPrivacyUpdate && this.renderPrivacyUpdate()}

        {shouldShowAccMgmtUpdate && (
          <div>
            <Notice>
              <p>
                {t(
                  'legalUpdate/We would like to make sure you allow us to process your personal information for account management.'
                )}
              </p>
            </Notice>

            {shouldShowDataMgmtUpdate && this.renderDataMgmtUpdate()}
            {shouldShowCookieUpdate && this.renderCookieUpdate()}
          </div>
        )}

        <Button
          className="LegalUpdate__Submit"
          variant={'success'}
          fillParent
          disabled={isSubmitDisabled}
          loading={isSubmitting}
          onClick={this.handleSubmit}>
          {t('legalUpdate/I accept the new changes')}
        </Button>

        {isSubmitFailed && (
          <Callout type="danger" tag="p" className="LegalUpdate__Callout">
            {t('legalUpdate/submitFail')}
          </Callout>
        )}
      </Panel>
    );
  }
}

const mapStateToProps = state => ({
  userConsent: flow(
    ownProfileSelector,
    getUserConsent
  )(state)
});

const mapDispatchToProps = {
  closeModal,
  updatePrivacy
};

export default compose(
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withModal()
)(LegalUpdateModal);
