import React from 'react';
import { Field } from 'redux-form';
import { Trans, translate } from 'react-i18next';
import { required } from '../../../utils/validation';
import { CheckboxFormControl } from '..';
import { userConsentKeys } from '../../../constants';

function AcceptCheckboxField({ t, name }) {
  return (
    <Field
      name={name}
      component={CheckboxFormControl}
      validate={[required(t)]}
      type="checkbox">
      <Trans i18nKey="authentication/signupProcess/consent/legalCheckbox">
        <a
          href="https://www.be-novative.com/terms_and_conditions.html"
          target="_blank"
          rel="noopener noreferrer">
          label
        </a>
        <a
          href="https://www.be-novative.com/privacy_policy.html"
          target="_blank"
          rel="noopener noreferrer">
          label
        </a>
      </Trans>
    </Field>
  );
}

AcceptCheckboxField.defaultProps = {
  name: userConsentKeys.TERMS_AND_PRIVACY_ACCEPTED
};

export default translate()(AcceptCheckboxField);
