import Button from '@bit/be-novative.kit.button';
import Spinner from '@bit/be-novative.kit.spinner';
import isFunction from 'lodash/isFunction';
import React, { useCallback, useState } from 'react';
import { translate } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import stripHTML from '@bit/be-novative.utils.strip-html';
import useTooltip from '../../../../common/hooks/useTooltip';
import { AsyncStatus } from '../../../../constants';
import { CommentCell } from '../ConceptTable.styled';

function CommentDisplay({ canEdit, onOpen, onVisible, t, text }) {
  const [status, setStatus] = useState(AsyncStatus.Idle);
  const fetchData = useCallback(async () => {
    if (isFunction(onVisible)) {
      setStatus(AsyncStatus.Loading);
      try {
        await onVisible();
        setStatus(AsyncStatus.Succeeded);
      } catch (error) {
        setStatus(AsyncStatus.Failed);
      }
    }
  }, [onVisible]);
  const fetchOnVisible = useCallback(
    async function handleFetch(isCellVisible) {
      if (isCellVisible && status === AsyncStatus.Idle) {
        fetchData();
      }
    },
    [fetchData, status]
  );
  const tooltip = useTooltip(
    canEdit
      ? t('common/userAction/edit', { context: 'click' })
      : t('common/userAction/showMore'),
    'right'
  );
  const tooltipProps = onOpen ? tooltip : {};

  return (
    <div style={{ width: '100%' }}>
      <VisibilitySensor onChange={fetchOnVisible} delayedCall>
        <div {...tooltipProps} style={{ minHeight: 1 }}>
          {status === AsyncStatus.Loading && (
            <Spinner type="benovative" size="medium" />
          )}
          {status === AsyncStatus.Succeeded && (
            <CommentCell onClick={onOpen}>
              {stripHTML(text) ? (
                <span>{stripHTML(text)}</span>
              ) : (
                <small>
                  <em>{t('common/Empty', { context: 'parens' })}</em>
                </small>
              )}
            </CommentCell>
          )}
          {status === AsyncStatus.Failed && (
            <small>
              {t('ideaConcept/evaluation/comment/loadFailed')}
              <br />
              <Button variant="link" onClick={() => fetchData()}>
                {t('ideaConcept/evaluation/comment/loadRetryCTA')}
              </Button>
            </small>
          )}
        </div>
      </VisibilitySensor>
    </div>
  );
}

export default translate()(CommentDisplay);
