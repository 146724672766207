import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../../common/actions';
import { Button, Modal, Panel, Grid, Icon } from '../../../common/components';

class ShareModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    opts: PropTypes.object,
    sendAlert: PropTypes.func.isRequired,
    shareButtonClick: PropTypes.func,
    showLinkCopy: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string.isRequired
  };

  static defaultProps = {
    shareButtonClick: () => {},
    showLinkCopy: true
  };

  copyToClipboard = () => {
    const { sendAlert, copyToClipboardClicked, url, t } = this.props;

    copyToClipboardClicked();

    this.inputEl.focus();
    this.inputEl.setSelectionRange(0, url.length);

    sendAlert({
      message: t('common/Link copied to clipboard'),
      kind: 'success'
    });
  };

  setInputRef = el => {
    this.inputEl = el;
  };

  render() {
    const { t, url, title, subtitle, showLinkCopy, closeModal } = this.props;

    return (
      <Modal
        size="small"
        isOpen={true}
        onClose={closeModal}
        rounded
        isCloseButtonShown>
        <Panel bordered>
          <header>
            <h2>{title || t('common/Share this')}</h2>
            <p>
              <small>{subtitle}</small>
            </p>
          </header>
          {showLinkCopy && (
            <Grid.Row>
              <Grid.Col xsGutterX={2} xsGutterY={1}>
                <CopyToClipboard text={url} onCopy={this.copyToClipboard}>
                  <input
                    ref={this.setInputRef}
                    type="text"
                    value={url}
                    readOnly={true}
                  />
                </CopyToClipboard>
              </Grid.Col>
              <Grid.Col xsGutterX={2} xsGutterY={1}>
                <CopyToClipboard text={url} onCopy={this.copyToClipboard}>
                  <Button variant="info" size="large" fillParent>
                    <Icon type="link" />
                    <span>{t('common/Copy Link')}</span>
                  </Button>
                </CopyToClipboard>
              </Grid.Col>
            </Grid.Row>
          )}
        </Panel>
      </Modal>
    );
  }
}

export { ShareModal };

export default compose(translate(), connect(null, actions))(ShareModal);
