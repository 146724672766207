import first from 'lodash/first';
import flow from 'lodash/flow';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import * as Sentry from '@sentry/browser';
import {
  getUserHash,
  getUserDisplayName,
  getFirstName,
  getLastName,
  getUserId
} from '../../common/getters/users';
import emailRegExp from '../regex/email';

export function convertUserToInvitee(userObject) {
  let firstName = getFirstName(userObject);
  let lastName = getLastName(userObject);
  const userObj = get(userObject, 'userName');

  if (isObject(userObj)) {
    firstName = getFirstName(userObj);
    lastName = getLastName(userObj);
  }

  return {
    userName: {
      firstName,
      lastName
    },
    hash: getUserHash(userObject),
    displayName: getUserDisplayName(userObject),
    userId: getUserId(userObject)
  };
}

export function convertInviteeToIdString(invitee) {
  const hash = getUserHash(invitee);

  if (hash) {
    return hash;
  }

  const displayName = getEmailFromInviteeDisplayName(invitee);

  if (!displayName) {
    Sentry.addBreadcrumb({
      level: 'warning',
      data: {
        ...invitee
      }
    });
    throw new Error(
      'Could not find email address in invitee displayName ' + displayName
    );
  }

  return displayName;
}

export function getEmailFromInviteeDisplayName(userObject) {
  return flow(
    getUserDisplayName,
    str => str.match(emailRegExp),
    first
  )(userObject);
}
