import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCanReviewConcepts from '../../hooks/useCanReviewConcepts';
import useActiveStageFilter from '../../hooks/conceptList/useActiveStageFilter';
import {
  conceptListColumns,
  IdeaConceptImplementationStatus as ConceptPhase
} from '../../constants';
import { conceptEvalPage } from '../../paths';
import { fetchChallenge } from '../../../common/actions/challenges';
import { openConceptPreview } from '../../actions/modals';
import { defaultReviewCriteriaSelector } from '../../../common/state/platform';
import { getEvalCriterionId } from '../../selectors/conceptEvaluation';
import { getChallengeId, getIdeaConceptId } from '../../selectors/ideaConcepts';
import CellRenderer from './CellRenderer';

export const scrollIds = {
  scores: 0,
  comments: 1
};

/**
 * @typedef {object} ConceptTableColumnConfig
 * @property {string} id - UUID, but also used as a sorting key
 * @property {boolean} [hidden]
 * @property {string} [title]
 * @property {boolean} [sortDisabled]
 * @property {import('react').ReactNode} [renderHeader]
 * @property {import('react').ReactNode} [renderMain]
 * @property {import('react').ReactNode} [renderExpanded]
 * @property {'left' | 'right'} [sticky] - If true, will also render the left sticky cells as `<th/>`s
 */

/**
 * Generates config for rendering header, content, expanded content for each concept table column
 * @returns {ConceptTableColumnConfig[]}
 */
export default function useTableConfig() {
  const leftStickyCols = useMemo(() => {
    const width = '280px';
    const expandedMaxWidth = '270px';
    return [
      {
        id: conceptListColumns.TITLE,
        width,
        sticky: 'left',
        title: 'Concept Title',
        ariaRole: 'columnHeader',
        renderMain: CellRenderer.conceptTitle.main,
        renderExpanded: (t, concept) =>
          CellRenderer.conceptTitle.expanded(t, concept, expandedMaxWidth)
      }
    ];
  }, []);

  function mapEvalCriteriaToColConfig(criteria, width) {
    return criteria.map((criterion, ix) => {
      const id = getEvalCriterionId(criterion);
      return {
        id,
        width,
        sortDisabled: true,
        renderHeader: t =>
          CellRenderer.criterionScore.header(
            t,
            null,
            criterion,
            ix === 0 ? scrollIds.scores : undefined
          ),
        renderMain: (t, concept) =>
          CellRenderer.criterionScore.main(t, concept, criterion),
        renderExpanded: (t, concept) =>
          CellRenderer.criterionScore.expanded(t, concept, criterion)
      };
    });
  }
  const criteria = useSelector(defaultReviewCriteriaSelector);
  const bodyCols = useMemo(
    () =>
      [].concat(
        {
          id: conceptListColumns.OWNER,
          width: '90px',
          renderHeader: t => CellRenderer.owner.header(t),
          renderMain: CellRenderer.owner.main
        },
        {
          id: conceptListColumns.TEAM,
          width: '140px',
          title: 'Team',
          renderMain: CellRenderer.team.main
        },
        {
          id: conceptListColumns.CREATE_TIME,
          width: '150px',
          title: 'Start date',
          renderMain: CellRenderer.startTime.main
        },
        mapEvalCriteriaToColConfig(criteria, '130px'),
        {
          id: conceptListColumns.EVAL_SCORE,
          width: '105px',
          title: 'Average score',
          renderMain: CellRenderer.averageScore.main,
          renderExpanded: (t, concept) =>
            CellRenderer.averageScore.expanded(t, concept)
        },
        {
          id: conceptListColumns.INTERNAL_COMMENT,
          width: '300px',
          renderHeader: t =>
            CellRenderer.internalComments.header(t, scrollIds.comments),
          renderMain: CellRenderer.internalComments.main,
          renderExpanded: CellRenderer.internalComments.expanded
        },
        {
          id: conceptListColumns.REVIEWER_FEEDBACK,
          width: '300px',
          renderHeader: CellRenderer.feedback.header,
          renderMain: CellRenderer.feedback.main,
          renderExpanded: CellRenderer.feedback.expanded
        },
        {
          id: conceptListColumns.STAGE,
          width: '200px',
          title: 'Status',
          renderMain: CellRenderer.status.main
        }
      ),
    [criteria]
  );

  const activePhase = useActiveStageFilter();
  const canReviewConcepts = useCanReviewConcepts();
  const dispatch = useDispatch();
  const openPreview = useCallback(
    conceptId => dispatch(openConceptPreview(conceptId)),
    [dispatch]
  );
  const prefetchChallenge = useCallback(
    challengeId => dispatch(fetchChallenge(challengeId)),
    [dispatch]
  );
  const rightStickyCols = useMemo(
    () => [
      {
        id: 'action',
        width: '160px',
        sticky: 'right',
        renderHeader: CellRenderer.action.header,
        renderMain: (t, concept) => {
          const shouldRenderReview =
            canReviewConcepts &&
            [
              ConceptPhase.IMPLEMENTING,
              ConceptPhase.VALIDATION,
              ConceptPhase.DONE
            ].includes(activePhase);
          const conceptId = getIdeaConceptId(concept);
          const parentChallengeId = getChallengeId(concept);
          const btnProps = shouldRenderReview
            ? {
                children: t('ideaConcept/evaluation/form/submitCTA', {
                  context: 'short'
                }),
                to: conceptEvalPage('conceptId', conceptId),
                onMouseEnter: () => prefetchChallenge(parentChallengeId)
              }
            : {
                children: t('common/navigation/details', { context: 'short' }),
                onClick: () => openPreview(conceptId)
              };
          return CellRenderer.action.main(t, concept, btnProps);
        }
      }
    ],
    [activePhase, canReviewConcepts, openPreview, prefetchChallenge]
  );

  const filteredBodyCols = useMemo(() => {
    const colsHiddenFromAdmin = [
      conceptListColumns.OWNER,
      conceptListColumns.TEAM,
      conceptListColumns.CREATE_TIME
    ];
    const colsHiddenFromUser = [
      conceptListColumns.INTERNAL_COMMENT,
      conceptListColumns.EVAL_SCORE,
      ...criteria.map(getEvalCriterionId)
    ];
    const hiddenCols = canReviewConcepts
      ? colsHiddenFromAdmin
      : colsHiddenFromUser;
    return bodyCols.filter(colConfig => !hiddenCols.includes(colConfig.id));
  }, [bodyCols, criteria, canReviewConcepts]);

  const filteredRightCols = useMemo(
    () => (canReviewConcepts ? rightStickyCols : []),
    [rightStickyCols, canReviewConcepts]
  );

  return [leftStickyCols, filteredBodyCols, filteredRightCols];
}
