import forEach from 'lodash/forEach';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import get from 'lodash/get';
import { SOCKET_EVENTS } from '../api/constants';
import {
  socketConnecting,
  SOCKET_SEND_MESSAGE,
  socketConnected,
  socketError,
  socketDisconnected,
  SOCKET_INIT_CONNECTION,
  SOCKET_CLOSE_CONNECTION,
  SOCKET_SUBSCRIBE,
  SOCKET_UNSUBSCRIBE
} from '../common/actions/socket';
import { getToken } from '../authentication/services/token.service';
import addBreadcrumb, { Severity } from '../telemetry/addBreadcrumb';

const { CONNECTED, DISCONNECTED, RECONNECTING, ERROR } = SOCKET_EVENTS;

export default function socketMiddlewareFactory(
  config = {
    socketId: '',
    url: '',
    socketHandler: undefined,
    autoConnect: false
  }
) {
  const { socketId, socketHandler, url, autoConnect } = config;
  let connection,
    storedToken,
    loggingOut = false;

  return store => next => action => {
    const { type, payload, meta } = action;
    const latestToken = getToken();
    const hasTokenChanged = latestToken && latestToken !== storedToken;
    const shouldUpdateToken = hasTokenChanged && (connection || autoConnect);
    const shouldLogout = !latestToken && !loggingOut;

    if (hasTokenChanged) {
      storedToken = getToken();
      loggingOut = false;
    }

    if (shouldUpdateToken) {
      handleConnect(store, storedToken);
    } else if (shouldLogout) {
      loggingOut = true;
      handleCloseConnection(store);
    } else if (get(meta, 'socketId') === socketId) {
      switch (type) {
        case SOCKET_INIT_CONNECTION:
          return handleConnect(store, storedToken);
        case SOCKET_SEND_MESSAGE:
          return handleSendMessage(action);
        case SOCKET_SUBSCRIBE:
          handleAddListeners(store, payload);
          break;
        case SOCKET_UNSUBSCRIBE:
          handleRemoveListeners(payload);
          break;
        case SOCKET_CLOSE_CONNECTION:
          handleCloseConnection(store);
          break;
        default:
      }
    }

    return next(action);
  };

  function handleConnect(store, storedToken) {
    if (!connection) {
      connection = new socketHandler(socketId, url);

      connection
        .withToken(storedToken)
        .on(CONNECTED, handleConnected(store))
        .on(RECONNECTING, handleReconnecting(store))
        .on(DISCONNECTED, handleDisconnected(store))
        .on(ERROR, handleError(store))
        .connect();
      store.dispatch(socketConnecting(socketId));
    } else {
      connection.withToken(storedToken);
    }
  }

  function handleCloseConnection(store) {
    if (connection) {
      connection.disconnect().then(() => {
        connection = undefined;
        store.dispatch(socketDisconnected(socketId));
      });
    }
  }

  function handleSendMessage(action) {
    if (!connection) {
      return;
    }

    const targetSocket = get(action, ['meta', 'socketId']);
    const messageType = get(action, ['meta', 'messageType']);

    if (!targetSocket) {
      addBreadcrumb('Socket-related action contains no socketId, ignoring', {
        level: Severity.Warning,
        data: action
      });
      return;
    }

    return connection.send(messageType, action.payload);
  }

  function handleConnected(store) {
    return () => {
      store.dispatch(socketConnected(socketId));
    };
  }

  function handleReconnecting(store) {
    return () => {
      store.dispatch(socketConnecting(socketId));
    };
  }

  function handleDisconnected(store) {
    return () => {
      store.dispatch(socketDisconnected(socketId));
    };
  }

  function handleError(store) {
    return error => {
      store.dispatch(socketError(socketId, error));
    };
  }

  function handleAddListeners(store, newListeners) {
    if (connection) {
      forEach(newListeners, (listener, targetMessage) => {
        connection.on(targetMessage, createListenerFromAction(store, listener));
      });
    } else {
      throw new Error(
        `Cannot subscribe to ${socketId} events because socket is not initialised yet`
      );
    }
  }

  function handleRemoveListeners(listeners) {
    if (connection) {
      forEach(Object.keys(listeners), messageType => {
        connection.off(messageType);
      });
    }
  }

  function createListenerFromAction(store, action) {
    if (isString(action)) {
      return (response, messageType) =>
        store.dispatch({
          type: action,
          payload: response,
          meta: {
            messageType,
            status: response.status,
            socketId
          }
        });
    } else if (isFunction(action)) {
      return (response, messageType) =>
        store.dispatch(action(response, messageType, socketId));
    } else {
      throw new Error(
        `Event listener action must be string or function, got ${typeof action}`
      );
    }
  }
}
