import flow from 'lodash/flow';
import isEqual from 'lodash/isEqual';
import getFp from 'lodash/fp/get';
import mapFp from 'lodash/fp/map';
import { getIdeaId, getParentGroupId } from '../../common/selectors/ideas';
import { SOCKET_MSG, S_ID } from '../constants';
import {
  addCanvasIdeaMsg,
  deleteCanvasIdeaMsg,
  linkChallengeIdeaMsg,
  normaliseIdeaEditedMsg
} from '../utils/socketMsg/ideas';
import { getCanvasIdeaById } from '../selectors/ideas';
import { getIdeasByGroupId } from '../selectors/groups';
import { hideGroup } from './groups';
import forceArray from '../../utils/forceArray';
import {
  mapOwnerIdFromMsg,
  mapPositionFromMsg
} from '../utils/socketMsg/_mappers';
import { storeCanvasItemPosition } from './movement';
import { sendSocketMessage } from '../../common/actions/socket';
import { getCanvasItemPosition } from '../selectors/movement';

export const STORE_IDEAS = 'canvas/idea/store';
/**
 * Stores one or more items as canvas ideas in Redux
 * @param {ConceptCanvasIdea|ConceptCanvasIdea[]} payload
 * @returns {import('redux').Action}
 */
export const storeCanvasIdeas = payload => ({
  type: STORE_IDEAS,
  payload: forceArray(payload),
  meta: { canvasId: S_ID }
});

export const addCanvasIdea = (ideaText, posX, posY) => dispatch =>
  dispatch(addCanvasIdeaMsg(ideaText, posX, posY));

export const linkChallengeIdea = (ideaId, posX, posY) => dispatch =>
  dispatch(linkChallengeIdeaMsg(ideaId, posX, posY));

export const CANVAS_IDEA_ADDED = 'canvas/idea/add';
export const canvasIdeaAdded = response => dispatch => {
  const ideaLike = flow(
    mapPositionFromMsg,
    mapOwnerIdFromMsg
  )(response);
  dispatch({
    type: CANVAS_IDEA_ADDED,
    payload: ideaLike,
    meta: { canvasId: S_ID }
  });
  dispatch(
    storeCanvasItemPosition(
      { id: getIdeaId(ideaLike), posX: ideaLike.posX, posY: ideaLike.posY },
      'external'
    )
  );
};

export const editCanvasIdea = (ideaId, ideaText) => (dispatch, getState) => {
  const existingMergedIdeaIds = flow(
    state => getCanvasIdeaById(state, ideaId),
    getFp('mergedIdeas'),
    mapFp(getIdeaId)
  )(getState());
  dispatch(
    sendSocketMessage(S_ID, SOCKET_MSG.EDIT_IDEA_REQ, [
      ideaId,
      ideaText,
      existingMergedIdeaIds
    ])
  );
};

export const CANVAS_IDEA_EDITED = 'canvas/idea/edit';
export const canvasIdeaEdited = (response, messageType) => {
  const { ideaId, text } = normaliseIdeaEditedMsg(response);
  return {
    type: CANVAS_IDEA_EDITED,
    payload: text,
    // TODO stop using meta.id
    meta: { messageType, id: ideaId, canvasId: S_ID }
  };
};

/**
 * Initiates merging 2 or more canvas ideas. The API will create a new idea
 * with the merge parent and merge child included as merged variations.
 * @param {string|number} mergeParentId - ID of the idea into which another idea will be merged
 * @param {string} ideaText - Edited text of the newly merged idea
 * @param {(string|number)[]} mergedIdeaIds - IDs of merge child ideas. This should include both the merge parent and the merge children.
 * @returns {import('redux-thunk').ThunkAction}
 */
export const mergeCanvasIdeas = (mergeParentId, ideaText, mergedIdeaIds) => (
  dispatch,
  getState
) => {
  const section = null;
  const [x, y] = getCanvasItemPosition(getState(), mergeParentId);
  dispatch(
    sendSocketMessage(S_ID, SOCKET_MSG.IDEA_MERGE_REQ, [
      ideaText,
      section,
      { x, y },
      [mergeParentId].concat(mergedIdeaIds)
    ])
  );
};

export const CANVAS_IDEAS_MERGED = 'canvas/idea/merge';
export const canvasIdeaMerged = (response, messageType) => {
  const [ideaId, ideaText, mergedIdeaIds] = response;
  return {
    type: CANVAS_IDEAS_MERGED,
    payload: { ideaText, mergedIdeaIds },
    meta: { id: ideaId, canvasId: S_ID, messageType }
  };
};

export const deleteCanvasIdea = ideaId => dispatch =>
  dispatch(deleteCanvasIdeaMsg(ideaId));

export const CANVAS_IDEA_DELETED = 'canvas/idea/delete';
export const canvasIdeaDeleted = (ideaId, messageType) => (
  dispatch,
  getState
) => {
  const ideaLike = getCanvasIdeaById(getState(), ideaId);
  const parentGroupId = getParentGroupId(ideaLike);
  const siblingIdeaIds = getIdeasByGroupId(getState(), parentGroupId).map(
    getIdeaId
  );
  const isLastGroupItem = isEqual(siblingIdeaIds, [ideaId]);
  if (isLastGroupItem) {
    dispatch(hideGroup(parentGroupId));
  }
  return dispatch({
    type: CANVAS_IDEA_DELETED,
    payload: null,
    meta: { messageType, id: ideaId, canvasId: S_ID }
  });
};
