import { useState } from 'react';
import throttle from 'lodash/throttle';
import useMountEffect from './useMountEffect';

// Based on
// Added `useMountEffect` and throttling options
/**
 * Returns the viewport width and height.
 * Based on https://usehooks.com/useWindowSize/,
 * but added internal `useMountEffect` hook
 * and throttling options.
 * @param {Object} options
 * @param {number} [options.throttleMs] - Optional sampling interval in milliseconds. Defaults to `150`.
 * @returns {{ width: number, height: number }} The current viewport size
 */
export default function useWindowSize(options = { throttleMs: 100 }) {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useMountEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = throttle(() => {
      setWindowSize(getSize());
    }, options.throttleMs);

    function maybeResize() {
      handleResize();
    }

    window.addEventListener('resize', maybeResize);
    return function cleanUp() {
      window.removeEventListener('resize', maybeResize);
    };
  }, []);

  return windowSize;
}
